import React from 'react';
import GlitchText from '../GlitchText/GlitchText';
import logo_header from '@img/logo-header.svg';

import './style.css';
const FullPageLoader = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ lineHeight: '100vh', display: 'inline-block' }}>
        {/* 'Loading.........' */}
        <div className="logo-text">
          <GlitchText class="glitch">
            <div className="d-flex justify-content-center align-items-center">
              <img src={logo_header} alt="Leagues logo" width="55px" height="55px" />
              <strong
                className="uppercase logo__text"
                style={{ marginLeft: '10px', color: 'white', fontSize: '40px' }}>
                LEAGUES.GG
              </strong>
            </div>
          </GlitchText>
        </div>
      </div>
    </div>
  );
};

export default FullPageLoader;
