import React, { useState, useContext } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../../context/AppContext';
import useTranslated from '../../../../helpers/translationHelper';

const MySocials = () => {
  const { user } = useContext(AppContext);
  const { register, handleSubmit, errors } = useForm();
  const [cerror, setCerror] = useState({});
  const saveChangesTrans = useTranslated('id_settings_(socials)_0');
  const socialMessTrans = useTranslated('id_settings_(message)_1');
  let _discord_translation = useTranslated('id_social_media_links_0');
  let _Name_social_media_links_translation = useTranslated('id_social_media_links_1');
  let _Twitch_social_media_links_translation = useTranslated('id_social_media_links_2');
  let _Twitch_tv_social_media_links_translation = useTranslated('id_social_media_links_3');
  let _Facebook_social_media_links_translation = useTranslated('id_social_media_links_4');
  let _facebook_url_social_media_links_translation = useTranslated('id_social_media_links_5');
  let _Twitter_social_media_links_translation = useTranslated('id_social_media_links_6');
  let _twitter_url_social_media_links_translation = useTranslated('id_social_media_links_7');
  let _Instagram_social_media_links_translation = useTranslated('id_social_media_links_8');
  let _instagram_url_social_media_links_translation = useTranslated('id_social_media_links_9');
  let _Youtube_social_media_links_translation = useTranslated('id_social_media_links_10');
  let _youtube_url_social_media_links_translation = useTranslated('id_social_media_links_11');

  const onSubmit = async (data) => {
    try {
      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/auth`, data);
      user.dispatch({ type: 'update', payload: newData.updatedUser });
      toast.success(/* "Social links updated" */ socialMessTrans);
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : 'Fatal error';
      toast.error(msg);
    }
  };

  // Social fields are dynamically rendered from this array! The value must match the regex in order to be valid
  const socialFields = [
    {
      name: `${_discord_translation}`,
      placeholder: `${_Name_social_media_links_translation}`,
      regex: /.*\s?#\s?([0-9]{4})$/,
      invalidMsg: `${_discord_translation} Discord name is not valid`
    },
    {
      name: `${_Twitch_social_media_links_translation}`,
      placeholder: `${_Twitch_tv_social_media_links_translation}`,
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitch.tv)\/([A-Za-z0-9-_]+)/
    },
    {
      name: `${_Facebook_social_media_links_translation}`,
      placeholder: `${_facebook_url_social_media_links_translation}`,
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com)\/([A-Za-z0-9-_]+)/
    },
    {
      name: `${_Twitter_social_media_links_translation}`,
      placeholder: `${_twitter_url_social_media_links_translation}`,
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)/
    },
    {
      name: `${_Instagram_social_media_links_translation}`,
      placeholder: `${_instagram_url_social_media_links_translation}`,
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/
    },
    {
      name: `${_Youtube_social_media_links_translation}`,
      placeholder: `${_youtube_url_social_media_links_translation}`,
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:youtube.com|youtu.be)\/([A-Za-z0-9-_]+)/
    }
  ];

  const handleChange = (value, fieldName, regex) => {
    let validate = false;
    if (value !== '') {
      if (value.match(regex)) {
        validate = true;
        let cuserrors = cerror;
        delete cuserrors[fieldName];
        setCerror({ ...cuserrors });
      } else {
        validate = false;
        let cuserrors = cerror;
        setCerror({ ...cuserrors, [fieldName]: `Invalid ${fieldName} URL ${value}` });
      }
    } else {
      let cuserrors = cerror;
      delete cuserrors[fieldName];
      setCerror({ ...cuserrors });
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="acc-settings-form"
        className="account-settings-form"
      >
        <div className="row">
          {/* Social links */}
          {socialFields.map(({ name, placeholder, regex, invalidMsg }) => (
            <div className="col-6">
              <div className="form-row">
                <label htmlFor={name.toLowerCase()}>{name}</label>
                {name === 'Discord' ? (
                  <>
                    <input
                      ref={register({
                        validate: (value) =>
                          !value.match(regex) && value !== ''
                            ? invalidMsg || `Invalid ${name} URL`
                            : true
                      })}
                      type="text"
                      id={name.toLowerCase()}
                      name={name.toLowerCase()}
                      autoComplete="off"
                      className={`form-input ${errors[name.toLowerCase()] ? 'invalid' : ''}`}
                      defaultValue={user.state[name.toLowerCase()]}
                      placeholder={placeholder}
                    />
                    {errors[name.toLowerCase()] && (
                      <div className="form-input-error">{errors[name.toLowerCase()].message}</div>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      id={name.toLowerCase()}
                      name={name.toLowerCase()}
                      // autoComplete="off"
                      className={`form-input ${cerror[name.toLowerCase()] ? 'invalid' : ''}`}
                      defaultValue={user.state[name.toLowerCase()]}
                      placeholder={placeholder}
                      onBlur={(e) => handleChange(e.target.value, name.toLowerCase(), regex)}
                    />
                    {Object.keys(cerror).length > 0 && cerror[name.toLowerCase()] && (
                      <div className="form-input-error">{cerror[name.toLowerCase()]}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="form-row">
          <button
            className="button primary"
            disabled={Object.keys(cerror).length > 0 ? true : false}
            type="submit"
          >
            {/* Save Changes */}
            {saveChangesTrans}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MySocials;
