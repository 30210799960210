import React, { useState } from 'react';
import TournamentItem from '../../_common/TournamentItem/TournamentItem';
import moment from 'moment';
import { FaFolder } from 'react-icons/fa';
import ShareOptions from '../../_common/ShareOptions/ShareOptions';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetitions } from '../../../redux/action/competitionActions';
import ShowRecentfolders from './ShowRecentfolders';
import ListViewRecent from './listviewRecent';
import { SimplePageWithTabs } from '../../_common';

const Recents = () => {
  var isRecent=true
  const [RenameShareDeleteMouseenter, setRenameShareDeleteMouseenter] = useState(false);
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getCompetitions())
  },[])

  // var today = new Date();
  // const rDiff = moment(today).diff(moment());
  // const rDiffDuration = moment.duration(rDiff);
  // const _rDate = moment(today);
  // let rString = _rDate.format('DD MMM YYYY');
  // if (rDiffDuration.days() === -1) rString = 'Yesterday';
  // if (rDiffDuration.days() === 1) rString = 'Tomorrow';
  // if (rDiffDuration.days() === 0) rString = 'Today';
  // let reg_date =
  //   rString + ' at ' + _rDate.format('HH:mm') + ' ' + moment.tz(moment.tz.guess()).zoneAbbr();
  const currentDate = moment().format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  const competitions = useSelector((state)=> state.FOLDERS.Competitions ? state.FOLDERS.Competitions : [])
  const alldata = [];

  const iscompetitions = () => {
 

    return competitions?.map((organizer) => {
      alldata.push({
        ...organizer,
        iscompetition: true,
        timedifference: moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours'),
        whichday:
          moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 25
            ? 'today'
            : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 169
            ? 'Earlier this week'
            : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 720
            ? 'Earlier this month'
            : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 8760
            ? 'Earlier this year'
            : 'Older'
      });
    });
  };
  iscompetitions();
  let tabs = {
    'Grid View': (
      <>
        <ShowRecentfolders isRecent={isRecent} alldata={alldata}  />
      </>
    ),
    'List View': (
      <>
        <ListViewRecent isRecent={isRecent} alldata={alldata} />
      </>
    )
  };
    // const timeZone = useSelector((state) => state.TIMEZONE);
    // const getDate=(date)=>{
    //     if(!timeZone){
    //     return date
    //     }
    //     else {
    //       return moment(date).tz(timeZone?.timeZone)
    //       }
    //      }
    
  return (
    <>
    <div>
      <SimplePageWithTabs title="Recent" tabs={tabs} />
    </div>
  </>
    // <>
    //   <div style={{ marginLeft: '10px' }}>
    //     <h1>Recents</h1>
    //     <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
    //     {competitions.map((competition, index) => {
    //       return(
    //       <>
    //       <div style={{ marginLeft: '10px' }}>
    //       <SimplePageWithTabs title="Trash" tabs={tabs} />
    //     </div>
    //     </>
    //       )
    //       //  <TournamentItem
    //       //          key={index}
    //       //          reg_date={ moment(getDate(competition.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss')? 'Last edited: ' + moment(getDate(competition.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss') + ' by Fredrik Hockauf' : undefined}
    //       //         //  title={competition.name}
    //       //         title={
    //       //           <div style={{ display: 'flex', flexDirection: 'row' }}>
    //       //             <span class="imgLogoContainer">
    //       //               <img
    //       //                 class=" imgLogoWidth imgLogo  imgLogoCropped"
    //       //                 src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
    //       //                 alt="Insight Gaming"
    //       //                 width={'10px'}
    //       //               />
    //       //               {competition.name}
    //       //             </span>
    //       //             <div style={{ marginLeft: '180px' }}>
    //       //             <ShareOptions />
    //       //             </div>
    //       //           </div>
    //       //         }
    //       //          image={competition.thumbnail}
    //       //          logo={competition.logo}
    //       //          subtitle={
    //       //           <div style={{ display: 'flex', flexDirection: 'row' }}>
    //       //             <FaFolder /> <span>{competition.name}</span>
    //       //           </div>
    //       //         }
    //       //        />
              
    //         })}

    //     </div>
    //   </div>
    // </>
  );
};
export default Recents;
