import React, { useRef, useState, useEffect } from 'react';


const SimpleTabs = ({ page, setPage, pages, unSavedStages, styles,disabledIndexes=[] ,modifiedTabs=[]}) => {
  const [newPages, setNewPages] = useState([]);
  const [dropDownPages, setDropDownPages] = useState([]);
  useEffect(() => {
    if (pages.length > 3) {
      const [a, b, ...rest] = pages;
      setNewPages([a, b]);
      setDropDownPages([...rest]);
    } else {
      setNewPages(pages);
    }
  }, [pages]);
  const containerRef = useRef(null);
  const elemRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    setIsScrollable(containerRef.current.scrollWidth > containerRef.current.clientWidth);
  });

  return (
    <div className="tabs-container simple__tabs__container" style={{ height: '35px', ...styles }}>
      <div ref={containerRef} className="tabs-nav mt-4 sub-tabs" style={{ background: 'none' }}>
        <nav ref={elemRef}>
          {/* {newPages.map((item, index) => ( */}
          {pages.map((item, index) => {
            let isDisabled = false;
            if(disabledIndexes.includes(index)){
              isDisabled = true;
            }
            return(
            <button
              disabled={isDisabled}
              className={page === index ? 'active' : ''}
              onClick={(e) => {
                e.preventDefault();
                setPage(index);
              }}
              key={index}>
              {/* {item} */}
              <div
                className={
                  (unSavedStages?.length > 0 && unSavedStages.findIndex((e) => e === index) !== -1 || modifiedTabs.includes(item))
                    ? 'unsaved-stage'
                    : ''
                }>
                {item}
              </div>
            </button>
          )})}
          {/* {pages.length > 3 && (
            <select
              onClick={(e) => {
                
                e.preventDefault();
                if(e.target.value === '') return;
                setPage(parseInt(e.target.value));
              }}
              name="homeTeam"
              className={'form-input black-bg'}
              style={{
                padding: '0',
                // top: '-6px',
                fontSize: '14px',
                color: '#68717e'
              }}
            >
              <option value="">
                Select Best Of
              </option>
              {dropDownPages.map((page, i) => (
                <option key={i} value={i + newPages.length}>
                  {page}
                </option>
              ))}
            </select>
          )} */}
          {/* <div className="ellipses-btn">{isScrollable && <button onClick={() => {}}>{"..."}</button>}</div> */}
        </nav>
      </div>
    </div>
  );
};

export default SimpleTabs;
