import React, { useState, useEffect, useMemo } from 'react';
import './generate-matches-modal.scss';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import StageAndGroupPage from './Pages/StageAndGroupPage/StageAndGroupPage';
import MatchSettingsPage from './Pages/MatchSettingsPage/MatchSettingsPage';
import WeeklySchedulePage from './Pages/WeeklySchedulePage/WeeklySchedulePage';
import { getConvertedDate, getFormattedDate, getTimeInUTC, getUtcFormatDate } from './genHelpers';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getCompetitionsMatches } from '../../../redux/action/CompetitonData';
import { isEmpty } from 'lodash';
function GenerateMatchesModal({ open, onClose, competition, stageIndex, groupIndex }) {
  const [generating, setGenerating] = useState(false);
  const [Step, setStep] = useState(0);
  const [Titles, setTitles] = useState(['Stage and Groups', 'Match Settings', 'Weekly Schedule']);
  const [stage, setStage] = useState({});
  const [maxGroup, setMaxGroup] = useState({});
  const [hideStage, setHideStage] = useState(false);
  const [hideGroup, setHideGroup] = useState(false);
  const [gameCover, setGameCover] = useState(
    competition?.game?.overViewPageBanner ?? 'https://www.leagues.gg/static/media/ScrimFinderBanner.dc39825d.jpg'
  );
  const [shortBar, setShortBar] = useState(false);
  const [startDate, setStartDate] = useState('');

  const [payload, setPayload] = useState({
    stage: false,
    groups: [],
    startDate: '',
    alternateSides: false,
    broadcasted: false,
    sideSections: false,
    matchDates: [
      {
        day: 'Monday',
        time: '2022-12-25T05:08:38.000Z'
      }
    ],
    bestOf: 0,
    durationWeeks: 1,
    sideSelection: false,
    startDate: '2023-01-12T18:00',
    scoreSystem: false,
    weekSchedule: [],
    matchPerWeek: 'once',
    matchesPerWeek: false
  });
  useEffect(() => {
    // console.log("COmpetiton",competition.timezone)
    if (competition && competition?.timezone) {
      setPayload((payload) => ({ ...payload, timezone: competition.timezone }));
    }
  }, [competition.timezone]);
  // useEffect(()=>{
  //   console.log('payload999=>',payload);
  //   // debugger
  //   console.log('payload satrt date=>',payload.startDate);
  // },[payload]);
  useEffect(() => {
    if (competition?.game?.cover) {
      setGameCover(competition?.game.cover);
    }
    let _startDate = competition.startedDate ?? '2023-01-12T18:00';
    let _day = new Date(_startDate).getDay();
    if (_day === 0 || _day === 6) {
      // If startDate is Sunday or Saturday so we need to moke it monday or something
      let newStartDate = new Date(_startDate);
      newStartDate.setDate(newStartDate.getDate() + ((_day + 1) % 5));
      _startDate = getFormattedDate(newStartDate);
    }
    setPayload((payload) => ({
      ...payload,
      startDate: competition.startedDate ? competition.startedDate : '2023-01-12T18:00',
      startDate: _startDate
    }));
  }, [competition]);
  // useEffect(() => {
  //   console.log({ payload });
  // }, [payload]);
  useEffect(() => {
    ///////*****************////////////
    // THIS CODE IS FOR DEFAULT STAGE AND GROUP SELECTION (THIS USEEFFECT)
    ///////***************/////////////
    // console.log({stageIndex,groupIndex})
    if (competition) {
      if (competition.stages.length === 1) {
        setHideStage(true);
        let _stage = competition.stages[0];
        setStage(_stage);
        setPayload((payload) => ({ ...payload, stage: _stage._id }));
        if (_stage.groups.length === 1) {
          setPayload((payload) => ({ ...payload, groups: [_stage.groups[0]._id] }));
          setShortBar(true);
          setStep(1);
        }
      }
    }
    if (stageIndex > 0) {
      let _stageIndex = stageIndex - 1; /// becase 0 is for all groups and actual stage index is always n-1
      let _stage = competition.stages[_stageIndex];
      setStage(_stage);
      setHideStage(true);
      setPayload((state) => ({ ...state, stage: _stage?._id }));
      if (_stage.groups.length === 1) {
        setPayload((state) => ({ ...state, groups: [_stage.groups[0]._id] }));
        setShortBar(true);
        setStep(1);
      }
    }
    if (groupIndex > 0) {
      let _groupIndex = groupIndex - 1; /// becase 0 is for all groups and actual group index is always n-1
      let groupId = competition?.stages[stageIndex - 1]?.groups[_groupIndex]?._id;

      if (groupId) {
        setHideGroup(true);
        setPayload((state) => ({ ...state, groups: [groupId] }));
      }
    }
    if (stageIndex > 0 && groupIndex > 0) {
      setShortBar(true);
      setStep(1);
    }
  }, [stageIndex, groupIndex]);
  // useEffect(()=>{
  //   console.log('stageIndex=>',stageIndex);
  //   console.log('groupIndex=>',groupIndex);
  //   console.log('competitions=>',competition);
  //   // debugger
  // },[stageIndex,groupIndex,competition])
  useEffect(() => {
    if (competition) {
      let _stage = competition.stages.find((stage) => stage._id === payload.stage);
      if (_stage) {
        if (_stage.groups.length === 1) {
          setHideGroup(true);
          setPayload((state) => ({ ...state, groups: [_stage.groups[0]._id] }));
        } else {
          if (!groupIndex) {
            setHideGroup(false);
            let _groups = _stage.groups.map((group) => group._id);
            setPayload((state) => ({ ...state, groups: _groups }));
          }
        }
      }
    }
  }, [payload.stage]);
  const dispatch = useDispatch();
  const matchCountCalculator = () => {
    let x = 0,
      y = 0,
      z = 0,
      power = 1;
    if (payload.stage && payload.groups.length > 0) {
      let _stage = competition.stages.find((stage) => stage._id === payload.stage);
      payload.groups.forEach((groupId) => {
        let group = stage.groups.find((group) => group._id === groupId);
        x += group.noOfTeams;
        if (y < group.noOfTeams) {
          y = group.noOfTeams;
          setMaxGroup(group);
        }
        z += (group.noOfTeams / 2) * (group.noOfTeams - 1);
      });
      y = y + (-1 + (y % 2));

      // handling bracket case
      if (typeof _stage?.bracketType !== 'undefined') {
        z = (_stage.teamSize - 1) * payload.groups.length
        y = Math.ceil(Math.log2(_stage.teamSize))
      }
    }
    return { teams: x, rounds: y, matches: z };
  };
  const obj = useMemo(matchCountCalculator, [payload]);
  useEffect(() => {
    // console.log({ payload });
    if (payload.stage && payload.stage !== '') {
      let _stage = competition.stages.find((stage) => stage._id === payload.stage);
      setStage(_stage);
    }
  }, [payload]);
  const handleGenerateMatches = async () => {
    if (payload) {
      // toast.info('Generating Matches');
      setGenerating(true);
      let _payload
      if (stage.bracketType) {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competition._id}/matches/GetBracketData/${stage._id}`, {generateLibMatches: false, bestOf: payload.bestOf.toString()})
        .then(({ data }) => {
        _payload = {stage: stage._id, StageBracketMatches: data?.StageBracketMatches, broadcasted: payload.broadcasted, roundDates: payload.weekSchedule.map((match) => ({
          day: match.day,
          time: getTimeInUTC(match.date, match.time, payload.timezone)
        }))}
        if (payload.broadcasted) _payload.broadCastedChannel = payload.broadCastedChannel
      }).catch(error => toast.error(error.message))
      } else {
        _payload = {
        stage: payload.stage,
        groups: payload.groups,
        startDate: getUtcFormatDate(payload.startDate, payload.timzone),
        alternateSides: payload.alternateSides,
        broadcasted: payload.broadcasted,
        sideSelection: payload.sideSelection,
        matchDates: [
          {
            day: 'Monday',
            time: '2022-12-25T05:08:38.000Z'
          }
        ],
        bestOf: payload.bestOf.toString(),
        durationWeeks: payload.durationWeeks,
        scoreSystem: payload.scoreSystem,
        weekSchedule: payload.weekSchedule.map((match) => ({
          day: match.day,
          time: getTimeInUTC(match.date, match.time, payload.timezone)
        }))
      };
      if (payload.broadcasted) _payload.broadCastedChannel = payload.broadCastedChannel
    }
      Axios.post(
        `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competition._id}/matches/generateSeedingMatches?isVeto=false`,
        _payload
      )
        .then((res) => {
          let query = { status: '', limit: '100', page: '1' };
          setGenerating(false);
          dispatch(getCompetitionsMatches({ id: competition._id, query }));
          res.status === 200
            ? toast.success('Matches Generated Successfully')
            : toast.error('Something went wrong');
          onClose(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Something went wrong');
          onClose(false);
          setGenerating(false);
        });
    }
  };
  const Styles = {
    header: {
      background: 'transparent 0% 0% no-repeat padding-box',
      backgroundPosition: 'center',
      backgroundImage: `linear-gradient(180deg, #171A2280 0%, #171A22 100%), url(${gameCover})`,
      borderRadius: '4px 4px 0px 0px',
      opacity: '0.5',
      height: '115px',
      width: '100%',
      position: 'absolute'
    },
    pill: {
      marginTop: '10px'
    },
    progress: {
      marginTop: '115px'
    },
    firstDot: {
      flexDirection: 'row'
    }
  };
  const steps = [
    <StageAndGroupPage
      payload={payload}
      stage={stage}
      hideStage={hideStage}
      hideGroup={hideGroup}
      setPayload={setPayload}
      competition={competition}
    />,
    <MatchSettingsPage
      payload={payload}
      stage={stage}
      maxGroup={maxGroup}
      setPayload={setPayload}
      competition={competition}
      tag={obj}
      // startDate={startDate}
    />,
    <WeeklySchedulePage
      payload={payload}
      stage={stage}
      setPayload={setPayload}
      competition={competition}
      tag={obj}
      setStartDate={setStartDate}
      startDate={startDate}
      // startDate={startDate}
    />
  ];
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        onClose(false);
      }}
      className="modal generate-matches-modal"
      shouldCloseOnEsc={false}>
      <div>
        <div
          className="modal-close"
          style={{ zIndex: 1, marginRight: '5px' }}
          onClick={() => onClose(false)}>
          <RiCloseLine color={'white'} />
        </div>
        <div className="modal-header" style={Styles.header}>
          <Row className="header-pill" style={Styles.pill}>
          
          {stage.stageType!="tournament"?  <span>
              Round Robin -{' '}
              {stage?.encounters
                ? stage?.encounters <= 1
                  ? `${stage.encounters} Encounter`
                  : `${stage.encounters}  Encounters`
                : '0 Encounters'}
            </span>:<span>single elimination</span> }
          </Row>
          <h2 className="bold uppercase">Generate Matches</h2>
        </div>
        <Row className="main-progress-row" style={Styles.progress}>
          {steps.map((page, ind) => {
            if (shortBar && ind === 0) {
              return;
            }
            return (
              <Col className="single-prgress-container" key={ind}>
                <Row className="dot-and-line" style={ind === 0 ? Styles.firstDot : {}}>
                  {ind === 1 && (
                    <div
                      id="one"
                      className={Step > ind ? 'progress-line-bright' : 'progress-line-dull'}
                    />
                  )}
                  <div
                    id="1.5"
                    className={Step >= ind ? 'progress-dot-bright' : 'progress-dot-dull'}></div>
                  {
                    <div
                      id="two"
                      style={shortBar && ind === 1 ? { visibility: 'hidden' } : {}}
                      className={
                        Step >= ind && Step !== 0 ? 'progress-line-bright' : 'progress-line-dull'
                      }
                    />
                  }
                </Row>
                <Row>
                  <div
                    id="three"
                    className={Step >= ind ? 'progress-text-bright' : 'progress-text-dull'}>
                    {Titles[ind]}
                  </div>
                </Row>
              </Col>
            );
          })}
        </Row>

        <div className="modal-body E129">
          {steps.map((Component, i) => i === Step && <div key={i}>{Component}</div>)}
        </div>
        <div className="modal-footer generate-matches-footer">
          <Row className="footer-buttons">
            <div>
              {(Step > 0 ) ? (
                (competition?.stages.length === 1) ?( 
                  (stageIndex === 0 && Step === 1)?(''):(
                    <button
                        className="back-page-btn"
                        onClick={() => {
                          if (Step === 1) {
                            if (!hideGroup && !hideStage) {
                              setStep(Step - 1);
                            }
                          } else {
                            setStep(Step - 1);
                          }
                        }}>
                        <span>BACK</span>
                      </button>
                  ) 
                ):(stageIndex === 0 && Step >= 1)?(
                      <button
                        className="back-page-btn"
                        onClick={() => {
                          if (Step === 1) {
                            if (!hideGroup && !hideStage) {
                              setStep(Step - 1);
                            }
                          } else {
                            setStep(Step - 1);
                          }
                        }}>
                        <span>BACK</span>
                      </button>):(stageIndex > 0 && Step >= 1 && payload.groups.length > 1)? (<button
                        className="back-page-btn"
                        onClick={() => {
                          if (Step === 1) {
                            if (!hideGroup && !hideStage) {
                              setStep(Step - 1);
                            }
                          } else {
                            setStep(Step - 1);
                          }
                        }}>
                        <span>BACK</span>
                      </button>):(stageIndex > 0 && Step > 1)?(<button
                        className="back-page-btn"
                        onClick={() => {
                          if (Step === 1) {
                            if (!hideGroup && !hideStage) {
                              setStep(Step - 1);
                            }
                          } else {
                            setStep(Step - 1);
                          }
                        }}>
                        <span>BACK</span>
                      </button>):('')
                                 
              ): ('')
              // (stageIndex > 0 && Step > 1)
              // ? (
              //   <button
              //     className="back-page-btn"
              //     onClick={() => {
              //       if (Step === 1) {
              //         if (!hideGroup && !hideStage) {
              //           setStep(Step - 1);
              //         }
              //       } else {
              //         setStep(Step - 1);
              //       }
              //     }}>
              //     <span>BACK</span>
              //   </button>
              // ): ('')
              }
              {/* {(Step > 0 && stageIndex === '0' ) ? (
                <button
                  className="back-page-btn"
                  onClick={() => {
                    if (Step === 1) {
                      if (!hideGroup && !hideStage) {
                        setStep(Step - 1);
                      }
                    } else {
                      setStep(Step - 1);
                    }
                  }}>
                  <span>BACK</span>
                </button>
              ): (stageIndex > 0 && Step > 1) ? (
                <button
                  className="back-page-btn"
                  onClick={() => {
                    if (Step === 1) {
                      if (!hideGroup && !hideStage) {
                        setStep(Step - 1);
                      }
                    } else {
                      setStep(Step - 1);
                    }
                  }}>
                  <span>BACK</span>
                </button>
              ): ('')} */}
            </div>
            <div>
              {Step <= 1 ? (
                <button
                  className="next-page-btn"
                  onClick={() => {
                    if (Step === 0) {
                      if (payload.stage && payload.groups.length > 0) {
                        setStep(Step + 1);
                        return;
                      }
                      toast.error('Please Enter Required Fields');
                    } else if (Step === 1) {
                      if (
                        payload?.startDate &&
                        payload?.timezone &&
                        payload?.bestOf &&
                        payload?.scoreSystem
                      ) {
                        setStep(Step + 1);
                        return;
                      }
                      toast.error('Please Enter Required Fields');
                    }
                  }}>
                  <span>Next</span>
                </button>
              ) : (
                <button
                  className="next-page-btn"
                  disabled={generating}
                  onClick={() => {
                    handleGenerateMatches();
                  }}>
                  <span>{generating ? 'Generating Matches' : 'Generate Matches'}</span>
                </button>
              )}
            </div>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default GenerateMatchesModal;
