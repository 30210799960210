import { SET_LOADING } from '../action/types';
const initialState = {
  Loading: false
};

// Use the initialState as a default value
export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        Loading: action.payload
      };
    default:
      return state;
  }
}
