import React, { useState, useEffect, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Footer from './Footer/Footer';
import { AuthModal } from '../_modals/AuthModal/AuthModal';
import 'react-toastify/dist/ReactToastify.css';
// import GameKeys from "./GameKeys/GameKeys";
import { AppContext } from '../../context/AppContext';
import verifyToken from '../../context/utils/verifyToken';
import moment from 'moment-timezone';
import { Timer, Icon } from '@common';
import Axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { ConnectToSteamModal } from '../_modals/ConenctToSteamModal';
import { FiX } from 'react-icons/fi';

const Layout = ({ children }) => {
  const { authenticated } = useContext(AppContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [nextVeto, setNextVeto] = useState(null);
  const history = useHistory();
  const nextVetoChecker = React.createRef();
  const vetoToast = React.createRef();
  const [bannerContent, setBannerContent] = useState(null);
  const [needPlayerSetting, setNeedPlayerSetting] = useState(null);
  const [scrim, setScrim] = useState();
  const search = useLocation().search;
  const sideBar = new URLSearchParams(search).get("side");
  useEffect(()=>{
    if(sideBar){
      setSidebarOpen(sideBar)
    }
  },[sideBar])
  useEffect(() => {
    const validTokenData = verifyToken();
    if (validTokenData) {
      Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/veto/isVetoReady`)
        .then(({ data }) => {
          if (data) {
            data.match =
              data.match && data.match.length && data.match[0] ? data.match[0] : data.match;
            if (!data.vetoStartTime) data.vetoStartTime = 900;
            setNextVeto(data);
            setScrim(data.match.scrim);
          }
        })
        .catch(() => {
          // toast.error("There was a problem retrieving your matches");
        });
    }
  }, [authenticated]);
  const showVetoStartToast = () => {
    if (
      !(nextVeto.match?.scrim?.numberOfMatches == 1 && nextVeto.match?.scrim?.map !== 'Veto') &&
      nextVeto
    ) {
      const timeLeft = moment(nextVeto.match.datetime).diff(moment(), 'seconds');
      const _nextVetoTime = moment().add(timeLeft, 'seconds');

      vetoToast.current = toast(
        <>
          <Timer
            onTimeComplete={() => toast.dismiss(vetoToast.current)}
            fontSize={13}
            time={_nextVetoTime}
            name="clock"
          >
            - VETO OPEN :{' '}
            {String(
              nextVeto.match?.league?.name || nextVeto.match?.tournament?.name || 'SCRIM MATCH'
            ).toUpperCase()}
          </Timer>
        </>,
        {
          toastId: 'timer-toast-veto',
          autoClose: false,
          closeButton: (
            <span
              onClick={(e) => {
                e.stopPropagation();
                toast.dismiss(vetoToast.current);
              }}
              className="veto-timer-alert veto-close-btn"
            >
              <Icon name="cross" style={{ width: 20, height: 20 }}></Icon>
            </span>
          ),
          onClick: () => history.push(`/match/${nextVeto.match._id}/veto`),
          type: toast.TYPE.DEFAULT,
          closeOnClick: false,
          style: { minHeight: 0, padding: '10px 10px', background: '#56b85b', color: 'black' },
          hideProgressBar: true,
          position: 'bottom-right',
          className: 'veto-timer-alert'
        }
      );
    }
  };

  const setStartVetoTimer = () => {
    nextVetoChecker.current = setInterval(() => {
      const timeLeft = moment(nextVeto.match.datetime).diff(moment(), 'seconds');
      if (timeLeft < 900) {
        clearInterval(nextVetoChecker.current);
        if (timeLeft > 0) showVetoStartToast();
      }
    }, 2000);
  };
  const showVetoToast = () => {
    if (
      !(nextVeto.match?.scrim?.numberOfMatches === 1 && nextVeto.match?.scrim?.map !== 'Veto') &&
      nextVeto
    ) {
      const timeLeft =
        moment(nextVeto.match.datetime).diff(moment(), 'seconds') - nextVeto.vetoStartTime;
      const _nextVetoTime = moment().add(timeLeft, 'seconds');
      vetoToast.current = toast(
        <>
          <Timer
            onTimeComplete={() => {
              toast.dismiss(vetoToast.current);
              showVetoStartToast();
            }}
            fontSize={13}
            time={_nextVetoTime}
            name="clock-white"
          >
            UNTIL VETO FOR{' '}
            {String(
              nextVeto.match?.league?.name || nextVeto.match?.tournament?.name || 'SCRIM MATCH'
            ).toUpperCase()}{' '}
            OPENS
          </Timer>
        </>,
        {
          toastId: 'timer-toast-veto',
          autoClose: false,
          closeButton: (
            <span
              onClick={(e) => {
                e.stopPropagation();
                toast.dismiss(vetoToast.current);
                setStartVetoTimer();
              }}
              className="veto-timer-alert veto-close-btn"
            >
              <Icon name="cross-white" style={{ width: 20, height: 20 }}></Icon>
            </span>
          ),
          onClick: () => history.push(`/match/${nextVeto.match._id}/veto`),
          type: toast.TYPE.DEFAULT,
          closeOnClick: false,
          style: {
            minHeight: 0,
            padding: '10px 10px',
            background: 'var(--primary)',
            color: 'white'
          },
          hideProgressBar: true,
          position: 'bottom-right',
          className: 'veto-timer-alert'
        }
      );
    }
  };

  useEffect(() => {
    if (nextVeto && authenticated) {
      const { _REACT_APP_VETO_DEADLINE_TIME = 3600 } = process.env;
      const REACT_APP_VETO_DEADLINE_TIME = Number(_REACT_APP_VETO_DEADLINE_TIME);
      const _timeLeft =
        moment(nextVeto.match.datetime).diff(moment(), '  seconds') - nextVeto.vetoStartTime;
      if (_timeLeft < REACT_APP_VETO_DEADLINE_TIME) {
        if (_timeLeft > 0) showVetoToast();
      } else
        nextVetoChecker.current = setInterval(() => {
          const timeLeft =
            moment(nextVeto.match.datetime).diff(moment(), 'seconds') - nextVeto.vetoStartTime;
          if (timeLeft < REACT_APP_VETO_DEADLINE_TIME) {
            clearInterval(nextVetoChecker.current);
            if (timeLeft < 0) showVetoStartToast();
            else showVetoToast();
          }
        }, 2000);
    }
    if (!authenticated) toast.dismiss(vetoToast.current);
  }, [nextVeto, authenticated]);

  const hideBannerBars = (type) => {
    if (type === 'banner_content') {
      setBannerContent(null);
    } else {
      setNeedPlayerSetting(null);
    }

    localStorage.setItem([type], 'hide');
  };

  const showBannerBars = (type) => {
    const bannerStatus = localStorage.getItem([type]);

    if (bannerStatus === null || (bannerStatus && bannerStatus !== 'hide') || needPlayerSetting) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const bannerStatus = localStorage.getItem(['banner_content']);
    if (bannerStatus === null || (bannerStatus && bannerStatus !== 'hide')) {
      // setBannerContent(
      //   "Leagues.gg is still under development. <a target='_blank' href='https://leagues.gg/news/stillunder-contruction'>Follow the process here.</a>"
      // );
    }
  }, [setBannerContent]);

  useEffect(() => {
    const validTokenData = verifyToken();
    if (validTokenData) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/matches/needToSetPlayersSoon`)
        .then(({ data }) => {
          if (data) {
            setNeedPlayerSetting(
              "Less than a day left to set players. Do it here. <a href='/your-matches'>Go to your matches.</a>"
            );
          } else {
            hideBannerBars('warning_content');
          }
        })
        .catch(() => {
          // toast.error("There was a problem retrieving your matches");
        });
    } else {
      hideBannerBars('warning_content');
    }
  }, [authenticated]);

  return (
    <div
      className={
        (showBannerBars('banner_content') || showBannerBars('warning_content')) &&
        (bannerContent !== null || needPlayerSetting !== null)
          ? 'App has_banner_content'
          : 'App'
      }
    >
      {showBannerBars('banner_content') && bannerContent !== null ? (
        <div className="header-banner-bar">
          <span
            className="text-12 bold uppercase"
            dangerouslySetInnerHTML={{ __html: bannerContent }}
          ></span>
          <span
            className="text-12 bold uppercase"
            style={{ position: 'absolute', right: '25px' }}
            onClick={() => hideBannerBars('banner_content')}
          >
            <FiX style={{ fontWeight: 'bold', fontSize: '27px' }} />
          </span>
        </div>
      ) : null}

      {showBannerBars('warning_content') && needPlayerSetting !== null ? (
        <div className="header-banner-bar warningBar">
          <span
            className="text-12 bold uppercase"
            dangerouslySetInnerHTML={{ __html: needPlayerSetting }}
          ></span>
          <span
            className="text-12 bold uppercase"
            style={{ position: 'absolute', right: '25px' }}
            onClick={() => hideBannerBars('warning_content')}
          >
            <FiX style={{ fontWeight: 'bold', fontSize: '27px' }} />
          </span>
        </div>
      ) : null}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        pauseOnFocusLoss
        style={{ width: 'max-content', maxWidth: '640px' }}
      />
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* <AuthModal /> */}

      <div className="global-main">
        <Sidebar open={sidebarOpen} />

        <div className="global-content">
          {children}

          <Footer />
        </div>
      </div>

      {/* those game key popup things for players who have games soon */}
      {/* {authenticated && <GameKeys />} */}
      {authenticated && <ConnectToSteamModal />}
    </div>
  );
};

export default Layout;
