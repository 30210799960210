import React from 'react';
import { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import Newfolder from '../../_modals/OrganizerPopup/newfolder/newfolder';
import './_folder.scss'
const AddNewFolder = ({ OrganizaionId, parentId, setAddnewfolder,isLastView }) => {
  const [isopen, setIsopen] = useState();
  const handleChange = () => {
    setIsopen(true);
  };
  return (
    <>
      <div className={isLastView ? 'card-x': "card-panel newfolder"} /* style={{ border: '1px dashed #1c202a' }} */ onClick={handleChange}>
        <div style={{ display:'flex',alignItems:'center',fontSize:'9px' }} className='add-folder-btn-gaped-border'>
        <svg className="dotted-svg-1" width="280px" height="50px" style={{margin:'0px 0px 0px -20px'}} xmlns="http://www.w3.org/2000/svg">
                  <rect
                    width="100%"
                    height="100%"
                    fill="none"
                    rx="8"
                    ry="8"
                    stroke="%233EABF0FF"
                    stroke-width="1"
                    stroke-dasharray="5, 5, 5"
                    stroke-dashoffset="0"
                    stroke-linecap="round"
                  />
                </svg>

          {/* <BiPlus style={{ fontSize:isLastView?'14px':'1.5em', marginBottom:isLastView?'-2px': '-5px',marginRight:'4x'}} /> */}
          <BiPlus style={{ fontSize:isLastView?'14px':'1.5em', marginBottom:isLastView?'-2px': '0px',marginRight:'4x'}} />
          <strong style={{fontSize:isLastView?'15px':'',fontWeight:isLastView?'600':''}}>Add Folder</strong>
        </div>
      </div>
      <Newfolder
      isLastView={isLastView}
        setAddnewfolder={setAddnewfolder}
        open={isopen}
        onClose={setIsopen}
        OrganizaionId={OrganizaionId}
        parentId={parentId}
      />
    </>
  );
};
export default AddNewFolder;
