// import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { TiArrowUnsorted } from 'react-icons/ti';
// import moment from 'moment';
import { Calendar, Checkbox } from '../../../../_common';
import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import { Button, OverlayTrigger, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../../../context/AppContext';
import FilterMatchesPopup from '../../../../_modals/FilterMatchesPopup/FilterMatchesPopup';
import { getCompetitionsMatches, updateMatchStatus } from '../../../../../redux/action/CompetitonData';
import EditVetoModal from './EditVetoModal/EditVetoModal';
import {
  BsFillCameraVideoFill,
  BsSquareHalf,
  BsFillUnlockFill,
  BsFillLockFill
} from 'react-icons/bs';

import { useSelector } from 'react-redux';
import {
  faEye,
  faPencilAlt,
  faTrashAlt,
  faUsers,
  faWrench,
  faGamepad
} from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import { useEffect } from 'react';
import {
  getCompetition,
  getCompetitionsData,
  getFilterMatches
} from '../../../../../redux/action/CompetitonData';
import { RiSearchLine } from 'react-icons/ri';
import { FiX } from 'react-icons/fi';
import { groupByRoundfubt } from './GroupBy';
import SetScoreModal from '../../../../_modals/SetScoreModal/SetScoreModal';
import useRoles from '../../../../../utils/useRoles';
import {
  checkEditMatchDisabled,
  checkSetScoreDisabled,
  checkSetPlayersDisabled
} from '../../utils';
import GeneratMatchButton from '../../../Competition/generateMatchModal.js/GenerateMatchButton';
import MatchSetPlayersModal from './MatchSetPlayersModal';
import MatcheRescheduleModal from './lockReschedule';
import { useParams } from 'react-router-dom';
import EditMatchPopup from './EditMatch/EditMatch';
import { filterMatches } from './matchUtlis';
import GenerateCodes from '../../../../_modals/GenerateCodes/GenerateCodes';
import { HiCheckCircle, HiCode } from 'react-icons/hi';
import MatchesSelectionBar from './MatchesSelectionBar';
import { Loading } from '../../../../_common';
const MatchesTab = ({
  groupIndex,
  stageIndex,
  userPermissions,
  competitionData,
  setFilterMatches,
  filterMatches
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const loadingState = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const filterQuery = useSelector((state) => state?.COMPETITION?.filterMatches);
  const [groupBy, setGroupBy] = useState(false);
  const game = useSelector((state) =>
    state?.COMPETITION?.Matches?.data?.game ? state.COMPETITION?.Matches?.data?.game : null
  );
  const [checkCompleteOrNot, setCheckCompleteOrNot] = useState({
    complete: true,
    upcomimg: true
  });
  const [bracketMathces, setBracketMatches] = useState(false);
  const [showCalendarView, setShowCalendarView] = useState(false);
  const [matchDataFromCalendar, setMatchDataFromCalendar] = useState(null);
  const [showEditMatchModal, setShowEditMatchModal] = useState(false);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState([]);
  const [selectAllMatches, setSelectAllMatches] = useState(false);
  const [matchesCount, setMatchesCount] = useState();
  const [inputText, setInputText] = useState('');
  const [showMatches, setShowMatches] = useState([]);
  const [sortedMatches, setSortedMatches] = useState([]);
  const [showGenerateMatch, setShowGenerateMatch] = useState(false);
  const [sortby, setSortBy] = useState('');
  const [bracketAndRRStages, setBracketAndRRStages] = useState(false);
  const matches = useSelector((state) => state.COMPETITION?.Matches?.data?.data);
  const [gameData, setGameData] = useState(null);
  const [Brac, setBrac] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(`${process.env.REACT_APP_FRONTEND_URL}`);
  const competition = useSelector((state) =>
    state.COMPETITION.Competition.multiStageLeague
      ? state.COMPETITION.Competition.multiStageLeague
      : false
  );
  useEffect(() => {
    if (matches?.length > 0 && stageIndex !== 0) {
      const temp = matches[stageIndex][0].stageDetails?.bracketType ? true : false;
      setBrac(temp);
    }
  }, [stageIndex, matches]);
  
  useEffect(() => {
    if (stageIndex === 0 && competitionData) {
      let _stages = competitionData.stages;
      let hasBoth = hasLeagueAndTournament(_stages);
      setBracketAndRRStages(hasBoth);
    }
  }, [competitionData, stageIndex]);
  
  const hasLeagueAndTournament = (arr) => {
    let hasLeague = false;
    let hasTournament = false;

    for (let item of arr) {
      if (item.league) {
        hasLeague = true;
      }
      if (item.tournament) {
        hasTournament = true;
      }
    }

    return hasLeague && hasTournament;
  };

  useEffect(() => {
    if (game) {
      setRedirectUrl((prevUrl) => {
        let _newUrl = prevUrl.replace(
          '.leagues',
          `-${game?.shortName?.toLowerCase() ?? 'lol'}.leagues`
        );
        return _newUrl;
      });
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${game._id}`).then(({ data }) =>
        setGameData(data)
      );
    }
  }, [game]);
  const Sort = (payload) => {
    if (sortby === '' || sortby !== payload) {
      if (payload === 't1') {
        setSortBy('t1');
      }
      if (payload === 't2') {
        setSortBy('t2');
      }
      if (payload === 'datetime') {
        setSortBy('datetime');
      }
      if (payload === 'week') {
        setSortBy('week');
      }
      if (payload === 'bestOf') {
        setSortBy('bestOf');
      }
      if (payload === 't1Score') {
        setSortBy('t1Score');
      }
      if (payload === 'status') setSortBy('status');
    } else {
      setSortedMatches(showMatches);
      setSortBy('');
    }
  };
  useEffect(() => {
    if (sortby.length > 1 && !groupBy) {
      let matches = [...showMatches];
      const sortedmatchess = matches.sort(function (a, b) {
        let nameA =
          sortby === 'datetime' ||
          sortby === 'week' ||
          sortby === 'bestOf' ||
          sortby === 'status' ||
          sortby === 't1Score'
            ? a[sortby]
            : a[sortby]?.name
            ? a[sortby]?.name.toUpperCase()
            : sortby === 't1'
            ? `Seed ${a?.sp1?.seedPosition}`.toUpperCase()
            : `Seed ${a?.sp2?.seedPosition}`.toUpperCase(); // ignore upper and lowercase
        let nameB =
          sortby === 'datetime' ||
          sortby === 'week' ||
          sortby === 'bestOf' ||
          sortby === 'status' ||
          sortby === 't1Score'
            ? b[sortby]
            : b[sortby]?.name
            ? b[sortby]?.name?.toUpperCase()
            : sortby === 't1'
            ? `Seed ${b?.sp1?.seedPosition}`.toUpperCase()
            : `Seed ${b?.sp2?.seedPosition}`.toUpperCase(); // ignore upper and lowercase

        // sort in an ascending order
        // if (nameA < nameB) {
        //   return -1;
        // }
        // if (nameA > nameB) {
        //   return 1;
        // }
        if (typeof nameA === 'number' || typeof nameB === 'number') {
          nameA += '';
          nameB += '';
        }
        return nameA?.localeCompare(nameB, undefined, {
          numeric: true,
          sensitivity: 'base'
        });

        // names must be equal
        // return 0;
      });
      setSortedMatches(sortedmatchess);
    }
    if (sortby.length > 1 && groupBy) {
      let matches = {};
      Object.keys(showMatches).map((key) => {
        matches = { ...matches, [key]: sortbyGroupBy(showMatches[key]) };
      });
      setSortedMatches(matches);
    }
  }, [sortby]);
  const sortbyGroupBy = (macthes) => {
    const sortedmatchess = macthes.sort(function (a, b) {
      let nameA =
        sortby === 'datetime' ||
        sortby === 'week' ||
        sortby === 'bestOf' ||
        sortby === 'status' ||
        sortby === 't1Score'
          ? a[sortby]
          : a[sortby]?.name
          ? a[sortby]?.name.toUpperCase()
          : sortby === 't1'
          ? `Seed ${a?.sp1?.seedPosition}`.toUpperCase()
          : `Seed ${a?.sp2?.seedPosition}`.toUpperCase(); // ignore upper and lowercase
      let nameB =
        sortby === 'datetime' ||
        sortby === 'week' ||
        sortby === 'bestOf' ||
        sortby === 'status' ||
        sortby === 't1Score'
          ? b[sortby]
          : b[sortby]?.name
          ? b[sortby]?.name?.toUpperCase()
          : sortby === 't1'
          ? `Seed ${b?.sp1?.seedPosition}`.toUpperCase()
          : `Seed ${b?.sp2?.seedPosition}`.toUpperCase(); // ignore upper and lowercase
      if (typeof nameA === 'number' || typeof nameB === 'number') {
        nameA += '';
        nameB += '';
      }
      return nameA.localeCompare(nameB, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    });
    return sortedmatchess;
  };
  useEffect(() => {
    setSortedMatches(showMatches);
  }, [showMatches]);

  useEffect(() => {
    if (modalData && Object.keys(modalData).length > 0) {
      let found = matches[0][0].AllMatches.find((el) => el._id === modalData._id);
      if (found) {
        setModalData(found);
      }
    }
  }, [matches]);
  useEffect(() => {
    if (
      matches &&
      (!filterQuery.hasOwnProperty('groupBy') ||
        filterQuery.groupBy === 'none' ||
        filterQuery.groupBy === '')
    ) {
      setGroupBy(false);
      setSortedMatches([]);
      if (stageIndex === 0) {
        if (inputText !== '') {
          setShowMatches(
            matches[stageIndex][0]?.AllMatches?.filter((i) => {
              if (
                i.t1?.name.toLowerCase().includes(inputText.toLowerCase()) ||
                i.t2?.name.toLowerCase().includes(inputText.toLowerCase())
              )
                return i;
            })
          );
        } else {
          setShowMatches(matches[stageIndex][0]?.AllMatches);
        }
      } else if (groupIndex === 0) {
        if (inputText !== '') {
          setShowMatches(
            matches[stageIndex][0]?.allStagesmatches?.filter((i) => {
              if (
                i.t1?.name.toLowerCase().includes(inputText.toLowerCase()) ||
                i.t2?.name.toLowerCase().includes(inputText.toLowerCase())
              )
                return i;
            })
          );
        } else {
          setShowMatches(matches[stageIndex][0]?.allStagesmatches);
        }
      } else {
        if (inputText !== '') {
          setShowMatches(
            matches[stageIndex][groupIndex]?.groupMatches?.filter((i) => {
              if (
                i.t1?.name.toLowerCase().includes(inputText.toLowerCase()) ||
                i.t2?.name.toLowerCase().includes(inputText.toLowerCase())
              )
                return i;
            })
          );
        } else {
          setShowMatches(matches[stageIndex][groupIndex]?.groupMatches);
        }
      }
    } else if (matches && filterQuery?.groupBy !== 'none') {
      setGroupBy(true);
      let groupByDate = [];
      const groupBye = filterQuery?.groupBy;
      if (stageIndex === 0) {
        if (inputText !== '') {
          groupByDate =
            matches[stageIndex].length > 0 &&
            matches[stageIndex][0]?.AllMatches?.filter((i) => {
              if (
                i.t1?.name.toLowerCase().includes(inputText.toLowerCase()) ||
                i.t2?.name.toLowerCase().includes(inputText.toLowerCase())
              )
                return i;
            });
        } else {
          groupByDate = matches[stageIndex].length > 0 && matches[stageIndex][0]?.AllMatches;
        }
      } else if (groupIndex === 0) {
        if (inputText !== '') {
          groupByDate =
            matches[stageIndex].length > 0 &&
            matches[stageIndex][0]?.allStagesmatches?.filter((i) => {
              if (
                i.t1?.name.toLowerCase().includes(inputText.toLowerCase()) ||
                i.t2?.name.toLowerCase().includes(inputText.toLowerCase())
              )
                return i;
            });
        } else {
          groupByDate = matches[stageIndex].length > 0 && matches[stageIndex][0]?.allStagesmatches;
        }
      } else {
        if (inputText !== '') {
          groupByDate =
            matches[stageIndex].length > 0 &&
            matches[stageIndex][groupIndex]?.groupMatches?.filter((i) => {
              if (
                i.t1?.name.toLowerCase().includes(inputText.toLowerCase()) ||
                i.t2?.name.toLowerCase().includes(inputText.toLowerCase())
              )
                return i;
            });
        } else {
          groupByDate =
            matches[stageIndex].length > 0 && matches[stageIndex][groupIndex]?.groupMatches;
        }
      }

      groupByDate = groupByRoundfubt(groupByDate, groupBye);
      setShowMatches(groupByDate);
    }
  }, [matches, groupIndex, stageIndex, filterQuery, groupBy, inputText]);

  useEffect(() => {
    let isComplete = '';
    if (checkCompleteOrNot.complete === true && checkCompleteOrNot.upcomimg === false) {
      isComplete = 'complete';
    } else if (checkCompleteOrNot.complete === false && checkCompleteOrNot.upcomimg === true) {
      isComplete = 'upcoming';
    }
    dispatch(
      getFilterMatches({
        status: isComplete,
        limit: '100',
        page: '1'
      })
    );
  }, [checkCompleteOrNot]);
  useEffect(() => {
    if (selectAllMatches === true) {
      groupBy
        ? showMatches &&
          Object.keys(showMatches).map((matches) =>
            showMatches[matches]?.map((match) => {
              return setSelectedMatch((state) => [...state, match._id]);
            })
          )
        : setSelectedMatch(showMatches?.map((match) => match._id));
    } else {
      setSelectedMatch([]);
    }
  }, [selectAllMatches]);
  useEffect(() => {
    dispatch(getCompetitionsData(id));
  }, []);
  useEffect(() => {
    if (showMatches?.length > 0 && !groupBy) {
      setMatchesCount(showMatches.filter((el) => !el.isDeleted).length);
    } else if (groupBy) {
      setMatchesCount(0);

      const a =
        Object.keys(showMatches) &&
        Object.keys(showMatches).map((matches) => {
          return showMatches[matches].map((match, i) => {
            return match;
          });
        });
      setMatchesCount(a.flat(Infinity).length);
    } else {
      setMatchesCount(0);
    }
  }, [showMatches]);
  // useEffect(() => {
  //   console.log('filterQuery?.groupBy=>', filterQuery?.groupBy);
  //   console.log('showMatches=>', showMatches);
  // }, [filterQuery?.groupBy, showMatches]);
  // useEffect(() => {
  //   console.log('selectedMatch', selectedMatch);
  // }, [selectedMatch]);

  const onEditMatch = (match) => {
    setMatchDataFromCalendar(match);
    setShowEditMatchModal(true);
  };

  useEffect(() => {
    if (stageIndex !== 0)
      typeof competition.stages[stageIndex - 1]?.bracketType !== 'undefined'
        ? setBracketMatches(true)
        : setBracketMatches(false);
  }, [stageIndex]);

  const checkDeletion = () => {
    let mat =
      stageIndex === 0
        ? matches[stageIndex][0]?.AllMatches
        : groupIndex === 0
        ? matches[stageIndex][0]?.allStagesmatches
        : matches[stageIndex][groupIndex]?.groupMatches;
    let completedMatches = mat?.filter(
      (match) => selectedMatch.includes(match._id) && match.status === 'complete'
    );
    return completedMatches?.length;
  };

  return (
    <>
      <div style={{ padding: '0px 0px', minHeight: '90vh' }}>
        <div className="dynamic-search-input" style={{ margin: 'auto', display: 'block' }}>
          <input
            style={{ width: '100%', height: '40px' }}
            id="outlined-basic"
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            variant="outlined"
            placeholder="Search teams to filter matches"
          />
          {inputText?.length !== 0 ? (
            <FiX
              className="input-icon"
              onClick={() => {
                setInputText('');
              }}
            />
          ) : (
            <RiSearchLine className="input-icon" />
          )}
        </div>
        {selectedMatch.length > 0 ? (
          <MatchesSelectionBar
            bracketMathces={bracketMathces}
            checkBeforeDeletion={() => checkDeletion()}
            selectedMatch={selectedMatch}
            setSelectedMatch={setSelectedMatch}
          />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>{matchesCount} Matches</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                // key={folder._id}
                onChange={() => {
                  setCheckCompleteOrNot({
                    complete: checkCompleteOrNot?.complete,
                    upcomimg: !checkCompleteOrNot?.upcomimg
                  });
                }}
                type="checkbox"
                checked={checkCompleteOrNot?.upcomimg}
                className="check-type1"
                matchesbg={true}
              />
              <span
                style={
                  checkCompleteOrNot?.upcomimg
                    ? {
                        marginRight: '7px',
                        marginLeft: '-10px',
                        font: 'normal normal bold 12px/15px Montserrat',
                        color: 'white'
                      }
                    : {
                        marginRight: '7px',
                        marginLeft: '-10px',
                        font: 'normal normal bold 12px/15px Montserrat',
                        color: '#818386'
                      }
                }>
                UPCOMING
              </span>

              <Checkbox
                type="checkbox"
                onChange={() => {
                  setCheckCompleteOrNot({
                    complete: !checkCompleteOrNot?.complete,
                    upcomimg: checkCompleteOrNot?.upcomimg
                  });
                }}
                checked={checkCompleteOrNot?.complete}
                className="check-type1"
                matchesbg={true}
              />
              <span
                style={
                  checkCompleteOrNot?.complete
                    ? {
                        marginRight: '7px',
                        marginLeft: '-10px',
                        font: 'normal normal bold 12px/15px Montserrat',
                        color: 'white'
                      }
                    : {
                        marginRight: '7px',
                        marginLeft: '-10px',
                        font: 'normal normal bold 12px/15px Montserrat',
                        color: '#818386'
                      }
                }>
                COMPLETE
              </span>
              <button
                className="button primary"
                onClick={() => setShowCalendarView(!showCalendarView)}>
                {!showCalendarView ? 'Calender View' : 'List View'}
              </button>
            </div>
          </div>
        )}
        {(loadingState && matchesCount == 0) ? (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loading/></div>) :
        // (matches &&  matches[0][stageIndex]?.count < 1 )
        (matches &&  matches[0][stageIndex]?.count < 1 && !inputText) ? (
          <GeneratMatchButton
            userPermissions={userPermissions}
            stageIndex={stageIndex}
            groupIndex={groupIndex}
            competitionData={competitionData}
          />
        ) : showCalendarView ? (
          groupBy ? (
            <Calendar
              matches={Object.keys(showMatches)
                ?.map((matches) => showMatches[matches])
                .flat()}
              onEditMatch={onEditMatch}
              groupIndex={groupIndex}
              stageIndex={stageIndex}
            />
          ) : (
            <Calendar
              matches={sortedMatches}
              onEditMatch={onEditMatch}
              groupIndex={groupIndex}
              stageIndex={stageIndex}
            />
          )
        ) : (
          <table
            className="table"
            border="0"
            cellSpacing="0"
            cellPadding="0"
            style={{ borderCollapse: 'collapse', minWidth: '500px' }}>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    // key={folder._id}
                    type="checkbox"
                    checked={selectAllMatches}
                    onChange={() => setSelectAllMatches(!selectAllMatches)}
                    className="check-type1"
                    matchesbg={true}
                  />
                </th>
                <th scope="col">
                  <div style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                    <div>
                      DATE
                    </div>
                    <TiArrowUnsorted
                      onClick={() => Sort('datetime')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </th>
                <th scope="col" >
                  <div style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                    <div style={{paddingLeft:'7px'}}>
                      GROUP{' '}
                    </div>
                    <TiArrowUnsorted
                      // onClick={() => sort('TOURNAMENTDATE')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </th>
                {(bracketAndRRStages && stageIndex === 0) ? (
                  <th scope="col">
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div>
                      WK : BK <br/>
                      /RD
                    </div>
                    <TiArrowUnsorted
                      onClick={() => Sort('week')}
                      style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                    />
                    </div>
                  </th>
                ) : (
                  <th scope="col">
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div>{`${bracketMathces ? 'BRACKET/ROUND' : 'WEEK/ROUND'}`}{' '}</div>
                    <TiArrowUnsorted
                      onClick={() => Sort('week')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                    </div>
                  </th>
                )}
                <th scope="col">
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div style={{paddingLeft:'20px'}}> 
                      BEST OF
                    </div>
                    <TiArrowUnsorted
                      onClick={() => Sort('bestOf')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </th>
                <th scope="col">
                  <div style={{display:'flex',alignItems:'center',justifyContent:'end',paddingRight:'20px'}}>
                    <div>
                      {competitionData.participantType === 1 ? 'TEAM 1' : 'PLAYER 1'}
                    </div>
                    <TiArrowUnsorted
                      onClick={() => Sort('t1')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </th>
                <th scope="col">
                  <div style={{display:'flex',alignItems:'center'}}>
                    <div>SCORE</div>
                    <TiArrowUnsorted
                      onClick={() => Sort('t1Score')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </th>
                <th scope="col">
                  <div style={{display:'flex',alignItems:'center'}}>
                    <div>
                      {competitionData.participantType === 1 ? 'TEAM 2' : 'PLAYER 2'}
                    </div>
                    <TiArrowUnsorted
                      onClick={() => Sort('t2')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </th>
                <th scope="col">
                  <div style={{display:'flex',alignItems:'center'}}>
                    <div>
                      STATUS{' '}
                    </div>
                    <TiArrowUnsorted
                      onClick={() => Sort('status')}
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </th>{' '}
                {(bracketAndRRStages && stageIndex === 0) ? (
                  <>
                    <th scope="col">
                    <div style={{display:'flex',alignItems:'center'}}>
                      MATCH
                      <TiArrowUnsorted
                        style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                      />
                      </div>
                    </th>
                    <th scope="col">
                    <div style={{display:'flex',alignItems:'center'}}>
                      <div>TIE BREAKER</div>
                      <TiArrowUnsorted
                        style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                      />
                      </div>
                    </th>
                  </>
                ) : (
                  <th scope="col">
                  <div style={{display:'flex',alignItems:'center'}}>
                    <div>{`${bracketMathces ? 'MATCH' : 'TIE BREAKER'}`}{' '}</div>
                    <TiArrowUnsorted
                      style={{ width: '20px', cursor: 'pointer' }}
                    />
                    </div>
                  </th>
                )}
                <th scope="col" style={{ width: '11%' }}>
                  <div style={(game?.name === 'CS:GO' || game?.name === 'Valorant')?{display:'flex',alignItems:'center',justifyContent:'end',paddingRight:'12%'}:{display:'flex',alignItems:'center',justifyContent:'end',paddingRight:'25%'} }>
                  {/* (game?.name === 'CS:GO' || game?.name === 'Valorant')?{paddingRight:'25%'}:{paddingRight:'12%'} */}
                    ACTIONS{' '}
                  </div>
                </th>{' '}
                <th scope="col">
                  <div style={{display:'flex',alignItems:'center',justifyContent:'end',paddingRight:'20px'}}>
                    TOGGLES 
                  </div>
                  </th>
              </tr>
            </thead>
            <>
              <tbody>
                {groupBy
                  ? Object.keys(showMatches)?.map((matches) => {
                      return (
                        <>
                          <th>
                            <h2
                              style={{ position: 'absolute', marginTop: 'auto', color: '#3eabf0' }}>
                              {filterQuery?.groupBy} {matches ? matches : null}
                            </h2>
                          </th>
                          {showMatches[matches]?.map((match, i) => {
                            return (
                              <FolderRow
                                key={i}
                                folder={match}
                                bracketMathces={bracketMathces}
                                setShowScoreModal={setShowScoreModal}
                                setModalData={setModalData}
                                modalData={modalData}
                                setSelectedMatch={setSelectedMatch}
                                selectedMatch={selectedMatch}
                                selectAllMatches={selectAllMatches}
                                userPermissions={userPermissions}
                                groupIndex={groupIndex}
                                stageIndex={stageIndex}
                                setShowGenerateMatch={setShowGenerateMatch}
                                gameData={gameData}
                                game1={game}
                                redirectUrl={redirectUrl}
                                competition={competition}
                                bracketAndRRStages={bracketAndRRStages}
                              />
                            );
                          })}
                        </>
                      );
                    })
                  : sortedMatches &&
                    sortedMatches?.map((match, ind, v) => {
                      return (
                        <FolderRow
                          key={ind + ' ' + match._id}
                          folder={match}
                          bracketMathces={bracketMathces}
                          setShowScoreModal={setShowScoreModal}
                          setModalData={setModalData}
                          modalData={modalData}
                          setSelectedMatch={setSelectedMatch}
                          selectedMatch={selectedMatch}
                          selectAllMatches={selectAllMatches}
                          userPermissions={userPermissions}
                          groupIndex={groupIndex}
                          stageIndex={stageIndex}
                          setShowGenerateMatch={setShowGenerateMatch}
                          gameData={gameData}
                          game1={game}
                          redirectUrl={redirectUrl}
                          competition={competition}
                          bracketAndRRStages={bracketAndRRStages}
                        />
                      );
                    })}
              </tbody>
            </>
          </table>
        )}
        <GenerateCodes
          matchData={modalData}
          onClose={() => setShowGenerateMatch(false)}
          open={showGenerateMatch}
        />

        {showScoreModal && (
          <SetScoreModal
            open={showScoreModal}
            gameName={game?.shortName ?? 'LoL'}
            competitionId={competition?._id}
            matchData={modalData}
            onClose={setShowScoreModal}
          />
        )}
        {filterMatches && (
          <FilterMatchesPopup
            open={filterMatches}
            onClose={setFilterMatches}
            showMatches={showMatches}
            selectedMatch={selectedMatch}
            matches={matches}
            groupIndex={groupIndex}
            stageIndex={stageIndex}
            setSelectedMatch={setSelectedMatch}
            setShowMatches={setShowMatches}
            competitionData={competitionData}
            selectAllMatches={selectAllMatches}
            setSelectAllMatches={setSelectAllMatches}
            groupBye={filterQuery?.groupBy}
          />
        )}
        {showEditMatchModal && (
          <EditMatchPopup
            stageIndex={stageIndex}
            groupIndex={groupIndex}
            open={showEditMatchModal}
            editMatchData={matchDataFromCalendar}
            onClose={setShowEditMatchModal}
          />
        )}
      </div>
    </>
  );
};
export const FolderRow = ({
  folder,
  updateMatches,
  onEditMatch,
  setSelectedMatch,
  selectedMatch,
  selectAllMatches,
  setModalData,
  setShowScoreModal,
  userPermissions,
  modalData,
  stageIndex,
  groupIndex,
  setShowGenerateMatch,
  gameData,
  bracketMathces,
  game1,
  competition,
  redirectUrl,
  bracketAndRRStages
}) => {
  const { user } = useContext(AppContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const timeZone = useSelector((state) => state.TIMEZONE);
  // const [showScoreModal, setShowScoreModal] = useState(false);
  const [showSetPlayersModal, setShowSetPlayersModal] = useState(false);
  // const [modalData, setModalData] = useState(false);
  const [showEditVeto, setShowEditVeto] = useState(false);
  const [vetoModalData, setVetoModalData] = useState(false);
  const [generateCodeloaderSpinner, setGenerateCodeloaderSpinner] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(true);
  const [checkedOrNot, setCheckedOrNot] = useState();
  const [game, setGame] = useState();
  const [editMatch, setEditMatch] = useState();
  const [editMatchData, setEditMatchData] = useState();
  const [editMatchStageGroup, setEditMatchStageGroup] = useState({ stageInd: 1, groupInd: 0 });
  const [showLockRescheduleModal, setShowLockRescheduleModal] = useState(false);
  const [scorecolor, setScoreColor] = useState('#68717e');
  // const [generateCodeloaderSpinner, setGenerateCodeloaderSpinner] = useState(false);
  const [tieBreaker, setTieBreaker] = useState(folder?.isTieBreaker ? folder?.isTieBreaker : false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const setTiebreaker = (isTieBreaker) => {
    setTieBreaker(isTieBreaker);
    Axios.put(
      process.env.REACT_APP_CORE_API +
        `/api/organizer/Competition/${id}/matches/${folder._id}/tieBreaker`,
      {
        isTieBreaker: isTieBreaker
      }
    );
  };
  const onSetPlayers = (match) => {
    setModalData(match);
    setShowSetPlayersModal(true);
  };
  const onToggle = (toggle) => {
    const data = {
      match: {
        ...folder,
        [toggle]: !folder[toggle]
      }
    };
    Axios.put(
      `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${id}/matches/editMatch/${folder._id}`,
      data
    )
      .then(({ data }) => {
        let query = { status: '', limit: '100', page: '1' };
        dispatch(getCompetitionsMatches({ id: id, query }));
        toast.success('Updated Successfully');
        // onHide();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const checkMultipleGroups = (stageId) => {
    const stages = competition?.stages ?? [];
    return stages.find(stage => stage._id === stageId)?.groups.length > 1
  };
  const seteditMatch = (match) => {
    const stages = competition?.stages ?? [];
    const stageInd = stages.findIndex((stage) => stage._id === match.stage) ?? 0;
    const groupInd = stages[stageInd].groups.findIndex((group) => group._id === match.groupId) ?? 0;
    setEditMatchStageGroup({ stageInd, groupInd });
    setEditMatchData(match);
    setEditMatch(true);
  };
  const onSetScore = (match) => {
    setModalData(match);
    setShowScoreModal(true);
  };
  const onEditVeto = (match) => {
    setVetoModalData(match);
    setShowEditVeto(true);
  };
  const toggleMatchStatus = (matchId) => {
    Axios.put(
      `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${id}/matches/toggleStatus/${matchId}`
    )
      .then(({ data }) => {
       const {match} = data
       dispatch(updateMatchStatus(match))
        toast.success(data.message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    if (
      Math.floor(folder.bestOf / 2) + 1 < folder.t1Score + folder.t2Score ||
      folder.t1Score + folder.t2Score < 0
    ) {
      setScoreColor('#f11857');
    } else if (Math.floor(folder.bestOf / 2) + 1 == folder.t1Score + folder.t2Score) {
      setScoreColor('#3eabf0');
    } else {
      setScoreColor('#68717e');
    }
  }, []);
  // useEffect(() => {
  //   console.log('folder=>', folder);
  //   console.log('game1=>', game1);
  // }, [folder,game1]);
  // useEffect(()=>{
  // console.log('Folder league gam nam =>',folder.league.game.name);
  // console.log('generateCodeloaderSpinner=>',generateCodeloaderSpinner);
  // },[folder,generateCodeloaderSpinner])
  const contextAction = useMemo(() => {
    const onDeleteMatches = async () => {
      const ids = selectedRows.map(({ _id }) => _id);

      if (window.confirm(`Are you sure you want to delete ${ids.length} matches?`)) {
        try {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/deleteMulti`, { ids });

          updateMatches((matches) => matches.filter(({ _id }) => !ids.includes(_id)));
          setSelectedRows([]);
          setClearSelectedRows((prevState) => !prevState);
          toast.success(`Successfully deleted ${ids.length} matches`);
        } catch (e) {
          toast.error('There was a problem deleting the selected matches');
        }
      }
    };

    return (
      <div>
        <Button size="sm" variant="danger" onClick={onDeleteMatches}>
          <FontAwesomeIcon icon={faTrashAlt} />
          &nbsp;Delete Selected
        </Button>
      </div>
    );
  }, [selectedRows, updateMatches]);
  useEffect(() => {
    if (selectAllMatches === true) {
      setCheckedOrNot(true);
    } else {
      setCheckedOrNot(false);
    }
  }, [selectAllMatches]);
  const handleCheckboxClick = (id) => {
    if (selectedMatch?.find((e) => e === folder._id)) {
      setCheckedOrNot(false);
      setSelectedMatch((current) => current.filter((fruit) => fruit !== id));
    } else {
      setCheckedOrNot(true);
      setSelectedMatch((state) => [...state, `${id}`]);
    }
  };
  const generateTournamentCodes = async (folder) => {
    setModalData(folder);

    if (folder?.lolData?.tournamentCodes.length) {
      setShowGenerateMatch(true);
    } else {
      try {
        setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(true);
        const { data } = Axios.post(
          `${process.env.REACT_APP_LOL_API}/api/matches/${folder._id}/generateTournamentCodes`
        );
        if (data) {
          toast.success(data.msg);
          setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
          let query = { status: '', limit: '100', page: '1' };
          dispatch(getCompetitionsMatches({ id: id, query }));
        }
      } catch (error) {
        setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
        toast.error('Failed to generate tournament codes');
      }
    }
  };
  if (folder.isDeleted) return null;
  return (
    <tr key={folder._id} id={folder._id} className="hoverable-row">
      <td style={{ width: 'fit-content' }}>
        <Checkbox
          key={folder._id}
          type="checkbox"
          checked={checkedOrNot}
          onChange={() => {
            !checkEditMatchDisabled(userPermissions) && handleCheckboxClick(folder._id);
          }}
          className="check-type1"
          matchesbg={true}
        />
      </td>
      <td style={{ width: 'fit-content' }}>
        <Col>
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span
                className="text"
                style={{
                  // maxWidth: '175px',
                  textOverflow: 'ellipsis',
                  // marginLeft: '300px',
                  height: '18px',
                  // width: '235px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'inline-block',
                  margin: '0 5px 0 5px',
                  textAlign: 'left',
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  font: 'normal normal bold 12px/15px Montserrat',
                  width: '200px'
                }}>
                {moment(folder?.datetime).tz(timeZone?.timeZone).format(`DD/MM/YYYY HH:mm (zz)`) 
                // +
                  // ' (' +
                  // moment.tz.guess() +
                  // ')'
                  }
              </span>
            </div>
          </Row>
        </Col>
      </td>
      <td style={{ width: 'fit-content' }}>
        {stageIndex === 0 ?<Col>
          <Row>
              <div className="Publictag" style={{ background: `${folder?.groups && folder?.groups[0].color}22` }}>
                <span
                  className="samiCircle"
                  style={{ font: 'normal normal bold 12px/15px Montserrat', color: folder?.groups && folder?.groups[0]?.color }}>
                  {checkMultipleGroups(folder?.stageId) ? folder?.stageName + " - " + folder?.groupName : folder?.stageName}
                </span>
              </div>
          </Row>
        </Col> :<Col>
          <Row>
            {folder.groups.map((group) => (
              <div className="Publictag" style={{ background: `${group.color}22` }}>
                <span
                  className="samiCircle"
                  style={{ font: 'normal normal bold 12px/15px Montserrat', color: group?.color }}>
                  {group.name}
                </span>
              </div>
            ))}
          </Row>
        </Col>}
      </td>
      {(bracketAndRRStages && stageIndex === 0) ? (
        <td style={{ width: 'fit-content' }}>
          <Col>
            <Row
              style={{ justifyContent: 'center', font: 'normal normal bold 12px/15px Montserrat' }}>
              {folder?.bracketType
                ? `B: 1 / W: ${folder.round}`
                : `R : ${folder.round}/ W: ${folder.week}`}
            </Row>
          </Col>
        </td>
      ) : (
        <td style={{ width: 'fit-content' }}>
          <Col>
            <Row
              style={{ justifyContent: 'center', font: 'normal normal bold 12px/15px Montserrat' }}>
              {bracketMathces
                ? `B: 1 / W: ${folder.round}`
                : `R : ${folder.round}/ W: ${folder.week}`}
            </Row>
          </Col>
        </td>
      )}
      <td style={{ width: 'fit-content' }}>
        <Col>
          <Row style={{ justifyContent: 'center' }}>{folder.bestOf}</Row>
        </Col>
      </td>
      <td style={{ width: 'fit-content' }}>
        <Col>
          <Row style={{justifyContent:'end',paddingRight:'10px'}}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span
                className="text"
                style={{
                  maxWidth: '175px',
                  textOverflow: 'ellipsis',
                  marginLeft: '300px',
                  height: '18px',
                  width: '235px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:'end',
                  margin: '0 6px 0 5px',
                  paddingRight:'20px',
                  textAlign: 'left',
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                {folder?.t1?.name
                  ? folder?.t1?.name
                  : folder.sp1?.seedPosition === undefined
                  ? stageIndex === 0 && groupIndex === 0
                    ? 'TBD'
                    : folder?.tbd[0]?.name
                  : 'SEED ' + folder.sp1?.seedPosition}
                {folder?.lolData?.players?.t1.length > 0 && <HiCheckCircle size={20} />}
              </span>
              <span className="imgLogoContainer">
                <img
                  className=" imgLogoWidth imgLogo  imgLogoCropped"
                  src={
                    folder?.t1?.logo
                      ? folder?.t1?.logo
                      : 'http://leagues-static.s3.eu-west-2.amazonaws.com/default/league-default.jpg'
                  }
                  alt="Insight Gaming"
                  width={'25px'}
                />
              </span>
            </div>
          </Row>
        </Col>
      </td>
      <td style={{ width: 'fit-content' }}>
        <Col style={{marginLeft:'5px'}}>
          <Row
            style={{
              justifyContent: 'center',
              top: '495px',
              left: '738px',
              width: '40px',
              height: '20px',
              background: `${scorecolor} 0% 0% no-repeat padding-box`,
              borderRadius: '2px',
              opacity: '1'
            }}>
            {folder?.t1Score}-{folder?.t2Score}
          </Row>
        </Col>
      </td>{' '}
      <td style={{ width: 'fit-content' }}>
        <Col>
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span className="imgLogoContainer">
                <img
                  className=" imgLogoWidth imgLogo  imgLogoCropped"
                  src={
                    folder?.t2?.logo
                      ? folder?.t2?.logo
                      : 'http://leagues-static.s3.eu-west-2.amazonaws.com/default/league-default.jpg'
                  }
                  alt="Insight Gaming"
                  width={'25px'}
                />
              </span>
              <span
                className="text"
                style={{
                  maxWidth: '175px',
                  textOverflow: 'ellipsis',
                  marginLeft: '300px',
                  height: '18px',
                  width: '235px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 5px 0 5px',
                  textAlign: 'left',
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                {folder?.t2?.name
                  ? folder?.t2?.name
                  : folder?.sp2?.seedPosition === undefined
                  ? stageIndex === 0 && groupIndex === 0
                    ? 'TBD'
                    : folder?.tbd[1]?.name
                  : 'SEED ' + folder.sp2?.seedPosition}
                {folder?.lolData?.players?.t2.length > 0 && <HiCheckCircle size={20} />}
              </span>
            </div>
          </Row>
        </Col>
      </td>{' '}
      <td style={{ width: 'fit-content' }} onClick={(e)=> toggleMatchStatus(folder?._id) }>
        <Col>
          <Row style={{ font: 'normal normal bold 12px/15px Montserrat' }}>{folder.status}</Row>
        </Col>
      </td>
      {(bracketAndRRStages && stageIndex === 0) ? (
        <>
          <td style={{ width: 'fit-content' }}>
            {folder?.bracketType && (
              <strong style={{ fontSize: 14 }}>
                <span style={{ color: '#68717e' }}>Match</span> {folder.matchNo}
              </strong>
            )}
          </td>
          <td style={{ width: 'fit-content' }}>
            {!folder?.bracketType && (
              <Checkbox
                style={{ justifyContent: 'center' }}
                key={folder._id}
                type="checkbox"
                checked={tieBreaker}
                onChange={() =>
                  !checkEditMatchDisabled(userPermissions) && setTiebreaker(!tieBreaker)
                }
                className="check-type1"
              />
            )}
          </td>
        </>
      ) : (
        <td style={{ width: 'fit-content' }}>
          {bracketMathces ? (
            <strong style={{ fontSize: 14 }}>
              <span style={{ color: '#68717e' }}>Match</span> {folder.matchNo}
            </strong>
          ) : (
            <Checkbox
              style={{ justifyContent: 'center' }}
              key={folder._id}
              type="checkbox"
              checked={tieBreaker}
              onChange={() =>
                !checkEditMatchDisabled(userPermissions) && setTiebreaker(!tieBreaker)
              }
              className="check-type1"
            />
          )}
        </td>
      )}
      <td style={{ width: 'fit-content' }}>
        <Col>
          <>
            <OverlayTrigger placement="top">
              <Tippy content="View Match" placement="top">
                <a
                  className="btn text-secondary"
                  href={`${redirectUrl}/match/${folder._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ padding: '8px', background: 'inherit', border: 'none', color: 'gray' }}>
                  <FontAwesomeIcon icon={faEye} />
                </a>
              </Tippy>
            </OverlayTrigger>
            <OverlayTrigger placement="top">
              <Tippy
                content={
                  checkSetPlayersDisabled(userPermissions)
                    ? '‘Set players’ is not enabled for this competition'
                    : 'Set Players'
                }
                placement="top">
                <div style={{ display: 'inline-block' }}>
                  <Button
                    variant="link"
                    className={'text-secondary'}
                    onClick={() => onSetPlayers(folder)}
                    style={{ padding: '8px', background: 'inherit', border: 'none', color: 'gray' }}
                    // disabled={!user?.state?.admin && !user?.state?.permissions?.user.matches}
                    disabled={checkSetPlayersDisabled(userPermissions)}>
                    <FontAwesomeIcon icon={faUsers} />
                  </Button>
                </div>
              </Tippy>
            </OverlayTrigger>
            {(game1.name === 'CS:GO' || game1.name === 'Valorant') && (
              <OverlayTrigger placement="top">
                <Tippy
                  content={
                    checkEditMatchDisabled(userPermissions)
                      ? 'You don’t have permission for this'
                      : 'Edit Veto'
                  }
                  placement="top">
                  <div style={{ display: 'inline-block' }}>
                    <Button
                      variant="link"
                      className={'text-secondary'}
                      onClick={() => onEditVeto(folder)}
                      style={{
                        padding: '8px',
                        background: 'inherit',
                        border: 'none',
                        color: 'gray'
                      }}
                      disabled={checkEditMatchDisabled(userPermissions)}>
                      <FontAwesomeIcon icon={faGamepad} />
                    </Button>
                  </div>
                </Tippy>
              </OverlayTrigger>
            )}
            <OverlayTrigger placement="top">
              <Tippy
                content={
                  checkSetScoreDisabled(userPermissions)
                    ? 'You don’t have permission for this'
                    : 'Set Score Manually'
                }
                placement="top">
                <div style={{ display: 'inline-block' }}>
                  <Button
                    variant="link"
                    className={
                      checkSetScoreDisabled(userPermissions)
                        ? 'disabled-link text-secondary'
                        : 'text-secondary'
                    }
                    onClick={() => onSetScore(folder)}
                    style={{ padding: '8px', background: 'inherit', border: 'none', color: 'gray' }}
                    disabled={checkSetScoreDisabled(userPermissions)}>
                    <FontAwesomeIcon icon={faWrench} />
                  </Button>
                </div>
              </Tippy>
            </OverlayTrigger>
            <OverlayTrigger placement="top">
              <Tippy
                content={
                  checkEditMatchDisabled(userPermissions)
                    ? 'You don’t have permission for this'
                    : 'Edit Match'
                }
                placement="top">
                <div style={{ display: 'inline-block' }}>
                  <Button
                    variant="link"
                    className={'text-secondary'}
                    // onClick={() => { onEditMatch(folder) }}
                    onClick={() => seteditMatch(folder)}
                    style={{ padding: '8px', background: 'inherit', border: 'none', color: 'gray' }}
                    disabled={checkEditMatchDisabled(userPermissions)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                </div>
              </Tippy>
            </OverlayTrigger>
            <div style={{ display: 'inline-block', color: '#24262b' }}>
              <span style={{ fontSize: '37px' }}>|</span>
            </div>
          </>
        </Col>
      </td>
      <td style={{ width: 'fit-content',width:'7%'}}>
        <Col>
          <Row style={{justifyContent:'end',paddingRight:'10px'}}>
            {' '}
            <>
              <div className="vertical-divider" />
              <div className="match-icons-grid" style={{ display: 'flex' }}>
                <OverlayTrigger placement="top">
                  <Tippy
                    content={
                      checkEditMatchDisabled(userPermissions)
                        ? `You don’t have permission for this`
                        : 'Lock Scheduling'
                    }
                    placement="top">
                    <div style={{ display: 'inline-block' }}>
                      <Button
                        variant="link"
                        style={{
                          padding: '8px',
                          background: 'inherit',
                          border: 'none',
                          color: 'gray'
                        }}
                        className={'text-secondary'}
                        onClick={
                          () => {
                            setShowLockRescheduleModal(true);
                          }

                          // editMatch(filteredMatches._id, { broadcasted: !filteredMatches.broadcasted })
                        }
                        disabled={checkEditMatchDisabled(userPermissions)}>
                        {folder.isLockReschedule ? (
                          <BsFillLockFill style={{ opacity: 0.5 }} />
                        ) : (
                          <BsFillUnlockFill style={{ opacity: 0.5 }} />
                        )}
                      </Button>
                    </div>
                  </Tippy>
                </OverlayTrigger>
                {folder.league?.game?.name.toLowerCase() === 'league of legends' ? (
                  // generateCodeloaderSpinner !== filteredMatches._id ||
                  typeof generateCodeloaderSpinner === 'boolean' &&
                  generateCodeloaderSpinner === false ? (
                    <OverlayTrigger placement="top">
                      <Tippy
                        content={
                          checkEditMatchDisabled(userPermissions)
                            ? `You don’t have permission for this`
                            : 'Tournament Codes'
                        }
                        placement="top">
                        <div style={{ display: 'inline-block' }}>
                          <Button
                            style={{
                              padding: '8px',
                              background: 'inherit',
                              border: 'none',
                              color: 'gray'
                            }}
                            variant="link"
                            disabled={checkEditMatchDisabled(userPermissions)}
                            // onClick={() => generateTournamentCodes(folder)}
                            className={
                              // checkEditMatchDisabled(userPermissions)
                              //   ? 'disabled-link text-secondary'
                              //   :
                              'text-secondary'
                            }>
                            <HiCode
                              color="white"
                              style={{ opacity: 0.5 }}
                              className={
                                // filteredMatches.lolData?.tournamentCodes?.length ? '' :
                                'faded'
                              }
                            />
                          </Button>
                        </div>
                      </Tippy>
                    </OverlayTrigger>
                  ) : (
                    <>
                      <Spinner
                        animation="border"
                        className="m-auto"
                        size="sm"
                        role="status"></Spinner>
                    </>
                  )
                ) : (
                  <></>
                )}
                <OverlayTrigger placement="top">
                  <Tippy
                    content={
                      checkEditMatchDisabled(userPermissions)
                        ? `You don’t have permission for this`
                        : 'Broadcasting'
                    }
                    placement="top">
                    <div style={{ display: 'inline-block' }}>
                      <Button
                        variant="link"
                        style={{
                          padding: '8px',
                          background: 'inherit',
                          border: 'none',
                          color: 'gray'
                        }}
                        className={
                          // checkEditMatchDisabled(userPermissions)
                          //   ? 'disabled-link text-secondary'
                          //   :
                          'text-secondary'
                        }
                        disabled={checkEditMatchDisabled(userPermissions)}
                        onClick={
                          () => {
                            onToggle('broadcasted');
                          }
                          // editMatch(filteredMatches._id, { broadcasted: !filteredMatches.broadcasted })
                        }>
                        <BsFillCameraVideoFill
                          style={{ opacity: `${!folder.broadcasted ? 0.5 : ''}` }}
                        />
                      </Button>
                    </div>
                  </Tippy>
                </OverlayTrigger>
                {folder?.league?.game?.name.toLowerCase() === 'league of legends' && (
                  <OverlayTrigger placement="top">
                    <Tippy
                      content={
                        checkEditMatchDisabled(userPermissions)
                          ? `You don’t have permission for this`
                          : 'Side Selection'
                      }
                      placement="top">
                      <div style={{ display: 'inline-block' }}>
                        <Button
                          variant="link"
                          style={{
                            padding: '8px',
                            background: 'inherit',
                            border: 'none',
                            color: 'gray'
                          }}
                          disabled={checkEditMatchDisabled(userPermissions)}
                          className={
                            // checkEditMatchDisabled(userPermissions)
                            //   ? 'disabled-link text-secondary'
                            //   :
                            'text-secondary'
                          }
                          onClick={
                            () => {
                              onToggle('sideSelection');
                            }
                            // editMatch(filteredMatches._id, {
                            //   sideSelection: !filteredMatches.sideSelection
                            // })
                          }>
                          <BsSquareHalf
                            style={{ opacity: `${!folder.sideSelection ? 1.5 : ''}` }}
                          />
                        </Button>
                      </div>
                    </Tippy>
                  </OverlayTrigger>
                )}
              </div>
            </>
          </Row>
        </Col>
      </td>
      <MatchSetPlayersModal
        updateMatchRecords={() => dispatch(getCompetitionsMatches({ id: id, query: "" }))}
        show={showSetPlayersModal}
        onHide={() => setShowSetPlayersModal(false)}
        match={modalData}
        updateMatches={updateMatches}
        // league={league}
        flag={gameData?.shortName === 'CSGO'}
        game={gameData}
      />
      {showEditVeto && (
        <EditVetoModal
          open={showEditVeto}
          onClose={setShowEditVeto}
          // match={modalData}
          match={folder}
        />
      )}
      <EditMatchPopup
        stageIndex={editMatchStageGroup?.stageInd}
        groupIndex={editMatchStageGroup?.groupInd}
        open={editMatch}
        editMatchData={editMatchData}
        onClose={setEditMatch}
      />
      {showLockRescheduleModal && (
        <MatcheRescheduleModal
          show={showLockRescheduleModal}
          match={folder}
          onHide={() => setShowLockRescheduleModal(false)}
        />
      )}
    </tr>
  );
};
export default MatchesTab;
