import React from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import ConnectGame from './ConnectGame/ConnectGame';

import useTranslated from '../../../helpers/translationHelper';
const CreatePlayerModal = ({ open, onClose }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="modal"
      contentLabel="Example Modal"
      shouldCloseOnEsc={false}
    >
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>{useTranslated('id_pop-up_-_create_player_0') /* Create a Player */}</h2>
        <h3>
          {
            useTranslated(
              'id_pop-up_-_create_player_1'
            ) /* Connect your game account to participate in tournaments, scrims etc. You can only create one player profile per game. */
          }
        </h3>
      </div>
      <div className="modal-body">
        <ConnectGame onClose={onClose} />
      </div>
    </Modal>
  );
};

export default CreatePlayerModal;
