import { SET_TRASH } from './types';
import Axios from 'axios';
let backend = process.env.REACT_APP_CORE_API;
export const getTrash = () => (dispatch) => {
  Axios.get(backend + '/api/organizer/trash')
    .then((res) => {
      dispatch({
        type: SET_TRASH,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
