const moment = require('moment');
export const groupByRoundfubt = (matchese, groupBye) => {
  if (groupBye === 'round') {
    const groupByRound = matchese.reduce((x, y) => {
      (x[y.round] = x[y.round] || []).push(y);

      return x;
    }, {});
    return groupByRound;
  }
  if (groupBye === 'week') {
    const groupByWeek = matchese.reduce((x, y) => {
      (x[y.week] = x[y.week] || []).push(y);

      return x;
    }, {});
    return groupByWeek;
  }
  if (groupBye === 'date') {
    // const groupByDate = matchese.reduce((x, y) => {
    //   (x[y.datetime] = x[y.datetime] || []).push(y);

    //   return x;
    // }
    const groupByDate = matchese.reduce((accumulator, currentValue) => {
      let currentYear = moment().year()
      const date = moment(currentValue.datetime);
      let formattedDate = date.format('dddd-DD MMM');
      let dateYear = date.year();
      if(dateYear !== currentYear) {
        formattedDate = formattedDate += ` ${dateYear}`
      }
      (accumulator[formattedDate] = accumulator[formattedDate] || []).push(currentValue);

      return accumulator;
    }, {});
    return groupByDate;
  }
};
