import React from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { ScheduleMatchesItem, Icon, Loading, Checkbox } from '@common';
import { useState } from 'react';
import { useEffect } from 'react';
import userEvent from '@testing-library/user-event';

function Customize({ selectedMembers, setSelectedUsersRole, setSelectedMembers }) {
  const [seleteduser, setSelecteduser] = useState(0);
  const [ischaecked, setIschecked] = useState({
      changePermission: selectedMembers[seleteduser]?.permission?.changePermission,
      manageFolderStructure: selectedMembers[seleteduser]?.permission?.manageFolderStructure,
      createAndDeleteCompetitions: selectedMembers[seleteduser]?.permission?.createAndDeleteCompetitions,
      administrateCompetitions: selectedMembers[seleteduser]?.permission?.administrateCompetitions,
      editFormat: selectedMembers[seleteduser]?.permission?.editFormat,
      manageParticipants: selectedMembers[seleteduser]?.permission?.manageParticipants,
      editMatches: selectedMembers[seleteduser]?.permission?.editMatches,
      setMatchScore: selectedMembers[seleteduser]?.permission?.setMatchScore,
  });
  const setvalueofobj = (i) => {
    const c = Object.keys(ischaecked)[i];
    setIschecked((state) => ({ ...state, [c]: !ischaecked[c] }));
  };

  let permissions = [
    'Change permissions',
    'Manage folder structure',
    'Create and delete competitions',
    'Administrate competitions',
    'Edit format',
    'Manage participants',
    'Edit matches',
    'Set match score'
  ];
  const selectedser = (i) => {
    selectedMembers.map((u, index) => {
      if (index === i) {
        let obj = {
          changePermission: u?.userPermission?.changePermission?u.userPermission.changePermission:false,
          manageFolderStructure: u?.userPermission?.manageFolderStructure?u.userPermission.manageFolderStructure:false,
          createAndDeleteCompetitions: u?.userPermission?.createAndDeleteCompetitions?u.userPermission.createAndDeleteCompetitions:false,
          administrateCompetitions: u?.userPermission?.administrateCompetitions?u.userPermission.administrateCompetitions:false,
          editFormat: u?.userPermission?.editFormat?u.userPermission.editFormat:false,
          manageParticipants: u?.userPermission?.manageParticipants?u.userPermission.manageParticipants:false,
          editMatches: u?.userPermission?.editMatches?u.userPermission.editMatches:false,
          setMatchScore: u?.userPermission?.setMatchScore?u.userPermission.setMatchScore:false,
        }
        setIschecked(obj)
      } 
    })
    setSelecteduser(i);
  };
  useEffect(() => {
    const users = selectedMembers.map((u, index) => {
      let obj = {
        changePermission: u?.userPermission?.changePermission?u.userPermission.changePermission:false,
        manageFolderStructure: u?.userPermission?.manageFolderStructure?u.userPermission.manageFolderStructure:false,
        createAndDeleteCompetitions: u?.userPermission?.createAndDeleteCompetitions?u.userPermission.createAndDeleteCompetitions:false,
        administrateCompetitions: u?.userPermission?.administrateCompetitions?u.userPermission.administrateCompetitions:false,
        editFormat: u?.userPermission?.editFormat?u.userPermission.editFormat:false,
        manageParticipants: u?.userPermission?.manageParticipants?u.userPermission.manageParticipants:false,
        editMatches: u?.userPermission?.editMatches?u.userPermission.editMatches:false,
        setMatchScore: u?.userPermission?.setMatchScore?u.userPermission.setMatchScore:false,
      }
      if (index === seleteduser && u.name != 'Owner' && JSON.stringify(obj) !== JSON.stringify(ischaecked)) {
        if (typeof u.userPermission === 'undefined' && // comparing object with stringfy is not best approch bcz if any permission order is changed then string will not be the same.
        u.permission.changePermission === ischaecked.changePermission &&
        u.permission.manageFolderStructure === ischaecked.manageFolderStructure &&
        u.permission.createAndDeleteCompetitions === ischaecked.createAndDeleteCompetitions &&
        u.permission.administrateCompetitions === ischaecked.administrateCompetitions &&
        u.permission.editFormat === ischaecked.editFormat &&
        u.permission.manageParticipants === ischaecked.manageParticipants &&
        u.permission.editMatches === ischaecked.editMatches &&
        u.permission.setMatchScore === ischaecked.setMatchScore
        ) return { ...u, permission: ischaecked };
        else return { ...u, permission: ischaecked, name: 'Custom' };
      } else {
        if (u.name === "Custom") u.permission = obj // reseting user permission to fix can not un-check checboxes
        return u;
      }
    });
    setSelectedMembers(users);
  }, [ischaecked]);
  const style1 = {
    display:'flex',
    alignItems:'flex-start',
  }
  return (
    <div className="dynamic-search-selected-items" style={{ overflowY: 'none' ,marginTop:'0px'}}>
      {/* {selectedUser && ( */}
      <div className="row" style={{ alignItems: 'inherit'}}>
        <div className="col-md-6">
          <div style={{font: 'normal normal bold 12px/15px Montserrat',height:'15px',width:'135px',textTransform:'uppercase'}}>Users With access</div>
          <div style={{marginTop:'10px'}}>
            {selectedMembers &&
              selectedMembers.map((selectedUser, i) => (<>
                <div className={`selected-items ${seleteduser===i?'selected':null}`}  
                  onClick={() => selectedser(i)}>

                  <div className="item-details customize-item" style={{display:'flex'}}>
                  <div className="item-img" style={{paddingLeft:'5px'}}>
                          {selectedUser?.img ? (
                            <div
                              className="search-result-img"
                              style={{ backgroundImage: `url(${selectedUser?.img})`, margin:'0px 3px',height:'30px',width:'30px' }}
                            />
                          ) : (
                            <div className="profile-img-with-inital-1-letter">
                              {selectedUser?.title.charAt(0)}
                            </div>
                          )}
                        </div>
                  
                    <div>
                      <div className="item-name"  style={{marginLeft:'8px',font: 'normal normal  12px/15px Montserrat'}}>
                        <p>{selectedUser?.title}</p>
                        <span style={{ color: '#676f7c' }}>{selectedUser?.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              ))}
          </div>
        </div>
        <div className="col-md-6" style={{paddingLeft:'10px'}}>
          <div className="uppercase bold mt-1" style={{ padding: '0px 0px 10px 0px',font: 'normal normal bold 12px/15px Montserrat' }}>
            Permissions
          </div>
          {seleteduser > -1 ? (
            permissions.map((perm, i) => {
              return <Checkbox
                key={i}
                type="checkbox"
                checked={selectedMembers.length>0?Object?.values(selectedMembers[seleteduser]?.permission)[i]:false}
                onChange={() => setvalueofobj(i)}
                label={perm}
                className="check-type1 check-type2"
                style1={style1}
              />
          })
          ) : (
            <h2>select user</h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default Customize;
