import React from 'react';
import { ListItem, Icon } from '@common';

/* this compoenntn is somewhat experimental */
/* Some of the code should be moved to a more general compontnt */
/* And this component should then use composition */
const SortableListItem = ({
  config,
  show_header,
  highlight = false,
  sort,
  setSort,
  desc,
  setDesc,
  expandable_content,
  expandable_content_visible,
  style,
  ...rest
}) => {
  if (typeof config === 'undefined') {
    return <span>No config</span>;
  }

  /*const header = [
		{ title: "#", prop_name: "number", is_sortable: true, alignment: "left" },
		{ title: "Team", alignment: "left" },
		{ title: "Score", prop_name: "score", is_sortable: true, alignment: "left" },
		{ title: "Matches", prop_name: "matches", is_sortable: true, alignment: "left" },
		{ title: "Wins", prop_name: "wins", is_sortable: true, alignment: "left" },
		{ title: "Looses", prop_name: "looses", is_sortable: true, alignment: "left" },
		{ title: "Recent Matches", alignment: "left" },
	];*/

  const setListSort = (sort_item) => {
    if (sort === sort_item) {
      if (!desc) {
        setDesc(true);
        return;
      } else {
        setDesc(false);
        setSort(false);
      }
    } else {
      // setDesc(!desc);
      setSort(sort_item);
    }
  };

  // const expandToggle = () => {
  // 	alert("Hello");
  // };

  if (show_header) {
    return (
      <div className="row tiny-gutter-row">
        {config.map((c) => {
          if (c.is_sortable) {
            return (
              <div className={`col-${c.col} ${c.alignment}`} style={c.style}>
                <button onClick={() => setListSort(c.prop_name)}>
                  {c.title} <Icon name="up-down" />
                </button>
              </div>
            );
          }
          return (
            <div
              className={`col-${c.col} ${c.alignment}`}
              style={c.prop_name === 'position' ? { marginLeft: '20px' } : null}
            >
              {c.title}
            </div>
          );
        })}
      </div>
    );
  }

  if (typeof expandable_content !== 'undefined') {
    return (
      <React.Fragment>
        <ListItem style={{ background: highlight && 'rgba(255,0,0,0.2)' }}>
          <div className="row tiny-gutter-row">
            {config.map((c) => {
              return (
                <div
                  className={`col-${c.col} ${c.alignment} `}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {rest[c.prop_name]}
                </div>
              );
            })}
          </div>
        </ListItem>
        <div
          className={
            expandable_content_visible
              ? 'sortable-expandable-content show'
              : 'sortable-expandable-content'
          }
        >
          {expandable_content}
        </div>
      </React.Fragment>
    );
  }

  return (
    <ListItem>
      <div className="row tiny-gutter-row " style={style}>
        {config.map((c) => {
          // return <div className={`col-${c.col} ${c.alignment}`} style={{}}>{rest[c.prop_name]}</div>;
          return (
            <div className={`col-${c.col} ${c.alignment}`} style={{ ...c.style }}>
              {c.prop_name === 'position' ? (
                <React.Fragment>
                  <img
                    src={require(`@img/${rest[c.prop_name]?.toLowerCase()}.svg`)}
                    alt={rest[c.prop_name]}
                    width={20}
                    height={20}
                  />
                  <span style={{ paddingLeft: '15px' }}>{rest[c.prop_name]}</span>
                </React.Fragment>
              ) : c.suffix ? (
                rest[c.prop_name] + c.suffix
              ) : (
                rest[c.prop_name]
              )}
            </div>
          );
        })}
      </div>
    </ListItem>
  );
};

export default SortableListItem;
