import Tippy from '@tippyjs/react';
import React from 'react';
import { HiInformationCircle } from 'react-icons/hi';

function InformationLogo({ size = 22, placement = 'top', content = 'Tooltip', disabled = false ,style}) {
  return (
    <Tippy content={content} placement={placement} disabled={disabled}>
      <div style={style}>
        <HiInformationCircle size={size} color={'#68717e'} style={{ marginBottom: '-5px' }} />
      </div>
    </Tippy>
  );
}
InformationLogo.defaultProps = {
  size :'22',
  palcement : 'top',
  content : 'Tooltip',
  disabled :false,
  style:{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }
}

export default InformationLogo;
