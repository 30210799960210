import React from 'react';

const List = (props) => {
  let classes = 'list';

  if (typeof props.classes !== 'undefined') {
    classes += ' ' + props.classes;
  }

  return <ul className={classes}>{props.children}</ul>;
};

export default List;
