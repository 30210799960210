import React, { useEffect } from 'react';
import { Folder } from '../../_common';
import TournamentItem from '../../_common/TournamentItem/TournamentItem';
import ShareOptions from '../../_common/ShareOptions/ShareOptions';
import { FaFolder } from 'react-icons/fa';
import { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import DeleteItemsForever from '../../_modals/OrganizerPopup/deleteitems/deleteforever';
import { getCompetitions } from '../../../redux/action/competitionActions';
import { HiFolder } from 'react-icons/hi';

const Showfolders = ({ alldata, getTrans,isRecent }) => {
  // var isRecentGrid=true
  const [deleteId, setDeleteId] = useState();
  const [selectedCompitation, setSelectedCompitation] = useState();
  const [deleteforeveropen, setDeleteforeveropen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompetitions());
  }, []);
  const competitions = useSelector((state) =>
    state.FOLDERS.Competitions ? state.FOLDERS.Competitions : []
  );

  const isTrash = false;
  let showhead = { today: false, week: false, month: false, year: false, older: false };
  // console.log({alldata})
  alldata.map((folder, index) => {
    if (folder.whichday === 'today') {
      showhead.today = true;
    }
    if (folder.whichday === 'Earlier this week') {
      showhead.week = true;
    }
    if (folder.whichday === 'Earlier this month') {
      showhead.month = true;
    } 
    if (folder.whichday === 'Earlier this year') {
      showhead.year = true;
    }
    if (folder.whichday === 'Older') {
      showhead.older = true;
    }
  });
  const timeZone = useSelector((state) => state.TIMEZONE);
  // console.log('<<<', timeZone);
  const getDate = (date) => {
    return moment(date).tz(timeZone?.timeZone);
  };
  const handleCompetitionRoute = (id) =>{
    let url = `/competition/${id}`
    window.open(url, '_blank').focus();
  }

  return (
    <>
    <div>
      <h2 style={{marginTop:'-30px',paddingLeft:'3px'}}>{showhead.today === true ? 'Today' : null}</h2>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',paddingLeft:'3px' }}>
        {alldata.map((folder, i) =>
          folder?.isfolder === true && folder.timedifference < 25 ? (
            <div
              id={folder._id}
              style={{height:'244.5px',width:'300px'}}
              onClick={(e) => {
                // console.log('gnjkngj', e.target.id);
                setDeleteforeveropen(true);
                setDeleteId(e.target.id);
              }}>
              <TournamentItem
              ind={i}
              organizerData={folder}
              type={'COMPETITION'}
              selectedCompitation={selectedCompitation}
              onClick={() => setSelectedCompitation(i)}
              // isRecentGrid={isRecentGrid}
              isRecent={isRecent}
                key={folder._id}
                competition={folder}
                //   link_to={`/tournament/${123}`}
                image={
                  'https://drive-thirdparty.googleusercontent.com/128/type/application/vnd.google-apps.folder'
                }
                title={
                  <div style={{ color: selectedCompitation === i ? '#3eabf0' : 'white', display: 'flex', flexDirection: 'row' }}>
                    <span className="imgLogoContainer"></span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                  </div>
                }
              
                  threeDots={<ShareOptions
                    getTrans={getTrans}
                    isTrash={isTrash}
                    folderId={folder._id}
                    folderName={folder?.name}
                  />}
            
                organizerName={
                  <>
                   {folder.organizer?.name}
                  </>
                }
                date={
                  <div style={{color: selectedCompitation === i ? '#296f9e' : 'white'}}>
                  {'Last edited: ' +
                  moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss') +
                  ' By ' +
                  folder?.lastEditor?.firstName +
                  ' ' +
                  folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : folder?.iscompetition === true && folder.timedifference < 25 ? (
            <div id={folder._id} style={{height:'244.5px',width:'300px'}}>
              <TournamentItem
               ind={i}
               type={'COMPETITION'}
               isRecent={isRecent}
              selectedCompitation={selectedCompitation}
              onClick={() => setSelectedCompitation(i)}
                key={folder._id}
                //   link_to={`/tournament/${123}`}
                organizerData={folder}
                image={folder.thumbnail}
                onRouting= {()=> handleCompetitionRoute(folder._id)}
                title={
                  <div style={{ color: selectedCompitation === i ? '#3eabf0' : 'white', display: 'flex', flexDirection: 'row' }}>
                    <span className="imgLogoContainer">
                      <img
                        className=" imgLogoWidth imgLogo  imgLogoCropped"
                        src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                        alt="Insight Gaming"
                        width={'10px'}
                      />
                    </span>

                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                    
                  </div>
                }
                threeDots={
                  <ShareOptions
                        getTrans={getTrans}
                        competition={folder._id}
                        folderName={folder?.name}
                      />
                }
                organizerName={
                  <div style={{width:'200px'}}>
                   {folder.organizer?.name}
                  </div>
                }
                date={
                  <div style={{color: selectedCompitation === i ? '#296f9e' : 'white',fontSize:'9px'}}>
                  {'Last edited: ' +
                  moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss') +
                  // moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss') +
                  ' By ' +
                  folder?.lastEditor?.firstName +
                  ' ' +
                  folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : null
        )}
      </div>
      <h2 style={{marginTop:'0px'}}>{showhead.week === true ? 'Earlier this week' : null}</h2>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,paddingLeft:'3px'}}>
        {alldata.map((folder, i) =>
          folder?.iscompetition === true &&
          folder.timedifference < 169 &&
          folder.timedifference > 24 ? (
            <div id={folder._id} className='alltgekkjdksjn' style={{height:'244.5px',width:'300px'}}>
              <TournamentItem
                key={folder._id}
                ind={i}
                organizerData={folder}
                type={'COMPETITION'}
                isRecent={isRecent}
              selectedCompitation={selectedCompitation}
              onClick={() => setSelectedCompitation(i)}
                //   link_to={`/tournament/${123}`}
                onRouting= {()=> handleCompetitionRoute(folder._id)}
                image={folder.thumbnail}
                competition={folder}
                title={
                  <div style={{ color: selectedCompitation === i ? '#3eabf0' : 'white', display: 'flex', flexDirection: 'row' }}>
                    <span className="imgLogoContainer">
                      <img
                        className=" imgLogoWidth imgLogo  imgLogoCropped"
                        src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                        alt="Insight Gaming"
                        width={'10px'}
                      />
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                 
                  </div>
                }
                threeDots={  <ShareOptions
                  getTrans={getTrans}
                  competition={folder._id}
                  folderName={folder?.name}
                />}
                organizerName={
                  <div style={{width:'200px'}}>
                   {folder.organizer?.name}
                  </div>
                }
                date={
                  <div style={{color: selectedCompitation === i ? '#296f9e' : 'white',fontSize:'9px'}}>
                  {'Last edited: ' +
                  moment(getDate(folder.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss') +
                  ' By ' +
                  folder?.lastEditor?.firstName +
                  ' ' +
                  folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : null
        )}
      </div>
      <h2 style={{marginTop:'0px'}}>{showhead.month === true ? 'Earlier this month' : null}</h2>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',paddingLeft:'3px' }}>
        {alldata.map((folder, i) =>
          folder?.iscompetition === true &&
          folder.timedifference < 720 &&
          folder.timedifference > 169 ? (
            <div id={folder._id} style={{height:'244.5px',width:'300px'}}>
              <TournamentItem
               ind={i}
               organizerData={folder}
               type={'COMPETITION'}
               isRecent={isRecent}
              selectedCompitation={selectedCompitation}
              onClick={() => setSelectedCompitation(i)}
                key={folder._id}
                //   link_to={`/tournament/${123}`}
                onRouting= {()=> handleCompetitionRoute(folder._id)}
                competition={folder}
                image={folder.thumbnail}
                title={
                  <div style={{ color: selectedCompitation === i ? '#3eabf0' : 'white', display: 'flex', flexDirection: 'row' }}>
                    <span className="imgLogoContainer">
                      <img
                        className=" imgLogoWidth imgLogo  imgLogoCropped"
                        src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                        alt="Insight Gaming"
                        width={'10px'}
                      />
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                   
                  </div>
                }
                threeDots={
                  <ShareOptions
                    getTrans={getTrans}
                    folderName={folder?.name}
                    competition={folder._id}
                  />
                 }
                organizerName={
                  <div style={{width:'200px'}}>
                  {folder.organizer?.name}
                 </div>
                }
                date={
                  <div style={{  color: selectedCompitation === i ? '#296f9e' : 'white',fontSize:'9px'}}>
                  {'Last edited: ' +
                  moment(getDate(folder.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss') +
                  ' By ' +
                  folder?.lastEditor?.firstName +
                  ' ' +
                  folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : null
        )}
      </div>
      <h2 style={{marginTop:'0px'}}>{showhead.year === true ? 'Earlier this year' : null}</h2>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,paddingLeft:'3px'}}>
        {alldata.map((folder, i) =>
          folder?.iscompetition === true &&
          folder.timedifference < 720 &&
          folder.timedifference > 8760 ? (
            <div id={folder._id} style={{height:'244.5px',width:'300px'}}>
              <TournamentItem
              ind={i}
              organizerData={folder}
              type={'COMPETITION'}
              isRecent={isRecent}
              selectedCompitation={selectedCompitation}
              onClick={() => setSelectedCompitation(i)}
                key={folder._id}
                //   link_to={`/tournament/${123}`}
                onRouting= {()=> handleCompetitionRoute(folder._id)}
                image={folder.thumbnail}
                competition={folder}
                title={
                  <div style={{ color: selectedCompitation === i ? '#3eabf0' : 'white', display: 'flex', flexDirection: 'row' }}>
                    <span className="imgLogoContainer">
                      <img
                        className=" imgLogoWidth imgLogo  imgLogoCropped"
                        src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                        alt="Insight Gaming"
                        width={'10px'}
                      />
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                  
                  </div>
                }
                threeDots={
                  <ShareOptions
                        getTrans={getTrans}
                        folderName={folder?.name}
                        competition={folder._id}
                      />
                }
                organizerName={
                  <div style={{width:'200px'}}>
                   {folder.organizer?.name}
                  </div>
                }
                date={
                  <div style={{color: selectedCompitation === i ? '#296f9e' : 'white',fontSize:'9px'}}>
                  {'Last edited: ' +
                  moment(getDate(folder.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss') +
                  ' By ' +
                  folder?.lastEditor?.firstName +
                  ' ' +
                  folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : null
        )}
      </div>
      <h2 style={{marginTop:'0px'}}>{showhead.older === true ? 'Older' : null}</h2>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,paddingLeft:'3px'}}>
        {alldata.map((folder, i) =>
          folder?.iscompetition === true && folder.timedifference > 8760 ? (
            <div id={folder._id} style={{height:'244.5px',width:'300px'}}>
              <TournamentItem
              ind={i}
              organizerData={folder}
              type={'COMPETITION'}
              isRecent={isRecent}
              selectedCompitation={selectedCompitation}
              onClick={() => setSelectedCompitation(i)}
                key={folder._id}
                //   link_to={`/tournament/${123}`}
                onRouting= {()=> handleCompetitionRoute(folder._id)}
                competition={folder}
                image={folder.thumbnail}
                title={
                  <div style={{ color: selectedCompitation === i ? '#3eabf0' : 'white', display: 'flex', flexDirection: 'row' }}>
                    <span className="imgLogoContainer">
                      <img
                        className=" imgLogoWidth imgLogo  imgLogoCropped"
                        src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                        alt="Insight Gaming"
                        width={'10px'}
                      />
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                  </div>
                }
                threeDots={ <ShareOptions
                  getTrans={getTrans}
                  folderName={folder?.name}
                  competition={folder._id}
                />}
                organizerName={
                  <div style={{width:'200px'}}>
                   {folder.organizer?.name}
                  </div>
                }
                date={
                  <div style={{color: selectedCompitation === i ? '#296f9e' : 'white',fontSize:'9px'}}>
                  {'Last edited: ' +
                  moment(getDate(folder.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss') +
                  ' By ' +
                  folder?.lastEditor?.firstName +
                  ' ' +
                  folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : null
        )}
      </div>
      {deleteId ? (
        <DeleteItemsForever
          open={deleteforeveropen}
          setDeleteforeveropen={() => setDeleteforeveropen(!deleteforeveropen)}
          deleteOrganizaionId={deleteId}
          isforever={true}
        />
      ) : null}
    </div>
    </>
  );
};
export default Showfolders;
