import React, { useEffect, useState } from 'react';
import MatchesTab from '../MyCompetitions/ConpetitionTabs/MatchesTab/MatchesTab';
import StagesTab from '../MyCompetitions/ConpetitionTabs/StagesTab';
import SeedingTab from '../MyCompetitions/ConpetitionTabs/SeedingTab';
import ParticipantsTab from '../MyCompetitions/ConpetitionTabs/ParticipantsTab/ParticipantsTab';
import LeaderboardTab from '../MyCompetitions/ConpetitionTabs/LeaderboardTab';
import PrizesTab from '../MyCompetitions/ConpetitionTabs/PrizesTab';
import InformationTab from '../MyCompetitions/ConpetitionTabs/InformationTab/InformationTab';
import SettingsTab from '../MyCompetitions/Settings/SettingsTab';
import { SimplePageWithTabs } from '../../_common';
import { useSelector } from 'react-redux';
import StagesTabs from './StageTabs';
import { useParams } from 'react-router-dom';
import UnSavedChanges from '../../_modals/UnSavedPopUp/UnSavedChanges';
import useRoles from '../../../utils/useRoles';
import Participants from '../../PreviewTabs/Participants/Participants';
import Overview from '../../PreviewTabs/Overview/Overview.jsx';
import Leaderboard from '../../PreviewTabs/Leaderboard/Leaderboard';
import Prizes from '../../PreviewTabs/Prizes/Prizes';
import Matches from '../../PreviewTabs/Matches/Matches';
import { useDispatch } from 'react-redux';
import {
  setInformationPreviewData,
  setPrizesPreviewData
} from '../../../redux/action/previewTabsActions';
const CompetitionTabs = ({ socketRef, userPermissions, previewOn, setHidePreview, setName,setLogo,setCover, setDefaultPictures }) => {
  const [openUnsavedPopup, setOpenUnsavedPopup] = useState(false);
  const [tabs, setTabs] = useState({});
  const [stageIndex, setStageIndex] = useState(0);
  let [stagesNames, setStagesNames] = useState([]);
  let [currentStageIndex, setCurrentStageIndex] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  //State taken ouut from Information tab//
  // const [allInformationData, setAllInformationData] = useState({});
  const allInformationData = useSelector((state) => state.PREVIEW.Information ?? null);
  ///Information tab//

  ///state for Prizes tabs//
  const [previewPrizes, setPreviewPrizes] = useState(null);
  ///state for Prizes tabs//
  const competitonData = useSelector(
    (state) => state.COMPETITION.CompetitionData?.data?.allStageMatches
  );
  const competition = useSelector((state) => state.COMPETITION.Competition.multiStageLeague ?? {});
  useEffect(() => {
    if (stageIndex !== 5) {
      //setting informationdata to empty if tab changes because we don't need to save state on tab change. state was saved for preview
      dispatch(setPrizesPreviewData(null));
      // setPreviewPrizes(null);
    } else if (stageIndex !== 6) {
      dispatch(setInformationPreviewData(null));
    }
    if (stageIndex > 0 && stageIndex < 3) {
      setHidePreview(true);
    } else {
      setHidePreview(false);
    }
  }, [stageIndex]);

  useEffect(() => {
    let _competition = { ...competition };
    if (allInformationData) {
      // debugger
      Object.keys(allInformationData).forEach((el) => {
        if (_competition.hasOwnProperty(el)) {
          _competition[el] = allInformationData[el];
        }
      });
    }
    let previewTabs = {
      Matches: <Matches id={competition?._id} league={competition} />,
      Stages: null,
      Seeding: null,
      Participants: <Participants id={competition?._id} league={competition} />,
      Leaderboard: (
        <Leaderboard
          id={competition?._id}
          league={competition}
          defaultLogo={competition?.game?.defaultTeamLogo}
        />
      ),
      Prizes: (
        <Prizes
          id={competition?._id}
          teams={competition?.teams}
          prizes={competition?.prizes}
          leagueFinished={competition?.leagueFinished}
        />
      ),
      Information: <Overview id={competition?._id} league={_competition} />,
      Settings: <Overview id={competition?._id} league={competition} />
    };
    let _tabs = {
      Matches: (
        <StagesTabs
          userPermissions={userPermissions}
          competitionData={competitonData}
          setParentStagesNames={setStagesNames}
          setParentCurrentStageIndex={setCurrentStageIndex}
        />
      ),
      Stages: userPermissions?.editFormat ? (
        <StagesTab
          userPermissions={userPermissions}
          id={id}
          setParentStagesNames={setStagesNames}
          setParentCurrentStageIndex={setCurrentStageIndex}
        />
      ) : null,
      Seeding: userPermissions?.editFormat ? (
        <SeedingTab
          userPermissions={userPermissions}
          id={id}
          stagesCount={stagesNames.length}
          currentParentStageIndex={currentStageIndex}
        />
      ) : null,
      Participants: userPermissions?.manageParticipants ? (
        <ParticipantsTab userPermissions={userPermissions} />
      ) : null,
      Leaderboard: userPermissions?.editFormat ? (
        <LeaderboardTab
          userPermissions={userPermissions}
          shortName={competition?.game?.shortName}
          defaultLogo={competition?.game?.defaultTeamLogo}
        />
      ) : null,
      Prizes: userPermissions.administrateCompetitions ? (
        <PrizesTab competitonData={competitonData} userPermissions={userPermissions} />
      ) : null,
      Information: userPermissions.administrateCompetitions ? (
        <InformationTab setName={setName} setLogo={setLogo} setCover={setCover} socketRef={socketRef} userPermissions={userPermissions} />
      ) : null,
      Settings: userPermissions.administrateCompetitions ? (
        <SettingsTab userPermissions={userPermissions} />
      ) : null
    };
    let _stageIndex = stageIndex;
    let currentTab = Object.keys(_tabs)[_stageIndex];
    if (previewOn) {
      Object.keys(_tabs).forEach((el) => {
        if (el === currentTab) {
          _tabs[el] = previewTabs[el];
        } else {
          _tabs[el] = null;
        }
      });
      setTabs(_tabs);
    } else {
      setTabs(_tabs);
    }
  }, [previewOn]);
  useEffect(() => {
    let _tabs = {
      Matches: (
        <StagesTabs
          userPermissions={userPermissions}
          competitionData={competitonData}
          setParentStagesNames={setStagesNames}
          setParentCurrentStageIndex={setCurrentStageIndex}
        />
      ),
      Stages: userPermissions?.editFormat ? (
        <StagesTab
          userPermissions={userPermissions}
          id={id}
          setParentStagesNames={setStagesNames}
          setParentCurrentStageIndex={setCurrentStageIndex}
        />
      ) : null,
      Seeding: userPermissions?.editFormat ? (
        <SeedingTab
          userPermissions={userPermissions}
          id={id}
          stagesCount={stagesNames.length}
          currentParentStageIndex={currentStageIndex}
        />
      ) : null,
      Participants: userPermissions?.manageParticipants ? (
        <ParticipantsTab userPermissions={userPermissions} />
      ) : null,
      Leaderboard: userPermissions?.editFormat ? (
        <LeaderboardTab userPermissions={userPermissions} shortName={competition?.game?.shortName}/>
      ) : null,
      Prizes: userPermissions.administrateCompetitions ? (
        <PrizesTab competitonData={competitonData} userPermissions={userPermissions} />
      ) : null,
      Information: userPermissions.administrateCompetitions ? (
        <InformationTab setName={setName} setLogo={setLogo} setCover={setCover} socketRef={socketRef} userPermissions={userPermissions} />
      ) : null,
      Settings: userPermissions.administrateCompetitions ? (
        <SettingsTab userPermissions={userPermissions} />
      ) : null
    };
    // for(let property in tabs){
    //   if(NOT_MANAGER.includes(property)){
    //   newTabs[property] =tabs[property]
    //   }
    // }
    setTabs(_tabs);
    // console.log({_permission})
  }, [userPermissions]);

  return (
    <div className="main-page-forall">
      <SimplePageWithTabs
        title={''}
        tabs={tabs}
        setStageIndex={setStageIndex}
        isMainTabs={true}
        setOpenUnsavedPopup={setOpenUnsavedPopup}
        base_route={`/competition/${id}`}
      />
      {/* <UnSavedChanges open={openUnsavedPopup} setDefaultPictures={setDefaultPictures} onClose={setOpenUnsavedPopup} /> */}
    </div>
  );
};
export default CompetitionTabs;
