import React, { useEffect, useState } from 'react';
import { ListItem } from '@common';
import { RiCheckLine, RiPencilLine } from 'react-icons/ri';

const TeamMemberListItemType2 = ({
  img,
  title,
  subtitle,
  position = false,
  role,
  nationality = {},
  actions = [],
  pending = false,
  editable = false,
  availableNewRoles = [],
  onChangeRole,
  positionData,
  subPosition,
  game
}) => {
  let initialLetters = title && title.charAt(0) + title.charAt(1);
  const [editingRole, setEditingRole] = useState(false);
  const [newRole, setNewRole] = useState();
  useEffect(() => {
    if (position && position.name) {
      setNewRole('Player:' + position.name);
    }
  }, [position]);

  const onEditMemberRole = () => {
    onChangeRole(newRole);
    setEditingRole(false);
  };

  return (
    <ListItem>
      <div className="row team-member-list-item justify-content-between">
        <div className="col-4 team-member-details">
          <div className="col-12 pl-0 pr-0">
            {img ? (
              <div
                className="team-member-img"
                style={{ backgroundImage: `url(${img})` }}
                alt={title}
              />
            ) : (
              <div className="no_profile" style={{ backgroundColor: `${game?.mainColour}1A` }}>
                {initialLetters}
              </div>
            )}
            <div className="team-member-info">
              <span className="text-14 bold">{title || '[Deleted]'}</span>
              {subtitle && <span className="text-14 text-faded">{subtitle}</span>}
            </div>
          </div>
        </div>
        <div className="col-7 team-member-details align-items-centerd">
          <div className="row ">
            <div className="col-4">
              {position.name ? (
                <div className="substituteSubRoleTypes icon-alignment">
                  <div className="sub-icon">
                    <img
                      src={positionData?.icon || position.icon}
                      alt={position.name}
                      className="position-icon"
                    />
                  </div>
                  <div className="withoutSubRole">
                    <div className="sub-text-icon">
                      <span className="text-14 uppercase bold">{position.name}</span>
                    </div>
                  </div>
                  {subPosition && subPosition.name !== 'No Specification' && (
                    <div className="text-14 text-faded subRole no-spec">{subPosition.name}</div>
                  )}
                  {/* {!pending && editable && (
                    <button onClick={() => setEditingRole(true)} className="edit-teammem-role">
                      <RiPencilLine />
                    </button>
                  )} */}
                </div>
              ) : (
                role && (
                  <span>
                    <span className="text-14 uppercase bold">{role}</span>
                    {subPosition && subPosition.name !== 'No Specification' && (
                      <div className="text-14 text-faded">{subPosition.name}</div>
                    )}
                  </span>
                )
              )}
            </div>

            <div className="col-4">
              {nationality && (
                <>
                  {nationality.code && (
                    <img
                      src={`https://flagcdn.com/w20/${nationality.code?.toLowerCase()}.png`}
                      alt={nationality.name}
                    />
                  )}

                  <span className="text-14 uppercase bold">{nationality.name}</span>
                </>
              )}
            </div>
            <div className="col-3 team-member-actions">
              <div>
                {actions.map((action, i) => (
                  <React.Fragment key={i}>{action}</React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default TeamMemberListItemType2;
