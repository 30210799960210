import React from 'react';
import { useDragLayer } from 'react-dnd';
import Folder from '../Folder/Folder';
import DraggingItem from './DraggingItem/DraggingItem';
import DraggingItemMultiple from './DraggingItem/DraggingItemMultiple';
// import { ItemTypes } from "./ItemTypes";
// import { Box } from "./Box";

const styles = {
  display: 'inline-block'
  //   height: '400px'
  //   transform: "rotate(-2deg)",
  //   WebkitTransform: "rotate(-2deg)"
};

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};
function getItemStyles(initialOffset, currentOffset, isSnapToGrid, mouseOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }
  let { x, y } = currentOffset;
  let _x = mouseOffset.x;
  let _y = mouseOffset.y;
  //   debugger
  const transform = `translate(${_x - 15}px, ${_y - 15}px)`; // -15 for getting it closer to mouse pointer
  return {
    transform,
    WebkitTransform: transform
  };
}
export const CustomDragLayerGrid = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset, mouseOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      mouseOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging()
    })
  );
  function renderItem() {
    switch (itemType) {
      case 'CARD':
        return (
          <div style={styles}>
            {item.total > 1 ? <DraggingItemMultiple item={item} /> : <DraggingItem item={item} />}
          </div>
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, props.snapToGrid, mouseOffset)}>
        {renderItem()}
      </div>
    </div>
  );
};
