import React, { useContext, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import Dropdown from './Dropdown/Dropdown';
import default_profile_picture from '@img/rallerpenos-image.png';
import logo_header from '@img/logo-header.svg';
import { DynamicSearch, Icon } from '@common';
import NotificationsDropdown from './NotificationsDropdown/NotificationsDropdown';
import GameSwitcher from './GameSwitcher/GameSwitcher';
import GlitchText from '../../_common/GlitchText/GlitchText';
import TranslationDropdown from './TranslationDropdown/TranslationDropdown';
import useTranslated from '../../../helpers/translationHelper';
import TimezoneDropdown from './TimezoneDropdown/TimezoneDropdown';
import { BiPlus } from 'react-icons/bi';
import { useEffect } from 'react';
import NewCompetionModal from './NewCompetition/NewCompetition';
import { useSelector } from 'react-redux';

import useRoles from '../../../utils/useRoles';

const toggleSidebar = (sidebarOpen, setSidebarOpen) => {
  setSidebarOpen(!sidebarOpen);
  // localStorage.setItem('sidebarOpen', !sidebarOpen);
};

const HeaderButtons = ({ setShowCompModal, showCompetitionModal }) => {
  const history = useHistory();
  const { authenticated, setAuthenticated, user, setShowAuth } = useContext(AppContext);
  const InheritedPermissions = useSelector((state) =>
    state.FOLDERS.InheritedPermissions ? state.FOLDERS.InheritedPermissions : []
  );
  const currentUserPermission = useSelector((state) =>
    state.FOLDERS.currentUserPermission ? state.FOLDERS.currentUserPermission : []
  );

  const [disableButton, setDisableButton] = useState(true);
  const Leagues_Admin = useTranslated('id_top_bar_(settings)_0');
  const Ghost_Dashboard = useTranslated('id_top_bar_(settings)_1');
  const Settings = useTranslated('id_top_bar_(settings)_2');
  const Logout = useTranslated('id_top_bar_(settings)_3');
  const [isenabem, setEnabled] = useState({
    ele: document.getElementById('NewComprtitionButton'),
    location: window.location.pathname
  });
  const location = useLocation();
  // const permissions = useRoles()
  useEffect(() => {
    // const aa = InheritedPermissions.concat(currentUserPermission).map((a) => {
    //   return a.inheted.permission.createAndDeleteCompetitions?a.inheted.permission.createAndDeleteCompetitions : a.userPermission?.permission?.createAndDeleteCompetitions;
    // });
    // return inharitedpermission?'': a.userPermission?.permission?.createAndDeleteCompetitions;
    // console.log({permissions});
    let _pathname = location.pathname.split('/')[1];
    if (_pathname === 'my-competitions' || _pathname === 'folders') {
      // const aa = InheritedPermissions.concat(currentUserPermission); //creating a competition is based on inhertted permissions that's why this is commented out
      const aa = InheritedPermissions;
      let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
      let orgAccess = user?.state?.permissions?.user?.OrganizerAccess ?? false;
      let sharedOrganizer = user?.state?.sharedOrganizer > 0 ? true : false;
      if (user?.state?.admin || orgAdmin) {
        setDisableButton(false);
      } else if (orgAccess || sharedOrganizer) {
        let permissions = aa.find((el) => el.user._id === user.state._id)?.userPermission ?? {};
        if (
          permissions?.permission?.administrateCompetitions ||
          permissions?.permission?.createAndDeleteCompetitions
        ) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      }
    } else {
      setDisableButton(true);
    }
  }, [InheritedPermissions, currentUserPermission, location]);

  // useEffect(() => {
  //   const aa = InheritedPermissions.concat(currentUserPermission).map((a) => {
  //     return a.inheted.permission.createAndDeleteCompetitions?a.inheted.permission.createAndDeleteCompetitions : a.userPermission?.permission?.createAndDeleteCompetitions;
  //   });
  // return inharitedpermission?'': a.userPermission?.permission?.createAndDeleteCompetitions;
  // debugger

  //   setDisableButton(aa);
  // }, []);

  useEffect(() => {
    let ele = document.getElementById('NewComprtitionButton');
    if (window.location.pathname.includes('my-competitions')) {
      if (ele) {
        ele.disabled = false;
        ele.style.background = '#3eabf0';
      }
    } else {
      if (ele) {
        ele.disabled = true;
        ele.style.background = 'gray';
      }
    }
  }, [window.location.pathname]);

  const logout = () => {
    localStorage.removeItem('sessionToken');
    setAuthenticated(false);
    // history.push('/');
    window.location.replace(
      process.env.REACT_APP_LEAGUES_URL
        ? `${process.env.REACT_APP_LEAGUES_URL}/?STR=true`
        : `${process.env.REACT_APP_FRONTEND_URL}/?STR=true`,
      '_self'
    );
  };
  let usertranslate1 = useTranslated('id_top_bar_3');
  let usertranslate2 = useTranslated('id_top_bar_4');
  if (authenticated && user.state) {
    return (
      <React.Fragment>
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/players/${user.state._id}`}
          className="header-profile-link">
          <img
            src={user.state.avatarImage || default_profile_picture}
            style={{ borderRadius: '15px' }}
            alt=""
          />
          <span className="bold">{user.state.nickname || user.state.firstName}</span>
        </a>

        <NotificationsDropdown />

        <Dropdown
          label={<Icon name="cog" />}
          render={() => (
            <>
              {user.state.admin && (
                <>
                  <a
                    href="https://admin.leagues.gg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-settings-link">
                    <Icon name="cog" /> {Leagues_Admin}
                  </a>
                  <a
                    href="https://pr.leagues.gg/ghost/#/posts"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-settings-link">
                    <Icon name="cog" /> {Ghost_Dashboard}
                  </a>
                </>
              )}
              <Link to="/account" className="header-settings-link">
                <Icon name="cog" /> {Settings}
              </Link>
              <div onClick={logout} className="header-settings-link">
                <Icon name="logout" /> {Logout}
              </div>
            </>
          )}
        />
        <TimezoneDropdown />
        <TranslationDropdown />

        <button
          disabled={disableButton}
          className={'button wide-primary'}
          // id="NewComprtitionButton"

          style={
            disableButton
              ? { height: '36px', backgroundColor: '#68717e', color: '#fffff' }
              : { height: '36px', backgroundColor: '#3eabf0' }
          }
          onClick={() => {
            setShowCompModal(!showCompetitionModal);
          }}>
          <BiPlus
            style={{ fontSize: '1em', marginBottom: '-2px', strokeWidth: '3', opacity: '100%' }}
          />
          NEW COMPETITION
        </button>
      </React.Fragment>
    );
  } else {
    setShowAuth('login');
  }

  return (
    <React.Fragment>
      <TranslationDropdown />

      {/* //     <button className="button bordered medium ml-3 mr-3" onClick={() => setShowAuth("login")}>
  //     {usertranslate1}
  //     </button>
  //     <button className="button primary medium" onClick={() => setShowAuth("register")}>
  //     {usertranslate2}
  //     </button> */}
    </React.Fragment>
  );
};

const Header = ({ sidebarOpen, setSidebarOpen }) => {
  const [showCompetitionModal, setShowCompModal] = useState(false);

  return (
    <React.Fragment>
      <header className="global-header">
        <div className="header-logo">
          <button className="menu-icon" onClick={() => toggleSidebar(sidebarOpen, setSidebarOpen)}>
            {sidebarOpen ? <Icon name="menu-close" /> : <Icon name="menu-open" />}
          </button>
          <div style={{ cursor: 'pointer' }}>
            <Link to="/" className="logo-text">
              <GlitchText animateOnHover>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={logo_header} alt="Leagues logo" style={{marginBottom:'5px'}}/>
                  <strong className="uppercase logo__text" style={{top:'0px'}}>{useTranslated('id_top_bar_0')}</strong>
                </div>
              </GlitchText>
            </Link>
          </div>
          <span className="header-game-switcher-bleft">&nbsp;</span>
          <GameSwitcher />
        </div>

        <div className="header-actions">
          <HeaderButtons
            setShowCompModal={setShowCompModal}
            showCompetitionModal={showCompetitionModal}
          />
        </div>
      </header>
      {showCompetitionModal === true ? (
        <NewCompetionModal open={showCompetitionModal} onClose={setShowCompModal} />
      ) : null}
    </React.Fragment>
  );
};

export default Header;
