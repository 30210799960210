import React from 'react';
import { Icon } from '@common';

const Card = (props) => {
  const { icon, title, subtitle } = props;
  let classes = props.classes ?? 'corner-outline';

  if (typeof props.children !== 'undefined') {
    return (
      <div className="card-container">
        <div className={`card ${classes}`}>
          <div>
            <h3 className="uppercase m-0 pb-3 pt-2">{title}</h3>
            <span className="">{props.children}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card-container">
      <div className={`card ${classes}`}>
        <div className="overlay"></div>
        {icon}
        <div className="text">
          <h4 className="uppercase">{title}</h4>
          <span className="faded">{subtitle}</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
