var moment = require('moment-timezone/moment-timezone');
export const getConvertedDate = (date, payloadF, competitionF, time) => {
  if (time) {
    let append = '18:00';
    let _date = new moment(date.split('T')[0] + ' ' + time).format('YYYY-MM-DDTHH:mm');
    let arr = _date.split('T');
    return arr[0] + 'T' + append;
  }
  if (payloadF) {
    return new moment.tz(date, payloadF).format('YYYY-MM-DDTHH:mm');
  }
  if (competitionF) {
    return new moment.tz(date, payloadF).format('YYYY-MM-DDTHH:mm');
  }

  return moment().format('YYYY-MM-DDTHH:mm');
};
export const getFormattedDate = (date)=>{
  return moment(date).format('YYYY-MM-DDTHH:mm')
}
export const getDefaultDateTime = (date) => {
  let _date = date.split('T');
  return _date[0] + 'T18:00';
};

export const getUtcFormatDate = (date, payloadF, competitionF, time) => {
  if (time) {
    let append = 'T18:00:00Z';
    let _date = moment(date.split('T')[0] + ' ' + time)
      .utc()
      .format();
    let arr = _date.split('T');
    return arr[0] + 'T' + append;
  }
  if (payloadF) {
    return new moment.tz(date, payloadF).utc().format();
  }
  if (competitionF) {
    return new moment.tz(date, payloadF).utc().format();
  }

  return moment(date).utc().format();
};

export const getDateAndTime = (date, timezone) => {
  if (timezone) {
    let _date = getConvertedDate(date, timezone);
    return _date.split('T');
  }
  return date.split('T');
};

export const arrangeWeekdays = (today) => {
  let weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let dayI = moment(today).day();
  let rest = weekdays.splice(0, dayI);
  return [...weekdays, ...rest];
};

export const getDateWithAddedWeeks = (date, weeks) => {
  let _date = new moment(date).add(weeks, 'w');
  return _date.format('YYYY-MM-DD');
};

export const addTimeinDate = (date, num, type) => {
  let _date = new moment(date).add(num, type).format('YYYY-MM-DDTHH:mm');
  return _date.split('T');
};
export const subtractTimeFromDate = (date, num, type) =>{
  let _date = new moment(date).subtract(num, type).format('YYYY-MM-DDTHH:mm');
  return _date.split('T');
}

export const getTimeInUTC = (date, time, format) => {
  let _date = `${date}T${time}:00`;
  const utcDate = moment.tz(_date, format).format();
  console.log('UTC Date : ', utcDate);
  return utcDate;
};


export const getWeekDay = (date,time)=>{
  let _date = `${date}T${time}:00`;
  return moment(_date).day();
}

export const getWeekDayFromArr = (date,time,arr)=>{
  let weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let _day = getWeekDay(date,time)
  return arr.findIndex(el=> el === weekdays[_day])
}