import {
  SET_COMETITIONDATA,
  SET_MATCHES,
  SET_COMPETITION,
  UPDATE_COMPETITION,
  SET_STAGES_DATA,
  APPEND_STAGES_DATA,
  SET_CURRENT_STAGE,
  REMOVE_STAGE_WITH_ID,
  UPDATE_SCORESYSTEM,
  SET_UNSAVED_STAGES,
  REPLACE_STAGE_AFTER_UPDATE,
  MATCHES_FILTER,
  ALL_TEAMS,
  SET_INFORMATION_DATA,
  SET_ANYCHANGE_IN_STAGE,
  CLEAR_UNSAVED_STAGES,
  SET_STAGE_INDEX,
  TOGGLE_MATCH_STATUS
} from './types';
import Axios from 'axios';
import { toast } from 'react-toastify';
let backend = process.env.REACT_APP_CORE_API;
export const getCompetitionsData = (id) => (dispatch) => {
  dispatch({type: 'SET_LOADING', payload: true})
  Axios.get(backend + `/api/multiStageLeague/${id}/matches`)
    .then((res) => {
      dispatch({
        type: SET_COMETITIONDATA,
        payload: res.data
      });
      dispatch({type: 'SET_LOADING', payload: false})
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: 'SET_LOADING', payload: false})
    });
};
export const getCompetitionsMatches =
  ({ id, query }) =>
  (dispatch) => {
    Axios.get(backend + `/api/organizer/competition/${id}/matches`, {
      params: { status: query?.status }
    })
      .then(async (res) => {
        dispatch({
          type: SET_MATCHES,
          payload: res.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
export const getCompetition = (id) => (dispatch) => {
  Axios.get(backend + `/api/organizer/competition/${id}`)
    .then((res) => {
      dispatch({
        type: SET_COMPETITION,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateCompetition = (payload) => ({
  type: UPDATE_COMPETITION,
  payload: payload
});

export const getFilterMatches = (payload) => (dispatch) => {
  dispatch({
    type: MATCHES_FILTER,
    payload: payload
  });
};
// export const getMatches = (arr) => (dispatch) => {
//   dispatch({
//     type: SET_MATCHES,
//     payload: arr
//   });
// };
export const getInformationData = (id) => (dispatch) => {
  Axios.get(backend + `/api/organizer/competition/${id}`)
    .then((res) => {
      const multiStageLeague = res.data;
      dispatch({
        type: SET_INFORMATION_DATA,
        payload: multiStageLeague
      });
    })
    .catch((err) => {
      toast.error(err.msg);
      console.log(err);
    });
};

export const getStagesData =
  ({ id }) =>
  (dispatch) => {
    Axios.get(backend + `/api/multiStageLeague/${id}/stage`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type: SET_STAGES_DATA,
          payload: data
        });
      })
      .catch((err) => {
        toast.error(err.msg);
        console.log(err);
      });
  };
export const addNewStage = (id) => {
  const obj = {
    stageType: 'league',
    playThirdPlaceOrder: false,
    evenGroupBySize: true,
    groups: [
      {
        _id: 12313,
        noOfTeams: 10,
        color: '#19db60',
        overviewManualSort: false,
        name: 'Group A',
        teams: [],
        matchCompleted: false
      }
    ],
    pairingProcedure: 'Circle',
    homeTeam: 'Alternate',
    teamSize: 2,
    name: 'Stage 5',
    format: 1,
    encounters: 1,
    groupSize: 10,
    rankingCriteria: [],
    scoreSystem: [],
    // "createdAt": "2023-05-08T11:09:51.354Z",
    // "updatedAt": "2023-05-08T11:09:51.385Z",
    isMatchesGenerated: false
  };
  return {
    type: APPEND_STAGES_DATA,
    payload: obj
  };
};
export const createStage = (id) => (dispatch) => {
  Axios.post(backend + `/api/organizer/competition/${id}/stage/`, { multiStageLeague: id })
    .then((res) => {
      const { data } = res.data;
      const { msg } = res.data;
      // toast.success(msg);
      dispatch({
        type: APPEND_STAGES_DATA,
        payload: data
      });
      dispatch(getCompetition(id));
      dispatch(getCompetitionsData(id));
    })
    .catch((err) => {
      const { msg } = err.response.data;
      toast.error(msg);
      console.log(err);
    });
};
export const deleteStage = (multiStageLeague, stageId) => (dispatch) => {
  if(stageId.length < 6){
    dispatch({
      type: REMOVE_STAGE_WITH_ID,
      payload: stageId
    });
  }else{
    Axios.delete(backend + `/api/organizer/competition/${multiStageLeague}/stage/${stageId}`)
      .then((res) => {
        // const {data} = res.data
        let deletedId = res.data.msg.split(' ').pop();
        if (deletedId) {
          toast.error('Successfully Deleted a Stage');
          dispatch({
            type: REMOVE_STAGE_WITH_ID,
            payload: deletedId
          });
        }
      })
      .catch((err) => {
        const { msg } = err.response.data;
        toast.error(msg);
        console.log(err);
      });
  }
};
export const getAllTeams = (id) => (dispatch) => {
  Axios.get(backend + `/api/multiStageLeague/${id}/group/allTeams`)
    .then((res) => {
      const { data } = res.data;
      const { msg } = res.data;
      // toast.success(msg);
      dispatch({
        type: ALL_TEAMS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      // if (err.message) return toast.error(err.message);
      // const { msg } = err?.response?.data;
      // toast.error(msg);
    });
};
export const updateStage = (id, obj,oldId) => (dispatch) => {
  if (obj.groups.length > 0) {
    //FOR REMOVING ID FROM NEW GROUPS
    obj = {
      ...obj,
      groups: obj.groups.map((group) => {
        let { _id, ...rest } = group;
        if (typeof _id === 'number') {
          return rest;
        } else {
          return group;
        }
      })
    };
  }
  Axios.put(`${backend}/api/organizer/competition//${id}/stage/${obj._id}`, obj)
    .then((res) => {
      let { data } = res.data;
      dispatch(replaceStageAfterUpdate(data,oldId));
      dispatch(getCompetition(id));
      toast.success('Stage has been updated successfully');
    })
    .catch((err) => {
      toast.error('Something went wrong while updating stage');
    });
  dispatch(setIsAnyChangeInTab(false));
};
export const setIsAnyChangeInTab = (issame) => ({ type: SET_ANYCHANGE_IN_STAGE, payload: issame });
export const setCurrentStage = (obj) => (dispatch) => {
  return dispatch({ type: SET_CURRENT_STAGE, payload: obj });
};
export const setUnSavedStages = (obj) => {
  console.log('it is dispatched');
  // const _obj = JSON.parse(obj)
  return {
    type: SET_UNSAVED_STAGES,
    payload: obj
  };
};
export const clearUnsavedStages = () => ({
  type: CLEAR_UNSAVED_STAGES
});
export const replaceStageAfterUpdate = (stage,oldId) => (dispatch) => {
  dispatch({ type: REPLACE_STAGE_AFTER_UPDATE, payload: {stage,oldId} });
};

export const setScoreAction =
  ({ matchId, payload, competitionId, gameName }) =>
  (dispatch) => {
    let url = `${process.env.REACT_APP_CORE_API}/api/matches/${matchId}/?type=League`;
    if (gameName === 'LoL') {
      url = `${process.env.REACT_APP_LOL_API}/api/matches/${matchId}/updateScore`;
    }
    if (gameName === 'CSGO') {
      url = `${process.env.REACT_APP_CSGO_API}/api/matches/${matchId}/?type=League`;
    }
    Axios.put(url, payload)
      .then((res) => {
        Axios.get(
          `${process.env.REACT_APP_CORE_API}/api/matches/updateBracketOfUpdatedScore/${matchId}`
        )
          .then((res) => {
            const { data } = res.data;
            const { msg } = res.data;
            toast.success(msg);
            if (competitionId) {
              dispatch(
                getCompetitionsMatches({
                  id: competitionId,
                  query: { status: '', limit: '100', page: '1' }
                })
              );
            }
          })
          .catch((error) => toast.error(error));
      })
      .catch((err) => {
        const { msg } = err?.response?.data ?? 'Something Went Wrong';
        toast.error(msg);
        console.log(err);
      });
  };
export const setStageIndexGlobally =
  (ind = 0) =>
  (dispatch) => {
    return dispatch({ type: SET_STAGE_INDEX, payload: Number(ind) });
  };
export const updateMatchStatus = (match)=>({
  type : TOGGLE_MATCH_STATUS,
  payload : match
})