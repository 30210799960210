import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import SingleNotification from './SingleNotification';
import { AppContext } from '@context/AppContext';
import { toast } from 'react-toastify';
import { SimplePageWithTabs, Icon, Loading } from '@common';
import useTranslated from '../../helpers/translationHelper';

const Notifications = () => {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  let useTranslated1 = useTranslated('id_notifications_0');

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/notifications`).then(({ data }) => {
      setNotifications(data);
      setLoading(false);
    });
  }, []);

  const onMarkAllRead = async () => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/notifications/markAllRead`);
      setNotifications(notifications.map((notification) => ({ ...notification, read: true })));

      // Update notifications count in header
      user.dispatch({ type: 'update', payload: { notifications: 0 } });
    } catch (e) {
      toast.error("Couldn't change notification status");
    }
  };

  const setRead = (id, bool) => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        read: notification._id === id ? bool : notification.read
      }))
    );
  };

  return (
    <SimplePageWithTabs
      title="Notifications"
      top_right={
        <button className="button bordered" onClick={onMarkAllRead}>
          {useTranslated('id_notifications_3')}
        </button>
      }
    >
      <ul class="fa-ul">
        {loading ? (
          <div div className="col-12 text-center">
            <Loading />
          </div>
        ) : !notifications.length ? (
          <div className="league-no-matches">
            <Icon name="news" />
            <p>There are no {useTranslated1} to show</p>
          </div>
        ) : (
          notifications.map((notification) => (
            <li>
              <span className="notification-icon-span" key={notification._id}>
                {!notification.img ? (
                  <i class="fas fa-bell"></i>
                ) : (
                  <img className="notification-image" src={notification.img}></img>
                )}
                <SingleNotification
                  {...notification}
                  setRead={(bool) => setRead(notification._id, bool)}
                />
              </span>
            </li>
          ))
        )}
      </ul>
    </SimplePageWithTabs>
  );
};

export default Notifications;
