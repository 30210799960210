import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import EditImageModal from '../../../EditImageModal/EditImageModal';
import Axios from 'axios';
import { toast } from 'react-toastify';
import useTranslated from '../../../../../helpers/translationHelper';

const NonIntegrationGame = ({ afterConnect, game, user }) => {
  const [name, setName] = useState('');
  const [about, setAbout] = useState('');

  const [modals, setModals] = useState({
    logo: false,
    banner: false
  });
  const [images, setImages] = useState({});

  const lableBanner = useTranslated('id_pop-up_-_upload_image_3');
  const lableLogo = useTranslated('id_pop-up_-_upload_image_2');

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/players`, {
        name,
        about,
        images,
        game,
        user
      });
      afterConnect(data);
    } catch (e) {
      const msg =
        e.response && e.response.data ? e.response.data.msg : 'There was a problem creating player';
      toast.error(msg);
    }
  };

  const update = async (type, image) => {
    setImages({ ...images, [type]: image });
    setModals({ ...modals, [type]: false });
  };

  return (
    <form onSubmit={onSubmit} type="post">
      <div className="row form-row sm-gutters">
        {/* LOGO */}
        <div
          className="image-card round-logo"
          onClick={() => setModals({ ...modals, logo: true })}
          style={{ backgroundImage: `url(${images.logo})` }}
        >
          Add Avatar
        </div>
        <EditImageModal
          name={lableLogo} /* "Logo" */
          width={250}
          height={250}
          borderRadius={250}
          open={modals.logo}
          onClose={() => setModals({ ...modals, logo: false })}
          afterImageUpload={(file) => update('logo', file)}
        />

        {/* BANNER */}
        <div
          className="image-card"
          onClick={() => setModals({ ...modals, banner: true })}
          style={{ backgroundImage: `url(${images.banner})` }}
        >
          Add Banner
        </div>

        <EditImageModal
          name={lableBanner} /* "Banner" */
          width={800}
          height={200}
          crop={false}
          rotate={false}
          borderRadius={0}
          open={modals.banner}
          onClose={() => setModals({ ...modals, banner: false })}
          afterImageUpload={(file) => update('banner', file)}
        />

        <div className="col-12 mb-4">
          <label htmlFor="time">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            className={`form-input`}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="col-12 mb-4">
          <label htmlFor="description">About (max 200 words)</label>
          <textarea
            // ref={register({ maxLength: { value: 2000, message: "Description too long" } })}
            type="text"
            value={about}
            max={2000}
            rows={4}
            id="description"
            name="description"
            autoComplete="description"
            placeholder="Write something about yourself"
            className={`form-input`}
            onChange={(e) => setAbout(e.target.value)}
          />
        </div>
      </div>

      <button type="submit" className="mt-4 button primary thicc">
        Save
      </button>
    </form>
  );
};

export default NonIntegrationGame;
