import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { Form, Button, Row, Col, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './a.css';
import './_style.scss';
import { getOrganizations } from '../../../../../redux/action/competitionActions';
import UploadLogobox from '../../../../_modals/EditImageModal/UploadLogobox';
import Cropper from '../../../../Image/Cropper';
import { HiPencil } from 'react-icons/hi';
import Uploadlogo from '../../../../_modals/Uploadlogo/Uploadlogo';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useForm } from 'react-hook-form';
import { FaDiscord, FaTwitch, FaYoutube } from 'react-icons/fa';
const AppearanceTab = ({
  setAllInformationData,
  allInformationData,
  setInformationCall,
  socketRef,
  setName,
  setLogo,
  setCover
}) => {
  const dispatch = useDispatch();
  // const [logo, setLogo] = useState();
  // const organizerdata = useSelector((state) => state.COMPETITION.informationData.multiStageLeague);
  const organizerdata = useSelector((state) => state.COMPETITION.Competition.multiStageLeague);

  const [detail, setDetails] = useState({
    name: '',
    description: '',
    logo: '',
    thumbnail: '',
    cover: '',
    discord: '',
    youtube: '',
    twitch: ''
  });
  const { register, handleSubmit, errors } = useForm();
  const [logoEditModal, setLogoEditModal] = useState(false);
  const [contentState, setContentState] = useState(); // ContentState JSON
  useEffect(() => {
    setDetails({
      description: organizerdata ? organizerdata?.divisionDescription : null,
      logo: organizerdata ? organizerdata?.logo : null,
      cover: organizerdata ? organizerdata?.cover : null,
      thumbnail: organizerdata ? organizerdata?.thumbnail : null,
      discord: organizerdata ? organizerdata?.appearLinks[0]?.discord : null,
      youtube: organizerdata ? organizerdata?.appearLinks[0]?.youtube : null,
      twitch: organizerdata ? organizerdata?.appearLinks[0]?.twitch : null,
      name: organizerdata ? organizerdata.name : null
    });
  }, [organizerdata]);
  const updatenameAndLogo = () => {};
  // useEffect(() => {
  //   setDetails((state) => ({ ...state, logo: logo }));
  //   console.log('logo', logo);
  // }, [logo]);
  const updateLogo = (logo) => {
    setDetails((state) => ({ ...state, logo: logo }));
  };
  useEffect(() => {
    const { logo, cover, thumbnail, description } = detail;
    let appearLinks = [{}];
    if (detail.youtube || detail.twitch || detail.discord) {
      appearLinks = [{ youtube: detail.youtube, twitch: detail.twitch, discord: detail.discord }];
    }
    // console.log("Details",cover)
    setAllInformationData((state) => ({
      ...state,
      logo,
      cover,
      thumbnail,
      description,
      appearLinks
    }));
    if (detail.appearLinks) {
      let _contentState = ContentState.createFromText(detail?.description);
      const raw = convertToRaw(_contentState);
      setContentState(raw);
    }
    if (detail.logo) {
      setLogo(detail.logo);
    }
    if (detail.cover) {
      setCover(detail.cover);
    }
  }, [detail]);
  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on('disconnect', () => {
        console.log('Connection is disconnected ');
      });

      socketRef.current.on('CoverUpdate', (data) => {
        console.log(data);
      });
      return () => {
        socketRef.current.off('disconnect');
        socketRef.current.off('CoverUpdate');
      };
    }
  }, [socketRef]);
  const hendleChange = () => {
    setInformationCall(allInformationData);
  };
  return (
    <>
      {detail.thumbnail !== undefined ? (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%' }}>
            <div style={{ width: '90%' }}>
              <div>
                <h2 style={{ color: '#68717e' }}>LOGO</h2>
                <div style={{ margin: '20px 0px' }}>
                  <strong
                    style={{
                      color: '#68717e',
                      fontWeight: '100',
                      font: 'normal normal normal 12px/15px Montserrat'
                    }}>
                    The logo is displayed on the competition page and in notifications about the
                    competition.
                  </strong>
                </div>
                <div
                  className="logoSide"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  // onClick={(e) => setDetails((state) => ({ ...state, logo: e.target.value }))}
                >
                  {/* <img
                    src={
                      detail.logo
                        ? detail.logo
                        : 'http://cdn.pixabay.com/photo/2016/10/26/19/00/domain-names-1772243_1280.jpg'
                    }
                  /> */}
                  <div
                    className="bannerlogo"
                    style={
                      allInformationData.logo
                        ? { width: '150px', height: '150px' }
                        : { width: '150px', height: '150px', border: '1px dashed #22262e' }
                    }
                    onClick={() => setLogoEditModal(true)}

                    // {detail.logo && (<img src={detail.logo ? detail.logo : 'http://cdn.pixabay.com/photo/2016/10/26/19/00/domain-names-1772243_1280.jpg'} alt="not found"/>) }
                    // src={
                    //   detail.logo
                    //     ? detail.logo
                    //     : 'http://cdn.pixabay.com/photo/2016/10/26/19/00/domain-names-1772243_1280.jpg'
                    // }
                    // alt="not found"
                  >
                    {allInformationData.logo && (
                      <img
                        style={{ height: '150px', width: '150px' }}
                        src={
                          allInformationData.logo
                            ? allInformationData.logo
                            : 'http://cdn.pixabay.com/photo/2016/10/26/19/00/domain-names-1772243_1280.jpg'
                        }
                        alt="not found"
                      />
                    )}
                  </div>
                  {/* <span class="pencil" onClick={() => setLogoEditModal(true)}> */}
                  <span
                    class="pencil"
                    style={{ marginBottom: '102px', borderRadius: '23px' }}
                    onClick={() => setLogoEditModal(true)}>
                    <HiPencil
                      className="pencilClass"
                      style={{
                        backgroundColor: '#68717e',
                        padding: '9px',
                        borderRadius: '23px',
                        height: '43px',
                        width: '43px'
                      }}
                    />
                  </span>
                </div>
                <div
                  style={{ margin: '20px 0px', font: 'normal normal normal 10px/13px Montserrat' }}>
                  <strong style={{ color: '#68717e', fontWeight: '100' }}>
                    Recommended size:{' '}
                  </strong>
                  250 x 250 pixels{' '}
                  <strong style={{ color: '#68717e', fontWeight: '100' }}>
                    or larger. For transparent backgrounds, make sure to use a PNG file.
                  </strong>
                </div>
              </div>
              {/* <UploadLogobox
                name={'Banner'}
                width={1920}
                height={320}
                size={1600000}
                setNameinput={setDetails}
                nameinput={detail}
                // checkisempty={checkisempty}
                editableimg={detail?.cover}
                textunderHeading="The banner is displayed at the top of the competition page."
                Uploadimage={'Upload Banner'}
                isBanner={true}
                textunderButton={
                  <div style={{ margin: '20px 0px' }}>
                    <strong style={{ color: '#68717e', fontWeight: '100' }}>
                      {' '}
                      Recommended size: .
                    </strong>
                    1920 x 320 pixels
                  </div>
                }
                reuploadbuttontext={'UPDATE Banner'}
              /> */}
              <Cropper
                width={1280}
                height={213}
                size={2000000}
                name={'BANNER'}
                setNameinput={setDetails}
                nameinput={detail}
                aspectRatio={'6/1'}
                // checkisempty={checkisempty}
                editableimg={detail?.cover}
                textunderHeading="The banner is displayed at the top of the competition page."
                Uploadimage={'Upload Banner'}
                isBanner={true}
                textunderButton={
                  <div>
                    <strong
                      style={{
                        color: '#68717e',
                        font: 'normal normal normal 10px/13px Montserrat',
                        width: '193px',
                        height: '13px'
                      }}>
                      Recommended size:{' '}
                    </strong>
                    <strong
                      style={{
                        color: '#FFFFFF',
                        font: 'normal normal normal 10px/13px Montserrat',
                        width: '88px',
                        height: '13px'
                      }}>
                      1920 x 320 pixels
                    </strong>

                    <strong
                      style={{
                        color: '#68717e',
                        font: 'normal normal normal 10px/13px Montserrat',
                        width: '193px',
                        height: '13px'
                      }}>
                      .
                    </strong>
                  </div>
                }
                reuploadbuttontext={'UPDATE Banner'}
              />
              {/* <UploadLogobox
                name={'thumbnail'}
                width={640}
                height={360}
                size={10000000}
                setNameinput={setDetails}
                nameinput={detail}
                editableimg={detail?.thumbnail}
                Uploadimage={'Upload Thumbnail'}
                isThumbnail={true}
                // checkisempty={checkisempty}
                textunderHeading="The thumbnail is what players see first when browsing for competitions on Leagues.gg."
                textunderButton={
                  <div style={{ margin: '20px 0px' }}>
                    <strong style={{ color: '#68717e', fontWeight: '100' }}>
                      {' '}
                      Recommended size:
                    </strong>
                    640x360 pixels
                  </div>
                }
                reuploadbuttontext={'UPDATE THUMBNAIL'}
              /> */}
              <Cropper
                name={'thumbnail'}
                width={640}
                height={360}
                aspectRatio={'16/9'}
                size={1000000}
                setNameinput={setDetails}
                nameinput={detail}
                editableimg={detail?.thumbnail}
                Uploadimage={'Upload Thumbnail'}
                isThumbnail={true}
                // checkisempty={checkisempty}
                textunderHeading={
                  <div style={{ font: '0.75rem / 1rem Montserrat', color: 'rgb(104, 113, 126)' }}>
                    The thumbnail is what players see first when browsing for competitions on
                    Leagues.gg.
                  </div>
                }
                textunderButton={
                  <div>
                    <p>
                      <strong
                        style={{
                          color: '#68717e',
                          font: 'normal normal normal 10px/13px Montserrat',
                          width: '193px',
                          height: '13px'
                        }}>
                        Recommended size:{' '}
                      </strong>
                      <strong
                        style={{
                          color: '#FFFFFF',
                          font: 'normal normal normal 10px/13px Montserrat',
                          width: '88px',
                          height: '13px'
                        }}>
                        {' '}
                        640 x 360 pixels
                      </strong>
                      <strong
                        style={{
                          color: '#68717e',
                          font: 'normal normal normal 10px/13px Montserrat',
                          width: '193px',
                          height: '13px'
                        }}>
                        .
                      </strong>
                    </p>
                  </div>
                }
                reuploadbuttontext={'UPDATE THUMBNAIL'}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '20px'
                }}>
                <button className="button primary" onClick={hendleChange}>
                  Save Changes
                </button>
              </div>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <div style={{ width: '90%', float: 'right' }}>
              <h2>About</h2>
              <label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                description
              </label>
              <Editor
                defaultContentState={contentState}
                onContentStateChange={setContentState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
              <div>
                <h3>
                  Links <strong style={{ color: '#68717e' }}>(optional)</strong>
                </h3>
                <Form.Group>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <FaDiscord
                      style={{
                        fontSize: 'xx-large',
                        borderRadius: '50%',
                        background: '#68717e',
                        // color: '#171c23',
                        color: '#68717E',
                        background: '#171C23',
                        marginRight: '10px',
                        width: '40px',
                        height: '35px',
                        marginTop: '13px'
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px 0px'
                      }}>
                      <div>
                        <div
                          style={{
                            font: 'normal normal bold 12px/15px Montserrat',
                            margin: '5px 0px',
                            textTransform: 'uppercase'
                          }}>
                          Discord
                        </div>
                        <strong style={{ color: '#68717e', fontWeight: '100', margin: '5px 0px' }}>
                          Please enter a Discord link to a server that players can join for any
                          questions about the competition.
                        </strong>
                      </div>
                      <Form.Control
                        ref={register()}
                        style={{ margin: '15px 0px', width: '350px' }}
                        type="url"
                        id="discord"
                        class="plachodercolor"
                        name="discord"
                        placeholder="Example: https://discord.gg/...."
                        value={detail?.discord}
                        onChange={(e) => {
                          setDetails((state) => ({ ...state, discord: e.target.value }));
                        }}
                        // onBlur={handleSubmit(onSubmit)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.discord && errors.discord.message}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <FaYoutube
                      style={{
                        fontSize: 'xx-large',
                        borderRadius: '50%',
                        background: '#68717e',
                        // color: '#171c23',
                        color: '#68717E',
                        background: '#171C23',
                        marginRight: '10px',
                        width: '40px',
                        height: '35px',
                        marginTop: '13px'
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px 0px'
                      }}>
                      <div>
                        <div
                          style={{
                            font: 'normal normal bold 12px/15px Montserrat',
                            margin: '5px 0px',
                            textTransform: 'uppercase'
                          }}>
                          YouTube Video
                        </div>
                        <strong style={{ color: '#68717e', fontWeight: '100', margin: '5px 0px' }}>
                          This YouTube video will be featured on the competition page.
                        </strong>
                      </div>
                      <Form.Control
                        ref={register()}
                        style={{ margin: '15px 0px', width: '350px' }}
                        type="url"
                        id="discord"
                        name="discord"
                        class="plachodercolor"
                        placeholder="Example: https://www.youtube.com...."
                        value={detail?.youtube}
                        onChange={(e) => {
                          setDetails((state) => ({ ...state, youtube: e.target.value }));
                        }}
                        // onBlur={handleSubmit(onSubmit)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.discord && errors.discord.message}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <FaTwitch
                      style={{
                        fontSize: 'xx-large',
                        borderRadius: '50%',
                        background: '#68717e',
                        // color: '#171c23',
                        color: '#68717E',
                        background: '#171C23',
                        marginRight: '10px',
                        width: '40px',
                        height: '35px',
                        marginTop: '13px'
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px 0px'
                      }}>
                      <div>
                        <div
                          style={{
                            font: 'normal normal bold 12px/15px Montserrat',
                            margin: '5px 0px',
                            textTransform: 'uppercase'
                          }}>
                          Twitch Channel
                        </div>
                        <strong style={{ color: '#68717e', fontWeight: '100', margin: '5px 0px' }}>
                          This Twitch channel will be featured on the competition page.
                        </strong>
                      </div>
                      <Form.Control
                        ref={register()}
                        style={{ margin: '15px 0px', width: '350px' }}
                        type="url"
                        id="discord"
                        name="discord"
                        class="plachodercolor"
                        placeholder="Example: https://www.twitch.tv...."
                        value={detail.twitch}
                        onChange={(e) => {
                          setDetails((state) => ({ ...state, twitch: e.target.value }));
                        }}
                        // onBlur={handleSubmit(onSubmit)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.discord && errors.discord.message}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Uploadlogo
        setLogo={updateLogo}
        isOpen={logoEditModal}
        setLogoEditModal={setLogoEditModal}
        updatenameAndLogo={updatenameAndLogo}
      />
    </>
  );
};
export default AppearanceTab;
