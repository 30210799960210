import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('sessionToken');
  }, []);

  return <Redirect to="/" />;
};

export default Logout;
