import React, { useState, useEffect, useContext } from 'react';
import Organizer from './Organizer/Organizer';
import Axios from 'axios';
import CreateOrganizer from './Organizer/CreateOrganizer';
import OrganizerGridView from './Organizer/OrganizerGridView';
import { SimplePageWithTabs } from '@common';
import { HiViewGrid, HiPlusCircle } from 'react-icons/hi';
import ListView from '../../_common/ListView/ListView';
import LastEdit from '../../_common/LastEdit/LastEdit';
import { SimpleListView } from '@common';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganizations,
  setEmptyFolderAction,
  setOrganizations
} from '../../../redux/action/competitionActions';
import { getSortByLastModified, getSortedObjByName } from '../MyCompetitions/utils';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import OrganizerListView from './Organizer/OrganizerListView';
const backendServerURL = process.env.REACT_APP_CORE_API;

const MyOrganizations = () => {
  const { user } = useContext(AppContext);
  const allOrganizers = useSelector((state) =>
    state.FOLDERS.Organizers ? state.FOLDERS.Organizers : []
  );
  const [tabs, setTabs] = useState({});
  const [showCreateOrganaizer, setShowCreateOrganaizer] = useState(false);
  useEffect(() => {
    let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
    let orgAccess = user?.state?.permissions?.user?.OrganizerAccess ?? false;
    let sharedOrganizer = user?.state?.sharedOrganizer > 0 ? true : false;
    if (!orgAdmin && sharedOrganizer && !orgAccess) {
      setShowCreateOrganaizer(false);
    } else if (orgAdmin || orgAccess) {
      setShowCreateOrganaizer(true);
    } else {
      setShowCreateOrganaizer(false);
    }
  }, [user]);
  useEffect(() => {
    console.log({ showCreateOrganaizer });
  }, [showCreateOrganaizer]);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizations());
    dispatch(setEmptyFolderAction());
  }, []);
  const handleRouting = (id) => {
    history.push(`my-competitions/${id}`, { view: 'LIST' });
  };

  const loading = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const onSort = (allData, type, sort) => {
    let _folderObj = { competitions: allData };
    switch (type) {
      case 'NAME':
        _folderObj = getSortedObjByName(_folderObj, type, sort);
        break;
      case 'TOURNAMENT_DATE':
        _folderObj = getSortedObjByName(allData, type, sort);
        break;
      case 'MATCHES':
        _folderObj = getSortedObjByName(allData, type, sort);
        break;
      case 'ORG':
        _folderObj = getSortedObjByName(allData, type, sort);
        break;
      case 'FOLDER':
        _folderObj = getSortedObjByName(allData, type, sort);
        break;
      case 'ORGANIZER':
        _folderObj = getSortedObjByName(allData, type, sort);
        break;
      case 'LAST_MODIFIED':
        _folderObj = getSortByLastModified(_folderObj, type, sort);
        break;
      default:
    }
    dispatch(setOrganizations(_folderObj.competitions));
  };
  useEffect(() => {
    let _tabs = {
      'Grid View': (
        <>
          <OrganizerGridView allOrganizers={allOrganizers} showCreate={showCreateOrganaizer} user={user}/>
        </>
      ),
      'List View': (
        <>
          <OrganizerListView
            alldata={allOrganizers}
            handleRouting={handleRouting}
            getTrans={getOrganizations}
            onSort={onSort}
            showCreate={showCreateOrganaizer}
            user={user}
          />
          {/* <SimpleListView
            alldata={allOrganizers}
            onRoute={handleRouting}
            getTrans={getOrganizations}
            onSort={onSort}
            showCreate={showCreateOrganaizer}
          /> */}
          {/* <ListView alldata={allOrganizers} getTrans={getOrganizations}/>  */}
        </>
      )
    };
    setTabs(_tabs);
  }, [allOrganizers, showCreateOrganaizer]);
  const Newpage = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '670px',
          alignItems: 'center',
          marginTop: '100px'
        }}>
        <HiPlusCircle style={{ color: '#171c23', fontSize: '80px' }} />
        <h1
          style={{
            font: 'normal normal normal  Montserrat',
            color: '#FFFFFF',
            fontSize: '18px',
            marginTop: '0px'
          }}>
          create organizer
        </h1>
        <span
          style={{
            font: 'normal normal normal 12px/15px Montserrat',
            color: '#68717E',
            width: '488px',
            height: '30px',
            marginTop: '-14px'
          }}>
          Create an organizer profile to start setting up events. The organizer profile can be your
          esports organization, company, or however you’d like to structure your events.
        </span>
        <CreateOrganizer showCreate={showCreateOrganaizer} />
      </div>
    );
  };
  return (
    <div>
      {/* <LastEdit /> */}
      <SimplePageWithTabs
        title="My Competitions"
        firstPage={showCreateOrganaizer ? <Newpage /> : <></>}
        tabs={tabs}
        showLastEdit={true}
        organizerLength={!loading ? allOrganizers?.length : 1}
        top_right={
          <>
            <span className="main-leagues-gg">
              <div className="mirror-text">
                <span className="mirror-text-span">Leagues.gg</span>
              </div>
            </span>
          </>
        }
        showCreate={showCreateOrganaizer}
      />
    </div>
  );
};
export default MyOrganizations;
