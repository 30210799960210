function listenForOutsideClicks(listening, setListening, menuRef, setShowOptions) {
  return () => {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        const cur = menuRef?.current;
        const node = evt?.target;
        if (cur?.contains(node)) return;
        setShowOptions(false);
      });
    });
  };
}
export default listenForOutsideClicks;
