import Axios from "axios";
import { toast } from "react-toastify";
import store from "../store/store";
import { setIsAnyChangeInTab } from "./CompetitonData";
import { SEEDING_DATA, SET_LOADING } from "./types";


const backend = process.env.REACT_APP_CORE_API;


export const getStagesSeedingData = ({ id }) => (dispatch) => {
  dispatch({ type: SEEDING_DATA.LOAD });
  dispatch({ type: SET_LOADING, payload: true });
  Axios.get(backend + `/api/organizer/competition/${id}/stage`)
    .then((res) => {
      const { data } = res.data;
      dispatch({
        type: SEEDING_DATA.LOAD_SUCCESS,
        payload: data
      });
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch((err) => {
      toast.error(err.msg);
      console.log(err);
      dispatch({ type: SEEDING_DATA.LOAD_FAIL })
      dispatch({ type: SET_LOADING, payload: false });
    });
};

export const setSeedingData = (id, stageId, _data, autoFillTeams, stageIndex) => {
  let data = {};
  data.groups = _data;
  if (autoFillTeams !== undefined) {
    data.autoFillTeams = autoFillTeams;
  }
  store.dispatch({ type: SEEDING_DATA.SET_SAVE_LOADING, payload: true });
  Axios.post(`${backend}/api/organizer/competition/${id}/stage/${stageId}/group/seeding/`, {
    ...data,
  })
    .then(({ data }) => {
      console.log("response", data);
      store.dispatch({ type: SEEDING_DATA.SET_SAVE_LOADING, payload: false });
      stageChangesSaved(stageIndex);
      if (data.data) {
        store.dispatch({
          type: SEEDING_DATA.SAVED_UPDATE_GROUPS,
          payload: { stageId: stageId, data: data.data }
        });
      }
      toast.success("Seeding saved successfully");

      // if (isAll) {
      //   if (isLast) {
      //     toast.success("Seeding saved successfully");
      //   }
      // } else {
      //   toast.success("Seeding saved successfully");
      // }
    })
    .catch((err) => {
      console.log("error in setSeedingData  ", err);
      store.dispatch({ type: SEEDING_DATA.SET_SAVE_LOADING, payload: false });
      toast.error("Cannot update seeding, please retry");

    });
};


export const setSeedingDataAll = (id, stageId, _data, autoFillTeams, isAll, isLast) => {
  let data = {};
  data.groups = _data;
  if (autoFillTeams !== undefined) {
    data.autoFillTeams = autoFillTeams;
  }
  store.dispatch({ type: SEEDING_DATA.SET_SAVE_LOADING, payload: true });
  Axios.post(`${backend}/api/organizer/competition/${id}/stage/${stageId}/group/seeding/`, {
    ...data,
  })
    .then(({ data }) => {
      if (data.data) {
        store.dispatch({
          type: SEEDING_DATA.SAVED_UPDATE_GROUPS,
          payload: { stageId: stageId, data: data.data }
        });
      }
      if (isAll) {
        if (isLast) {
          store.dispatch({ type: SEEDING_DATA.SET_SAVE_LOADING, payload: false, message: 'success' });
          stageChangesSavedForAll();
          toast.success("Seeding saved successfully");
          setTimeout(() => {
            store.dispatch(setIsAnyChangeInTab(false));
          }, 500)
        }
      } else {
        store.dispatch({ type: SEEDING_DATA.SET_SAVE_LOADING, payload: false, message: 'success' });
        toast.success("Seeding saved successfully");
        // store.dispatch(setIsAnyChangeInTab(false));
        setTimeout(() => {
          store.dispatch(setIsAnyChangeInTab(false));
        }, 500)
      }
    })
    .catch((err) => {
      console.log("error in setSeedingData  ", err);
      store.dispatch({ type: SEEDING_DATA.SET_SAVE_LOADING, payload: false, message: 'failed' });
      toast.error("Cannot update seeding, please retry");
    });
};

export const resetSavingMessage = () => {
  store.dispatch({ type: SEEDING_DATA.SET_SAVE_MESSAGE_RESET })
}

export const setSeedingsWithoutSave = (stageIndex, updatedSeedings) => {
  store.dispatch({
    type: SEEDING_DATA.SET_SEEDINGS_IN_STAGE,
    payload: {
      stageIndex: stageIndex,
      unSavedChanges: true,
      seedings: updatedSeedings
    }
  })
}

export const checkUnSavedSeedingChanges = (stageIndex, isChanged, initialSeedings, updatedSeedings) => {
  store.dispatch({
    type: SEEDING_DATA.SET_UNSAVED_CHANGES_IN_STAGE,
    payload: {
      stageIndex: stageIndex,
      unSavedChanges: isChanged,
    }
  })
}

export const stageChangesSaved = (stageIndex) => {
  store.dispatch({
    type: SEEDING_DATA.SET_UNSAVED_CHANGES_IN_STAGE,
    payload: {
      stageIndex: stageIndex,
      unSavedChanges: false,
    }
  })
}

export const stageChangesSavedForAll = () => {
  store.dispatch({ type: SEEDING_DATA.SET_UNSAVED_CHANGES_ALL_SAVED });
}

export const resetSeedingUpdate = () => (dispatch) => {
  dispatch({ type: SEEDING_DATA.UPDATE_STAGE_SEEDING_RESET });
};
