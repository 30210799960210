import React, { useState, createContext, useEffect } from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import './_Popup_modals.scss';
import vars from '@styles/_variables.scss';
import { HiX } from 'react-icons/hi';

const Popup_modals = ({
  Component,
  open,
  onClose,
  OrganizaionId,
  folder,
  parentId,
  deleteOrganizaionId,
  setNewname,
  setAddnewfolder,
  isdoubbleclick,
  type,
  item,
  multipleDelete,
  canRestor,
  folderName,
  afterCreate = () => false,
  handleClearSelection,
  setDefaultPictures = ()=> {},
  isCurrentPage,
  baseRoute
}) => {

  return (
    <Modal enforceFocus={true} restoreFocus={true} isOpen={open} onRequestClose={onClose} className="org-modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={()=>onClose(false)}>
        {/* <RiCloseLine style={{float:"right"}}/> */}
        {/* <HiX style={{ float: 'right' }} /> */}
      </div>
      <Component
      handleClearSelection={handleClearSelection}
        canRestor={canRestor}
        onClose={() => onClose(false)}
        setAddnewfolder={setAddnewfolder}
        deleteOrganizaionId={deleteOrganizaionId}
        setNewname={setNewname}
        folder={folder}
        type={type}
        OrganizaionId={OrganizaionId}
        parentId={parentId}
        isdoubbleclick={isdoubbleclick}
        item={item}
        multipleDelete={multipleDelete}
        namefolder={folderName}
        setDefaultPictures={setDefaultPictures}
        isCurrentPage={isCurrentPage}
        baseRoute={baseRoute}
      />
    </Modal>
  );
};

export default Popup_modals;
