import React, { useEffect, useContext } from 'react';
import { Switch, Route, useLocation, Redirect, useHistory } from 'react-router-dom';
import { AuthRoute, GuestRoute } from './utils/CustomRouteTypes';
import Settings from './components/Settings/Settings';
import UserProfile from './components/UserProfile/UserProfile';
import Logout from './components/Logout/Logout';
import Notifications from './components/Notifications/Notifications';

import BrowseGames from './components/BrowseGames/BrowseGames';
import Contact from './components/Contact/Contact';
import DynamicPage from './components/DynamicPage/DynamicPage';
import PageNotFound from './components/PageNotFound/PageNotFound';
import { AppContext } from './context/AppContext';
// import DropDownTest from "./components/_common/DemoTest/DropDownTest";
import Overview from './components/Pages/Overview/Overview';
import MyOrganizations from './components/Pages/MyOrganizations/MyOrganizations';
import Recents from './components/Pages/Recents/Recents';
import Sharedwithme from './components/Pages/SharedWithMe/SharedWithMe';
import Templates from './components/Pages/Templates/Templates';
import Trash from './components/Pages/Trash/Trash';
import MyCompetitions from './components/Pages/MyCompetitions/MyCompetitions';

//sawaiz work only for testing

import DeleteItems from './components/_modals/OrganizerPopup/deleteitems/delete';
import Newfolder from './components/_modals/OrganizerPopup/newfolder/newfolder';
import DeleteItemsForever from './components/_modals/OrganizerPopup/deleteitems/deleteforever';
import { getOrganizations } from './redux/action/competitionActions';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { useState } from 'react';
import Competition from './components/Pages/Competition/Competition';
//sawaiz work only for testing

// Global router
const AppRouter = () => {
  const { pathname } = useLocation();
  const search = useLocation().search;
  const lst = new URLSearchParams(search).get('lst');
  const sideBar = new URLSearchParams(search).get('side');
  const { showRouterComponent, setUserData } = useContext(AppContext);
  const { user, selectedGame, loading, authenticated } = useContext(AppContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const organizers = useSelector((state) =>
    state.FOLDERS.Organizers ? state.FOLDERS.Organizers : []
  );
  const [Isoneorganizer, setIsoneorganizer] = useState(0);
  useEffect(() => {
    if (organizers.length === 1) {
      if (Isoneorganizer === 0) {
        history.push(`/my-competitions/${organizers[0]?._id}`);
        setIsoneorganizer(1);
      }
    }
  }, [Isoneorganizer, organizers]);

  useEffect(() => {
    if (lst) {
      Axios.defaults.headers.common.authorization = `Bearer ${lst}`;
      localStorage.setItem('sessionToken', lst);
      setUserData(lst);
      history.push('/?side=true');
      dispatch(getOrganizations());
    }
  }, [lst]);
  useEffect(() => {
    if (!loading && localStorage.getItem('sessionToken')) {
      if (
        user?.state?.permissions?.user.OrganizerAccess === true ||
        user?.state?.permissions?.user.OrganizerAdmin === true
      ) {
        if (sideBar) {
          history.push('/?sideBar=true');
        }
      }
    } else {
      window.location.replace(process.env.REACT_APP_FRONTEND_URL, '_self');
    }
  }, [loading]);

  const routes = [
    // {
    //   key: 'Overview',
    //   pathName: '/overview',
    //   content: <MyOrganizations />
    // },
    {
      key: 'My Competitions',
      pathName: '/',
      content: <MyOrganizations />,
      exact: true
    },
    // {
    //   key: "Shared With Me",
    //   pathName: "/shared-with-me",
    //   content: <Sharedwithme />,
    // },
    {
      key: 'Recents',
      pathName: '/recents',
      content: <Recents />
    },
    {
      key: 'Templates',
      pathName: '/templates',
      content: <Templates />
    },
    {
      key: 'Trash',
      pathName: '/trash',
      content: <Trash />
    }
  ];
  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // if (!showRouterComponent) return null;
  return (
    <Switch>
      {
        //sawaiz work only for testing
      }
      <Route exact path="/test1">
        <DeleteItems />
      </Route>
      <Route exact path="/test2">
        <DeleteItemsForever />
      </Route>
      <Route exact path="/test3">
        <Newfolder />
      </Route>
      {
        //sawaiz work end only for testing
      }
      {/* <Route exact path="/">
        <Redirect to='/my-competitions' />
      </Route> */}
      <AuthRoute path="/logout">
        <Logout />
      </AuthRoute>
      <AuthRoute path="/account">
        <Settings />
      </AuthRoute>
      <Route path="/s/:slug">
        <DynamicPage />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/user/:id">
        <UserProfile />
      </Route>
      <Route path="/my-competitions/:id">
        <MyCompetitions />
      </Route>
      <Route path="/competition/:id">
        <Competition />
      </Route>
      <Route path="/folders/:folderId">
        <MyCompetitions />
      </Route>

      <Route path="/notifications">
        <Notifications />
      </Route>
      <Route path="/p/:slug">
        <DynamicPage />
      </Route>
      <Route path="/browse-games">
        <BrowseGames />
      </Route>
      {routes.map((route, ind) => {
        // if (!selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[route.key])) {
        return (
          <Route key={ind} path={route.pathName} exact={route.exact}>
            {route.content}
          </Route>
        );
        // }
      })}
      {/* {authRoutes.map((route) => {
        if (!selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[route.key])) {
          return <AuthRoute path={route.pathName}>{route.content}</AuthRoute>;
        }
      })} */}
      {/* 404 */}
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
export default AppRouter;
