import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import LastEditItem from '../LastEditItem/LastEditItem';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetitions } from '../../../redux/action/competitionActions';
import ShareOptions from '../ShareOptions/ShareOptions';
import { getTranslationData } from '../../../redux/action/action';
import TournamentItem from '../TournamentItem/TournamentItem';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';

const LastEdit = () => {
  const { authenticated } = useContext(AppContext)
  const [selectedCompitation, setSelectedCompitation] = useState();
  const [selectedBoolean, setSelectedBoolean] = useState();
  var isLastEdit = true;
  var today = new Date();
  const history = useHistory();
  //   const rDiff = moment(today).diff(moment());
  //   const rDiffDuration = moment.duration(rDiff);
  const _rDate = moment(today);
  let rString = _rDate.format('DD/MM-YYYY');
  //   if (rDiffDuration.days() === -1) rString = "Yesterday";
  //   if (rDiffDuration.days() === 1) rString = "Tomorrow";
  //   if (rDiffDuration.days() === 0) rString = "Today";
  let reg_date = rString + ' ';
  const dispatch = useDispatch();
  useEffect(() => {
    if (authenticated) dispatch(getCompetitions());
    getTrans();
  }, [authenticated]);
  const getTrans = () => {
    dispatch(getTranslationData());
  };
  const onRouting = (id) => {
    let url = `/competition/${id}`;
    window.open(url, '_blank').focus();
  };
  const competitions = useSelector((state) =>
    state.FOLDERS.Competitions ? state.FOLDERS.Competitions : []
  );
  // console.log('competitions', competitions);
  //var timeZone=""
  const timeZone = useSelector((state) => state.TIMEZONE);
  const getDate = (date) => {
    if (timeZone === '') {
      return date;
    } else {
      // console.log('great',date)
      return moment(date).tz(timeZone?.timeZone).format('DD-MM-YYYY HH:MM:SS');
    }
  };

  competitions.sort((a, b) => (new Date(a.lastEditedDate) > new Date(b.lastEditedDate)) ? -1 : 1)

  var alldates = [];
  competitions.map((competition) => {
    alldates = [competition.lastEditedDate, ...alldates];
  });
  const myCompetitionItemHandler = (i) => {
    setSelectedCompitation(i+'e');
    setSelectedBoolean(!selectedBoolean);
  };
  return (
    <div>
      
      <p
        style={{
          top: '159px',
          left: '207px',
          width: '96px',
          height: '18px',
          textAlign: 'left',
          font: 'normal normal bold 14px/18px Montserrat',
          letterSpacing: '0px',
          color: '#68717E',
          textTransform: 'uppercase',
          opacity: '1'
        }}>
        LAST EDITED
      </p>
      <div className="row m-0 grid-tab-content">
        <div className="col-12 p-0">
          <div
            className="row smaller-gutter-row container"
            style={{
              marginLeft: '-14px',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              maxWidth: '3000px'
            }}>
            {competitions.map((competition, index) => {
              if (index < 5) {
                return (
                  <TournamentItem
                    onClick={() => myCompetitionItemHandler(index)}
                    style={{border:'10px solid blue'}}
                    type={'COMPETITION'}
                    ind={index+'e'}
                    selectedCompitation={selectedCompitation}
                    compId={competition._id}
                  competitionData = {competition}
                  isFolder={false}
                  isOrganizer={false}
                  isCompetition={true}
                    onRouting={() => onRouting(competition._id)}
                    organizerName={competition?.organizer?.name}
                    key={index}
                    isLastEdit={isLastEdit}
                    //reg_date={competition.lastEditedDate}
                    date={
                      <div style={{color:  selectedCompitation===index+'e' ? '#296f9e' : 'white'}}>
                      {competition.lastEditedDate
                        ? 'Last edited: ' +
                          getDate(competition.lastEditedDate) +
                          ' by ' +
                          competition?.lastEditor?.firstName +
                          ' ' +
                          competition?.lastEditor?.lastName
                        : undefined}
                        </div>
                    }
                    // title={competition.name}
                    title={
                      <div style={{color:  selectedCompitation===index+'e' ? '#3eabf0' : 'white' ,     display: 'flex', flexDirection: 'row' }}>
                        <span
                          className="imgLogoContainer"
                          style={{ color: selectedCompitation === index ? '#3eabf0' : '#3eabf0' }}>
                          <img
                            className=" imgLogoWidth imgLogo  imgLogoCropped"
                            src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                            alt="Insight Gaming"
                            width={'10px'}
                          />
                        </span>
                        <span
                          style={{
                            maxWidth: '175px',
                            textOverflow: 'ellipsis',
                            marginLeft: '300px',
                            height: '18px',
                            width: '235px',
                            padding: '0px',
                            overflow: 'hidden',
                            position: 'relative',
                            display: 'inline-block',
                            margin: '0 5px 0 5px',
                            textAlign: 'left',
                            textDecoration: 'none',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>
                          {competition.name}
                        </span>
                      </div>
                    }
                    // threeDots={
                      
                    //   <ShareOptions
                    //   style={{     color:  selectedCompitation===index+'e' ? '#3eabf0' : 'white'  }}
                    //     getTrans={getTrans}
                    //     // isTrash={isTrash}
                    //     competition={competition._id}
                    //   />
                    // }
                    image={competition.thumbnail}
                    logo={competition.logo}
                  />
                );
              }
            })}
            {/* <LastEditItem

              key={'23'}
              link_to={`/my-competitions/${123}`}
              title="Steelseries Winter"
              image={
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaHVK48pvKY8NXPnd6eVCWXvlvKRRN_rQYHCC1qpJMe2YGWryd8RaXCqvHAkQptgIHkuI&usqp=CAU'
              }
              reg_date={reg_date ? 'Last edited: ' + reg_date + 'by Fredrik Hockauf' : undefined}
              startTime={reg_date}
              registerationDeadline={reg_date}
            />  */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LastEdit;
