import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { AiOutlineClose } from 'react-icons/ai';
import { BsInfoCircleFill } from 'react-icons/bs';
import Select from 'react-select';
import './select-with-desc.scss';

const CustomOption = ({ innerProps, data }) => {
  return (
    <div {...innerProps} className="options-of-selection">
      <div
        style={{
          height: '15px',
          textAlign: 'left',
          font: 'normal normal normal 12px/15px Montserrat',
          letterSpacing: '0px',
          color: '#FFFFFF',
          opacity: 1
        }}>
        {data.label}
      </div>
      <p
        style={{
          height: '30px',
          /* UI Properties */
          textAlign: 'left',
          font: 'normal normal normal 12px/15px Montserrat',
          letterSpacing: '0px',
          color: '#68717E',
          opacity: 1
        }}>
        {data.description}
      </p>
    </div>
  );
};
const SelectWithDescription = ({
  name,
  placeholder = 'Select Something',
  handleChange,
  disabled = false,
  defaultSelection,
  options = [
    {
      value: 'Alternate',
      label: 'Alternate',
      description: 'Teams will alternate between being home team and away team each match.',
      default: true
    },
    {
      value: 'Favor better seed',
      label: 'Favor better seed',
      description: 'The better seeded team in a stage will always be the home team.'
    },
    {
      value: 'Algorithmic',
      label: 'Algorithmic',
      description:
        'The home team and away team is entirely decided by the pairing procedure algorithm.'
    },
    {
      value: 'Random',
      label: 'Random',
      description: 'The home team and away team will be randomly decided for each match.'
    }
  ]
}) => {
  const defaultVal = useMemo(() => {
    let val = options.find((el) => el.value === defaultSelection) ?? options[0];
    return val;
  }, [defaultSelection, options]);
  // console.log({defaultVal})
  return (
    <Select
      options={options}
      components={{
        Option: CustomOption,
        MultiValueRemove: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null
      }}
      // options={options}
      //   defaultValue={
      //     format === 2
      //       ? [bestOf2[0], bestOf2[1], bestOf2[3]]
      //       : [bestOf[0], bestOf[1], bestOf[2], bestOf[4]]
      //   }
      isDisabled={disabled}
      //   isMulti
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      // menuIsOpen={true}
      captureMenuScroll={false}
      isSearchable={false}
      styles={{
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          height: '15px',
          /* UI Properties */
          textAlign: 'left',
          font: 'normal normal normal 12px/15px Montserrat',
          letterSpacing: '0px',
          color: '#FFFFFF',
          opacity: 1
        }),
        indicatorsContainer: (baseStyles, state) => ({
          ...baseStyles,
          opacity: disabled ? 0.5 : 1
        }),
        container: (baseStyles, state) => ({
          ...baseStyles,
          width: '100%',
          borderRadius: '2px',
          border: '1px solid #22262E',
          background: disabled && '#12141a'
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: 'auto',
          height: '46px',
          opacity: 1,
          borderColor: state.isFocused ? '1px solid #3eabf0' : '1px solid #22262E',
          background: 'transparent',
          borderRadius: '2px',
          border: state.isFocused ? '1px solid #3eabf0' : '1px solid #22262E',

          ':hover': {
            border: '1px solid var(--primary)'
          }
        }),
        menu: (base) => ({
          ...base,
          marginTop: '0px',
          background: 'transparent'
        }),
        singleValue: (styles, { data }) => ({
          ...styles,
          color: disabled ? '#717276' : '#fff',
          fontWeight: 600,
          textTransform: 'uppercase'
        }),
        // multiValue: (styles, { data }) => ({
        //   background: 'transparent'
        // }),
        // multiValueLabel: (styles, { data }) => ({
        //   ...styles,
        //   color: '#7e8fa8',
        //   border: 0,
        //   padding: 0,
        //   margin: 0,
        //   textTransform: 'capitalize',
        //   '&:after': {
        //     content: '","'
        //   }
        // }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
          marginTop: '0px',
          background: '#03060D 0% 0% no-repeat padding-box',
          border: '1px solid #22262E',
          maxHeight: '200px'
          //   paddingLeft: '10px'
        }),
        option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
          ...baseStyles,
          backgroundColor: 'blue',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignContent: 'flex-start',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '10px 0 10px 15px'
          //   '&:active': {
          //     ...baseStyles[':active'],
          //     backgroundColor: 'gray'
          //   },
        })
      }}
      name={name}
      //   placeholder={placeholder}
      defaultValue={defaultVal}
      onChange={(value, e) => {
        e.target = {};
        e.target.name = e.name;
        e.target.value = value.value;
        // debugger
        handleChange(e);
        // handleChange(_selectedIds, _options);
      }}
      // allowSelectAll={true}
      //   value={Selected}
    />
  );
};

export default SelectWithDescription;
