import { useState, useEffect, useCallback, useRef } from 'react';
import {
  checkSelecting,
  getDeleteItemType,
  getElementsBetweenIndexes,
  getIndexToEnd,
  getStartToEndElements,
  mergeArraysWithRemoveMatching,
  mergeArraysWithUniqueValues
} from '../utilityMultiselect';
// import { setSelctedFolders } from '../../redux/action/dragNDropActions';

const useMultiSelect = ({ folders = [], competitions = [] }, handleDelete = () => {}) => {
  const [selectedItems, setSelectedItems] = useState({
    folders: [],
    competitions: []
  });
  const [lastSelectedId, setLastSelectedId] = useState({
    type: null,
    id: null
  });
  const [hoverIndexObj, setHoverIndexObj] = useState({ type: null, id: null });
  useEffect(() => {
    //  console.log('Indexes', lastSelectedId.id, hoverIndexObj.id);
  }, [lastSelectedId, hoverIndexObj]);
  const handleSelect = (event, item, type) => {
    //* This Function is called on Click events so i am not checking for mouse events in this function because it is obvious.
    const { shiftKey, metaKey, ctrlKey } = event;
    const isSelected = selectedItems[type].includes(item);
    // setLastSelectedId({type: type, id: item})
    if (metaKey || ctrlKey) {
      setHoverIndexObj({ type: type, id: item });
      setLastSelectedId({ type: type, id: item });
      return setSelectedItems((prevSelectedItems) => {
        const newSelectedItems = { ...prevSelectedItems };
        if (isSelected) {
          newSelectedItems[type] = prevSelectedItems[type].filter(
            (selectedItem) => selectedItem !== item
          );
        } else {
          newSelectedItems[type] = [...prevSelectedItems[type], item];
        }
        return newSelectedItems;
      });
    } else if (shiftKey) {
      if (!lastSelectedId?.id || lastSelectedId?.id === item) {
        //* Handling if lastSelectedId is null or lastSelected === newSelected
        if (!lastSelectedId._id) {
          setLastSelectedId({ type: type, id: item });
          setHoverIndexObj({ type: type, id: item });
        }
        return setSelectedItems((previousItems) => {
          const selectedItems = { folders: [], competitions: [] };
          selectedItems[type] = [item];
          return selectedItems;
        });
      }
      let allItems = {
        folders: folders.map((folder) => folder && folder?._id),
        competitions: competitions.map((competition) => competition && competition._id)
      };
      let indexOfLastSelected = allItems[lastSelectedId?.type].findIndex(
        (el) => el === lastSelectedId.id
      );
      let indexOfNewSelected = allItems[type].findIndex((el) => el === item);
      if (lastSelectedId?.type === type) {
        //* if both indexes are of the same type => (folders,folders) or (competitions,competitions)
        let _selectedIds = getElementsBetweenIndexes(
          indexOfLastSelected,
          indexOfNewSelected,
          allItems[type]
        );
        // setLastSelectedId({type: type, id: item})
        return setSelectedItems(() => {
          let newSelectedItems = { folders: [], competitions: [] };
          newSelectedItems[type] = _selectedIds;
          return newSelectedItems;
        });
      } else {
        //* If Last Index and newIndex is not between the same type of items => (folders,competitions) or (competitions,folders)
        if (lastSelectedId.type === 'folders') {
          // Forwards selection because folders are listed before competitions
          let restFolders = allItems['folders'].slice(indexOfLastSelected);
          let restCompetitions = allItems['competitions'].slice(0, indexOfNewSelected + 1);
          // setLastSelectedId({type: type, id: item})
          setSelectedItems({
            folders: [...restFolders],
            competitions: [...restCompetitions]
          });
        } else if (lastSelectedId.type === 'competitions') {
          // backwards selection because competitions are listed after folders
          let restCompetitions = allItems['competitions'].slice(0, indexOfLastSelected + 1);
          let restFolders = allItems['folders'].slice(indexOfNewSelected);
          setSelectedItems({
            folders: [...restFolders],
            competitions: [...restCompetitions]
          });
        }
      }
    } else {
      if (type === 'folders') {
        setLastSelectedId({ type: type, id: item });
        setHoverIndexObj({ type: type, id: item });
        return setSelectedItems({ folders: [item], competitions: [] });
      } else {
        setLastSelectedId({ type: type, id: item });
        setHoverIndexObj({ type: type, id: item });
        return setSelectedItems({ folders: [], competitions: [item] });
      }
    }
  };

  const handleSelectAll = (items, type) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = { ...prevSelectedItems };
      newSelectedItems[type] = items;
      return newSelectedItems;
    });
  };

  const handleClearSelection = (item, type = false) => {
    if (!type) {
      return setSelectedItems({ folders: [], competitions: [] });
    }
    setSelectedItems((prevState) => {
      let _state = { folders: [], competitions: [] };
      _state[type] = [item];
      return _state;
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.classList.contains('form-input')) {
        event.stopPropagation();
        // event.preventDefault();
        return;
      }
      // event.preventDefault();
      event.stopPropagation();
      let folderIds = folders.map((folder) => folder && folder?._id);
      let competitionIds = competitions.map((competition) => competition && competition?._id);
      let isSomethingSelected =
        selectedItems?.folders.length > 0 || selectedItems?.competitions.length > 0;
      let itemsWrapperDiv = document.querySelector('.wrapper-ms-unique-cls')?.offsetWidth;
      // let competitionWrapperDiv = document.querySelector('.competition-ms-unique-cls')?.offsetWidth;
      let allItemNodes = document.querySelectorAll('.multiselectItem-cls');
      // let allCompetitionNodes = document.querySelectorAll('.competitionCard-mainDiv');
      let itemsPerRow = 0;
      let competitionsPerRow = 0;
      if (allItemNodes.length > 0) {
        let folderWidth = allItemNodes[0].offsetWidth;
        itemsPerRow = Math.floor(itemsWrapperDiv / folderWidth);
      }
      const allItems = {
        folders: folders.map((folders) => folders && folders._id),
        competitions: competitions.map((competition) => competition && competition._id)
      };

      const { metaKey, ctrlKey, shiftKey, key,altKey,fnKey } = event;
      if ((ctrlKey || metaKey) && event.key === 'a') {
        event.preventDefault();
        // console.log("These are the selected,",folderIds,competitions)
        setSelectedItems({ folders: folderIds, competitions: competitionIds });
        //   handleSelectAll([...document.querySelectorAll('.item')], 'folders');
      } else if (
         (key === 'Delete' && ctrlKey && !altKey && !metaKey) || // Windows: Ctrl + Delete
         (key === 'Delete' && altKey && !ctrlKey && !metaKey) || // Mac: Option + Delete
         (key === 'Delete' && fnKey && !ctrlKey && !altKey && !metaKey) // Mac: fn + Delete
       ) {
        let type = getDeleteItemType(selectedItems);
        handleDelete(type,selectedItems);
      } else if ((!ctrlKey || !metaKey) && key === 'ArrowUp') {
        event.preventDefault();

        //* Handle arrow up key press
        if (!isSomethingSelected) {
          //* If something is not selected
          if (folderIds.length > 0) {
            setHoverIndexObj({ type: 'folders', id: folderIds[0] });
            setLastSelectedId({ type: 'folders', id: folderIds[0] });
            return setSelectedItems({
              folders: [folderIds[0]],
              competitions: []
            });
          } else if (competitionIds.length > 0)
            setHoverIndexObj({ type: 'competitions', id: competitionIds[0] });
          setLastSelectedId({ type: 'competitions', id: competitionIds[0] });
          return setSelectedItems({
            folders: [],
            competitions: [competitionIds[0]]
          });
        } else {
          //*If something is already selected
          const lastHoverIndex = allItems[hoverIndexObj.type].findIndex(
            (item) => item === hoverIndexObj.id
          );
          const lastSelectionIndex = allItems[lastSelectedId.type].findIndex(
            (el) => el === lastSelectedId.id
          );
          let type = null;
          let id = null;
          if (shiftKey) {
            ({ type, id } = hoverIndexObj);
          } else {
            ({ type, id } = lastSelectedId);
          }
          const totalItemsOfType = allItems[type].length - 1;
          let _currentIndex = allItems[type].findIndex((el) => el === id);
          if (type === 'folders' && _currentIndex < itemsPerRow) return;
          let newPointerIndex = _currentIndex - itemsPerRow;
          let _currentElement = { id: allItems[type][newPointerIndex], type: type };
          if (_currentElement?.id) {
            !shiftKey && setLastSelectedId(_currentElement);
            setHoverIndexObj(_currentElement);
            return setSelectedItems((previousItems) => {
              let newSelected = shiftKey ? { ...previousItems } : { folders: [], competitions: [] };
              if (shiftKey) {
                let _selectedIds = getElementsBetweenIndexes(
                  _currentIndex,
                  newPointerIndex,
                  allItems[type]
                );
                if (
                  checkSelecting(
                    lastSelectedId,
                    hoverIndexObj,
                    _currentElement,
                    lastSelectionIndex,
                    lastHoverIndex,
                    newPointerIndex,
                    'ArrowUp'
                  )
                ) {
                  newSelected = mergeArraysWithUniqueValues(newSelected, type, _selectedIds);
                } else {
                  newSelected = mergeArraysWithRemoveMatching(
                    newSelected,
                    type,
                    _selectedIds,
                    _currentElement
                  );
                }
              } else {
                newSelected[type] = [allItems[type][newPointerIndex]];
              }
              return newSelected;
            });
          } else {
            // If index is not in the same type
            let newIndex = 0;
            let _itemsOfType;
            if (type === 'competitions') {
              type = 'folders';
              _itemsOfType = allItems[type].length;
              let _totalRow = Math.ceil(_itemsOfType / itemsPerRow) - 1;
              newIndex = itemsPerRow * _totalRow + _currentIndex;
            }
            let itemsLength = allItems[type][newIndex];
            if (!itemsLength) {
              if (newIndex >= _itemsOfType) {
                newIndex = newIndex - itemsPerRow;
              } else {
                return;
              }
            }
            let _currentElement = { id: allItems[type][newIndex], type: type };
            !shiftKey && setLastSelectedId(_currentElement);
            setHoverIndexObj(_currentElement);
            if (hoverIndexObj.type === 'competitions') {
              if (newIndex < 0) return;
              return setSelectedItems((previousItems) => {
                let newSelection = shiftKey
                  ? { ...previousItems }
                  : { folders: [], competitions: [] };
                if (shiftKey) {
                  let restFolders;
                  let restCompetitions;
                  restFolders = getIndexToEnd(allItems['folders'], newIndex);
                  restCompetitions = getStartToEndElements(
                    allItems['competitions'],
                    lastHoverIndex
                  );
                  if (
                    checkSelecting(
                      lastSelectedId,
                      hoverIndexObj,
                      _currentElement,
                      lastSelectionIndex,
                      lastHoverIndex,
                      newIndex,
                      'ArrowUp'
                    )
                  ) {
                    newSelection = mergeArraysWithUniqueValues(
                      newSelection,
                      'folders',
                      restFolders
                    );

                    newSelection = mergeArraysWithUniqueValues(
                      newSelection,
                      'competitions',
                      restCompetitions
                    );
                  } else {
                    newSelection = mergeArraysWithRemoveMatching(
                      newSelection,
                      'folders',
                      restFolders,
                      _currentElement
                    );
                    newSelection = mergeArraysWithRemoveMatching(
                      newSelection,
                      'competitions',
                      restCompetitions,
                      _currentElement
                    );
                  }
                } else {
                  newSelection[type] = [allItems[type][newIndex]];
                }
                return newSelection;
              });
            }
          }
        }
      } else if ((!ctrlKey || !metaKey) && key === 'ArrowDown') {
        //* Handle arrow down key press
        event.preventDefault();

        if (!isSomethingSelected) {
          //* If something is not selected
          if (competitionIds.length) {
            setLastSelectedId({ type: 'competitions', id: competitionIds[0] });
            setHoverIndexObj({ type: 'competitions', id: competitionIds[0] });
            setSelectedItems({
              folders: [],
              competitions: [competitionIds[0]]
            });
          } else if (folderIds.length) {
            setLastSelectedId({ type: 'folders', id: folderIds[0] });
            setHoverIndexObj({ type: 'folders', id: folderIds[0] });
            return setSelectedItems({
              folders: [folderIds[0]],
              competitions: []
            });
          }
        } else {
          //*If something is already selected
          const lastSelectionIndex = allItems[lastSelectedId.type].findIndex(
            (el) => el === lastSelectedId.id
          );
          const lastHoverIndex = allItems[hoverIndexObj?.type].findIndex(
            (item) => item === hoverIndexObj.id
          );
          let type = null;
          let id = null;
          if (shiftKey) {
            ({ type, id } = hoverIndexObj);
          } else {
            ({ type, id } = lastSelectedId);
          }
          const totalItemsOfType = allItems[type].length - 1; //for managing length to indexes
          let _selectedIndex = allItems[type].findIndex((elem) => elem === id);
          let newPointerIndex = _selectedIndex + itemsPerRow;
          let _currentElement = { id: allItems[type][newPointerIndex], type: type };
          if (_currentElement?.id) {
            // if new index exists in same type
            !shiftKey && setLastSelectedId(_currentElement);
            setHoverIndexObj(_currentElement);
            return setSelectedItems((previousItems) => {
              let newSelected = shiftKey ? { ...previousItems } : { folders: [], competitions: [] };
              if (shiftKey) {
                let _selectedIds = getElementsBetweenIndexes(
                  _selectedIndex,
                  newPointerIndex,
                  allItems[type]
                );

                if (
                  checkSelecting(
                    lastSelectedId,
                    hoverIndexObj,
                    _currentElement,
                    lastSelectionIndex,
                    lastHoverIndex,
                    newPointerIndex,
                    'ArrowDown'
                  )
                ) {
                  newSelected = mergeArraysWithUniqueValues(newSelected, type, _selectedIds);
                } else {
                  newSelected = mergeArraysWithRemoveMatching(
                    newSelected,
                    type,
                    _selectedIds,
                    _currentElement
                  );
                }
              } else {
                newSelected[type] = [allItems[type][newPointerIndex]];
              }

              return newSelected;
            });
          } else {
            let _newIndex = 0;
            if (type === 'folders') {
              type = 'competitions';
              _newIndex = newPointerIndex % itemsPerRow;
            } else if (type === 'competitions') {
              _newIndex = allItems[type].length - 1;
            }
            let _currentElement = { id: allItems[type][_newIndex], type: type };

            if (!_currentElement?.id) return;
            !shiftKey && setLastSelectedId(_currentElement);
            setHoverIndexObj(_currentElement);
            if (hoverIndexObj.type === 'folders') {
              if (shiftKey) {
                let restFolders = getIndexToEnd(allItems['folders'], lastHoverIndex);
                let restCompetitions = getStartToEndElements(allItems['competitions'], _newIndex);
                return setSelectedItems((previousItems) => {
                  let newSelection = { ...previousItems };
                  // checkSelecting(lastSelectedId,_currentElement,lastSelectionIndex,_newIndex)
                  if (
                    checkSelecting(
                      lastSelectedId,
                      hoverIndexObj,
                      _currentElement,
                      lastSelectionIndex,
                      lastHoverIndex,
                      _newIndex,
                      'ArrowDown'
                    )
                  ) {
                    newSelection = mergeArraysWithUniqueValues(
                      newSelection,
                      'folders',
                      restFolders
                    );
                    newSelection = mergeArraysWithUniqueValues(
                      newSelection,
                      'competitions',
                      restCompetitions
                    );
                  } else {
                    newSelection = mergeArraysWithRemoveMatching(
                      newSelection,
                      'folders',
                      restFolders,
                      _currentElement
                    );
                    newSelection = mergeArraysWithRemoveMatching(
                      newSelection,
                      'competitions',
                      restCompetitions,
                      _currentElement
                    );
                  }
                  return newSelection;
                });
              } else {
                return setSelectedItems((previousItems) => ({
                  folders: [],
                  competitions: [allItems['competitions'][_newIndex]]
                }));
              }
            }
          }
        }
      } else if ((!ctrlKey || !metaKey) && key === 'ArrowRight') {
        event.preventDefault();

        if (!isSomethingSelected) return;
        let type = null;
        let id = null;
        if (shiftKey) {
          ({ type, id } = hoverIndexObj);
        } else {
          ({ type, id } = lastSelectedId);
        }
        const totalItemsOfType = allItems[type].length - 1;
        let newIndex = allItems[type].findIndex((el) => el === id) + 1;
        let checkIfElementsExist = allItems[type][newIndex];
        if (!checkIfElementsExist) {
          if (type === 'competitions') return;
          type = 'competitions';
          newIndex = 0;
        }
        const checkNewElemExists = allItems[type][newIndex];
        if (!checkNewElemExists) return;
        !shiftKey && setLastSelectedId({ type: type, id: allItems[type][newIndex] });
        const lastVisited = { ...hoverIndexObj };
        setHoverIndexObj({ type: type, id: allItems[type][newIndex] });
        if (shiftKey) {
          let lastSelectionIndex = allItems[lastSelectedId.type].findIndex(
            (el) => el === lastSelectedId.id
          );
          //for selection and unSelections
          if (type === lastSelectedId.type) {
            let _selectedIds = getElementsBetweenIndexes(
              lastSelectionIndex,
              newIndex,
              allItems[type]
            );
            return setSelectedItems((previousItems) => {
              let newSelected = { ...previousItems };
              let newObj = mergeArraysWithUniqueValues(newSelected, type, _selectedIds);
              //unselecting last selected
              if (newIndex <= lastSelectionIndex) {
                let _type = lastVisited.type;
                let _id = lastVisited.id;
                newObj[_type] = newObj[_type].filter((elem) => elem !== _id);
              }

              return newObj;
            });
          } else {
            //if type is not current type
            let restFolders;
            let restCompetitions;
            if (lastSelectedId.type === 'competitions') {
              restCompetitions = getStartToEndElements(
                allItems['competitions'],
                lastSelectionIndex
              );
              restFolders = getIndexToEnd(allItems['folders'], newIndex);
            } else {
              restFolders = getIndexToEnd(allItems['folders'], lastSelectionIndex);
              restCompetitions = getStartToEndElements(allItems['competitions'], newIndex);
            }
            return setSelectedItems((previousItems) => {
              let newSelected = { ...previousItems };
              newSelected = mergeArraysWithUniqueValues(newSelected, 'folders', restFolders);
              newSelected = mergeArraysWithUniqueValues(
                newSelected,
                'competitions',
                restCompetitions
              );
              if (lastSelectedId.type !== 'folders') {
                let _type = lastVisited.type;
                let _id = lastVisited.id;
                newSelected[_type] = newSelected[_type].filter((elem) => elem !== _id);
              }
              return newSelected;
            });
          }
        } else {
          return setSelectedItems((previousItems) => {
            let selected = { folders: [], competitions: [] };
            selected[type] = [allItems[type][newIndex]];
            return selected;
          });
        }
      } else if ((!ctrlKey || !metaKey) && key === 'ArrowLeft') {
        event.preventDefault();

        if (!isSomethingSelected) return;
        // let { type, id } = lastSelectedId;
        let type = null;
        let id = null;
        if (shiftKey) {
          ({ type, id } = hoverIndexObj);
        } else {
          ({ type, id } = lastSelectedId);
        }
        // const totalItemsOfType = allItems[type].length - 1;
        let newIndex = allItems[type] && allItems[type].findIndex((el) => el === id) - 1;
        let checkIfElementsExist = allItems[type][newIndex];
        if (!checkIfElementsExist) {
          if (type === 'folders') return;
          type = 'folders';
          newIndex = allItems[type].length - 1;
        }
        const checkNewElemExists = allItems[type][newIndex];
        if (!checkNewElemExists) return;
        !shiftKey && setLastSelectedId({ type: type, id: allItems[type][newIndex] });
        const lastVisited = { ...hoverIndexObj };
        setHoverIndexObj({ type: type, id: allItems[type][newIndex] });
        if (shiftKey) {
          let lastSelectionIndex = allItems[lastSelectedId.type].findIndex(
            (el) => el === lastSelectedId.id
          );
          //for selection and unSelections
          // I am working here
          if (type === lastSelectedId.type) {
            let _selectedIds = getElementsBetweenIndexes(
              lastSelectionIndex,
              newIndex,
              allItems[type]
            );
            return setSelectedItems((previousItems) => {
              let newSelected = { ...previousItems };
              let newObj = mergeArraysWithUniqueValues(newSelected, type, _selectedIds);

              if (newIndex >= lastSelectionIndex) {
                let _type = lastVisited.type;
                let _id = lastVisited.id;
                newObj[_type] = newObj[_type].filter((elem) => elem !== _id);
              }

              return newObj;
            });
          } else {
            //if type is not current type
            let restFolders;
            let restCompetitions;
            if (lastSelectedId.type === 'competitions') {
              restCompetitions = getStartToEndElements(
                allItems['competitions'],
                lastSelectionIndex
              );
              restFolders = getIndexToEnd(allItems['folders'], newIndex);
            } else {
              restFolders = getIndexToEnd(allItems['folders'], lastSelectionIndex);
              restCompetitions = getStartToEndElements(allItems['competitions'], newIndex);
            }
            return setSelectedItems((previousItems) => {
              let newSelected = { ...previousItems };

              newSelected = mergeArraysWithUniqueValues(newSelected, 'folders', restFolders);
              newSelected = mergeArraysWithUniqueValues(
                newSelected,
                'competitions',
                restCompetitions
              );
              if (lastSelectedId.type !== 'competitions') {
                let _type = lastVisited.type;
                let _id = lastVisited.id;
                newSelected[_type] = newSelected[_type].filter((elem) => elem !== _id);
              }
              return newSelected;
            });
          }
        } else {
          return setSelectedItems((previousItems) => {
            let selected = { folders: [], competitions: [] };
            selected[type] = [allItems[type][newIndex]];
            return selected;
          });
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItems, folders, competitions.lastSelectedId]);

  return { selectedItems, handleSelect, handleSelectAll, handleClearSelection };
};

export default useMultiSelect;
