import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import { HiOutlineClock } from 'react-icons/hi';
import React, { useEffect, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { TIMEZONESHEADER } from '../../../../utils/timezonesHeader';
import { getTimeZone } from '../../../../redux/action/competitionActions';

const TimezoneDropdown = () => {
  const dispatch = useDispatch();
  const timezone = TIMEZONESHEADER();
  const [index, setIndex] = useState(-1);
  const [title, setTitle] = useState("");
  const currentTimezone = `${moment().tz(moment.tz.guess()).format('z')}`;

  useEffect(() => {
    let index = -1
    let storedZone = localStorage.getItem('date');
    if (storedZone) {
      timezone.forEach((zone, i) => {
        if (zone.value === storedZone) index = i
      })
      setIndex(index)
    }
  }, [])

  useEffect(() => {
    let tz = timezone[index === -1 ? 0 : index];
    if (tz?.name !== "AUTOMATIC") {
      const splited = tz?.name?.split(',');
      setTitle(splited[0]);
    } else {
      setTitle(tz?.name);
    }
    dispatch(getTimeZone(tz.value));
    localStorage.setItem('date', tz.value);
  }, [index]);

  return (
    <Dropdown className='dropdown-fix mr-3'>
          <Dropdown.Toggle variant="outline-secondary dropdown-button-fix text-white" className={'d-flex align-items-center justify-content-center'}>
            <HiOutlineClock size={'1.5em'} style={{marginRight: '1em'}} color={'#68717e'} />
            <span className='timezone-text'>{title}&nbsp;&nbsp;{title === 'AUTOMATIC' ? `(${currentTimezone})` : ''}</span>
            <RiArrowDropDownLine size={'1.5em'} style={{marginLeft: '.6em'}} color={'#68717e'} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {timezone.map((item, index) => (
                <Dropdown.Item className='dropdown-item-fix' key={index} eventKey={index} onSelect={(eventKey) => setIndex(eventKey)}>
                  <strong>{item.name.split(",")[0]}</strong>
                  <strong className='ml-2' style={{ color:'#68717e' }}>{`(${ item.name.split(",")[0] !== 'AUTOMATIC' ? item.name.split(",")[1] : currentTimezone})`}</strong>
                </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
  );
};

export default TimezoneDropdown;
