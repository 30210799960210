import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserKeyInfo from './includes/UserKeyInfo';
import UserTeams from './includes/UserTeams';
import UserPlayers from './includes/UserPlayers';
import { Loading } from '@common';

const UserProfile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/users/${id}`).then(({ data }) => {
      setProfile(data);
      setLoading(false);
    });
  }, [id]);

  return loading ? (
    <div className="col-12 text-center">
      <Loading />
    </div>
  ) : (
    <div className="user-profile">
      <div className="container">
        <div className="row">
          <div className="col-9">
            {/* name */}
            <div className="user-profile-header">
              <div className="header-name">
                <h1>
                  {profile.nickname
                    ? profile.nickname.trim()
                    : `${profile.firstName} ${profile.lastName}`}
                </h1>
                {profile.nickname && profile.nickname.trim() && (
                  <h4>{`${profile.firstName} ${profile.lastName}`}</h4>
                )}
              </div>
              <div
                className="header-avatar"
                style={{ backgroundImage: `url(${profile.avatarImage})` }}
              ></div>
            </div>

            <UserKeyInfo profile={profile} />
            <UserTeams teams={profile.teams} />
            <UserPlayers players={profile.assocPlayers} />
          </div>
          <div className="col-3">advert</div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
