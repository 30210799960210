import React, { useState, useEffect, useReducer, useRef, useContext, useMemo } from 'react';
// import 'antd/dist/antd.css';
import { HiFolder, HiDotsHorizontal } from 'react-icons/hi';
import './list-view.scss';
import { Button, Space, Table } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { TiArrowUnsorted } from 'react-icons/ti';
import ShareOptions from '../../_common/ShareOptions/ShareOptions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setContextObj } from '../../../redux/action/contextMenuAction';
import { usePermissions, usePermissionsOrg } from '../../../utils/usePermissions';
import ThreeDots from '../../_common/ThreeDots/ThreeDots';
import ContextMenu from '../../_common/ContextMenu/ContextMenu';
import Options from '../../_common/ThreeDots/Options/Options';
import useContextMenu from '../../_common/ContextMenu/hooks/useContextMenu';
import { getFolderOrCompetitonPermissions } from '../../../helpers/permissionsHelper';
import { AppContext } from '../../../context/AppContext';
const ListView = ({
  alldata,
  allDataObj,
  handleClearSelection,
  getTrans,
  handleSelection,
  selection
}) => {
  const isTrash = true;
  const { user } = useContext(AppContext);
  const [sortedData, setSortedData] = useState(allDataObj);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useDispatch();
  const contextMenu = useRef(document.getElementById('context-menu'));
  const categorizes = {
    today: 'Today',
    earlierThisWeek: 'Earlier This Week',
    lastWeek: 'Last Week',
    earlierThisMonth: 'Earlier This Month',
    lastMonth: 'Last Month',
    earlierThisYear: 'Earlier This Year',
    older: 'Older'
  };
  // const [trashData,setTrashData] = useState(allTrashData)
  // useEffect(() => {
  // setSortedData(alldata);
  // console.log(alldata,'----------')
  // }, [alldata]);
  // useEffect(()=>{
  //   // setSortedData(alldata)
  //   // console.log(alldata,'----------')
  // },[sortedData])
  // let showhead = { today: false, week: false, month: false, year: false, older: false };
  // sortedData.map((folder) => {
  //   if (folder.whichday === 'today') {
  //     showhead.today = true;
  //   }
  //   if (folder.whichday === 'Earlier this week') {
  //     showhead.week = true;
  //   }
  //   if (folder.whichday === 'Earlier this month') {
  //     showhead.month = true;
  //   }
  //   if (folder.whichday === 'Earlier this year') {
  //     showhead.year = true;
  //   }
  //   if (folder.whichday === 'Older') {
  //     showhead.older = true;
  //   }
  // });
  // console.log(showhead);
  const timeZone = useSelector((state) => state.TIMEZONE);
  const getDate = (date) => {
    if (!timeZone) {
      return date;
    } else {
      return moment(date).tz(timeZone?.timeZone);
    }
  };
  // const showContextMenu = (e, item, perm, type, isTrash = false) => {
  //   e.preventDefault();
  //   if (perm.showOptions) {
  //     document.body.style.overflow = 'hidden';
  //   }
  //   let obj = {
  //     item: item,
  //     permissions: perm,
  //     type: type,
  //     isTrash: isTrash
  //   };
  //   dispatch(setContextObj(obj));
  //   if (perm.showOptions) {
  //     const { clientX: mouseX, clientY: mouseY } = e;
  //     contextMenu.current.style.top = `${mouseY}px`;
  //     contextMenu.current.style.left = `${mouseX}px`;
  //     contextMenu.current.classList.add('visible');
  //   }
  // };
  // const sort = (col) => {
  //   // console.log(col);
  //   switch (col) {
  //     case 'Name':
  //       {
  //         let list = sortedData;
  //         // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
  //         list.sort(function (a, b) {
  //           // console.log((a.name<b.name))
  //           if (a.name < b.name) {
  //             return -1;
  //           }
  //           if (a.name > b.name) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         // console.log(alldata)
  //         // console.log(list)
  //         setSortedData(list);
  //         forceUpdate();
  //       }
  //       break;
  //     case 'TOURNAMENTDATE':
  //       {
  //         let list = sortedData;
  //         // list.sort((a,b)=> a.createdDate? new Date(b.createdDate) - new Date(a.createdDate):'')
  //         list.sort(function (a, b) {
  //           if (new Date(a.createdDate) < new Date(b.createdDate)) {
  //             return -1;
  //           }
  //           if (new Date(a.createdDate) > new Date(b.createdDate)) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         // console.log(alldata)
  //         // console.log(list)
  //         setSortedData(list);
  //         forceUpdate();
  //       }
  //       break;
  //     case 'MATCHES':
  //       {
  //         let list = sortedData;
  //         // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
  //         list.sort(function (a, b) {
  //           // console.log((a.name<b.name))
  //           if (a.name < b.name) {
  //             return -1;
  //           }
  //           if (a.name > b.name) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         // console.log(list)
  //         setSortedData(list);
  //         forceUpdate();
  //       }
  //       break;
  //     case 'ORG':
  //       {
  //         let list = sortedData;
  //         // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
  //         list.sort(function (a, b) {
  //           // console.log((a.name<b.name))
  //           if (a.name < b.name) {
  //             return -1;
  //           }
  //           if (a.name > b.name) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         // console.log(list)
  //         setSortedData(list);
  //         forceUpdate();
  //       }
  //       break;
  //     case 'FOLDER':
  //       {
  //         let list = sortedData;
  //         // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
  //         list.sort(function (a, b) {
  //           // console.log((a.name<b.name))
  //           if (a.name < b.name) {
  //             return -1;
  //           }
  //           if (a.name > b.name) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         // console.log(list)
  //         setSortedData(list);
  //         forceUpdate();
  //       }
  //       break;
  //     case 'ORGANIZER':
  //       {
  //         let list = sortedData;
  //         list.sort(function (a, b) {
  //           if (a.organizer < b.organizer) {
  //             return -1;
  //           }
  //           if (a.organizer > b.organizer) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         setSortedData(list);
  //         forceUpdate();
  //       }
  //       break;
  //     case 'LASTMODIFIED':
  //       {
  //         let list = sortedData;
  //         list.sort(function (a, b) {
  //           if (new Date(a.lastEditedDate) < new Date(b.lastEditedDate)) {
  //             return -1;
  //           }
  //           if (new Date(a.lastEditedDate) > new Date(b.lastEditedDate)) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         // console.log(list);
  //         setSortedData(list);
  //         forceUpdate();
  //       }
  //       break;

  //     case 'RESET':
  //       setSortedData(alldata);
  //       break;
  //     default:
  //       //you might want to do something as default, I don't
  //       break;
  //     // setSort(sort+1);
  //   }
  // };

  const searchSelection = (itemId) => {
    let flatArr = selection.flat(Infinity);
    for (let i = 0; i < flatArr.length; i++) {
      if (flatArr[i] === itemId) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <table
        className="table"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th scope="col" style={{ width: '20%' }}>
              <div className="for-arrow-recents" style={{ display: 'flex', alignItems: 'center' }}>
                <div>NAME</div>
                <div>
                  <TiArrowUnsorted
                    // onClick={() => sort('Name')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width: '17%' }}>
              <div className="for-arrow-recents" style={{ display: 'flex', alignItems: 'center' }}>
                <div>TOURNAMENT DATE</div>
                <div>
                  <TiArrowUnsorted
                    // onClick={() => sort('TOURNAMENTDATE')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width: '15%' }}>
              <div className="for-arrow-recents" style={{ display: 'flex', alignItems: 'center' }}>
                <div>MATCHES</div>
                <div>
                  <TiArrowUnsorted
                    // onClick={() => sort('MATCHES')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            {/*<th scope="col" >
            <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center'}}>
                <div>
                  ORG.
                </div>
                <div>
                  <TiArrowUnsorted
                    onClick={() => sort('MATCHES')}
                    style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th> */}
            <th scope="col" style={{ width: '15%' }}>
              <div className="for-arrow-recents" style={{ display: 'flex', alignItems: 'center' }}>
                <div>FOLDER</div>
                <div>
                  <TiArrowUnsorted
                    // onClick={() => sort('MATCHES')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width: '15%' }}>
              <div className="for-arrow-recents" style={{ display: 'flex', alignItems: 'center' }}>
                <div>ORGANIZER</div>
                <div>
                  <TiArrowUnsorted
                    // onClick={() => sort('MATCHES')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width: '13%' }}>
              <div className="for-arrow-recents" style={{ display: 'flex', alignItems: 'center' }}>
                <div>LAST MODIFIED</div>
                <div>
                  <TiArrowUnsorted
                    // onClick={() => sort('LASTMODIFIED')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width: '5%' }}></th>
          </tr>
        </thead>
      </table>
      {/* 
        {showhead.today === true ? (
          <>
            <h2>{showhead.today === true ? 'Today' : null}</h2>
            <tbody>
              {sortedData.map((folder, ind) =>
                folder.timedifference < 25 ? (
                  folder?.isfolder === true ? (
                    <FolderRow
                      folder={folder}
                      isTrash
                      showContextMenu={showContextMenu}
                      getDate={getDate}
                      getTrans={getTrans}
                      // trashSelected={searchSelection(folder._id)}
                      // onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                    />
                  ) : (
                    <OrganizerRow
                      folder={folder}
                      isTrash
                      showContextMenu={showContextMenu}
                      getDate={getDate}
                      getTrans={getTrans}
                      // trashSelected={searchSelection(folder._id)}
                      // onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                    />
                  )
                ) : null
              )}
            </tbody>
          </>
        ) : null} */}

      {Object.entries(sortedData).map(([key, value]) =>
        value?.length ? (
          <>
            <h2>{categorizes[key]}</h2>
            <table
              className="table"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              style={{ borderCollapse: 'collapse' }}>
              <tbody>
                {value?.map((item, ind) => {
                  let _disabled = searchSelection(item?._id);
                  let selectionType = item?.isFolder ? 'folders' : 'competitions';
                  return item?.isFolder === true ? (
                    <FolderRow
                      user={user}
                      folder={item}
                      _disabled={!_disabled}
                      isTrash
                      permissions={[...item?.organizerRoles]}
                      type={'TRASH_FOLDER'}
                      // showContextMenu={showContextMenu}
                      getDate={getDate}
                      getTrans={getTrans}
                      trashSelected={searchSelection(item._id)}
                      onClick={(e) => handleSelection(e, item._id, item.whichday)}
                    />
                  ) : item?.isOrganizer ? (
                    <OrganizerRow
                      folder={item}
                      isTrash
                      // showContextMenu={showContextMenu}
                      getDate={getDate}
                      getTrans={getTrans}
                      trashSelected={searchSelection(item._id)}
                      onClick={(e) => handleSelection(e, item._id, item.whichday)}
                    />
                  ) : (
                    <CompetitionRow title={item?.name} logo={item?.logo} item={item} _disabled={!_disabled} onClickHandler={(e) => handleSelection(e, item._id, item.whichday)}/>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null
      )}

      {/* {showhead.week === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {sortedData.map((folder, ind) =>
              folder.timedifference < 168 && folder.timedifference > 24 ? (
                folder?.isfolder === true ? (
                  <FolderRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                ) : (
                  <OrganizerRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                )
              ) : null
            )}
          </tbody>
        </table>
      ) : null}
      <h2>{showhead.month === true ? 'Early this month' : null}</h2>
      {showhead.month === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {sortedData.map((folder, ind) =>
              folder.timedifference > 169 && folder.timedifference < 720 ? (
                folder?.isfolder === true ? (
                  <FolderRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                ) : (
                  <OrganizerRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                )
              ) : null
            )}
          </tbody>
        </table>
      ) : null}
      <h2>{showhead.year === true ? 'Early this year' : null}</h2>
      {showhead.year === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {sortedData.map((folder, ind) =>
              folder.timedifference < 8760 && folder.timedifference > 719 ? (
                folder?.isfolder === true ? (
                  <FolderRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                ) : (
                  <OrganizerRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                )
              ) : null
            )}
          </tbody>
        </table>
      ) : null}
      <h2>{showhead.older === true ? 'Older' : null}</h2>
      {showhead.older === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {sortedData.map((folder, ind) =>
              folder.timedifference > 8759 ? (
                folder?.isfolder === true ? (
                  <FolderRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                ) : (
                  <OrganizerRow
                    folder={folder}
                    isTrash
                    showContextMenu={showContextMenu}
                    getDate={getDate}
                    getTrans={getTrans}
                    trashSelected={searchSelection(folder._id)}
                    onClick={(e) => handleSelection(e, folder._id, folder.whichday)}
                  />
                )
              ) : null
            )}
          </tbody>
        </table>
      ) : null} */}
    </>
  );
};

const FolderRow = ({
  user,
  folder,
  permissions,
  _disabled,
  isTrash,
  showContextMenu,
  getTrans,
  getDate,
  onClick,
  trashSelected,
  type
}) => {
  // const folderPerm = usePermissions(folder?._id);
  const { userPermissions, showDots } = useMemo(
    () => getFolderOrCompetitonPermissions(user, permissions),
    [permissions]
  );
  const { isVisible, x, y, showMenu, hideMenu } = useContextMenu();
  const handleContextMenu = (event) => {
    event.preventDefault();
    // onClickHandler(event, folder?._id, selectionType);
    onClick(event);
    showMenu(event);
  };
  return (
    <tr
      key={folder._id}
      id={folder._id}
      className="hoverable-row"
      onClick={onClick}
      onContextMenu={handleContextMenu}
      style={trashSelected ? { backgroundColor: '#050c16' } : {}}>
      <td style={{ width: '20%' }}>
        <Col className="name-row text">
          <Row>
            <HiFolder size={25} color={!_disabled ? '#3eabf0' : '#8fa3b1'} />
            <span className="text" style={!_disabled ? { color: '#3eabf0' } : {}}>
              {folder?.name}
            </span>
          </Row>
        </Col>
      </td>
      <td style={{ width: '17%' }}></td>
      <td style={{ width: '15%' }}></td>
      {/* <td></td> */}

      <td style={{ width: '15%' }}>
        <Col className="org-name">
          <Row>
            <HiFolder size={15} />
            STEELSERIES
          </Row>
        </Col>
      </td>
      {/* <td>
        <Col className="org-name">
          <Row></Row>
        </Col>
      </td> */}
      <td scope="col" style={{ width: '15%' }}>
        <Col className="me-organizer" style={trashSelected ? { color: '#3eabf0' } : {}}>
          {folder?.organizer?.name}
        </Col>
      </td>
      <td scope="col" style={{ width: '13%' }}>
        <Col className="last-modified">
          {' '}
          {folder?.lastEditedDate
            ? moment(getDate(folder?.lastEditedDate)).format('MM-DD-YYYY') +
              ' - ' +
              folder?.lastEditor?.firstName +
              ' ' +
              folder?.lastEditor?.lastName
            : undefined}
          {/* {moment(getDate(folder?.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss')} */}
        </Col>{' '}
      </td>
      <td scope="col" style={{ width: '5%' }}>
        <Col className="options-dots">
          {showDots && (
            <ThreeDots
              userPermissions={userPermissions}
              user={user}
              type={type}
              disabled={_disabled}
              folder={type === 'TRASH_FOLDER' && folder}
              competition={type === 'TRASH_COMPETITION' && folder}
              // onFirstClick={(e) => onClickHandler(e, folder?._id, selectionType)}
              onFirstClick={onClick}
            />
          )}
          {showDots && isVisible && !_disabled && (
            <ContextMenu x={x} y={y} onClose={hideMenu} isVisible={isVisible}>
              <Options
                type={type}
                userPermissions={userPermissions}
                folder={type === 'TRASH_FOLDER' && folder}
                competition={type === 'TRASH_COMPETITION' && folder}
              />
            </ContextMenu>
          )}
        </Col>
      </td>
    </tr>
  );
};
const OrganizerRow = ({
  folder,
  isTrash,
  showContextMenu,
  getTrans,
  getDate,
  onClick,
  trashSelected
}) => {
  const orgPerm = usePermissionsOrg(folder?._id);

  return (
    <tr
      onContextMenu={(e) => {
        showContextMenu(e, folder, orgPerm, 'ORGANIZER', isTrash);
      }}
      style={trashSelected ? { backgroundColor: '#050c16' } : {}}
      key={folder._id}
      id={folder._id}
      className="hoverable-row"
      onClick={onClick}>
      <td style={{ width: '20%' }}>
        <Col className="name-row text">
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span className="imgLogoContainer">
                <img
                  className=" imgLogoWidth imgLogo  imgLogoCropped"
                  src={
                    folder.logo
                      ? folder.logo
                      : 'https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png'
                  }
                  alt="Insight Gaming"
                  width={'10px'}
                />
              </span>
              <span
                className="text"
                style={{
                  maxWidth: '175px',
                  textOverflow: 'ellipsis',
                  marginLeft: '300px',
                  height: '18px',
                  width: '235px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'inline-block',
                  margin: '0 5px 0 5px',
                  textAlign: 'left',
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: trashSelected ? '#3eabf0' : '#FFF'
                }}>
                {folder.name}
              </span>
            </div>
          </Row>
        </Col>
      </td>
      <td style={{ width: '17%' }}></td>
      <td style={{ width: '15%' }}></td>
      {/* <td> */}
      {/* <Col className="org-name"> */}
      {/* <Row> */}
      {/* <HiFolder size={15} /> */}
      {/* STEELSERIES */}
      {/* </Row> */}
      {/* </Col> */}
      {/* </td> */}
      <td>
        {/* <Col className="org-name">
          <Row>
            <HiFolder size={15} />
            STEELSERIES
          </Row>
        </Col> */}
        {/* <Col className="org-name">
          <Row>hello</Row>
        </Col> */}
      </td>
      <td style={{ width: '15%' }}>
        <Col className="me-organizer">
          <span style={{ textTransform: 'capitalize' }}>
            {folder.isorganizer ? folder.name : ''}
          </span>
        </Col>
      </td>
      <td style={{ width: '13%' }}>
        <Col className="last-modified">
          {' '}
          {/* {moment(getDate(folder?.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss')} */}
          {folder.lastEditedDate
            ? moment(getDate(folder.lastEditedDate)).format('MM-DD-YYYY') +
              ' - ' +
              folder?.lastEditor?.firstName +
              ' ' +
              folder?.lastEditor?.lastName
            : undefined}
        </Col>{' '}
      </td>
      <td style={{ width: '5%' }}>
        <Col className="options-dots">
          {folder?.isfolder === true ? (
            <ShareOptions
              getTrans={getTrans}
              isTrash={isTrash}
              folderId={folder._id}
              folderName={folder.name}
              style={trashSelected ? { color: '#3eabf0', backgroundColor: '#091625' } : {}}
            />
          ) : (
            <ShareOptions
              getTrans={getTrans}
              isTrash={isTrash}
              organizerId={folder._id}
              folderName={folder.name}
              style={trashSelected ? { color: '#3eabf0', backgroundColor: '#091625' } : {}}
            />
          )}
        </Col>
      </td>
    </tr>
  );
};

const CompetitionRow = ({
  _disabled = true,
  organiser,
  item,
  folder = {},
  permissions,
  logo,
  last_edited,
  ind = 0,
  title,
  onClickHandler = () => {},
  onDoubleClickHandler = () => {},
  type,
  selectionType
}) => {
  const {user} = useContext(AppContext)
  const { userPermissions, showDots } = useMemo(
    () => getFolderOrCompetitonPermissions(user, permissions),
    [permissions]
  );
  const { isVisible, x, y, showMenu, hideMenu } = useContextMenu();
  const handleContextMenu = (event) => {
    event.preventDefault();
    // onClickHandler(event, folder?._id, selectionType);
    onClickHandler(event);
    showMenu(event);
  };
  return (
    <tr onContextMenu={handleContextMenu} onClick={onClickHandler}>
      {' '}
      <td>
        <Col className="name-row text" style={{ marginLeft: 16 }}>
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span className="imgLogoContainer" style={{ color: _disabled ? 'black' : '#8fa3b1' }}>
                {/* add folder instead of image */}
                {logo ? (
                  <img
                    className="imgLogoWidth imgLogoCropped"
                    src={logo}
                    alt="Insight Gaming"
                    width={'25px'}
                    height={'25px'}
                  />
                ) : (
                  <HiFolder size={25} color={'#FFF'} />
                )}
              </span>
              <span
                className="text"
                style={{
                  maxWidth: '250px',
                  textOverflow: 'ellipsis',
                  marginLeft: '10px',
                  height: '18px',
                  width: '235px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'inline-block',
                  textAlign: 'left',
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: _disabled ? '#FFF' : '#3eabf0'
                }}>
                {title ?? 'SteelSeries'}
              </span>
            </div>
          </Row>
        </Col>
      </td>
      <td
        className="table-date-f"
        style={{
          color: !_disabled ? '#3eabf0' : '#8fa3b2',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        {item.isFolder ?? moment(item?.createdDate).format('YYYY-MM-DD')}
      </td>
      <td>
        {/* <Col
    className="me-organizer"
    style={{
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50%',
      // width: '30px',
      color: _disabled ? '' : '#296f9e'
    }}>
    <div style={{ width: '25px', height: '22px', display: 'inline-flex' }}>
      <img
        src={owner && owner?.avatarImage}
        style={{ borderRadius: 15 }}
        alt={owner && owner?.firstName ? owner?.firstName + ' ' + owner?.lastName : 'No name'}
      />
    </div>
    {owner && owner?._id === user?.state?._id ? (
      <span style={{ textTransform: 'capitalize', marginLeft: '5px' }}>Me</span>
    ) : (
      owner?.firstName + ' ' + owner?.lastName
    )}
  </Col> */}
      </td>
      <td
        className="table-matches"
        style={{
          color: !_disabled ? '#3eabf0' : '#8fa3b2',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        {item?.isfolder ?? item?.matches ?? 0}
      </td>
      <td style={{ width: '15%' }}></td>
      <td>
        <Col className="last-modified" style={{ color: _disabled ? '' : '#296f9e' }}>
          {last_edited}
        </Col>{' '}
      </td>
      <td>
        <Col className="options-dots">
          {showDots && (
            <ThreeDots
              userPermissions={userPermissions}
              user={user}
              type={type}
              disabled={_disabled}
              folder={type === 'FOLDER' && item}
              competition={type === 'COMPETITION' && item}
              onFirstClick={(e) => onClickHandler(e, item?._id, selectionType)}
            />
          )}
          {showDots && isVisible && !_disabled && (
            <ContextMenu x={x} y={y} onClose={hideMenu} isVisible={isVisible}>
              <Options
                type={type}
                userPermissions={userPermissions}
                folder={type === 'FOLDER' && item}
                competition={type === 'COMPETITION' && item}
              />
            </ContextMenu>
          )}
        </Col>
      </td>
    </tr>
  );
};
export default ListView;
