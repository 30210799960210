import React, { useEffect } from "react";
import "./SwitchButton.css";

const SwitchButton = ({ label, onChange,isOn, disabled = false }) => {
return (
	<div className="sw-container">
	<div className="toggle-switch">
		<input type="checkbox" disabled={disabled} className="checkbox" onChange={onChange}
			name={label} id={label} />
		<label className="sw-label" htmlFor={label}>
		<span className={`inner stages-toggle ${isOn && 'is-on' } `} />
		<span className="switch" />
		</label>
	</div>
    {label}{" "}
	</div>
);
};

export default SwitchButton;
