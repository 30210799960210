import Axios from 'axios';
import React, { useContext, useRef, useEffect } from 'react';
import { useState } from 'react';
import { HiExternalLink, HiEye, HiOutlineEye, HiPencil, HiUserGroup } from 'react-icons/hi';
import { AppContext } from '../../../context/AppContext';
import Uploadlogo from '../../_modals/Uploadlogo/Uploadlogo';
import Tippy from '@tippyjs/react';
import CompetitionTabs from './CopetitionsTabs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCompetition,
  getCompetitionsData,
  updateCompetition,
  getCompetitionsMatches,
  getAllTeams,
  getFilterMatches,
  getInformationData,
  setStageIndexGlobally
} from '../../../redux/action/CompetitonData';
import io from 'socket.io-client';
import socketIOClient from 'socket.io-client';
import { set } from 'core-js/library/fn/dict';
import useRoles from '../../../utils/useRoles';
import moment from 'moment';
import SharePopup from '../../_modals/SharePopup/SharePopup';
import { setPermissionsForCompetition } from '../../../redux/action/competitionActions';
import { FullPageLoader } from '../../_common';
const backendServerURL = process.env.REACT_APP_CORE_API;
const Competition = () => {
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [logoEditModal, setLogoEditModal] = useState(false);
  const dispatch = useDispatch();
  const socketRef = useRef();
  const [liveUsers, setLiveUsers] = useState([]);
  const SOCKET_SERVER_URL = process.env.REACT_APP_CORE_API;
  const competition = useSelector((state) => state.COMPETITION.Competition);
  const filterQuery = useSelector((state) => state?.COMPETITION?.filterMatches);
  const [logo, setLogo] = useState();
  const [name, setName] = useState();
  const [cover, setCover] = useState();
  const [query, setQuery] = useState({});
  const [competitionStatus, setCompetitionStatus] = useState();
  const [saveName, setSaveName] = useState();
  const userPermissions = useRoles();
  const [open, setOpen] = useState(false);
  const [owners, setOwners] = useState([]);
  const [previewOn, setPreviewOn] = useState(false);
  const [hidePreview, setHidePreview] = useState(false);
  useEffect(() => {
    //////SETTING STAGEINDEX TO ZERO ON MOVING TO ANOTHER COMPETITION
    return () => {
      dispatch(setStageIndexGlobally(0));
    };
  }, []);
  useEffect(() => {
    dispatch(getCompetition(id));
    dispatch(getCompetitionsData(id));
    dispatch(getAllTeams(id));
    dispatch(getInformationData(id));
  }, [id]);
  useEffect(() => {
    if (id && Object.keys(query).length) {
      dispatch(getCompetitionsMatches({ id, query }));
    }
  }, [id, query]);
  useEffect(() => {
    setQuery(filterQuery);
  }, [filterQuery]);
  const updatenameAndLogo = () => {
    if (id && socketRef?.current?.id && name && logo) {
      return Axios.post(`${backendServerURL}/api/organizer/competition/${id}/rename`, {
        competitionId: id,
        name: name,
        logo: logo,
        cover: competition?.multiStageLeague?.cover,
        socketId: socketRef?.current?.id
      });
    }
    if (name?.length == 0) {
      setName(saveName);
    }
  };

  useEffect(() => {
    // setLogo(competition?.multiStageLeague?.logo);
    // setName(competition?.multiStageLeague?.name);
    // setCover(competition?.multiStageLeague?.cover);
    setDefaultPictures()
    setCompetitionStatus(competition?.multiStageLeague?.visibility);
  }, [competition?.multiStageLeague]);

  useEffect(() => {
    const competitionRoomId = id;
    if (user.state && id) {
      if (!socketRef.current) {
        // console.log(user.state);
        const { firstName, lastName, _id, avatarImage, nickname } = user.state;
        // console.log(`${firstName} "${nickname}" ${lastName}`);
        const userName =
          nickname && nickname.length > 1
            ? `${firstName} "${nickname}" ${lastName}`
            : firstName + ' ' + lastName;
        // console.log('kdhgfkhshg');
        socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
          query: { competitionRoomId, _id, userName, avatarImage, nickname }
        });
      }
    }
  }, [id, user, socketRef]);
  useEffect(() => {
    return () => {
      dispatch(setPermissionsForCompetition([]));
      if (socketRef?.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on('usersInCompetition', (UserList) => {
        setLiveUsers(UserList);
      });

      socketRef.current.on('disconnect', () => {
        console.log('Connection is disconnected ');
      });

      socketRef.current.on('BannerData', (data) => {
        if (name !== null && logo !== null) {
          // setName(data.name);
          // setLogo(data.logo);
          dispatch(updateCompetition(data));
        }
      });
      socketRef.current.on('changeCompetitionStatus', (Data) => {
        setCompetitionStatus(Data);
      });
      return () => {
        socketRef.current.off('changeCompetitionStatus');
        socketRef.current.off('disconnect');
        socketRef.current.off('usersInCompetition');
        socketRef.current.off('BannerData');
      };
    }
  }, [socketRef]);

  const setDefaultPictures = ()=>{
    // console.log(competition?.multiStageLeague?.name)
    setLogo(competition?.multiStageLeague?.logo);
    setName(competition?.multiStageLeague?.name);
    setCover(competition?.multiStageLeague?.cover);
  }
  const onShareCompetition = (id, competition) => {
    let inheritedPermissions = competition?.inheritedPermissions ?? [];
    // let orgnazerRoles = competition?.multiStageLeague?.organizerRoles ?? []
    // setOwners([...inheritedPermissions,...orgnazerRoles])
    dispatch(setPermissionsForCompetition(inheritedPermissions));
    setOpen(true);
    // debugger;
  };
  const handlePreview = () => {
    setPreviewOn(!previewOn);
  };

  const checkButtonAvailability = (perms) => {
    if (perms.administrateCompetitions || perms.changePermission) return false;
    return true;
  };
  return !userPermissions ? (
    <FullPageLoader />
  ) : (
    <>
      <div
        style={{
          background: `linear-gradient(to top, rgb(3, 6, 13) 20%, rgba(3, 6, 13, 0) 120%), url(${cover})`,
          position: 'absolute',
          height: '200px',
          width: '100%'
        }}></div>
      <div className="bannerContainer" style={{ zIndex: '1' }}>
        <div
          className={
            userPermissions.administrateCompetitions ? 'logoSide logoSide-hover' : 'logoDisabled'
          }
          onClick={() => userPermissions?.administrateCompetitions && setLogoEditModal(true)}>
          <img className="bannerlogo" src={logo} alt="not found" />
          <span className="pencil">
            <HiPencil className="pencilClass" />
          </span>
        </div>
        <div className="nameSide">
          <div className="Inputfield" style={{ marginLeft: '10px' }}>
            <Tippy content="Rename" placement="bottom">
              <input
                type="text"
                id="name"
                name="name"
                disabled={!userPermissions?.administrateCompetitions}
                style={{ width: `${(name?.length ? name?.length + 2 : 3) * 21.5 + 'px'}` }}
                onBlur={() => updatenameAndLogo()}
                className={
                  name?.length < 1
                    ? 'invalid bannerinput'
                    : userPermissions.administrateCompetitions
                    ? 'bannerinput'
                    : 'bannerinputDisabled'
                }
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                onClick={(e) => {
                  setSaveName(e.target.value);
                }}
              />
            </Tippy>
          </div>

          <div className="bannerContainer">
            <div className="Publictag">
              <span
                className="samiCircle"
                style={{
                  color:
                    competitionStatus === 'Public'
                      ? '#22db60'
                      : competitionStatus === 'Unlisted'
                      ? '#d5901f'
                      : competitionStatus === 'Private'
                      ? '#fd1049'
                      : '#22262e'
                }}>
                {competitionStatus === 'Scheduled'
                  ? competitionStatus +
                    ' ' +
                    moment(competition?.multiStageLeague?.publishDate.slice(0, 9))
                      .format('Do of MMMM YYYY')
                      .toString()
                  : competitionStatus}
              </span>
            </div>
            <div className="textNextToPublicTag">
              EUW - 5V5 - Promotion to Divison 2 - Single elemination
            </div>
          </div>
        </div>
        <div className="buttonsSide">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {liveUsers?.slice(0, 4).map((user, index) =>
              user?.avatarImage?.length > 2 ? (
                <Tippy content={user.userName} placement="bottom">
                  <a
                    href={`${process.env.REACT_APP_FRONTEND_URL}/players/${user.userId}/`}
                    className="profile-main">
                    <img
                      key={index}
                      style={{
                        width: '40px',
                        height: '40px',
                        marginLeft: `${-15}px`,
                        borderRadius: '50%',
                        backgroundColor: 'black'
                      }}
                      src={user.avatarImage}
                    />
                  </a>
                </Tippy>
              ) : (
                <Tippy content={user.userName} placement="bottom">
                  <div
                    className="profile-img-with-inital-1-letter"
                    style={{
                      width: '40px',
                      height: '40px',
                      marginLeft: `${-7}px`,
                      borderRadius: '50%'
                    }}>
                    {name?.charAt(0)}
                  </div>
                </Tippy>
              )
            )}
            {liveUsers.length > 5 ? (
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  marginLeft: `${-7}px`,
                  borderRadius: '50%',
                  background: '#22262e',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                {liveUsers.length - 5}+
              </div>
            ) : null}
          </div>
          {!hidePreview && (
            <button className="shareButton perviewButton" onClick={handlePreview}>
              {previewOn ? <HiEye size={22} /> : <HiOutlineEye size={22} />} <span>preview</span>
            </button>
          )}
          <a href={`${process.env.REACT_APP_FRONTEND_URL}/single-competition/${id}`}>
            <button className="shareButton">
              <HiExternalLink /> view on leagues.gg
            </button>
          </a>
          <button
            className="shareButton"
            disabled={checkButtonAvailability(userPermissions)}
            onClick={() => onShareCompetition(id, competition)}>
            <HiUserGroup /> SHARE
          </button>
        </div>
      </div>
      <CompetitionTabs
        setName={setName}
        setLogo={setLogo}
        setCover={setCover}
        previewOn={previewOn}
        socketRef={socketRef}
        userPermissions={userPermissions}
        setHidePreview={setHidePreview}
        setDefaultPictures={setDefaultPictures}
      />
      <Uploadlogo
        setLogo={setLogo}
        isOpen={logoEditModal}
        setLogoEditModal={setLogoEditModal}
        updatenameAndLogo={updatenameAndLogo}
      />
      {open && (
        <SharePopup
          open={open}
          folderName={competition?.multiStageLeague?.name}
          owners={{ type: 'COMPETITION', _id: competition?.multiStageLeague?._id }}
          onClose={() => setOpen(!open)}
        />
      )}
    </>
  );
};
export default Competition;
