import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import boostrapTheme from '@fullcalendar/bootstrap';
// import { toast } from "react-toastify";ß
// import Axios from "axios";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

const Calendar = ({
  matches,
  prefferedTimezone,
  onEditMatch,
  selectedStageIndex,
  selectedGroupIndex,
  isAllStage,
  isAllGroup
}) => {
  const [calendarView, setCalendarView] = useState();

  // const syncNotification = useRef(null);

  useEffect(() => {
    // console.log({selectedStageIndex,matches,selectedStage})
    setCalendarView(
      <FullCalendar
        contentHeight="auto"
        themeSystem="bootstrap5"
        plugins={[
          boostrapTheme,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          momentTimezonePlugin
        ]}
        timeZone={prefferedTimezone}
        headerToolbar={{
          left: 'title',
          center: '',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,prev,next today'
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={matches?.map((match) => {
          const zonedDatetime = moment.tz(match.datetime, null, true, prefferedTimezone);
          const newDateTime = moment(zonedDatetime).format();
          return {
            id: match._id,
            title: `${
              match.t1
                ? match.t1.name
                : match.isSeedingMatch
                ? match?.sp1?.seedPosition ? `Seed ${match?.sp1?.seedPosition}` : 'TBD'
                : '[DELETED]'
            } vs ${
              match.t2
                ? match.t2.name
                : match.isSeedingMatch
                ? match?.sp2?.seedPosition ? `Seed ${match?.sp2?.seedPosition}` : 'TBD'
                : '[DELETED]'
            }`,
            start: newDateTime,
            extendedProps: match,
            backgroundColor: match?.groups[0]?.color
          };
        })}
        eventClick={onEventClick}
        eventChange={onEventChange}
        eventTimeFormat={{
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          // hour12: false,
          hourCycle: 'h23'
        }}
      />
    );
  }, [prefferedTimezone, isAllStage, selectedStageIndex, selectedGroupIndex, isAllGroup]);

  const onEventClick = ({ event }) => {
    console.log('Edit match is not a func : =>>', event);

    onEditMatch(event.extendedProps);
  };

  const onEventChange = async ({ event }) => {
    console.log('EVENT : ', event);
    // if (syncNotification.current) toast.dismiss(syncNotification.current);
    // syncNotification.current = toast.info("Syncing matches...", { autoClose: false });

    // try {
    //   await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${event.id}`, { datetime: event.startStr });

    //   toast.update(syncNotification.current, { render: "Sync complete", type: toast.TYPE.SUCCESS, autoClose: 2000 });
    // } catch (e) {
    //   toast.update(syncNotification.current, { render: "Problem syncing matches", type: toast.TYPE.ERROR, autoClose: 2000 });
    // }
  };

  return <div>{calendarView}</div>;
};

export default Calendar;
