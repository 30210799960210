import { combineReducers } from 'redux';
import translationReducer from './translationReducer';
import selectLanguageReducer from './selectedLanguageReducer';
import foldersReducer from './competitionReducer';
import trashReducer from './trashReducer';
import dragNDropReducer from './dragNDropReducer';
import timeZoneReducer from './timeZoneReducer';
import loadingReducer from './loadingReducer';
import contextMenuReducer from './contextMenuReducer';
import competitionDataReducer from './CompetitonDataReducer';
import previewTabsReducer from './previewTabsReducer';

const AppReducer = combineReducers({
  TRANSLATION: translationReducer,
  LANGUAGE: selectLanguageReducer,
  TRASH: trashReducer,
  FOLDERS: foldersReducer,
  DRAGNDROP: dragNDropReducer,
  TIMEZONE: timeZoneReducer,
  LOADING: loadingReducer,
  CONTEXTMENU: contextMenuReducer,
  COMPETITION: competitionDataReducer,
  PREVIEW: previewTabsReducer
});
export default AppReducer;
