import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import Loading from '../../../../_common/Loading/Loading';
import { AppContext } from '../../../../../context/AppContext';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useTranslated from '../../../../../helpers/translationHelper';

const DeleteAccountModal = ({ isOpen, onClose }) => {
  const { ghost } = useContext(AppContext);
  const history = useHistory();
  const [text, setText] = useState('');
  const [loadingText, setLoadingText] = useState(true);
  const [password, setPassword] = useState('');
  const [processing, setProcessing] = useState(false);

  const ghostSlug = 'delete-user';
  const accountDeletionMessTrans = useTranslated('id_settings_(message)_2');
  let _delete_your_account_translation = useTranslated('id_pop-up_-_delete_account_0');
  let _to_continue_translation = useTranslated('id_pop-up_-_delete_account_2');
  let _delete_account_translation = useTranslated('id_pop-up_-_delete_account_3');
  useEffect(() => {
    ghost.pages.read({ slug: ghostSlug }).then((page) => {
      setText(page);
      setLoadingText(false);
    });
  }, [ghost]);

  const onDeleteSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/deleteAccount`, { password });
      toast.success(/* "Successful" */ accountDeletionMessTrans);
      localStorage.removeItem('sessionToken');
      history.push('/');
    } catch (e) {
      const error = e.response.data ? e.response.data.msg : 'There was a problem';
      toast.error(error);
    }

    setProcessing(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal">
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>{_delete_your_account_translation}</h2>
      </div>
      <div className="modal-body">
        {loadingText ? (
          <Loading />
        ) : (
          <div className="delete-account-text">
            <div dangerouslySetInnerHTML={{ __html: text.html }} />

            <p className="to-continue-text">{_to_continue_translation}</p>

            <form onSubmit={onDeleteSubmit} id="delete-acc-form" autoComplete="off">
              <div className="form-row">
                <input
                  type="password"
                  className="form-input"
                  autoComplete="new-password"
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-row">
                <button
                  type="submit"
                  className="button danger thicc"
                  disabled={!password || processing}
                >
                  {processing ? <Loading /> : `${_delete_account_translation}`}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
