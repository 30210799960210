import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearUnsavedStages,
  getStagesData,
  setCurrentStage,
  setStageIndexGlobally,
  setUnSavedStages
} from '../../../../redux/action/CompetitonData';
import { Loading, StageTabs } from '../../../_common';
import Stage from './Stages/Stage';
import useDidMountEffect from './../../.././../helpers/useDidMountEffect';
const checkChanges = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
const getStagesComp = (Component, dispatch) => {
  return function EnhancedComponent({
    stages,
    UnsavedStages,
    page,
    setPage,
    disableSave,
    setDisableSave,
    setDisableDelete
  }) {
    if (stages[page]) {
      let find = UnsavedStages.find((el) => el._id === stages[page]._id);
      if (find) {
        let isSame = checkChanges(stages[page], find);
        if (!isSame) {
          dispatch(setCurrentStage(find));
          return (
            <Component
              setPage={setPage}
              disableSave={disableSave}
              setDisableSave={setDisableSave}
              setDisableDelete={setDisableDelete}
              stageIndex={page}
              currentStage={find}
            />
          );
        } else {
          dispatch(setCurrentStage(stages[page]));
          return (
            <Component
              setPage={setPage}
              disableSave={disableSave}
              setDisableSave={setDisableSave}
              setDisableDelete={setDisableDelete}
              stageIndex={page}
              currentStage={stages[page]}
            />
          );
        }
      } else {
        dispatch(setCurrentStage(stages[page]));
        return (
          <Component
            setPage={setPage}
            disableSave={disableSave}
            setDisableSave={setDisableSave}
            setDisableDelete={setDisableDelete}
            stageIndex={page}
            currentStage={stages[page]}
          />
        );
      }
    } else {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>
      );
    }
  };
};
const StagesTab = ({ id }) => {
  const [nextPage, setNextPage] = useState(0);
  const [Tabs, setTabs] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const dispatch = useDispatch();
  const stageIndexGlobally = useSelector((state) =>
    state.COMPETITION.StageIndex ? state.COMPETITION.StageIndex : 0
  );
  const stages = useSelector((state) =>
    state.COMPETITION.StageTabs ? state.COMPETITION.StageTabs : []
  );
  const UnsavedStages = useSelector((state) =>
    state.COMPETITION.UnsavedStages ? state.COMPETITION.UnsavedStages : []
  );
  useEffect(() => {
    if (stageIndexGlobally !== 0) {
      let _stageIndexGlobally = stageIndexGlobally - 1;
      setNextPage(_stageIndexGlobally);
    }
  }, [stageIndexGlobally]);

  useDidMountEffect(() => {
    let _nextPage = nextPage + 1;
    if (stageIndexGlobally !== _nextPage) {
      dispatch(setStageIndexGlobally(_nextPage));
    }
  }, [nextPage]);
  useEffect(() => {
    return () => {
      dispatch(clearUnsavedStages());
    };
  }, []);
  useEffect(() => {
    if (stages.length < 1) {
      dispatch(getStagesData({ id }));
    }
  }, [id]);
  useEffect(() => {
    let _tabs = stages.map((stage) => ({ name: stage.name, _id: stage._id }));
    setTabs(_tabs);
  }, [stages]);
  const StageBody = React.memo(getStagesComp(Stage, dispatch));
  return (
    <>
      <StageTabs
        id={id}
        frontpage
        pages={Tabs}
        stages={stages}
        disableSave={disableSave}
        disableDelete={disableDelete}
        setPage={setNextPage}
        page={nextPage}
      />
      <StageBody
        stages={stages}
        setPage={setNextPage}
        disableSave={disableSave}
        setDisableDelete={setDisableDelete}
        setDisableSave={setDisableSave}
        UnsavedStages={UnsavedStages}
        page={nextPage}
      />
    </>
  );
};
export default StagesTab;
