import Axios from 'axios';
import { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { GoCloudUpload } from 'react-icons/go';
import React, { useState, useRef } from 'react';
import { AiTwotoneCamera } from 'react-icons/ai';
import Loading from '../_common/Loading/Loading';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import EditImageModal from '../_modals/EditImageModal/EditImageModal';
import useTranslated from '../../helpers/translationHelper';

const Cropper = ({
  name,
  style,
  width,
  height,
  size,
  textunderHeading,
  textunderButton,
  reuploadbuttontext,
  setNameinput,
  nameinput,
  editableimg,
  Uploadimage,
  checkisempty,
  isLogo,
  isBanner,
  isThumbnail,
  aspectRatio='auto'
}) => {
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState();
  const [crop, setCrop] = useState();
  const lableDragAndDrop = 'Click or drag to upload an image';
  const lableBanner=useTranslated("id_pop-up_-_upload_image_3")
  //  useTranslated('id_pop-up_-_upload_image_4');
  useEffect(() => {

    if (
      nameinput.logo === '' &&
      nameinput.cover === '' &&
      nameinput.thumbnail === '' &&
      nameinput.name === ''
    ) {
      setImage('');
    }
  }, [nameinput]);
  useEffect(()=>{
    setImage(editableimg)
console.log('editableimg',editableimg)
  },[editableimg])
const handleUpload = (uploadAbleImg)=>{

  const file = new File([uploadAbleImg], `${name}.png`, {
    type: 'image/png'
  });
  const formData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  formData.append('image', file);
  Axios.post(`https://dev-api-core.leagues.gg/api/upload`, formData, config)
  .then(({ data }) => {
    if (name === 'LOGO') {
      setNameinput({ ...nameinput, logo: data.file });
    }
    if (name === 'BANNER') {
      setNameinput({ ...nameinput, cover: data.file });
    }
    if (name === 'thumbnail') {
      setNameinput({ ...nameinput, thumbnail: data.file });
    }
  })
  .catch((err) => {
    console.log(err);
  });
}
  const handleDrop = (dropped) => {
    // Get image from drop
    const _image = dropped[0];
    const imageObj = new Image();
    imageObj.src = window.URL.createObjectURL(_image);
    // imageObj.addEventListener("load", isTransparent, true);

    imageObj.onload = () => {
      // console.log(width,height,size,image.size,imageObj.width,imageObj.height)
      const imageSize = _image && _image.size;
      const imageWidth = imageObj.width;
      const imageHeight = imageObj.height;
      // Is image less than 8MB?
      if (imageSize > size) return toast.error(`File must be smaller than ${size / 1000000}MB`);

      // Is image the minimum dimensions?
      if (imageWidth < width || imageHeight < height)
        return toast.error(`File must be at least ${width}px x ${height}px in size`);
      setImage(URL.createObjectURL(_image));
      setCrop(true)

    };
  };

  const onClickReupload = (name) => {
    if (document.getElementById(`${name}`)) {
      document.getElementById(`${name}`).click();
    }
  };

  return (
    <>
      <div className="edit-image-body">
        <div class="form-row">
          
          {/* <h2 for="logo" style={{textTransform:'uppercase',color:'#ffffff',font:"normal normal bold 12px/15px Montserrat" }}>{name}</h2> */}
          <h2 for="logo" style={{marginBottom:'10px'}}><span style={style}>{name} </span></h2>
          <p style={{ color: 'rgb(153, 153, 153)',font: 'normal normal normal 12px/15px Montserrat',
          fontSize: '0.75rem',
          lineHeight:' 1rem',
          fontWeight: 'normal',
          color: '#68717e',
          width: '410px',height: '30px',display:'contents' }}>
            {textunderHeading ? textunderHeading : null}
          </p>
        </div>
        {/* <span style={{ maxWidth: '600px', maxHeight: '150px' }}> */}

        <div style={image ? { position: 'relative' } : { display: 'none' }}>
            <img style={{ width: `100%`,height:'auto', aspectRatio: aspectRatio }} src={image} alt="not found" />
            <div className={`save-buttons reuploadbutton`} style={{margin : name === 'BANNER' ? '5px 4px 5px 5px' :'4px' }} useRef={`${name}`}>
              <button
                className="button"
                style={{ background: '#68717E 0% 0% no-repeat padding-box', borderRadius: '2px',padding: name === 'BANNER'? '9px 17.5px': name==='thumbnail'? '9px 6px':null }}
                onClick={() => onClickReupload(name)}
                disabled={uploading}>
                <AiTwotoneCamera style={{ fontSize: '1.5em', marginBottom: '-5px',marginRight:'5px'}} />
                {uploading ? <Loading /> : reuploadbuttontext ? reuploadbuttontext : null}
              </button>
            </div>
        </div>
        {/* </span> */}
        <div className={`image-dropzoneonhover`} style={image ? { display: 'none' } : null}>
          <Dropzone
            multiple={false}
            accept={['image/jpeg', 'image/png', 'image/gif']}
            onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div
              style={{    display: 'flex',
                alignItems: 'center',
                justifyContent:' center',}}
                className={`image-dropzone uplodimglogo ${
                  // isLogo && checkisempty?.logo === true
                  //   ? 'dotedorder'
                  //   : isBanner && checkisempty?.cover === true
                  //   ? 'dotedorder'
                  //   : isThumbnail && checkisempty?.thumbnail === true
                  //   ? 'dotedorder'
                  //   : 
                    'image-dropzone'
                } `}
                id={`${name}`}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="Uploadimage">
                  
                  <HiOutlineCloudUpload style={{display:'flex',flexDirection:'column',border:'none' ,alignItem:'center',top: '362px',left: '706px',width: '23px',height: '25px',color:'#68717e',marginTop:'-12px'}} className='cloudIcon' />
                  <label
                    className="UploadimageLabel"
                    style={{border:'none',margin:'5px 0px'}}
                    // style={{ width: '100%', color: 'white', border: '0px' }}
                  >
                    {Uploadimage}
                  </label>
                  <div className="lableDragAndDrop">{lableDragAndDrop}</div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div style={{ marginBottom: '15px' }}>{textunderButton ? textunderButton : null}</div>
      </div>
      {
        image && (
            <EditImageModal
            open={crop}
            name={name}
            width={width}
            height={height}
            borderRadius={0}
            buttonText={'Crop'}
            defaultImage={image}
            setLogoEditModal={() => {
                setCrop(!crop)
                setImage(false)
            }}
            onClose={() => {
                setCrop(!crop)
                setImage(false)
            }}
            logoCropped={(value) => {
              // debugger
                // setImage(URL.createObjectURL(value));
                handleUpload(value)
                setCrop(!crop)
              }}
              afterImageUpload={(file) => {}}
              afterImageUploadFullSize={(file) => {}}
            />
        )
      }
    </>
  );
};

export default Cropper;
