import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import moment from 'moment';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { checkIfTimeInBody } from '../../utils/additional-helpers';
import Tippy from '@tippyjs/react';

const SingleNotification = ({
  _id,
  expired,
  title = 'NOTIFICATION_TITLE',
  body,
  actions = [],
  read,
  setRead,
  createdAt
}) => {
  const { user } = useContext(AppContext);
  const history = useHistory();

  const onToggleRead = async () => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/notifications/${_id}/markAs`, {
        read: !read
      });
      setRead(!read);

      // Update notifications count in header
      const newCount = !read === true ? user.state.notifications - 1 : user.state.notifications + 1;
      user.dispatch({ type: 'update', payload: { notifications: newCount } });
    } catch (e) {
      toast.error("Couldn't change notification status");
    }
  };

  const onClickAction = async (url, type) => {
    try {
      if (!type) {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api${url}`);
        toast.success('Success');
      } else if (type == 'web') {
        window.open(url, '_blank');
      }
      onToggleRead();
    } catch (e) {
      toast.error('A problem occurred. Please try again later.');
    }
  };

  const handleMatchRescheduleRequest = async (url) => {
    try {
      await onToggleRead();
      history.push(url);
    } catch (e) {
      toast.error('Failed to mark notification as read');
    }
  };

  return (
    <div className="notification-single">
      <p className="notif-single-title">{title}</p>
      <p className="notif-single-body">{checkIfTimeInBody(body)}</p>
      <p className="notif-single-date">{moment(createdAt).fromNow()}</p>

      {actions.map(({ name, url, type }) =>
        !expired ? (
          name === 'view request' ? (
            <button
              className={`button small ${name === 'view request' ? 'primary' : 'bordered'}`}
              onClick={() => handleMatchRescheduleRequest(url)}
            >
              {name}
            </button>
          ) : (
            !read && (
              <button
                className={`button small ${name === 'Accept' ? 'primary' : 'bordered'}`}
                onClick={() => onClickAction(url, type)}
              >
                {name}
              </button>
            )
          )
        ) : (
          <Tippy
            content={`This ${
              title.includes('New Team Invite') ? 'invitation' : 'notification'
            } has expired`}
          >
            <span>
              <button
                key={url}
                className={`button small mr-2`}
                style={{ backgroundColor: 'lightgray' }}
                disabled={true}
              >
                {name}
              </button>
            </span>
          </Tippy>
        )
      )}

      {/* <button onClick={onToggleRead}>Mark as {read ? "unread" : "read"}</button> */}
    </div>
  );
};

export default SingleNotification;
