import React, { useState } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../../../_common/Loading/Loading';
import { useHistory } from 'react-router-dom';
import { FaSteam } from 'react-icons/fa';
const ConnectCSGO = ({ gameData, afterConnect }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [summonerName, setSummonerName] = useState('');
  const [primaryRole, setPrimaryRole] = useState('');
  const [secondaryRole, setSecondaryRole] = useState('');
  const [code, setCode] = useState('');

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     const { data } = await Axios.post(`${process.env.REACT_APP_CSGO_API}/api/connect`, {
  //       game: gameData._id,
  //       name: summonerName
  //     });
  //     toast.success("Successfully created CSGO player!");
  //     setLoading(false);

  //     afterConnect(data.player);

  //     history.push(`/game-profile/${data.player._id}`);
  //   } catch (e) {
  //     const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating player";
  //     toast.error(msg);
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <a href={`${process.env.REACT_APP_CSGO_API}/authenticate`} class="steambutton">
        <span>Login With Steam</span>
        <div class="icon">
          <FaSteam />
        </div>
      </a>
    </>
  );
};

export default ConnectCSGO;
