import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DeleteItems from '../../../_modals/OrganizerPopup/deleteitems/delete';

const Editbar = ({ unSelected, showDelete, selectedItems,handleClearSelection=()=>{} }) => {
  // const selectedFolders = useSelector((state) => state.DRAGNDROP.SelectedFolders ?? []);
  const [deletePopup, setDeletePopup] = useState(false);

  const duplicate = () => {};
  const move = () => {};
  const share = () => {};
  const publicity = () => {};
  const formatTemplate = () => {};
  const organizationTemplate = () => {};
  const deleteall = () => {
    setDeletePopup(true);
  };
  return (
    <>
      <div
        style={{
          // width: '1650px',
          width: '100.35%',
          height: '50px',
          display: 'flex',
          flexDirection: 'row',
          background: '#22262E',
          justifyContent: 'space-between',
          alignItems: 'center',
          // marginBottom:"30px",
          paddingRight: '5px',
          marginTop: '-17px'
        }}>
        <div style={{ marginLeft: '14px' }}>
          {unSelected ? null : `${[...selectedItems?.folders,...selectedItems?.competitions].length} ITEMS SELECTED`}
        </div>

        <div>
          {/* <button
            className="button primary"
            style={{ marginRight: '9px', height: '30px' }}
            onClick={duplicate}>
            DUPLICATE
          </button> */}
          {/* <button
            className="button primary"
            style={{ marginRight: '9px', height: '30px' }}
            onClick={move}>
            MOVE
          </button> */}
          {/* <button
            className="button primary"
            style={{ marginRight: '9px', height: '30px' }}
            onClick={share}>
            SHARE
          </button> */}
          {/* <button
            className="button primary"
            style={{ marginRight: '9px', height: '30px' }}
            onClick={publicity}>
            EDIT PUBLICITY
          </button> */}
          {/* <button
            className="button primary"
            style={{
              marginRight: '9px',
              height: '30px',
              background: '#D5901F',
              border: '1 px solid #D5901F'
            }}
            onClick={formatTemplate}>
            Apply Format Template
          </button> */}
          {/* <button
            className="button primary"
            style={{
              marginRight: '9px',
              height: '30px',
              background: '#D5901F',
              border: '1 px solid #D5901F'
            }}
            onClick={organizationTemplate}>
            Apply Organization Template
          </button> */}
          {showDelete && (
            <button
              className="button primary"
              style={{
                marginRight: '9px',
                height: '30px',
                background: '#F11857',
                border: '1 px solid #F11857'
              }}
              onClick={deleteall}>
              DELETE
            </button>
          )}
        </div>
      </div>
      {showDelete && (
        <DeleteItems
          open={deletePopup}
          setDelete={() => setDeletePopup(!deletePopup)}
          multipleDelete={selectedItems}
          type={'MULTIPLE'}
          handleClearSelection={handleClearSelection}
        />
      )}
    </>
  );
};
export default Editbar;
