import React from 'react'
import { string_to_slug } from '@utils';
import Tippy from '@tippyjs/react';

const ToggleSlider = ({onChange,checked,unique,label,label_style,toggleColor}) => {
    return (
        <div className="checkbox-slider-wrapper noselect" key={string_to_slug(unique)}>
          <input
            type="checkbox"
            id={string_to_slug(unique)}
            checked={checked}
            onChange={(e) =>onChange(e)}
            name={string_to_slug(unique ?? label)}
          />
          <label
            htmlFor={string_to_slug(unique)}
            className={ 'checkbox-slider'}
            style={{ alignSelf: 'baseline', backgroundColor: `${checked ? toggleColor : '#1c2029'} ` }}></label>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label
              htmlFor={string_to_slug(unique)}
              className={label_style ?? 'uppercase bold'} 
              style={!checked ? {color :'#68717e'}: {}}>
              {checked ?"Victory" : "Defeat"}
            </label>
          </div>
        </div>
      );
}

export default ToggleSlider



