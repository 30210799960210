import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '@context/AppContext';
import Icon from '@common/Icon/Icon';
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/shift-toward.css';
import {
  HiOutlineCollection,
  HiOutlineClock,
  HiOutlineTemplate,
  HiOutlineTrash
} from 'react-icons/hi';
import { IconTournament } from '@tabler/icons';
import { IoLogoGameControllerB } from 'react-icons/io';
import { AiOutlineBars } from 'react-icons/ai';
import { RiTrophyLine } from 'react-icons/ri';
import useTranslated from '../../../helpers/translationHelper';

const Sidebar = ({ open }) => {
  const { authenticated } = useContext(AppContext);
  const { selectedGame } = useContext(AppContext);
  const sidebarItems = {
    section1: [
      // {
      //   tabName: "Overview" ,
      //   path: '/overview',
      //   displayName:"Overview",
      //   icon:  <Icon name="overview" pureIcon={<AiOutlineBars size={`2.3em`} />} />
      // },
      {
        tabName: 'My Competitions',
        path: '/',
        icon: <Icon name="bracket" pureIcon={<HiOutlineCollection size={`2.3em`} />} />,
        displayName: 'My Competitions'
      },
      // {
      //   tabName: "Share With Me",
      //   path: '/shared-with-me',
      //   icon: <Icon name="scrims" pureIcon={<IoLogoGameControllerB size={`2.3em`} />} /> ,
      //   displayName:"Share With Me"
      // },
      {
        tabName: 'Recents',
        path: '/recents',
        icon: <Icon name="tournaments" pureIcon={<HiOutlineClock size={`2.3em`} />} />,
        displayName: 'Recent'
      }
    ],
    section2: [
      {
        tabName: 'Templates',
        path: '/templates',
        icon: <Icon name="scrims" pureIcon={<HiOutlineTemplate size={`2.3em`} />} />,
        displayName: 'Templates'
      }
    ],
    section3: [
      {
        tabName: 'TeTrashams',
        path: '/trash',
        icon: (
          <Icon
            name="scrims"
            style={{ color: 'gray' }}
            pureIcon={<HiOutlineTrash size={`2.3em`} />}
          />
        ),
        displayName: 'Trash'
      }
    ]
  };
  const renderSidebarItems = (key) => {
    return sidebarItems[key].map((item,i) => {
      let isAccessible = true;
      // !selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[item.tabName]);
      return (
        <li key={i} style={item.tabName == 'Overview' ? { marginTop: '24px', marginBottom: '0px' } : {}}>
          <Tippy
            disabled={open || !isAccessible}
            content={item.displayName}
            placement="right"
            animation="shift-toward"
          >
            <Link
              to={item.path}
              disabled={!isAccessible}
              style={!isAccessible ? { opacity: 0.5, cursor: 'none', pointerEvents: 'none' } : {}}
            >
              {item.icon}
              {item.displayName}
            </Link>
          </Tippy>
        </li>
      );
    });
  };

  return (
    <nav className={`global-sidebar ${!open ? 'closed' : ''}`}>
      <ul style={{ marginTop: '-5px'}}> {renderSidebarItems('section1')} </ul>
      <hr style={{ marginTop: '22px' }} />

      <ul> {renderSidebarItems('section2')} </ul>
      <hr />

      <ul> {renderSidebarItems('section3')} </ul>
    </nav>
  );
};

export default Sidebar;
