import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import useHandleClickOutside from './hooks/useHandleClickOutside';

const ContextMenu = ({ x, y, onClose, children, isVisible }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden'; //FOr stopping the scroll
      ref.current.classList.add("context-menu-fade-in");
    } else {
        document.body.style.overflow = 'scroll'; //For continuing scroll
    
      ref.current.classList.remove("context-menu-fade-in");
    }
  }, [isVisible]);
  const handleClick = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    console.log({ isVisible });
  }, [isVisible]);
  useHandleClickOutside(ref, onClose);
  return ReactDOM.createPortal(
    <div
      ref={ref}
      className="context-menu-11"
      style={{
        top: y,
        left: x,
        display: 'block' ,
        padding: '3px 12px',
        // opacity:  '0',
        // transition: 'opacity 100ms linear',
        position: 'fixed'
      }}
      onClick={handleClick}>
      {isVisible && children}
    </div>,
    document.body
  );
};

export default ContextMenu;
