import React from 'react';
import { string_to_slug } from '@utils';
import Tippy from '@tippyjs/react';

const CheckboxSlider = ({
  label,
  label_style,
  value,
  explenamtion,
  onChange,
  checked,
  slider,
  icon,
  tooltipText,
  data,
  unique,
  isSetScore = false,style
}) => {
  return (
    <div className="checkbox-slider-wrapper noselect">
      <input
        type="checkbox"
        id={data ? data?._id : string_to_slug(unique ?? label)}
        checked={checked || value}
        onChange={(e) => {
          if (data) {
            onChange(e, data);
          } else {
            onChange(e);
          }
        }}
        name={data ? data?._id : string_to_slug(unique ?? label)}
      />
      <label
        htmlFor={data ? data?._id : string_to_slug(unique ?? label)}
        className={slider ?? 'checkbox-slider'}
        style={{ alignSelf: 'baseline' }}></label>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label
          htmlFor={data ? data?._id : string_to_slug(unique ?? label)}
          className={label_style ?? 'uppercase bold'} 
          style={!value ? {color :'#8b8d91'}:{}}>
          {isSetScore && (checked ?"Victory" : "Defeat")}
          {!isSetScore && label}
        </label>
        <p style={style && style}>{explenamtion ? explenamtion : null}</p>
      </div>
      {icon && (
        <Tippy content={tooltipText} placement="right" animation="shift-toward">
          <div className="icon">{icon}</div>
        </Tippy>
      )}
    </div>
  );
};

export default CheckboxSlider;
