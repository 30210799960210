import React, { createContext, useState, useEffect, useReducer } from 'react';
import Axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import verifyToken from './utils/verifyToken';
import userReducer from './reducers/userReducer';
import subscribeNotifications from './utils/subscribeNotifications';
import GhostContentAPI from '@tryghost/content-api';
import GlitchText from '../components/_common/GlitchText/GlitchText';
import logo_header from '@img/logo-header.svg';
import './appcontext.css';
export const AppContext = createContext();
export const WithAppContext = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showRouterComponent, setShowRouterComponent] = useState(true);
  const [userState, userDispatch] = useReducer(userReducer, false);
  const [selectedGame, setSelectedGame] = useState(JSON.parse(localStorage.selectedGame || false));
  // const [filters, setFilters] = useState();
  const [ghost] = useState(
    new GhostContentAPI({
      url: process.env.REACT_APP_GHOST_URL,
      key: process.env.REACT_APP_GHOST_CONTENT_KEY,
      version: 'v3'
    })
  );
  // Check authentication & set user data on load and each page change
  const history = useHistory();
  const { pathname } = useLocation();

  const remountDom = () => {
    setShowRouterComponent(false);
    setTimeout(() => setShowRouterComponent(true), 10);
  };
  const setUserData = (tkn = false) => {
    const validTokenData = verifyToken(tkn);

    if (validTokenData) {
      if (!authenticated || !userState) {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/auth`)
          .then(async ({ data }) => {
            const superAdmin = data?.admin;
            const sharedOrg = data.sharedOrganizer > 0;
            const orgAccess = data?.permissions?.user?.OrganizerAccess;
            const orgAdmin = data.permissions.user.OrganizerAdmin;
            if (!superAdmin && !sharedOrg && !orgAdmin && !orgAccess) {
              return window.location.replace(process.env.REACT_APP_FRONTEND_URL, '_self');
            }
            await subscribeNotifications(data.pushSubscriptions); // setup notifications
            setAuthenticated(true);
            userDispatch({
              type: 'load',
              payload: {
                ...validTokenData,
                ...data
              }
            });
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            localStorage.removeItem('sessionToken');
            window.location.replace(process.env.REACT_APP_FRONTEND_URL, '_self');
            setLoading(false);
          });
      }
    } else {
      // Clear authentication state
      setAuthenticated(false);
      if (Object.keys(userState).length) {
        userDispatch({ type: 'load', payload: false });
      }
      setLoading(false);
    }
  };
  // Authentication setup
  useEffect(() => {
    setUserData();
  }, [history, pathname, authenticated, userState]);

  // get games, and set the first one as the selected game, if it isn't already been retrieved from LS
  useEffect(() => {
    if (!selectedGame) {
      //   Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      //     // setSelectedGame(data[0]);
      //     setSelectedGame(data.filter((gamdata) => gamdata.defaultGame === true)[0]);
      //   });
      // } else {
      //   Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${selectedGame._id}`).then(
      //     ({ data }) => {
      //       if (data.updatedAt !== selectedGame.updatedAt) {
      //         //Update time change
      //         setSelectedGame(data);
      //       }
      //     }
      //   );
      let game = {
        name: 'Organize',
        shortName: 'Organize',
        whiteIcon:
          'https://leagues-static.s3.eu-west-2.amazonaws.com/img/White%20Game%20Icon-200-200-05c819ce-ecca-4d53-ad6b-2d9ca07f264f.png',
        mainColor: '#3eabf0'
      };

      setSelectedGame(game);
    }
  }, [selectedGame]);

  // Update LS when selected game is changed
  useEffect(() => {
    let game = {
      name: 'Organize',
      shortName: 'Organize',
      whiteIcon:
        'https://leagues-static.s3.eu-west-2.amazonaws.com/img/White%20Game%20Icon-200-200-05c819ce-ecca-4d53-ad6b-2d9ca07f264f.png',
      mainColor: '#3eabf0'
    };
    selectedGame && localStorage.setItem('selectedGame', JSON.stringify(game));
  }, [selectedGame]);

  useEffect(() => {
    userState && localStorage.setItem('timezone', userState?.timezone);
  }, [userState]);

  const context = {
    authenticated,
    setAuthenticated,
    showAuth,
    setShowAuth,
    ghost,
    selectedGame,
    setSelectedGame,
    showRouterComponent,
    remountDom,
    loading,
    setUserData,
    // filters,
    // setFilters,
    user: { state: userState, dispatch: userDispatch }
  };

  return loading ? (
    <div style={{ textAlign: 'center' }}>
      <div style={{ lineHeight: '100vh', display: 'inline-block' }}>
        {/* 'Loading.........' */}
        <div className="logo-text">
          <GlitchText class="glitch">
            <div className="d-flex justify-content-center align-items-center">
              <img src={logo_header} alt="Leagues logo" width="55px" height="55px" />
              <strong
                className="uppercase logo__text"
                style={{ marginLeft: '10px', color: 'white', fontSize: '40px' }}>
                LEAGUES.GG
              </strong>
            </div>
          </GlitchText>
        </div>
      </div>
    </div>
  ) : (
    <AppContext.Provider value={context}>{children}</AppContext.Provider>
  );
};
