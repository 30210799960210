import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { HiArrowNarrowRight, HiUserGroup, HiX } from "react-icons/hi";



const Group = (props) => {
  const { groupId, allGroup, groups, seeds, totalSeeds, teams, updateSeeding, stageSeedings, onlySingleGroup, handleRemoveSeeding } = props;
  const ARROW_COLOR = "#68717e";
  const [newGroupSeeds, setNewGroupSeeds] = useState([]);
  // const [groupSeeds, setGroupSeeds] = useState(seeds !== undefined ? seeds : []);
  const [selectedGroup, setSelectedGroup] = useState(groups !== undefined ? groups.filter((item) => item._id === groupId) : []);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);

  useEffect(() => {
    if (!allGroup) {
      let _selectedGroup = seeds.filter((item) => item.groupId === groupId);
      setSelectedGroup(_selectedGroup);
    }
  }, [seeds]);

  useEffect(() => {
    // console.log("selectedGroupsTeam  stageSeedings: ", stageSeedings);
    if (stageSeedings) {
      let _groupSeeds = [];
      groups.forEach((group) => {
        let _group = { ...group };
        let _groupTeams = stageSeedings.filter((item) => item.group && item.group._id === group._id).map((li) => li);
        let _positiveSeeds = _groupTeams.filter(item => item.seedPosition > 0);
        let seedsPresent = _positiveSeeds.map(item => item.seedPosition);
        // let _negativeSeeds = _groupTeams.filter(item => item.seedPosition < 0);
        let remainingSeeds = [];
        let missing = [];
        for (let i = 1; i <= group.noOfTeams; i++) {
          if (seedsPresent.indexOf(i) == -1) {
            missing.push(i);
          }
        }
        missing.forEach((item) => {
          remainingSeeds.push({
            group: _group,
            seedPosition: item,
            team: 'TBD',
            noTeam: true
          })
        })
        let totalTeams = [..._positiveSeeds, ...remainingSeeds].sort((a, b) => a.seedPosition - b.seedPosition);
        // _groupTeams = [..._positiveSeeds, ..._negativeSeeds];
        _group.teams = totalTeams;
        _groupSeeds.push(_group);
      });
      setNewGroupSeeds(_groupSeeds);
    }
  }, [stageSeedings, updateSeeding]);

  useEffect(() => {
    if (selectedGroup) {
      let _selectedGroupIndex = groups.findIndex((item) => item._id === groupId);
      setSelectedGroupIndex(_selectedGroupIndex);
    }
  }, [selectedGroup]);

  return (
    <>
      {allGroup ? (
        // groups?.length > 0 &&
        // groups?.map((groupItem, groupIndex) =>
        //   groupIndex % 2 === 0 ? (
        <Row className="groups-row">
          {newGroupSeeds
            .reduce(function (accumulator, currentValue, currentIndex, array) {
              if (currentIndex % 2 === 0) accumulator.push(array.slice(currentIndex, currentIndex + 2));
              return accumulator;
            }, [])
            .map((groupItem, groupIndex) => (
              <Row key={groupIndex} className="group-row">
                {groupItem[0] && (
                  <Col className="group-container">
                    <Row className="header-row group-box">
                      <div>
                        <h3 className="group-name">{groupItem[0].name}</h3>
                      </div>
                      {groupItem[0].teams.map((_team, index) => {
                        return (
                          <div key={groupItem[0]._id + "xxx" + index} className='w100'>
                            <Row className="group-row" >
                              <div className={`seed-pos m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {_team?.noTeam || _team.seedPosition < 0 ? index + 1 : _team.seedPosition}</div>
                              <HiArrowNarrowRight color={ARROW_COLOR} size={25} />
                              <div className={`input-group filled m5 w195 ${_team.team === "TBD" || _team.isTbd || !_team.isComplete || _team?.seedPosition < 0 ? 'no-spot-team' : ''}`}>
                                {/* {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? "TBD" : _team?.team?.team?.name} */}
                                {_team.team === "TBD" || _team.isTbd || !_team.isComplete || _team?.seedPosition < 0 ?
                                  "Empty spot"
                                  :
                                  <div className="team-container">
                                    {_team?.team?.team?.logo ?
                                      <img src={_team?.team?.team?.logo} className='team-icon' />
                                      :
                                      <HiUserGroup size={17} style={{ marginRight: '10px', marginLeft: '10px' }} color={"#68717e"} />
                                    }
                                    {_team?.team?.team?.name}
                                  </div>
                                }
                                {/* Placeholder 1 */}
                                {/* <HiX className='cross' size={20} color='#F11857' /> */}
                                {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? null :
                                  <HiX className='cross'
                                    size={20} color='#F11857'
                                    onClick={() => handleRemoveSeeding(groupItem[0]._id, _team.team, _team.seedPosition)}
                                  />}

                              </div>
                            </Row>
                          </div>
                        )
                      }
                      )}
                    </Row>
                  </Col>)}
                {groupItem[1] && (
                  <Col className="group-container ">
                    <Row className="header-row group-box">
                      <div>
                        <h3 className="group-name">{groupItem[1].name}</h3>
                      </div>
                      {groupItem[1].teams.map((_team, index) => {
                        return (
                          <div key={groupItem[1]._id + "xxx" + index} className='w100'>
                            <Row className="group-row" >
                              <div className={`seed-pos m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {_team?.noTeam || _team.seedPosition < 0 ? index + 1 : _team.seedPosition}</div>
                              {/* <div className={`m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {index + 1}</div> */}
                              <HiArrowNarrowRight color={ARROW_COLOR} size={25} />
                              <div className={`input-group filled m5 w195 ${_team.team === "TBD" || _team.isTbd || !_team.isComplete || _team?.seedPosition < 0 ? 'no-spot-team' : ''}`}>
                                {/* {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? "TBD" : _team?.team?.team?.name} */}
                                {_team.team === "TBD" || _team.isTbd || !_team.isComplete || _team?.seedPosition < 0 ?
                                  "Empty spot"
                                  :
                                  <div className="team-container">
                                    {_team?.team?.team?.logo ?
                                      <img src={_team?.team?.team?.logo} className='team-icon' />
                                      :
                                      <HiUserGroup size={17} style={{ marginRight: '10px', marginLeft: '10px' }} color={"#68717e"} />
                                    }
                                    {_team?.team?.team?.name}
                                  </div>
                                }
                                {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? null :
                                  <HiX className='cross' size={20} color='#F11857'
                                    onClick={() => handleRemoveSeeding(groupItem[1]._id, _team.team, _team.seedPosition)}
                                  />}
                              </div>
                            </Row>
                          </div>
                        )
                      })}
                    </Row>
                  </Col>
                )}
              </Row>
            ))}
        </Row>
      ) : (
        <Row className="group-row-single">
          <Col className="group-container single-group-">
            <Row className="header-row group-box" style={{ flexDirection: 'column' }}>
              <div>
                <h3 className="group-name">{onlySingleGroup ? "Placements" : newGroupSeeds[selectedGroupIndex]?.name}</h3>
              </div>
              {newGroupSeeds[selectedGroupIndex]?.teams.map((_team, index) => {
                return (
                  <div key={newGroupSeeds[selectedGroupIndex]._id + "xxx" + index} className='w100'>
                    <Row className="group-row">
                      <div className={`seed-pos m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {_team?.noTeam || _team.seedPosition < 0 ? index + 1 : _team.seedPosition}</div>
                      <HiArrowNarrowRight color={ARROW_COLOR} size={25} />
                      <div className={`input-group filled m5 w195 ${_team.team === "TBD" || _team.isTbd || !_team.isComplete || _team?.seedPosition < 0 ? 'no-spot-team' : ''}`}>
                        {_team.team === "TBD" || _team.isTbd || !_team.isComplete || _team?.seedPosition < 0 ?
                          "Empty spot"
                          :
                          <div className="team-container">
                            {_team?.team?.team?.logo ?
                              <img src={_team?.team?.team?.logo} className='team-icon' />
                              :
                              <HiUserGroup size={17} style={{ marginRight: '10px', marginLeft: '10px' }} color={"#68717e"} />
                            }
                            {_team?.team?.team?.name}
                          </div>
                        }
                        {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? null :
                          <HiX className='cross' size={20} color='#F11857'
                            onClick={() => handleRemoveSeeding(newGroupSeeds[selectedGroupIndex]?._id, _team.team, _team.seedPosition)}
                          />}
                        {/* <HiX className='cross' size={20} color='#F11857' /> */}
                      </div>
                    </Row>
                  </div>
                )
              })}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Group;

// const Wrapper = styled.div`
//   .team-card-dist {
//     margin: auto !important;
//   }
//   .header-row {
//     margin: 0px 0px 2px 0px !important;
//   }
//   .m4 {
//     margin: 4px 0px 0px 0px !important;
//   }
//   .m5 {
//     margin: 5px 4px 5px 5px !important;
//   }
//   .m25 {
//     border: 1px solid gainsboro !important;
//     border-radius: 5px;
//     margin: 5px 5px 5px 5px !important;
//     width: 300px !important;
//   }
//   .w300 {
//     border: 1px solid gainsboro !important;
//     border-radius: 5px;
//     margin: 5px 5px 5px 5px !important;
//     width: 300px !important;
//   }
//   .w250 {
//     width: 250px !important;
//   }
//   .w56 {
//     width: 56px !important;
//   }
//   .w57 {
//     width: 57px !important;
//   }
//   .w145 {
//     width: 150px !important;
//   }
//   .group-name {
//     margin: auto !important;
//   }
//   .input {
//     border: 1px dashed !important;
//     overflow: hidden !important;
//     white-space: nowrap !important;
//   }
//   .line {
//     font-weight: 900;
//     magin: 4px 4px 4px 4px !important;
//   }
//   .container {
//     padding: 5px 5px 5px 5px;
//   }
//   .group-container {
//     margin: 5px 5px 5px 5px !important;
//     border: 1px solid gainsboro !important;
//     padding: 0px 0px 0px 0px !important;
//     border-radius: 5px !important;
//     height: min-content;
//   }
//   .group-empty {
//     margin: 5px 5px 5px 5px !important;
//     padding: 0px 0px 0px 0px !important;
//   }
//   .group-row {
//     margin: 0px 0px 2px 0px !important;
//   }
//   .group-row-single {
//     margin: 5px 5px 5px 5px !important;
//   }
//   .row-seeding {
//     margin: auto;
//     justify-content: space-between;
//     border: 1px solid gainsboro;
//     border-radius: 5px;
//     padding: 5px 5px 5px 5px;
//   }
// `;
