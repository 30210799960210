import React, { useEffect, useState } from "react";
import { SortableList, SortableLeadBoardListItem, Loading, SimpleTabs } from "@common";
import Axios from "axios";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { Col } from "react-bootstrap";
import MultiStageDropdown from "../../_common/MultiStageDropdown/MultiStageDropdown";
import { FiArrowUp } from "react-icons/fi";
const Leaderboard = ({ id,defaultLogo }) => {
  const [loading, setLoading] = useState(true);
  const [leagueFromGame, setLeagueFromGame] = useState(null);
  const [allStagsLeaderboard, setAllStagsLeaderboard] = useState([]);
  const [stageTabs, setStageTabs] = useState([]);
  const [stageAllGroups, setStageAllGroups] = useState([]);
  const [stageGroupNames, setStageGroupNames] = useState([]);
  const [stageIndex, setStageIndex] = useState(0);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [filteredLeaderboard, setFilteredLeaderboard] = useState([]);
  const [lastStage, SetLastStage] = useState(null);
  const [isAll, setIsAll] = useState(true);

  useEffect(() => {
    if (stageIndex === 0) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }
  }, [stageIndex]);
  const setData = (stageLeaderboard) => {
    let groupTeams = [];
    let _groupsArr = [];
    const groupNames = stageLeaderboard.groups.map((group) => {
      groupTeams = groupTeams.concat(group.teams);
      _groupsArr = [..._groupsArr, { stageName: stageLeaderboard.name, group: group }];
      return group.name;
    });
    setStageGroupNames(groupNames);
    setFilteredLeaderboard(groupTeams);
    setStageAllGroups(_groupsArr);
  };

  const onSeletedChange = async (data) => {
    selectedStage.groups.forEach((g, i) => {
      let val = data.find((data) => g._id === data.groupId);
      if (val.status) {
        setSelectedGroupIndex(i);
        return g;
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/leaderboard`) // Fetch all stages leaderboard
      .then(({ data }) => {
        setAllStagsLeaderboard(data.data);
        if (data.data.length > 0) {
          let totalStages = data.data.length;
          let _data = [...data.data];
          for (let index = 0; index < _data.length; index++) {
            const currentStage = _data[index];
            const nextStage = _data[totalStages > index ? index + 1 : null];
            if (nextStage) {
              currentStage.groups.forEach((group, ind) => {
                group.teams.forEach((teamObj, i) => {
                  let finding;
                  nextStage.groups.forEach((group2, ind2) => {
                    finding = group2.teams.find((el, i2) => {
                      const a = el?.team?._id ?? el?.team;
                      const b = teamObj?.team?._id;
                      return a === b;
                    });
                    if (finding) {
                      teamObj.status = nextStage.name;
                    } else {
                      teamObj.status = false;
                    }
                  });
                });
              });
            }
          }
          const lastSt = _data[stageIndex + 1] ? false : true;
          SetLastStage(lastSt);
          setAllStagsLeaderboard(_data);
          setSelectedStage(_data[stageIndex]);
          setLeagueFromGame(
            _data[stageIndex].stageType === "league" ? _data[stageIndex].league.game.shortName : _data[stageIndex].tournament.game.shortName
          );
          const stageNames = _data.map((stage) => stage.name);
          setStageTabs(stageNames);
          setData(_data[stageIndex]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("There was a problem in fetching top players");
      });
  }, []);

  useEffect(() => {
    if (allStagsLeaderboard.length > 0) {
      const lastSt = allStagsLeaderboard[stageIndex + 1] ? false : true;
      SetLastStage(lastSt);
      if (allStagsLeaderboard[stageIndex].stageType === "league") setLoading(true);
      setStageGroupNames([]);
      setSelectedStage(null);
      setFilteredLeaderboard([]);
      setSelectedStage(allStagsLeaderboard[stageIndex]);
      setData(allStagsLeaderboard[stageIndex]);
      setLoading(false);
    }
  }, [stageIndex]);

  const getCalculatedRD = (latestMatches = [], teamId = null) => {
    let sumOfRD = Number(0);
    if (latestMatches.length && teamId !== null) {
      latestMatches.map((lmatch) => {
        lmatch.csgoData &&
          lmatch.csgoData.mapData.map((singMap) => {
            singMap &&
              Object.values(singMap.processedteamData).map((singleTeamMap) => {
                // if(singleTeamMap.roundDiffrence > 0 && singleTeamMap.teamDbId === teamId){ // Uncomment if only calculate positive numbers
                if (singleTeamMap.teamDbId === teamId) {
                  sumOfRD = sumOfRD + singleTeamMap.roundDiffrence;
                }
              });
          });
      });
    }

    if (Number(sumOfRD) > 0) {
      return <span style={{ color: "#22db60" }}>+{sumOfRD}</span>;
    } else if (Number(sumOfRD) < 0) {
      return <span style={{ color: "#fd1049" }}>{sumOfRD}</span>;
    } else {
      return 0;
    }
  };
  const groupLength = selectedStage?.groups.length;

  return loading ? (
    <div className="text-center league-no-matches">
      <Loading width={50} height={50} />
    </div>
  ) : (
    <div className="pb-5">
      <div className="align-items-center" style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
        <div className="justify-content-start" style={{ width: "80%" }}>
          {stageTabs?.length > 1 && <SimpleTabs pages={stageTabs} page={stageIndex} setPage={setStageIndex} />}
        </div>
        <div className="" style={{ position: "absolute", marginLeft: "calc(83% - 29px)" }}>
          <Col span={2} className="col-2" style={{ position: "absolute", marginTop: "-25.5px" }}>
            <MultiStageDropdown
              groups={stageAllGroups}
              onSeletedChange={onSeletedChange}
              isMultiSelect={false}
              setDefault={true}
              reUpdate={true}
            />
          </Col>
        </div>
      </div>
      {
        // isAll &&
        selectedStage?.groups.length > 1 && (
          <div key={selectedGroupIndex} className="align-items-center" style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="par-num align-items-centerd"
              style={{
                borderRadius: 20,
                background: `${selectedStage?.groups[selectedGroupIndex]?.color}22`,
                paddingLeft: 7,
                paddingRight: 7,
                paddingTop: 4,
                paddingBottom: 4,
                justifyContent: "center",
                flexDirection: "row",
                display: "flex",
                marginRight: 10,
                marginTop: 25,
              }}
            >
              <span
                className="uppercase"
                style={{
                  fontSize: 12,
                  padding: 4,
                  fontWeight: "bold",
                  color: selectedStage?.groups[selectedGroupIndex]?.color,
                  paddingLeft: 5,
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  maxWidth: "12ch",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {stageGroupNames[selectedGroupIndex]}
              </span>
            </div>
          </div>
        )
      }

      <h2 className="mt-4 text-16 bold uppercase pt-0 pb-4 m-0">{filteredLeaderboard.length} teams</h2>
      <SortableList
        config={[
          { col: 1, title: "#", prop_name: "number", is_sortable: true, alignment: "left" },
          { col: 2, title: "Team", prop_name: "team", alignment: "left" },
          groupLength === 1 ? {} : { col: `${!lastStage ? 1 : 2}`, title: "Group", prop_name: "group", alignment: "center" },
          !lastStage ? { col: 1, title: "Status", prop_name: "status", alignment: "center" } : {},
          // { col: 1, title: "Status", prop_name: "status", alignment: "center" },
          { col: 1, title: "Score", prop_name: "score", alignment: "right" },
          leagueFromGame && leagueFromGame === "CSGO" && { col: 1, title: "RD", prop_name: "rd", alignment: "right" },
          { col: leagueFromGame && leagueFromGame === "CSGO" ? 1 : 1, title: "Matches", prop_name: "matches", alignment: "right" },
          { col: 1, title: "Wins", prop_name: "wins", alignment: "right" },
          { col: 1, title: "Losses", prop_name: "losses", alignment: "right" },
          { col: 2, title: "Recent Matches", prop_name: "recent_matches", alignment: "right" },
        ]}
      >
        {selectedStage?.groups[selectedGroupIndex]?.teams
          .sort((a, b) => {
            if (selectedStage?.stageType === "league") {
              if (selectedStage?.groups[selectedGroupIndex]?.overviewManualSort) {
                return a.order - b.order;
              } else {
                return b.score - a.score;
              }
            } else {
              return a.score - b.score;
            }
            //  (selectedStage?.league?.overviewManualSort ? a.order - b.order : b.score - a.score) : (b.score - a.score)
          })
          .map(({ _id, team, score, matches, mapsWon, mapsLost, latestMatches, status }, i) => {
            return(
            <SortableLeadBoardListItem
              key={_id}
              number={i + 1}
              team={
                <>
                  <Link to={`/teams/${team?._id ?? team}`} className="text-12 primary-link text-white">
                    <img
                      src={team?.logo ? team?.logo : defaultLogo}
                      alt={team?.name}
                      className={team?.logoCropped || team?.logoCropped === undefined ? "image-40 border-radius-100" : "image-40"}
                      style={{ display: "inline-block", verticalAlign: "middle" }}
                    />
                    <span style={{ display: "inline-block", verticalAlign: "middle" }}>{team?.name ? team?.name : 'TBD'}</span>
                  </Link>
                </>
              }
              group={
                groupLength === 1 ? (
                  ""
                ) : selectedStage.groups.length < 1 ? undefined : (
                  <span
                    key={selectedGroupIndex}
                    className={"pill"}
                    style={{
                      backgroundColor: `${selectedStage.groups[selectedGroupIndex].color}22`,
                      color: selectedStage.groups[selectedGroupIndex].color,
                      textOverflow: "ellipsis",
                      maxWidth: "10ch",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {stageGroupNames[selectedGroupIndex]}
                  </span>
                )
              }
              status={
                status ? (
                  <span className={"pill"} style={{ backgroundColor: `${"#19DB60"}22` }}>
                    <FiArrowUp style={{ marginBottom: "-3.5px", color: "#19DB60", marginRight: 5, marginLeft: -2, fontSize: 15 }} />
                    <span style={{ color: "#19DB60" }}>{status}</span>
                  </span>
                ) : null
              }
              score={score ?? 0}
              lastStage={lastStage ?? 0}
              rd={getCalculatedRD(latestMatches, team?._id) ?? 0}
              leagueFromGame={leagueFromGame ?? 0}
              groupLength={groupLength}
              matches={matches ?? 0}
              wins={mapsWon ?? 0}
              losses={mapsLost ?? 0}
              recent_matches={Array(5)
                .fill()
                .map((_, i) =>
                  latestMatches && latestMatches[i] ? (
                    <Tippy
                      content={`${latestMatches[i]?.t1?.name || "No team name"} ${latestMatches[i]?.t1Score} - ${
                        latestMatches[i]?.t2Score
                      } ${latestMatches[i]?.t2?.name || "No team name"} (${moment(latestMatches[i]?.datetime).fromNow()})`}
                      placement="top"
                      animation="shift-toward"
                    >
                      <Link
                        to={`/match/${latestMatches[i]._id}`}
                        className={`pill small round ${i === 3 ? "size-75" : i === 4 ? "size-50" : ""} ${
                          latestMatches[i]?.win === true
                            ? "success"
                            : latestMatches[i]?.win === false
                            ? "danger"
                            : latestMatches[i]?.draw === true
                            ? "warning"
                            : ""
                        }`}
                      />
                    </Tippy>
                  ) : (
                    <span className={`pill small round ${i === 3 ? "size-75" : i === 4 ? "size-50" : ""}`}></span>
                  )
                )}
              listType="league"
            />
          )})}
      </SortableList>
    </div>
  );
};

export default Leaderboard;
