import React, { useEffect } from 'react';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import GeneralTab from './GeneralTab';
import AppearanceTab from './AppearanceTab';
import Publishing from './Publishing';
import Roles from './Roles';
import Streams from './Streams';
import { SimpleTabs } from '../../../../_common';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCompetition,
  getCompetitionsData,
  getInformationData,
  setIsAnyChangeInTab,
  updateCompetition
} from '../../../../../redux/action/CompetitonData';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { setInformationPreviewData } from '../../../../../redux/action/previewTabsActions';
const InformationTab = ({ socketRef, userPermissions, setName, setLogo, setCover }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = ['General', 'Appearance', 'Publishing', 'Roles', 'Streams'];
  const [allInformationData, setAllInformationData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [unSavedStages, setUnSavedStages] = useState([]);
  const inforamtionPreviewData = useSelector((state) => state.PREVIEW.Information ?? null);
  useEffect(() => {
    // dispatch(getInformationData(id));
    // dispatch(getCompetitionsMatches(id));
    dispatch(getCompetitionsData(id));
  }, [id]);
  const informationData = useSelector((state) => state.COMPETITION.Competition?.multiStageLeague);
  useEffect(()=>{ 
    if(unSavedStages.length > 0){
      dispatch(setIsAnyChangeInTab(true));
    }
    return ()=>{
      dispatch(setIsAnyChangeInTab(false));
    }
  },[unSavedStages])
  useEffect(() => {
    if (informationData) {
      let _informationData = { ...informationData };
      if (inforamtionPreviewData) {
        Object.keys(_informationData).forEach((el) => {
          if (inforamtionPreviewData.hasOwnProperty(el)) {
            _informationData[el] = inforamtionPreviewData[el] ?? _informationData[el];
          }
        });
      }
      const {
        _id,
        logo,
        cover,
        thumbnail,
        visibility,
        publishDate,
        divisionDescription,
        appearLinks,
        streams,
        name,
        game,
        region,
        openFor,
        contact,
        timezone,
        startedDate,
        division,
        competitionType,
        divisionName,
        participantType,
        leagueFinished,
        socketId
      } = _informationData;
      let _data = {
        id: _id,
        logo,
        cover,
        thumbnail,
        visibility,
        publishDate,
        divisionDescription,
        appearLinks,
        streams,
        name,
        game: game._id,
        region,
        openFor,
        contact,
        timezone,
        startedDate,
        division,
        competitionType,
        divisionName,
        participantType,
        leagueFinished,
        socketId: socketRef?.current?.id
      };
      setOriginalData(_data);
      setAllInformationData(_data);
    }
  }, [informationData]);
  useEffect(() => {
    if (socketRef){
      setAllInformationData((state) => ({ ...state, socketId: socketRef?.current?.id }));
    }
    return ()=> {dispatch(setInformationPreviewData(null))}
  }, [socketRef]);
  // useEffect(() => {
  //   // delete allInformationData?.openFor;
  //   // delete originalData?.openFor;
  //   if (allInformationData?.id) {
  //     if (_.isEqual(allInformationData, originalData)) {
  //       dispatch(setIsAnyChangeInTab(false));
  //     } else {
  //       dispatch(setInformationPreviewData(allInformationData));
  //       dispatch(setIsAnyChangeInTab(true));
  //     }
  //   }
  // }, [allInformationData]);
  const hendleChange = () => {
    setUnSavedStages([]);
    dispatch(setIsAnyChangeInTab(false));
    setInformationCall(allInformationData);
  };
  const updatenameAndLogo = (name, logo, cover) => {
    if (id && socketRef?.current?.id && name && logo) {
      return Axios.post(
        `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${id}/rename`,
        {
          competitionId: id,
          name: name,
          logo: logo,
          cover: cover,
          socketId: socketRef?.current?.id
        }
      );
    }
    // if (name?.length == 0) {
    //   setName(saveName);
    // }
  };
  const setInformationCall = (payload) => {
    if (payload?.streams?.length < 1) {
      payload['streams'] = [{}];
    }
    if (payload?.appearLinks?.length < 1) {
      payload['appearLinks'] = [{}];
    }
    if (socketRef.current) {
      updatenameAndLogo(payload?.name, payload?.logo, payload?.cover);
    }
    Axios.post(process.env.REACT_APP_CORE_API + '/api/organizer/competition/update', payload)
      .then((data) => {
        // console.log('data', data);
        if (data.data.code !== 1004) {
          data.data.errors.map((error) => {
            toast.error(`${error.msg} in ${error.param} tab`);
          });
          toast.error(`${data.data.msg}`);
        } else {
          toast.success('Settings saved successfully');
          // setName(payload?.name);
          // setLogo(payload?.logo)
          dispatch(getCompetition(id));
          dispatch(updateCompetition(payload));
          dispatch(setIsAnyChangeInTab(false));
          dispatch(getInformationData(informationData?._id));
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SimpleTabs
          pages={tabs}
          disabledIndexes={userPermissions?.changePermission ? [] : [3]}
          page={selectedTab}
          unSavedStages={unSavedStages}
          setPage={setSelectedTab}
        />
        <button class="button primary" onClick={() => hendleChange()}>
          Save Changes
        </button>
      </div>
      {selectedTab == 0 ? (
        <GeneralTab
          setAllInformationData={setAllInformationData}
          allInformationData={allInformationData}
          setInformationCall={setInformationCall}
          setUnSavedStages={setUnSavedStages}
          selectedTab={selectedTab}
          userPermissions={userPermissions}
          setName={setName}
        />
      ) : selectedTab == 1 ? (
        <AppearanceTab
          socketRef={socketRef}
          setAllInformationData={setAllInformationData}
          allInformationData={allInformationData}
          setInformationCall={setInformationCall}
          setUnSavedStages={setUnSavedStages}
          selectedTab={selectedTab}
          setName={setName}
          setLogo={setLogo}
          setCover={setCover}
        />
      ) : selectedTab == 2 ? (
        <Publishing
          socketRef={socketRef}
          setAllInformationData={setAllInformationData}
          allInformationData={allInformationData}
          setInformationCall={setInformationCall}
          setUnSavedStages={setUnSavedStages}
          selectedTab={selectedTab}
        />
      ) : selectedTab == 3 ? (
        <Roles />
      ) : selectedTab == 4 ? (
        <Streams
          setAllInformationData={setAllInformationData}
          allInformationData={allInformationData}
          setInformationCall={setInformationCall}
          setUnSavedStages={setUnSavedStages}
          selectedTab={selectedTab}
        />
      ) : null}
    </>
  );
};
export default InformationTab;
