import React from "react";
import checkmark from "@img/check-mark.svg";
import checkmarkCSGO from "@img/check-mark-csgo.svg";
import checkmark1 from "@img/check-mark-appointments.svg";
import Tippy from "@tippyjs/react";
import "./_checkbox.scss"
const NewCheckbox = ({ label, checked, onChange, className, customClass, checkBoxClass, icon, tooltipText, game, isAligned }) => {
  return (
    <div className={`checkbox-wrapper-1 noselect row m-0 p-0 align-items-center ${className}`}>
      <div
        onClick={onChange}
        className={`${checkBoxClass ? checkBoxClass : game === "CS:GO" ? "checkbox-container-1 checkbox-csgo-1" : "checkbox-container-1"}`}
      >
        {checked ? (
          <img className="check-mark-1 fade" src={game === "CS:GO" ? checkmarkCSGO : checkBoxClass ? checkmark1 : checkmark} />
        ) : null}
      </div>
      <p className={`check-label-1 ${customClass}`}>{label}</p>
      {icon && (
        <Tippy content={tooltipText} placement="right" animation="shift-toward">
          <div className="icon">{icon}</div>
        </Tippy>
      )}
    </div>
  );
};

export default NewCheckbox;
