import { setSeedingData, setSeedingDataAll } from '../../../redux/action/seedingActions';
import store from '../../../redux/store/store';

export const getSortedObjByName = (obj, type, sort) => {
  let _folders = obj.folders ?? [];
  let _competitions = obj.competitions ?? [];
  if (!sort) {
    if (_folders.length > 0) {
      _folders.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (_competitions.length > 0) {
      _competitions.sort((a, b) => a.name.localeCompare(b.name));
    }
  } else {
    if (_folders.length > 0) {
      _folders.sort((a, b) => b.name.localeCompare(a.name));
    }
    if (_competitions.length > 0) {
      _competitions.sort((a, b) => b.name.localeCompare(a.name));
    }
  }
  obj.folders = _folders;
  obj.competitions = _competitions;
  return obj;
};

export const getSortByLastModified = (obj, type, sort) => {
  let _folders = obj.folders ?? [];
  let _competitions = obj.competitions ?? [];
  // debugger
  if (!sort) {
    if (_folders.length > 0) {
      _folders.sort(
        (a, b) => Number(new Date(a.lastEditedDate)) - Number(new Date(b.lastEditedDate))
      );
    }
    if (_competitions.length > 0) {
      _competitions.sort(
        (a, b) => Number(new Date(a.lastEditedDate)) - Number(new Date(b.lastEditedDate))
      );
    }
  } else {
    if (_folders.length > 0) {
      _folders.sort(
        (a, b) => Number(new Date(b.lastEditedDate)) - Number(new Date(a.lastEditedDate))
      );
    }
    if (_competitions.length > 0) {
      _competitions.sort(
        (a, b) => Number(new Date(b.lastEditedDate)) - Number(new Date(a.lastEditedDate))
      );
    }
  }
  obj.folders = _folders;
  obj.competitions = _competitions;
  return obj;
};

/*****************
 * Seeding Utils *
 *****************/

export const fetchSeedingDetailsAutoSaveOnChange = (stageId, autoFillTeams, seedings, _id) => {
  // let _id = localStorage.getItem("_id");
  let _stageId = stageId;

  let _seedingStages = store.getState().COMPETITION.SeedingTab.seedingStages;
  let _active_stage = _seedingStages.filter((x) => x._id === _stageId);

  let _data = [];

  _active_stage.forEach((element) => {
    let { groups } = element;
    groups.forEach((group, index) => {
      let _obj = seedings.filter((x) => x.group && x.group._id === group._id && x.team !== 'TBD');
      let _group = {
        _id: group._id
      };
      let teams = [];
      _obj.map((item, index) => {
        // if (item.isComplete) {
        teams.push({
          team: item?.team?.team?._id,
          seedPosition: item.seedPosition
        });
        // }
        _group['teams'] = teams;
      });
      _data.push(_group);
    });
  });

  setSeedingData(_id, _stageId, _data, autoFillTeams);
};

export const fetchSeedingDetailsWithSave = (stageId, autoFillTeams, _id) => {
  // let _id = localStorage.getItem("_id");
  let _stageId = stageId;
  let _seedingStages = store.getState().COMPETITION.SeedingTab.seedingStages;
  let _activeStageIndex = _seedingStages.findIndex((x) => x._id === _stageId);
  let _active_stage = [];
  if (_activeStageIndex > -1) {
    _active_stage = [_seedingStages[_activeStageIndex]];
  }
  let _data = [];

  _active_stage.forEach((element) => {
    let { groups, seedings } = element;
    groups.forEach((group, index) => {
      let _obj = seedings.filter((x) => x.group && x.group._id === group._id && x.team !== 'TBD');
      let _group = {
        _id: group._id
      };
      let teams = [];
      _obj.map((item, index) => {
        teams.push({
          team: item?.team?.team?._id,
          seedPosition: item.seedPosition
        });
        _group['teams'] = teams;
      });
      _data.push(_group);
    });
  });

  setSeedingData(_id, _stageId, _data, autoFillTeams, _activeStageIndex);
};


export const saveSeedingDetailsForAll = (_id) => {
  // let _id = localStorage.getItem("_id");
  let _seedingStages = store.getState().COMPETITION.SeedingTab.seedingStages;

  _seedingStages.forEach((_active_stage, _idx) => {
    let _stageId = _active_stage._id;
    let autoFillTeams = _active_stage.autoFillTeams;
    let _data = [];
    let { groups, seedings } = _active_stage;
    groups.forEach((group, index) => {
      let _obj = seedings.filter((x) => x.group && x.group._id === group._id && x.team !== "TBD");
      let _group = {
        _id: group._id,
      };
      let teams = [];
      _obj.map((item, index) => {
        teams.push({
          team: item?.team?.team?._id,
          seedPosition: item.seedPosition,
        });
        _group["teams"] = teams;
      });
      _data.push(_group);
    });

    setSeedingDataAll(_id, _stageId, _data, autoFillTeams, true, _seedingStages.length === _idx + 1);
  });
};


//////// Permissions Utility Methods //////////////////////////////////


export const checkSetScoreDisabled = (permissions) => {
  if (Object.keys(permissions).length) {
    if (permissions.setMatchScore) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};
export const checkAddTeamDisabled = (permissions) => {
  if (Object.keys(permissions).length) {
    if (permissions.administrateCompetitions || permissions.manageParticipants) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};
export const checkEditMatchDisabled = (permissions)=>{
  if (Object.keys(permissions).length) {
    if (permissions.editMatches) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}
export const checkSetPlayersDisabled = (permissions)=>{
  if (Object.keys(permissions).length) {
    if (permissions.editMatches) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}
