import React from 'react';
import { SimpleTabs } from '../../_common';
import GroupTabs from './GroupTabs';
import { useState } from 'react';
import { useEffect } from 'react';
import { Group } from 'antd/lib/avatar';
import GroupBy from './GroupbyDropdown/GroupBy';
import { HiChevronDown, HiPlusSm } from 'react-icons/hi';
import { BsPlus } from 'react-icons/bs';
import { GoSettings } from 'react-icons/go';
import MatchesTab from '../MyCompetitions/ConpetitionTabs/MatchesTab/MatchesTab';
import GeneratMatchButton from './generateMatchModal.js/GenerateMatchButton';
import { useSelector } from 'react-redux';
import GenerateMatchesModal from '../../_modals/GenerateMatchesModal.jsx/GenerateMatchesModal';
import CreateMatchModal from './Creatematches/CreateMatchModal/CreateMatchModal';
import CreateMathesPopup from './Creatematches/CreateMatches';
import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setStageIndexGlobally } from '../../../redux/action/CompetitonData';
import useDidMountEffect from '../../../helpers/useDidMountEffect';
import FilterMatchesPopup from '../../_modals/FilterMatchesPopup/FilterMatchesPopup';
import { checkEditMatchDisabled } from '../MyCompetitions/utils';
import Tippy from '@tippyjs/react';
const StageTabs = ({ setParentStagesNames, setParentCurrentStageIndex, userPermissions }) => {
  const [stageIndex, setStageIndex] = useState(0);
  const [stagesName, setStagesName] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [groupIndex, setGroupIndex] = useState(0);
  const dispatch = useDispatch()
  const [createMatch, setCreateMatch] = useState(false);
  const [filterMatches,setFilterMatches]=useState(false)
  const [open, setOpen] = useState(false);
  const competitiondetail = useSelector((state) => state.COMPETITION?.Matches?.data?.data);
  const competitionData = useSelector((state) =>
    state.COMPETITION.Competition.multiStageLeague
      ? state.COMPETITION.Competition.multiStageLeague
      : false
  );
  const stageIndexGlobally = useSelector((state) => state.COMPETITION?.StageIndex ? state.COMPETITION.StageIndex : 0)
  useEffect(()=>{
    if(stageIndexGlobally !== stageIndex){
      if(!competitionData.stages[stageIndexGlobally]){
        setStageIndex(stageIndexGlobally - 1)
      }else{
        setStageIndex(stageIndexGlobally)
      }
    }
  },[stageIndexGlobally])
  useDidMountEffect(()=>{ // This hook is not called on initial render like useEffect
    //////////////////////////////// FOR SETTING STAGE INDEX GLOBALLY////////////////////////////////
    if(stageIndex !== 0){
      if(stageIndexGlobally !== stageIndex){
        dispatch(setStageIndexGlobally(stageIndex))
      }
    }
  },[stageIndex])
  // useEffect(()=>{
  //   console.log('Stage=>',competitionData);
  // },[competitionData]);
  useEffect(() => {
    if (competitiondetail?.length > 0) {
      let _names = competitiondetail?.map((competition) =>
        competition[0]?.stageDetails?.name ? competition[0]?.stageDetails?.name : 'All'
      );
      setStagesName(_names);
      setParentStagesNames(_names);
    }
  }, [competitiondetail]);
  useEffect(() => {
    if (stageIndex > 0) {
      if(competitiondetail[stageIndex]){
        setGroupsName([
          'All',
          ...competitiondetail[stageIndex][0]?.stageDetails?.groups?.map((groups) => groups.name)
        ]);
      }
    } else {
      setGroupsName([]);
    }
    setGroupIndex(0);
  }, [stageIndex]);
  useEffect(() => {
    // console.log('groupIndex', groupIndex);
  }, [groupIndex]);
  const handleSetIndex = (index) => {
    setStageIndex(index);
    setParentCurrentStageIndex(index);
  };

  return (
    <>
      <div className="subTabs" style={{ justifyContent: 'space-between' }}>
        <div className="subTabs">
          {stagesName?.length > 1 ? (
            <SimpleTabs pages={stagesName} page={stageIndex} setPage={handleSetIndex} />
          ) : null}
          <div className="vl"></div>
          {groupsName.length > 2 && groupsName.length < 5 ? (
            <GroupTabs
              groupsName={groupsName}
              groupIndex={groupIndex}
              setGroupIndex={setGroupIndex}
            />
          ) : groupsName.length >= 5 ? (
            <div className="form-row" style={{ width: '150px' }}>
              <select
                id="region"
                name="region"
                className={`form-input `}
                value={groupIndex}
                onChange={(e) => {
                  setGroupIndex(parseInt(e.target.value));
                }}>
                {groupsName.map((group, key) => (
                  <option value={key} selected={key == groupIndex}>
                    {group}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </div>
        <div className="subTabs">
          <GroupBy />
          <button className="shareButton perviewButton" onClick={()=>setFilterMatches(true)}>
            <GoSettings /> FILTER
          </button>
          <button
            style={{ marginRight: '5px' }}
            onClick={() => setOpen(true)}
            disabled={checkEditMatchDisabled(userPermissions)}
            className="button primary">
              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <BsPlus style ={{height:'1.7em',width:'1.7em'}}/> 
                <span>
                  GENERATE MATCHES
                </span>
            </div>
          </button>
          {stageIndex > 0 && competitionData?.stages[stageIndex-1]?.stageType!="tournament" ? (
            <button
              className="button primary"
              onClick={() => setCreateMatch(!createMatch)}
              disabled={checkEditMatchDisabled(userPermissions) || stageIndex === 0}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <BsPlus style={{ height: '1.7em', width: '1.7em' }} />
                <span>CREATE MATCH</span>
              </div>
            </button>
          ) : (
            <Tippy
              content="Single matches cannot be created for a bracket."
              placement={'top'}>
              <button
                className="button disabled-opo"
                // onClick={() => setCreateMatch(!createMatch)}
                disabled={checkEditMatchDisabled(userPermissions)}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <BsPlus style={{ height: '1.7em', width: '1.7em' }} />
                  <span>CREATE MATCH</span>
                </div>
              </button>
            </Tippy>
          )}
        </div>
      </div>
      <MatchesTab
        competitionData={competitionData}
        userPermissions={userPermissions}
        stageIndex={stageIndex}
        groupIndex={groupIndex}
        filterMatches={filterMatches}
        setFilterMatches={setFilterMatches}
      />

      <CreateMathesPopup
        open={createMatch}
        groupIndex={groupIndex}
        stageIndex={stageIndex}
        onClose={setCreateMatch}
      />

      {open && (
        <GenerateMatchesModal
          stageIndex={stageIndex}
          groupIndex={groupIndex}
          open={open}
          competition={competitionData}
          onClose={setOpen}
        />
      )}
    </>
  );
};
export default StageTabs;
