import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { HiFolder, HiDotsHorizontal } from 'react-icons/hi';
import { Button, Space, Table } from 'antd';
import { TiArrowUnsorted } from 'react-icons/ti';
import { Col, Row } from 'react-bootstrap';
import ShareOptions from '../../../_common/ShareOptions/ShareOptions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Loading } from '../../../_common';
import { setContextObj } from '../../../../redux/action/contextMenuAction';
import { usePermissionsOrg } from '../../../../utils/usePermissions';
import CreateOrganizer from './CreateOrganizer';
import Image from 'react-bootstrap/Image';
import { AppContext } from '../../../../context/AppContext';
import {
  checkDeleteOrgPermission,
  getOrganiserPermissions,
  getOwnerOrganizer
} from '../../../../helpers/permissionsHelper';
import useContextMenu from '../../../_common/ContextMenu/hooks/useContextMenu';
import ThreeDots from '../../../_common/ThreeDots/ThreeDots';
import ContextMenu from '../../../_common/ContextMenu/ContextMenu';
import Options from '../../../_common/ThreeDots/Options/Options';
import DeleteItems from '../../../_modals/OrganizerPopup/deleteitems/delete';
import useCtrlDelete from '../../../_common/ContextMenu/hooks/useCtrlDelete';

function OrganizerListView({ alldata, getTrans, onSort, handleRouting, showCreate = false, user }) {
  const timeZone = useSelector((state) => state.TIMEZONE);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const loading = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const [showDelete, setShowDelete] = useState(false);

  const onHoverHandler = (id, type) => {
    switch (type) {
      case 'IN':
        return setHoveredId(id);
      case 'OUT':
        return setHoveredId(null);
      default:
        return setHoveredId(null);
    }
  };
  const [defaultSort, setDefaultSort] = useState({
    NAME: true,
    TOURNAMENT_DATE: true,
    MATCHES: true,
    ORG: true,
    FOLDER: true,
    ORGANIZER: true,
    LAST_MODIFIED: true,
    CREATE_MODIFIED: true
  });
  const myCompetitionItemHandler = (id) => {
    // if(selectedOrg === i ) return setSelectedOrg(null)
    setSelectedOrg(id);
    // setSelectedBoolean(!selectedBoolean);
  };
  const getLastEdited = ({ lastEditedDate, lastEditor }) => {
    let _lastEdited = moment(lastEditedDate).format('DD/MM-YYYY');
    let editorName = lastEditor?.firstName + ' ' + lastEditor?.lastName;
    return `${_lastEdited} - ${editorName}`;
  };

  useCtrlDelete(() => {
    let _organizer = alldata.find((org) => org._id === selectedOrg);
    if (!_organizer) return;
    let perm = checkDeleteOrgPermission(user, _organizer?.organizerRoles);
    if (perm) {
      setShowDelete(true);
    }
  });
  return loading ? (
    <div className="text-center league-no-matches">
      <Loading width={50} height={50} />
    </div>
  ) : (
    <div className="row m-0 grid-tab-content">
      <div style={{ marginLeft: 'auto', marginRight: '-17px', marginTop: '-135px' }}>
        <CreateOrganizer showCreate={showCreate} />
      </div>

      <>
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid  #1d1f26' }}>
              <th scope="col" width={'45%'}>
                <div className="for-arrow" style={{ display: 'flex', alignItems: 'center' }}>
                  <div>NAME</div>
                  <div>
                    <TiArrowUnsorted
                      style={{
                        width: '20px',
                        marginTop: '2px',
                        cursor: 'pointer',
                        paddingTop: '1px'
                      }}
                      onClick={(e) => {
                        onSort(alldata, 'NAME', defaultSort.NAME);
                        setDefaultSort((state) => ({ ...state, NAME: !defaultSort.NAME }));
                      }}
                    />
                  </div>
                </div>
              </th>
              <th scope="col" width={'35%'}>
                <div className="for-arrow" style={{ display: 'flex', alignItems: 'center' }}>
                  <div>OWNER</div>
                  <div>
                    <TiArrowUnsorted
                      style={{
                        width: '20px',
                        marginTop: '2px',
                        cursor: 'pointer',
                        paddingTop: '1px'
                      }}
                    />
                  </div>
                </div>
              </th>
              <th scope="col" width={'15%'}>
                <div className="for-arrow" style={{ display: 'flex', alignItems: 'center' }}>
                  <div>LAST MODIFIED</div>
                  <div>
                    <TiArrowUnsorted
                      onClick={(e) => {
                        onSort(alldata, 'LAST_MODIFIED', defaultSort.LAST_MODIFIED);
                        setDefaultSort((state) => ({
                          ...state,
                          LAST_MODIFIED: !defaultSort.LAST_MODIFIED
                        }));
                      }}
                      style={{
                        width: '20px',
                        cursor: 'pointer',
                        marginTop: '2px',
                        paddingTop: '1px'
                      }}
                    />
                  </div>
                </div>
              </th>
              <th scope="col" width={'5%'} />
            </tr>
          </thead>
          <tbody>
            {alldata?.map((organizer, ind) => {
              let _disabled = (organizer?._id === selectedOrg) ? true : (organizer?._id === hoveredId) ? true : false
              return (
                <>
                  <OrganizerRow
                    //New Props
                    _disabled={!_disabled}
                    onHoverHandler={onHoverHandler}
                    organiser={organizer}
                    permissions={organizer?.organizerRoles ?? []}
                    // thumbnail={organizer?.thumbnail}
                    logo={organizer?.logo}
                    last_edited={getLastEdited(organizer)}
                    ind={ind}
                    title={organizer?.name}
                    onClickHandler={myCompetitionItemHandler}
                    onDoubleClickHandler={(e) => handleRouting(organizer?._id)}
                    type="ORGANIZER"
                    //End
                    // selectedFolder={selectedFolder}
                    // setSelectedFolder={setSelectedFolder}
                    // index={ind}
                    // folder={item}
                    // onDoubleClick={(e) => onRoute(item._id)}
                    // showContextMenu={showContextMenu}
                    // getDate={getDate}
                    // getTrans={getTrans}
                  />
                </>
              );
            })}
          </tbody>
        </table>
        {showDelete && (
          <DeleteItems
            open={showDelete}
            setDelete={(e) => setShowDelete(!showDelete)}
            type={'ORGANIZER'}
            folder={alldata.find((org) => org._id === selectedOrg)}
          />
        )}
      </>
    </div>
  );
}

const OrganizerRow = ({
  onHoverHandler= ()=>{},
  _disabled,
  organiser,
  permissions,
  logo,
  last_edited,
  ind,
  title,
  onClickHandler,
  onDoubleClickHandler,
  type
}) => {
  const { user } = useContext(AppContext);
  const { userPermissions, showDots } = useMemo(
    () => getOrganiserPermissions(user, permissions),
    [permissions]
  );
  const owner = useMemo(() => getOwnerOrganizer(organiser?.organizerRoles), [organiser]);
  const { isVisible, x, y, showMenu, hideMenu } = useContextMenu();
  const handleContextMenu = (event) => {
    event.preventDefault();
    onClickHandler(organiser?._id);
    showMenu(event);
  };

  return (
    <tr
      key={organiser._id ?? ind}
      onMouseEnter={()=>onHoverHandler(organiser?._id,'IN')}
      onMouseLeave={()=>onHoverHandler(organiser?._id,'OUT')}
      onContextMenu={handleContextMenu}
      onClick={(e) => {
        onClickHandler(organiser?._id);
      }}
      onDoubleClick={onDoubleClickHandler}
      style={{
        backgroundColor: _disabled ? 'transparent' : '#050c16',
        borderBottom: '1px solid  #1d1f26'
      }}
      className="hoverable-row">
      <td>
        <Col className="name-row text" style={{ marginLeft: 16 }}>
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span className="imgLogoContainer" style={{ color: _disabled ? 'black' : '#8fa3b1' }}>
                {/* add folder instead of image */}
                {logo ? (
                  <img
                    className="imgLogoWidth imgLogoCropped"
                    src={logo}
                    alt="Insight Gaming"
                    width={'32px'}
                    height={'32px'}
                  />
                ) : (
                  <HiFolder size={32} color={'#FFF'} />
                )}
              </span>
              <span
                // className="text"
                style={{
                  // maxWidth: '250px',
                  // textOverflow: 'ellipsis',
                  marginLeft: '10px',
                  height: '18px',
                  // width: '235px',
                  padding: '0px',
                  // overflow: 'hidden',
                  position: 'relative',
                  display: 'inline-block',
                  textAlign: 'left',
                  textDecoration: 'none',
                  // textOverflow: 'ellipsis',
                  // whiteSpace: 'nowrap',
                  color: _disabled ? '#FFF' : '#3eabf0'
                }}>
                {title ?? 'SteelSeries'}
              </span>
            </div>
          </Row>
        </Col>
      </td>

      <td>
        <Col
          className="me-organizer"
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
            // width: '30px',
            // color: _disabled ? '' : '#296f9e'
            color: _disabled ? '' : '#3eabf0'
          }}>
          <div style={{ width: '25px', height: '22px', display: 'inline-flex' }}>
            <img
              src={owner && owner?.avatarImage}
              style={{ borderRadius: 15 }}
              alt={owner && owner?.firstName ? owner?.firstName + ' ' + owner?.lastName : 'No name'}
            />
          </div>
          {owner && owner._id === user?.state?._id ? (
            <span style={{ textTransform: 'capitalize', marginLeft: '5px' }}>Me</span>
          ) : (
            owner?.firstName + ' ' + owner?.lastName
          )}
        </Col>
      </td>
      <td>
        <Col className="last-modified" style={{ color: _disabled ? '' : '#296f9e' }}>
          {last_edited}
        </Col>{' '}
      </td>
      <td>
        <Col className="options-dots">
          {showDots && (
            <ThreeDots
              userPermissions={userPermissions}
              user={user}
              type={type}
              disabled={_disabled}
              organiser={organiser}
              onFirstClick={() => onClickHandler(ind)}
            />
          )}
          {/* <ShareOptions
            style={{
              marginLeft: 0,
              color: sortedLastIndex === selectedFolder && '#3eabf0',
              background: '#091625'
            }}
            getTrans={getTrans}
            item={folder}
            type={'ORGANIZER'}
            permissions={orgPerm}
          /> */}
          {showDots && isVisible && !_disabled && (
            <ContextMenu x={x} y={y} onClose={hideMenu} isVisible={isVisible}>
              <Options type={type} userPermissions={userPermissions} organiser={organiser} />
            </ContextMenu>
          )}
        </Col>
      </td>
    </tr>
  );
};
export default OrganizerListView;
