import { useEffect, useRef } from 'react';

const useHandleClickOutside = (ref, onClickOutside) => {
  const handleClick = (event) => {
    const modalElem = document?.querySelector('.ReactModalPortal');
    if (ref.current && !ref.current.contains(event.target)) {
      if (modalElem && modalElem.contains(event.target)) {
        return;
      }
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};

export default useHandleClickOutside;
