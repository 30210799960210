const checkAdminRoles = (user) => {
  let superAdmin = user?.state?.admin ?? false;
  let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
  if (!superAdmin && !orgAdmin) return false;
  return {
    name: 'Owner',
    permission: {
      administrateCompetitions: true,
      changePermission: true,
      createAndDeleteCompetitions: true,
      editFormat: true,
      editMatches: true,
      manageFolderStructure: true,
      manageParticipants: true,
      setMatchScore: true
    }
  };
};
export const canCreateFolder = (user, permissions = []) => {
  let superAdmin = user?.state?.admin ?? false;
  let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
  if (superAdmin || orgAdmin) return true;
  const _permissions = mergeSamePermissions(permissions);
  if (_permissions) {
    let userPermission =
      _permissions.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ??
      null;
    if (userPermission) {
      let perm = userPermission.permission;
      return perm?.manageFolderStructure ?? false;
    }
  }
  return false;
};
export const mergeSamePermissions = (arr) => {
  let result = [];
  arr.forEach((elem) => {
    let found = result.findIndex((el) => el?.user?._id === elem?.user?._id);
    if (found >= 0) {
      let newObj = {
        ...elem,
        userPermission: {
          ...elem.userPermission,
          permission: {
            ...elem.userPermission.permission,
            administrateCompetitions:
              elem?.userPermission?.permission?.administrateCompetitions ||
              result[found]?.userPermission?.permission?.administrateCompetitions,
            changePermission:
              elem?.userPermission?.permission?.changePermission ||
              result[found]?.userPermission?.permission?.changePermission,
            createAndDeleteCompetitions:
              elem?.userPermission?.permission?.createAndDeleteCompetitions ||
              result[found]?.userPermission?.permission?.createAndDeleteCompetitions,
            editFormat:
              elem?.userPermission?.permission?.editFormat ||
              result[found]?.userPermission?.permission?.editFormat,
            editMatches:
              elem?.userPermission?.permission?.editMatches ||
              result[found]?.userPermission?.permission?.editMatches,
            manageFolderStructure:
              elem?.userPermission?.permission?.manageFolderStructure ||
              result[found]?.userPermission?.permission?.manageFolderStructure,
            manageParticipants:
              elem?.userPermission?.permission?.manageParticipants ||
              result[found]?.userPermission?.permission?.manageParticipants,
            setMatchScore:
              elem?.userPermission?.permission?.setMatchScore ||
              result[found]?.userPermission?.permission?.setMatchScore
          }
        }
      };
      result[found] = newObj;
    } else {
      result.push(elem);
    }
  });
  return result;
};
export const getOptionsAvailable = (userPerms, user) => {
  let newPerms = { ...userPerms };
  const _admin = checkAdminRoles(user);
  if (_admin) {
    newPerms = _admin;
  }
  if (!newPerms) return false;
  let isOwner = newPerms && newPerms.name === 'Owner' ? true : false;
  let _permObj = newPerms?.permission;

  let _edit = _permObj?.manageFolderStructure ?? false;
  let _share = _permObj?.changePermission ?? false;
  let _delete = _permObj?.manageFolderStructure ?? false;
  let _rename = _permObj?.manageFolderStructure ?? false;
  let deleteOrg = isOwner ?? false;
  let restoreOrg = isOwner ?? false;
  let permanentlyDeleteOrg = isOwner ?? false;
  let restoreFolder = _permObj?.manageFolderStructure ?? false;
  let permanentlyDeleteFolder = _permObj?.manageFolderStructure ?? false;
  let restoreCompetition = _permObj?.createAndDeleteCompetitions ?? false;
  let permanentlyDeleteComp = _permObj?.createAndDeleteCompetitions ?? false;
  return {
    _edit,
    _share,
    _delete,
    _rename,
    deleteOrg,
    restoreOrg,
    permanentlyDeleteOrg,
    restoreFolder,
    permanentlyDeleteFolder,
    restoreCompetition,
    permanentlyDeleteComp
  };
};

//For Getting Show Dots user in getOrganiserPermissions
const getShowDots = (userPerms) => {
  if (!userPerms) return false;
  let isOwner = userPerms && userPerms.name === 'Owner' ? true : false;
  let _permObj = userPerms?.permission;

  let editOrg = _permObj?.manageFolderStructure ?? false;
  let shareOrg = _permObj?.changePermission ?? false;
  return isOwner || editOrg || shareOrg;
};
export const getOrganiserPermissions = (user, permissions) => {
  let _admin = checkAdminRoles(user);
  if (_admin) return { userPermissions: _admin, showDots: true };
  let userPermission =
    permissions.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ?? null;
  if (userPermission) {
    let _showDots = getShowDots(userPermission);
    return { userPermissions: userPermission, showDots: _showDots };
  } else {
    return { userPermissions: userPermission, showDots: false };
  }
};
export const getFolderOrCompetitonPermissions = (user, permissions = []) => {
  let _admin = checkAdminRoles(user);
  if (_admin) return { userPermissions: _admin, showDots: true };
  const mergedPermissions = mergeSamePermissions(permissions); //For Merging inherited permissions
  let userPermission =
    mergedPermissions.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ??
    null;
  if (userPermission) {
    let _showDots = getShowDots(userPermission);
    return { userPermissions: userPermission, showDots: _showDots };
  } else {
    return { userPermissions: userPermission, showDots: false };
  }
};

//For getting owner of the Organizer (this is used in organizer list view)
export const getOwnerOrganizer = (organizerRoles) => {
  if (organizerRoles.length > 0) {
    const owner = organizerRoles.find((role) => role.userPermission.name === 'Owner');
    if (owner) {
      return owner.user;
    }
  }
};

export const checkDeletePermission = (user, permissions = []) => {
  // For checking permissions to delete a specific folder/competition
  let _admin = checkAdminRoles(user);
  if (_admin) return true;
  const mergedPermissions = mergeSamePermissions(permissions);
  let userPermission =
    mergedPermissions.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ??
    null;
  if (userPermission) {
    // debugger
    return userPermission?.permission?.manageFolderStructure ?? false;
  }
  return false;
};
export const checkDeletePermForMultipleItems = (user, inheritedPerms, itemsObjArr) => {
  // For checking permissions to delete on multiple items and return will be boolean
  if (itemsObjArr.length) {
    return itemsObjArr.every((el) =>
      checkDeletePermission(user, [...inheritedPerms, ...el.organizerRoles])
    );
  }
  return false;
};

export const checkDeleteOrgPermission = (user, permissions = []) => {
  let _admin = checkAdminRoles(user);
  if (_admin) return true;
  let userPermission =
    permissions.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ?? null;
  if (userPermission) {
    // debugger
    return userPermission?.permission?.manageFolderStructure ?? false;
  }
  return false;
};

const checkOrgOwner = (user, organizerRoles = []) => {
  // used in checkTrashDeletePermmission
  let userPermission =
    organizerRoles.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ??
    null;
  if (userPermission) {
    return userPermission?.name === 'Owner';
  }
  return false;
};
const checkCompetitionForeverDeletePerm = (user, organizerRoles = []) => {
  // used in checkTrashDeletePermmission
  let userPermission =
    organizerRoles.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ??
    null;
  if (userPermission) {
    return userPermission?.permission?.createAndDeleteCompetitions ?? false;
  }
  return false;
};
const checkFolderForeverDeletePerm = (user, organizerRoles = []) => {
  // used in checkTrashDeletePermissions
  let userPermission =
    organizerRoles.find((permObj) => permObj?.user?._id === user?.state?._id)?.userPermission ??
    null;
  if (userPermission) {
    return userPermission?.permission?.manageFolderStructure ?? false;
  }
  return false;
};
export const checkTrashDeletePermission = (user, itemsObj) => {
  let _admin = checkAdminRoles(user);
  if (_admin) return true;
  const { folders, competitions, organizers } = itemsObj;
  if ([...folders, ...competitions, ...organizers].length === 0) return false; //if nothing is selected return false because .every will return true on empty array
  // debugger
  const orgPerm = organizers.every((org) => checkOrgOwner(user, org?.organizerRoles));
  const compPerm = competitions.every((competition) =>
    checkCompetitionForeverDeletePerm(user, competition?.organizerRoles)
  );
  const folderPerm = folders.every((folder) =>
    checkFolderForeverDeletePerm(user, folder?.organizerRoles)
  );

  return orgPerm && compPerm && folderPerm;
};
