import React from 'react';

import moment from 'moment';

import useTranslated from '../../../helpers/translationHelper';
import default_organiser_img from '@img/default_organiser_img.jpg';

import './_organiser_item.scss';
import { Link } from 'react-router-dom';
const OrganiserItem = ({
  thumbnail,
  logo,
  isOrganizer = false,
  organizerId,
  organizerData,
  isTrash,
  type,
  isFolder = false,
  hideOption = true,
  selected = 0,
  ind = 0,
  compId,
  isLastEdit,
  isRecent,
  competitionData,
  isCompetition,
  compPermissions,
  isShowTrashfolders,
  trashDate,
  trashSelected,
  disabled,
  lastEdited,
  // isRecentGrid,
  ...props
}) => {
  function daysRemaining(lastEdited) {
    const dateToCheck = moment(lastEdited);
    const duration = moment.duration(moment().diff(dateToCheck));
    const days = duration.days();
    const daysRemaining = 30 - days;
    return daysRemaining;
  }

  let classes = 'item';
  let imageContainerClass = '';

  if (typeof props.imageContainerClass !== 'undefined') {
    imageContainerClass = props.imageContainerClass;
  }

  if (typeof props.large !== 'undefined') {
    classes += ' large';
  }

  if (typeof props.column !== 'undefined') {
    classes += ' col-' + props.column;
  }

  if (typeof props.classes !== 'undefined') {
    classes += ' ' + props.classes;
  }

  if (typeof props.loading !== 'undefined') {
    classes += ' loading';
  }
  const excerpt = props.excerpt;

  const timeAgo = moment(props.published ?? new Date()).fromNow();
  const link_to = props.link_to ?? '#';
  const hideTimeAgo = props.hide_time_ago ?? true;
  const above_excerpt = props.above_excerpt;
  const tag = props.tag;
  const bgSize = thumbnail ? 'cover' : logo ? '35%' : '100%';
  const date = props.date;

  return (
    <div className="organiser-item-main-div">
      <Link to={link_to} style={{ textDecoration: 'none' }}>
      {/* <GlitchClip disabled={glitchDisabled} duration="4s" onHover={glitchDisabled ? false : true}> */}
      <div
        className={'border-clip-for-color'}
        style={disabled ? { background: 'transparent' } : {}}>
        <div
          className={`item-image image-container-OI ${imageContainerClass}`}
          style={{
            backgroundImage: `url(${thumbnail ? thumbnail : logo ? logo : default_organiser_img})`,
            backgroundSize: bgSize ? bgSize : '100%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#0f1116'
          }}>
          {isShowTrashfolders ? (
            <span
              style={{
                color: '#f11857',
                background: '#1b0815',
                font: 'normal normal bold 8px/10px Montserrat',
                float: 'right',
                padding: '5px',
                borderRadius: '5px',
                margin: '15px',
                width: '50px',
                textAlign: 'center'
              }}>
              {daysRemaining(lastEdited)} {`${daysRemaining(lastEdited) === 1 ? 'Day' : 'Days'}`}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
      </Link>

      {/* <Link to={link_to} style={{ textDecoration: 'none' }}> */}
      {!hideTimeAgo ? <ItemTimeAgo time_ago={timeAgo} /> : null}
      <AboveExcerpt content={above_excerpt} />
      <div className="item-excerpt">{excerpt}</div>
      {date ? <div>{date}</div> : null}
      {/* </Link> */}
    </div>
  );
};
export default OrganiserItem;

const AboveExcerpt = ({ content }) => {
  if (typeof content === 'undefined') {
    return null;
  }

  return <div className="item-above-excerpt">{content}</div>;
};
const TeamsGameIconInner = ({ imgContent }) => {
  if (typeof imgContent === 'undefined' || imgContent === null) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="game-image-cover">
        <img src={imgContent} className="players-game-icon" alt={`Game`} />
      </div>
    </React.Fragment>
  );
};

const ItemTimeAgo = ({ time_ago }) => {
  const month = useTranslated('id_news_page_10');
  const fewSeconds = useTranslated('id_news_page_11');
  const years = useTranslated('id_news_page_12');
  const ago = useTranslated('id_news_page_13');

  let timereplace = '';
  if (time_ago.includes('months') && month) {
    timereplace = time_ago.replace('months', month);
  } else if (time_ago.includes('few seconds') && fewSeconds) {
    timereplace = time_ago.replace('few seconds', fewSeconds);
  } else if (time_ago.includes('year') && years) {
    timereplace = time_ago.replace('year', years);
  } else {
    timereplace = time_ago;
  }
  if (time_ago.includes('ago') && ago) {
    timereplace = timereplace.replace('ago', ago);
  }
  return (
    <div className="item-info">
      <span>{timereplace}</span>
    </div>
  );
};

const ItemImageInner = ({ tag }) => {
  if (typeof tag === 'undefined' || tag === null) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="image-cover"></div>
      <span>{tag}</span>
    </React.Fragment>
  );
};
