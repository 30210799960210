import Axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../../context/AppContext';
import { getOptionsAvailable } from '../../../../helpers/permissionsHelper';
import { getTrash } from '../../../../redux/action/trashActions';
import CreateCompetitionModal from '../../../_modals/CreateCompetitionModal/CreateCompetitionModal';
import DeleteItems from '../../../_modals/OrganizerPopup/deleteitems/delete';
import DeleteItemsForever from '../../../_modals/OrganizerPopup/deleteitems/deleteforever';
import Renamefolder from '../../../_modals/OrganizerPopup/renameFolder/renameFolder';
import SharePopup from '../../../_modals/SharePopup/SharePopup';
import './_options.scss';
function Options({
  type,
  userPermissions,
  organiser = null,
  competition = null,
  folder = null,
  handleTippy = () => {}
}) {
  const { user } = useContext(AppContext);
  const backend = process.env.REACT_APP_CORE_API;

  const {
    _edit,
    _rename,
    _share,
    _delete,
    deleteOrg,
    restoreOrg,
    restoreFolder,
    restoreCompetition,
    permanentlyDeleteOrg,
    permanentlyDeleteFolder,
    permanentlyDeleteComp
  } = useMemo(() => getOptionsAvailable(userPermissions, user.state));
  const [shareObj, setShareObj] = useState({ type: 'ORGANIZER', _id: null });
  const [deleteForeverObj, setDeleteForeverObj] = useState({
    item: null,
    name: null,
    type: 'ORGANIZER',
    _id: null
  });
  //*ORGANISER OPTIONS ONLY
  const [showEditOrg, setShowEditOrg] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteForever, setShowDeleteForever] = useState(false);
  const dispatch = useDispatch();
  const editHandler = (e) => {
    if (e) e.stopPropagation();
    handleTippy(false);
    setShowEditOrg(!showEditOrg);
  };
  const shareHandler = (e) => {
    e && e.stopPropagation();
    handleTippy(false);
    setShareObj({ type: type, _id: organiser?._id ?? competition?._id });
    setShowShare((prevState) => !prevState);
  };
  const deleteHandler = (e) => {
    e && e.stopPropagation();
    handleTippy(false);
    setShowDelete((prevState) => !prevState);
  };
  //*ORGANISER OPTIONS ONLY

  //*COMPETITION OPTIONS ONLY
  const [showRename, setShowRename] = useState(false); //FOr Rename a Folder

  const handleRenameFolder = (e) => {
    e && e.stopPropagation();
    handleTippy(false);
    setShowRename(true);
  };
  const handleShareFolder = (e) => {
    e && e.stopPropagation();
    handleTippy(false);
    setShareObj({ type: 'FOLDER', _id: folder?._id });
    setShowShare(true);
  };
  const handleDeleteFolder = (e) => {};

  //*COMPETITION OPTIONS ONLY

  const handleDeleteForever = (e) => {
    let item = organiser ?? folder ?? competition;
    let _types = { TRASH_ORG: 'ORGANIZER', TRASH_FOLDER: 'FOLDER', TRASH_COMP: 'COMPETITION' };
    e && e.stopPropagation();
    handleTippy(false);
    setDeleteForeverObj({ item: item, name: item?.name, type: _types[type], _id: item?._id });
    setShowDeleteForever(!showDeleteForever);
  };
  const handleRestore = (_type) => {
    let restoreObj = { Folders: [], Organizers: [], Competitions: [] };
    if (_type === 'FOLDER') {
      restoreObj.Folders.push(competition?._id); // competition?._id because i am using competion card in showTrashFolders.jsx from folders cards and it receives competion prop
    }
    if (_type === 'ORGANIZER') {
      restoreObj.Organizers.push(organiser?._id);
    }
    if (_type === 'COMPETITION') {
      restoreObj.Competitions.push(competition?._id);
    }

    Axios.put(backend + '/api/organizer/trash/restore', restoreObj)
      .then((res) => {
        dispatch(getTrash());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="share-options-O">
      {
        <GetInnerOptions
          type={type}
          _edit={_edit}
          editHandler={editHandler}
          _share={_share}
          shareHandler={shareHandler}
          handleShareFolder={handleShareFolder}
          deleteOrg={deleteOrg}
          deleteHandler={deleteHandler}
          restoreOrg={restoreOrg}
          restoreFolder={restoreFolder}
          restoreCompetition={restoreCompetition}
          _delete={_delete}
          handleDeleteFolder={handleDeleteFolder}
          _rename={_rename}
          handleRenameFolder={handleRenameFolder}
          permanentlyDeleteOrg={permanentlyDeleteOrg}
          permanentlyDeleteComp={permanentlyDeleteComp}
          permanentlyDeleteFolder={permanentlyDeleteFolder}
          handleDeleteForever={handleDeleteForever}
          handleRestore={handleRestore}
        />
      }
      {/* For stopping click and double click events on modals */}
      <div onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
        {showEditOrg && (
          <CreateCompetitionModal
            open={showEditOrg}
            onClose={editHandler}
            isEditorganizer="true"
            organizerdata={organiser}
          />
        )}
        {showShare && (
          <SharePopup
            open={showShare}
            folderName={organiser?.name ?? folder?.name ?? competition?.name}
            owners={shareObj}
            onClose={(e) => shareHandler(e)}
          />
        )}
        {showDelete && (
          <DeleteItems
            open={showDelete}
            setDelete={(e) => deleteHandler(e)}
            type={type}
            folder={organiser ?? folder ?? competition}
          />
        )}
        {showRename && (
          <Renamefolder
            open={showRename}
            setRename={() => setShowRename(!showRename)}
            folder={folder ?? competition}
            type={type}
            // setNewname={setNewname}
          />
        )}
        {showDeleteForever && (
          <DeleteItemsForever
            open={showDeleteForever}
            setDeleteforeveropen={() => setShowDeleteForever(!showDeleteForever)}
            item={deleteForeverObj?.item}
            type={deleteForeverObj?.type}
            isforever={true}
            folderName={deleteForeverObj?.name}
          />
        )}
      </div>
    </div>
  );
}
const GetInnerOptions = ({
  type,
  editHandler,
  shareHandler,
  deleteHandler,
  handleRenameFolder,
  handleShareFolder,
  handleDeleteFolder,
  handleDeleteForever,
  handleRestore,
  ...props
}) => {
  const {
    _edit,
    _rename,
    _share,
    _delete,
    deleteOrg,
    restoreOrg,
    restoreFolder,
    restoreCompetition,
    permanentlyDeleteOrg,
    permanentlyDeleteFolder,
    permanentlyDeleteComp
  } = props;

  switch (type) {
    case 'ORGANIZER':
      return (
        <>
          {_edit && (
            <div onClick={(e) => editHandler(e)} className="option" style={{padding: '5px 0px'}}>
              EDIT
            </div>
          )}
          {_share && (
            <div onClick={(e) => shareHandler(e)} className="option" style={{padding: '5px 0px'}}>
              SHARE
            </div>
          )}
          {deleteOrg && (
            <div onClick={(e) => deleteHandler(e)} className="option" style={{padding: '5px 0px'}}>
              DELETE
            </div>
          )}
        </>
      );
    case 'TRASH_ORG':
      return (
        <>
          {restoreOrg && (
            <div onClick={(e) => handleRestore('ORGANIZER')} className="option" style={{padding: '5px 0px'}}>
              RESTORE
            </div>
          )}
          {permanentlyDeleteOrg && (
            <div onClick={(e) => handleDeleteForever(e)} className="option" style={{padding: '5px 0px'}}>
              DELETE FOREVER
            </div>
          )}
        </>
      );
    case 'TRASH_FOLDER':
      return (
        <>
          {restoreFolder && (
            <div onClick={(e) => handleRestore('FOLDER')} className="option" style={{padding: '5px 0px'}}>
              RESTORE
            </div>
          )}
          {permanentlyDeleteFolder && (
            <div onClick={(e) => handleDeleteForever(e)} className="option" style={{padding: '5px 0px'}}>
              DELETE FOREVER
            </div>
          )}
        </>
      );
    case 'TRASH_COMP':
      return (
        <>
          {restoreCompetition && (
            <div onClick={(e) => handleRestore('COMPETITION')} className="option" style={{padding: '5px 0px'}}>
              RESTORE
            </div>
          )}
          {permanentlyDeleteComp && (
            <div onClick={(e) => handleDeleteForever(e)} className="option" style={{padding: '5px 0px'}}>
              DELETE FOREVER
            </div>
          )}
        </>
      );
    case 'COMPETITION':
      return (
        <>
          {_rename && (
            <div onClick={handleRenameFolder} className="option" style={{padding: '5px 0px'}}>
              RENAME
            </div>
          )}
          {_share && (
            <div onClick={(e) => shareHandler(e)} className="option" style={{padding: '5px 0px'}}>
              SHARE
            </div>
          )}
          {_delete && (
            <div onClick={(e) => deleteHandler(e)} className="option" style={{padding: '5px 0px'}}>
              DELETE
            </div>
          )}
        </>
      );
    case 'FOLDER':
      return (
        <>
          {_rename && (
            <div onClick={handleRenameFolder} className="option" style={{padding: '5px 0px'}}>
              RENAME
            </div>
          )}
          {_share && (
            <div onClick={handleShareFolder} className="option" style={{padding: '5px 0px'}}>
              SHARE
            </div>
          )}
          {_delete && (
            <div onClick={deleteHandler} className="option" style={{padding: '5px 0px'}}>
              DELETE
            </div>
          )}
        </>
      );
    default:
      return <></>;
  }
};
export default Options;
