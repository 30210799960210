import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import default_image from '@img/image.jpg';
import GlitchClip from 'react-glitch-effect/core/Clip';
import { GlitchImage } from '@common';
import useTranslated from '../../../helpers/translationHelper';
import { HiDotsHorizontal, HiFolder } from 'react-icons/hi';
import CreateCompetitionModal from '../../_modals/CreateCompetitionModal/CreateCompetitionModal';
import { usePermissions, usePermissionsOrg } from '../../../utils/usePermissions';
import ShareOptions from '../ShareOptions/ShareOptions';
import { useDispatch } from 'react-redux';
import { setContextObj } from '../../../redux/action/contextMenuAction';
const ItemTimeAgo = ({ time_ago }) => {
  const month = useTranslated('id_news_page_10');
  const fewSeconds = useTranslated('id_news_page_11');
  const years = useTranslated('id_news_page_12');
  const ago = useTranslated('id_news_page_13');

  let timereplace = '';
  if (time_ago.includes('months') && month) {
    timereplace = time_ago.replace('months', month);
  } else if (time_ago.includes('few seconds') && fewSeconds) {
    timereplace = time_ago.replace('few seconds', fewSeconds);
  } else if (time_ago.includes('year') && years) {
    timereplace = time_ago.replace('year', years);
  } else {
    timereplace = time_ago;
  }
  if (time_ago.includes('ago') && ago) {
    timereplace = timereplace.replace('ago', ago);
  }
  return (
    <div className="item-info">
      <span>{timereplace}</span>
    </div>
  );
};

const ItemImageInner = ({ tag }) => {
  if (typeof tag === 'undefined' || tag === null) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="image-cover"></div>
      <span>{tag}</span>
    </React.Fragment>
  );
};

const TeamsGameIconInner = ({ imgContent }) => {
  if (typeof imgContent === 'undefined' || imgContent === null) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="game-image-cover">
        <img src={imgContent} className="players-game-icon" alt={`Game`} />
      </div>
    </React.Fragment>
  );
};

const AboveExcerpt = ({ content }) => {
  if (typeof content === 'undefined') {
    return null;
  }

  return <div className="item-above-excerpt">{content}</div>;
};

const Item = ({
  isOrganizer = false,
  organizerId,
  organizerData,
  isTrash,
  type,
  isFolder = false,
  hideOption = true,
  selected,
  ind,
  compId,
  isLastEdit,
  isRecent,
  competitionData,
  isCompetition,
  compPermissions,
  isShowTrashfolders,
  trashDate,
  trashSelected,
  // isRecentGrid,
  ...props
}) => {
  const dispatch = useDispatch();
  const [showOrgModal, setShowOrgModal] = useState(false);
  const contextMenu = useRef(document.getElementById('context-menu'));
  const orgPermission = usePermissionsOrg(organizerId);
  const folderPerm = usePermissions(organizerId);
  // console.log({organizerData,folderPerm,orgPermission})
  const showContextMenu = (e, item, perm, type, isTrash = false) => {
    e.preventDefault();
    if (perm.showOptions) {
      document.body.style.overflow = 'hidden';
    }
    // if (item.organizerRoles.length) {
    //   if (item.organizerRoles[0].userPermission.name !== 'Owner' && type === 'ORGANIZER') {
    //     perm.delete = false
    //   }
    // }
    let obj = {
      item: item,
      permissions: perm,
      type: type,
      isTrash: isTrash
    };
    dispatch(setContextObj(obj));
    if (perm.showOptions) {
      const { clientX: mouseX, clientY: mouseY } = e;
      contextMenu.current.style.top = `${mouseY}px`;
      contextMenu.current.style.left = `${mouseX}px`;
      contextMenu.current.classList.add('visible');
    }
  };
  function daysRemaining() {
    var eventdate = moment(trashDate);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, 'days');
}
  // function daysRemaining() {
  //   var given = moment(trashDate, "YYYY-MM-DD");
  //   var current = moment().startOf('month');
  //   return moment.duration(given.diff(current)).asDays();
    // return eventdate.diff(todaysdate, 'days');
// }

// var given = moment("2018-03-10", "YYYY-MM-DD");
// var current = moment().startOf('day');

// //Difference in number of days
// moment.duration(given.diff(current)).asDays();
  let { glitchDisabled = false, browseGame } = props;
  let classes = 'item';
  let imageContainerClass = '';

  if (typeof props.imageContainerClass !== 'undefined') {
    imageContainerClass = props.imageContainerClass;
  }

  if (typeof props.large !== 'undefined') {
    classes += ' large';
  }

  if (typeof props.column !== 'undefined') {
    classes += ' col-' + props.column;
  }

  if (typeof props.classes !== 'undefined') {
    classes += ' ' + props.classes;
  }

  if (typeof props.loading !== 'undefined') {
    classes += ' loading';
  }
  const openInNewWindow = props.openInNewWindow ?? false;
  const title = props.title ?? '[Deleted]';
  const excerpt = props.excerpt;
  const image = props.image ?? props.default_image ?? default_image;
  
  const timeAgo = moment(props.published ?? new Date()).fromNow();
  const link_to = props.link_to ?? '#';
  const hideTimeAgo = props.hide_time_ago ?? true;
  const above_excerpt = props.above_excerpt;
  const tag = props.tag;
  const bgSize = props.backgroundSize;
  const ThreeDots = props.threeDots;
  const date = props.date;
  const key = props.key;
  const onRouting = props.onRouting;
  const[selectedEdit,setSelectedEdit]=useState('')
  // const organizerId= props.organizerId
  const competition = props.competition;
  const permissions = usePermissionsOrg(organizerId);

  return (
    // width: isLastEdit? '320px':'240px'
    <div style={{width: isLastEdit?'340px':'265px'}} className={`${props.link_to === '/news/not-found' ? 'disabled_link' : ''}`}>
      <div
        onClick={props.onClick}
        onDoubleClick={onRouting}
        onContextMenu={(e) => {
          if (isOrganizer) {
            showContextMenu(e, organizerData, orgPermission, 'ORGANIZER', isTrash);
          }
          if (isFolder) {
            showContextMenu(e, organizerData, orgPermission, 'FOLDER', isTrash);
          }else if(isCompetition){
            showContextMenu(e, competitionData, compPermissions, 'COMPETITION');
          }
        }}
        className={`${Number(props.column) === 1 ? 'item column1' : 'item '}`}
        style={{ paddingLeft: '14px', paddingRight: '14px' }}>
        <Link to={link_to} style={{ textDecoration: 'none' }}>
          {/* <GlitchClip disabled={glitchDisabled} duration="4s" onHover={glitchDisabled ? false : true}> */}
          <div 
            className={`item-image ${imageContainerClass}`}
            style={{
              border:(selected === ind && (isLastEdit || isRecent || isOrganizer)) ? '1px solid #3eabf0' : trashSelected ? '1px solid #3eabf0' : 'none', clipPath: (selected === ind && isLastEdit) ? 'polygon(13% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 24%)' : (!isLastEdit && !isRecent) ? 'polygon(16% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 28%)' : isRecent ? 'polygon(16% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 23%)' : trashSelected ? 'polygon(16% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 28%)' : 'polygon(13% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 24%)', height: isLastEdit || isRecent ? '157px':'135px',width: isLastEdit ? '288px':'280px',
              // dotsColor={ selected === ind ? '#3eabf0' : 'red' }

              backgroundImage: `url(${image})`,
              backgroundSize: bgSize ? bgSize : '100%',
              backgroundPosition: 'center'
            }}>
              {isShowTrashfolders?<span style={{color:'#f11857',background:'#1b0815',font:'normal normal bold 8px/10px Montserrat'}}>{daysRemaining()+ 30} {`${(daysRemaining()+ 30) === 1 ? 'Day' : 'Days'}`}</span>:''}
              
            <div className="image-cover" style={{height:'148px',width:'240px',transform:'rotate(51deg)' , borderRight: (selected === ind) ? '1px solid #3eabf0' : trashSelected ? '1px solid #3eabf0' : 'none'}}></div>
            {props.item_image_inner ?? <ItemImageInner tag={tag} />}
            <TeamsGameIconInner imgContent={props.team_game_icon_inner} />{' '}
            {/* Only for team Item */}
            {browseGame && (
              <>
                <Link
                  className="browse-game gotogameButton"
                  target={openInNewWindow && '_blank'}
                  to={link_to}
                  style={{ textDecoration: 'none' }}>
                  <h3 style={{ fontSize: '17px' }}>GO TO GAME</h3>
                </Link>
                <div className="coverOpacity"> </div>
              </>
            )}
          </div>
        </Link>
        {type === "COMPETITION" ?(
          // width: isLastEdit? '320px':'240px'
          compPermissions?.showOptions && <div style={{ position: 'absolute',marginTop:'5px',marginLeft: isLastEdit? '265px':'257px' }}>
     
            <ShareOptions 
            style={{height:'26px',width:'26px', color: (selected === ind) ? '#3eabf0' : 'gray', backgroundColor: (selected === ind) ? '#091625' : '#201e1e'}}
              hideOption={hideOption}
              item={organizerData}
              type={type}
              isTrash={isTrash}
              permissions={compPermissions}
            />
          </div>
        ) : permissions?.showOptions ? (
          // width: isLastEdit? '320px':'240px'
          <div style={{ position: 'absolute',marginTop:'5px',marginLeft: isLastEdit? '222px':'218px' }}>
          {/* // <div style={{ position: 'absolute', width:'100%',flexDirection:'row',flexWrap:'wrap' }}> */}

            {/* {hideOption&& */}
            <ShareOptions 
            style={{height:'26px',width:'26px' ,color: ((selected === ind && isLastEdit) || selected === ind) ? '#3eabf0' : trashSelected ? '#3eabf0' : 'gray', backgroundColor: ((selected === ind && isLastEdit) || selected === ind) ? '#091625' : trashSelected ? '#091625' : '#201e1e' }}
            // dotsColor={ selected === ind && !isLastEdit && !isRecent ? '#3eabf0' : '#68717e;' }
              hideOption={hideOption}
              item={organizerData}
              type={type}
              isTrash={isTrash}
              permissions={permissions}
            />
            {/* } */}
          </div>
        ) : null}
        <Link to={link_to} style={{ textDecoration: 'none' }}>
          {!hideTimeAgo ? <ItemTimeAgo time_ago={timeAgo} /> : null}
          {/* </GlitchClip> */}
          {/* <div className="item-title" style={{ display: "flex", alignItems: "center", marginTop:5 }}> */}
          {/* <div>
            <HiFolder className="hi-folder-item" />
          </div>
          <span className="competition-steel-series">STEELSERIES</span>
          <div className="dropdown">
            <button className="dropbtn"><HiDotsHorizontal className="hi-dot-item" /></button>
            <div className="dropdown-content">
              <a className="uppercase" onClick={() => setShowOrgModal(true)}>Edit</a>
              <a className="uppercase">Share</a>
              <a className="uppercase">Delete</a>
            </div>
          </div>
          {showOrgModal ? (
            <CreateCompetitionModal open={showOrgModal} onClose={setShowOrgModal} />
          ) : null} */}
          {/* <div>
            <HiDotsHorizontal className="hi-dot-item" />
            </div> */}
          {/* </div> */}
          <div className="item-title">
            <h3 style={{font:'normal normal bold 10px/13px Montserrat',width: '167px',height: '13px'}}>{title}</h3>
          </div>
          <AboveExcerpt content={above_excerpt} />
          <div className="item-excerpt">{excerpt}</div>
          {date ? <div >{date}</div> : null}
        </Link>
      </div>
    </div>
  );
};

export default Item;
