import React, { useState } from 'react';
import { Tabs } from '@common';
import { Row } from 'react-bootstrap';
import LastEdit from '../LastEdit/LastEdit';
import { useEffect } from 'react';

const SimplePageWithTabs = ({
  title,
  firstPage,
  organizerLength,
  breadcrumbs,
  lastedit,
  type,
  children,
  tabs,
  top_right,
  tabs_props,
  buttonProps,
  currentPage,
  frontpage,
  isTeams,
  page,
  createOrganizer,
  showLastEdit,
  style,
  setOpenUnsavedPopup,
  isMainTabs = false, ///added to pass stageIndex for managing preview
  setStageIndex,
  showCreate = false,
  base_route
}) => {
  const [nextPage, setNextPage] = useState(0);
  useEffect(()=>{
    if(isMainTabs){
      setStageIndex(nextPage);
    }
  },[nextPage])
  useEffect(() => {
    if (!currentPage) {
      let page = localStorage.getItem('page');
      if (page) {
        setNextPage(page);
      }
    } else {
      setNextPage(currentPage);
    }
  }, [currentPage]);
  const setLocalPage = (num) => {
    localStorage.setItem('page', num);
    setNextPage(num);
  };
  return (
    <div className={'container-fluid simple-page-with-tabs ' + type}>
      <div className="row">
        <div className="col-12">
          {title && (<div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: buttonProps ? '44px' : '0px'
            }}
          >
            <h1>{title ?? 'Lorem Ipsum'}</h1>
            <Row>
              <div>
                <span style={{ display: 'flex', marginTop: '8px' }}>{top_right}</span>
              </div>
              {showCreate ? createOrganizer : ''}
            </Row>
          </div>)}
       
            {showLastEdit && <LastEdit />}
          <Row>{breadcrumbs ?? ''}</Row>
          {organizerLength < 1 ? (
            <Row style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {firstPage ?? ''}
            </Row>
          ) : (
            <>
              {' '}
              <Row>{lastedit ?? ''}</Row>
              {buttonProps && (
                <button
                  className={`transparentButton ${page == 'YourMatches' && 'your-matches'}`}
                  type="button"
                  onClick={() => setNextPage(1)}
                >
                  {buttonProps}
                </button>
              )}
              {children ?? (
                <Tabs
                  style={style}
                  pages={tabs}
                  {...tabs_props}
                  nextPage={nextPage}
                  baseRoute={base_route}
                  setNextPage={setLocalPage}
                  frontpage
                  isTeams={isTeams}
                  default={nextPage}
                  setOpenUnsavedPopup={setOpenUnsavedPopup}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimplePageWithTabs;
