import Axios from 'axios';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { Loading } from '../../../_common';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import StageTab from './Leaderboard/StageTab';
import { TiArrowUnsorted } from 'react-icons/ti';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LOADING } from '../../../../redux/action/types';

const LeaderboardTab = ({defaultLogo, shortName}) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [stages, setStages] = useState([])
    const loadingState = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
    const getCompetitionStages = async () => {
        dispatch({type: SET_LOADING, payload: true})
        await Axios.get(`${process.env.REACT_APP_CORE_API}/api/organizer/competition/${id}/stage/leaderboard`).then(response => {
            if(response.data?.data.length) {
                setStages(response.data.data)
                toast.success(response.data.msg)
            }
            dispatch({type: SET_LOADING, payload: false})
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.msg)
                return
            } 
            toast.error(error.message)
            dispatch({type: SET_LOADING, payload: false})
        })
    }

    useEffect(() => console.log({loadingState}), [loadingState])

    useEffect(() => {
        getCompetitionStages()
    }, [])

    const settingsTab = {
        'BEST OF 1': (<div>
            <Row>
                <Col md={12}>
                    <h2>OUTCOMES</h2>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Table className="table" variant="dark">
                        <thead>
                            <tr>
                                <th colSpan={4}>SCORE <TiArrowUnsorted style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}/></th>
                                <th>WINNER POINTS <TiArrowUnsorted style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}/></th>
                                <th>LOSER POINTS <TiArrowUnsorted style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}/></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={4}><Col sm={4} style={{paddingLeft: 0}}><Form.Control type="text" value={'2 - 0'} className={'form-input black-bg'} disable/></Col></td>
                                <td><Form.Control type="text" value={3} column={'sm'} className={'form-input black-bg'}/></td>
                                <td><Form.Control type="text" value={0} column={'sm'} className={'form-input black-bg'}/></td>
                            </tr>
                            <tr>
                                <td colSpan={4}><Col sm={4} style={{paddingLeft: 0}}><Form.Control type="text" value={'2 - 1'} className={'form-input black-bg'} disable/></Col></td>
                                <td><Form.Control type="text" value={2} column={'sm'} className={'form-input black-bg'}/></td>
                                <td><Form.Control type="text" value={1} column={'sm'} className={'form-input black-bg'}/></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>),
        'BEST OF 2': <div>2</div>,
        'BEST OF 3': <div>3</div>,
        'BEST OF 4': <div>4</div>,
    }

    return (
        <>
            <Row>
                <Col md={12}>   
                        <StageTab loadingState={loadingState} stages={stages} competitionId={id} getLeaderboard={getCompetitionStages} defaultLogo={defaultLogo} shortName={shortName}/>
                </Col>
            </Row>

            {/* <Row style={{ marginTop: 25 }}>
                <Col md={6}>
                    <h2>RANKING CRITERIA <BsInfoCircleFill color={'#68717E'} size={15}/></h2>
                    <p style={{color: '#68717E', fontSize: 12}}>You can set several tiebreakers which will be triggered top to bottom to try and break the ties. If a tie resists to a tiebreaker, then the next one in the list will be used.</p>
                    <div className='card collapsible-card'>
                        <Collapsible
                            trigger="Match win/draws/losses (overall)"
                            classParentString="collapsible-wrapper"
                            triggerStyle={{ display: 'block', margin: '0px 10px', lineHeight: 2.5, paddingLeft: 10, background: '#03060D', height: 40, left: 217, borderRadius: 2}}>
                                <p>
                                    This is the collapsible content. It can be any element or React
                                    component you like.
                                </p>
                        </Collapsible>
                        <AiOutlineClose  color={'red'} size={20} style={{ marginRight: 20}}/>
                    </div>

                    <div className='card collapsible-card'>
                        <Collapsible
                            trigger="Points (head-to-head)"
                            classParentString="collapsible-wrapper"
                            triggerStyle={{ display: 'block', margin: '0px 10px', lineHeight: 2.5, paddingLeft: 10, background: '#03060D', height: 40, left: 217, borderRadius: 2}}>
                                <p>
                                    This is the collapsible content. It can be any element or React
                                    component you like.
                                </p>
                        </Collapsible>
                        <AiOutlineClose  color={'red'} size={20} style={{ marginRight: 20}}/>
                    </div>

                    <div className='card collapsible-card'>
                        <Collapsible
                            trigger="Manual"
                            classParentString="collapsible-wrapper"
                            triggerStyle={{ display: 'block', margin: '0px 10px', lineHeight: 2.5, paddingLeft: 10, background: '#03060D', height: 40, left: 217, borderRadius: 2}}>
                                <p>
                                    This is the collapsible content. It can be any element or React
                                    component you like.
                                </p>
                        </Collapsible>
                        <AiOutlineClose  color={'red'} size={20} style={{ marginRight: 20}}/>
                    </div>

                    <div className='card collapsible-card'>
                        <Collapsible
                            trigger="Match score difference (head-to-head)"
                            classParentString="collapsible-wrapper"
                            triggerStyle={{ display: 'block', margin: '0px 10px', lineHeight: 2.5, paddingLeft: 10, background: '#03060D', height: 40, left: 217, borderRadius: 2}}>
                                <p>
                                    This is the collapsible content. It can be any element or React
                                    component you like.
                                </p>
                        </Collapsible>
                        <AiOutlineClose  color={'red'} size={20} style={{ marginRight: 20}}/>
                    </div>


                    <div className='card collapsible-card'>
                        <Collapsible
                            trigger="Match score difference (overall)"
                            classParentString="collapsible-wrapper"
                            triggerStyle={{ display: 'block', margin: '0px 10px', lineHeight: 2.5, paddingLeft: 10, background: '#03060D', height: 40, left: 217, borderRadius: 2}}>
                                <p>
                                    This is the collapsible content. It can be any element or React
                                    component you like.
                                </p>
                        </Collapsible>
                        <AiOutlineClose  color={'red'} size={20} style={{ marginRight: 20}}/>
                    </div>
                </Col>

                <Col md={6}>
                    <h2>SETTINGS</h2>
                    <h4>SCORE SYSTEM</h4>
                        <MultiSelectDropDown options={[{value: 1, label: 'Option One'}]}/>
                    <Tabs
                        frontpage={0}
                        pages={settingsTab}
                        nextPage={() => {}}
                        style={{ marginLeft: 0}}/>
                </Col>
            </Row> */}
        </>
    );
};
export default LeaderboardTab;
