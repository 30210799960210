import hexRgb from 'hex-rgb';
import { groupBy } from 'lodash';
import { applyBrowserTimezone, getCurrentTimeZone } from './time-functions';
import useTranslated from '../helpers/translationHelper';

export const numberSuffix = (number) => {
  switch (number) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
export const separateMainRoleFromSubRole = (roles = []) => {
  return roles.map((role) => {
    let count = role.match(/:/g)?.length;
    if (count == 2) {
      return role.substring(0, role.lastIndexOf(':'));
    } else if (count == 1 && role.split(':')[0] === 'Coach') {
      return 'Coach';
    }
    return role;
  });
};
export const getTopRole = (roles = [], game) => {
  roles = separateMainRoleFromSubRole(roles);
  const sortedRoles = [];
  const allRoles =
    game?.shortName === 'LoL'
      ? [
          'Owner',
          'Manager',
          'Coach',
          'Player:TOP',
          'Player:JUNGLE',
          'Player:MID',
          'Player:BOT',
          'Player:SUPPORT',
          'Player:SUB'
        ]
      : [
          'Owner',
          'Manager',
          'Coach',
          'Player:IGL',
          'Player:RIFLER',
          'Player:SUPPORT',
          'Player:AWP',
          'Player:LURKER',
          'Player:SUB'
        ];
  if (roles?.length) {
    allRoles.forEach((role) => {
      if (roles.includes(role)) sortedRoles.push(role);
    });
    return sortedRoles[0];
  }
  return false;
};
export const ordinalSuffixOf = (i) => {
  // if (i<=20) {
  //   return useTranslated("id_utility_-_leaderboard_position_"+i)
  // }
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) return i + 'st';
  if (j === 2 && k !== 12) return i + 'nd';
  if (j === 3 && k !== 13) return i + 'rd';

  return i + 'th';
};
export const arrayModifierTournaments = (arr1, arr2) => {
  if (arr1.length === arr2.length) return;
  const smallerArray = arr1.length < arr2.length ? arr1 : arr2;
  let indentation = 0;
  let lastAddedIndex = 1;
  while (arr1.length !== arr2.length) {
    smallerArray.splice(lastAddedIndex + indentation, 0, [undefined]);
    lastAddedIndex++;
    indentation++;
  }
  console.log(arr1, arr2);
};

export const getTeamsAvgRank = (teammembers = [], game = {}) => {
  // console.log("SCRIMS players game: ", teammembers, game);
  if (!Object.keys(game).length || Object.keys(game) === []) {
    return 'No game chosen';
  }
  if (!teammembers.length || teammembers === []) {
    return 'No Rank';
  }
  let mainPlayersType = [
    'Player:TOP',
    'Player:JUNGLE',
    'Player:MID',
    'Player:BOT',
    'Player:SUPPORT'
  ];
  const mainPlayers = teammembers.filter(({ role }) => mainPlayersType.includes(role));
  let arrayofPlayerRanks = [];
  mainPlayers.filter(({ player }) => {
    if (player) {
      const leagueEntry = player?.lolData?.leagueEntries.find(
        ({ queueType }) => queueType === 'RANKED_SOLO_5x5'
      );
      if (leagueEntry) {
        arrayofPlayerRanks.push(`${leagueEntry.tier} ${leagueEntry.rank}`);
      }
    }
  });
  // console.log("SCRIMS : ", game.gameRanks);
  if (!arrayofPlayerRanks.length || arrayofPlayerRanks === []) {
    return 'No Rank';
  }
  let calculatedRank = Math.round(
    arrayofPlayerRanks
      .map((rank) => {
        if (game.gameRanks.indexOf(rank) == -1) {
          let temp = rank.split(' ');
          temp.pop();
          rank = temp.join(' ');
          return game.gameRanks.indexOf(rank);
        }

        return game.gameRanks.indexOf(rank);
      })
      .reduce((a, b) => a + b, 0) / arrayofPlayerRanks.length
  );
  const value = calculatedRank < 0 ? game.gameRanks.length - 1 : calculatedRank;
  return `${game.gameRanks[value]}`;
};

export const getCountryCode = (country = 'Indonesia') => {
  const allCountries = {
    AF: 'Afghanistan',
    AX: 'Aland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua And Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia And Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, Democratic Republic',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Cote D'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island & Mcdonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic Of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle Of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyan Arab Jamahiriya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States Of',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthelemy',
    SH: 'Saint Helena',
    KN: 'Saint Kitts And Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre And Miquelon',
    VC: 'Saint Vincent And Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome And Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia And Sandwich Isl.',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard And Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad And Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks And Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis And Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  };
  const countryObj = Object.keys(allCountries).find((key) => allCountries[key] === country);

  return countryObj;
};

export const getFiltersConcat = ({ groups = [], seasons = [], divisions = [] }) => {
  let defaultFilters = {};
  defaultFilters.nationality = groups
    ?.filter((el) => el.selected)
    .map((el) => el.name)
    .join(',');
  defaultFilters.divisionsFilter = divisions
    ?.filter((el) => el.selected)
    .map((el) => el.name)
    .join(',');
  defaultFilters.seasonsFilter = seasons
    ?.filter((el) => el.selected)
    .map((el) => el.name)
    .join(',');
  return defaultFilters;
};

export const updateFilterCount = (latestFilter, oldFilter = latestFilter) => {
  let defaultFilters = {};
  defaultFilters.groups = oldFilter.groups?.map((el, i) =>
    Object.assign(el, { matches: latestFilter.groups[i].matches })
  );
  defaultFilters.divisions = oldFilter.divisions?.map((el, i) =>
    Object.assign(el, { matches: latestFilter.divisions[i].matches })
  );
  defaultFilters.seasons = oldFilter.seasons?.map((el, i) =>
    Object.assign(el, { matches: latestFilter.seasons[i].matches })
  );
  return defaultFilters;
};

export const groupMatchesDate = (matches = [], oldestToNew = false, timezone) => {
  var grouped = groupBy(matches, function (match) {
    return applyBrowserTimezone(new Date(match.datetime), timezone).format('ddd MMMM DD YYYY');
  });
  const ordered = Object.keys(grouped).sort(function (x, y) {
    return oldestToNew ? new Date(x) - new Date(y) : new Date(y) - new Date(x);
  });
  const sortedMatches = ordered.map((date) => {
    return { [date]: grouped[date] };
  });

  // console.log("grouped : ", sortedMatches);
  return sortedMatches;
};

export const checkIfTimeInBody = (body) => {
  let bodies = body.split('&-');
  let notiBody = body;

  if (bodies.length >= 2) {
    let formatDate = new Date(bodies[1]);
    notiBody =
      bodies[0] +
      formatDate.getHours() +
      ':' +
      formatDate.getMinutes() +
      ` (${getCurrentTimeZone()})` +
      bodies[2];
  }
  return notiBody;
};

export const getTeamName = (
  team1 = { members: [], organisation: {}, _id: '' },
  team2 = { members: [], organisation: {}, _id: '' },
  userId
) => {
  const team1Member = isMemberOfTeam(team1, userId);
  const team2Member = isMemberOfTeam(team2, userId);
  return team1Member ? team1.name : team2Member ? team2.name : null;
};

export const isMemberOfTeam = (team = { members: [], organisation: {}, _id: '' }, userId) => {
  const teamMember =
    (team.members.length &&
      team?.members?.find(
        (member) =>
          member.player?.user?._id === userId ||
          member.player?.user === userId ||
          member.coach?.user?._id === userId ||
          member.coach?.user === userId ||
          member.user?._id === userId ||
          member.user === userId
      )) ||
    (team.organisation?.owners?.length &&
      team.organisation?.owners?.find(
        (owner) => owner?.user === userId || owner?.user?._id === userId
      ));
  return teamMember ? true : false;
};

export const convertToInternationalCurrencySystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + 'K'
    : Math.abs(Number(labelValue));
};

export const getGameBackground = (alpha = 1) => {
  const game = JSON.parse(localStorage.getItem('selectedGame'));
  return hexRgb(game.mainColour, { alpha: 0.1, format: 'css' });
};

export const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)';
  }
  throw new Error('Bad Hex');
};
