import React, { useContext, useState } from 'react';
import { CheckboxSlider } from '@common';
import { AppContext } from '../../../../context/AppContext';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import useTranslated from '../../../../helpers/translationHelper';

const Sound = () => {
  const { user } = useContext(AppContext);
  const [notificationSound, setNotificationSound] = useState(user.state.notificationSound);
  const enableSoundTrans = useTranslated('id_settings_(notifications)_0');
  const saveTrans = useTranslated('id_settings_(notifications)_1');
  const onSubmit = async (e) => {
    e.preventDefault();

    const data = { notificationSound };

    try {
      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/auth`, data);
      user.dispatch({ type: 'update', payload: newData.updatedUser });
      toast.success('Notification Sound settings updated');
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : 'Fatal error';
      toast.error(msg);
    }
  };

  return (
    <form onSubmit={onSubmit} className="account-settings-form">
      <div className="row">
        <div className="col-12">
          <div className="form-row">
            <CheckboxSlider
              value={notificationSound}
              onChange={(e) => setNotificationSound(e.target.checked)}
              label=/* "Enable Notification Sound" */ {enableSoundTrans}
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <button className="button primary" type="submit">
          {/* Save Changes */}
          {saveTrans}
        </button>
      </div>
    </form>
  );
};

export default Sound;
