import React from 'react';
import logo_header from '@img/logo-header.svg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@common';

import useTranslated from '../../../helpers/translationHelper';

const Footer = () => {
  return (
    <footer className="global-footer">
      <div className="container-fluid">
        <div className="footer-logo">
          <Link to="/" className="logo-text">
            <img src={logo_header} alt="Leagues logo" />
            <strong className="uppercase">
              {' '}
              {useTranslated('id_footer_0')} {/*  Leagues.gg */}
            </strong>
          </Link>
        </div>
        <div style={{ marginBottom: 40 }}>
          <Link to={{ pathname: process.env.REACT_APP_ABOUT_LEAGUE_URL }} target="_blank">
            <Button className="footer-about-leagues-gg">About Leagues.GG</Button>
          </Link>
        </div>
        <div className="footer-links blurry">
          <span className="copy-notice">Leagues ApS - CVR 38606166.</span>

          {/* <Link to="/rules">Rules</Link>
          <Link to="/s/terms-and-conditions">Conditions of use</Link>
          <Link to={{ pathname: process.env.REACT_APP_ABOUT_LEAGUE_URL }} target="_blank">Staff</Link>
          <Link to={{ pathname: process.env.REACT_APP_ABOUT_LEAGUE_URL }} target="_blank">Contact</Link> */}
        </div>
        {/* <div> */}

        {/* <a href="https://www.facebook.com/LeaguesGGLOL" target="_blank">
            <Icon name="facebook" />
          </a>
          <a href="https://twitter.com/Leagues_GG" target="_blank">
            <Icon name="twitter" />
          </a>
          <a href="https://www.twitch.tv/leaguesGGDenmark" target="_blank">
            <Icon name="twitch" />
          </a>
          <a href="https://www.youtube.com/channel/UCWi39meY_gHxb2npuxe42Pg" target="_blank">
            <Icon name="youtube" />
          </a>
        </div>
        <span className="copy-notice">&copy;{useTranslated("id_footer_3")} {/* Leagues 2020. All rights reserved.</span> */}
      </div>
    </footer>
  );
};

export default Footer;
