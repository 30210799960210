import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { HiFolder, HiDotsHorizontal } from 'react-icons/hi';
import { Button, Space, Table } from 'antd';
import { TiArrowUnsorted } from 'react-icons/ti';
import { Col, Row } from 'react-bootstrap';
// import ShareOptions from '../../../_common/ShareOptions/ShareOptions';
// import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// import { Loading } from '../../../_common';
// import { setContextObj } from '../../../../redux/action/contextMenuAction';
// import { usePermissionsOrg } from '../../../../utils/usePermissions';
// import CreateOrganizer from './CreateOrganizer';
// import Image from 'react-bootstrap/Image';
import useContextMenu from '../ContextMenu/hooks/useContextMenu';
import ThreeDots from '../ThreeDots/ThreeDots';
import ContextMenu from '../ContextMenu/ContextMenu';
import Options from '../ThreeDots/Options/Options';
import {
  getFolderOrCompetitonPermissions,
  getOrganiserPermissions,
  getOwnerOrganizer
} from '../../../helpers/permissionsHelper';
import { AppContext } from '../../../context/AppContext';
export const ListViewRow = ({
  _disabled = true,
  organiser,
  item,
  folder = {},
  permissions = [],
  logo,
  last_edited,
  ind = 0,
  title,
  onClickHandler = () => {},
  onDoubleClickHandler = () => {},
  onHoverHandler = () => {},
  type,
  selectionType,
  matchCount
}) => {
  const { user } = useContext(AppContext);
  const { userPermissions, showDots } = useMemo(
    () => getFolderOrCompetitonPermissions(user, permissions),
    [permissions]
  );
  const owner = useMemo(() => getOwnerOrganizer(organiser?.organizerRoles ?? []), [organiser]);
  const { isVisible, x, y, showMenu, hideMenu } = useContextMenu();
  const handleContextMenu = (event) => {
    event.preventDefault();
    onClickHandler(event, item?._id, selectionType);
    showMenu(event);
  };

  return (
    <div
      key={item?._id ?? ind}
      onContextMenu={handleContextMenu}
      onMouseEnter={()=>onHoverHandler(item?._id,'IN')}
      onMouseLeave={()=>onHoverHandler(item?._id,'OUT')}
      onClick={(e) => {
        onClickHandler(e, item?._id, selectionType);
      }}
      onDoubleClick={onDoubleClickHandler}
      style={{
        // backgroundColor: !_disabled  ? 'transparent' : '#050c16',
        display: 'contents',
        verticalAlign: 'inherit'
      }}
      className="hoverable-row">
      <td>
        <Col className="name-row text" style={{ marginLeft: 16 }}>
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span className="imgLogoContainer" style={{ color: _disabled ? 'black' : '#8fa3b1' }}>
                {/* add folder instead of image */}
                {logo ? (
                  <img
                    className="imgLogoWidth imgLogoCropped"
                    src={logo}
                    alt="Insight Gaming"
                    width={'32px'}
                    height={'32px'}
                  />
                ) : (
                  <HiFolder size={32} color={'#8fa3b1'} />
                )}
              </span>
              <span
                // className="text"
                style={{
                  maxWidth: '350px',
                  textOverflow: 'ellipsis',
                  marginLeft: '10px',
                  height: '18px',
                  width: '350px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'inline-block',
                  textAlign: 'left',
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  // color: _disabled ? '#FFF' : '#3eabf0'
                  // color: _disabled ? '#FFF' : '#3eabf0',
                  color: _disabled ? (type === 'FOLDER'? '#8fa3b1' : '#fff') : '#3eabf0'
                }}>
                {title ?? 'SteelSeries'}
              </span>
            </div>
          </Row>
        </Col>
      </td>
      <td
        className="table-date-f"
        style={{
          // color: !_disabled ? '#3eabf0' : '#8fa3b2',
          color: !_disabled ? '#3eabf0' : '#FFF',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        {item.isFolder ?? moment(item?.createdDate).format('YYYY-MM-DD')}
      </td>
      <td>
        {/* <Col
          className="me-organizer"
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
            // width: '30px',
            color: _disabled ? '' : '#296f9e'
          }}>
          <div style={{ width: '25px', height: '22px', display: 'inline-flex' }}>
            <img
              src={owner && owner?.avatarImage}
              style={{ borderRadius: 15 }}
              alt={owner && owner?.firstName ? owner?.firstName + ' ' + owner?.lastName : 'No name'}
            />
          </div>
          {owner && owner?._id === user?.state?._id ? (
            <span style={{ textTransform: 'capitalize', marginLeft: '5px' }}>Me</span>
          ) : (
            owner?.firstName + ' ' + owner?.lastName
          )}
        </Col> */}
      </td>
      <td
        className="table-matches"
        style={{
          // color: !_disabled ? '#3eabf0' : '#8fa3b2',
          color: !_disabled ? '#3eabf0' : '#FFF',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        {item?.isfolder ?? null ?? matchCount}
      </td>
      <td style={{ width: '15%' }}></td>
      <td>
        <Col className="last-modified" style={{ color: _disabled ? '' : '#296f9e' }}>
          {last_edited}
        </Col>{' '}
      </td>
      <td>
        <Col className="options-dots">
          {showDots && (
            <ThreeDots
              userPermissions={userPermissions}
              user={user}
              type={type}
              disabled={_disabled}
              folder={type === 'FOLDER' && item}
              competition={type === 'COMPETITION' && item}
              onFirstClick={(e) => onClickHandler(e, item?._id, selectionType)}
            />
          )}
          {showDots && isVisible && !_disabled && (
            <ContextMenu x={x} y={y} onClose={hideMenu} isVisible={isVisible}>
              <Options
                type={type}
                userPermissions={userPermissions}
                folder={type === 'FOLDER' && item}
                competition={type === 'COMPETITION' && item}
              />
            </ContextMenu>
          )}
        </Col>
      </td>
    </div>
  );
};
