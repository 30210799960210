import React, { useState, useRef, useEffect } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { FiX } from 'react-icons/fi';
import Loading from '../Loading/Loading';
import { Link } from 'react-router-dom';

// useKeyPress custom hook
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

const DynamicSearch = ({
  placeholder = 'Search',
  onSearch,
  onSelectResult,
  loadingResults,
  results = [],
  mixedResults = false,
  newTab,
  resultStyle,
  searchInputStyle,
  schedule
}) => {
  const queryRef = useRef();
  const [query, setQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [cursor, setCursor] = useState(0);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const NewTabOpen = newTab ? NewTabOpener : React.Fragment;

  const onQueryChange = (e) => {
    const val = e.target.value;
    onSearch(val); // Component callback, for running search logic outside the component
    setQuery(val);
    setCursor(0); // reset the keyboard selection back to first item
  };

  const onClickResult = (result) => {
    onSelectResult(result); // Component callback, for sending selected result outside of component

    // clear query
    onSearch('');
    setQuery('');
    queryRef.current.blur(); // take focus out of search box
  };

  //
  // KEYBOARD STUFF
  //

  // If down key pressed
  useEffect(() => {
    if (results.length && downPress) {
      setCursor((prevState) => (prevState < results.length - 1 ? prevState + 1 : 0));
    }
  }, [results, downPress]);

  // If up key pressed
  useEffect(() => {
    if (results.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : results.length - 1));
    }
  }, [results, upPress]);

  // If enter pressed
  useEffect(() => {
    if (results.length && enterPress && showResults) {
      onSelectResult(results[cursor]); // Component callback, for sending selected result outside of component

      // clear query
      onSearch('');
      setQuery('');
      queryRef.current.blur(); // take focus out of search box
    }
  }, [results, cursor, enterPress, onSearch, onSelectResult, showResults]);

  // Reset cursor when results hide/show
  useEffect(() => {
    setCursor(0);
  }, [showResults]);

  return (
    <div className="dynamic-search">
      <div
        className="dynamic-search-input"
        style={{ marginTop: '-14px', marginBottom: '-8px', marginRight: '-10px' }}>
        <input
          ref={queryRef}
          type="text"
          id="query"
          className={`form-input ${!schedule && 'notSchedule'}`} //if it is not schedule then we need to add this class.
          placeholder={placeholder}
          value={query}
          onChange={onQueryChange}
          onFocus={() => setShowResults(true)}
          onBlur={(e) => {
            setShowResults(false);
            e.target.value = e.target.value.trim();
          }}
          autoComplete="off"
          style={searchInputStyle}
        />
        {query.length !== 0 ? (
          <FiX
            className="input-icon"
            onClick={() => {
              onSearch('');
              setQuery('');
              queryRef.current.focus();
            }}
          />
        ) : (
          <RiSearchLine className="input-icon" />
        )}
        {/* <RiSearchLine className="input-icon" /> */}
      </div>
      <div
        className={!showResults ? 'dynamic-search-results hide' : 'dynamic-search-results'}
        onMouseDown={(e) => e.preventDefault()}
        style={resultStyle}>
        {/* Results is an array with 3 possible keys: _id, img, title, subtitle */}
        {loadingResults ? (
          <div className="search-result results-loading">
            <Loading width={27} height={27} />
          </div>
        ) : !results.length && query ? (
          <div className="search-result no-results">
            {query.length < 3 ? 'Try typing more characters...' : 'No results found.'}
          </div>
        ) : (
          results.map(
            ({ _id, img, title, subtitle, type, user, player, coach, logoCropped }, i) => (
              <NewTabOpen result={{ _id, type }}>
                <div
                  className={`search-result ${i === cursor && 'active'}`}
                  key={_id}
                  onClick={() =>
                    onClickResult({
                      _id,
                      type,
                      img,
                      title,
                      subtitle,
                      user,
                      player,
                      coach,
                      logoCropped
                    })
                  }>
                  {img ? (
                    <div
                      className={
                        logoCropped || logoCropped === undefined
                          ? 'search-result-img'
                          : 'search-result-img-notCropped'
                      }
                      style={{ backgroundImage: `url(${img})` }}
                    />
                  ) : (
                    <div className="profile-img-with-inital-1-letter">{title.charAt(0)}</div>
                  )}
                  <div className="search-result-details">
                    {mixedResults && type ? (
                      <div className="flex-sb">
                        <div className="title-subtitle">
                          <p className="title">{title}</p>
                          {subtitle && <small className="subtitle">{subtitle}</small>}
                        </div>
                        <div className="type">{type}</div>
                      </div>
                    ) : (
                      <div className="flex-normal">
                        <p className="title">{title}</p>
                        {subtitle && <small className="subtitle">{subtitle}</small>}
                      </div>
                    )}
                  </div>
                </div>
              </NewTabOpen>
            )
          )
        )}
      </div>
    </div>
  );
};

export default DynamicSearch;

const NewTabOpener = ({ result, children }) => {
  const { _id, type } = result;
  let linkToPage;
  switch (type) {
    case 'User':
      linkToPage = `/players/${_id}`;
      break;
    case 'Player':
      linkToPage = `/game-profile/${_id}`;
      break;
    case 'Organisation':
      linkToPage = `/organisation/${_id}`;
      break;
    case 'Team':
      linkToPage = `/teams/${_id}`;
      break;
    case 'Coach':
      linkToPage = `/coaches/${_id}`;
      break;
    default:
  }

  return (
    <Link to={linkToPage} className="redirect-link">
      {children}
    </Link>
  );
};
