import React, { useEffect, useState } from 'react';
import { Card, Nav } from 'react-bootstrap';
import SetPlayers from './SetPlayers';
import { computeSegEndResizable } from '@fullcalendar/react';
import { Button, Form, Spinner } from 'react-bootstrap';
import './_setplayers.scss';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import { BsExclamationCircle } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { getCompetitionsMatches } from '../../../../../redux/action/CompetitonData';
import { useSelector } from 'react-redux';

const MatcheRescheduleModal = ({
  show,
  onHide,
  match,
  updateMatches,
  league,
  flag,
  id,
  isScrim,
  game = null,
  updateMatchRecords
}) => {
  // console.log(match);
  const dispatch = useDispatch();
  const competitionData = useSelector((state) => state.COMPETITION.Competition.multiStageLeague);
  const submitForm = () => {
    // const broadCastedChannel = competitionData?.streams?.find((strm, index) => strm._id == stream);

    const data = {
      match: {
        ...match,
        isLockReschedule: !match.isLockReschedule
      }
    };
console.log(data);
    Axios.put(
      `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competitionData._id}/matches/editMatch/${match._id}`,
      data
    )
      .then(({ data }) => {
        let query = { status: '', limit: '100', page: '1' };
        dispatch(getCompetitionsMatches({ id: competitionData._id, query }));
        toast.success('Updated Successfully');
        onHide();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  return (
    <Modal
      isOpen={show}
      onRequestClose={onHide}
      className="modal editCategory"
      // shouldCloseOnEsc={false}
    >
      <div
        className="modal-close"
        onClick={() => {
          onHide();
        }}>
        <RiCloseLine />
      </div>

      <div className="modal-body">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'nowrap'
          }}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <BsExclamationCircle
              style={{ minHeight: '120px', minWidth: '120px', margin: '6px 0 6px 0' }}
            />
            <div>Do you want to {match.isLockReschedule?'Un':''}Lock rescheduling of this match</div>
          </div>
          <div style={{ borderRadius: 0, border: 0, margin: 0, display: 'flex' }}>
            <Button
              onClick={() => {submitForm()}}
              style={{ maxWidth: '120px', margin: '5px' }}
              variant="success"
              className="button primary">
              Yes
            </Button>
            <Button
              onClick={() => {
                onHide();
              }}
              style={{ maxWidth: '120px', margin: '5px' }}
              variant="danger"
              className="button primary">
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MatcheRescheduleModal;
