import { SET_LANGUAGE, SET_ALL_LANGUAGES } from './types';

export const setLanguage = (value) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: value
  });
};
export const setLanguagesAction = (arr) => (dispatch) => {
  dispatch({
    type: SET_ALL_LANGUAGES,
    payload: arr
  });
};
