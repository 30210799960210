import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './_folder.scss';
import { FaFolder } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useState } from 'react';
import ShareOptions from '../ShareOptions/ShareOptions';
import { useEffect } from 'react';
import { usePermissions } from '../../../utils/usePermissions';
import { useRef } from 'react';
import useContextMenu from '../ContextMenu/hooks/useContextMenu';
import {
  getFolderOrCompetitonPermissions,
  getOrganiserPermissions
} from '../../../helpers/permissionsHelper';
import ContextMenu from '../ContextMenu/ContextMenu';
import Options from '../ThreeDots/Options/Options';
import ThreeDots from '../ThreeDots/ThreeDots';
function Folder(
  {
    user,
    folder,
    index,
    name = 'Folder',
    permissions = [],
    folderId = '12312323',
    disabled = true,
    type = 'FOLDER',
    handleClearSelection,
    onClickHandler = () => {},
    handleRouting = () => {},
    onHoverHandler = () => {},
    isActive = false
  },
  props
) {
  const [newname, setNewname] = useState();
  const { isVisible, x, y, showMenu, hideMenu } = useContextMenu();
  const { userPermissions, showDots } = useMemo(
    () => getFolderOrCompetitonPermissions(user, permissions),
    [permissions]
  );
  // const permission = usePermissions(folderId);
  const handleContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('This ContextMenu is called', folderId);
    handleClearSelection(folderId, 'folders');
    // onClickHandler(competition?._id,"competitions");
    showMenu(event);
  };
  return (
    <div
      id={folderId}
      key={index}
      className={`card-panel-1 folder-1 multiselectItem-cls ${
        !isActive && disabled ? '' : 'selected-folder'
      }`} //added multiselectItem-cls class for multiselect so don;t remove it.
      onMouseEnter={()=>onHoverHandler(folder?._id,'IN')}
      onMouseLeave={()=>onHoverHandler(folder?._id,'OUT')}
      onClick={(e) => {
        onClickHandler(e, folderId, 'folders');
      }}
      onContextMenu={handleContextMenu}
      onDoubleClick={(e) => {
        e.stopPropagation();
        localStorage.setItem('lastSelected', folderId);
        handleRouting(folderId, 'FOLDER');
      }}>
      <div className='for-tippy-bg' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FaFolder style={{ marginRight: '5px' }} size={25} />
        <div className="texteclips cap" style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              textOverflow: 'ellipsis',
              fontWeight: '600',
              fontSize: '9px',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {newname ? newname : name ? name : 'Folder'}
          </span>
        </div>
        {showDots && (
          <ThreeDots
            userPermissions={userPermissions}
            user={user}
            type={type}
            disabled={disabled}
            folder={folder}
            onFirstClick={(e) => onClickHandler(e, folderId, 'folders')}
            isFolder={true}
          />
        )}
      </div>
      {showDots && isVisible && !disabled && (
        <ContextMenu x={x} y={y} onClose={hideMenu} isVisible={isVisible}>
          <Options type={type} userPermissions={userPermissions} folder={folder} />
        </ContextMenu>
      )}
    </div>
  );
}

export default Folder;
