import React, { createRef, useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  HiDuplicate,
  HiInboxIn,
  HiKey,
  HiOutlineMinusCircle,
  HiOutlinePlusCircle,
  HiOutlineSwitchHorizontal,
  HiRefresh,
  HiX
} from 'react-icons/hi';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import Modal from 'react-modal';
import './set-score-modal.scss';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { setScoreAction, getCompetitionsMatches } from '../../../redux/action/CompetitonData';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { InformationLogo, ToggleSlider } from '../../_common';
import Tippy from '@tippyjs/react';

export default function SetScoreModal({ open, onClose, matchData, competitionId, gameName }) {
  const elRefs = React.useRef([]);
  const [scoreError, setscoreError] = useState(false);
  const [team1Error, setTeam1Error] = useState(false);
  const [team2Error, setTeam2Error] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [BestOf, setBestOf] = useState(0);
  const [payload, setPayload] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [resettingMatch, setResettingMatch] = useState(false);
  const [genCodes, setGenCodes] = useState(false);
  const [isScrim, setIsScrim] = useState(false);
  const [MatchData, setMatchData] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setMatchData({ ...matchData });
  }, [matchData]);
  useEffect(() => {
    let tournamentCodes = matchData?.lolData?.tournamentCodes
      ? matchData?.lolData?.tournamentCodes
      : [];
    if (elRefs.current.length !== tournamentCodes.length) {
      // add or remove refs
      elRefs.current = Array(tournamentCodes.length)
        .fill()
        .map((_, i) => elRefs.current[i] || createRef());
    }
    setInitialPayload();
  }, [matchData]);
  useEffect(() => {
    let total = (payload.t1Score ?? 0) + (payload.t2Score ?? 0);
    let bestOf = matchData.bestOf;
    let scoreLimit = bestOf / 2 + 1;
    if (payload.t1Score > scoreLimit) {
      setErrorMsg(`This team’s score is too high for a best of ${bestOf} match.`);
      setTeam1Error(true);
    } else {
      setTeam1Error(false);
    }
    if (payload.t2Score > scoreLimit) {
      setErrorMsg(`This team’s score is too high for a best of ${bestOf} match.`);
      setTeam2Error(true);
    } else {
      setTeam2Error(false);
    }
    if (total && total > BestOf) {
      setscoreError(true);
      setErrorMsg(`The scores are higher than possible for a best of ${bestOf} match.`);
    } else {
      setscoreError(false);
    }
  }, [payload]);
  const setInitialPayload = () => {
    let payload = {
      t1Score: matchData.t1Score ?? 0,
      t2Score: matchData.t2Score ?? 0,
      t1FF: matchData.t1FF ?? false,
      t2FF: matchData.t2FF ?? false,
      scoreReason: matchData.scoreReason ?? '',
      roundScore: matchData.roundScore ?? false
    };
    setBestOf(matchData.bestOf);
    setPayload({ ...payload });
    return () => setPayload({});
  };

  const onManuallyFetch = async () => {
    setFetchingData(true);

    try {
      const { data } = await Axios.get(
        `${
          gameName === 'LoL'
            ? process.env.REACT_APP_LOL_API
            : gameName === 'CSGO'
            ? process.env.REACT_APP_CSGO_API
            : process.env.REACT_APP_CORE_API
        }/api/matches/${matchData._id}/manuallyGetData?type=3`
      );
      dispatch(
        getCompetitionsMatches({
          id: competitionId,
          query: { status: '', limit: '100', page: '1' }
        })
      );
      // updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
      // if (!isScrim) setLeague((league) => ({ ...league, teams: data.updatedLeague.teams }));
      // setT1Score(data.updatedMatch.t1Score);
      // setT2Score(data.updatedMatch.t2Score);
      toast.success('Manually fetched match data from RIOT');
    } catch (e) {
      toast.error('RIOT could not get data/find any data from the tournament codes');
    }

    setFetchingData(false);
  };

  const onGenTournamentCodes = async () => {
    setGenCodes(true);
    // setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(match._id);

    try {
      // debugger
      const { data } = await Axios.post(
        `${process.env.REACT_APP_LOL_API}/api/matches/${matchData._id}/generateTournamentCodes${
          isScrim ? '?type=Scrim' : ''
        }`
      );

      // updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
      dispatch(
        getCompetitionsMatches({
          id: competitionId,
          query: { status: '', limit: '100', page: '1' }
        })
      );
      // setTournamentCodes(data.updatedMatch.lolData.tournamentCodes);

      toast.success('Manually generated tournament codes 😍');
    } catch (e) {
      toast.error(e?.response?.data?.msg || 'RIOT could not generate tournament codes');
    }

    setGenCodes(false);
    // setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
  };

  const onResetMatch = async () => {
    setResettingMatch(true);

    try {
      const { data } = await Axios.put(
        `${
          gameName === 'LoL'
            ? process.env.REACT_APP_LOL_API
            : gameName === 'CSGO'
            ? process.env.REACT_APP_CSGO_API
            : process.env.REACT_APP_CORE_API
        }/api/matches/${matchData._id}/resetData`
      );
      // console.log('reset data -- ', data);
      dispatch(
        getCompetitionsMatches({
          id: competitionId,
          query: { status: '', limit: '100', page: '1' }
        })
      );

      // updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));

      // if (!isScrim) setLeague((league) => ({ ...league, teams: data.updatedLeague.teams }));
      // setT1Score(0);
      // setT2Score(0);
      // setT1FF(false);
      // setT2FF(false);
      // setScoreReason("");

      toast.success('Successfully reset match!');
    } catch (e) {
      toast.error('There was a problem resetting this match 😞');
    }

    setResettingMatch(false);
  };
  const handleScoreClicks = ({ name, action }) => {
    setPayload((state) => {
      switch (name) {
        case 't1Score':
          return {
            ...state,
            t1Score:
              action === 'PLUS' ? state.t1Score + 1 : state.t1Score - 1 < 0 ? 0 : state.t1Score - 1
          };
        case 't2Score':
          return {
            ...state,
            t2Score:
              action === 'PLUS' ? state.t2Score + 1 : state.t2Score - 1 < 0 ? 0 : state.t2Score - 1
          };
        default:
          return state;
      }
    });
  };
  const handleScoreChange = ({ name, value }) => {
    setPayload((state) => {
      switch (name) {
        case 't1Score':
          return {
            ...state,
            t1Score: Number(value)
          };
        case 't2Score':
          return {
            ...state,
            t2Score: Number(value)
          };
        case 'scoreReason':
          return {
            ...state,
            scoreReason: value
          };
        default:
          return state;
      }
    });
  };
  const handleFFChange = ({ name }) => {
    setPayload((state) => {
      switch (name) {
        case 't1FF':
          return {
            ...state,
            t1FF: !state.t1FF
          };
        case 't2FF':
          return {
            ...state,
            t2FF: !state.t2FF
          };
        default:
          return state;
      }
    });
  };
  const handleSubmit = (matchId, payload) => {
    setPayload(payload);
    dispatch(setScoreAction({ matchId, payload, competitionId, gameName }));
  };
  const handleTeamsSwitch = () => {
    // switchMatchTeams();
    let _payload = { ...payload };
    _payload.isSwapTeam = _payload.t1Score;
    _payload.t1Score = _payload.t2Score;
    _payload.t2Score = _payload.isSwapTeam;
    _payload.roundScore = payload.roundScore.map((el) => {
      let tempSide = el.leftSide.score;
      el.leftSide.score = el.rightSide.score;
      el.rightSide.score = tempSide;
      return el;
    });
    // if (payload?.isSwapTeam) {
    //   _payload.isSwapTeam = false;
    // } else {
    //   _payload.isSwapTeam = true;
    // }
    delete _payload.isSwapTeam;
    // debugger;
    setPayload(_payload);
  };
  const switchMatchTeams = () => {
    //for switching teams
    if (MatchData) {
      let _modalData = MatchData;
      _modalData.sp3 = _modalData.sp1;
      _modalData.sp1 = _modalData.sp2;
      _modalData.sp2 = _modalData.sp3;
      delete _modalData.sp3;
      setMatchData(_modalData);
    }
  };
  return (
    <Modal isOpen={open} shouldCloseOnEsc={false} className="modal setScore-modal E81232">
      <div>
        <div className="modal-close" onClick={() => onClose(false)}>
          <RiCloseLine color={'white'} />
        </div>
        <div className="modal-header">
          <h2 className="bold uppercase">SET SCORE</h2>
        </div>
        <div className="modal-body">
          <div className="pd-20">
            <Row className="scores-manually">
              <Col className="teams-scores">
                <Col>
                  <Row className="team-name-logo">
                    <span className="imgLogoContainer">
                      <img
                        className=" imgLogoWidth imgLogo  imgLogoCropped"
                        src={
                          MatchData?.t1?.logo
                            ? MatchData?.t1?.logo
                            : 'http://leagues-static.s3.eu-west-2.amazonaws.com/default/league-default.jpg'
                        }
                        alt="Insight Gaming"
                        width={'25px'}
                      />
                    </span>
                    <h5>
                      {' '}
                      {MatchData?.t1
                        ? MatchData?.t1?.name
                        : MatchData?.sp1?.seedPosition
                        ? `Seed ${MatchData?.sp1?.seedPosition}`
                        : 'TBD'}
                    </h5>
                  </Row>
                  <Row style={{ flexWrap: 'nowrap' }}>
                    <button
                      name="t1Score"
                      onClick={(e) => handleScoreClicks({ name: 't1Score', action: 'MINUS' })}>
                      <AiOutlineMinus size={12} />
                    </button>
                    <input
                      style={{ borderColor: scoreError || team1Error ? '#f11857' : '' }}
                      type="number"
                      min={0}
                      name="t1Score"
                      onChange={(e) => handleScoreChange(e.target)}
                      value={payload?.t1Score ?? 0}
                    />
                    <button
                      name="t1Score"
                      onClick={(e) => handleScoreClicks({ name: 't1Score', action: 'PLUS' })}>
                      <AiOutlinePlus size={12} />
                    </button>
                  </Row>
                  {(scoreError || team1Error) && (
                    <span style={{ fontSize: '14px', color: '#f11857', textAlign: 'left' }}>
                      The scores are higher than possible for a best of {matchData.bestOf} match.
                    </span>
                  )}
                  <Row>
                    <input
                      type="checkbox"
                      onChange={(e) => handleFFChange(e.target)}
                      name="t1FF"
                      checked={payload.t1FF ?? false}
                    />
                    <h4>FF</h4>
                  </Row>
                </Col>
                <Col className="swap-btn" style={{ color: '#68717e' }}>
                  <HiOutlineSwitchHorizontal
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      handleTeamsSwitch();
                    }}
                    size={22}
                  />
                </Col>
                <Col>
                  <Row className="team-name-logo">
                    <span className="imgLogoContainer">
                      <img
                        className=" imgLogoWidth imgLogo  imgLogoCropped"
                        src={
                          MatchData?.t2?.logo
                            ? MatchData?.t2?.logo
                            : 'http://leagues-static.s3.eu-west-2.amazonaws.com/default/league-default.jpg'
                        }
                        alt="Insight Gaming"
                        width={'25px'}
                      />
                    </span>
                    <h5>
                      {' '}
                      {MatchData?.t2
                        ? MatchData?.t2?.name
                        : MatchData?.sp2?.seedPosition
                        ? `Seed ${MatchData?.sp2?.seedPosition}`
                        : 'TBD'}
                    </h5>
                  </Row>
                  <Row style={{ flexWrap: 'nowrap' }}>
                    <button
                      onClick={(e) => handleScoreClicks({ name: 't2Score', action: 'MINUS' })}>
                      <AiOutlineMinus size={12} />
                    </button>
                    <input
                      style={{ borderColor: scoreError || team2Error ? '#f11857' : '' }}
                      type="number"
                      min={0}
                      name="t2Score"
                      onChange={(e) => handleScoreChange(e.target)}
                      value={payload?.t2Score ?? 0}
                    />
                    <button onClick={(e) => handleScoreClicks({ name: 't2Score', action: 'PLUS' })}>
                      <AiOutlinePlus size={12} />
                    </button>
                  </Row>
                  {(scoreError || team2Error) && (
                    <span style={{ fontSize: '14px', color: '#f11857', textAlign: 'left' }}>
                      The scores are higher than possible for a best of {matchData.bestOf} match.
                    </span>
                  )}
                  <Row>
                    <input
                      type="checkbox"
                      onChange={(e) => handleFFChange(e.target)}
                      name="t2FF"
                      checked={payload.t2FF ?? false}
                    />
                    <h4>FF</h4>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
          {payload?.roundScore && (
            <SideAndOutcomes
              setPayload={setPayload}
              roundScores={payload.roundScore}
              gameName={gameName}
              payload={payload}
              matchData={MatchData}
            />
          )}
          <div className="pd-20">
            <Row>
              <Col
                className="notes-div"
                style={{ borderTop: '1px solid #22262e', paddingTop: '15px' }}>
                <div className="note">NOTE</div>
                <input
                  type="text"
                  placeholder="Please describe in a few words the reason for score changes"
                  defaultValue={payload.scoreReason ?? ''}
                  name="scoreReason"
                  onChange={(e) => handleScoreChange(e.target)}
                />
              </Col>
            </Row>
            {gameName === 'LoL' && (
              <>
                <Row>
                  <Col className="quick-actions">
                    <h4>ACTIONS</h4>
                    <Row>
                      <Col>
                        <Row className="pb-3">
                          <button
                            disabled={genCodes}
                            className="generate"
                            onClick={() => onGenTournamentCodes()}>
                            <HiKey /> <span>GENERATE TOURNAMENT CODES</span>
                          </button>
                        </Row>
                        <Row>
                          <button
                            disabled={fetchingData}
                            onClick={() => onManuallyFetch()}
                            className="generate">
                            <HiInboxIn />
                            <span>MANUALLY FETCH SCORE</span>
                          </button>
                          <button
                            disabled={resettingMatch}
                            onClick={() => onResetMatch()}
                            className="reset-match">
                            <HiRefresh />
                            <span> RESET MATCH</span>
                          </button>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="tournaments-codes-row">
                  {matchData.lolData &&
                    !!matchData?.lolData?.tournamentCodes.length &&
                    matchData?.lolData?.tournamentCodes.map((code, i) => (
                      <>
                        <p className="t-codes-title">Tournament Codes</p>
                        <Form.Group key={i}>
                          <Form.Label className="d-block text-secondary">
                            <small>Map {i + 1}:</small>
                          </Form.Label>
                          <div className="d-flex">
                            <Form.Control
                              ref={elRefs.current[i]}
                              readOnly
                              style={{
                                fontFamily: 'monospace',
                                fontSize: '15px',
                                background: '#22262e'
                              }}
                              size="sm"
                              type="text"
                              value={code}
                            />
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="tooltip">Copy</Tooltip>}>
                              <Button
                                variant="link"
                                style={{ paddingRight: '15px', border: 'none' }}
                                size="sm"
                                className="text-primary copy-code-button"
                                onClick={() => {
                                  elRefs.current[i].current.focus();
                                  elRefs.current[i].current.select();
                                  document.execCommand('copy');
                                  toast.success('Copied to clipboard');
                                }}>
                                {/* <FontAwesomeIcon icon={faCopy} /> */}
                                <HiDuplicate size="22" color="white" /> <span>COPY</span>
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </Form.Group>
                      </>
                    ))}
                </Row>{' '}
              </>
            )}
          </div>
        </div>
        <div className="mt-4 modal-footer footer-horizontal-line">
          <div className="col-6">
            <Row className="footer-buttons">
              <button
                type="submit"
                className="cancel-changes-btn"
                style={{
                  marginRight: '10px',
                  font: 'normal normal bold 12px/15px Montserrat',
                  color: 'white'
                }}
                onClick={() => onClose(false)}>
                {/* <span>CANCEL</span> */}
                CANCEL
              </button>
              <button
                type="submit"
                style={{
                  marginRight: '10px',
                  font: 'normal normal bold 12px/15px Montserrat',
                  color: 'white',
                  width: '53%'
                }}
                className="save-changes-btn"
                onClick={() => {
                  // debugger
                  handleSubmit(matchData._id, payload);
                }}>
                {/* <span>SAVE CHANGES</span> */}
                SAVE CHANGES
              </button>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const SideAndOutcomes = ({ gameName, roundScores, setPayload, payload, matchData }) => {
  const [roundInfo, setRoundInfo] = useState([]);
  let leftSideName =
    gameName === 'LoL' ? 'Blue Side' : gameName === 'CSGO' ? 'Terrorists' : 'Attackers';
  let rightSideName =
    gameName === 'LoL' ? 'Red Side' : gameName === 'CSGO' ? 'Counter Terrorists' : 'Defenders';
  let showToggles = gameName === 'LoL';
  let informationLogoText =
    gameName === 'LoL'
      ? 'Declare the sides for each game as well as the winner.'
      : 'Declare the starting sides for each game as well as the round scores.';
  let _heading = gameName === 'LoL' ? 'Sides and outcomes' : 'Starting sides and round scores';
  useEffect(() => {
    ColorCalculations(payload, matchData, gameName);
  }, [payload]);
  const ColorCalculations = ({ t1Score, t2Score, roundScore, ...rest }, matchData, gameName) => {
    let _yellow = '#d5901f';
    let _red = '#f11857';
    let _blue = '#3eabf0';
    let _total = t1Score + t2Score;
    let _t1Score = 0;
    let _t2Score = 0;
    let _team1 = matchData?.sp1?.team?._id ?? matchData?.sp1?.seedPosition;
    let _team2 = matchData?.sp2?.team?._id ?? matchData?.sp2?.seedPosition;
    const _arr = roundScore.map((el, index) => {
      const { leftSide, rightSide } = el;
      const team1 = leftSide?.team?._id ?? leftSide?.seed;
      const team2 = rightSide?.team?._id ?? rightSide?.seed;
      const roundPlayedInd = _total;
      let _t1Score = 0;
      let _t2Score = 0;
    
      const Obj = {
        tagColor: _blue,
        content: '',
        gamePlayed: true,
        t1Color: false,
        t2Color: false,
      };
    
      if (leftSide?.score > 0 || rightSide?.score > 0) {
        if (leftSide.score > rightSide.score) {
          if (team1 === _team1) {
            _t1Score += 1;
          } else {
            _t2Score += 1;
          }
        } else if (rightSide.score > leftSide.score) {
          if (team2 === _team2) {
            _t2Score += 1;
          } else {
            _t1Score += 1;
          }
        }
        if (index >= roundPlayedInd) {
          Obj.tagColor = _red;
          Obj.content = "Result set for a match that hasn't been played.";
          Obj.gamePlayed = false;
        } else {
          if (_t1Score > t1Score || _t2Score > t2Score) {
            if (roundPlayedInd === 0 && index === 0) {
              Obj.tagColor = _red;
              Obj.content = "Result set for a match that hasn't been played.";
            } else {
              Obj.tagColor = _yellow;
              Obj.content = 'Game results do not match score.';
              if (_t1Score > t1Score) {
                _t1Score -= 1;
              } else {
                _t2Score -= 1;
              }
            }
            Obj.gamePlayed = false;
          }
        }
        if (leftSide.score === rightSide.score) {
          if (index > roundPlayedInd) {
            Obj.tagColor = _red;
            Obj.content = "Result set for a match that hasn't been played.";
            Obj.gamePlayed = false;
          } else {
            Obj.tagColor = _yellow;
            Obj.content = 'Result indicates a tie.';
          }
        }
      } else {
        if (index < roundPlayedInd) {
          Obj.tagColor = _yellow;
          Obj.content = 'Game results do not match score.';
          Obj.gamePlayed = false;
        } else {
          Obj.gamePlayed = false;
          Obj.tagColor = null;
        }
      }
    
      return Obj;
    });
    
    // let _arr = [];
    // roundScore.forEach((el, index) => {
    //   let Obj = {
    //     tagColor: _blue,
    //     content: '',
    //     gamePlayed: true,
    //     t1Color: false,
    //     t2Color: false
    //   };
    //   let roundPlayedInd = _total;
    //   let { leftSide, rightSide } = el;
    //   let _team1 = leftSide?.team?._id ?? leftSide?.seed;
    //   let _team2 = rightSide?.team?._id ?? rightSide?.seed;
    //   if (leftSide?.score > 0 || rightSide?.score > 0) {
    //     if (leftSide.score > rightSide.score) {
    //       if (_team1 === team1) {
    //         _t1Score += 1;
    //       } else {
    //         _t2Score += 1;
    //       }
    //     } else if (rightSide.score > leftSide.score) {
    //       if (_team2 === team2) {
    //         _t2Score += 1;
    //       } else {
    //         _t1Score += 1;
    //       }
    //     }
    //     if (index >= roundPlayedInd) {
    //       Obj.tagColor = _red;
    //       Obj.content = "Result set for a match that hasn't been played.";
    //       Obj.gamePlayed = false;
    //     } else {
    //       if (_t1Score > t1Score) {
    //         if (roundPlayedInd === 0 && index === 0) {
    //           Obj.tagColor = _red;
    //           Obj.content = "Result set for a match that hasn't been played.";
    //           Obj.gamePlayed = false;
    //         } else {
    //           _t1Score !== 0 ? (_t1Score -= 1) : (_t1Score = 0);
    //           Obj.tagColor = _yellow;
    //           Obj.content = 'Game results do not match score.';
    //         }
    //       } else if (_t2Score > t2Score) {
    //         if (roundPlayedInd === 0 && index === 0) {
    //           Obj.tagColor = _red;
    //           Obj.content = "Result set for a match that hasn't been played.";
    //           Obj.gamePlayed = false;
    //         } else {
    //           _t2Score !== 0 ? (_t2Score -= 1) : (_t2Score = 0);
    //           Obj.tagColor = _yellow;
    //           Obj.content = 'Game results do not match score.';
    //         }
    //       }
    //     }
    //     if (leftSide.score === rightSide.score) {
    //       if (index > roundPlayedInd) {
    //         Obj.tagColor = _red;
    //         Obj.content = "Result set for a match that hasn't been played.";
    //         Obj.gamePlayed = false;
    //       } else {
    //         Obj.tagColor = _yellow;
    //         Obj.content = 'Result indicates a ties.';
    //       }
    //     }
    //   } /// end of Score is more than zero
    //   else {
    //     if (index < roundPlayedInd) {
    //       Obj.tagColor = _yellow;
    //       Obj.content = 'Game results do not match score.';
    //       Obj.gamePlayed = false;
    //     } else {
    //       Obj.gamePlayed = false;
    //       Obj.tagColor = null;
    //     }
    //   }

    //   _arr.push(Obj);
    // });
    setRoundInfo(_arr);
  };

  const handleRoundToggle = ({ checked }, { name, id }) => {
    let index = 0;
    setPayload((state) => {
      switch (name) {
        case 'leftSide':
          index = state.roundScore.findIndex((el) => el._id === id);
          if (state.roundScore[index].rightSide?.score && checked) {
            state.roundScore[index].rightSide.score = 0;
          }
          state.roundScore[index].leftSide.score = Number(checked);
          return {
            ...state,
            roundScore: state.roundScore
          };
        case 'rightSide':
          index = state.roundScore.findIndex((el) => el._id === id);
          if (state.roundScore[index].leftSide?.score && checked) {
            state.roundScore[index].leftSide.score = 0;
          }
          state.roundScore[index].rightSide.score = Number(checked);
          return {
            ...state,
            roundScore: state.roundScore
          };
        default:
          return state;
      }
    });
  };
  const handleRoundSwitch = (id) => {
    let index = 0;
    let obj;
    setPayload((state) => {
      return {
        ...state,
        roundScore: state.roundScore.map((el) => {
          if (el._id === id) {
            let tempSide = el.leftSide;
            el.leftSide = el.rightSide;
            el.rightSide = tempSide;
            return el;
          } else {
            return el;
          }
        })
      };
    });
  };
  const handleScoreClicks = ({ name, action, id }) => {
    setPayload((state) => {
      let index = state.roundScore.findIndex((el) => el._id === id);
      switch (name) {
        case 'right-side':
          if (action === 'PLUS') {
            state.roundScore[index].rightSide.score =
              state.roundScore[index].rightSide?.score !== 1000
                ? state.roundScore[index].rightSide.score + 1
                : state.roundScore[index].rightSide?.score;
          } else {
            state.roundScore[index].rightSide.score =
              state.roundScore[index].rightSide?.score !== 0
                ? state.roundScore[index].rightSide.score - 1
                : state.roundScore[index].rightSide?.score;
          }
          return {
            ...state,
            roundScore: state.roundScore
          };
        case 'left-side':
          if (action === 'PLUS') {
            state.roundScore[index].leftSide.score =
              state.roundScore[index].leftSide?.score !== 1000
                ? state.roundScore[index].leftSide.score + 1
                : state.roundScore[index].leftSide?.score;
          } else {
            state.roundScore[index].leftSide.score =
              state.roundScore[index].leftSide.score !== 0
                ? state.roundScore[index].leftSide.score - 1
                : state.roundScore[index].leftSide?.score;
          }
          return {
            ...state,
            roundScore: state.roundScore
          };
        default:
          return state;
      }
    });
  };
  const handleAddRoundScoreChange = ({ name, value }, _id) => {
    setPayload((state) => {
      let index = 0;
      switch (name) {
        case 'leftSide':
          index = state.roundScore.findIndex((el) => el._id === _id);
          state.roundScore[index].leftSide.score = Number(value);
          return {
            ...state,
            roundScore: state.roundScore
          };
        case 'rightSide':
          index = state.roundScore.findIndex((el) => el._id === _id);
          state.roundScore[index].rightSide.score = Number(value);
          return {
            ...state,
            roundScore: state.roundScore
          };
        default:
          return state;
      }
    });
  };
  const style = {
    display: 'inline',
    width: 'fit-content',
    margin: '0px 5px 3px 5px'
  };
  return (
    <Row key={Math.random() % 5} style={{ paddingBottom: '15px' }}>
      <Row className="round-score-heading">
        <>
          <h4 style={{ textTransform: 'uppercase', padding: '0px 5px 0px 0px' }}>{_heading} </h4>
          <InformationLogo content={informationLogoText} style={style} />
        </>
      </Row>
      {roundScores.map(({ leftSide, rightSide, _id }, index) => {
        let leftColor = leftSide?.score > rightSide?.score ? true : false;
        let rightColor = leftSide?.score < rightSide?.score ? true : false;
        if (leftSide?.score === rightSide?.score && leftSide?.score !== 0) {
          leftColor = true;
          rightColor = true;
        }
        let gameNo = index + 1;
        let _content = roundInfo[index] && roundInfo[index].content;
        let _tagColor = roundInfo[index] && roundInfo[index].tagColor;
        return (
          <Row key={index} className="round-score-row E81232">
            <Col className="round-score-col-1">
              <Tippy content={_content} disabled={_content === ''} placement="top">
                <div className="game-capsule" style={{ backgroundColor: _tagColor }}>
                  <span>Game {gameNo}</span>
                </div>
              </Tippy>
            </Col>
            <Col className="round-score-col-team" key={`leftSide${_id}`}>
              <Row className="team-name">
                {leftSide?.team?.name
                  ? leftSide?.team?.name
                  : leftSide?.seed
                  ? `Seed ${leftSide?.seed}`
                  : 'TBD'}
              </Row>
              <Row className="team-side">{leftSideName}</Row>

              {showToggles ? (
                <Row>
                  <ToggleSlider
                    // <CheckboxSlider
                    unique={`leftSide${_id}`}
                    // data={leftSide}
                    toggleColor={_tagColor}
                    checked={Boolean(leftSide?.score)}
                    onChange={(e, data) => {
                      handleRoundToggle(e.target, { name: 'leftSide', id: _id });
                    }}
                    label_style={'height-auto'}
                    label="Winner"
                    isSetScore
                  />
                </Row>
              ) : (
                <Row className="scores-manually-2" style={{ flexWrap: 'nowrap' }}>
                  <button
                    onClick={(e) => {
                      handleScoreClicks({ name: 'left-side', action: 'MINUS', id: _id });
                    }}>
                    <AiOutlineMinus size={12} />
                  </button>
                  <input
                    type="number"
                    name="leftSide"
                    style={leftColor ? { border: `1px solid ${_tagColor}` } : {}}
                    min={0}
                    onChange={(e) => handleAddRoundScoreChange(e.target, _id)}
                    value={leftSide?.score ?? 0}
                  />
                  <button
                    onClick={(e) => {
                      handleScoreClicks({ name: 'left-side', action: 'PLUS', id: _id });
                    }}>
                    <AiOutlinePlus size={12} />
                  </button>
                </Row>
              )}
            </Col>
            <Col className="round-score-col-2">
              <Tippy placement="top" content="Switch Teams" animation="shift-toward">
                <div style={{ paddingRight: '21px' }}>
                  <HiOutlineSwitchHorizontal
                    style={{ cursor: 'pointer', color: '#68717e' }}
                    onClick={(e) => {
                      handleRoundSwitch(_id);
                    }}
                    size={22}
                  />
                </div>
              </Tippy>
            </Col>
            <Col
              className="round-score-col-team"
              key={`rightSide${_id}`}
              style={{ paddingLeft: '40px' }}>
              <Row className="team-name">
                {rightSide?.team?.name
                  ? rightSide?.team?.name
                  : rightSide?.seed
                  ? `Seed ${rightSide?.seed}`
                  : 'TBD'}
              </Row>
              <Row className="team-side">{rightSideName}</Row>
              {showToggles ? (
                <Row>
                  <ToggleSlider
                    // <CheckboxSlider
                    unique={`rightSide${_id}`}
                    checked={Boolean(rightSide?.score)}
                    toggleColor={_tagColor}
                    onChange={(e) => {
                      handleRoundToggle(e.target, { name: 'rightSide', id: _id });
                    }}
                    label_style={'height-auto'}
                    label="Winner"
                  />
                </Row>
              ) : (
                <Row className="scores-manually-2" style={{ flexWrap: 'nowrap' }}>
                  <button
                    onClick={(e) => {
                      handleScoreClicks({ name: 'right-side', action: 'MINUS', id: _id });
                    }}>
                    <AiOutlineMinus size={12} />
                  </button>
                  <input
                    type="number"
                    name="rightSide"
                    style={rightColor ? { border: `1px solid ${_tagColor}` } : {}}
                    min={0}
                    onChange={(e) => handleAddRoundScoreChange(e.target, _id)}
                    value={rightSide?.score ?? 0}
                  />
                  <button
                    onClick={(e) => {
                      handleScoreClicks({ name: 'right-side', action: 'PLUS', id: _id });
                    }}>
                    <AiOutlinePlus size={12} />
                  </button>
                </Row>
              )}
            </Col>
          </Row>
        );
      })}
    </Row>
  );
};
