import React, { useMemo, useState } from 'react';
import Axios from 'axios';
import { SimplePageWithTabs, GridView, ListView } from '@common';
import { HiViewGrid, HiViewList } from 'react-icons/hi';
import BreadcrumbView from '../../_common/OrganizerBreadcrumb/OrganizerBreadcrumb';
import LastEditItem from '../../_common/LastEditItem/LastEditItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getFolders, getOrganizer, setFolderOnly } from '../../../redux/action/competitionActions';
import { useDispatch, useSelector } from 'react-redux';
import './breadcrum.css';
import { getSortByLastModified, getSortedObjByName } from './utils';
import { setSelctedFolders } from '../../../redux/action/dragNDropActions';
import DeleteItems from '../../_modals/OrganizerPopup/deleteitems/delete';
function MyCompetitions() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, folderId } = useParams();
  useEffect(() => {
    id && dispatch(getOrganizer(id));
  }, [id]);
  useEffect(() => {
    folderId && dispatch(getFolders(folderId));
  }, [folderId]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(0);
  const [nextSelectedIndex, setNextSelectedIndex] = useState(0);
  const [deletePopup, setDeletePopup] = useState(false);

  const [lastAction, setLastAction] = useState(null);
  const [folder, setFolder] = useState({});
  const selectedFolders = useSelector((state) => state.DRAGNDROP.SelectedFolders ?? []);
  const foldera = useSelector((state) =>
    state.FOLDERS.Folders ? state.FOLDERS.Folders : { folders: [], competitions: [],organizerRoles:[] }
  );
  const items = useSelector((state) =>
    state.FOLDERS.Breadcrumbs
      ? [{ name: 'My Competitons', _id: '0001' }, ...state.FOLDERS.Breadcrumbs]
      : []
  );
  useEffect(() => {
    return localStorage.removeItem('lastSelected');
  }, []);

  useEffect(() => {
    setFolder(foldera);
  }, [foldera]);
  useEffect(() => {
    let item = localStorage.getItem('lastSelected');
    if (foldera.folders) {
      let ind = foldera.folders.findIndex((el) => el._id === item);
      if (ind >= 0) {
        dispatch(setSelctedFolders([item]));
      }
    }
  }, [foldera]);
  // const arrangFoldersAndCompetitons = (obj)=>{
  //   _obj = getObjectType(obj)
  // }

  // const handleFolderSelection = (index, cmdKey, shiftKey, ctrlKey, comp = '') => {
  //   // debugger
  //   let newSelectedFields = [];
  //   let fields = foldera.folders.map((el) => el._id);
  //   const competition = foldera?.competitions?.map(el => el._id)
  //   fields = fields.concat(competition)
  //   const field = index < 0 ? '' : comp.length > 0 ? competition[index] : fields[index];
  //   const newLastSelectedIndex = index;

  //   if (!cmdKey && !shiftKey & !ctrlKey) {
  //     if (selectedFolders.includes(field)) {
  //       newSelectedFields = [];
  //     } else {
  //       newSelectedFields = [field];
  //     }
  //   } else if (shiftKey) {
  //     if (lastSelectedIndex >= index) {
  //       newSelectedFields = [].concat.apply(
  //         selectedFolders,
  //         fields.slice(index, lastSelectedIndex)
  //       );
  //     } else {
  //       newSelectedFields = [].concat.apply(
  //         selectedFolders,
  //         fields.slice(lastSelectedIndex + 1, index + 1)
  //       );
  //     }
  //   } else if (cmdKey) {
  //     const foundIndex = selectedFolders.findIndex((f) => f === field);
  //     // If found remove it to unselect it.
  //     if (foundIndex >= 0) {
  //       newSelectedFields = [
  //         ...selectedFolders.slice(0, foundIndex),
  //         ...selectedFolders.slice(foundIndex + 1)
  //       ];
  //     } else {
  //       newSelectedFields = [...selectedFolders, field];
  //     }
  //   } else if (ctrlKey) {
  //     // debugger
  //     let exists = selectedFolders.includes(field);
  //     if (exists) {
  //       newSelectedFields = [...selectedFolders.filter((id) => id !== field)];
  //     } else {
  //       newSelectedFields = [...selectedFolders, field];
  //     }
  //   }
  //   const finalList = fields ? fields.filter((f) => newSelectedFields.find((a) => a === f)) : [];
  //   console.log({newSelectedFields, finalList });
  //   dispatch(setSelctedFolders(finalList));
  //   setLastSelectedIndex(newLastSelectedIndex);
  //   setNextSelectedIndex(newLastSelectedIndex);
  // };
  // const listViewKey = (e)=>{
  //   let nextIndex = nextSelectedIndex;
  //   let lastIndex = lastSelectedIndex;
  //   let totalIndex = foldera.folders.length - 1 ?? 0;
  //   let _filtered = [];
  //   let _newFolders;

  //   let fields = foldera.folders.map((el) => el._id);
  //   if (e.ctrlKey && e.keyCode === 65) {
  //     e.preventDefault();
  //     dispatch(setSelctedFolders(fields));
  //   }
  //   // ARROW RIGHT
  //   else if (e.shiftKey && foldera.folders.length > 0 && e.keyCode === 40) {
  //     nextIndex += 1;
  //     if (lastIndex < nextIndex) {
  //       _newFolders = fields.slice(lastIndex, nextIndex + 1);
  //     } else {
  //       _newFolders = fields.slice(nextIndex, lastIndex + 1);
  //     }
  //     dispatch(setSelctedFolders(_newFolders));
  //   }
  //   // ARROW LEFT
  //   else if (e.shiftKey && foldera.folders.length > 0 && e.keyCode === 38) {
  //     nextIndex -= 1;
  //     if (nextIndex < 0) {
  //       nextIndex = 0;
  //     }

  //     if (lastIndex < nextIndex) {
  //       _newFolders = fields.slice(lastIndex, nextIndex + 1);
  //     } else {
  //       _newFolders = fields.slice(nextIndex, lastIndex + 1);
  //     }
  //     dispatch(setSelctedFolders(_newFolders));
  //   }else{
  //     if(e.keyCode === 46){
  //       setDeletePopup(!deletePopup)
  //     }
  //   }

  //   if (nextIndex > -1 && nextIndex <= totalIndex) {
  //     // console.log(lastIndex);
  //     setNextSelectedIndex(nextIndex);
  //   }
  // }
  // const handleKeyDown = (e) => {
  //   let nextIndex = nextSelectedIndex;
  //   let lastIndex = lastSelectedIndex;
  //   let totalIndex = foldera?.folders?.length - 1 ?? 0;
  //   let _filtered = [];
  //   let _newFolders;
  //   let fields = foldera.folders.map((el) => el._id);
  //   // debugger
  //   // console.log(lastIndex)
  //   if (e.ctrlKey && e.keyCode === 65) {
  //     e.preventDefault();
  //     dispatch(setSelctedFolders(fields));
  //   }
  //   // ARROW RIGHT
  //   else if (e.shiftKey && foldera.folders.length > 0 && e.keyCode === 39) {
  //     nextIndex += 1;
  //     if (lastIndex < nextIndex) {
  //       _newFolders = fields.slice(lastIndex, nextIndex + 1);
  //     } else {
  //       _newFolders = fields.slice(nextIndex, lastIndex + 1);
  //     }
  //     dispatch(setSelctedFolders(_newFolders));
  //   }
  //   // ARROW LEFT
  //   else if (e.shiftKey && foldera.folders.length > 0 && e.keyCode === 37) {
  //     nextIndex -= 1;
  //     if (nextIndex < 0) {
  //       nextIndex = 0;
  //     }

  //     if (lastIndex < nextIndex) {
  //       _newFolders = fields.slice(lastIndex, nextIndex + 1);
  //     } else {
  //       _newFolders = fields.slice(nextIndex, lastIndex + 1);
  //     }
  //     dispatch(setSelctedFolders(_newFolders));
  //   }
  //   // ARROW DOWN
  //   else if (e.shiftKey && foldera.folders.length > 0 && e.keyCode === 40) {
  //     nextIndex += 5;
  //     if (lastIndex < nextIndex) {
  //       _newFolders = fields.slice(lastIndex, nextIndex + 1);
  //     } else {
  //       _newFolders = fields.slice(nextIndex, lastIndex + 1);
  //     }
  //     dispatch(setSelctedFolders(_newFolders));
  //   }
  //   //ARROW UP
  //   else if (e.shiftKey && foldera.folders.length > 0 && e.keyCode === 38) {
  //     nextIndex -= 5;
  //     if (lastIndex < nextIndex) {
  //       _newFolders = fields.slice(lastIndex, nextIndex + 1);
  //     } else {
  //       _newFolders = fields.slice(nextIndex, lastIndex + 1);
  //     }
  //     dispatch(setSelctedFolders(_newFolders));
  //   } else {
  //     if(e.keyCode === 46){
  //       setDeletePopup(!deletePopup)
  //     }
  //   }
  //   if (nextIndex > -1 && nextIndex <= totalIndex) {
  //     // console.log(lastIndex);
  //     setNextSelectedIndex(nextIndex);
  //   }
  // };

  const clearItemSelection = () => {
    dispatch(setSelctedFolders([]));
    setLastSelectedIndex(-1);
    // localStorage.removeItem('lastSelected')
  };
  const modifyArray = (folders = [], competitions = []) => {
    let _arr = [];
    if (folders.length > 0) {
      folders.forEach((el, ind) => {
        folders[ind].isfolder = true;
      });
    }
    _arr = [...folders, ...competitions];
    return _arr.flat();
  };
  const newArr = useMemo(() => modifyArray(foldera.folders, foldera.competitions), [foldera]);
  const onSort = (folderObj, type, sort) => {
    let _folderObj;
    switch (type) {
      case 'NAME':
        _folderObj = getSortedObjByName(folderObj, type, sort);
        break;
      case 'TOURNAMENT_DATE':
        _folderObj = getSortedObjByName(folderObj, type, sort);
        break;
      case 'MATCHES':
        _folderObj = getSortedObjByName(folderObj, type, sort);
        break;
      case 'ORG':
        _folderObj = getSortedObjByName(folderObj, type, sort);
        break;
      case 'FOLDER':
        _folderObj = getSortedObjByName(folderObj, type, sort);
        break;
      case 'ORGANIZER':
        _folderObj = getSortedObjByName(folderObj, type, sort);
        break;
      case 'LAST_MODIFIED':
        _folderObj = getSortByLastModified(folderObj, type, sort);
        break;
      default:
    }
    dispatch(setFolderOnly(_folderObj));
  };
  let tabs = {
    'Grid View': (
      <GridView
        parentFolder={{...foldera,folders:foldera?.folders?.length>0?foldera?.folders?.sort((a, b) => a.name.localeCompare(b.name)):[]
        }}
        // handleFolderSelection={handleFolderSelection}
        clearItemSelection={clearItemSelection}
        // handleKeyDown={handleKeyDown}
      />
    ),
    'List View': (
      <ListView
        parentFolder={foldera}
        // handleFolderSelection={handleFolderSelection}
        clearItemSelection={clearItemSelection}
        alldata={newArr ? newArr : []}
        onSort={onSort}
        // handleKeyDown={listViewKey}
      />
    )
  };
  useEffect(() => window.history.replaceState({}, document.title), [])
  return (
    // <div onClick={(e) => clearItemSelection()}>
    <>
      <DndProvider backend={HTML5Backend}>
        <SimplePageWithTabs
          title={
            items ? (
              <BreadcrumbView /* handleFolderSelection={handleFolderSelection} */ items={items} />
              ) : null
          }
          tabs={tabs}
          currentPage={history.location.state?.view === 'LIST' ? 'List View' : history.location.state?.view === 'GRID' ? 'Grid View' : ''}
          />
      </DndProvider>
      {/* <DeleteItems
        open={deletePopup}
        setDelete={() => setDeletePopup(!deletePopup)}
        multipleDelete={selectedFolders}
        type={'FOLDERS'}
       /> */}
      </>
    // </div>
  );
}

export default MyCompetitions;
