import React, { useState, useRef } from 'react';
import './options-menu.scss';
import DeleteItems from '../../_modals/OrganizerPopup/deleteitems/delete';
import SharePopup from '../../_modals/SharePopup/SharePopup';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useEffect } from 'react';
import Axios from 'axios';
import Renamefolder from '../../_modals/OrganizerPopup/renameFolder/renameFolder';
import listenForOutsideClicks from './isclickoutside';
import DeleteItemsForever from '../../_modals/OrganizerPopup/deleteitems/deleteforever';
import CreateCompetitionModal from '../../_modals/CreateCompetitionModal/CreateCompetitionModal';
import { useDispatch, useSelector } from 'react-redux';
import { getTrash } from '../../../redux/action/trashActions';
function OptionsMenu({
  folderId,
  organizerId,
  folderName,
  setNewname,
  getTrans,
  organizerdata,
  competition,
  ...props
}) {
  const contextObj = useSelector((state) => state.CONTEXTMENU.ContextObj ?? {});
  const contextPermissions = useSelector((state) =>
    state.CONTEXTMENU.ContextObj.permissions ? state.CONTEXTMENU.ContextObj.permissions : {}
  );
  // console.log({contextObj})
  const isTrash = contextObj.isTrash ?? false;
  // console.log({isTrash})
  const [open, setOpen] = useState(false);
  const [ShareData, setShareData] = useState({});
  const [showOptions, setShowOptions] = useState(true);
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [deleteforeveropen, setDeleteforeveropen] = useState(false);
  const [deleteOrganizaionIdforever, setDeleteOrganizaionIdforever] = useState();
  const [showOrgModal, setShowOrgModal] = useState(false);
  const dispatch = useDispatch();
  let restoreids = {
    Organizers: [],
    Folders: [],
    Competitions: []
  };
  let backend = process.env.REACT_APP_CORE_API;

  // const setOwners = (owners) => {
  //   setOrganisation({ ...organisation, owners });
  // };
  const [deletePopup, setDeletePopup] = useState(false);
  const [renamePopup, setRenamePopup] = useState(false);

  const restoreData = (item, type) => {
    if (type === 'FOLDER') {
      restoreids.Folders.push(item._id);
    }
    if (type === 'ORGANIZER') {
      restoreids.Organizers.push(item._id);
    }
    if (type === 'COMPETITION') {
      // restoreids.Competitions.push(id.split(' ').pop());
    }
    // const ele = document.getElementById(id.split(' ').pop());
    // ele.remove();
    Axios.put(backend + '/api/organizer/trash/restore', restoreids)
      .then((res) => {
        dispatch(getTrash());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setShowOptions));

  return (
    <div ref={menuRef} className="Dots-container">
      {/* <div className="hi-dots" onClick={() => setShowOptions(!showOptions)}>
        <HiDotsHorizontal size={25} />
      </div> */}
      {/* {console.log({contextPermissions})} */}
      <div>
        <div id="context-menu">
          {isTrash ? (
            <>
              {contextPermissions.showOptions ? (
                <>
                  <div
                    className="list-item"
                    id={
                      folderId
                        ? 'folder ' + folderId
                        : organizerId
                        ? 'organizer ' + organizerId
                        : 'competition ' + competition
                    }
                    onClick={(e) => restoreData(contextObj.item, contextObj.type)}>
                    Restore
                  </div>
                  <div
                    className="list-item"
                    id={
                      folderId
                        ? 'folder ' + folderId
                        : organizerId
                        ? 'organizer ' + organizerId
                        : 'competition ' + competition
                    }
                    onClick={(e) => {
                      setDeleteOrganizaionIdforever(e.target.id);
                      setDeleteforeveropen(true);
                    }}>
                    Delete Forever
                  </div>
                </>
              ) : null}
            </>
          ) : (
            contextPermissions.showOptions && (
              <>
                {/* <div id="context-menu"> */}
                {contextObj?.type === 'FOLDER'
                  ? contextPermissions.rename && (
                      <strong>
                        <div
                          className="list-item"
                          id={folderId ? 'folder ' + folderId : null}
                          onClick={(e) => {
                            setRenamePopup(true);
                            // setRenameOrganizaionId(e.target.id);
                          }}>
                          RENAME
                        </div>
                      </strong>
                    )
                  : contextPermissions.rename && (
                      <strong>
                        <div
                          className="list-item"
                          id={organizerId ? 'organizer ' + organizerId : null}
                          onClick={() => setShowOrgModal(true)}>
                          EDIT
                        </div>
                      </strong>
                    )}
                {contextPermissions.share && (
                  <strong>
                    <div
                      className="list-item"
                      id={contextObj?.item?._id}
                      onClick={(e) => {
                        setOpen(!open);
                        setShareData({ _id: contextObj?.item?._id, type: contextObj.type });
                      }}>
                      SHARE
                    </div>
                  </strong>
                )}
                {contextPermissions.delete && (
                  <strong>
                    <div
                      className="list-item"
                      id={
                        folderId
                          ? 'folder ' + folderId
                          : organizerId
                          ? 'organizer ' + organizerId
                          : 'competition ' + competition
                      }
                      onClick={(e) => {
                        setDeletePopup(true);
                        // setDeleteOrganizaionId();
                      }}>
                      DELETE
                    </div>
                  </strong>
                )}
              </>
            )
          )}
        </div>
      </div>
      {open && <SharePopup open={open} folderName={contextObj?.item?.name} owners={ShareData} onClose={() => setOpen(!open)} />}
      {deletePopup && <DeleteItems
        open={deletePopup}
        setDelete={() => setDeletePopup(!deletePopup)}
        folder={contextObj?.item}
        type={contextObj?.type}
      />}
      {renamePopup && <Renamefolder
        open={renamePopup}
        setRename={() => setRenamePopup(!renamePopup)}
        folder={contextObj?.item}
        type={contextObj?.type}
        setNewname={setNewname}
      />}
      {deleteforeveropen && <DeleteItemsForever
        open={deleteforeveropen}
        setDeleteforeveropen={() => setDeleteforeveropen(!deleteforeveropen)}
        item={contextObj?.item}
        type={contextObj?.type}
        isforever={true}
        folderName={contextObj?.item.name}
      />}
      {showOrgModal && <CreateCompetitionModal
        open={showOrgModal}
        onClose={setShowOrgModal}
        organizerdata={contextObj?.item}
        isEditorganizer={true}
      />}
    </div>
  );
}

export default OptionsMenu;
