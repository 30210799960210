import React, { useState, useContext, useEffect } from 'react';
import useTranslated from '../helpers/translationHelper';
export const TIMEZONESHEADER = () => {

  return [
    { name: 'AUTOMATIC' ,value: 'Asia/Karachi' },
    { name: 'UTC ,UTC-11', value: 'Etc/GMT+11' },
    { name: 'HST ,UTC-10',value: 'Pacific/Honolulu' },
    // { name: 'HST ,UTC-10',value: 'Pacific/Marquesas' }, DUP
    { name: 'AK ,UTC-9:30', value: 'America/Anchorage' },
    { name: 'AST ,UTC-9', value: 'Etc/GMT+9' },
    { name: 'UTC ,UTC-9', value: 'America/Tijuana' },
    { name: 'PST ,UTC-8', value: 'Etc/GMT+8' },
    { name: 'UTC ,UTC-8', value: 'America/Los_Angeles' },
    { name: 'PST ,UTC-8', value: 'US/Arizona' },
    // { name: 'MST ,UTC-7', value: 'America/Chihuahua' }, DUP
    { name: 'MST ,UTC-7', value: 'America/Hermosillo' },
    { name: 'AST ,UTC-6', value: 'America/Chicago' },
    { name: 'CST ,UTC-6', value: 'America/Regina' },
    { name: 'EIST ,UTC-6', value: 'America/Araguaina' },
    // { name: 'CST ,UTC-6',  value: 'Pacific/Easter' }, DUP
    { name: 'CST ,UTC-6', value: 'America/Mexico_City' },
    // { name: 'CST ,UTC-6',  value: 'America/Panama' },
    { name: 'SA PST ,UTC-5',  value: 'America/Regina' },
    { name: 'EST ,UTC-5',  value: 'America/Bogota' },
    { name: 'EST ,UTC-5',  value: 'America/Cancun' },
 
    { name: 'US EST ,UTC-5',  value: 'America/Havana' },
    { name:'PYT ,UTC-4',  value: 'America/New_York' },
    { name:'AST ,UTC-4',  value: 'America/Asuncion' },
    // { name: 'VET ,UTC-4', value: 'America/Glace_Bay' },DUP
    // { name: 'VET ,UTC-4',  value: 'America/Caracas' },DUP
    // { name:'VET ,UTC-4',  value: 'America/Cuiaba' },DUP
    { name:'VET ,UTC-4',  value: 'America/Cuiaba' },
    { name: 'NT ,UTC-3:30',  value: 'America/Santiago' },
    { name: 'BRT ,UTC-3',  value: 'America/St_Johns' },
    { name:'ADT ,UTC-3',  value: 'Pacific/Noumea' },
    { name: 'MST ,UTC-3', value: 'Pacific/Auckland' },
    { name:'BRT ,UTC-3', value: 'Pacific/Fiji' },
    { name: 'UTC ,UTC-2',  value: 'Pacific/Chatham' },
    { name:'AZOST ,UTC-1', value: 'Etc/GMT-13' },
    { name: 'CVT ,UTC-1',  value: 'America/Araguaina' },
    { name: 'UTC',  value: 'America/Cayenne' },
    { name: 'GMT ,UTC+0',  value: 'America/Buenos_Aires' },
    // { name: 'GMT ,UTC+0',  value: 'America/Nuuk' }, DUP
    { name: 'SAO TST ,UTC+0',  value: 'America/Montevideo' },
    { name:'GMT ,UTC+1',  value: 'America/Punta_Arenas' },
    { name: 'WET ,UTC+1',  value: 'America/Bahia' },
    { name:'CET ,UTC+1',  value: 'Etc/GMT+2' },
    { name: 'RT ,UTC+1',  value: 'Atlantic/Azores' },
    { name: 'CET ,UTC+1',  value: 'Atlantic/Cape_Verde' },
    { name: 'JST ,UTC+2',  value: '	Etc/UTC' },
    { name: 'GTB ,UTC+2',  value: 'Etc/GMT' },
    // { name: 'EST ,UTC+2',  value: 'Etc/Greenwich' },DUP
    // { name: 'EET ,UTC+2', value: 'Africa/Sao_Tome' },DUP
    // { name:'EET ,UTC+2', value: 'Africa/Casablanca' },DUP
    { name:'EET ,UTC+2', value: 'Europe/Amsterdam' },
    { name: 'SST ,UTC+2', value: 'Europe/Belgrade' },
    { name: 'EET ,UTC+2', value: 'Europe/Brussels' },
    { name: 'FLE ,UTC+2',value: 'Europe/Sarajevo' },
    { name: 'IST ,UTC+2', value: 'Europe/Rome' },
    { name: 'GMT ,UTC+2', value: 'Asia/Amman' },
    { name: 'CAT ,UTC+2', value: 'Europe/Athens' },
    { name:'EET ,UTC+2', value: 'Asia/Beirut' },
    { name:'CAT ,UTC+2', value: 'Africa/Cairo' },
    { name: 'VST ,UTC+2', value: 'Europe/Moscow' },
    { name: 'SST ,UTC+2',value: 'Asia/Yangon' },
    { name: 'SAST ,UTC+2', value: 'Asia/Magadan' },
    { name: 'AEST ,UTC+3',  value: 'Pacific/Norfolk' },
    { name: 'ART ,UTC+3',  value: 'Asia/Sakhalin' },
    { name: 'AST ,UTC+3', value: 'Asia/Nicosia' },
    { name: 'TRT ,UTC+3', value: 'Asia/Damascus' },
    { name: 'AST ,UTC+3', value: 'Asia/Hebron' },
    { name: 'MSK ,UTC+3', value: 'Africa/Maputo' },
    { name: 'EET ,UTC+3', value: 'Europe/Helsinki' },
    { name: 'EAT ,UTC+3', value: 'Asia/Jerusalem' },
    { name: 'IRST ,UTC+3', value: 'Europe/Kaliningrad' },
    { name:'AST ,UTC+4', value: 'Africa/Khartoum' },
    // { name: 'GMT ,UTC+4', value: 'Africa/Tripoli' },
    // { name:'GMT ,UTC+4', value: 'Africa/Windhoek' },
    { name:'MUT ,UTC+4', value: 'Asia/Baghdad' },
    { name: 'MSK ,UTC+4', value: 'Europe/Istanbul' },
    { name: 'GMT ,UTC+4', value: 'Asia/Riyadh' },
    { name: 'GET ,UTC+4', value: 'Europe/Minsk' },
    // { name: 'EET ,UTC+2', value: 'Africa/Nairobi' },
    { name: 'AFT ,UTC+4', value: 'Asia/Tehran' },
    // { name: 'GMT ,UTC+4', value: 'Asia/Dubai' },
    { name: 'YEKT ,UTC+5', value: 'Europe/Astrakhan' },
    { name: 'PKT ,UTC+5', value: 'Asia/Baku' },
    { name: 'QYT ,UTC+5', value: 'Europe/Samara' },
    { name: 'IST ,UTC+5:30', value: 'Indian/Mauritius' },
    { name: 'SLT ,UTC+5:30', value: 'Europe/Saratov' },
    { name: 'NPT ,UTC+5:45', value: 'Asia/Tbilisi' },
    { name: 'BST ,UTC+6', value: 'Asia/Oblast' },
    { name: 'OMST ,UTC+6', value: 'Asia/Yerevan' },
    { name: 'MST ,UTC+6:30', value: 'Asia/Kabul' },
    { name: 'SE AST ,UTC+7', value: 'Asia/Yekaterinburg' },
    // { name: 'VST ,UTC+7', value: 'Asia/Karachi' },
    { name: 'MST ,UTC+7', value: 'Asia/Qyzylorda' },
    // { name: 'NST ,UTC+7', value: 'Asia/Kolkata' },
    { name: 'NCST ,UTC+7', value: 'Asia/Colombo' },
    { name: 'VST ,UTC+7',value: 'Asia/Kathmandu' },
    { name: 'CST ,UTC+8',value: 'Asia/Tashkent' },
    { name: 'CST ,UTC+8', value: 'Asia/Dhaka' },
    { name: 'EST ,UTC+8',value: 'Asia/Omsk' },
    { name: 'AWST ,UTC+8', value: 'Asia/Bangkok' },
    { name: 'IANA ,UTC+8', value: 'Asia/Hovd' },
    { name: 'ACWST ,UTC+8:45', value: 'Asia/Hovd' },
    // { name: 'VST ,UTC+2', value: 'Asia/Krasnoyarsk' },
    { name: 'TST ,UTC+9', value: 'Asia/Novosibirsk' },
    // { name: 'KST ,UTC+9', value: 'Asia/Tomsk' },DUP
    { name: 'KST ,UTC+9', value: 'Asia/Shanghai' },
    { name: 'YAKT ,UTC+9', value: 'Asia/Irkutsk' },
    { name: 'ACST ,UTC+9:30', value: 'Asia/Singapore' },
    { name: 'ACDT ,UTC+9:30',value: 'Australia/Perth' },
    // { name: 'AEST ,UTC+10', value: 'Asia/Taipei' },DUP
    { name: 'AEST ,UTC+10', value: 'Asia/Ulaanbaatar' },
    { name: 'PT ,UTC+10', value: 'Australia/Eucla' },
    { name: 'AEDT ,UTC+10', value: 'Asia/Chita' },
    { name: 'VLAT ,UTC+10', value: 'Asia/Tokyo' },
    { name: 'NZST ,UTC+10',  value: 'Australia/Sydney' },
    { name: 'LHST ,UTC+10:30',value: 'Asia/Pyongyang' },
    { name: 'BST ,UTC+11', value: 'Asia/Seoul' },
    { name: 'MAGT ,UTC+11', value: 'Asia/Yakutsk' },
    { name:'NFDT ,UTC+11', value: 'Australia/Adelaide' },
    { name: 'SAKT ,UTC+11', value: 'Australia/Darwin' },
    { name:'PST ,UTC+11',  value: 'Australia/Brisbane' },
    { name: 'UTC ,UTC+12', value: 'Pacific/Guam' },
    { name: 'FJT ,UTC+12',  value: 'Australia/Hobart' },
    { name: 'CHADT ,UTC+12:45',  value: 'Asia/Vladivostok' },
    { name: 'UTC ,UTC+13',  value: 'Australia/Lord_Howe' },
    // { name: 'AEDT ,UTC+10',  value: 'Pacific/Bougainville' },
   
  
    // { name:'AEDT ,UTC+10',  value: 'Etc/GMT-12' },
 
    { name: 'TOT ,UTC+13',  value: 'Pacific/Tongatapu' }
  ];
};
