import React, { useContext, useEffect, useMemo, useState } from 'react';
import TournamentItem from '../../_common/TournamentItem/TournamentItem';
import { useDispatch, useSelector } from 'react-redux';
import { getTrash } from '../../../redux/action/trashActions';
import ShareOptions from '../../_common/ShareOptions/ShareOptions';
import { Folder } from '../../_common';
import Showfolders from './showTrashfolders';
import { SimplePageWithTabs } from '@common';
import moment from 'moment';
import { wrap } from 'lodash';
import ListView from './listviewtrash';
import Statusbar from './Statusbar';
import Axios from 'axios';
import {
  getCompetitions,
  getOrganizations,
  setFolderOnly
} from '../../../redux/action/competitionActions';
import DeleteItemsForever from '../../_modals/OrganizerPopup/deleteitems/deleteforever';
import useCtrlDelete from '../../_common/ContextMenu/hooks/useCtrlDelete';
import { checkTrashDeletePermission } from '../../../helpers/permissionsHelper';
import { AppContext } from '../../../context/AppContext';
const Trash = () => {
  const { user } = useContext(AppContext);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const backendServerURL = process.env.REACT_APP_CORE_API;
  const [allTrashData, setAllTrashData] = useState({
    today: [],
    earlierThisWeek: [],
    earlierThisMonth: []
  });
  const isTrash = true;
  useEffect(() => {
    getTrans();
  }, []);
  const getTrans = () => {
    dispatch(getTrash());
  };

  const currentDate = moment().format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  const trashdata = useSelector((state) => state.TRASH.Trash);
  const alldata = [];
  //*NEW WORK
  const [showDeleteForever, setShowDeleteForever] = useState(false);
  useEffect(() => {
    const _trashDataCategorized = categorizeArrayItems(trashdata);
    setAllTrashData(_trashDataCategorized);
  }, [trashdata]);

  function categorizeArrayItems(obj) {
    const today = moment().startOf('day');
    const earlierThisWeek = moment().startOf('week');
    const lastWeek = moment().subtract(1, 'weeks').startOf('week');
    const earlierThisMonth = moment().startOf('month');
    const lastMonth = moment().subtract(1, 'months').startOf('month');
    const earlierThisYear = moment().startOf('year');

    const categorizedItems = {
      today: [],
      earlierThisWeek: [],
      lastWeek: [],
      earlierThisMonth: [],
      lastMonth: [],
      earlierThisYear: [],
      older: []
    };

    if (Object.keys(obj).length > 0) {
      let combinedArr = [...obj?.folders, ...obj?.competitions, ...obj?.organizers]?.sort(
        (a, b) => new Date(b.lastEditedDate) - new Date(a.lastEditedDate)
      );

      combinedArr.forEach((item) => {
        const lastEdited = moment(item.lastEditedDate);

        if (lastEdited.isSameOrAfter(today)) {
          categorizedItems.today.push(item);
        } else if (lastEdited.isSameOrAfter(earlierThisWeek)) {
          categorizedItems.earlierThisWeek.push(item);
        } else if (lastEdited.isSameOrAfter(lastWeek)) {
          categorizedItems.lastWeek.push(item);
        } else if (lastEdited.isSameOrAfter(earlierThisMonth)) {
          categorizedItems.earlierThisMonth.push(item);
        } else if (lastEdited.isSameOrAfter(lastMonth)) {
          categorizedItems.lastMonth.push(item);
        } else if (lastEdited.isSameOrAfter(earlierThisYear)) {
          categorizedItems.earlierThisYear.push(item);
        } else {
          categorizedItems.older.push(item);
        }
      });
    }

    return categorizedItems;
  }

  const handleClearSelection = (itemId, type) => {
    setSelectedItems([itemId]);
  };
  //*NEW WORK END

  const handleSelection = (e, itemId, whichday) => {
    e && e.preventDefault();
    let selected = selectedItems;
    if (e.metaKey || e.shiftKey) {
      if (e.metaKey) {
        let flatArr = selected.flat(Infinity);
        let skipedArr = flatArr.filter((id) => id !== itemId);
        setSelectedItems(
          flatArr.length !== skipedArr.length ? skipedArr : flatArr.concat([itemId])
        );
      }

      if (e.shiftKey && !e.metaKey) {
        let idsArr = [];
        let endPoint = -1;
        let startPoint = -1;
        let children = Array.from(document.getElementById(itemId).parentElement.children);
        let singleArr = selected.filter((item) => Array.isArray(item) === false);

        children.forEach((child, index) => {
          if (child.id === singleArr[singleArr.length - 1]) startPoint = index + 1;
          if (child.id === itemId) endPoint = index + 1;
          idsArr.push(child.id);
        });

        if (startPoint !== -1 && endPoint !== -1) {
          let slice =
            startPoint < endPoint
              ? idsArr.slice(startPoint, endPoint)
              : idsArr.slice(--endPoint, --startPoint);
          setSelectedItems((selectedItems) => [...singleArr, slice]);
        }
      }
    } else setSelectedItems([itemId]);
  };
  const multiSelectedItems = useMemo(() => {
    let deleteArr = selectedItems.flat(Infinity);
    return {
      Folders:
        trashdata?.folders?.filter((el) => deleteArr.includes(el._id)).map((el) => el._id) ?? [],
      Organizers:
        trashdata?.organizers?.filter((el) => deleteArr.includes(el._id)).map((el) => el._id) ?? [],
      Competitions:
        trashdata?.competitions?.filter((el) => deleteArr.includes(el._id)).map((el) => el._id) ??
        []
    };
  }, [selectedItems]);

  // const isfolders = () => {
  //   return trashdata?.folders?.map((organizer) => {
  //     alldata.push({
  //       ...organizer,
  //       isfolder: true,
  //       timedifference: moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours'),
  //       whichday:
  //         moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 25
  //           ? 'today'
  //           : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 169
  //           ? 'Earlier this week'
  //           : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 720
  //           ? 'Earlier this month'
  //           : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 8760
  //           ? 'Earlier this year'
  //           : 'Older'
  //     });
  //   });
  // };
  // isfolders();
  // const isorganizers = () => {
  //   return trashdata?.organizers?.map((organizer) => {
  //     alldata.push({
  //       ...organizer,
  //       isorganizer: true,
  //       timedifference: moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours'),
  //       whichday:
  //         moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 25
  //           ? 'today'
  //           : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 169
  //           ? 'Earlier this week'
  //           : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 720
  //           ? 'Earlier this month'
  //           : moment(currentDate).diff(moment(organizer?.lastEditedDate), 'hours') < 8760
  //           ? 'Earlier this year'
  //           : 'Older'
  //     });
  //   });
  // };
  // isorganizers();
  // const iscompetitions = () => {
  //   return trashdata?.competitions?.map((organizer) => {
  //     alldata.push({ ...organizer, iscompetition: true });
  //   });
  // };
  // iscompetitions();

  const multiDelete = () => {
    const { Folders, Competitions, Organizers } = multiSelectedItems;
    const folderObjects = trashdata?.folders?.filter((el) => Folders.includes(el._id)) ?? [];
    const compObjs = trashdata?.competitions?.filter((el) => Competitions.includes(el._id));
    const orgObjects = trashdata?.organizers?.filter((el) => Organizers.includes(el._id)) ?? [];
    const allowed = checkTrashDeletePermission(user, {
      folders: folderObjects,
      competitions: compObjs,
      organizers: orgObjects
    });
    if (allowed) {
      return setShowDeleteForever(true);
    }
    // let deleteArr = selectedItems.flat(Infinity);
    // let payload = {
    //   Folders: trashdata.folders.filter((el) => deleteArr.includes(el._id)).map(el=> el._id) ?? [],
    //   Organizers: trashdata.organizers.filter((el) => deleteArr.includes(el._id)).map(el=> el._id) ?? [],
    //   Competitions: trashdata.competitions.filter((el) => deleteArr.includes(el._id)).map(el=> el._id) ?? []
    // };

    // console.log(payload);

    // Axios.put(backendServerURL + '/api/organizer/trash/delete', payload)
    //   .then((res) => {
    //     setSelectedItems([]);
    //     dispatch(setFolderOnly(res.data));
    //     dispatch(getOrganizations());
    //     dispatch(getCompetitions());
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  useCtrlDelete(() => {
    multiDelete();
  });

  let tabs = {
    'Grid View': (
      <>
        {selectedItems.flat(Infinity).length > 1 ? (
          <Statusbar
            selectionCount={selectedItems.flat(Infinity).length}
            deleteSelected={() => multiDelete()}
          />
        ) : null}
        <Showfolders
          alldataObj={allTrashData}
          getTrans={getTrans}
          selection={selectedItems}
          handleSelection={handleSelection}
          handleClearSelection={handleClearSelection}
        />
      </>
    ),
    'List View': (
      <>
        {selectedItems.flat(Infinity).length > 1 ? (
          <Statusbar
            selectionCount={selectedItems.flat(Infinity).length}
            deleteSelected={() => multiDelete()}
          />
        ) : (
          <ListView
            allDataObj={allTrashData}
            alldata={alldata}
            getTrans={getTrans}
            selection={selectedItems}
            handleSelection={handleSelection}
            handleClearSelection={handleClearSelection}
          />
        )}
      </>
    )
  };
  return (
    <>
      <div style={{ marginLeft: '10px' }}>
        <SimplePageWithTabs title="Trash" tabs={tabs} />
      </div>
      {showDeleteForever && (
        <DeleteItemsForever
          open={showDeleteForever}
          setDeleteforeveropen={() => setShowDeleteForever(!showDeleteForever)}
          item={multiSelectedItems}
          type={'MULTIPLE'}
          isforever={true}
          folderName={`${selectedItems.flat(Infinity).length} Items`}
          setSelectedItems={setSelectedItems}
        />
      )}
    </>
  );
};
export default Trash;
