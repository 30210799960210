import React from "react";
import CreateMatchModal from "./CreateMatchModal/CreateMatchModal";
const CreateMathesPopup=(props)=>{
return(
    <>
    <div>
    <CreateMatchModal open={props.open}groupIndex={props.groupIndex} stageIndex={props.stageIndex} onClose={props.onClose} />
    </div>
    </>
)
}
export default CreateMathesPopup;