import React, { useState, createContext, useEffect } from 'react';
import Axios from 'axios';
import './_delete.scss';
import Popup_modals from '../modals/Popup_modals';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompetitions,
  getOrganizations,
  setFolderOnly,
  setOrganizations
} from '../../../../redux/action/competitionActions';
import { setSelctedFolders } from '../../../../redux/action/dragNDropActions';
import { HiX } from 'react-icons/hi';
import { toast } from 'react-toastify';
const backendServerURL = process.env.REACT_APP_CORE_API;

const Component = ({ onClose, folder, type, multipleDelete, handleClearSelection }) => {
  // const selectedFolders = useSelector((state) => state.DRAGNDROP.SelectedFolders ?? []);

  const dispatch = useDispatch();
  const deleteOrganizaion = (type, id, multipleDelete) => {
    let payload = {
      Organizers: [],
      Folders: [],
      Competitions: []
    };
    let url;
    if (type === 'FOLDER') {
      payload.Folders.push(id);
      url = backendServerURL + '/api/organizer/trash/delete';
    } else if (type === 'ORGANIZER') {
      payload.Organizers.push(id);
      url = backendServerURL + '/api/organizer/trash/delete';
    } else if (type === 'MULTIPLE') {
      payload.Folders = multipleDelete.folders;
      payload.Competitions = multipleDelete.competitions;
      url = backendServerURL + '/api/organizer/trash/delete';
    } else if (type === 'COMPETITION') {
      url = backendServerURL + '/api/organizer/trash/delete';
      payload.Competitions.push(id);
    } else {
      return;
    }
    Axios.put(url, payload)
      .then((res) => {
        onClose(false);
        if (type === 'FOLDER') {
          dispatch(setFolderOnly(res.data));
        } else if (type === 'ORGANIZER') {
          dispatch(getOrganizations());
        } else if (type === 'MULTIPLE') {
          dispatch(setSelctedFolders([]));
          dispatch(setFolderOnly(res.data));
          handleClearSelection();
        } else if (type === 'COMPETITION') {
          dispatch(getCompetitions());
        }
      })
      .catch((err) => {
        toast.error(err)
      });
  };
  return (
    // <div className="newfolder-main" style={{border:'10px solid #0f1116'}}>
    <div className="newfolder-main">
      <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>MOVE TO TRASH?</h2>
        <HiX
          onClick={() => onClose(false)}
          // style={{ marginTop: '18px', marginRight: '14px', fontSize: '20px', color: 'grey' }}
          style={{ marginTop: '18px', marginRight: '22px', fontSize: '26.5px', color: '#68717e' }}
        />
      </div>
      <div className="modal-body">
        <div
          className="organizer-delete-text"
          style={{
            font: 'normal normal normal 12px/15px Montserrat',
            color: '#68717e',
            width: '312px',
            height: '15px'
          }}>
          {type === 'MULTIPLE'
            ? [...multipleDelete.folders, ...multipleDelete.competitions].length + ' Items'
            : folder?.name}{' '}
          will be deleted forever after 30 days.
        </div>
        <div className="newfolder-buttons">
          <button
            className="button bordered ml-3 mr-3"
            onClick={() => {
              onClose(false);
            }}>
            Cancel
          </button>
          <button
            autoFocus
            className="button primary"
            onClick={(e) => {
              deleteOrganizaion(type, folder?._id, multipleDelete);
            }}>
            MOVE TO TRASH
          </button>
        </div>
      </div>
    </div>
  );
};
const DeleteItems = ({
  open,
  setDelete,
  type,
  folder,
  multipleDelete = [],
  handleClearSelection
}) => {
  console.log('folder ', folder)
  return (
    <Popup_modals
      open={open}
      onClose={setDelete}
      folder={folder}
      type={type}
      multipleDelete={multipleDelete}
      handleClearSelection={handleClearSelection}
      Component={Component}></Popup_modals>
  );
};
export default DeleteItems;
