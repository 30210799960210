import { useState } from 'react';

const useContextMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const showMenu = (event) => {
    event.preventDefault();
    setIsVisible(true);
    setX(event.clientX);
    setY(event.clientY);
  };

  const hideMenu = () => {
    setIsVisible(false);
  };

  return { isVisible, x, y, showMenu, hideMenu };
};

export default useContextMenu;
