//* This hook is created for multiselection components to unselect 
//* items when click is made outside of the selectable elements

import React, { useEffect } from 'react';

const useClickOutsideNav = (onClickOutside) => {
  const handleClick = (e) => {
    let nodes = document.querySelectorAll('.multiselectItem-cls'); 
    const modalElem = document?.querySelectorAll('.ReactModalPortal');
    let newArray = [...Array.from(nodes),...modalElem];
    const containsTarget = newArray.some((node) => node && node?.contains(e.target));
    if (!containsTarget) {
      onClickOutside();
    }
    return;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};

export default useClickOutsideNav;
