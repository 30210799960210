import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Form, Dropdown, Card, Media } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { getAllTeams } from '../../../../../redux/action/CompetitonData';

const AddTeamsModal = ({ show, onHide ,competitionId,updateTeams}) => {
  const leagesid = useSelector((state) => state?.COMPETITION?.Competition?.multiStageLeague);
const dispatch=useDispatch()
  const { id } = useParams();
  const timeout = useRef();
  const [teamsQuery, setTeamsQuery] = useState('');
  const [teamResults, setTeamResults] = useState([]);
  const [teamResultsLoading, setTeamResultsLoading] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [league, setLeague] = useState();
  const [stage, setStage] = useState();
  const [teams, setTeams] = useState();
  const [game, setGame] = useState();
  useEffect(() => {
    if (leagesid?.season?.game?._id) {
      setLeague(leagesid?.stages[0]?.bracketType === undefined ? leagesid?.stages[0]?.league?._id : leagesid?.stages[0]?.tournament._id);
      setStage(leagesid?.stages[0]);
      setTeams(leagesid?.stages[0]?.bracketType === undefined ? leagesid?.stages[0]?.league?.teams : leagesid?.stages[0]?.tournament.teams);
      setGame(leagesid?.season?.game?._id);
    }
  }, [leagesid]);
  useEffect(() => {
    console.log(game, teamsQuery);
  }, [game, teamsQuery]);
  // alter team results on query change
  useEffect(() => {
    if (!teamsQuery.length) return setTeamResults([]);
    setTeamResultsLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/searchByGame`, {
        query: teamsQuery,
        game: game || null
      }).then(({ data }) => {
        const withoutSelectedTeams = data.filter(
          ({ _id }) =>
            !teams.map(({ team }) => team?._id).includes(_id) &&
            !selectedTeams.map((team) => team?._id).includes(_id)
        );

        setTeamResults(withoutSelectedTeams);
        setTeamResultsLoading(false);
      });
    }, 500);
  }, [teamsQuery, teams, selectedTeams]);

  const onSelectTeam = (team) => {
    setSelectedTeams((teams) => [...teams, team]);
    setTeamsQuery('');
    setTeamResults([]);
  };

  const onAddTeams = async (e) => {
    e.preventDefault();

    try {
      if (stage.stageType === 'league') {
        const { data } = await Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/leagues/${league}/teams`,
          {
            teams: selectedTeams.map(({ _id }) => _id)
          }
        );
        setLeague((league) => ({ ...league, ...data.updatedLeague }));
        setTimeout(()=>{
          if(stage){
            if (stage?.stageType === 'league') {
              Axios.get(
                `${process.env.REACT_APP_CORE_API}/api/leagues/${stage?.league?._id}?withMatches=true`
              ).then(({ data }) => {
                updateTeams(data);
              });
            } else {
              Axios.get(
                `${process.env.REACT_APP_CORE_API}/api/tournaments/${stage?.tournament?._id}?withMatches=true`
              ).then(({ data }) => {
                updateTeams(data);
              });
            }
          }
          dispatch(getAllTeams(leagesid._id))
        },200)
      } else {
        const { data } = await Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/tournaments/${league}/teams`,
          {
            teams: selectedTeams.map(({ _id }) => _id)
          }
        );
        setLeague((tournament) => ({ ...tournament, ...data.updatedTournament }));
        Axios.get(
          `${process.env.REACT_APP_CORE_API}/api/tournaments/${data?.updatedTournament?._id}?withMatches=true`
        ).then(({ data }) => {
          updateTeams(data);
        }).catch(error => toast.error(error));
      }
      toast.success('Successfully added teams');
      setSelectedTeams([]);
      onHide();
    } catch (e) {
      toast.error('There was a problem adding the teams');
    }
  };

  const onRemoveTeam = (id) => {
    setSelectedTeams((teams) => teams.filter(({ _id }) => _id !== id));
  };

  const teamSelectedNumberContent = () => {
    var teamsInNumber = selectedTeams.length;
    if (teamsInNumber) {
      if (teamsInNumber > 1) {
        return `(${teamsInNumber} teams selected)`;
      } else {
        return `(${teamsInNumber} team selected)`;
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <h2>
          Add Team <small>{teamSelectedNumberContent()}</small>
        </h2>
        <div className="modal-close" onClick={() => onHide(false)}>
          <RiCloseLine />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onAddTeams}>
          <Form.Group>
            <Form.Control
              autoFocus
              placeholder="Search for teams"
              onChange={(e) => setTeamsQuery(e.target.value)}
              value={teamsQuery}
            />
            {(teamResultsLoading || !!teamResults.length) && (
              <Card bg="light">
                <div>
                  {teamResultsLoading ? (
                    <Dropdown.Item style={{textDecoration: 'none'}} className="text-secondary">Loading...</Dropdown.Item>
                  ) : (
                    teamResults.map((team) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '5px',
                          border: '1px solid gray'
                        }}>
                        <img
                          width={25}
                          height={25}
                          className="mr-3"
                          src={team.logo}
                          alt={team.name}
                        />
                        <Dropdown.Item style={{textDecoration: 'none'}}key={team._id} onClick={() => onSelectTeam(team)}>
                          {team.name}
                        </Dropdown.Item>
                      </div>
                    ))
                  )}
                </div>
              </Card>
            )}
          </Form.Group>

          <div>
            {selectedTeams.map(({ _id, logo, name }) => (
              <div key={_id} className="mb-3">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <img width={25} height={25} className="mr-3" src={logo} alt={name} />
                  <Media.Body>
                    <span>{name}</span>
                    <Button
                      variant="link"
                      className="text-danger"
                      size="sm"
                      style={{ background: 'red', color: 'white' }}
                      onClick={() => onRemoveTeam(_id)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </Media.Body>
                </div>
              </div>
            ))}
          </div>

          <Button
            type="submit"
            variant="success"
            className="button primary"
            style={{ width: '30%', marginTop: '15px', float: 'right' }}>
            Add
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTeamsModal;
