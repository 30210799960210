import {
  REARRANGE_ENTITIES,
  SET_DRAGGING_TASK,
  SET_ENTITIES,
  SET_SELECTED_FOLDERS,
  SET_SELECTED_TASKS,
  UPDATE_ENTITIES
} from '../action/types';
const initialState = {
  SelectedFolders : [],

};

// Use the initialState as a default value
export default function dragNDropReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_FOLDERS:
      return {
        ...state,
        SelectedFolders: action.payload
      };

    default:
      return state;
  }
}
