import React from "react";
import { RiCloseLine } from "react-icons/ri";
import Modal from "react-modal";
import useTranslated from "../../../../../../helpers/translationHelper";
const TempTournCodes = ({ isOpen, onClose, team1, teamSelector, team2, tournamentCodes = [] }) => {
  const colorCodes =
    teamSelector === "t1" ? ["Blue", "Red", "Blue", "Red", "Blue", "Red"] : ["Red", "Blue", "Red", "Blue", , "Red", "Blue"];
    const maptrans=useTranslated('id_pop-up_-_tournament_codes_1')
    const sidetrans=useTranslated('id_pop-up_-_tournament_codes_2')

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal auth-modal">
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>{useTranslated('id_pop-up_-_tournament_codes_0')}</h2>
      </div>
      <div className="modal-body">
        {/* <div className="row smaller-gutter-row team-names">
          <div className="col-6">
            <small className="text-faded">Blue side</small>
            <p className="team-name">
              <strong>{team1}</strong>
            </p>
          </div>
          <div className="col-6">
            <small className="text-faded">Red side</small>
            <p className="team-name">
              <strong>{team2}</strong>
            </p>
          </div>
        </div> */}

        {tournamentCodes.map((code, i) => (
          <div>
            <p className="bold">
              {maptrans} {i + 1} ({colorCodes[i]} {sidetrans}):
            </p>{" "}
            <p>{code}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default TempTournCodes;
