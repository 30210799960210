import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import 'font-awesome/css/font-awesome.css';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';
import store from './redux/store/store.js';

console.log('Hello from leagues-frontend-organizer/staging-master');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
