import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { moveToFolder } from '../../../redux/action/dragNDropActions';

const CARD_TYPE = 'CARD';
export default function DragNDropWrapper({
  parent,
  view = 'GRID',
  _item,
  selectedItems,
  _disabled,
  canCreate,
  blurIt = false,
  handleClearSelection = () => {},
  addSeparatorLine=false,
  children
}) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const checkItemCanBeDropped = (item, selectedItems) => {
    if (_item?.type === 'COMPETITION') return false;
    if (item?._id === item?.destinationFolder) return false;
    let _selected = selectedItems?.folders.includes(item.destinationFolder);
    return !_selected;
  };

  const [{ isDragging, canDrag }, drag, preview] = useDrag({
    type: CARD_TYPE,
    item: (item, monitor) => {
      let type = _item?.type;
      let name = _item?.element?.name ?? 'Un Named Folder';
      let logo = _item?.element?.logo;
      let total = [...selectedItems?.folders, ...selectedItems?.competitions].length;
      let _id = _item?.element?._id;
      return {
        parent,
        type,
        name,
        total,
        _id,
        logo,
        index: _item?.index,
        destinationIndex: null,
        destinationFolder: null,
        folders: selectedItems?.folders,
        competitions: selectedItems?.competitions
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => !_disabled && canCreate
  });

  const [{ isActive, canDrop }, drop] = useDrop({
    accept: CARD_TYPE,
    drop: (item) => {
      dispatch(moveToFolder(item));
      handleClearSelection();
    //   console.log('Dropped Item', { item });
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item;
      const hoverIndex = _item;
      if (dragIndex._id === hoverIndex._id) {
        return;
      }
      //   const hoverBoundingRect = ref.current.getBoundingClientRect();
      //   const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      //   const clientOffset = monitor.getClientOffset();
      //   const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      item.destinationFolder = hoverIndex?.element?._id;
      item.destinationIndex = hoverIndex?.index;

      //   console.log('DD', dragIndex, hoverIndex);
    },
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver()
    }),
    canDrop: (item, monitor) => checkItemCanBeDropped(item, selectedItems)
  });

  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)

    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    preview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    });
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log('unmounting...');
  }, []);

  drag(drop(ref));
  const addPropsToChild = (child, isActive) => {
    return React.cloneElement(child, { isActive });
  };

  switch (view) {
    case 'GRID':
      return (
        <div ref={ref} style={{ opacity: blurIt ? 0.3 : 1 }}>
          {addPropsToChild(children, isActive)}
        </div>
      );
    case 'LIST':
      return (
        <tr
          ref={ref}
          className={`multiselectItem-cls ${(isActive || !_disabled) ? 'active-tr' : ''} ${addSeparatorLine ? 'competition-row-items' : ''}` }
          style={{
            opacity: blurIt ? 0.3 : 1,
            backgroundColor: !isActive && _disabled ? 'transparent' : '#050c16',
          }}>
          {addPropsToChild(children, isActive)}
        </tr>
      );
    default:
      return <></>;
  }
}
