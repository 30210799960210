// import { setSeedingData, setSeedingDataAll } from '../../../redux/action/seedingActions';
// import store from '../../../redux/store/store';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

export const filterMatches = (matches, query) => {
  // console.log(query);

  let queryObj = JSON.parse(JSON.stringify(query));
  let filter = {
    fromDateTime: queryObj?.fromDateTime,
    toDateTime: queryObj?.toDateTime,
    fromRound: queryObj?.fromRound,
    toRound: queryObj?.toRound,
    fromWeek: queryObj?.fromWeek,
    toWeek: queryObj?.toWeek,
    singleTeam: queryObj?.lineup === 'Roster set for one team' ? 'singleTeam' : undefined,
    bothTeam: queryObj?.lineup === 'Roster set for both teams' ? 'bothTeam' : undefined,
    noTeams: queryObj?.lineup === 'Roster not set for either team' ? 'noTeams' : undefined,
    broadcasted: queryObj.broadcasted.yes ? true : queryObj.broadcasted.no ? false : undefined,
    isLockReschedule: queryObj.rescheduleLocked.yes
      ? true
      : queryObj.rescheduleLocked.no
      ? false
      : undefined,
    sideSelection: queryObj.sideSelection.yes
      ? true
      : queryObj.sideSelection.no
      ? false
      : undefined,
    status:
      queryObj?.checkCompleteOrNot?.complete && queryObj?.checkCompleteOrNot?.upcomimg
        ? undefined
        : queryObj.checkCompleteOrNot.complete
        ? 'complete'
        : queryObj.checkCompleteOrNot.upcomimg
        ? 'upcoming'
        : undefined
  };
  filter = JSON.parse(JSON.stringify(filter));
  Object.keys(filter).forEach(el=> !filter[el] && delete filter[el])
  if (Object.keys(filter).length > 0) {
    let filteredMatches = matches.filter((match) =>{
      return Object.keys(filter).every((key) => {
        console.log({key})
        switch (key) {
          case 'singleTeam':
            return (
              (match.lolData && !!(match.lolData?.players?.t1 || []).length) ||
              (match.csgoData && !!(match.csgoData?.players?.t1 || []).length) ||
              (match.lolData && !!(match.lolData?.players?.t2 || []).length) ||
              (match.csgoData && !!(match.csgoData?.players?.t2 || []).length)
            );
          case 'bothTeam':
            return (
              ((match.lolData && !!(match.lolData?.players?.t1 || []).length) ||
                (match.csgoData && !!(match.csgoData?.players?.t1 || []).length)) &&
              ((match.lolData && !!(match.lolData?.players?.t2 || []).length) ||
                (match.csgoData && !!(match.csgoData?.players?.t2 || []).length))
            );
          case 'noTeams':
            return (
              ((match.lolData && (match.lolData?.players?.t1 || []).length === 0) ||
                (match.csgoData && (match.csgoData?.players?.t1 || []).length === 0)) &&
              ((match.lolData && (match.lolData?.players?.t2 || []).length === 0) ||
                (match.csgoData && (match.csgoData?.players?.t2 || []).length === 0))
            );
          case 'fromRound':
            return match.round === parseInt(filter[key]) || match.round > parseInt(filter[key]);
          case 'toRound':
            return match.round === parseInt(filter[key]) || match.round < parseInt(filter[key]);
          case 'fromWeek':
            return match.week === parseInt(filter[key]) || match.week > parseInt(filter[key]);
          case 'toWeek':
            return match.week === parseInt(filter[key]) || match.week < parseInt(filter[key]);
          case 'fromDateTime':
            return moment(match.datetime).isAfter(moment(filter.fromDateTime));
          //   return match.datetime === filter[key] || match.datetime > filter[key];
          case 'toDateTime':
            return moment(match.datetime).isBefore(moment(filter.toDateTime));
          //   return match.datetime === filter[key] || match.datetime < filter[key];
          case "status":
            if(filter[key] === 'upcoming'){
              return match.status !== 'complete'
            }else{
              return match.status === filter[key] 
            }
          default:
           // return match[key] === filter[key];// every stops if it is false; This condition can easily be false in any case
              return true;
        }
      })}
    );
    return filteredMatches;
  } else {
    return matches;
  }
};
