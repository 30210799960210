import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../context/AppContext';
import Axios from 'axios';
import { RiEditLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import EditImageModal from '../EditImageModal/EditImageModal';
import useTranslated from '../../../helpers/translationHelper';

const Uploadlogo = ({ setLogo, isOpen, onClose ,updatenameAndLogo,setLogoEditModal}) => {
  const { user } = useContext(AppContext);
  const [modals, setModals] = useState({});
  const [Image,setImage]=useState()
  const avatarTrans = useTranslated('id_settings_(my_account)_16');
  const bannerTrans = useTranslated('id_settings_(my_account)_17');
  const updateUser = async (type, imageUrl) => {
    setLogo(imageUrl)
    setImage(imageUrl)
    setLogoEditModal(false);
    // try {
    //   await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/image`, {
    //     type,
    //     image: imageUrl
    //   });
    //   user.dispatch({ type: 'update', payload: { [type]: imageUrl } });
    //   setLogo(imageUrl);
    //   onClose(false);
    //   setModals({ ...modals, [type]: false });
    // } catch (err) {
    //   toast.error('There was a problem updating your account');
    // }
  };
useEffect(()=>{
  if(Image!==null){
    updatenameAndLogo()
  }
},[Image])
  const images = [
    {
      name: `${avatarTrans}`,
      key: 'avatarImage',
      width: 250,
      height: 250,
      displayWidth: 150,
      displayHeight: 150,
      crop: true,
      radius: 250
    }
  ];
  return (
    <EditImageModal
      name={images.name}
      width={images.width}
      height={images.height}
      open={isOpen}
      crop={images.crop}
      borderRadius={images.radius}
      setLogoEditModal={setLogoEditModal}
      onClose={() => setModals({ ...modals, ['avatarImage']: false })}
      afterImageUpload={(file) => updateUser('avatarImage', file)}
    />
  );
};

export default Uploadlogo;
