import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { formatData, connectSocket, disconnectSocket } from './Helpers/interceptors';
import tmpData from './Helpers/tmp.json';
import { Loading, Checkbox } from '@common';
import BracketsMap from './BracketsMap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { MdZoomOutMap } from 'react-icons/md';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import { AiOutlineZoomOut, AiOutlineZoomIn, AiOutlineFullscreen } from 'react-icons/ai';
import Modal from 'react-modal';

// import MatchModal from "../../MatchModal";

const Brackets = ({ id, stage, setStageSettings }) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const modalFullScreen = query.get('open_fullscreen');
  const [loading, setLoading] = useState(true);
  // const [modal, setModal] = useState(false);
  const [bracketsData, setBracketsData] = useState(stage.bracketsData ?? []);
  const [selectedWinner, setSelectedWinner] = useState(BRACKETS.WINNING);
  const [selectedLoser, setSelectedLoser] = useState(BRACKETS.LOSING);
  const [hilighted, setHilighted] = useState('');
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [selctedMatchId, setSelctedMatchId] = useState(null);

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${id}/matches/GetBracketData/${stage._id}`,
      { generateLibMatches: true, teamSize: stage.teamSize }
    ).then(({ data }) => {
      if (data.length > 0) {
        if (!stage?.bracketsData || stage.bracketsData.length === 0) {
          setBracketsData(data);
        } else if (
          stage.bracketsData[0] &&
          stage.bracketsData[0][0] &&
          stage.bracketsData[0][0].results &&
          data[0][0].results.length !== stage.bracketsData[0][0].results.length
        ) {
          const newData = [...data];
          const prevData = stage.bracketsData[0][0].matches;
      
          prevData.forEach((match, index) => {
            const _matchNo = match?.matchData?.matchNo;
            const dataInd = newData[0][0].matches.findIndex(
              (match) => match.matchData.matchNo === _matchNo
            );
      
            if (dataInd >= 0 && match?.matchData?.bestOf !== undefined) {
              newData[0][0].matches[dataInd].matchData.bestOf = match.matchData.bestOf;
            }
          });
          // debugger
          setBracketsData(newData);
        }
      }
    });
  }, [stage.teamSize]);

  useEffect(() => {
    if (bracketsData.length) {
      setLoading(false);
      setStageSettings((_stage) => ({
        ...stage,
        bracketsData
      }));
    }
  }, [bracketsData]);

  const formatSingleBracketData = (data) => {
    let bracketData = filteredBracketData(data);
    let temp = bracketData[0];
    let seed = temp[0].seeds;
    let visibleSeed = seed[0];
    visibleSeed = { ...visibleSeed, visibility: true };
    seed[0] = visibleSeed;
    temp[0] = { ...temp[0], seeds: seed };
    return temp;
  };

  const filteredBracketData = (data) => {
    let brackData = [[], []];
    const formatedData = formatData(data, false, true);
    brackData[0] = formatedData[0]
      .filter((round) => round.some((seed) => seed?.matchData))
      .map((el, index) => ({ title: 'Round ' + (index + 1), seeds: el }));
    brackData[1] = formatedData[1]
      .filter((round) => round.some((seed) => seed?.matchData))
      .map((el, index) => ({ title: 'Round ' + (index + 1), seeds: el }));
    return brackData;
  };

  // const enterFullScreen = () => {
  //   setModal(!modal);
  // };

  // const openFullScreenInTab = () => {
  //   if (!modal) {
  //     const urlLink = `/tournament/${id}/brackets?open_fullscreen=true`;
  //     const win = window.open(urlLink, "_blank");
  //     win.focus();
  //   } else {
  //     enterFullScreen();
  //   }
  // };

  const handleMatchClick = (matchData) => {
    setSelctedMatchId(matchData._id);
    setShowMatchModal(true);
    // console.log('match dATA-->', matchData);
  };

  const renderChildContent = (isFullScreen) => (
    <div
      className="w-100 h-100 brackets-wrapper"
      style={{ overflow: 'hidden', border: '1px solid #22262e', borderRadius: '5px' }}>
      <div className={`p-0 m-0 w-100 position-relative`}>
        <TransformWrapper
          wheel={{
            wheelEnabled: false,
            step: 20
          }}
          zoomIn={{
            step: 5
          }}
          zoomOut={{
            step: 5
          }}
          options={{
            limitToBounds: false,
            minScale: 0.25
          }}
          pan={{
            velocityEqualToMove: true,
            velocity: true,
            lockAxisY: true
          }}>
          {({ zoomIn, zoomOut, resetTransform }) => {
            return (
              <>
                <TransformComponent>
                  {selectedWinner
                    ? bracketsData.map((singleBracket) => (
                        <BracketsMap
                          selectedLoser={selectedLoser}
                          hilighted={hilighted}
                          setHilighted={setHilighted}
                          data={formatSingleBracketData(singleBracket[0])}
                          handleMatchClick={handleMatchClick}
                          setBracketsData={setBracketsData}
                        />
                      ))
                    : null}
                  {/* {selectedLoser && (
                    <BracketsMap
                      // isShort={duel?.options?.short}
                      hilighted={hilighted}
                      setHilighted={setHilighted}
                      losingTrack={true}
                      upperBracketData={bracketsData[0]}
                      data={bracketsData[1]}
                      handleMatchClick={handleMatchClick}
                    />
                  )} */}
                </TransformComponent>
                <div className="zoom-control-buttons">
                  {/* <button onClick={openFullScreenInTab} className="zoom-control-button">
                    {modal ? <BsFullscreenExit color="#FFFFFF" /> : <BsFullscreen color="#FFFFFF" />}
                  </button> */}
                  <button onClick={resetTransform} className="zoom-control-button">
                    <MdZoomOutMap color="#FFFFFF" />
                  </button>
                  <button onClick={zoomIn} className="zoom-control-button">
                    <AiOutlineZoomIn color="#FFFFFF" />
                  </button>
                  <button onClick={zoomOut} className="zoom-control-button">
                    <AiOutlineZoomOut color="#FFFFFF" />
                  </button>
                </div>
              </>
            );
          }}
        </TransformWrapper>
      </div>
    </div>
  );

  return (
    <>
      <div className="brackets-class">
        {loading ? (
          <div className="w-100" style={{ textAlign: 'center' }}>
            <Loading />
          </div>
        ) : bracketsData.length === 0 ? (
          <div className="col-12 p-5 text-grey text-center">Nothing to show here!</div>
        ) : (
          renderChildContent()
        )}
        {/* below Model is full screen bracket */}
      </div>
      {/* below model opens when click on team */}
      {/* <MatchModal showModal={showMatchModal} closeModal={() => setShowMatchModal(false)} id={id} selctedMatchId={selctedMatchId} /> */}
    </>
  );
};

export default Brackets;

const BRACKETS = {
  WINNING: true,
  LOSING: true,
  BOTH: 'BOTH'
};
