import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import {
  HiCollection,
  HiPlus,
  HiSortAscending,
  HiSortDescending,
  HiUsers,
  HiX
} from 'react-icons/hi';
import { toast } from 'react-toastify';
import { InformationLogo, SelectWithDescription } from '../../../../_common';
const criteria = [
  { criteria: 'Points', matches: 'none', participants: 'total', rankingOrder: 'Descending' },
  {
    criteria: 'Tiebreaker points',
    matches: 'none',
    participants: 'head-to-head',
    rankingOrder: 'Descending'
  },
  {
    criteria: 'Match wins',
    matches: 'All matches',
    participants: 'total',
    rankingOrder: 'Descending'
  },
  {
    criteria: 'Match draws',
    matches: 'All matches',
    participants: 'total',
    rankingOrder: 'Descending'
  },
  {
    criteria: 'Match losses',
    matches: 'All matches',
    participants: 'total',
    rankingOrder: 'Ascending'
  },
  {
    criteria: 'Game wins',
    matches: 'none',
    participants: 'head-to-head',
    rankingOrder: 'Descending'
  },
  {
    criteria: 'Game losses',
    matches: 'none',
    participants: 'head-to-head',
    rankingOrder: 'Ascending'
  },
  {
    criteria: 'Round difference',
    matches: 'none',
    participants: 'head-to-head',
    rankingOrder: 'Descending'
  },
  {
    criteria: 'Forfeits',
    matches: 'All matches',
    participants: 'total',
    rankingOrder: 'Ascending'
  },
  {
    criteria: 'Matches played',
    matches: 'All matches',
    participants: 'none',
    rankingOrder: 'Ascending'
  },
  { criteria: 'Manual', matches: 'All matches', participants: 'none', rankingOrder: 'Descending' },
  { criteria: 'Random', matches: 'All matches', participants: 'none', rankingOrder: 'none' }
];
const matches = ['All matches', 'Wins', 'Ties', 'Losses', 'Losses + Forfeits'];
function RankingCriteria({ stage, setStageSettings, isMatchesGenerated=false }) {
  const styles = {
    info: {
      color: '#68717E'
    }
  };
  const optionsSelectorFunct = (e,ind)=>{
    // debugger
return handleCriteraChange(e.target.name,e.target.value,ind)
  }
  const handleCriteraChange = (type, value, index) => {
    setStageSettings((state) => {
      switch (type) {
        case 'rankingOrder':
          return {
            ...state,
            rankingCriteria: state.rankingCriteria.map((newCriteria, i) => {
              let _obj = JSON.parse(JSON.stringify(newCriteria));
              if (i === index) {
                _obj.rankingOrder = value;
              }
              return _obj;
            })
          };
        case 'participants':
          return {
            ...state,
            rankingCriteria: state.rankingCriteria.map((newCriteria, i) => {
              let _obj = JSON.parse(JSON.stringify(newCriteria));
              if (i === index) {
                _obj.participants = value;
              }
              return _obj;
            })
          };
        case 'criteria':
          return {
            ...state,
            rankingCriteria: state.rankingCriteria.map((newCriteria, i) => {
              if (i === index) {
                let _criteria = criteria.find((el) => el.criteria === value);
                const { _id, ...rest } = newCriteria;
                if (_id) {
                  _criteria._id = _id;
                }
                newCriteria = _criteria;
              }
              return newCriteria;
            })
          };
        case 'matches':
          return {
            ...state,
            rankingCriteria: state.rankingCriteria.map((newCriteria, i) => {
              if (i === index) {
                newCriteria.matches = value;
              }
              return newCriteria;
            })
          };
        default:
          return state;
      }
    });
  };

  return (
    <div>
      <div style={{margin:'85px 0 0 0', display:'flex',alignItems:'center',justifyContent:'space-between'}}>  
        <h1 >Ranking Criteria</h1>
        <button
        className="add-group-btn" style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        disabled={isMatchesGenerated || (stage.rankingCriteria.length >= 10)}
        onClick={(e) => {
          e.preventDefault();
          let totalCriteria = stage.rankingCriteria.length;
          // debugger
          if (totalCriteria < 10) {
            setStageSettings((state) => ({
              ...state,
              rankingCriteria: [...state.rankingCriteria, criteria[totalCriteria]]
            }));
            toast.success('New Criterion Added Successfully');
          } else {
            toast.error("Criteria length exceeds can't add more");
          }
        }}>
        <HiPlus size={12} style={{marginRight:'4px'}} /> Add Criterion
      </button>
      </div>
      <p style={styles.info}>
      Determine how the leaderboard is calculated. You can set several ranking criteria that will be used from top to bottom to try and rank the teams and resolve potential ties. If a tie resists a criterion, then the following in the list will be used. For each ranking criterion it is possible to determine if the participants should be ordered in an ascending or descending order. Some criteria can be restricted to only look at head-to-head matches, or only matches that participants have won, lost, or tied.
        <span>
          {' '}
          <InformationLogo content="Head-to-head matches are the matches among tied participants." />
        </span>
      </p>
      <div>
        <table>
          <thead>
            <tr>
              <td className='col-sm-1'>Order</td>
              <td className='col-sm-4'>Criteria</td>
              <td>
                {/* Ranking Order <InformationLogo disabled /> */}
                Ranking Order <InformationLogo content='Whether or not participants should be ordered from lowest to highest (ascending order) or from highest to lowest (descending order), based on the chosen criterion.' />
              </td>
              <td>
                {/* Participants <InformationLogo disabled /> */}
                Participants <InformationLogo content='Determines if the criterion should only be applied between tied participants (head-to-head) or all participants (total).' />
              </td>
              <td>
                {/* Matches <InformationLogo disabled /> */}
                Matches <InformationLogo content='Decides what matches participants are ranked on.' />
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {stage.rankingCriteria.map((_criteria, ind) => {
              // console.log({ criteria }, _criteria.criteria);
              let num = ind + 1;
              return (
                <tr key={Math.random() % 10}>
                  <td style={{ color: '#a0a1a3'}} className='col-sm-1'> #{num}</td>
                  <td className='col-sm-4'>
                    <SelectWithDescription
                    name='criteria'
                      disabled={isMatchesGenerated || _criteria.criteria === 'none' ? true : false}
                      defaultVal={_criteria.criteria}
                      defaultSelection={_criteria.criteria ? _criteria.criteria : 'Points'}
                      handleChange={(e)=>optionsSelectorFunct(e,ind)}
                      options={criteria.map((el) => ({
                        label: el.criteria,
                        value: el.criteria,
                        description:
                          el.criteria === 'Points'
                            ? 'Accumulated points from matches.'
                            : el.criteria === 'Tiebreaker points'
                            ? 'Accumulated points from tiebreaker matches.'
                            : el.criteria === 'Match wins'
                            ? 'Number of matches won.'
                            : el.criteria === 'Match draws'
                            ? 'Number of matches tied.'
                            : el.criteria === 'Match losses'
                            ? 'Number of matches lost.'
                            : el.criteria === 'Game wins'
                            ? 'Number of games won in matches.'
                            : el.criteria === 'Game losses'
                            ? 'Number of games lost in matches.'
                            : el.criteria === 'Round difference'
                            ? 'Sum of round differences from matches.'
                            : el.criteria === 'Forfeits'
                            ? 'Number of forfeits.'
                            : el.criteria === 'Matches played'
                            ? 'Number of matches played.'
                            : el.criteria === 'Manual'
                            ? 'Amount of "ranking points" manually assigned in the leaderboard.'
                            : 'Randomly decided when calculating the leaderboard'
                      }))}
                    />
                    {/* <SelectionField
                      isMatchesGenerated={isMatchesGenerated}
                      index={ind}
                      options={criteria.map((el) => el.criteria)}
                      name={'criteria'}
                      content={
                        _criteria.criteria === 'Points'
                          ? 'Accumulated points from matches.'
                          : _criteria.criteria === 'Tiebreaker points'
                          ? 'Accumulated points from tiebreaker matches.'
                          : _criteria.criteria === 'Match wins'
                          ? 'Number of matches won.'
                          : _criteria.criteria === 'Match draws'
                          ? 'Number of matches tied.'
                          : _criteria.criteria === 'Match losses'
                          ? 'Number of matches lost.'
                          : _criteria.criteria === 'Game wins'
                          ? 'Number of games won in matches.'
                          : _criteria.criteria === 'Game losses'
                          ? 'Number of games lost in matches.'
                          : _criteria.criteria === 'Round difference'
                          ? 'Sum of round differences from matches.'
                          : _criteria.criteria === 'Forfeits'
                          ? 'Number of forfeits.'
                          : _criteria.criteria === 'Matches played'
                          ? 'Number of matches played.'
                          : _criteria.criteria === 'Manual'
                          ? 'Amount of "ranking points" manually assigned in the leaderboard.'
                          : 'Randomly decided when calculating the leaderboard'
                      }
                      handleChange={handleCriteraChange}
                      defaultVal={_criteria.criteria}
                      disabled={_criteria.criteria === 'none' ? true : false}
                    /> */}
                  </td>
                  <td>
                    <ADButton
                      isMatchesGenerated={isMatchesGenerated}
                      handleClick={handleCriteraChange}
                      name={'rankingOrder'}
                      Icon={
                        _criteria.rankingOrder === 'Ascending' ? HiSortAscending : HiSortDescending
                      }
                      index={ind}
                      option1={_criteria.rankingOrder === 'Ascending' ? 'Ascending' : 'Descending'}
                      option2={_criteria.rankingOrder === 'Ascending' ? 'Descending' : 'Ascending'}
                      content={
                        _criteria.rankingOrder === 'Ascending'
                          ? 'Participants will be ordered from lowest to highest based on the chosen criterion.'
                          : 'Participants will be ordered from highest to lowest based on the chosen criterion.'
                      }
                      disabled={_criteria.rankingOrder === 'none' ? true : false}
                    />
                  </td>
                  <td>
                    <ADButton
                      isMatchesGenerated={isMatchesGenerated}
                      handleClick={handleCriteraChange}
                      name={'participants'}
                      Icon={_criteria.participants === 'total' ? HiCollection : HiUsers}
                      index={ind}
                      option1={_criteria.participants === 'total' ? 'total' : 'head-to-head'}
                      option2={_criteria.participants === 'total' ? 'head-to-head' : 'total'}
                      content={
                        _criteria.participants === 'total'
                          ? 'Matches between all participants will be considered.'
                          : 'Only matches between tied participants will be considered.'
                      }
                      disabled={_criteria.participants === 'none' ? true : false}
                    />
                  </td>
                  <td>
                    <SelectionField
                      isMatchesGenerated={isMatchesGenerated}
                      index={ind}
                      name={'matches'}
                      content={
                        _criteria.matches === 'All matches'
                          ? 'Matches of all results will be considered.'
                          : _criteria.matches === 'Wins'
                          ? 'Only matches resulting in a win will be considered.'
                          : _criteria.matches === 'Ties'
                          ? 'Only matches resulting in a tie will be considered.'
                          : _criteria.matches === 'Losses'
                          ? 'Only matches resulting in a loss will be considered.'
                          : 'Only matches resulting in a loss or a tie will be considered.'
                      }
                      defaultVal={_criteria.matches}
                      handleChange={handleCriteraChange}
                      options={matches}
                      disabled={_criteria.matches === 'none' ? true : false}
                    />
                  </td>
                  <td>
                    {stage?.rankingCriteria?.length > 1 && (
                      <HiX
                        size={20}
                        color="#f11857"
                        onClick={(e) => {
                          if (isMatchesGenerated) return;
                          let totalCriteria = stage.rankingCriteria.length;
                          if (totalCriteria > 1) {
                            setStageSettings((state) => ({
                              ...state,
                              rankingCriteria: state.rankingCriteria.filter(
                                (el, index) => index !== ind
                              )
                            }));
                            toast.error('Criterion Removed');
                          }
                          // else {
                          //   toast.error('Stage must have atleast one Criterion');
                          // }
                          // setList((state) => state.filter((el) => el._id !== _id));
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <button
        className="add-group-btn"
        disabled={isMatchesGenerated || (stage.rankingCriteria.length >= 10)}
        onClick={(e) => {
          e.preventDefault();
          let totalCriteria = stage.rankingCriteria.length;
          // debugger
          if (totalCriteria < 10) {
            setStageSettings((state) => ({
              ...state,
              rankingCriteria: [...state.rankingCriteria, criteria[totalCriteria]]
            }));
            toast.success('New Criterion Added Successfully');
          } else {
            toast.error("Criteria length exceeds can't add more");
          }
        }}>
        <HiPlus size={12} /> Add Criterion
      </button> */}
    </div>
  );
}

const SelectionField = ({
  isMatchesGenerated,
  options = [],
  defaultVal,
  handleChange,
  name,
  index,
  disabled,
  content
}) => {
  const select = {
    textOverflow: 'ellipsis',
    paddingRight: '30px'
  };
  return (
    <Tippy content={content} placement={'top'}>
      <select
        style={{ ...select, display: `${disabled ? 'none' : ''}` }}
        onChange={(e) => handleChange(e.target.name, e.target.value, index)}
        name={name}
        disabled={disabled || isMatchesGenerated}
        className={'form-input black-bg'}
        defaultValue={defaultVal}
        // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
      >
        <option value="" disabled>
          Select Criteria
        </option>
        {options.map((format, ind) => (
          <option key={Math.random() % 10} value={format}>
            {format}
          </option>
        ))}
      </select>
    </Tippy>
  );
};
const ADButton = ({
  option1,
  option2,
  Icon,
  index,
  handleClick,
  name,
  disabled,
  content,
  isMatchesGenerated
}) => {
  const button = {
    width: '200px',
    height: '44px',
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#02060e',
    border: '1px solid #22262e',
    textTransform: 'uppercase',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start'
  };

  // return option1 === 'Ascending' || option1 === 'total' ? (
  return (
    <Tippy content={content} placement={'top'}>
      <button
        style={{ ...button, display: `${disabled ? 'none' : 'flex'}` }}
        className="black-bg"
        name={name}
        value={option2}
        disabled={disabled || isMatchesGenerated}
        onClick={(e) => {
          e.preventDefault();
          handleClick(name, option2, index);
        }}>
        <div style={{ padding: '0 0 0 10px' }}>
          <Icon size={22} />
        </div>
        <div style={{ margin: '0 0 0 14px' }}>{option1}</div>
      </button>
    </Tippy>
  );
};
export default RankingCriteria;
