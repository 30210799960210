import io from 'socket.io-client';

export const formatData = (data, short, isBrackets) => {
  const processedData = [[], []];
  //MAKE ARRAYS WHICH CAN BE FED TO BRACKETS OBJECT
  data.matches.forEach((match) => {
    const { s, r, m } = match.id;
    if (match.teams[0]) match.teams[0].score = match.matchData?.t1Score;
    if (match.teams[1]) match.teams[1].score = match.matchData?.t2Score;
    //   console.log("team names - ", match.teams[0].team.name, " ", match.teams[1].team.name, " s : ", s, " r : ", r);
    const proccesedObject = match;
    if (processedData[s - 1][r - 1]) processedData[s - 1][r - 1].push(proccesedObject);
    else processedData[s - 1][r - 1] = [proccesedObject];
  });
  let matchIndex = 1;
  // ASSIGN MATCH NUMBERS
  processedData.forEach((track) => track.forEach((round) => round.forEach((match) => (match.index = (match.matchData.status == 'complete') ? -1 : matchIndex++))));
  //MOVE FINAL TO UPPER BRACKET
  if (!short) {
    if (processedData[1].length !== 0) {
      const lastRound = [...processedData[1][processedData[1].length - 1]];
      processedData[1].splice(processedData[1].length - 1, 1);
      processedData[0].push(lastRound);
    }
  }
  // MOVE LAST THREE ROUNDS TO THE END
  if (isBrackets) {
    if (processedData[0].length > 3 && processedData[0].length < processedData[1].length) {
      const diff = processedData[1].length - processedData[0].length;
      const elelemtnsCountOnQuarterFinal = processedData[0][processedData[0].length - 3].length;
      for (let j = 0; j < diff; j++) {
        processedData[0].splice(processedData[0].length - 3, 0, [...Array(elelemtnsCountOnQuarterFinal)]);
      }
    } else if (processedData[0].length <= 3 && processedData[0].length < processedData[1].length) {
      const diff = processedData[1].length - processedData[0].length;
      const elelemtnsCountOnQuarterFinal =
        processedData[0][processedData[0].length - 3] && processedData[0][processedData[0].length - 3].length;
      for (let j = 0; j < diff; j++) {
        processedData[0].splice(
          processedData[0].length - 3,
          0,
          [...Array(elelemtnsCountOnQuarterFinal)].map((el) => ({ hidden: true }))
        );
      }
    }
  }

  return processedData;
};

const socket = {
    _socket: undefined,
    _interval: undefined
}
export const connectSocket = (id, callback) => {
    socket._socket = io(process.env.REACT_APP_LOL_API);
    socket._socket.on("connect", () => {
        socket._socket.on(`tournament-update-${id}`, (e) => {
            callback(e);
        });
    });
    socket._socket.on("disconnect", () => {
    });
}

export const disconnectSocket = () => {
    if (socket._socket)
        socket._socket.disconnect();
}
