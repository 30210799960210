import React, { useState, useEffect } from 'react';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Button, Form, Table, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Checkbox, CheckboxSlider, Icon, SimpleTabs } from '../../../_common';
import useDidMountEffect from '../../../../helpers/useDidMountEffect';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { HiOutlineTrash } from 'react-icons/hi';
import {
  setIsAnyChangeInTab,
  setStageIndexGlobally,
  setUnSavedStages
} from '../../../../redux/action/CompetitonData';
// import './Prizes.css';
import './prizes.scss';
import { AiOutlineTrophy } from 'react-icons/ai';
import { setPrizesPreviewData } from '../../../../redux/action/previewTabsActions';
const areEqual = (prevProps, nextProps) => {
  return prevProps.count === nextProps.count;
};
const PrizesTab = ({setPreviewPrizes,/* previewPrizes */}) => {
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm({ mode: 'all' });
  const position = ['1st', '2nd', '3rd'];
  const color = ['yellow', 'gray'];
  // const [prizes, setPrizes] = useState(league.prizes);
  const [submitting, setSubmitting] = useState(false);
  const [allStagePrizes, setAllStagePrizes] = useState([]);
  const [savedData1, setSavedData1] = useState([]);
  const [activeStageTab, setActiveStageTab] = useState(0);
  const [changedStagePrizes, setChangedStagePrizes] = useState([]);
  const [unSavedStages, setUnSavedStages] = useState([]);
  const [responseloader, setResponseLoading] = useState(false);
  const [stages, setStages] = useState([]);
  const dispatch = useDispatch();
  const [unsave, setUnsaved] = useState();
  const [stageIndex, setStageIndex] = useState(0);
  const stageIndexGlobally = useSelector((state) =>
    state.COMPETITION?.StageIndex ? state.COMPETITION.StageIndex : 0
  );
  const previewPrizes = useSelector((state) => state.PREVIEW.Prizes ?? null)
  useEffect(()=>{
    dispatch(setPrizesPreviewData(allStagePrizes))
  },[allStagePrizes,changedStagePrizes,])

  useEffect(() => {
    if (unsave === true) {
      // saveStageChanges()
      dispatch(setIsAnyChangeInTab(true));
    } else {
      dispatch(setIsAnyChangeInTab(false));
    }
  }, [unsave]);

  useEffect(() => {
    // console.log('stageIndexGlobally', stageIndexGlobally, stageIndex);
    if (stageIndexGlobally !== stageIndex && stageIndexGlobally !== 0) {
      // console.log("stageIndexGlobally", stageIndexGlobally,stageIndex)
      setStageIndex(stageIndexGlobally - 1);
    }
  }, [stageIndexGlobally]);
  useDidMountEffect(() => {
    if (stageIndex !== 0) {
      if (stageIndexGlobally !== stageIndex) {
        dispatch(setStageIndexGlobally(stageIndex + 1));
      }
    }
  }, [stageIndex]);
  const competitonData = useSelector(
    (state) => state.COMPETITION.CompetitionData?.data?.allStageMatches
  );
  useEffect(() => {
    if (competitonData?.length > 0) {
      setStages(competitonData.map((stage) => stage.stageName));
    }
  }, [competitonData]);

  // Use Effect get stages
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/prizes`)
      .then(({ data }) => {
        let store = JSON.stringify(data.data);
        if(previewPrizes){
          setAllStagePrizes(previewPrizes);
          for (let i = 0; i < data.data.length; i++) {
            let type = data?.data[i].stageType
            let changed = data.data[i][type].prizes.toString() === previewPrizes[i][type].prizes.toString()
            setChangedStagePrizes(state=>[...state,!changed])
            if(!changed){
              setUnSavedStages(state=>[...state,i])
            }
          }
          setUnsaved(true)
        }else{
          setAllStagePrizes(data.data);
          for (let i = 0; i < data.data.length; i++) {
            const changedStagePrizesCopy = changedStagePrizes;
            changedStagePrizesCopy.push(false);
            setChangedStagePrizes(changedStagePrizesCopy);
          }
        }
        setSavedData1(JSON.parse(store));
      })
      .catch((err) => {
        toast.error('There was a problem in getting stages');
      });
  }, []);

  const stagePrizeChange = (stageIndex) => {
    const changedStagePrizesCopy = [...changedStagePrizes];
    changedStagePrizesCopy[stageIndex] = true;
    setChangedStagePrizes(changedStagePrizesCopy);
  };

  const changeStageHidePrize = (stageIndex) => {
    // console.log('hiiii')
    const allStagePrizesCopy = [...allStagePrizes];
    if (allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.hidePrizes =
        !allStagePrizesCopy[stageIndex].league.hidePrizes;
    else
      allStagePrizesCopy[stageIndex].tournament.hidePrizes =
        !allStagePrizesCopy[stageIndex].tournament.hidePrizes;
    // console.log( allStagePrizesCopy[stageIndex],savedData[stageIndex])
    if (savedData1 !== allStagePrizesCopy) {
      setUnsaved(true);
      saveStageChanges(stageIndex);
    }
    if (savedData1 === allStagePrizesCopy) {
      let temp = unSavedStages;
      let isIndex = temp.findIndex((e) => e === stageIndex);
      if (isIndex !== -1) {
        temp = temp.slice(0, isIndex);
        // console.log(temp);
        setUnSavedStages(temp);
      }
    }
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
  };

  const onUpdatePrize = (stageIndex, prizeIndex, value) => {
    const allStagePrizesCopy = [...allStagePrizes];

    if (allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.prizes[prizeIndex] = value;
    else allStagePrizesCopy[stageIndex].tournament.prizes[prizeIndex] = value;
    if (JSON.stringify(savedData1[stageIndex]) === JSON.stringify(allStagePrizesCopy[stageIndex])) {
      let temp = unSavedStages;
      let isIndex = temp.findIndex((e) => e === stageIndex);
      if (isIndex !== -1) {
        temp = temp.slice(isIndex, 1);
        // console.log(temp);
        setUnSavedStages(temp);
      }
    }
    if (savedData1 !== allStagePrizesCopy) {
      setUnsaved(true);
      saveStageChanges(stageIndex);
    }
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
  };

  const onAddPrize = (stageIndex) => {
    setUnsaved(true);
    saveStageChanges(stageIndex);
    const allStagePrizesCopy = [...allStagePrizes];
    if (allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.prizes.push('');
    else allStagePrizesCopy[stageIndex].tournament.prizes.push('');
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
  };

  const onDeletePrize = (stageIndex, prizeIndex) => {
    setUnsaved(true);
    saveStageChanges(stageIndex);
    const allStagePrizesCopy = [...allStagePrizes];
    if (allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.prizes.splice(prizeIndex, 1);
    else allStagePrizesCopy[stageIndex].tournament.prizes.splice(prizeIndex, 1);
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
    if (savedData1 === allStagePrizesCopy) {
      let temp = unSavedStages;
      let isIndex = temp.findIndex((e) => e === stageIndex);
      if (isIndex !== -1) {
        temp = temp.slice(0, isIndex);
        // console.log(temp);
        setUnSavedStages(temp);
      }
    }
    saveStageChanges(stageIndex);
  };
  const onSave = async (stageIndex, stage) => {
    setSubmitting(true);

    try {
      let prizes = stage.stageType === 'league' ? stage?.league?.prizes : stage?.tournament?.prizes;
      let hidePrizes =
        stage.stageType === 'league' ? stage?.league?.hidePrizes : stage?.tournament?.hidePrizes;
      await Axios.post(
        `${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${stage._id}/prizes`,
        { prizes, hidePrizes }
      );
      const changedStagePrizesCopy = [...changedStagePrizes];
      changedStagePrizesCopy[stageIndex] = false;
      setChangedStagePrizes(changedStagePrizesCopy);
      dispatch(setIsAnyChangeInTab(false));
      setUnSavedStages([]);
      toast.success('Saved');
    } catch (e) {
      console.log('ERROR : ', e);
      toast.error('There was a problem saving');
    }

    setSubmitting(false);
  };
  const ordinalSuffixOf = (i) => {
    let j = i % 10,
      k = i % 100;

    if (j === 1 && k !== 11) return i + 'st';
    if (j === 2 && k !== 12) return i + 'nd';
    if (j === 3 && k !== 13) return i + 'rd';

    return i + 'th';
  };
  const saveStageChanges = (index) => {
    // console.log(index);
    if (JSON.stringify(unSavedStages[index]) !== JSON.stringify(allStagePrizes[index])) {
      if (unSavedStages.length > 0) {
        let temp = unSavedStages;
        let isIndex = temp.findIndex((e) => e === index);
        if (isIndex == -1) {
          temp.push(index);
          setUnSavedStages(temp);
        }
      } else {
        let temp = unSavedStages;
        temp.push(index);
        setUnSavedStages(temp);
      }
    }
  };
  return (
    <div className="prizes-tab-pages-05">
      <Form className="sub-tabs-form-prizes">
        <div className="subTabs" style={stages.length > 1 ?{marginRight:'8px',display:'flex',justifyContent:'space-between',alignItems:'center'}:{marginRight:'8px',display:'flex',justifyContent:'end',alignItems:'center'}}>
          {stages.length > 1 ? (
            <SimpleTabs
              pages={stages}
              page={stageIndex}
              unSavedStages={unSavedStages}
              setPage={setStageIndex}
            />
          ) : null}
          <Button
                  className="primary button"
                  type="button"
                  variant="success"
                  onClick={() => onSave(stageIndex, allStagePrizes[stageIndex])}
                  disabled={submitting}>
                  {submitting ? 'Saving' : 'SAVE CHANGES'}
                </Button>
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}></div>
          {(
            allStagePrizes[stageIndex]?.stageType === 'league'
              ? !allStagePrizes[stageIndex]?.league?.prizes.length
              : !allStagePrizes[stageIndex]?.tournament?.prizes.length
          ) ? (
            <div className="no-prizes-div">
              <div className="circle-around-logo" style={{height:'41px',width:'41px'}}>
                <AiOutlineTrophy color="#68717e" size={20} style={{marginTop:'4px',marginLeft:'4px'}} />
              </div>
              <div className="not-created">No prizes created.</div>
              <div className="create-prizes-for-participants">
                Create prizes to let your participants know what's at stake!
              </div>
              <Button
                variant="success"
                size="sm"
                className="shareButton create-prize-btn"
                onClick={() => onAddPrize(stageIndex)}
                style={{
                  justifyContent: 'flex-end',
                  display: 'block',
                  float: 'left',
                  color: '#ffffff',
                  backgroundColor: '#3eabf0'
                }}>
                CREATE PRIZE
                {/* <FontAwesomeIcon icon={faPlus} /> ADD PRIZE */}
              </Button>
            </div>
          ) : (
            <div>
              <div className='newAddPrizeButton' style={{display:'flex', alignItems:'center', justifyContent: 'space-between' ,font:'normal normal bold 14px/18px Montserrat',margin:'8px 0 14px 0'}}>
                {`STAGE ${stageIndex+1} PRIZES`} 
                {/* <Button>hel</Button> */}
                {(
                    allStagePrizes[stageIndex]?.stageType === 'league'
                      ? !allStagePrizes[stageIndex]?.league?.prizes.length
                      : !allStagePrizes[stageIndex]?.tournament?.prizes.length
                  ) ? null : (
                    <Button
                      variant="success"
                      size="sm"
                      className="shareButton"
                      onClick={() => onAddPrize(stageIndex)}
                      style={{
                        justifyContent: 'flex-end',
                        display: 'block',
                        float: 'left'
                      }}>
                      <FontAwesomeIcon icon={faPlus} /> ADD PRIZE
                    </Button>
                  )}
              </div>
              <table className="table-of-prizes05">
                <thead>
                  <tr>
                    <th style={{ color: '#68717e',paddingLeft:'15px' }}>Place</th>
                    <th style={{ color: '#68717e' }}>Prize</th>
                    <th style={{ color: '#68717e' ,paddingLeft:'25px'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allStagePrizes[stageIndex]?.stageType === 'league' ? (
                    <>
                      {allStagePrizes[stageIndex]?.league.prizes.map((prize, prizeIndex) => {
                        return (
                          <tr key={prizeIndex}>
                            <td style={{ font: 'normal normal bold 12px/15px Montserrat' }}>
                              <div className="text-14 bold col-2" style={{paddingLeft:'15px',display:'flex',alignItems:'center'}}>
                                <Icon 
                                  name={
                                    prizeIndex === 0
                                      ? 'trophy-icon-gold'
                                      : prizeIndex === 1
                                      ? 'trophy-icon-silver'
                                      : prizeIndex === 2
                                      ? 'trophy-icon-bronze'
                                      : 'trophy-icon'
                                  }
                                  without_wrapper
                                />
                                <span className="pl-2" style={{paddingTop:'2px',fontSize:'16px'}}>{ordinalSuffixOf(prizeIndex + 1)}</span>
                              </div>
                            </td>
                            <td>
                              <input style={{marginBottom:'5px'}}
                                type="text"
                                value={`${prize}`}
                                onChange={(e) => {
                                  onUpdatePrize(stageIndex, prizeIndex, e.target.value);
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                style={{ color: 'gray', background: 'transparent', border: 'none' ,paddingLeft:'25px'}}
                                onClick={() => onDeletePrize(stageIndex, prizeIndex)}>
                                <HiOutlineTrash
                                  icon={faTrash}
                                  color="#f11857"
                                  style={{ fontSize: 'x-large' }}
                                />
                                <span>DELETE</span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {allStagePrizes[stageIndex]?.tournament.prizes.map((prize, prizeIndex) => (
                        <tr key={prizeIndex}>
                          <td style={{ font: 'normal normal bold 12px/15px Montserrat' ,paddingLeft:'15px'}}>
                            <div className="text-14 bold col-2 place-column" style={{paddingLeft:'15px',display:'flex',alignItems:'center'}}>
                              <Icon 
                                name={
                                  prizeIndex === 0
                                    ? 'trophy-icon-gold'
                                    : prizeIndex === 1
                                    ? 'trophy-icon-silver'
                                    : prizeIndex === 2
                                    ? 'trophy-icon-bronze'
                                    : 'trophy-icon'
                                }
                                without_wrapper
                              />
                              <span className="pl-2" style={{paddingTop:'2px',fontSize:'16px'}}>{ordinalSuffixOf(prizeIndex + 1)}</span>
                            </div>
                          </td>
                          <td>
                            <Form.Control
                              style={{marginBottom:'5px'}}
                              type="text"
                              defaultValue={prize}
                              onChange={(e) =>
                                onUpdatePrize(stageIndex, prizeIndex, e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <Button
                              style={{ color: 'gray', background: 'transparent', border: 'none',paddingLeft:'25px' }}
                              onClick={() => onDeletePrize(stageIndex, prizeIndex)}>
                              <HiOutlineTrash
                                icon={faTrash}
                                color="#f11857"
                                style={{ fontSize: 'x-large' }}
                              />
                              <span>DELETE</span>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
              <Col>
                <Row style={{ marginTop: '15px' }}>
                  {(
                    allStagePrizes[stageIndex]?.stageType === 'league'
                      ? !allStagePrizes[stageIndex]?.league?.prizes.length
                      : !allStagePrizes[stageIndex]?.tournament?.prizes.length
                  ) ? null : (
                    <Button
                      variant="success"
                      size="sm"
                      className="shareButton"
                      onClick={() => onAddPrize(stageIndex)}
                      style={{
                        justifyContent: 'flex-end',
                        display: 'block',
                        float: 'left'
                      }}>
                      <FontAwesomeIcon icon={faPlus} /> ADD PRIZE
                    </Button>
                  )}
                </Row>
                <Row style={{ marginTop: '15px' }}>
                  <CheckboxSlider
                    onChange={() => changeStageHidePrize(stageIndex)}
                    value={
                      allStagePrizes[stageIndex]?.stageType === 'league'
                        ? allStagePrizes[stageIndex]?.league?.hidePrizes
                        : allStagePrizes[stageIndex]?.tournament?.hidePrizes
                    }
                    label="Hide this stage’s prizes"
                  />
                </Row>
              </Col>

              <div className="mt-3">
                <Button
                  className="primary button"
                  type="button"
                  variant="success"
                  onClick={() => onSave(stageIndex, allStagePrizes[stageIndex])}
                  disabled={submitting}>
                  {submitting ? 'Saving' : 'SAVE CHANGES'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default PrizesTab;
