import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { AppContext } from '../../../context/AppContext';
import UploadLogobox from '../EditImageModal/UploadLogobox';
import EditImageModal from '../EditImageModal/EditImageModal';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import './a.css';
import { getOrganizations } from '../../../redux/action/competitionActions';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import Cropper from '../../Image/Cropper';
import useTranslated from '../../../helpers/translationHelper';
const CreateCompetitionModalFunctionality = ({ onClose, organizerdata, isEditorganizer }) => {
  // const { user } = useContext(AppContext);
  const dispatch = useDispatch();
  const lableLogo = useTranslated('id_pop-up_-_upload_image_2');
  const lableBanner = useTranslated('id_pop-up_-_upload_image_3');
  const [imgahetyle, setImanetype] = useState({});
  const [border,setborder]=useState('')
  const [nameinput, setNameinput] = useState({
    name: organizerdata ? organizerdata.name : null,
    logo: organizerdata ? organizerdata.logo : null,
    cover: organizerdata ? organizerdata.cover : null,
    thumbnail: organizerdata ? organizerdata.thumbnail : null
  });
  const [checkisempty, setIsempty] = useState(false);
  const onClickCreate = () => {
    if (nameinput.name===null || nameinput.name === '') {
      setIsempty(true);
      setborder('2px solid #f11857')
      toast.error('Name is required');
      return;
    }

    {
      isEditorganizer
        ? Axios.put(
            `${process.env.REACT_APP_CORE_API}/api/organizer/${organizerdata?._id}/byid`,
            nameinput
          )
            .then((res) => {
              dispatch(getOrganizations());
              onClose(false);
            })
            .catch((err) => toast.error(err?.msg))
        : Axios.post(`${process.env.REACT_APP_CORE_API}/api/organizer`, nameinput)
            .then((res) => {
              dispatch(getOrganizations());
              onClose(false);
            })
            .catch((err) => toast.error(err?.response?.data?.msg));
    }
  };
  const styles = {
    isredbordercolor: {
      border: '2px solid red'
    }
  };
  const fontStyleSmall={
    color:'#ffffff',
    font: 'normal normal bold 12px/15px Montserrat',
  };
  const fontStyleLarge={
    color:'#ffffff',
    font: 'normal normal bold 18px/22px Montserrat',
  }
  return (
    <div>
      <div className="form-row">
        <div class="form-row">
          <label className="nameField" for="name">
            NAME
          </label>
          <input
            className="inputField"
            type="input"
            maxLength={30}
            id="name"
            name="name"
            autocomplete="name"
            class={`form-input  ${checkisempty=== true ? 'red1' : 'input1'}`}
            style={{ border:'1px solid #8b8d91' }}
            value={nameinput.name}
            onChange={(e) => {
              
                setNameinput({ ...nameinput, name: e.target.value });
                // e.target.value !== '' ? setIsempty(false) : null ;
                return setIsempty(false);
              
            } }
            placeholder="Organizer Name"
          />
        </div>
      </div>

      <Cropper
        name={'LOGO'}
        style={fontStyleSmall}
        width={250}
        height={250}
        setNameinput={setNameinput}
        nameinput={nameinput}
        editableimg={organizerdata?.logo ? organizerdata.logo : null}
        Uploadimage={'Upload image'}
        isLogo={true}
        size={1000000}
        textunderButton={
          <div>
            <span
              style={{
                font: 'normal normal normal 10px/13px Montserrat',
                // fontSize: '12px',
                width: '136px',
                height: '13px'
              }}>
              Recommended file format:{' '}
            </span>
            {/* <strong style={{ color: '#68717e', fontWeight: '100' }}> */}
            <span
              style={{
                font: 'normal normal normal 10px/13px Montserrat',
                width: '136px',
                height: '13px',
                // fontSize: '0.75rem',
                // lineHeight: '1rem',
                // fontWeight: 'normal',
                color: ' #68717e',
                font:'normal normal normal 10px/13px Montserrat'
              }}>
              {' '}
              PNG logos with transparent backgrounds and high contrast to dark backgrounds are
              recommended. The logo should be bigger than{' '}
              <span style={{ color: '#FFFFFF' }}>250 x 250 pixels </span>
              but not exceed <span style={{ color: '#FFFFFF' }}> 1 MB.</span>
            </span>
            {/* </strong> */}
          </div>
        }
        reuploadbuttontext={'UPDATE LOGO'}
      />

      <Cropper
        width={1200}
        style={fontStyleLarge}
        height={200}
        size={2000000}
        name={'BANNER'}
        // name={'lableBanner'}
        setNameinput={setNameinput}
        nameinput={nameinput}
        editableimg={organizerdata?.cover ? organizerdata.cover : null}
        textunderHeading="The banner is displayed at the top of the organizer page."
        Uploadimage={'Upload Banner'}
        isBanner={true}
        textunderButton={
          <div>
            <strong
              style={{
                color: '#68717e',
                font: 'normal normal normal 10px/13px Montserrat',
                width: '193px',
                height: '13px'
              }}>
              Recommended size:{' '}
            </strong>
            <strong
              style={{
                color: '#FFFFFF',
                font: 'normal normal normal 10px/13px Montserrat',
                width: '88px',
                height: '13px'
              }}>
              1920 x 320 pixels {''}
            </strong>
            <strong
              style={{
                color: '#68717e',
                font: 'normal normal normal 10px/13px Montserrat',
                width: '193px',
                height: '13px'
              }}>
              {' '}
              and less than <span style={{ color: '#FFFFFF' }}>2 MB.</span>
            </strong>
          </div>
        }
        reuploadbuttontext={'UPDATE Banner'}
      />
      <Cropper
        name={'thumbnail'}
        style={fontStyleLarge}
        width={640}
        height={360}
        size={1000000}
        setNameinput={setNameinput}
        nameinput={nameinput}
        editableimg={organizerdata?.thumbnail ? organizerdata.thumbnail : null}
        Uploadimage={'Upload Thumbnail'}
        isThumbnail={true}
        textunderHeading={
          <div style={{ font: 'normal normal normal 12px/15px Montserrat', color: '#68717e' }}>
            The thumbnail is what organizers see first when browsing for events on the
            platform. If no thumbnail is uploaded , the logo will be shown.
          </div>
        }
        textunderButton={
          <div>
            <p>
              <strong
                style={{
                  color: '#68717e',
                  font: 'normal normal normal 10px/13px Montserrat',
                  width: '193px',
                  height: '13px'
                }}>
                Recommended size:{' '}
              </strong>
              <strong
                style={{
                  color: '#FFFFFF',
                  font: 'normal normal normal 10px/13px Montserrat',
                  width: '88px',
                  height: '13px'
                }}>
                {' '}
                640 x 360 pixels {''}
              </strong>
              <strong
                style={{
                  color: '#68717e',
                  font: 'normal normal normal 10px/13px Montserrat',
                  width: '193px',
                  height: '13px'
                }}>
                  {' '}
                and less than <span style={{ color: '#FFFFFF' }}>1 MB.</span>
              </strong>
            </p>
          </div>
        }
        reuploadbuttontext={'UPDATE THUMBNAIL'}
      />
      {/* <div className={`image-dropzoneonhover`}>
         <div
              style={{    display: 'flex',
                alignItems: 'center',
                justifyContent:' center',}}
                className={`image-dropzone uplodimglogo ${
             
                    'image-dropzone'
                } `}
        
                >
      
                <div className="Uploadimage">
                  
                  <HiOutlineCloudUpload style={{display:'flex',flexDirection:'column',border:'none' ,alignItem:'center',top: '362px',left: '706px',width: '18px',height: '20px',color:'#68717e',marginTop:'-12px'}} className='cloudIcon' />
                  <label
                    className="UploadimageLabel"
                    style={{border:'none'}}
             
                  >
                 hkhk
                  </label>
                  <div className="lableDragAndDrop">hi</div>
                </div>
              </div>
         </div>
      <EditImageModal
      name={'thumbnail'}
      width={640}
      height={360}
      open={false}
      size={1000000}
      setNameinput={setNameinput}
      nameinput={nameinput}
      editableimg={organizerdata?.thumbnail ? organizerdata.thumbnail : null}
      Uploadimage={'Upload Thumbnail'}
      isThumbnail={true}
      checkisempty={checkisempty}
      textunderHeading={
        <div style={{ font: '0.75rem / 1rem Montserrat', color: 'rgb(104, 113, 126)' }}>
          The thumbnail is what organizers see first when browsing for competitions on the
          platform. If no thumbnail is uploaded , the logo will be shown.
        </div>
      }
      textunderButton={
        <div>
          <p>
            <strong
              style={{
                color: '#68717e',
                font: 'normal normal normal 10px/13px Montserrat',
                width: '193px',
                height: '13px'
              }}>
              Recommended size:{' '}
            </strong>
            <strong
              style={{
                color: '#FFFFFF',
                font: 'normal normal normal 10px/13px Montserrat',
                width: '88px',
                height: '13px'
              }}>
              {' '}
              640 x 360 pixels
            </strong>
            <strong
              style={{
                color: '#68717e',
                font: 'normal normal normal 10px/13px Montserrat',
                width: '193px',
                height: '13px'
              }}>
              less than <span style={{ color: '#FFFFFF' }}>1 MB.</span>
            </strong>
          </p>
        </div>
      }
      reuploadbuttontext={'UPDATE THUMBNAIL'}
      />
  <div style={{ font: '0.75rem / 1rem Montserrat', color: 'rgb(104, 113, 126)' }}>
          The thumbnail is what organizers see first when browsing for competitions on the
          platform. If no thumbnail is uploaded , the logo will be shown.
        </div> */}
      <div
        style={{
          display: 'flex',
          // justifyContent: 'space-between',
          marginBottom: '10px'
        }}>
        <button className="button primary" style={{ width: 'auto' }} onClick={onClickCreate}>
          {isEditorganizer ? 'Edit organizer' : 'create organizer'}
        </button>
        <button
          style={{ border: '1px solid #69717e', width: '93px', marginLeft: '10px' }}
          className="button transparent"
          onClick={() =>
            setNameinput({
              name: '',
              logo: '',
              cover: '',
              thumbnail: ''
            })
          }>
          Reset
        </button>
      </div>
    </div>
  );
};

export default CreateCompetitionModalFunctionality;
