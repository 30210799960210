import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import MultipleSelect from '../../../../_common/MultiSelectDropDown/MultipleSelect';
import './stage-and-group-page.scss';
function StageAndGroupPage({ competition, payload, setPayload, hideStage, hideGroup }) {
  const [StagesArr, setStagesArr] = useState([]);
  const [GroupsArr, setGroupsArr] = useState([]);

  useEffect(() => {
    let _stagesArr = competition?.stages.map((el) => ({ label: el.name, value: el._id }));
    setStagesArr(_stagesArr);
  }, [competition]);
  useEffect(() => {
    if (payload.stage) {
      setAllGroups(payload.stage);
    }
  }, [payload]);
  const setAllGroups = (value) => {
    let index = competition.stages.findIndex((el) => el._id === value);
    let _groupsArr = [];
    if (payload.groups.length > 0) {
      _groupsArr = competition.stages[index].groups.map((grp) => {
        if (payload.groups.includes(grp._id)) {
          return {
            ...grp,
            label: grp.name,
            value: grp.name,
            selected: true
          };
        } else {
          return {
            ...grp,
            label: grp.name,
            value: grp.name,
            selected: false
          };
        }
      });
    } else {
      _groupsArr = competition.stages[index].groups.map((grp) => ({
        ...grp,
        label: grp.name,
        value: grp.name,
        selected: false
      }));
    }
    setGroupsArr(_groupsArr);
  };
  const onStageAndGroupChange = ({ name, value }) => {
    if (value != false) {
      setPayload((state) => {
        switch (name) {
          case 'stage':
            return {
              ...state,
              stage: value,
              groups: []
            };
          case 'groups':
            return {
              ...state,
              groups: value
            };
          default:
            return state;
        }
      });
    }
  };
  const handleGroupSelect = (selected, updatedOptions) => {
    ///We need to update the options provided to multiselect
    setGroupsArr(updatedOptions);
    //now we have selected Ids
    onStageAndGroupChange({ name: 'groups', value: selected });
  };
  return (
    <div className="stageandgrouppage">
      <h2>Stage and groups</h2>
      {!hideStage && (
        <Row className="stage-row">
          <h4>Choose Stage</h4>
          <h5>Choose the stage you want to generate matches for.</h5>
          <div style={{ marginTop: '10px', width: '100%' }}>
            <select
              name="stage"
              onChange={(e) => onStageAndGroupChange(e.target)}
              value={payload?.stage ?? '_DEFAULT'}
              className={'form-input'}>
              <option key={''} value={false}>
                Choose Stage
              </option>
              {StagesArr.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </Row>
      )}
      {!hideGroup && (
        <Row className="stage-row">
          <h4>Choose Group</h4>
          <h5>Choose the groups you want to generate matches for.</h5>
          <div style={{ marginTop: '10px', width: '100%' }}>
            <MultipleSelect
              options={GroupsArr}
              handleChange={handleGroupSelect}
              placeholder="Choose Group(s)"
            />
          </div>
        </Row>
      )}
    </div>
  );
}

export default StageAndGroupPage;
