import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import DynamicSearch from '@common/DynamicSearch/DynamicSearch';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { ShareTabs } from '@common';
import useTranslated from '../../../helpers/translationHelper';
import './_sharePopup.scss';
import Customize from './Customize';
import { useSelector } from 'react-redux';
import {
  HiFolder,
  HiKey,
  HiUsers,
  HiPencilAlt,
  HiEye,
  HiChevronDown,
  HiStar,
  HiEyeOff
} from 'react-icons/hi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
let backend = process.env.REACT_APP_CORE_API;

const SharePopup = ({ open, onClose, owners, folderName }) => {
  const { id } = useParams();
  const timeout = useRef();
  const btnRef = useRef();
  const [loading, setLoading] = useState(false);
  const [userResults, setUserResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [defaultselectedMembers, setdefaultelectedMembers] = useState([]);
  const [showoption, setshowoptions] = useState(null);
  const [showoptionflag, setshowoptionsflag] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedUserr, setSelectedUserr] = useState();
  const ref = useRef(null);
  const ref2 = useRef(null);
  const foldera = useSelector((state) =>
    state.FOLDERS.InheritedPermissions ? state.FOLDERS.InheritedPermissions : []
  );
  const competitionInherited = useSelector((state) => state.CONTEXTMENU.ContextObj.item ?? {});
  const [boxposition, setBoxposition] = useState({ scalex: null, scaley: null });

  const basicroles = [
    { role: 'Owner', text: '', icon: '' },
    { role: 'Custom', text: '', icon: '' },
    {
      role: 'Manager',
      text: 'Rights to change everything within the organizer and competitions.',
      icon: 'HiFolder'
    },
    { role: 'Admin', text: 'Full control over every part of a competition', icon: 'HiKey' },
    { role: 'Editor', text: 'Manage participants and matches', icon: 'HiUsers' },
    { role: 'Moderator', text: 'Update scores for matches', icon: 'HiPencilAlt' },
    { role: 'Spectator', text: 'Only access to observe folders and competitions', icon: 'HiEye' }
  ];
  // useEffect(()=>{
  //   tippy(ref2.current, {
  //     content: document.querySelector('#my-content'),
  //     interactive: true,
  //     placement: 'bottom',
  //     trigger: 'click',
  //     onShow(instance) {
  //       // This function will be called when the tooltip is shown
  //       document.querySelector('#my-button').addEventListener('click', () => {
  //         // This function will be called when the button is clicked
  //         alert('Button clicked!');
  //         instance.hide();
  //       });
  //     },
  //   });

  // },[])

  useEffect(() => {
    // console.log(selectedMembers , "selectedMembers")
    if (open === true) {
      setSelectedMembers([]);
      setdefaultelectedMembers([]);
      if (owners?.type === 'COMPETITION') {
        let permission = competitionInherited?.topPermission ?? [];
        let data = permission.map((elm) => {
          return {
            user: elm.user._id,
            img: elm?.user?.avatarImage || null,
            name: elm.userPermission.name ? elm.userPermission.name : null,
            permission: elm.userPermission.permission,
            title: elm.user.firstName + ' ' + elm.user.lastName,
            userPermission: elm.userPermission.permission,
            islock: true
          };
        });
        setdefaultelectedMembers([...data]);
      }
      if (foldera.length > 0) {
        let data = foldera.map((elem) => {
          return {
            user: elem.user._id,
            img: elem?.user?.avatarImage || null,
            name: elem.userPermission.name ? elem.userPermission.name : null,
            permission: elem.userPermission.permission,
            title: elem.user.firstName + ' ' + elem.user.lastName,
            userPermission: elem.userPermission.permission,
            islock: true
          };
        });
        // console.log('data', data);
        setdefaultelectedMembers([...selectedMembers, ...data]);
      }
      let url = '';
      if (owners?.type === 'FOLDER') {
        url = backend + `/api/organizer/folder/getfolderRoles/${owners._id}`;
      } else if (owners?.type === 'ORGANIZER') {
        url = backend + `/api/organizer/getorganizerRoles/${owners._id}`;
      } else if (owners?.type === 'COMPETITION') {
        url = backend + `/api/organizer/competition/getCompetitionRoles/${owners._id}`;
      }
      Axios.get(url)
        .then((res) => {
          let data = res.data.map((elem) => {
            return {
              // ...elem.user,
              user: elem.user._id,
              img: elem?.user?.avatarImage,
              name: elem.userPermission.name ? elem.userPermission.name : null,
              permission: elem.userPermission.permission,
              title: elem.user.firstName + ' ' + elem.user.lastName,
              userPermission: elem.userPermission.permission
            };
          });
          setSelectedMembers([...selectedMembers, ...data]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectedMembers([]);
    }
  }, [open]);

  const addMember = (player) => {
    let roles = [];
    switch (player.type) {
      case 'User':
        roles = ['Manager', 'Owner'];
        break;
      default:
    }
    player = {
      ...player,
      user: player._id,
      name: 'Spectator',
      permission: {
        manageFolderStructure: false,
        changePermission: false,
        createAndDeleteCompetitions: false,
        administrateCompetitions: false,
        editFormat: false,
        manageParticipants: false,
        editMatches: false,
        setMatchScore: false
      }
    };
    // setSelectedUser({ ...data, role: "Owner" })}
    setSelectedMembers([{ ...player }, ...selectedMembers]);
  };
  const setSelectedUsersRole = (index, role) => {
    let memberData = selectedMembers;
    if (memberData.length > 0) {
      // debugger
      memberData[index].name = role;
      memberData[index].permission.administrateCompetitions =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? true
          : role === 'Editor'
          ? false
          : role === 'Moderator'
          ? false
          : role === 'Spectator'
          ? false
          : 'Custom';
      memberData[index].permission.changePermission =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? false
          : role === 'Editor'
          ? false
          : role === 'Moderator'
          ? false
          : role === 'Spectator'
          ? false
          : 'Custom';
      memberData[index].permission.createAndDeleteCompetitions =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? true
          : role === 'Editor'
          ? false
          : role === 'Moderator'
          ? false
          : role === 'Spectator'
          ? false
          : 'Custom';
      memberData[index].permission.editFormat =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? true
          : role === 'Editor'
          ? false
          : role === 'Moderator'
          ? false
          : role === 'Spectator'
          ? false
          : 'Custom';
      memberData[index].permission.editMatches =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? true
          : role === 'Editor'
          ? true
          : role === 'Moderator'
          ? false
          : role === 'Spectator'
          ? false
          : 'Custom';
      memberData[index].permission.manageFolderStructure =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? false
          : role === 'Editor'
          ? false
          : role === 'Moderator'
          ? false
          : role === 'Spectator'
          ? false
          : 'Custom';
      memberData[index].permission.manageParticipants =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? true
          : role === 'Editor'
          ? true
          : role === 'Moderator'
          ? false
          : role === 'Spectator'
          ? false
          : 'Custom';
      memberData[index].permission.setMatchScore =
        role === 'Owner'
          ? true
          : role === 'Manager'
          ? true
          : role === 'Admin'
          ? true
          : role === 'Editor'
          ? true
          : role === 'Moderator'
          ? true
          : role === 'Spectator'
          ? false
          : 'Custom';
      setSelectedMembers(JSON.parse(JSON.stringify(memberData)));
    }
  };

  useEffect(() => {
    setUserResults([]);
    setSelectedUser(false);
  }, [open]);

  const onUsersSearch = async (query) => {
    if (!query.length) return setUserResults([]);
    setLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/users/search`, {
        query
      });
      // Map data to required output, and remove current owners
      const players = data
        // .filter(({ _id }) => (owners === _id ? false : true))
        .map(({ _id, avatarImage, email, firstName, lastName, name }) => ({
          _id,
          img: avatarImage,
          title: firstName + ' ' + lastName

          // subtitle: email,
        }));
      let iddd = '';
      let newArr = players?.filter((ele) => {
        // console.log('ele.user,query',ele,query)
        if (ele?.title?.includes(query)) {
          return selectedMembers.map((member) => {
            if (member?.user != ele?._id) {
              return ele;
            } else if (member?.user == ele?._id) {
              iddd = ele?._id;
            }
          });
        }
      });
      setUserResults(newArr.filter((ele) => ele._id !== iddd));
      setLoading(false);
    }, 500);
  };

  const onAddOwner = async () => {
    if (!selectedMembers) {
      onClose();
      return false;
    }

    try {
      await Axios.post(
        `${process.env.REACT_APP_CORE_API}/api/organizer/${
          owners?.type === 'FOLDER'
            ? 'folder/shareFolder'
            : owners?.type === 'COMPETITION'
            ? 'competition/shareCompetition'
            : 'shareOrganizer'
        }`,
        {
          [owners?.type === 'FOLDER'
            ? 'folder'
            : owners?.type === 'COMPETITION'
            ? 'folder'
            : 'organizer']: owners._id,
          Roles: selectedMembers
        }
      );
      toast.success('Successfully invited user');
      onClose();
    } catch (e) {
      toast.error('There was a problem inviting selected user');
    }
  };

  const getRole = (user) => {
    let role = basicroles.filter((role) => role.role === user?.name);
    if (role[0]?.role) {
      return role[0].role;
    } else {
      return user?.name;
    }
  };

  const selectSpecficUSerHandler = (selectedUser) => {
    setSelectedUserr(selectedUser.name);
  };

  return (
    <>
      <Modal isOpen={open} onRequestClose={onClose} className="modal modal-sharepop">
        <div className="modal-header share-popup-modalheader" style={{ padding: '0 25px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginRight: '-7px'
            }}
            onClick={onClose}>
            <h2 style={{ textAlign: 'left', marginBottom: '10px' }}>Share {folderName}</h2>
            <RiCloseLine style={{ color: ' #68717e', fontSize: '30px', marginTop: '10px' }} />
          </div>
          {/* <h3>{useTranslated('id_pop-up_-_add_owner_or_manager_1')}</h3> */}
        </div>
        <div
          className="modal-body"
          style={{ overflow: showoptionflag === true ? 'scroll' : 'hidden', marginLeft: '-10px' }}>
          <div className="sharepop-searchbar">
            <DynamicSearch
              placeholder={'Add people to share with '}
              onSearch={onUsersSearch}
              onSelectResult={addMember}
              // onSelectResult={(data) => setSelectedUser({ ...data, role: "Owner" })}
              loadingResults={loading}
              results={userResults}
              resultStyle={{ marginTop: '10px', width: '102%' }}
            />
          </div>
          <div className="sharepopup-tab">
            <ShareTabs
              pages={[
                'All',
                // "Customized",
                'Customize'
              ]}
              totalmembers={defaultselectedMembers.length + selectedMembers.length}
              page={page}
              setPage={setPage}
            />
          </div>

          {page !== 1 ? (
            <div
            // className="dynamic-search-selected-items user-container"
            // style={{ overflow:showoptionflag===true? 'hidden':"scroll"  }}
            >
              {/* {selectedUser && ( */}
              {/* <div className="uppercase bold mt-1 customize-inner-heading">
              
              </div> */}
              <div
                style={{
                  font: 'normal normal bold 12px/15px Montserrat',
                  width: '135px',
                  height: '15px',
                  marginTop: '30px',
                  marginBottom: '-5px',
                  // marginTop:'-2px',
                  textTransform:'uppercase',
                }}>
                Users With access
              </div>

              <div
                className="dynamic-search-selected-items "
                style={{ margin: '12px -10px 5px 0px'}}>
                {defaultselectedMembers &&
                  defaultselectedMembers.map((selectedUser, i) => (
                    <div className="selected-item" style={{ marginBottom: 'auto' }}>
                      <div className="item-details customize-item mt-10px pl-1">
                        <div className="item-img" style={{ marginTop: '5px' }}>
                          {selectedUser?.img ? (
                            <div
                              className="search-result-img"
                              style={{
                                backgroundImage: `url(${selectedUser?.img})`
                              }}
                            />
                          ) : (
                            <div className="profile-img-with-inital-1-letter display-flex">
                              {selectedUser?.title.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="item-name" style={{ marginTop: '5px' }}>
                            <p style={{ font: '12px / 15px Montserrat' }}>{selectedUser?.title}</p>
                            <span style={{ color: '#676f7c', font: '10px / 13px Montserrat' }}>
                              {getRole(selectedUser)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <select
                        name="role"
                        id="role"
                        className="form-input"
                        defaultValue={getRole(selectedUser)}
                        // value={selectedUser.role}
                        style={{ borderRadius: '25px' }}
                        onChange={(e) => setSelectedUsersRole(i, e.target.value)}
                        disabled>
                        <option value="_EMPTY_" disabled>
                          Select Role
                        </option>
                        {basicroles.map((role) => (
                          <option value={role.role} disabled>
                            {role.role}
                          </option>
                        ))}
                      </select> */}
                      {/* <button
                        onClick={() =>
                          setSelectedMembers(selectedMembers.filter((val, key) => key !== i))
                        }>
                        <RiCloseLine
                          className="selected-user-delete"
                          size={27}
                          style={{ color: 'red' }}
                        />
                      </button> */}
                    </div>
                  ))}
                {selectedMembers &&
                  selectedMembers.map((selectedUser, i) => (
                    <div className="selected-item no-select" key={i}>
                      <div
                        className="item-details customize-item"
                        // style={{ margin: '0px 0px 2px 0px', padding: '5px 0px 13px 0px' }}
                      >
                        <div
                          className="item-img"
                          style={{ marginLeft: '5px', marginBottom: '0px' }}>
                          {selectedUser?.img ? (
                            <div
                              className="search-result-img"
                              style={{ backgroundImage: `url(${selectedUser?.img})`,height:'30px',width:'30px',margin:'0px' }}
                            />
                          ) : (
                            <div className="profile-img-with-inital-1-letter">
                              {selectedUser?.title.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="item-name" style={{ marginLeft: '-7px' }}>
                            <p
                              style={{
                                font: 'normal normal normal 12px/15px Montserrat',
                                width: '167px',
                                height: '15p'
                              }}>
                              {selectedUser?.title}
                            </p>
                            <span
                              style={{
                                color: '#68717E',
                                font: 'normal normal normal 12px/15px Montserrat',
                                width: '58px',
                                height: '13px'
                              }}>
                              {getRole(selectedUser)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='sharepopup-select-option'>
                        <Tippy
                          key={getRole(selectedUser)}
                          content={
                            <ShowOptions
                              basiclength={basicroles.length}
                              selectedUserr={selectedUserr}
                              setSelectedUsersRole={setSelectedUsersRole}
                              index={showoption}
                              boxposition={boxposition}
                              onSelect={setshowoptionsflag}
                            />
                          }
                          interactive={true}
                          placement="bottom-start"
                          animation="shift-away"
                          trigger="click">
                          <button
                            ref={ref2}
                            className=" show-dropdown-arrow dropdown-background-color"
                            onClick={(e) => {
                              selectSpecficUSerHandler(selectedUser);
                              setshowoptions(i);
                              setshowoptionsflag(!showoptionflag);
                              setBoxposition({ scalex: e.clientX, scaley: e.clientY - 100 });
                            }}
                            // onChange={(e) => setSelectedUsersRole(i, e.target.value)}
                            disabled={getRole(selectedUser) === 'Owner' ? 'disabled' : ''}>
                            {getRole(selectedUser)}
                            <HiChevronDown size={22} />
                          </button>
                        </Tippy>
                      </div>
                      <button
                        disabled={selectedUser.name === 'Owner'}
                        onClick={() =>
                          setSelectedMembers(selectedMembers.filter((val, key) => key !== i))
                        }>
                        <RiCloseLine
                          className="selected-user-delete"
                          size={27}
                          style={{ color: selectedUser.name !== 'Owner' ? 'red' : 'transparent' }}
                        />
                      </button>
                    </div>
                  ))}
              </div>
              {
                // <div
                //   ref={ref}
                //   className="aaa"
                //   style={{
                //     // display: 'block',
                //     // background: '#0f1116',
                //     // border: '1px solid #22262e',
                //     // position: 'fixed',
                //     // width: '85%',
                //     // zIndex: '99999',
                //     // maxHeight: '370px',
                //     // overflow: 'scroll',
                //     // left: '88%',
                //     // top: boxposition.scaley
                //   }}>
                // <ShowOptions
                //   basiclength={basicroles.length}
                //   selectedUserr={selectedUserr}
                //   setSelectedUsersRole={setSelectedUsersRole}
                //   index={showoption}
                //   boxposition={boxposition}
                //   onSelect={setshowoptionsflag}
                // />
                // </div>
              }
            </div>
          ) : (
            <>
              <div className="share-popup-customize">
                <Customize
                  selectedMembers={selectedMembers}
                  setSelectedUsersRole={setSelectedUsersRole}
                  setSelectedMembers={setSelectedMembers}
                />
              </div>
            </>
          )}
          {page !== 1 ? (
            <button
              style={{ width: 'auto', marginLeft: '85%' }}
              className="button primary "
              onClick={onAddOwner}
              // disabled={!selectedMembers?.length}
            >
              Done
            </button>
          ) : (
            <div className="next-buttons">
              <button
                className="button transparent"
                style={{ width: 'auto' }}
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                style={{ width: 'auto' }}
                className="button primary "
                onClick={onAddOwner}
                disabled={!selectedMembers.length}>
                Save
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SharePopup;

export const ShowOptions = ({
  basiclength,
  selectedUserr,
  setSelectedUsersRole,
  index,
  onSelect,
  boxposition
}) => {
  const [hoverI, setHoverI] = useState(null);
  // console.log({setSelectedUsersRole, index, onSelect,})
  const basicroles = [
    { role: 'Owner', text: '', Icon: HiKey },
    { role: 'Custom', text: '', Icon: HiKey },
    {
      role: 'Manager',
      text: 'Rights to change everything within the organizer and competitions.',
      Icon: HiFolder
    },
    { role: 'Admin', text: 'Full control over every part of a competition.', Icon: HiKey },
    { role: 'Editor', text: 'Manage participants and matches.', Icon: HiUsers },
    { role: 'Moderator', text: 'Update scores for matches.', Icon: HiPencilAlt },
    { role: 'Spectator', text: 'Only access to observe folders and competitions.', Icon: HiEye },
    {
      role: 'Transfer ownership ',
      text: 'Transfer the ownership of the organizer to this user.',
      Icon: HiStar
    },
    {
      role: 'Remove access',
      text: 'Remove the user from list of people with access.',
      Icon: HiEyeOff
    }
  ];

  return (
    <div id="my-content-div">
      {basicroles.map((role, i) => {
        const { Icon } = role;
        return (
          <div
            onMouseEnter={(e) => setHoverI(i)}
            onMouseLeave={(e) => setHoverI(null)}
            onClick={(e) => {
              setSelectedUsersRole(index, role.role);
              onSelect(false);
            }}
            id={role.role}
            value={role.role}
            style={role.role === 'Owner' || role.role === 'Custom' ? { display: 'none' } : {}}>
            <div
              value={role.role}
              style={{
                display: 'flex',
                flexDirection: 'row',
                // padding: '15px 25px',
                padding: '10px 5px',
                // marginLeft: '25px',
                // marginTop: '10px',
                // background: `${hoverI === i ? '#0a0c0f' : '#0f1116'}`,
                background: `${
                  role.role === selectedUserr ? '#0a0c0f' : hoverI === i ? '#0a0c0f' : '#0f1116'
                }`
              }}>
              <div
                style={
                  role.role === selectedUserr
                    ? {
                        alignSelf: 'center',
                        color: '#3eabf0',
                        marginLeft: '-13px',
                        selected: 'selected'
                      }
                    : { alignSelf: 'center', marginLeft: '-13px', color: '#68717e' }
                }
                value={role.role}>
                {/* <Icon
                  value={role.role}
                  style={{
                    border: '1px solid #21252b',
                    borderRadius: '49%',
                    background: '#21252b',
                    fontSize: '2em',
                    marginRight: '30px',
                    width: '1.5em',
                    padding: '10px',
                    height: '1.5em'
                  }}
                /> */}
                <Icon
                  value={role.role}
                  style={
                    //   border: '1px solid #21252b',
                    //   borderRadius: '49%',
                    //   background: '#21252b',
                    //   fontSize: '2em',
                    //   //color: '#68717e',
                    //   marginRight: '30px',
                    //   //width: '1.5em',
                    //   padding: '10px',
                    //   //height: '1.5em',
                    //   height: '1.5em',
                    //   width: '1.5em'
                    role.role === selectedUserr
                      ? {
                          border: '1px solid #21252b',
                          borderRadius: '49%',
                          background: '#21252b',
                          fontSize: '2em',
                          //color: '#68717e',
                          marginRight: '30px',
                          background: '#13344d',
                          //width: '1.5em',
                          padding: '10px',
                          //height: '1.5em',
                          height: '1.5em',
                          width: '1.5em',
                          marginLeft: '20px'
                        }
                      : {
                          border: '1px solid #21252b',
                          borderRadius: '49%',
                          background: '#21252b',
                          fontSize: '2em',
                          //color: '#68717e',
                          marginRight: '30px',
                          //width: '1.5em',
                          padding: '10px',
                          //height: '1.5em',
                          height: '1.5em',
                          width: '1.5em',
                          marginLeft: '20px'
                        }
                  }
                />
              </div>
              <div style={{ marginTop: 'auto', marginBottom: 'auto' }} value={role.role}>
                <div
                  style={{
                    fontSize: 'revert',
                    fontWeight: '700',
                    textTransform: 'UPPERCASE',
                    marginLeft: '-18px',
                    font: 'bold 12px/15px Montserrat'
                  }}
                  value={role.role}>
                  {role.role}
                </div>
                <div
                  style={{
                    fontSize: 'small',
                    color: 'grey',
                    marginLeft: '-18px',
                    font: '10px/13px Montserrat'
                  }}
                  value={role.role}>
                  {role.text}
                </div>
              </div>
            </div>
            {basiclength - 1 === i && <hr style={{ marginLeft: '20px', width: '490px' }} />}
          </div>
        );
      })}
    </div>
  );
};
