import React, { useState, useEffect, useReducer } from 'react';
// import 'antd/dist/antd.css';
import { HiFolder, HiDotsHorizontal } from 'react-icons/hi';
import './list-view.scss';
import { Button, Space, Table } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { TiArrowUnsorted } from 'react-icons/ti';
import ShareOptions from '../../_common/ShareOptions/ShareOptions';
import { useSelector } from 'react-redux';
import moment from 'moment';
const ListView = ({ alldata, getTrans }) => {
  const isTrash = false;
  const [sortedData, setSortedData] = useState(alldata);
  const [org,setOrg]=useState(false)
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    setSortedData(alldata);
    setOrg(true)
    // console.log(alldata,'----------')
  }, [alldata]);
  let showhead = { today: false, week: false, month: false, year: false, older: false };
  alldata.map((folder) => {
    if (folder.whichday === 'today') {
      showhead.today = true;
    }
    if (folder.whichday === 'Earlier this week') {
      showhead.week = true;
    }
    if (folder.whichday === 'Earlier this month') {
      showhead.month = true;
    }
    if (folder.whichday === 'Earlier this year') {
      showhead.year = true;
    }
    if (folder.whichday === 'Older') {
      showhead.older = true;
    }
  });
  // console.log(showhead)
  const timeZone = useSelector((state) => state.TIMEZONE);
  const Competitions=useSelector((state) => state.FOLDERS.Competitions);


  const getDate = (date) => {
    if (!timeZone) {
      return date;
    } else {
      return moment(date).tz(timeZone?.timeZone);
    }
  };
  const sort = (col) => {
    // console.log(col);
    switch (col) {
      case 'Name':
        {
          let list = sortedData;
          // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
          list.sort(function (a, b) {
            // console.log((a.name<b.name))
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          // list.sort((a,b)=>b.name-a.name)
          // console.log(alldata)
          // console.log(list)
          setSortedData(list)
          forceUpdate();
        }
        break;
      case 'TOURNAMENTDATE':
        {
          let list = sortedData;
          // list.sort((a,b)=> a.createdDate? new Date(b.createdDate) - new Date(a.createdDate):'')
          list.sort(function (a, b) {
            if (new Date(a.createdDate) < new Date(b.createdDate)) {
              return -1;
            }
            if (new Date(a.createdDate) > new Date(b.createdDate)) {
              return 1;
            }
            return 0;
          });
          // console.log(alldata)
          // console.log(list)
          setSortedData(list)
          forceUpdate();
        }
        break;
      case 'MATCHES':
        {
          let list = sortedData;
          // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
          list.sort(function (a, b) {
            // console.log((a.name<b.name))
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          // console.log(list)
          setSortedData(list);
          forceUpdate();
        }
        break;
      case 'ORG':
        {
          let list = sortedData;
          // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
          list.sort(function (a, b) {
            // console.log((a.name<b.name))
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          // console.log(list)
          setSortedData(list);
          forceUpdate();
        }
        break;
      case 'FOLDER':
        {
          let list = sortedData;
          // list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
          list.sort(function (a, b) {
            // console.log((a.name<b.name))
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          // console.log(list)
          setSortedData(list);
          forceUpdate();
        }
        break;
      case 'ORGANIZER':
        {
          let list = sortedData;
          list.sort(function (a, b) {
            if (a.organizer < b.organizer) {
              return -1;
            }
            if (a.organizer > b.organizer) {
              return 1;
            }
            return 0;
          });
          setSortedData(list);
          forceUpdate();
        }
        break;
      case 'LASTMODIFIED':
        {
          let list = sortedData;
          list.sort(function (a, b) {
            if (new Date(a.lastEditedDate) < new Date(b.lastEditedDate)) {
              return -1;
            }
            if (new Date(a.lastEditedDate) > new Date(b.lastEditedDate)) {
              return 1;
            }
            return 0;
          });
          // console.log(list);
          setSortedData(list);
          forceUpdate();
        }
        break;

      case 'RESET':
        setSortedData(alldata);
        break;
      default:
        //you might want to do something as default, I don't
        break;
      // setSort(sort+1);
    }
  };
  return (
    <>
      <table
        className="table"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th scope="col" style={{ width:'20%'}}>
              <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  NAME
                </div>
                <div>
                  <TiArrowUnsorted
                    onClick={() => sort('Name')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width:'17%'}}>
            <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center'}}>
              <div>
                TOURNAMENT DATE
              </div>
              <div>
                <TiArrowUnsorted
                  onClick={() => sort('TOURNAMENTDATE')}
                  style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                />
              </div>
            </div>
            </th>
            <th scope="col" style={{ width:'15%'}}>
              <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  MATCHES
                </div>
                <div>
                  <TiArrowUnsorted
                    onClick={() => sort('MATCHES')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            {/* {org ? <th scope="col" style={{ width:'10%'}}>
              <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center'}}>
                
              </div>
            </th>: <th scope="col" style={{ width:'10%'}}>
              <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center'}}>
                <div>
                  ORG.
                </div>
                <div>
                  <TiArrowUnsorted
                    onClick={() => sort('MATCHES')}
                    style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th> } */}
            <th scope="col" style={{ width:'15%'}}>
              <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  FOLDER
                </div>
                <div>
                  <TiArrowUnsorted
                    onClick={() => sort('MATCHES')}
                    style={{ width: '15px', marginTop: '2px', cursor: 'pointer',marginLeft:'3px' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width:'15%'}}>
              <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  ORGANIZER
                </div>
                <div>
                  <TiArrowUnsorted
                    onClick={() => sort('MATCHES')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th scope="col" style={{ width:'13%'}}>
              <div className='for-arrow-recents' style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  LAST MODIFIED
                </div>
                <div>
                  <TiArrowUnsorted
                    onClick={() => sort('LASTMODIFIED')}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </th>
            <th style={{ width:'5%'}}></th>
          </tr>
        </thead>
        {showhead.today === true ? (
          <>
            <h3>{showhead.today === true ? 'TODAY' : null}</h3>
            <tbody>
              {sortedData.map((folder, ind) => {
                return folder.timedifference < 25 ? (
                  <tr key={ind} id={folder._id} className="hoverable-row">
                    <td  style={{ width:'20%'}}>
                      <Col className="name-row">
                        {folder?.isfolder === true ? (
                          <Row>
                            <HiFolder size={25} />
                            {folder.name}
                          </Row>
                        ) : (
                          <Row>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <span className="imgLogoContainer">
                                <img
                                  className=" imgLogoWidth imgLogo  imgLogoCropped"
                                  src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                                  alt="Insight Gaming"
                                  width={'10px'}
                                />
                              </span>
                              <span
                                style={{
                                  maxWidth: '175px',
                                  textOverflow: 'ellipsis',
                                  marginLeft: '300px',
                                  height: '18px',
                                  width: '235px',
                                  padding: '0px',
                                  overflow: 'hidden',
                                  position: 'relative',
                                  display: 'inline-block',
                                  margin: '0 5px 0 5px',
                                  textAlign: 'left',
                                  textDecoration: 'none',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap'
                                }}>
                                {folder.name}
                              </span>
                            </div>
                          </Row>
                        )}
                      </Col>
                    </td>
                    {/* <td>{moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                    <td style={{ width:'17%'}}></td>
                    <td style={{ width:'15%'}}></td>
                    <td style={{ width:'15%'}}>
                      <Col className="org-name">
                        <Row>
                          <HiFolder size={15} />
                          <span className='ml-1'>{ folder.iscompetition ? folder?.folder?.name : ''}</span>
                        </Row>
                      </Col>
                    </td>
                    {/* <td>
                      <Col className="org-name">
                        <Row>
                          <HiFolder size={16} />
                          PRIME LEAGUE
                        </Row>
                      </Col>
                    </td> */}
                    <td style={{ width:'15%'}}>
                      <Row>
                        <Col lg={3} className='r-org-img'><img src={folder?.organizer?.logo} className='img-fluid' /></Col>
                        <Col className="me-organizer"><strong style={{color:'#8fa3b1'}}>{folder?.organizer?.name}</strong></Col>
                      </Row>
                    </td>
                    <td style={{ width:'13%'}}>
                      <Col className="last-modified">
                        {' '}
                        {/* {moment(getDate(folder?.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss')} */}
                        {folder?.lastEditedDate
                        ? moment(getDate(folder?.lastEditedDate)).format('MM-DD-YYYY')+
                        ' - ' +
                        folder?.lastEditor?.firstName +
                        ' ' +
                        folder?.lastEditor?.lastName
                      : undefined}
                      </Col>{' '}
                    </td>
                    <td style={{ width:'5%'}}>
                      <Col className="options-dots">
                        {folder?.isfolder === true ? (
                          <ShareOptions
                          item={folder}
                          permissions={{ showOptions: true, delete: true,share: true }}
                          type={'COMPETITION'}
                          />
                        ) : (
                          <ShareOptions
                          item={folder}
                          permissions={{ showOptions: true, delete: true,share: true }}
                          type={'COMPETITION'}
                          />
                        )}
                      </Col>
                    </td>
                  </tr>
                ) : null
                        })}
            </tbody>
          </>
        ) : null}
      </table>
      <h3>{showhead.week === true ? 'EARLIER THIS WEEK' : null}</h3>
      {showhead.week === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {sortedData.map((folder, ind) =>
              folder.timedifference < 168 && folder.timedifference > 24 ? (
                <tr key={ind} id={folder._id} className="hoverable-row">
                  <td style= {{width:'20%'}}>
                    <Col className="name-row">
                      {folder?.isfolder === true ? (
                        <Row>
                          <HiFolder size={25} />
                          {folder.name}
                        </Row>
                      ) : (
                        <Row>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span className="imgLogoContainer">
                              <img
                                className="imgLogoWidth imgLogo  imgLogoCropped"
                                src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                                alt="Insight Gaming"
                                width={'10px'}
                              />
                            </span>
                            <span
                              style={{
                                maxWidth: '175px',
                                textOverflow: 'ellipsis',
                                marginLeft: '300px',
                                height: '18px',
                                width: '235px',
                                padding: '0px',
                                overflow: 'hidden',
                                position: 'relative',
                                display: 'inline-block',
                                margin: '0 5px 0 5px',
                                textAlign: 'left',
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}>
                              {folder.name}
                            </span>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </td>
                  {/* <td>{moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                  <td style= {{width:'17%'}}></td>
                  <td style= {{width:'15%'}}></td>
                  {/* <td></td> */}
                  <td style= {{width:'15%'}}>
                    <Col className="org-name">
                      <Row>
                        <HiFolder size={15} />
                        <span className='ml-1'>{ folder.iscompetition ? folder?.folder?.name : ''}</span>
                      </Row>
                    </Col>
                  </td >
                  {/* <td>
                    <Col className="org-name">
                      <Row>
                        <HiFolder size={16} />
                        PRIME LEAGUE
                      </Row>
                    </Col>
                  </td> */}
                  <td style= {{width:'15%'}}>
                  <Row>
                      <Col lg={3} className='r-org-img'><img src={folder?.organizer?.logo} className='img-fluid r-org-img' /></Col>
                      <Col className="me-organizer"><strong style={{color:'#8fa3b1'}}>{folder?.organizer?.name}</strong></Col>
                  </Row>
                  </td>
                  <td style= {{width:'13%'}}>
                    <Col className="last-modified">
                      {folder?.lastEditedDate
                        ? moment(getDate(folder?.lastEditedDate)).format('MM-DD-YYYY')+
                        ' - ' +
                        folder?.lastEditor?.firstName +
                        ' ' +
                        folder?.lastEditor?.lastName
                      : undefined}
                    </Col>
                  </td>
                  <td style= {{width:'5%'}}>
                    <Col className="options-dots">
                      {folder?.isfolder === true ? (
                        <ShareOptions type={'COMPETITION'} item={folder} getTrans={getTrans} isTrash={isTrash} folderId={folder._id} folderName={folder.name} permissions={{ showOptions: true, delete: true,share: true }} />
                      ) : (
                        <ShareOptions
                          item={folder}
                          type={'COMPETITION'}
                          getTrans={getTrans}
                          isTrash={isTrash}
                          organizerId={folder._id}
                          folderName={folder.name}
                          permissions={{ showOptions: true, delete: true,share: true }}
                        />
                      )}{' '}
                    </Col>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      ) : null}
      <h3>{showhead.month === true ? 'EARLIER THIS MONTH' : null}</h3>
      {showhead.month === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {sortedData.map((folder, ind) =>
              folder.timedifference > 169 && folder.timedifference < 720 ? (
                <tr key={ind} id={folder._id} className="hoverable-row">
                  <td style= {{width:'20%'}}>
                    <Col className="name-row">
                      {folder?.isfolder === true ? (
                        <Row>
                          <HiFolder size={25} />
                          {folder.name}
                        </Row>
                      ) : (
                        <Row>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span className="imgLogoContainer">
                              <img
                                className=" imgLogoWidth imgLogo  imgLogoCropped"
                                src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                                alt="Insight Gaming"
                                width={'10px'}
                              />
                            </span>
                            <span
                              style={{
                                maxWidth: '175px',
                                textOverflow: 'ellipsis',
                                marginLeft: '300px',
                                height: '18px',
                                width: '235px',
                                padding: '0px',
                                overflow: 'hidden',
                                position: 'relative',
                                display: 'inline-block',
                                margin: '0 5px 0 5px',
                                textAlign: 'left',
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}>
                              {folder.name}
                            </span>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </td>
                  {/* <td>{moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                  <td style= {{width:'17%'}}></td>
                  <td style= {{width:'15%'}}></td>
                  {/* <td></td> */}
                   <td style= {{width:'15%'}}>
                    <Col className="org-name">
                      <Row>
                        <HiFolder size={15} />
                        <span className='ml-1'>{ folder.iscompetition ? folder?.folder?.name : ''}</span>
                      </Row>
                    </Col>
                  </td>
                  {/* <td>
                    <Col className="org-name">
                      <Row></Row>
                      <Row>
                        <HiFolder size={16} />
                        PRIME LEAGUE
                      </Row>
                    </Col>
                  </td> */}
                  <td style= {{width:'15%'}}>
                  <Row>
                      <Col lg={3} className='r-org-img'><img src={folder?.organizer?.logo} className='img-fluid r-org-img' /></Col>
                      <Col className="me-organizer"><strong style={{color:'#8fa3b1'}}>{folder?.organizer?.name}</strong></Col>
                  </Row>
                  </td>
                  <td style= {{width:'13%'}}>
                    <Col className="last-modified">
                      {/* {moment(getDate(folder?.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss')} */}
                      {folder?.lastEditedDate
                        ? moment(getDate(folder?.lastEditedDate)).format('MM-DD-YYYY')+
                        ' - ' +
                        folder?.lastEditor?.firstName +
                        ' ' +
                        folder?.lastEditor?.lastName
                      : undefined}
                    </Col>
                  </td>
                  <td style= {{width:'5%'}}>
                    <Col className="options-dots">
                      {folder?.isfolder === true ? (
                        <ShareOptions item={folder} type={'COMPETITION'} getTrans={getTrans} isTrash={isTrash} folderId={folder._id} folderName={folder.name} permissions={{ showOptions: true, delete: true,share: true }} />
                      ) : (
                        <ShareOptions
                          item={folder}
                          type={'COMPETITION'}
                          getTrans={getTrans}
                          isTrash={isTrash}
                          organizerId={folder._id}
                          folderName={folder.name}
                          permissions={{ showOptions: true, delete: true,share: true }}
                        />
                      )}{' '}
                    </Col>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      ) : null}
      <h3>{showhead.year === true ? 'EARLIER THIS YEAR' : null}</h3>
      {showhead.year === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {alldata.map((folder, ind) =>
              folder.timedifference < 8760 && folder.timedifference > 719 ? (
                <tr key={ind} id={folder._id} className="hoverable-row">
                  <td style= {{width:'20%'}}>
                    <Col className="name-row">
                      {folder?.isfolder === true ? (
                        <Row>
                          <HiFolder size={25} />
                          {folder.name}
                        </Row>
                      ) : (
                        <Row>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span className="imgLogoContainer">
                              <img
                                className=" imgLogoWidth imgLogo  imgLogoCropped"
                                src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                                alt="Insight Gaming"
                                width={'10px'}
                              />
                            </span>
                            <span
                              style={{
                                maxWidth: '175px',
                                textOverflow: 'ellipsis',
                                marginLeft: '300px',
                                height: '18px',
                                width: '235px',
                                padding: '0px',
                                overflow: 'hidden',
                                position: 'relative',
                                display: 'inline-block',
                                margin: '0 5px 0 5px',
                                textAlign: 'left',
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}>
                              {folder.name}
                            </span>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </td>
                  {/* <td>{moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                  <td style= {{width:'17%'}}></td>
                  <td style= {{width:'15%'}}></td>
                  {/* <td></td> */}
                  <td style= {{width:'15%'}}>
                    <Col className="org-name">
                      <Row>
                        <HiFolder size={15} />
                        <span className='ml-1'>{ folder.iscompetition ? folder?.folder?.name : ''}</span>
                      </Row>
                    </Col>
                  </td>
                  {/* <td>
                    <Col className="org-name">
                      <Row></Row>
                      <Row>
                        <HiFolder size={16} />
                        PRIME LEAGUE
                      </Row>
                    </Col>
                  </td> */}
                  <td style= {{width:'15%'}}>
                  <Row>
                      <Col lg={3} className='r-org-img'><img src={folder?.organizer?.logo} className='img-fluid r-org-img' /></Col>
                      <Col className="me-organizer"><strong style={{color:'#8fa3b1'}}>{folder?.organizer?.name}</strong></Col>
                  </Row>
                  </td>
                  <td style= {{width:'13%'}}>
                    <Col className="last-modified">
                      {/* {moment(getDate(folder?.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss')} */}
                      {folder?.lastEditedDate
                        ? moment(getDate(folder?.lastEditedDate)).format('MM-DD-YYYY')+
                        ' - ' +
                        folder?.lastEditor?.firstName +
                        ' ' +
                        folder?.lastEditor?.lastName
                      : undefined}
                    </Col>
                  </td>
                  <td style= {{width:'5%'}}>
                    <Col className="options-dots">
                      {folder?.isfolder === true ? (
                        <ShareOptions item={folder} type={'COMPETITION'} getTrans={getTrans} isTrash={isTrash} folderId={folder._id} folderName={folder.name} permissions={{ showOptions: true, delete: true,share: true }}/>
                      ) : (
                        <ShareOptions
                          item={folder}
                          type={'COMPETITION'}
                          getTrans={getTrans}
                          isTrash={isTrash}
                          organizerId={folder._id}
                          folderName={folder.name}
                          permissions={{ showOptions: true, delete: true,share: true }}
                        />
                      )}{' '}
                    </Col>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      ) : null}
      <h3>{showhead.older === true ? 'OLDER' : null}</h3>
      {showhead.older === true ? (
        <table
          className="table"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {sortedData.map((folder, ind) =>
              folder.timedifference > 8759 ? (
                <tr key={ind} id={folder._id} className="hoverable-row">
                  <td style= {{width:'20%'}}>
                    <Col className="name-row">
                      {folder?.isfolder === true ? (
                        <Row>
                          <HiFolder size={25} />
                          {folder.name}
                        </Row>
                      ) : (
                        <Row>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span className="imgLogoContainer">
                              <img
                                className=" imgLogoWidth imgLogo  imgLogoCropped"
                                src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                                alt="Insight Gaming"
                                width={'10px'}
                              />
                            </span>
                            <span
                              style={{
                                maxWidth: '175px',
                                textOverflow: 'ellipsis',
                                marginLeft: '300px',
                                height: '18px',
                                width: '235px',
                                padding: '0px',
                                overflow: 'hidden',
                                position: 'relative',
                                display: 'inline-block',
                                margin: '0 5px 0 5px',
                                textAlign: 'left',
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}>
                              {folder.name}
                            </span>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </td>
                  {/* <td>{moment(getDate(folder?.createdDate)).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                  <td style= {{width:'17%'}}></td>
                  <td style= {{width:'15%'}}></td>
                  <td style= {{width:'15%'}}>
                    <Col className="org-name">
                      <Row>
                        <HiFolder size={15} />
                        <span className='ml-1'>{ folder.iscompetition ? folder?.folder?.name : ''}</span>
                      </Row>
                    </Col>
                  </td>
                  {/* <td style= {{width:'15%'}}>
                    <Col className="org-name">
                      <Row></Row> */}
                      {/* <Row>
                        <HiFolder size={16} />
                        PRIME LEAGUE
                      </Row> */}
                    {/* </Col>
                  </td> */}
                  <td style= {{width:'15%'}}>
                  <Row>
                      <Col lg={3} className='r-org-img'><img src={folder?.organizer?.logo} className='img-fluid r-org-img' /></Col>
                      <Col className="me-organizer"><strong style={{color:'#8fa3b1'}}>{folder?.organizer?.name}</strong></Col>
                  </Row>
                  </td>
                  <td style= {{width:'13%'}}>
                    <Col className="last-modified">
                      {moment(getDate(folder?.lastEditedDate)).format('YYYY-MM-DD HH:mm:ss')}
                    </Col>
                  </td>
                  <td style= {{width:'5%'}}>
                    <Col className="options-dots">
                      {folder?.isfolder === true ? (
                        <ShareOptions item={folder} type={'COMPETITION'} getTrans={getTrans} isTrash={isTrash} folderId={folder._id} folderName={folder.name} permissions={{ showOptions: true, delete: true,share: true }}/>
                      ) : (
                        <ShareOptions
                          item={folder}
                          type={'COMPETITION'}
                          getTrans={getTrans}
                          isTrash={isTrash}
                          organizerId={folder._id}
                          folderName={folder.name}
                          permissions={{ showOptions: true, delete: true,share: true }}
                        />
                      )}{' '}
                    </Col>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      ) : null}
    </>
  );
};

export default ListView;
