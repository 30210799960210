import {
  SET_COMPETITIONS,
  SET_FOLDER_ONLY,
  SET_FOLDERS,
  SET_ORGANIZERS,
  SET_TIMEZONE,
  UPDATE_ENTITIES,
  SET_INHERITEDPERMISSIONS_ONLY
} from './types';
import Axios from 'axios';
import { setLoading } from './loadingActions';
let backend = process.env.REACT_APP_CORE_API;
export const setOrganizations = (arr) => ({
  type: SET_ORGANIZERS,
  payload: arr
});
export const getOrganizations = () => (dispatch) => {
  dispatch(setLoading(true));
  Axios.get(backend + '/api/organizer')
    .then((res) => {
      dispatch(setOrganizations(res.data));
      // setAllorganizers(res.data);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      console.log(err);
    });
};
export const getOrganizer = (id) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.get(backend + '/api/organizer/' + id + '/byid')
    .then((res) => {
      dispatch({
        type: SET_FOLDERS,
        payload: {
          folder: res.data.organizer,
          matchCount: res.data.matchesCount,
          breadcrumbs: res.data.breadcrumbs,
          currentUserPermissions: res.data.currentUserPermission,
          inheritedPermissions: res.data.inheritedPermissions
        }
      });
      dispatch(setLoading(false));

      // dispatch({
      //     type: UPDATE_ENTITIES,
      //     payload:res.data
      // })
    })
    .catch((err) => {
      console.log(err);
      dispatch(setLoading(false));
    });
};
export const setEmptyFolderAction = () => ({
  type: SET_FOLDERS,
  payload: {
    folder: [],
    breadcrumbs: [],
    inheritedPermissions: [],
    currentUserPermissions: []
  }
});
export const getFolders = (id) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.get(backend + '/api/organizer/folder/' + id)
    .then((res) => {
      let _folder = res.data.folder;
      if (_folder) {
        _folder.folders = _folder.children;
        dispatch({
          type: SET_FOLDERS,
          payload: res.data
        });
      }
      dispatch(setLoading(false));

      // dispatch({
      //     type: UPDATE_ENTITIES,
      //     payload:res.data
      // })
    })
    .catch((err) => {
      console.log(err);
      dispatch(setLoading(false));
    });
};

export const getTimeZone = (zone) => (dispatch) => {
  dispatch({
    type: SET_TIMEZONE,
    payload: zone
  });
};
export const getCompetitions = (id) => (dispatch) => {
  Axios.get(backend + '/api/organizer/recent')
    .then((res) => {
      dispatch({
        type: SET_COMPETITIONS,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const setFolderOnly = (obj) => (dispatch) => {
  let _obj = JSON.parse(JSON.stringify(obj));
  if (!_obj.folders) {
    _obj.folders = _obj.children ? _obj.children : [];
  }
  dispatch({
    type: SET_FOLDER_ONLY,
    payload: _obj
  });
};
export const setPermissionsForCompetition = (perm) => dispatch =>{
  dispatch({
    type: SET_INHERITEDPERMISSIONS_ONLY,
    payload: perm
  })
}