import Axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaLanguage } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { TiArrowUnsorted } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import { CgTrashEmpty } from 'react-icons/cg';
import './streams.scss';

const Streams = ({
  setAllInformationData,
  allInformationData,
  setInformationCall,
  setUnSavedStages,
  selectedTab
}) => {
  const [updatedData, setUpdatedData] = useState([]);
  // const organizerdata = useSelector(
  //   (state) => state.COMPETITION.informationData.multiStageLeague?.streams
  // );
  const organizerdata = useSelector(
    (state) => state.COMPETITION.Competition.multiStageLeague?.streams
  );
  const [name, setName] = useState({ key: '', name: '' });
  const [url, setUrl] = useState({ key: '', url: '' });
  const [language, setLanguage] = useState({ key: '', language: '' });
  const [disableSave, setDisableSave] = useState(true);
  // useEffect(() => {
  //   console.log('updatedData=>', updatedData[0]);
  //   console.log('disableSave=>', disableSave);
  //   console.log('new updated', updatedData.slice(1));

  //   console.log('allInformationData=>', allInformationData);
  // }, [updatedData,disableSave,allInformationData]);
  useEffect(() => {
    if (organizerdata) {
      organizerdata.map(({ name, url, language }) => {
        setUpdatedData((state) => [...state, { url: url, name: name, language: language }]);
        console.log('new Updated data =>', updatedData);
      });
    }
  }, []);
  useEffect(() => {
    // if (name?.name !== '') {
    setUpdatedData((prevState) => {
      const newState = prevState.map((obj, index) => {
        if (name.key === index) {
          return { ...obj, name: name.name };
        }
        return obj;
      });

      return newState;
    });
    // }
  }, [name]);
  useEffect(() => {
    setUpdatedData((prevState) => {
      const newState = prevState.map((obj, index) => {
        if (url.key === index) {
          return { ...obj, url: url?.url };
        }
        return obj;
      });

      return newState;
    });
  }, [url]);
  useEffect(() => {
    setUpdatedData((prevState) => {
      const newState = prevState.map((obj, index) => {
        if (language.key === index) {
          return { ...obj, language: language?.language };
        }
        return obj;
      });
      return newState;
    });
  }, [language]);
  useEffect(() => {
    if (updatedData?.length > 0) {
      setAllInformationData((state) => ({ ...state, streams: updatedData }));
    } else {
      setAllInformationData((state) => ({ ...state, streams: [{}] }));
    }
  }, [updatedData]);
  const hendleChange = () => {
    setUnSavedStages((state) => state.filter((e) => e != selectedTab));
    setInformationCall(allInformationData);
  };
  const checkUpdatedData = () => {
    let arr = updatedData?.find((el) => el.name === '' || el.url === '');
    console.log('updated Data', updatedData, Boolean(arr));
    return Boolean(arr);
  };
  const removeStream = (i) => {};
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>STREAMS</h2>
        <button
          className="shareButton"
          style={{
            marginRight: '0px',
            border: 'none',
            font: 'normal normal bold 12px/15px Montserrat'
          }}
          onClick={() => {
            setUnSavedStages((state) => [...state, selectedTab]);
            setUpdatedData((state) => [
              ...state,
              { name: 'Stream', url: 'https://www.twitch.tv/', language: 'English' }
            ]);
          }}>
          + ADD STREAM
        </button>
      </div>
      {updatedData?.length > 0 ? (
        <div>
          <table
            tabIndex={0}
            className="table"
            border="0"
            cellSpacing="0"
            cellPadding="0"
            style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th
                  scope="col"
                  className="strm-hd"
                  style={{ borderBottom: '1px solid #22262e', width: '30%' }}>
                  NAME
                  <TiArrowUnsorted
                    onClick={(e) => {
                      //   onSort(parentFolder, 'NAME', defaultSort.NAME);
                      //   setDefaultSort((state) => ({ ...state, NAME: !defaultSort.NAME }));
                    }}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </th>{' '}
                <th
                  scope="col"
                  className="strm-hd"
                  style={{ borderBottom: '1px solid #22262e', width: '30%' }}>
                  URL
                  <TiArrowUnsorted
                    onClick={(e) => {
                      //   onSort(parentFolder, 'NAME', defaultSort.NAME);
                      //   setDefaultSort((state) => ({ ...state, NAME: !defaultSort.NAME }));
                    }}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </th>{' '}
                <th
                  scope="col"
                  className="strm-hd"
                  style={{ borderBottom: '1px solid #22262e', width: '37%' }}>
                  LANGUAGE
                  <TiArrowUnsorted
                    onClick={(e) => {
                      //   onSort(parentFolder, 'NAME', defaultSort.NAME);
                      //   setDefaultSort((state) => ({ ...state, NAME: !defaultSort.NAME }));
                    }}
                    style={{ width: '20px', marginTop: '2px', cursor: 'pointer' }}
                  />
                </th>
                <th
                  scope="col"
                  className="strm-hd"
                  style={{ borderBottom: '1px solid #22262e', width: '10%' }}>
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {updatedData.map(({ name, url, language }, key) => (
                <FolderRow
                  index={key}
                  name={name}
                  url={url}
                  language={language}
                  setName={setName}
                  setUrl={setUrl}
                  setLanguage={setLanguage}
                  setUnSavedStages={setUnSavedStages}
                  selectedTab={setUnSavedStages}
                  setUpdatedData={setUpdatedData}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
      <Row style={{ marginTop: '10px', marginLeft: '-2px' }}>
        <button
          className="shareButton"
          onClick={() => {
            setUnSavedStages((state) => [...state, selectedTab]);
            setUpdatedData((state) => [
              ...state,
              { name: 'Stream', url: 'https://www.twitch.tv/', language: 'English' }
            ]);
          }}
          style={{ border: 'none', font: 'normal normal bold 12px/15px Montserrat' }}>
          + ADD STREAM
        </button>
        <button className="button primary" onClick={hendleChange} disabled={checkUpdatedData()}>
          Save Changes
        </button>
      </Row>
    </>
  );
};
export const FolderRow = ({
  name,
  url,
  language,
  setName,
  setUrl,
  setLanguage,
  index,
  setUnSavedStages,
  selectedTab,
  setUpdatedData
}) => {
  console.log('names', name);
  const { errors } = useForm();
  const validateform = (e) => {
    if (e.target.value.length < 1) {
      e.target.style.border = '1px solid red';
      // e.target.style.outline = '1px solid red';
      e.target.style.outline = '0px';
    } else {
      e.target.style.border = '1px solid #22262e';
    }
  };
  const setNameValue = (e)=>{
    const inputValue= e.target.value;
    const alphanumeric = /[a-zA-Z0-9\s]+$/;
    if(alphanumeric.test(inputValue) ||inputValue == '' ){
      // debugger
      setName({ key: index, name: e.target.value });}
  }
  return (
    <tr className="hoverable-row" style={{height:'86px'}}>
      <td style={{ borderBottom: '1px solid #22262e' }}>
        <Col className="name-row text">
          <Row>
            <Form.Group style={{ width: '-webkit-fill-available', margin: '10px 25px 10px 0px' }}>
              <Form.Control
                // ref={register({ required: true })}
                type="text"
                id="name"
                name="name"
                value={name}
                pattern='[a-zA-Z0-9]+'
                maxLength={30} // defaultValue={name}
                onBlur={(e) => validateform(e)}
                onChange={(e) => {
                  setNameValue(e);
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}
                style={{ marginLeft: '2px', border: '1px solid #22262e', marginTop:'-3px',marginBottom:'6px'}}
              />
              <Form.Control.Feedback
                type="invalid"
                style={name?.length < 1 ? { padding: '5px 0px 0px 2px' ,position:'absolute',bottom:'-2px'} : null}>
                {name?.length < 1 ? 'Stream name cannot be empty.' : null}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Col>
      </td>
      <td style={{ borderBottom: '1px solid #22262e' }}>
        <Col className="name-row text">
          <Row>
            <Form.Group style={{ width: '-webkit-fill-available', margin: '10px 25px 10px 0px' }}>
              <Form.Control
                // ref={register({ required: true })}
                type="text"
                id="name"
                name="name"
                value={url}
                onBlur={(e) => validateform(e)}
                onChange={(e) => {
                  setUrl({ key: index, url: e.target.value });
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}
                style={{ marginLeft: '2px', marginTop:'-3px',marginBottom:'6px' }}

                // onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid" style={{ padding: '5px 0px 0px 2px',position: 'absolute' ,bottom:'-2px'}}>
                {url?.length < 1 ? 'Stream URL cannot be empty.' : null}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Col>
      </td>
      <td style={{ borderBottom: '1px solid #22262e' }}>
        <Col className="name-row text">
          <Row>
            <div className="form-row">
              <select
                // ref={register({
                //   validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
                // })}
                style={{ width: '120%' }}
                id="region"
                name="region"
                className={`form-input ${errors.language ? 'invalid' : ''}`}
                value={language}
                onChange={(e) => {
                  setLanguage({ key: index, language: e.target.value });
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}>
                <option value="">SELECT LANGUAGE</option>
                {['English', 'French', 'Danish', 'Hindi', 'Urdu', 'Spanish'].map((name) => (
                  <option value={name}>{name}</option>
                ))}
              </select>
              {errors.language && <div className="form-input-error">{errors.language.message}</div>}
            </div>
          </Row>
        </Col>
      </td>
      <td style={{ borderBottom: '1px solid #22262e' }}>
        <Col className="org-name">
          <Row>
            <button
              style={{
                background: 'transparent',
                border: 'none',
                color: '#f11857',
                textTransform: 'uppercase',
                font: 'normal normal bold 12px/15px Montserrat',
                display: 'flex'
              }}
              onClick={(e) => {
                // setSelectedMembers(selectedMembers.filter((val, key) => key !== i));
                // console.log("hi this is delete", name + URL)
                setUpdatedData((prevState) => prevState.filter((item) => item.name != name));
                setUnSavedStages((state) => [...state, selectedTab]);
              }}>
              {/* <RiCloseLine className="selected-user-delete" size={27} style={{ color: 'red' }} /> */}
              <CgTrashEmpty style={{ fontSize: '15px', marginRight: '5px' }} />
              <span style={{ paddingTop: '2px' }}>DELETE</span>
            </button>
          </Row>
        </Col>
      </td>
    </tr>
  );
};
export default Streams;
