import React from 'react';
import { Loading, SimpleTabs } from '../../_common';
import GroupTabs from './GroupTabs';
import { useState } from 'react';
import { useEffect } from 'react';
import { HiChevronDown, HiOutlineTemplate, HiPlusSm } from 'react-icons/hi';
import { BsPlus, BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { GoInfo } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { saveSeedingDetailsForAll, fetchSeedingDetailsWithSave } from '../MyCompetitions/utils';
import SeedingSimpleTabs from '../../_common/SimpleTabs/SeedingSimpleTabs';

const DEFAULT_STAGES = [];

const SeedingTabs = ({ id, currentStageIndex, setCurrentStageIndex, competitionData }) => {
  const [stages, setStages] = useState(DEFAULT_STAGES);
  const [stagesNames, setStagesName] = useState(DEFAULT_STAGES);
  const [stagesStatus, setStagesStatus] = useState(DEFAULT_STAGES);
  const [isBracketView, setIsBracketView] = useState();
  // const [saving, setSaving] = useState(false);
  const [showHowToUse, setShowHowToUse] = useState();
  const seedingStages = useSelector((state) => state.COMPETITION.SeedingTab.seedingStages);
  const updatedSavedChanges = useSelector((state) => state.COMPETITION.SeedingTab.updatedSavedChanges);
  const saveLoading = useSelector((state) => state.COMPETITION.SeedingTab.saveLoading);
  const saveMessage = useSelector((state) => state.COMPETITION.SeedingTab.saveMessage);
  const isAnyChangeInStage = useSelector((state) => state.COMPETITION.isAnyChangeInStage);

  useEffect(() => {
    if (seedingStages?.length > 0) {
      let _stagesNames = [];
      let _stagesStatus = [];
      seedingStages.forEach(element => {
        _stagesNames.push(element.name);
        _stagesStatus.push(element?.unSavedChanges === true);
      });
      setStagesName(_stagesNames);
      setStagesStatus(_stagesStatus);
    }
  }, [seedingStages, updatedSavedChanges, isAnyChangeInStage]);

  return (
    <>
      <div className="subTabs" style={{ justifyContent: 'space-between' }}>
        <div className="subTabs">
          {stagesNames.length > 1 ? (
            <SeedingSimpleTabs savedStatus={stagesStatus} pages={stagesNames} page={currentStageIndex} setPage={setCurrentStageIndex} />
          ) : null}
          {/* <div className="vl"></div> */}
          {/* {stagesData ? <GroupTabs stagesData={stagesData} setSelectedGroup={setSelectedGroup} /> : null} */}
        </div>
        <div className="subTabs nav-side-buttons">
          <button className="info-btn" onClick={() => setShowHowToUse((state) => !state)}>
            <GoInfo size={20} /> HOW TO USE THIS PAGE
          </button>
          <button className="secondary-btn" onClick={() => setIsBracketView((state) => !state)}>
            {isBracketView ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />} BRACKET VIEW
          </button>
          <button className="secondary-btn">
            <HiOutlineTemplate size={20} /> APPLY TEMPLATE
          </button>
          <button className="primary-btn" onClick={() => {
            // let autoFill = seedingStages[currentStageIndex].league.autoFillTeams;
            // fetchSeedingDetailsWithSave(seedingStages[currentStageIndex]._id, autoFill, id)
            saveSeedingDetailsForAll(id);
            // setSaving(true);
          }}>
            {"SAVE CHANGES"}
          </button>
        </div>
      </div>
    </>
  );
};
export default SeedingTabs;
