export const SET_TRANSLATION = 'SET_TRANSLATION';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_ALL_LANGUAGES = 'SET_ALL_LANGUAGES';
export const SET_TRASH = 'SET_TRASH';
export const SET_FOLDERS = 'SET_FOLDERS';
export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_SELECTED_TASKS = 'SET_SELECTED_TASKS';
export const SET_DRAGGING_TASK = 'SET_DRAGGING_TASK';
export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';
export const REARRANGE_ENTITIES = 'REARRANGE_ENTITIES';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_DROP = 'SET_DROP';
export const SET_COMPETITIONS = 'SET_COMPETITIONS';
export const SET_FOLDER_ONLY = 'SET_FOLDER_ONLY';
export const SET_ORGANIZERS = 'SET_ORGANIZERS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_FOLDERS = 'SET_SELECTED_FOLDERS';
export const SET_CONTEXT_OBJ = 'SET_CONTEXT';
export const SET_COMETITIONDATA = 'SET_COMETITIONDATA';
export const SET_MATCHES = 'SET_MATCHES';
export const SET_COMPETITION = 'SET_COMPETITION';
export const UPDATE_COMPETITION = 'UPDATE_COMPETITION';
export const SET_STAGES_DATA = 'SET_STAGES_DATA';
export const APPEND_STAGES_DATA = 'APPEND_STAGES_DATA';
export const SET_CURRENT_STAGE = 'SET_CURRENT_STAGE';
export const REMOVE_STAGE_WITH_ID = 'REMOVE_STAGE_WITH_ID';
export const SET_UNSAVED_STAGES = 'SET_UNSAVED_STAGES';
export const REPLACE_STAGE_AFTER_UPDATE = 'REPLACE_STAGE_AFTER_UPDATE';
export const MATCHES_FILTER = 'MATCHES_FILTER';
export const ALL_TEAMS = 'ALL_TEAMS';
export const SET_INFORMATION_DATA = 'SET_INFORMATION_DATA';
export const SET_ANYCHANGE_IN_STAGE = 'SET_ANYCHANGE_IN_STAGE';
export const CLEAR_UNSAVED_STAGES = 'CLEAR_UNSAVED_STAGES';
export const SET_STAGE_INDEX = 'SET_STAGE_INDEX';
export const SET_INHERITEDPERMISSIONS_ONLY = 'SET_INHERITEDPERMISSIONS_ONLY';
export const SET_PRIZES_PREVIEW_DATA = 'SET_PRIZES_PREVIEW_DATA';
export const SET_INFORMATION_PREVIEW_DATA = 'SET_INFORMATION_PREVIEW_DATA';
export const UPDATE_STAGE_AFTER_CREATE = 'UPDATE_STAGE_AFTER_CREATE';
export const TOGGLE_MATCH_STATUS = 'TOGGLE_MATCH_STATUS';

export const SEEDING_DATA = {
  LOAD: 'SEEDING_DATA_LOAD',
  LOAD_SUCCESS: 'SEEDING_DATA_LOAD_SUCCESS',
  LOAD_FAIL: 'SEEDING_DATA_LOAD_FAIL',
  UPDATE_STAGE_SEEDING: 'SEEDING_DATA_UPDATE_STAGE_SEEDING',
  UPDATE_STAGE_SEEDING_RESET: 'SEEDING_DATA_UPDATE_STAGE_SEEDING_RESET',
  SET_SAVE_LOADING: 'SEEDING_DATA_SET_SAVE_LOADING',
  SET_SAVE_MESSAGE_RESET: 'SEEDING_DATA_SET_SAVE_MESSAGE_RESET',
  SAVED_UPDATE_GROUPS: 'SEEDING_DATA_SAVED_UPDATE_GROUPS',
  TOGGLE_AUTO_FILL: 'SEEDING_DATA_TOGGLE_AUTO_FILL',
  SET_SEEDINGS_IN_STAGE: 'SEEDING_DATA_SET_SEEDINGS_IN_STAGE',
  SET_UNSAVED_CHANGES_IN_STAGE: 'SEEDING_DATA_SET_UNSAVED_CHANGES_IN_STAGE',
  SET_UNSAVED_CHANGES_ALL_SAVED: 'SEEDING_DATA_SET_UNSAVED_CHANGES_ALL_SAVED'
};
