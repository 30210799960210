import React from 'react';
import { FaFolder } from 'react-icons/fa';
import default_logo from '@img/sample-logo.png';

import './dragging-item.scss';
const DraggingItem = ({ item }) => {
  const {
    type,
    name ="No Name",
    total = 1,
    _id,
    index,
    destinationIndex,
    destinationFolder,
    folder,
    competitions,
    logo =false
  } = item;
  return (
    <div
      id={_id}
      key={index}
      style={{ backgroundColor: '#050c16' }}
      className={`card-panel-1 folder-1 selected-folder'}`}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {type === 'COMPETITION' ? (
          <div className="logo-grid-cards">
            <img src={logo ?? default_logo} />
            {/* <HiFolder color="#3eabf0" /> */}
          </div>
        ) : (
          <FaFolder style={{ marginRight: '5px' }} size={25} />
        )}
        <div className="texteclips">
          <span
            style={{
              textOverflow: 'ellipsis',
              // textTransform: 'lowercase' ,
              fontWeight: '600'
            }}>
            {name ? name : 'Folder'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DraggingItem;
