import React, { useState, createContext, useEffect } from 'react';
import Axios from 'axios';
import './_newfolder.scss';
import Popup_modals from '../modals/Popup_modals';
import { useDispatch } from 'react-redux';
import { HiX } from 'react-icons/hi';
import { getFolders, getOrganizer } from '../../../../redux/action/competitionActions';
const backendServerURL = process.env.REACT_APP_CORE_API;

const Component = ({ onClose, folder, type, setNewname }) => {
  const [name, setname] = useState();
  const [id, setId] = useState();
  const dispatch = useDispatch();
  const renameFolder = (type, id) => {
    let url;
    if (type === 'FOLDER') {
      url = backendServerURL + '/api/organizer/folder/rename/' + id;
    } else if (type === 'COMPETITION') {
      url = `${backendServerURL}/api/organizer/competition/${id}/rename`
    } else {
      return;
    }
     (type === 'COMPETITION' ? Axios.post : Axios.put) (url, (type === 'COMPETITION') ? {competitionId: id, name} : { name: name })
      .then((res) => {
        onClose(false);
        let { parent, organizer } = res.data;
        // debugger
        if (parent) {
          dispatch(getFolders(parent));
        } else {
          dispatch(getOrganizer(organizer));
        }
        setNewname(name);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="newfolder-main" onKeyDown={(e) => e.stopPropagation()}>
      <div className="modal-header" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <h2>RENAME {type}</h2>
        <HiX
          // onClick={() => onClose(false)}
          onClick={() => {
            onClose(false);
          }}
          style={{ marginTop: '10px', marginRight: '24px', fontSize: '26.5px', color: '#68717e' }}
        />
      </div>

      <div className="newfolder-input">
        <input
          className="form-input form-input1"
          type="text"
          defaultValue={folder?.name}
          placeholder="Write the name of the folder"
          onChange={(e) => {
            // setname(e.target.value.toLowerCase());
            e.stopPropagation();
            setname(e.target.value);
          }}
        />
      </div>

      <div className="newfolder-buttons">
        <button
          className="button bordered medium ml-3 mr-3"
          onClick={() => {
            onClose(false);
          }}>
          Cancel
        </button>
        <button
          className="button primary medium"
          onClick={(e) => {
            e.stopPropagation();
            renameFolder(type, folder?._id);
          }}>
          Rename
        </button>
      </div>
    </div>
  );
};
const Renamefolder = ({ open, setRename, type, folder }) => {
  console.log({folder})
  return (
    <Popup_modals
      open={open}
      onClose={setRename}
      folder={folder}
      type={type}
      Component={Component}></Popup_modals>
  );
};
export default Renamefolder;
