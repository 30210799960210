//* These are the utility function which are used in useMultiSelection hook

export const getElementsBetweenIndexes = (index1, index2, arr) => {
  if (index1 > index2) {
    [index1, index2] = [index2, index1]; // swap values if index1 is greater than index2
  }
  return arr.slice(index1, index2 + 1);
};

export const mergeArraysWithUniqueValues = (obj, arrayType, newArray) => {
  let mergedArray = [];

  if (arrayType === 'folders') {
    mergedArray = [...obj.folders, ...newArray];
  } else if (arrayType === 'competitions') {
    mergedArray = [...obj.competitions, ...newArray];
  } else {
    mergedArray = [...obj.folders, ...obj.competitions, ...newArray];
  }

  const uniqueValues = new Set(mergedArray);
  const mergedArrayWithUniqueValues = [...uniqueValues];

  return {
    ...obj,
    [arrayType]: mergedArrayWithUniqueValues
  };
};
const removeCommonElements = (arr = [], arr1 = []) => {
  const resultArr = [];
  //   console.log({ arr, arr1 });
  arr.forEach((el) => {
    if (!arr1.includes(el)) {
      resultArr.push(el);
    }
  });
  arr1.forEach((el) => {
    if (!arr.includes(el)) {
      resultArr.push(el);
    }
  });

  return resultArr;
};

export const mergeArraysWithRemoveMatching = (obj, arrayType, newArray = [], hoverIndexObj) => {
  let mergedArray = [];

  if (arrayType === 'folders') {
    let folders = obj.folders ?? [];
    let diff = removeCommonElements(folders, newArray);
    if (hoverIndexObj.type === 'folders') {
      mergedArray = [...diff, hoverIndexObj.id];
    } else {
      mergedArray = [...diff];
    }
  } else if (arrayType === 'competitions') {
    let competitions = obj.competitions ?? [];
    let diff = removeCommonElements(competitions, newArray);
    if (hoverIndexObj.type === 'competitions') {
      mergedArray = [...diff, hoverIndexObj.id];
    } else {
      mergedArray = [...diff];
    }
    // mergedArray = [...first, ...diff, ...last];
  } else {
    mergedArray = [...obj.folders, ...obj.competitions, ...newArray];
  }

  const uniqueValues = new Set(mergedArray);
  const mergedArrayWithRemovedMatching = [...uniqueValues];

  return {
    ...obj,
    [arrayType]: mergedArrayWithRemovedMatching
  };
};

export const getStartToEndElements = (arr, index) => {
  return arr.slice(0, index + 1);
};
export const getIndexToEnd = (arr, index) => {
  return arr.slice(index);
};

export const removeArrayFromArray = (arr1, array2) => {
  return arr1.filter((item) => !array2.includes(item));
};
export const getDeleteItemType = (obj) => {
  let type;
  const numFolders = obj.folders.length;
  const numCompetitions = obj.competitions.length;
  if (numFolders === 1 && numCompetitions === 0) {
    type = 'FOLDER';
  } else if (numFolders === 0 && numCompetitions === 1) {
    type = 'COMPETITION';
  } else if (numFolders > 1 || numCompetitions > 1 || (numFolders === 1 && numCompetitions === 1)) {
    type = 'MULTIPLE';
  } else {
    type = null;
  }
  return type;
};

export const checkSelecting = (
  lastSelectedId,
  hoverIndexObj,
  _currentElement,
  lastSelectionIndex,
  lastHoverIndex,
  _newIndex,
  action
) => {
  if (action === 'ArrowDown') {
    if (
      lastSelectedId.type === 'folders' &&
      hoverIndexObj.type === 'folders' &&
      _currentElement.type === 'competitions'
    ) {
      return true;
    } else if (
      lastSelectedId.type === 'competitions' &&
      hoverIndexObj.type === 'folders' &&
      _currentElement.type === 'competitions'
    ) {
      return false;
    } else if (
      lastSelectedId.type === 'competitions' &&
      hoverIndexObj.type === 'folders' &&
      _currentElement.type === 'folders'
    ) {
      if (lastHoverIndex > _newIndex) {
        return false;
      }
    } else if (
      lastSelectedId.type === 'folders' &&
      hoverIndexObj.type === 'competitions' &&
      _currentElement.type === 'competitions'
    ) {
      if (lastHoverIndex > _newIndex) {
        return false;
      }
    } else if (
      lastSelectedId.type === 'folders' &&
      hoverIndexObj.type === 'folders' &&
      _currentElement.type === 'folders'
    ) {
      if (lastSelectionIndex < lastHoverIndex) {
        //up - down
        return true;
      } else if (lastSelectionIndex > lastHoverIndex) {
        // down - up
        return false;
      } else {
        return true;
      }
      //   if (lastSelectionIndex < lastHoverIndex) {
      //     return _newIndex >= lastHoverIndex;
      //   } else if (lastSelectionIndex > lastHoverIndex) {
      //     return _newIndex > lastHoverIndex;
      //   }
    } else if (
      lastSelectedId.type === 'competitions' &&
      hoverIndexObj.type === 'competitions' &&
      _currentElement.type === 'competitions'
    ) {
      if (lastSelectionIndex < lastHoverIndex) {
        //up - down
        return true;
      } else if (lastSelectionIndex > lastHoverIndex) {
        // down - up
        return false;
      } else {
        return true;
      }
    }
  } else if (action === 'ArrowUp') {
    if (
      lastSelectedId.type === 'folders' &&
      hoverIndexObj.type === 'competitions' &&
      _currentElement.type === 'folders'
    ) {
      return false;
    } else if (
      lastSelectedId.type === 'competitions' &&
      hoverIndexObj.type === 'competitions' &&
      _currentElement.type === 'folders'
    ) {
      return true;
    } else if (
      lastSelectedId.type === 'folders' &&
      hoverIndexObj.type === 'competitions' &&
      _currentElement.type === 'competitions'
    ) {
      //   if (lastHoverIndex > _newIndex) {
      return false;
      //   }
    } else if (
      lastSelectedId.type === 'folders' &&
      hoverIndexObj.type === 'folders' &&
      _currentElement.type === 'folders'
    ) {
      if (lastSelectionIndex < lastHoverIndex) {
        //up - down
        return false;
      } else if (lastSelectionIndex > lastHoverIndex) {
        // down - up
        return true;
      } else {
        return true;
      }
    } else if (
      lastSelectedId.type === 'competitions' &&
      hoverIndexObj.type === 'competitions' &&
      _currentElement.type === 'competitions'
    ) {
      if (lastSelectionIndex < lastHoverIndex) {
        //up - down
        return false;
      } else if (lastSelectionIndex > lastHoverIndex) {
        // down - up
        return true;
      } else {
        return true;
      }
    } else if (
      lastSelectedId.type === 'competitions' &&
      hoverIndexObj.type === 'folders' &&
      _currentElement.type === 'folders'
    ) {
      return true;
    }
  }
};
