import React, { useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import './styles.scss';
import { CheckboxSlider, Loading } from '../../../_common';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { toast } from 'react-toastify';
import { serverLocations } from './server-locations';
import Axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Tippy from '@tippyjs/react';
import { HiInformationCircle } from 'react-icons/hi';
import { SelectWithDescription } from '../../../_common';
import { useDispatch } from 'react-redux';
import { setIsAnyChangeInTab } from '../../../../redux/action/CompetitonData';
const GeneralTab = ({ payload, setPayload, handleSubmitSettings, userPermissions, loadingState }) => {
  const [weekDays, setWeekDays] = useState([]);
  const [setPlayersDeadlineHr, setSetPlayersDeadlineHr] = useState(0);
  const [setPlayersDeadlineMin, setSetPlayersDeadlineMin] = useState(0);
  const [earlydate, setEarlydate] = useState(payload?.earliestDate);
  const [earlytime, setEarlytime] = useState(payload?.earliestDate);
  const [latesttime, setLatesttime] = useState(payload?.latestDate);
  const [latestdate, setlatestdate] = useState(payload?.latestDate);
  const { register, handleSubmit, errors } = useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const participantType = useSelector(
    (state) => state?.COMPETITION?.Competition?.multiStageLeague?.participantType ?? 1
  );
  useEffect(() => {
    if (latestdate || latesttime) {
      setPayload((state) => ({
        ...state,
        latestDate: latestdate + 'T' + latesttime
      }));
    }
  }, [latestdate, latesttime]);
  useEffect(() => {
    if (
      (earlydate == null || earlytime === null || latestdate == null || latesttime == null) &&
      payload.earliestDate !== null
    ) {
      setEarlydate(
        payload?.earliestDate
          ? `${moment(payload.earliestDate).format('YYYY-MM-DD')}`.split('T')[0]
          : null
      );
      setEarlytime(payload?.earliestDate ? moment(payload.earliestDate).format('hh:mm:ss') : null);
      setlatestdate(payload?.latestDate ? `${payload?.latestDate}`.split('T')[0] : null);
      setLatesttime(payload?.latestDate ? moment(payload.latestDate).format('hh:mm:ss') : null);
    }
  }, [payload]);
  useEffect(() => {
    if (earlydate || earlytime) {
      setPayload((state) => ({
        ...state,
        earliestDate: earlydate + 'T' + earlytime
      }));
    }
  }, [earlydate, earlytime]);

  useEffect(() => {
    calculateWeakDays();
  }, []);
  function sortDays(array) {
    let daysSort = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    let sortedDays = [];
    daysSort.forEach(async (value) => {
      sortedDays.push(array.find((e) => e.name == value));
    });
    return sortedDays;
  }

  const calculateWeakDays = async () => {
    let days = [];
    let currentDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    days.push({ date: currentDate, name: currentDate.format('dddd') });
    for (let i = 1; i <= 7; i++) {
      let date = moment(days[i - 1].date).add(1, 'days');
      let date1 = date;
      days.push({ date, name: date1.format('dddd') });
    }
    let sortedArray = sortDays(days);
    setWeekDays(sortedArray);
  };
  const onSubmit = () => {
    handleSubmitSettings();
  };

  useEffect(() => {
    if (payload?.setPlayersDeadline < 0) {
      setPayload((state) => ({ ...state, forfeitTeam: 'Do nothing' }));
    }
  }, [payload?.setPlayersDeadline]);
  useEffect(() => {
    setPayload((state) => ({
      ...state,
      setPlayersDeadline: setPlayersDeadlineHr * 60 * 60 + setPlayersDeadlineMin * 60
    }));
  }, [setPlayersDeadlineHr, setPlayersDeadlineMin]);
  const onDeleteCompetition = () => {
    Axios.put(process.env.REACT_APP_CORE_API + '/api/organizer/trash/delete', {
      Organizers: [],
      Folders: [],
      Competitions: [id]
    });
    let x = window.location.href.split('/')[2];
    window.location.replace('http://' + x);
  };
  const onChange = (e) => {
    const value = e.target.value;
    setPayload((state) => ({ ...state, rescheduleType: value }));
    dispatch(setIsAnyChangeInTab(true));
  };
  const style = {
    margin: '5px 0'
  };
  if (loadingState) return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Loading/></div>
  )
  return (
    <>
      <Row className="align-item-baseline">
        <Col md={6}>
          {participantType === 1 && (
            <>
              <h4 style={{ font: 'normal normal bold 18px/22px Montserrat' }}>SET PLAYER</h4>
              <Row>
                <Col>
                  <Form.Group>
                    <CheckboxSlider
                      label={'REQUIRE TEAMS TO SET PLAYERS'}
                      value={payload?.setPlayersDeadline < 0 ? false : true}
                      style={style}
                      onChange={(e) => {
                        // console.log("Onchange called")
                        setPayload((state) => {
                          const _state = {...state}
                          return {
                          ..._state,
                          setPlayersDeadline:
                            payload.setPlayersDeadline > 0
                              ? -(payload.setPlayersDeadline + 1)
                              : -(payload.setPlayersDeadline + 1)
                        }});
                        dispatch(setIsAnyChangeInTab(true));
                      }}
                      explenamtion={
                        <strong style={{ fontWeight: '100', color: '#68717e' }}>
                          Require teams to declare their lineup a certain time before a match.
                        </strong>
                      }
                    />
                  </Form.Group>
                </Col>{' '}
              </Row>
              {payload?.setPlayersDeadline > -1 ? (
                <>
                  <Row className="mt-30">
                    <Col md={12}>
                      <h5
                        style={{
                          font: 'normal normal bold 14px/18px Montserrat',
                          margin: '10px 0px'
                        }}>
                        DEADLINE
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label
                        className="form-label lable"
                        style={{
                          font: 'normal normal bold 12px/15px Montserrat',
                          textTransform: 'uppercase'
                        }}>
                        set player deadline
                      </label>

                      <Row>
                        <Col md={6}>
                          <InputGroup>
                            <div className="d-flex align-item-center">
                              <input
                                className="form-input width-30"
                                type={'number'}
                                value={
                                  setPlayersDeadlineHr > -1
                                    ? Math.floor(payload.setPlayersDeadline / 3600)
                                        .toString()
                                        .padStart(2, '0')
                                    : 0
                                }
                                onChange={(e) => {
                                  setSetPlayersDeadlineHr(
                                    e.target.value > 23 ? '23' : e.target.value
                                  );
                                  dispatch(setIsAnyChangeInTab(true));
                                }}
                                min="0"
                              />
                              <InputGroup.Text className="add-on">hrs</InputGroup.Text>
                            </div>
                          </InputGroup>
                        </Col>
                        <Col md={6}>
                          <InputGroup>
                            <label className="form-label lable"></label>
                            <div className="d-flex align-item-center">
                              <input
                                className="form-input width-30"
                                type={'number'}
                                value={Math.floor((payload.setPlayersDeadline % 3600) / 60)
                                  .toString()
                                  .padStart(2, '0')}
                                onChange={(e) => {
                                  setSetPlayersDeadlineMin(e.target.value);
                                  dispatch(setIsAnyChangeInTab(true));
                                }}
                                min="0"
                              />
                              <InputGroup.Text className="add-on">mins</InputGroup.Text>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mt-3">
                      <div className="form-group">
                        <label className="form-label lable">IF A TEAM FAILS TO SET PLAYERS</label>
                        <select
                          name={'teamSize'}
                          className={'form-input'}
                          value={payload.forfeitTeam}
                          style={{ marginTop: '5px' }}
                          onChange={(e) => {
                            const value = e.target.value;
                            setPayload((state) => ({ ...state, forfeitTeam: value }));
                            dispatch(setIsAnyChangeInTab(true));
                          }}>
                          <option value="Do nothing">Do nothing</option>
                          <option value="Use last players">Use players from previous match</option>
                          <option value="Forfeit Team">Forfeit team</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )}
        </Col>
        <Col md={6}>
          <h4 style={{ marginBottom: '5px' }}>MATCH RESCHEDULING</h4>
          <p style={{ color: '#68717e', marginTop: '5px' }}>
            Determine the options for rescheduling matches in the competition.
          </p>
          <Col md={12} className="mt-5">
            <Row>
              <div className="form-group">
                {/* <select
                    name={'teamSize'}
                    className={'form-input'}
                    style={{width:'110%'}}
                    value={payload.rescheduleType}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPayload((state) => ({ ...state, rescheduleType: value }));
                      dispatch(setIsAnyChangeInTab(true));
                    }}>
                    <option value="NO_RESCHEDULE">No rescheduling</option>
                    <option value="WEEK">Within the week</option>
                    <option value="INTERVAL">Within an interval</option>
                  </select> */}
                <SelectWithDescription
                  name="criteria"
                  // disabled={isMatchesGenerated || _criteria.criteria === 'none' ? true : false}
                  // defaultVal={_criteria.criteria}
                  defaultVal={payload.rescheduleType}
                  // defaultSelection={_criteria.criteria ? _criteria.criteria : 'Points'}
                  // handleChange={(e)=>optionsSelectorFunct(e,ind)}
                  handleChange={(e) => {
                    onChange(e);
                  }}
                  options={[
                    {
                      label: 'No rescheduling',
                      value: 'NO_RESCHEDULE',
                      description: 'Participants cannot reschedule matches.'
                    },
                    {
                      label: 'Within the week',
                      value: 'WEEK',
                      description:
                        'Participants can reschedule a match to whenever they please within the week of the match.'
                    },
                    {
                      label: 'Within an interval',
                      value: 'INTERVAL',
                      description:
                        'Participants can reschedule a match to whenever they please within a defined interval.'
                    }
                  ]}
                />
              </div>
            </Row>
            <Row>
              {
              payload.rescheduleType && payload.rescheduleType === 'WEEK' && (
                <div className="form-group" style={{ paddingLeft: '0px', paddingTop: '10px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <label className="form-label lable" style={{ paddingRight: '5px' }}>
                      Week Start{' '}
                    </label>
                    <Tippy
                      content="Define what day that marks the start of a new week."
                      placement="right">
                      <div>
                        <HiInformationCircle />
                      </div>
                    </Tippy>
                  </div>
                  <select
                    name={'teamSize'}
                    className={'form-input'}
                    value={
                      payload.rescheduleType === 'WEEK'
                        ? moment(payload.latestDate).toISOString()
                        : null
                    }
                    onChange={(e) => {
                      const datetime = e.target.value;
                      setPayload((state) => ({
                        ...state,
                        latestDate: moment(datetime).format('DD-MM-YYYY HH:mm:ss').toString()
                      }));
                      dispatch(setIsAnyChangeInTab(true));
                    }}>
                    <option value={false} disabled>
                      Select Week Day
                    </option>
                    {weekDays.map((day, index) => (
                      <option key={index} value={moment(day.date).toString()}>
                        {day.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {payload.rescheduleType && payload.rescheduleType === 'INTERVAL' && (
                <Row style={{ alignItems: 'flex-start' }}>
                  <Col style={{ paddingLeft: '63px', paddingTop: '10px' }}>
                    <Form.Group className="mb-3">
                      {/* <label className="form-label lable">Earliest Date</label> */}
                      <label className="form-label lable">From</label>
                      {/* <Datetime
                        value={moment(payload.earliestDate).format('DD-MM-YYYY HH:mm:ss')}
                        onChange={(e) => {
                          setPayload((state) => ({
                            ...state,
                            earliestDate: e
                          }));
                        }}
                        dateFormat="DD-MM-YYYY"
                        timeFormat="HH:mm:ss"
                        name="earliestDate"
                        // isValidDate={(currentDate) => currentDate.isAfter(payload.earliestDate)}
                      /> */}
                      <Row>
                        <Col style={{ width: '60%' }}>
                          <Form.Group>
                            <input
                              type="date"
                              id="startDate"
                              name="startDate"
                              dateFormat="DD/MM/YYYY"
                              // value={Format="HH:mm:ss"
                              value={
                                payload?.earliestDate
                                  ? `${moment(payload.earliestDate).format('YYYY-MM-DD')}`.split(
                                      'T'
                                    )[0]
                                  : null
                              }
                              onChange={(e) => {
                                setEarlydate(e.target.value);
                                dispatch(setIsAnyChangeInTab(true));

                                // setUnSavedStages((state) => [...state, selectedTab]);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.startDate && errors.startDate.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col style={{ width: '40%' }}>
                          <Form.Group>
                            <input
                              type="time"
                              id="startDate"
                              name="startDate"
                              dateFormat="DD/MM/YYYY"
                              // timeFormat="HH:mm:ss"
                              value={moment(payload.earliestDate).format('hh:mm:ss')}
                              my-date-format="DD/MM/YYYY, hh:mm:ss"
                              onChange={(e) => {
                                setEarlytime(e.target.value);
                                dispatch(setIsAnyChangeInTab(true));

                                // setUnSavedStages((state) => [...state, selectedTab]);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.startDate && errors.startDate.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col style={{ paddingTop: '10px' }}>
                    <Form.Group className="mb-3">
                      {/* <label className="form-label lable">Latest Date</label> */}
                      <label className="form-label lable">To</label>
                      {/* <Datetime
                        value={moment(payload.latestDate).format('DD-MM-YYYY HH:mm:ss')}
                        onChange={(e) => {
                          setPayload((state) => ({
                            ...state,
                            latestDate: e
                          }));
                        }}
                        dateFormat="DD-MM-YYYY"
                        timeFormat="HH:mm:ss"
                        // isValidDate={(currentDate) => currentDate.isAfter(payload.latestDate)}
                        name="latestDate"
                      /> */}
                      <Row>
                        <Col style={{ width: '60%' }}>
                          <Form.Group>
                            <input
                              type="date"
                              id="startDate"
                              name="startDate"
                              dateFormat="DD/MM/YYYY"
                              value={
                                payload?.latestDate ? `${payload?.latestDate}`.split('T')[0] : null
                              }
                              // defaultValue={`${payload.latestDate}`}
                              onChange={(e) => {
                                setlatestdate(e.target.value);
                                dispatch(setIsAnyChangeInTab(true));

                                // setUnSavedStages((state) => [...state, selectedTab]);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.startDate && errors.startDate.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col style={{ width: '40%' }}>
                          <Form.Group>
                            <input
                              type="time"
                              id="startDate"
                              name="startDate"
                              dateFormat="DD/MM/YYYY"
                              // timeFormat="HH:mm:ss"
                              value={moment(payload.latestDate).format('hh:mm:ss')}
                              my-date-format="DD/MM/YYYY, hh:mm:ss"
                              onChange={(e) => {
                                setLatesttime(e.target.value);
                                dispatch(setIsAnyChangeInTab(true));

                                // setUnSavedStages((state) => [...state, selectedTab]);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.startDate && errors.startDate.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <span className="text-danger">
                      {moment(payload.earliestDate).isAfter(moment(payload.latestDate))
                        ? 'Latest Date should be after the Earliest Date'
                        : null}
                    </span>
                  </Col>
                </Row>
              )}
            </Row>
          </Col>
        </Col>
        <Row style={{ padding: '30px' }}>
          <Col>
            <button className="button primary" onClick={() => onSubmit()}>
              Save Changes
            </button>
            {userPermissions.createAndDeleteCompetitions && (
              <button className="button primary redButton" onClick={() => onDeleteCompetition()}>
                Delete Competition
              </button>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};
export default GeneralTab;
