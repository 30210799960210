import React, { useState, createContext, useEffect } from 'react';
import Axios from 'axios';
import './_unSavedChanges.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getTrash } from '../../../redux/action/trashActions';
import Popup_modals from '../OrganizerPopup/modals/Popup_modals';
import { HiX } from 'react-icons/hi';
import { setIsAnyChangeInTab, setUnSavedStages } from '../../../redux/action/CompetitonData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { string_to_slug } from '@utils';

const backendServerURL = process.env.REACT_APP_CORE_API;

const Component = ({ onClose, onStayPage, setChangePage,setDefaultPictures=()=>{},isCurrentPage,baseRoute }) => {
  const dispatch=useDispatch()
  const stages = useSelector((state) =>
state.COMPETITION.StageTabs ? state.COMPETITION.StageTabs : []
);
const history = useHistory()

  return (
    <div className="newfolder-main">
      <div className="modal-header-unsaved">
        <h2>WARNING: UNSAVED CHANGED</h2>
        <HiX
          onClick={() => onClose(false)}
          style={{ marginTop: '0px', marginRight: '-77px',marginLeft:'50px', fontSize: '26.5px', color: '#68717e' }}
        />
      </div>
      <div className="organizer-unsaved-text">
        You have unsaved changes. Are you sure you want to leave this page?
      </div>
      <div className="newfolder-buttons-unsaved">
        <button
          className="button bordered medium ml-3 mr-3" style={{backgroundColor:'#68717e'}}
          onClick={() => {
            dispatch(setIsAnyChangeInTab(false))
            onClose(false)
            setDefaultPictures()
            history.push(`${baseRoute}/${string_to_slug(isCurrentPage)}`);

          }}>
          LEAVE
        </button>
        <button
          className="button primary medium"
            onClick={() => {
              onClose(false)
          }}>
          STAY
        </button>
      </div>
    </div>
  );
};
const UnSavedChanges = ({ open, onClose, onStayPage, setChangePage,setDefaultPictures,isCurrentPage, baseRoute }) => {
  return (
    <Popup_modals
      open={open}
      onClose={onClose}
      Component={Component}
      onStayPage={onStayPage}
      setChangePage={setChangePage}
      setDefaultPictures={setDefaultPictures}
      isCurrentPage={isCurrentPage}
      baseRoute={baseRoute}
    />
  );
};
export default UnSavedChanges;
