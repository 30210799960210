import React, { useEffect } from 'react';
import { SimpleTabs } from '../../../_common';
import { Row, Col } from 'react-bootstrap';
import GeneralTab from './generalTab';
import { useState } from 'react';
import SettingRules from './SettingRules';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setIsAnyChangeInTab } from '../../../../redux/action/CompetitonData';
import { SET_LOADING } from '../../../../redux/action/types';
const SettingsTab = ({ userPermissions }) => {
  const dispatch = useDispatch();
  const tabs = ['General', 'Registration', 'Game', 'Rules'];
  const [selectedTab, setSelectedTab] = useState(0);
  const [league, setLeague] = useState({});
  const [originalData, setOriginaldata] = useState({});
  const [modifiedTabs, setModifiedTabs] = useState([]);
  const leagesid = useSelector(
    (state) => state?.COMPETITION?.Competition?.multiStageLeague?.stages[0]?.league?._id
  );
  const loadingState = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const [payload, setPayload] = useState({
    canPlayersVeto: true,
    earliestDate: null,
    latestDate: null,
    rescheduleType: null,
    setPlayersDeadline: 0,
    forfeitTeam: null,
    rulesGhostSlug: null
  });
  useEffect(() => {
    dispatch({type: SET_LOADING, payload: true})
    leagesid &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${leagesid}?withMatches=true`).then(
        ({ data }) => {
          setLeague(data);
          dispatch({type: SET_LOADING, payload: false})
        }
      );
      dispatch({type: SET_LOADING, payload: false})
  }, [leagesid]);

  useEffect(() => {
    setPayload({
      canPlayersVeto: true,
      earliestDate:
        league?.reschedule && league?.reschedule.earliestDate
          ? moment(league?.reschedule.earliestDate).format('YYYY-MM-DDTHH:MM:SS')
          : moment(league?.startDate).format('YYYY-MM-DDTHH:MM:SS')
          ? moment(league?.startDate).format('YYYY-MM-DDTHH:MM:SS')
          : false,
      latestDate:
        league?.reschedule && league?.reschedule.latestDate
          ? moment(league?.reschedule.latestDate).format('YYYY-MM-DDTHH:MM:SS')
          : moment(league?.startDate).format('YYYY-MM-DDTHH:MM:SS')
          ? moment(league?.startDate)
              .add(7, 'days')
              .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
              .format('YYYY-MM-DDTHH:MM:SS')
          : false,
      rescheduleType:
        league?.reschedule && league?.reschedule.rescheduleType
          ? league?.reschedule.rescheduleType
          : 'NO_RESCHEDULE',
      setPlayersDeadline: league?.setPlayersDeadline,
      forfeitTeam: league?.forfeitTeam,
      rulesGhostSlug: league?.rulesGhostSlug
    });
    setOriginaldata({
      canPlayersVeto: true,
      earliestDate:
        league?.reschedule && league?.reschedule.earliestDate
          ? moment(league?.reschedule.earliestDate).format('YYYY-MM-DDTHH:MM:SS')
          : moment(league?.startDate).format('YYYY-MM-DDTHH:MM:SS')
          ? moment(league?.startDate).format('YYYY-MM-DDTHH:MM:SS')
          : false,
      latestDate:
        league?.reschedule && league?.reschedule.latestDate
          ? moment(league?.reschedule.latestDate).format('YYYY-MM-DDTHH:MM:SS')
          : moment(league?.startDate).format('YYYY-MM-DDTHH:MM:SS')
          ? moment(league?.startDate)
              .add(7, 'days')
              .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
              .format('YYYY-MM-DDTHH:MM:SS')
          : false,
      rescheduleType:
        league?.reschedule && league?.reschedule.rescheduleType
          ? league?.reschedule.rescheduleType
          : 'NO_RESCHEDULE',
      setPlayersDeadline: league?.setPlayersDeadline,
      forfeitTeam: league?.forfeitTeam,
      rulesGhostSlug: league?.rulesGhostSlug
    });
  }, [league]);
  useEffect(() => {
    // console.log(_.isEqual(payload, originalData));

    ///For tabs red
    if (originalData && originalData?.setPlayersDeadline !== undefined) {
      let generaltab = [
        'canPlayersVeto',
        'earliestDate',
        // 'forfeitTeam',
        'latestDate',
        'rescheduleType',
        'setPlayersDeadline'
      ];
      let rulesTab = ['rulesGhostSlug'];
      let rulesChanges = compareObjects(rulesTab, payload, originalData);
      if (!rulesChanges) {
        setModifiedTabs((tabs) => {
          let _tabs = [...tabs];
          if (!_tabs.includes('Rules')) {
            _tabs.push('Rules');
          }
          return _tabs;
        });
      } else {
        setModifiedTabs((tabs) => {
          let _tabs = [...tabs];
          if (_tabs.includes('Rules')) {
            _tabs = _tabs.filter((el) => el !== 'Rules');
          }
          return _tabs;
        });
      }
      let generalChanges = compareObjects(generaltab, payload, originalData);
      if (!generalChanges) {
        setModifiedTabs((tabs) => {
          let _tabs = [...tabs];
          if (!_tabs.includes('General')) {
            _tabs.push('General');
          }
          return _tabs;
        });
      } else {
        setModifiedTabs((tabs) => {
          let _tabs = [...tabs];
          if (_tabs.includes('General')) {
            _tabs = _tabs.filter((el) => el !== 'General');
          }
          return _tabs;
        });
      }
      if (!rulesChanges || !generalChanges) {
        dispatch(setIsAnyChangeInTab(true));
      } else {
        dispatch(setIsAnyChangeInTab(false));
      }
    }
    ///end for tabs red
    // if (_.isEqual(payload, originalData) === false) {
    //   dispatch(setIsAnyChangeInTab(true));
    // } else {
    //   dispatch(setIsAnyChangeInTab(false));
    // }
  }, [payload, payload?.setPlayersDeadline]);
  const compareObjects = (keys, obj1, obj2) => {
    // console.log('compare called', keys, obj1, obj2);
    for (let key of keys) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  };
  const handleSubmitSettings = async () => {
    try {
      let _payload = payload.forfeitTeam ? payload : { ...payload, forfeitTeam: 'Do nothing' };
      await Axios.put(
        `${process.env.REACT_APP_CORE_API}/api/leagues/setting/${leagesid}`,
        _payload
      );
      setLeague((league) => ({
        ...league,
        ..._payload
      }));
      dispatch(setIsAnyChangeInTab(false));
      toast.success('Saved');
    } catch (error) {
      toast.error('There was a problem saving');
    }
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SimpleTabs
              pages={tabs}
              page={selectedTab}
              setPage={setSelectedTab}
              modifiedTabs={modifiedTabs}
            />
            <button className="button primary" onClick={handleSubmitSettings}>
              Save Changes
            </button>
          </div>
        </Col>
      </Row>
      {selectedTab === 0 ? (
        <GeneralTab
          loadingState={loadingState}
          setPayload={setPayload}
          payload={payload}
          handleSubmitSettings={handleSubmitSettings}
          userPermissions={userPermissions}
        />
      ) : selectedTab === 1 ? (
        <div>Registration</div>
      ) : selectedTab === 2 ? (
        <div>Game</div>
      ) : selectedTab === 3 ? (
        <SettingRules
          setPayload={setPayload}
          payload={payload}
          handleSubmitSettings={handleSubmitSettings}
          userPermissions={userPermissions}
        />
      ) : null}
    </>
  );
};
export default SettingsTab;
