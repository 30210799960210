import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiFolder, HiDotsHorizontal } from 'react-icons/hi';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { moveToFolder } from '../../../redux/action/dragNDropActions';
import ShareOptions from '../../_common/ShareOptions/ShareOptions';
import moment from 'moment/moment';
import ItemRow from './ItemRow';
import { usePermissions } from '../../../utils/usePermissions';
import { setContextObj } from '../../../redux/action/contextMenuAction';

function ListViewItem({
  folder,
  index,
  parentId,
  destination,
  clearItemSelection,
  handleSelection,
  getTrans,
  ...props
}) {
  const ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const contextMenu = useRef(document.getElementById('context-menu'));
  const permission = usePermissions(folder._id);
  const showContextMenu = (e, item, perm, type, isTrash = false) => {
    e.preventDefault();
    if (perm.showOptions) {
      document.body.style.overflow = 'hidden';
    }
    let obj = {
      item: item,
      permissions: perm,
      type: type,
      isTrash: isTrash
    };
    dispatch(setContextObj(obj));
    if (perm.showOptions) {
      const { clientX: mouseX, clientY: mouseY } = e;
      contextMenu.current.style.top = `${mouseY}px`;
      contextMenu.current.style.left = `${mouseX}px`;
      contextMenu.current.classList.add('visible');
    }
  };
  const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop({
    accept: 'ITEM',
    drop: (item, monitor) => {
      dispatch(moveToFolder(item));
      clearItemSelection();
    },
    hover: (item, monitor) => {
      const dragIndex = item.destination;
      const hoverIndex = destination;
      if (dragIndex === hoverIndex) {
        return;
      }
      item.destination = hoverIndex;
    },
    canDrop: (item, monitor) => {
      return !item.folders.includes(destination);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'ITEM',
    item: (item, monitor) => {
      let dragFields;
      if (selectedFolders.find((id) => id === destination)) {
        dragFields = selectedFolders;
      } else {
        dragFields = [...selectedFolders, destination];
      }
      return {
        folders: dragFields,
        source: parentId,
        destination: destination,
        name: folder.name,
        parent: parentId ?? false,
        organizer: folder.organizer._id,
        folder: folder
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const selectedFolders = useSelector((state) => state.DRAGNDROP.SelectedFolders ?? []);

  // const opacity = isDragging ? 0.4 : 1;
  const handleRouting = (id) => {
    history.push(`/folders/${id}`);
  };

  const handleRowSelection = (cmdKey, shiftKey, ctrlKey, index) => {
    handleSelection(index, cmdKey, shiftKey, ctrlKey);
  };

  useEffect(() => {
    preview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    });
  }, []);
  drag(drop(ref));

  const selected = selectedFolders.find((id) => folder._id === id);
  return (
    <tr 
      onClick={(e) => {
        // debugger;
        /*if (e.target?.parentElement?.nodeName === 'TR') {
          e.stopPropagation();*/
          handleRowSelection(e.metaKey, e.shiftKey, e.ctrlKey, index);
        }
      }/*}*/
      onContextMenu={(e) => {
        showContextMenu(e, folder, permission, 'FOLDER');
      }}
      onDoubleClick={(e) => {
        if (e.target?.parentElement?.nodeName === 'TR') {
          localStorage.setItem('lastSelected', folder._id);
          handleRouting(folder._id);
        }
      }}
      style={selected ? { background: '#050c16' } : {}}
      key={index}
      ref={ref}
      id={folder._id}
      className="hoverable-row">
      <ItemRow
        selected={selected}
        folder={folder}
        ind={index}
        refer={ref}
        // ShareOptions={ShareOptions}
        getTrans={getTrans}
        showOptions={true}
      />
    </tr>
  );
}

export default ListViewItem;
