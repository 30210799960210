import { useEffect, useRef } from 'react';

function useCtrlDelete(callback) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (
        (event.key === 'Delete' && event.ctrlKey && !event.altKey && !event.metaKey) || // Windows: Ctrl + Delete
        (event.key === 'Delete' && event.altKey && !event.ctrlKey && !event.metaKey) || // Mac: Option + Delete
        (event.key === 'Delete' && event.fnKey && !event.ctrlKey && !event.altKey && !event.metaKey) // Mac: fn + Delete
      ) {
        callbackRef.current();
      }else{
        return;
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}

export default useCtrlDelete;
