import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../context/AppContext';

export const usePermissions = (folderId) => {
  const [showOptions, setShowOptions] = useState({
    rename: false,
    share: false,
    delete: false,
    showOptions: false
  });
  // console.log({id})
  const { user } = useContext(AppContext);
  const Folders = useSelector((state) =>
    state.FOLDERS?.Folders?.folders ? state.FOLDERS.Folders.folders : []
  );
  const InheritedPermissions = useSelector((state) =>
    state.FOLDERS?.InheritedPermissions ? state.FOLDERS.InheritedPermissions : []
  );
  useEffect(() => {
    // console.log("Its calling",{InheritedPermissions,Folders,folderId})
    let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
    let orgAccess = user?.state?.permissions?.user?.OrganizerAccess ?? false;
    let sharedOrganizer = user?.state?.sharedOrganizer > 0 ? true : false;
    if (!orgAdmin && (orgAccess || sharedOrganizer)) {
      const folderRoles = Folders.find((elem) => elem._id === folderId)?.organizerRoles;
      const inheritedRole = InheritedPermissions.find((perm) => perm.user._id === user.state._id);
      if (folderRoles?.length > 0) {
        const userRole = folderRoles.find((perm) => perm.user?._id === user.state._id);
        if (userRole) {
          const { name, permission } = userRole.userPermission;
          const obj = {
            rename: permission.manageFolderStructure,
            share: permission.changePermission,
            delete: permission.manageFolderStructure,
            showOptions:
              permission.manageFolderStructure ||
              permission.changePermission ||
              permission.manageFolderStructure
          };
          setShowOptions(obj);
        }
      }
      if (inheritedRole) {
        const { name, permission } = inheritedRole.userPermission;
        setShowOptions((state) => ({
          ...state,
          rename: state.rename ? state.rename : permission.manageFolderStructure,
          share: state.share ? state.share : permission.changePermission,
          delete: state.delete ? state.delete : permission.manageFolderStructure,
          showOptions:
            permission.manageFolderStructure ||
            permission.changePermission ||
            permission.manageFolderStructure
        }));
      }
    } else{

    if (user.state.admin || orgAdmin) {
      setShowOptions({
        rename: true,
        share: true,
        delete: true,
        showOptions: true
      });
    }
  }
    return () => {
      setShowOptions({
        rename: false,
        share: false,
        delete: false,
        showOptions: false
      });
    };
  },[Folders.length === 0 ? Folders.length : Folders, InheritedPermissions.length === 0 ? InheritedPermissions.length : InheritedPermissions]);

  return showOptions;
};





export const usePermissionsOrg = (organisationId) => {
  const [showOptions, setShowOptions] = useState({
    rename: false,
    share: false,
    delete: false,
    showOptions: false
  });
  const { user } = useContext(AppContext);
  const Organizers = useSelector((state) =>
    state.FOLDERS?.Organizers ? state.FOLDERS.Organizers : []
  );
  useEffect(() => {
    let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
    let orgAccess = user?.state?.permissions?.user?.OrganizerAccess ?? false;
    let sharedOrganizer = user?.state?.sharedOrganizer > 0 ? true : false;

     if (user.state.admin || orgAdmin) {
      setShowOptions({
        rename: true,
        share: true,
        delete: true,
        showOptions: true
      });
    }else if(orgAccess || sharedOrganizer){
      const folderRoles = Organizers.find((elem) => elem._id === organisationId)?.organizerRoles;
      if (folderRoles && folderRoles.length > 0) {
        const userRole = folderRoles.find((perm) => perm.user._id === user.state._id);
        if (userRole) {
          const { name, permission } = userRole?.userPermission;
          const obj = {
            rename: permission.manageFolderStructure,
            share: permission.changePermission,
            delete: permission.manageFolderStructure,
            showOptions:
              permission.manageFolderStructure ||
              permission.changePermission ||
              permission.manageFolderStructure
          };
          setShowOptions(obj);
        }
      }
    }
    return () => {
      setShowOptions({
        rename: false,
        share: false,
        delete: false,
        showOptions: false
      });
    };
  }, [Organizers, user]);

  return showOptions;
};
