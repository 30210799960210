import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TIMEZONES } from '../../../../../utils/timezones';
import MultipleSelect from '../../../../_common/MultiSelectDropDown/MultipleSelect';
import './match-settings-page.scss';
import { InformationLogo } from '@common';
import {
  getConvertedDate,
  getDefaultDateTime,
  getFormattedDate,
  getUtcFormatDate
} from '../../genHelpers';
import { toast } from 'react-toastify';

function MatchSettingsPage({ competition, payload, setPayload, tag, stage, maxGroup }) {
  const timeZones = TIMEZONES();
  const [matchesPerWeek, setMatchesPerWeek] = useState([]);
  const [noOfWeeks, setNoOfWeeks] = useState(0);
  // let {teams,rounds,matches} = tag
  useEffect(() => {
    if (payload.matchPerWeek !== 'custom') {
      let y = payload.matchPerWeek;
      let M = tag.matches ?? 0;
      let x = Math.ceil(parseInt(M) / y);
      if (x === 1) {
        setNoOfWeeks('1 week');
      } else {
        setNoOfWeeks(`${x} no of Weeks`);
      }
    }
    if (payload.matchesPerWeek) {
      let y = payload.matchesPerWeek;
      let M = tag.matches ?? 0;
      let x = Math.ceil(parseInt(M) / y);
      if (payload.durationWeeks !== x) {
        setPayload((state) => ({ ...state, durationWeeks: x }));
      }
      if (x === 1) {
        setNoOfWeeks('1 week');
      } else {
        setNoOfWeeks(`${x} no of Weeks`);
      }
    }
    console.log('payload2=>',payload);
  }, [payload]);
  useEffect(() => {
    // console.log({ competition });
    let obj = {};
    if (payload.matchPerWeek === 'once') {
      let x = Math.floor(maxGroup.noOfTeams / 2);
      obj.matchesPerWeek = x;
    }

    if (!payload.bestOf) {
      let _scoreSystem = stage?.scoreSystem[0]?._id ?? '';
      // console.log({_scoreSystem})
      if (!_scoreSystem) {
        toast.error('This stage does not have scoreSystem');
        return;
      }

      let y = stage?.scoreSystem[0]?.bestOf ?? 1;
      obj.bestOf = y;
      obj.scoreSystem = _scoreSystem;
    }

    setPayload((state) => ({
      ...state,
      ...obj
    }));
    if (competition.startedDate && !payload.startDate) {
      let _date = getDefaultDateTime(competition.startedDate);
      let _timezone = competition.timezone;
      // let newDate = getUtcFormatDate(_date, _timezone,null,'18:00');
      setPayload((state) => ({
        ...state,
        // date: _date,
        startDate: _date,
        timezone: _timezone
      }));
    }
    setMatchesPerWeek(
      competition.participantType === 1
        ? [
            { label: 'Once Per Team', value: 'once' },
            { label: 'Twice per team', value: 'twice' },
            { label: 'Thrice per team', value: 'thrice' },
            { label: 'Custom', value: 'custom' }
          ]
        : [
            { label: 'Once Per Player', value: 'once' },
            { label: 'Twice per Player', value: 'twice' },
            { label: 'Thrice per Player', value: 'thrice' },
            { label: 'Custom', value: 'custom' }
          ]
    );
  }, [competition]);

  const handleChange = ({ name, value, checked }) => {
    setPayload((state) => {
      switch (name) {
        case 'startDate':
          let _startDate = value;
          let _day = new Date(value).getDay();
          if (_day === 0 || _day === 6) {
            // If startDate is Sunday or Saturday so we need to moke it monday or something
            let newStartDate = new Date(value);
            newStartDate.setDate(newStartDate.getDate() + ((_day + 1) % 5));
            _startDate = getFormattedDate(newStartDate);
          }
          return {
            ...state,
            startDate: _startDate,
            startedDate: value
          };
        case 'timezone':
          return {
            ...state,
            timezone: value
          };
        case 'bestOf':
          return {
            ...state,
            bestOf: parseInt(value)
          };
        case 'alternateSides':
          return {
            ...state,
            alternateSides: checked
          };
        case 'matchPerWeek':
          let newVal = 0;
          if (value === 'once') {
            newVal = Math.floor(maxGroup.noOfTeams / 2);
          } else if (value === 'twice') {
            newVal = Math.floor(maxGroup.noOfTeams / 2) * 2;
          } else if (value === 'thrice') {
            newVal = Math.floor(maxGroup.noOfTeams / 2) * 3;
          } else {
            newVal = false;
          }
          return {
            ...state,
            matchPerWeek: value,
            matchesPerWeek: newVal,
            weekSchedule: []
          };
        case 'matchesPerWeek':
          return {
            ...state,
            matchesPerWeek: parseInt(value)
          };
        case 'broadcasted':
          return {
            ...state,
            broadcasted: checked
          };
        case 'sideSelection':
          return {
            ...state,
            sideSelection: checked
          };
        case 'broadCastChannel':
          return {
            ...state,
            broadCastedChannel: (competition.streams.filter(stream => stream._id === value))[0]
          }
        default:
          return state;
      }
    });
  };
  return (
    <div className="matchsettingspage">
      <Row className="row-heading-two">
        <h2>Match Settings</h2>
        <div className="tag-team-round-matches w-100" style={{backgroundColor:'rgb(65, 65, 65,0.1)'}}>
          <div className="num">
            {tag?.teams ?? 0} <span style={{color:'#68717e'}}>Teams</span>
          </div>
          <div className="num">
            {tag?.rounds ?? 0} <span style={{color:'#68717e'}}>Rounds</span>
          </div>
          <div className="num">
            {tag?.matches ?? 0} <span style={{color:'#68717e'}}>Matches</span>
          </div>
        </div>
      </Row>
      <Row className="start-date-time-row">
        <Col className="equal-columns">
          <label className="text-12 label-left faded bold uppercase" htmlFor="stageName">
            Start Date
            {/* {content && <InformationLogo content={content} />} */}
          </label>
          <input
            onChange={(e) => {
              handleChange(e.target);
            }}
            id="dateTime-pg2"
            type="datetime-local"
            min={competition.startedDate &&  new Date(payload.startDate).toLocaleDateString()}
            name={'startDate'}
            autoComplete="off"
            // className={` ${defaultValue === '' ? 'form-input red-bg' : classNames}`}
            // value={payload.startedDate ? (payload.startedDate.splice(0,-3)) : (competition.startedDate.splice(0,-3))}
            value={payload.startedDate ? payload.startedDate : competition.startedDate}
            placeholder={'Select Date'}
            // step={60}
          />
        </Col>
        <Col className="equal-columns">
          <label className="text-12 faded bold uppercase" htmlFor="lastName">
            Timezone
          </label>
          <select
            onChange={(e) => handleChange(e.target)}
            name="timezone"
            id="timezone-select01"
            className={'form-input'}
            // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
            defaultValue={payload?.timezone ? payload?.timezone : competition?.timezone}>
            <option value="" disabled>
              Timezone
            </option>
            {timeZones.map(({ name, value }, i) => (
              <option key={i} value={value}>
                {name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      {competition.game.shortName === 'LoL' && <Row className="stage-row">
        <h4>Best Of</h4>
        {/* <h5>Choose the groups you want to generate matches for.</h5> */}
        <div style={{ marginTop: '10px', width: '100%' }}>
          <select
            onChange={(e) => handleChange(e.target)}
            name="bestOf"
            className={'form-input'}
            // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
            value={payload.bestOf ? payload.bestOf : 1}>
            <option value="" disabled>
              Choose Best of
            </option>
            {stage?.scoreSystem.map(({ bestOf, _id }, i) => (
              <option key={_id} value={bestOf}>
                {`Best Of ${bestOf}`}
              </option>
            ))}
          </select>
        </div>
      </Row>}
      {payload.bestOf > 1 && (
        <Row className="alternat-sides-row">
          <input
            type="checkbox"
            name="alternateSides"
            onChange={(e) => handleChange(e.target)}
            value={payload.alteranateSides}
          />{' '}
          <h5>Alternate Sides</h5>{' '}
          <InformationLogo content={'Participants will switch sides each game of the match.'} />
        </Row>
      )}
      {!stage.bracketType && (
        <>
          <Row className="start-date-time-row">
            <Col className="equal-columns">
              <label className="text-12 faded bold uppercase" htmlFor="lastName">
                Matches Per Week
                <InformationLogo
                  content={'The number of matches that will be played each week of the competition.'}
                />
              </label>
              <select
                onChange={(e) => handleChange(e.target)}
                name="matchPerWeek"
                className={'form-input'}
                value={payload.matchPerWeek}
                // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
                // defaultValue={StageSettings?.homeTeam}
                // If Participants type is player then it will be one per player etc..
              >
                {matchesPerWeek.map(({ label, value }, i) => (
                  <option key={i} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="equal-columns">
              <label className="text-12 faded bold uppercase" htmlFor="lastName">
                Matches
              </label>
              <input
                value={payload.matchesPerWeek ? payload.matchesPerWeek : payload.matchPerWeek}
                onChange={(e) => {
                  handleChange(e.target);
                }}
                disabled={payload.matchPerWeek === 'custom' ? false : true}
                // disabled={disabled}
                // style={payload.matchPerWeek ? {} : {'background-color':'#191b24 !important'}}
                type={'number'}
                min={1}
                max={tag.matches}
                name={'matchesPerWeek'}
                autoComplete="off"
                // className={'form-input gray-bg'}
                className={` ${payload.matchesPerWeek ? 'form-input gray-bg' : 'form-input black-bg'}`}
                placeholder={`Enter Matches`}
              />
            </Col>
          </Row>  
          <p>Duration: "{noOfWeeks}”</p>
        </>
      )}
      
      {competition?.game?.shortName === 'LoL' ? (
        <>
          <Row className="addons-row-col">
            <label>Addons</label>
          </Row>
          <Row className="divided-two">
            <Row className="alternat-sides-row">
              <input
                type="checkbox"
                onChange={(e) => handleChange(e.target)}
                name="broadcasted"
                value={payload.broadcasted}
              />{' '}
              <h5>Broadcasted</h5>
              <InformationLogo content={'Broadcasted matches will be displayed with a tag.'} />
            </Row>
            <Row className="alternat-sides-row">
              <input
                type="checkbox"
                onChange={(e) => handleChange(e.target)}
                name="sideSelection"
                value={payload.sideSelection}
              />
              <h5>Side Selection</h5>
              <InformationLogo
                content={
                  'Matches with side selection will have team 1 choose their preferred side to start on.'
                }
              />
            </Row>
          </Row>
          {/*payload.broadcasted && (
            <Row>
              <label className="text-12 label-left bold uppercase" htmlFor="stageName">
              Broadcast Channel
              </label>
              <select className='form-input' name={'broadCastChannel'} onChange={(e) => handleChange(e.target)}>
                <option>Default</option>
                {competition.streams.map((stream, i) => <option key={i} value={stream._id}>{stream.name}</option>)}
              </select>
            </Row>
          )*/}
        </>
      ) : null}
    </div>
  );
}

export default MatchSettingsPage;
