import React, { useContext, useEffect, useState } from 'react';
import { Item, Icon } from '@common';
import default_tournament_image from '@img/default-tournament-image.png';
import moment from 'moment';
import { makeCountdownString } from '@utils';
import { HiFolder } from 'react-icons/hi';
import { AppContext } from '../../../context/AppContext';
import { useSelector } from 'react-redux';
const TournamentItemImageInner = ({ text, color, IconName, startTime }) => {
  return (
    <React.Fragment>
      <div className="image-cover"></div>
      {moment() < moment(startTime) ? (
        <span className={`tournament-item-image-inner ${color ?? ''}`}>
          <Icon name={IconName ?? 'clock'} />
          {text ?? ''}
        </span>
      ) : null}
    </React.Fragment>
  );
};

const TournamentItem = (props) => {
  const {
    image,
    title,
    subtitle,
    color,
    date,
    startTime,
    reg_date,
    registerationDeadline,
    onClick,
    organizerName,
    organizerData,
    isTrash,
    isOrganizer,
    isFolder,
    isLastEdit,
    onRouting,
    compId,
    isShowTrashfolders,
    trashDate,
    ind,
    selectedCompitation,
    trashSelected,
    // isRecentGrid,
    isRecent,
    type,
    competitionData,
    isCompetition,
    ...props_rest
  } = { ...props };
  const { user } = useContext(AppContext);
  const [compPermissions, setCompPermissions] = useState({
    rename: false,
    share: false,
    delete: false,
    showOptions: false
  });
  const isRegDateAfterToday = moment(moment(registerationDeadline)).isAfter(moment());
  const [countDown, setCountDown] = useState('00:00:00:00');
  const inheritedPermissions = useSelector((state) =>
    state.FOLDERS.InheritedPermissions ? state.FOLDERS.InheritedPermissions : []
  );
  useEffect(() => {
    let _permissions = competitionData?.topPermission
      ? competitionData?.topPermission
      : competitionData?.organizerRoles
      ? competitionData?.organizerRoles
      : [];
    let allPerms = [..._permissions, ...inheritedPermissions];
    let merged = mergeSamePermissions(allPerms)
    let permission = getRecentCompPermissions(merged);
    setCompPermissions(permission);
  }, [competitionData, inheritedPermissions]);
  useEffect(() => {
    if (startTime) {
      const coundownEndDateTime = isRegDateAfterToday ? registerationDeadline : startTime;
      const _interval = setInterval(() => {
        const now = moment();
        const _startTime = moment(coundownEndDateTime);
        var duration = _startTime.diff(now);
        if (duration > 0) setCountDown(makeCountdownString(duration));
        else {
          clearInterval(_interval);
        }
      }, 1000);
    }
  }, []);
  // const mergeSamePermissions = (arr) => {
  //   const result = [];
  //   const userMap = new Map();
  
  //   for (const elem of arr) {
  //     const user = elem.user;
  //     const existingUser = userMap.get(user._id);
  
  //     if (existingUser) {
  //       existingUser.userPermission.permission = {
  //         ...existingUser.userPermission.permission,
  //         ...elem.userPermission.permission,
  //       };
  //     } else {
  //       userMap.set(user._id, { ...elem });
  //       result.push(elem);
  //     }
  //   }
  
  //   return result;
  // };
  
  const mergeSamePermissions = (arr) => {
    let result = [];
    arr.forEach((elem) => {
      let found = result.findIndex((el) => el.user._id === elem.user._id);
      if (found >= 0) {
        let newObj = {
          ...elem,
          userPermission: {
            ...elem.userPermission,
            permission: {
              ...elem.userPermission.permission,
              administrateCompetitions: elem?.userPermission?.permission?.administrateCompetitions || result[found]?.userPermission?.permission?.administrateCompetitions,
              changePermission: elem?.userPermission?.permission?.changePermission || result[found]?.userPermission?.permission?.changePermission,
              createAndDeleteCompetitions: elem?.userPermission?.permission?.createAndDeleteCompetitions || result[found]?.userPermission?.permission?.createAndDeleteCompetitions,
              editFormat: elem?.userPermission?.permission?.editFormat || result[found]?.userPermission?.permission?.editFormat,
              editMatches: elem?.userPermission?.permission?.editMatches || result[found]?.userPermission?.permission?.editMatches,
              manageFolderStructure: elem?.userPermission?.permission?.manageFolderStructure || result[found]?.userPermission?.permission?.manageFolderStructure,
              manageParticipants: elem?.userPermission?.permission?.manageParticipants || result[found]?.userPermission?.permission?.manageParticipants,
              setMatchScore: elem?.userPermission?.permission?.setMatchScore || result[found]?.userPermission?.permission?.setMatchScore
            }
          }
        };
        result[found] = newObj
      }else{
        result.push(elem)
      }

    });
    return result;
  };
  const getRecentCompPermissions = (permissions) => {
    //  set rename = true when editing a competiton is done but for now i added it as false below
    let _perm = { rename: false, share: true, delete: true, showOptions: true };
    let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
    let orgAccess = user?.state?.permissions?.user?.OrganizerAccess ?? false;
    let sharedOrganizer = user?.state?.sharedOrganizer > 0 ? true : false;
    if (user?.state?.admin || orgAdmin) {
      return _perm;
    }
    if (!orgAdmin && (orgAccess || sharedOrganizer)) {
      let userPerm = permissions?.find(
        (perm) => perm?.user?._id === user?.state?._id
      )?.userPermission;
      if (userPerm) {
        let { permission } = userPerm;
        const obj = {
          // rename: permission.manageFolderStructure,   /// Commented for now because we cant edit competition but we will handle it late
          rename: false,
          share: permission?.changePermission,
          delete: permission?.manageFolderStructure ?? false,
          showOptions:
            permission?.manageFolderStructure ||
            permission?.changePermission ||
            permission?.manageFolderStructure
        };
        return obj;
      }
    }
    return { rename: false, share: true, delete: true, showOptions: false };
  };

  return (
    <Item
      isRecent={isRecent}
      trashSelected={trashSelected}
      onClick={onClick}
      ind={ind}
      selected={selectedCompitation}
      // isRecentGrid={isRecentGrid}
      compId={compId}
      column="3"
      trashDate={trashDate}
      isShowTrashfolders={isShowTrashfolders}
      competitionData={competitionData}
      compPermissions={compPermissions}
      isCompetition={isCompetition}
      isLastEdit={isLastEdit}
      onRouting={onRouting}
      isOrganizer={isOrganizer}
      isFolder={isFolder}
      organizerData={organizerData ?? competitionData}
      isTrash={isTrash}
      organizerId={organizerData?._id}
      classes="tournament-item"
      image={image ?? default_tournament_image}
      title={title ?? 'TESD - Closed Qualifier'}
      // item_image_in?ner={<TournamentItemImageInner text={countDown ?? "COMING SOON"} color={color ?? "red"} IconName={isRegDateAfterToday? 'clock' : 'white-pencil'} startTime={startTime} />}
      hide_time_ago={true}
      type={type}
      excerpt={
        <div>
          {/* {subtitle ?? title ?? 'Lorem ipsum'} */}
          <span style={{ display: 'flex', alignItems: 'center', marginTop: '-3px', zIndex: '10' }}>
            {!isOrganizer ? (
              <React.Fragment>
                <HiFolder style={{ color: '#3eabf0' }} size={20} />{' '}
                <span
                  style={{
                    font: 'normal normal bold 10px/13px Montserrat',
                    width: '100%',
                    height: '13px',
                    marginLeft: '4px',
                    marginTop: '3px'
                  }}>
                  {organizerName}
                </span>
              </React.Fragment>
            ) : null}
          </span>
          {/* <div className="tournament-item-datetime" style={{color:' #68717e',fontSize:'12px',lineHeight:'15px'}}>{date}</div> */}
          <div
            className="tournament-item-datetime"
            style={{
              color: ' #68717e',
              minHeight: '15px',
              width: '75%',
              marginTop: isOrganizer ? '.5em' : '0px'
            }}>
            {date}
          </div>

          {reg_date ? <div className="tournament-item-datetime">{reg_date}</div> : null}
        </div>
      }
      {...props_rest}
    />
  );
};

export default TournamentItem;
