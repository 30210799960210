import React, { useEffect, useState } from 'react';
import { InformationLogo } from '@common';
import SetScoreModal from '../../_modals/SetScoreModal/SetScoreModal';
import GenerateMatchesModal from '../../_modals/GenerateMatchesModal.jsx/GenerateMatchesModal';
import { useSelector } from 'react-redux';
const Templates = () => {

  const [open,setOpen] = useState(true)
  // const competiton = useSelector(state=> state.)
  return (
    <>
      <div>   
        Templates <InformationLogo />
        {/* <GenerateMatchesModal open={open} competition={competitonData} onClose={setOpen}/> */}
        {/* <button onClick={()=>setOpen(true)}>OPEN</button>
        <SetScoreModal  open={open} onClose={setOpen}/> */}
      </div>
    </>
  );
};
export default Templates;
