import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useState from 'react-usestateref';
import { toast } from 'react-toastify';
import { Row, Col, Card, ListGroup, Form, Button } from 'react-bootstrap';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import AddTeamsModal from './AddTeam';
import MultiStageTeamsListCard from './MultiStageTeamsListCard';
import { checkAddTeamDisabled } from '../../utils';

const ParticipantsTab = ({userPermissions}) => {
  const { id } = useParams();
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [updatedPlayers, setUpdatedPlayers, updatedPlayerRef] = useState({});
  const teamdetails = useSelector((state) => state.COMPETITION?.allTeams);
  const [competitionId,setCompetitionID]=useState();
  const competitionData = useSelector((state) => state.COMPETITION.Competition.multiStageLeague);
  const [league, setLeague] = useState(false);

useEffect(()=>{
  setCompetitionID(competitionData?.stages[0]?.league?._id)
},[competitionData])
  const [openSetTeam, setOpenSetTeam] = useState(false);
  useEffect(() => {
    setAllTeams(teamdetails);
  }, [teamdetails]);

  const handleSelectTeam = (team) => {
    setSelectedTeam(team);
  };
  // useEffect(() => {
  //   console.log(selectedTeam);
  // }, [selectedTeam]);
  return (
    <div className="relative">
      <Row style={{ alignItems: 'self-start' }}>
        <Col className="col-9" style={{ alignItems: 'self-start' }}>
          <Row style={{ alignItems: 'self-start' }}>
            <Col md={6} style={{ alignItems: 'self-start' }}>
              <h5>Teams</h5>
              <Card className="roster-card">
                <Card.Body style={{ border: '1px solid gray', padding: '20px' }}>
                  <ListGroup variant="flush" style={{ display: 'flex', flexDirection: 'column' }}>
                    {allTeams.length > 0 &&
                      allTeams.map((team) => {
                        let active = team?._id === selectedTeam?._id;
                        return (
                          <ListGroup.Item
                            style={
                              active
                                ? {
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    background:
                                      '0% 0% no-repeat padding-box padding-box rgb(18, 20, 26)',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    height: '50px'
                                  }
                                : {
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    background: 'transparent',
                                    color: 'white',
                                    height: '50px'
                                  }
                            }
                            className="roster-team mb-2"
                            id={team?._id}
                            key={team?._id}
                            active={active}
                            action
                            onClick={() => {
                              handleSelectTeam(team);
                            }}>
                            {team?.name}
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} style={{ alignItems: 'self-start' }}>
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h5>Players</h5>
                <button className="button primary" disabled={checkAddTeamDisabled(userPermissions)} onClick={() => setOpenSetTeam(!openSetTeam)}>
                  Add Team
                </button>
              </div>
              <Card className="roster-card">
                <Card.Body style={{ border: '1px solid gray', padding: '20px' }}>
                  <ListGroup variant="flush" style={{ display: 'flex', flexDirection: 'column' }}>
                    {selectedTeam?.members &&
                      selectedTeam.members.map((member) => (
                        <ListGroup.Item
                          className="roster-team mb-2"
                          key={member?._id}
                          action
                          style={{
                            border: '1px solid gray',
                            borderRadius: '4px',
                            background: 'transparent',
                            color: 'white',
                            height: '50px'
                          }}>
                          {member.player
                            ? member?.player?.user?.firstName +
                              ' ' +
                              member?.player?.user?.lastName +
                              ' - ' +
                              member?.player?.user?.nationality?.name +
                              '(' +
                              member?.player?.user?.nationality?.code +
                              ')'
                            : member?.user?.firstName +
                              ' ' +
                              member?.user?.lastName +
                              ' - ' +
                              member?.user?.nationality?.name +
                              '(' +
                              member?.user?.nationality?.code +
                              ')'}
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col>
          <MultiStageTeamsListCard setLeague={setLeague} league={league} />
        </Col>
      </Row>
      <AddTeamsModal show={openSetTeam} onHide={setOpenSetTeam} competitionId={competitionId} updateTeams={setLeague} />
    </div>
  );
};

export default ParticipantsTab;
