import React, { useEffect, useState } from 'react';
import { useClickOutside } from 'react-click-outside-hook';
import './SelectCustom.scss';
import { HiChevronDown } from 'react-icons/hi';

const SelectCustom = ({ value, options, itemId, onClick, noSpot, disabled, singleGroup }) => {
  const [ref, hasClickedOutside] = useClickOutside();
  const [open, setOpen] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(0);

  const handleSelect = (value) => {
    if (onClick) {
      onClick(value, itemId);
      setOpen(false);
    }
  };

  const handleHoverDefault = () => {
    const index = options.findIndex((option) => option === value);
    setHoverIndex(index);
  };

  const handleOpenClose = () => {
    setOpen((state) => !state);
    handleHoverDefault();
  };

  useEffect(() => {
    if (hasClickedOutside) {
      setOpen(false);
    }
  }, [hasClickedOutside]);

  useEffect(() => {
    if (options?.length > 0) {
      handleHoverDefault();
    }
  }, [value, options]);

  let groupName = value.split('-')[0];
  let seedName = value.split('-')[1];

  return (
    <div ref={ref} className={`${disabled ? 'disabled-option-wrapper' : noSpot ? 'no-spot' : ''}`}>
      <div className={`dropdown ${open ? 'open' : 'open'}`} onClick={handleOpenClose}>
        {singleGroup ? (
          seedName
        ) : (
          <>
            {`${groupName} - `}
            <span className="greyed-text-dd">{seedName}</span>
          </>
        )}
        <HiChevronDown
          style={{ height: '18px', width: '25px' }}
          color={'white'}
          className="down-arrow-opt"
        />
      </div>
      {open && (
        <div className="dropdown-content">
          {options.map((option, index) => (
            <div
              className={`option-item ${index === hoverIndex ? 'hovered' : ''}`}
              onMouseEnter={() => setHoverIndex(index)}
              key={index}
              onClick={() => handleSelect(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectCustom;
