import React, { useState, useRef, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import ShareOptions from '../ShareOptions/ShareOptions';
import listenForOutsideClicks from '../ShareOptions/isclickoutide';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { moveToFolder } from '../../../redux/action/dragNDropActions';
import AddNewFolder from '../Folder/AddNewFolder';
import { usePermissions, usePermissionsOrg } from '../../../utils/usePermissions';
import { setContextObj } from '../../../redux/action/contextMenuAction';
import { HiChevronRight } from 'react-icons/hi';

function SeparatorView() {
  return <span className="breadcrumb-item-separator"> <HiChevronRight size={22}/> </span>;
}
function BreadcrumbItemView({ _id, name, onItemClick, showContextMenu, index, allItems }) {
  const dispatch = useDispatch();
  // const permission = usePermissions(_id);
  const [dragEnter, setDragEnter] = useState(false);
  const permission = usePermissions(_id);
  // let permissions = usePermissions(item._id)
  const [{ isOver, isActive, canDrop }, drop] = useDrop({
    accept: 'CARD',
    hover: (item, monitor) => {
      const dragIndex = item.destinationFolder;
      const hoverIndex = _id;
      if (dragIndex === hoverIndex) {
        return;
      }
      item.destinationFolder = hoverIndex;
    },
    drop: (item, monitor) => {
      dispatch(moveToFolder(item));
    },

    canDrop: (item, monitor) => {
      // debugger
      if (item?.destinationFolder === '0001') {
        return false;
      }
      if (item?.parent) {
        if (item?.destinationFolder !== item?.parent) {
          return true;
        } else {
          return false;
        }
      } else if (item?.organizer !== item?.destinationFolder) {
        return true;
      } else {
        return false;
      }
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      isActive: monitor.canDrop() && monitor.isOver()
      // canDrop: monitor.canDrop()
    })
  });

  return (
    <div
      ref={drop}
      className={`breadcrumb-item ${dragEnter ? `grabbing` : ``}`}
      onDragEnter={() => (document.body.style.cursor = 'pointer')}
      onDragLeave={() => (document.body.style.cursor = 'default')}
      onClick={() => {
        onItemClick(_id, name, index);
      }}
      style={isActive ? { color: '#3eabf0' } : {}}
      onContextMenu={(e) => {
        e.preventDefault();
        showContextMenu(e, { _id, name }, permission, 'FOLDER');
        // console.log(_id,"onContextMenu")
        // showContextMenu(e, folder, orgPerm, 'ORGANIZER');
      }}>
      {name}
    </div>
  );
}

export default function BreadcrumbView({ items, onClick }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const [listening, setListening] = useState(false);
  const history = useHistory();
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const MAX_ITEMS_TO_SHOW = 8;
  const TOTAL_ITEMS = items.length;
  const contextMenu = useRef(document.getElementById('context-menu'));
  let IS_POPOVER_NEEDED = TOTAL_ITEMS > MAX_ITEMS_TO_SHOW;
  let itemDOMS = [];
  let itemsToShowInPopover = [];
  const onItemClick = (_id, name, index) => {
    //If onclick function is passed
    let i = items.findIndex((el) => el._id === _id);
    let lastSelected = items[i + 1];
    localStorage.setItem('lastSelected', lastSelected?._id);
    // console.log({items})
    // debugger;
    if (onClick) {
      onClick(_id);
    }
    if (index === 0) {
      history.push(`/`);
    } else if (index === 1) {
      history.push(`/my-competitions/${_id}`);
    } else {
      history.push(`/folders/${_id}`);
    }
  };
  const showContextMenu = (e, item, perm, type, isTrash = false) => {
    e.preventDefault();

    console.log(perm, item._id, `showContextMenu`);
    if (perm.showOptions) {
      document.body.style.overflow = 'hidden';
    }
    let obj = {
      item: item,
      permissions: perm,
      type: type,
      isTrash: isTrash
    };
    dispatch(setContextObj(obj));
    if (perm.showOptions) {
      const { clientX: mouseX, clientY: mouseY } = e;
      contextMenu.current.style.top = `${mouseY}px`;
      contextMenu.current.style.left = `${mouseX}px`;
      contextMenu.current.classList.add('visible');
    }
  };
  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setAnchorEl));

  const handleClose = () => {
    setAnchorEl(null);
  };
  items.forEach(({ _id, name }, index, v) => {
    let oItemDOM = (
      <BreadcrumbItemView
        _id={_id}
        key={_id}
        name={name}
        index={index}
        showContextMenu={showContextMenu}
        onItemClick={onItemClick}
        allItems={v}
      />
    );

    if (IS_POPOVER_NEEDED && index > 0 && index < TOTAL_ITEMS - 2) {
      itemsToShowInPopover.push(
        <MenuItem
          style={{
            background: '#0f1116 0% 0% no-repeat padding-box',
            color: 'white',
            cursor: 'pointer',
            fontWeight: 400,
            padding: '4px 0px 4px 0px',
            textAlign: 'left',
            // font: 'normal normal bold 12px/15px Montserrat',
            letterSpacing: '0px',
            color: '#ffffff',
            textTransform: 'uppercase',
            opacity: '1'

          }}
          key={index}>
          {oItemDOM}
        </MenuItem>
      );
      if (index === 1) {
        itemDOMS.push(
          <span key={'more'} className="breadcrumb-item-dropdown-button" onClick={handleClick}>
            {' '}
            ...{' '}
          </span>
        );
        itemDOMS.push(<SeparatorView key={index} />);
      }
      return;
    } else {
      itemDOMS.push(oItemDOM);
    }

    if (index < TOTAL_ITEMS - 1) {
      itemDOMS.push(<SeparatorView key={index} />);
    }
  });

  return (
    <div className="breadcrumb-container" style={{ marginLeft: '-15px' }} ref={menuRef}>
      {itemDOMS}
      {anchorEl ? (
        <div
          style={{
            position: 'absolute',
            top: '50px',
            left: '130px',
            background: '#0f1116 0% 0% no-repeat padding-box',
            border: '1px solid #22262e',
            borderRadius: '2px',
            opacity: '1',
            zIndex: '100'
            // marginLeft: '55px'
          }}>
          {itemsToShowInPopover}
        </div>
      ) : null}
      {/* <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {itemsToShowInPopover}
      </Menu> */}
    </div>
  );
}
