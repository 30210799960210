import React, { useState } from 'react';
import { HiPlusCircle } from 'react-icons/hi';
import GenerateMatchesModal from '../../../_modals/GenerateMatchesModal.jsx/GenerateMatchesModal';
import { checkEditMatchDisabled } from '../../MyCompetitions/utils';
import GenerateMatchModal from './GenerateMatchModal';

const GeneratMatchButton = ({stageIndex,groupIndex,competitionData,userPermissions}) => {
  const [showOrgModal, setShowOrgModal] = useState(false);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '100px'
      }}>
      <HiPlusCircle style={{ color: '#171c23', fontSize: '100px' }} />
      <h1>no matches created</h1>
      <p>Generate matches to get started with your competition!.</p>
      <div className="create-organizer">
        <button className="button wide-primary" disabled={checkEditMatchDisabled(userPermissions)} onClick={() => setShowOrgModal(true)}>
          generate matches
        </button>
        {showOrgModal ?<GenerateMatchesModal stageIndex={stageIndex} groupIndex={groupIndex} open={showOrgModal} competition={competitionData} onClose={setShowOrgModal} />:null}
      </div>
    </div>
  );
};
export default GeneratMatchButton;
