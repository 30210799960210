import React, { useEffect } from 'react';
import { Folder } from '../../_common';
import TournamentItem from '../../_common/TournamentItem/TournamentItem';
import ShareOptions from '../../_common/ShareOptions/ShareOptions';
import { FaFolder } from 'react-icons/fa';
import { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DeleteItemsForever from '../../_modals/OrganizerPopup/deleteitems/deleteforever';
import RestoreItmes from '../../_modals/OrganizerPopup/deleteitems/restore';
import { usePermissions, usePermissionsOrg } from '../../../utils/usePermissions';
import OrganiserCard from '../../_common/OrganiserCard/OrganiserCard';
import CompetitionCard from '../../_common/CompetitionCard/CompetitionCard';
import default_folder_img from '@img/folder-icon.png';

const Showfolders = ({
  alldataObj,
  getTrans,
  handleSelection,
  handleClearSelection,
  selection
}) => {
  var isShowTrashfolders = true;

  const [deleteId, setDeleteId] = useState();
  const permission = usePermissions(deleteId);
  const orgPermission = usePermissionsOrg(deleteId);
  const [selectedCompitation, setSelectedCompitation] = useState();
  const [deleteforeveropen, setDeleteforeveropen] = useState(false);
  //*NEW WORK
  const categorizes = {
    today: 'Today',
    earlierThisWeek: 'Earlier This Week',
    lastWeek: 'Last Week',
    earlierThisMonth: 'Earlier This Month',
    lastMonth: 'Last Month',
    earlierThisYear: 'Earlier This Year',
    older: 'Older'
  };
  const getLastDeleted = ({ lastEditedDate, lastEditor }) => {
    let _lastEdited = moment(lastEditedDate).format('DD/MM-YYYY');
    let editorName = lastEditor?.firstName + ' ' + lastEditor?.lastName;
    return ` Deleted On: ${_lastEdited} by ${editorName}`;
  };
  useEffect(() => {
    const newObj = { ...alldataObj };
    // debugger;
  }, [alldataObj]);

  //*NEW WORK END
  // const isTrash = true;
  // let showhead = { today: false, week: false, month: false, year: false, older: false };
  // alldata.map((folder) => {
  //   if (folder.whichday === 'today') {
  //     showhead.today = true;
  //   }
  //   if (folder.whichday === 'Earlier this week') {
  //     showhead.week = true;
  //   }
  //   if (folder.whichday === 'Earlier this month') {
  //     showhead.month = true;
  //   }
  //   if (folder.whichday === 'Earlier this year') {
  //     showhead.year = true;
  //   }
  //   if (folder.whichday === 'Older') {
  //     showhead.older = true;
  //   }
  // });
  const timeZone = useSelector((state) => state.TIMEZONE);
  // console.log('<<<', timeZone);
  const getDate = (date) => {
    if (!timeZone) {
      return date;
    } else {
      return moment(date).tz(timeZone?.timeZone);
    }
  };

  const searchSelection = (itemId) => {
    let flatArr = selection.flat(Infinity);
    for (let i = 0; i < flatArr.length; i++) {
      if (flatArr[i] === itemId) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {Object.entries(alldataObj).map(([key, value]) =>
        value?.length ? (
          <>
            <h2>{categorizes[key]}</h2>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {value?.map((item, i) => {
                let _disabled = searchSelection(item?._id);
                return item?.isOrganizer ? (
                  // <div
                  //   key={i}
                  //   id={item._id}
                  //   style={{display: 'flex'}}
                  //   onDoubleClick={(e) => {
                  //     setDeleteforeveropen(true);
                  //     setDeleteId(`organizer ${item._id}`);
                  //   }}>
                  <OrganiserCard
                    _disabled={!_disabled}
                    organiser={item}
                    permissions={item?.organizerRoles ?? []}
                    thumbnail={item?.thumbnail}
                    logo={item?.logo}
                    last_edited={getLastDeleted(item)}
                    ind={i}
                    title={item?.name}
                    onClickHandler={(e) => handleSelection(e, item._id)}
                    onDoubleClickHandler={(e) => {
                      setDeleteforeveropen(true);
                      setDeleteId(`organizer ${item._id}`);
                    }}
                    type="TRASH_ORG"
                    showTrash={true}
                  />
                ) : // </div>
                item.isFolder ? (
                  // <div
                  //   key={i}
                  //   id={item._id}
                  //   onDoubleClick={(e) => {
                  //     setDeleteforeveropen(true);
                  //     setDeleteId(`folder ${item._id}`);
                  //   }}>
                  <CompetitionCard
                    type={'TRASH_FOLDER'}
                    _disabled={!_disabled}
                    competition={item}
                    last_edited={getLastDeleted(item)}
                    handleClearSelection={handleClearSelection}
                    onClickHandler={(e) => handleSelection(e, item._id)}
                    onDoubleClickHandler={(e) => {
                      setDeleteforeveropen(true);
                      setDeleteId(`folder ${item._id}`);
                    }}
                    permissions={[...item.organizerRoles]}
                    thumbnail={item?.thumbnail}
                    logo={default_folder_img}
                    title={item?.name}
                    parent_organiser={item?.organizer?.name ?? 'STEELSERIES'}
                    showTrash={true}
                  />
                ) : (
                  // </div>
                  <CompetitionCard
                    type={'TRASH_COMP'}
                    _disabled={!_disabled}
                    competition={item}
                    last_edited={getLastDeleted(item)}
                    onClickHandler={(e) => handleSelection(e, item._id)}
                    handleClearSelection={handleClearSelection}
                    //  onClickHandler={onClickHandler}
                    onDoubleClickHandler={(e) => {
                      setDeleteforeveropen(true);
                      setDeleteId(`competition ${item._id}`);
                    }}
                    permissions={[...item.organizerRoles]}
                    thumbnail={item?.thumbnail}
                    logo={item?.logo}
                    title={item?.name}
                    parent_organiser={item?.organizer?.name ?? 'STEELSERIES'}
                    showTrash={true}
                  />
                );
              })}
            </div>
          </>
        ) : null
      )}
      {/* <h2>{showhead.week === true ? 'Earlier this week' : null}</h2>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {alldata.map((folder, i) =>
          folder?.isfolder === true && folder.timedifference < 169 && folder.timedifference > 24 ? (
            <div
              key={i}
              id={folder._id}
              onDoubleClick={(e) => {
                setDeleteforeveropen(true);
                setDeleteId(`folder ${folder._id}`);
              }}>
              <TournamentItem
                ind={i}
                trashSelected={searchSelection(folder._id)}
                selectedCompitation={selectedCompitation}
                onClick={(e) => {
                  handleSelection(e, folder._id, folder.whichday);
                }}
                key={folder._id}
                trashDate={folder.lastEditedDate}
                isShowTrashfolders={isShowTrashfolders}
                image={
                  'https://drive-thirdparty.googleusercontent.com/128/type/application/vnd.google-apps.folder'
                }
                organizerData={folder}
                isTrash
                isFolder
                title={
                  <div
                    style={{
                      color: selectedCompitation === i ? '#3eabf0' : 'white',
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <span className="imgLogoContainer">
                      <FaFolder />
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                  </div>
                }
                subtitle={<></>}
                date={
                  <div style={{ color: selectedCompitation === i ? '#296f9e' : 'white' }}>
                    {'deleted: ' +
                      folder.lastEditedDate +
                      ' By ' +
                      folder?.lastEditor?.firstName +
                      ' ' +
                      folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : folder?.isorganizer === true &&
            folder.timedifference < 169 &&
            folder.timedifference > 24 ? (
            <div
              key={i}
              id={folder._id}
              onDoubleClick={(e) => {
                setDeleteforeveropen(true);
                setDeleteId(`organizer ${folder._id}`);
              }}>
              <TournamentItem
                ind={i}
                trashSelected={searchSelection(folder._id)}
                selectedCompitation={selectedCompitation}
                onClick={(e) => {
                  handleSelection(e, folder._id, folder.whichday);
                }}
                key={folder._id}
                isShowTrashfolders={isShowTrashfolders}
                trashDate={folder.lastEditedDate}
                image={folder.logo}
                organizerData={folder}
                isTrash
                isOrganizer
                title={
                  <div
                    style={{
                      color: selectedCompitation === i ? '#3eabf0' : 'white',
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <span className="imgLogoContainer">
                      {!folder?.isorganizer ? (
                        <img
                          className=" imgLogoWidth imgLogo  imgLogoCropped"
                          src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                          alt="Insight Gaming"
                          width={'10px'}
                        />
                      ) : (
                        <FaFolder size={15} color={'rgb(62, 171, 240)'} />
                      )}
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingLeft: folder?.isorganizer ? 10 : 0
                      }}>
                      {folder.name}
                    </span>
                  </div>
                }
                subtitle={<></>}
                date={
                  <div style={{ color: selectedCompitation === i ? '#296f9e' : 'white' }}>
                    {'deleted: ' +
                      folder.lastEditedDate +
                      ' By ' +
                      folder?.lastEditor?.firstName +
                      ' ' +
                      folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : null
        )}
      </div>
      <h2>{showhead.month === true ? 'Early this month' : null}</h2>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {alldata.map((folder, i) => {
          let _disabled = searchSelection(folder?._id);
          return folder?.isfolder === true &&
            folder.timedifference < 720 &&
            folder.timedifference > 169 ? (
            <div
              key={i}
              id={folder._id}
              onDoubleClick={(e) => {
                setDeleteforeveropen(true);
                setDeleteId(`folder ${folder._id}`);
              }}>
              <TournamentItem
                ind={i}
                trashSelected={searchSelection(folder._id)}
                selectedCompitation={selectedCompitation}
                onClick={(e) => {
                  handleSelection(e, folder._id, folder.whichday);
                }}
                key={folder._id}
                isShowTrashfolders={isShowTrashfolders}
                trashDate={folder.lastEditedDate}
                image={
                  'https://drive-thirdparty.googleusercontent.com/128/type/application/vnd.google-apps.folder'
                }
                organizerData={folder}
                isTrash
                isFolder
                title={
                  <div
                    style={{
                      color: selectedCompitation === i ? '#3eabf0' : 'white',
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <span className="imgLogoContainer">
                      <FaFolder />
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                  </div>
                }
                subtitle={<></>}
                date={
                  <div style={{ color: selectedCompitation === i ? '#296f9e' : 'white' }}>
                    {'deleted: ' +
                      folder.lastEditedDate +
                      ' By ' +
                      folder?.lastEditor?.firstName +
                      ' ' +
                      folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : folder?.isorganizer === true &&
            folder.timedifference < 720 &&
            folder.timedifference > 169 ? (
            <div
              key={i}
              id={folder._id}
              onDoubleClick={(e) => {
                setDeleteforeveropen(true);
                setDeleteId(`organizer ${folder._id}`);
              }}>
              <OrganiserCard
                _disabled={!_disabled}
                organiser={folder}
                permissions={folder?.organizerRoles ?? []}
                thumbnail={folder?.thumbnail}
                logo={folder?.logo}
                last_edited={getLastDeleted(folder)} // Will be Used a Deleted here in trash
                ind={i}
                title={folder?.name}
                onClickHandler={(e) => {
                  handleSelection(e, folder._id, folder.whichday);
                }}
                key={folder._id}
                type="ORG"
              />
            </div>
          ) : null;
        })}
      </div>
      <h2>{showhead.older === true ? 'Older' : null}</h2>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {alldata.map((folder, i) =>
          folder?.isfolder === true && folder.timedifference > 8760 ? (
            <div
              key={i}
              id={folder._id}
              onDoubleClick={(e) => {
                setDeleteforeveropen(true);
                setDeleteId(`folder ${folder._id}`);
              }}>
              <TournamentItem
                ind={i}
                trashSelected={searchSelection(folder._id)}
                selectedCompitation={selectedCompitation}
                onClick={(e) => {
                  handleSelection(e, folder._id, folder.whichday);
                }}
                key={folder._id}
                isShowTrashfolders={isShowTrashfolders}
                trashDate={folder.lastEditedDate}
                image={
                  'https://drive-thirdparty.googleusercontent.com/128/type/application/vnd.google-apps.folder'
                }
                organizerData={folder}
                isTrash
                isFolder
                title={
                  <div
                    style={{
                      color: selectedCompitation === i ? '#3eabf0' : 'white',
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <span className="imgLogoContainer">
                      <FaFolder />
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {folder.name}
                    </span>
                  </div>
                }
                subtitle={<></>}
                date={
                  <div style={{ color: selectedCompitation === i ? '#296f9e' : 'white' }}>
                    {'deleted: ' +
                      folder.lastEditedDate +
                      ' By ' +
                      folder?.lastEditor?.firstName +
                      ' ' +
                      folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : folder?.isorganizer === true && folder.timedifference > 8760 ? (
            <div
              key={i}
              id={folder._id}
              onDoubleClick={(e) => {
                setDeleteforeveropen(true);
                setDeleteId(`organizer ${folder._id}`);
              }}>
              <TournamentItem
                ind={i}
                trashSelected={searchSelection(folder._id)}
                selectedCompitation={selectedCompitation}
                onClick={(e) => {
                  handleSelection(e, folder._id, folder.whichday);
                }}
                key={folder._id}
                isShowTrashfolders={isShowTrashfolders}
                trashDate={folder.lastEditedDate}
                image={folder.logo}
                organizerData={folder}
                isTrash
                isOrganizer
                title={
                  <div
                    style={{
                      color: selectedCompitation === i ? '#3eabf0' : 'white',
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                    <span className="imgLogoContainer">
                      {!folder?.isorganizer ? (
                        <img
                          className=" imgLogoWidth imgLogo  imgLogoCropped"
                          src="https://leagues-static.s3.eu-west-2.amazonaws.com/img/Default%20Team%20Logo-c7819365-ef0f-4f99-bf25-5ec9455664c1.png"
                          alt="Insight Gaming"
                          width={'10px'}
                        />
                      ) : (
                        <FaFolder size={15} color={'rgb(62, 171, 240)'} />
                      )}
                    </span>
                    <span
                      style={{
                        maxWidth: '175px',
                        textOverflow: 'ellipsis',
                        marginLeft: '300px',
                        height: '18px',
                        width: '235px',
                        padding: '0px',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 5px 0 5px',
                        textAlign: 'left',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingLeft: folder?.isorganizer ? 10 : 0
                      }}>
                      {folder.name}
                    </span>
                  </div>
                }
                subtitle={<></>}
                date={
                  <div style={{ color: selectedCompitation === i ? '#296f9e' : 'white' }}>
                    {'deleted: ' +
                      folder.lastEditedDate +
                      ' By ' +
                      folder?.lastEditor?.firstName +
                      ' ' +
                      folder?.lastEditor?.lastName}
                  </div>
                }
              />
            </div>
          ) : null
        )}
      </div> */}
      {deleteId ? (
        <RestoreItmes
          open={deleteforeveropen}
          canRestor={!orgPermission.delete}
          setDeleteforeveropen={() => setDeleteforeveropen(!deleteforeveropen)}
          deleteOrganizaionId={deleteId}
          isforever={true}
          isdoubbleclick={true}
        />
      ) : null}
    </>
  );
};
export default Showfolders;
