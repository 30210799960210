import React from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import './a.css';
import CreateMatcehsFunctionality from './CreateMatchModalFunctionality';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const CreateMatchModal = ({ open, onClose, stageIndex,groupIndex }) => {
  const [cover, setCover] = useState();
  const competitionData = useSelector(
    (state) => state.COMPETITION.informationData.multiStageLeague
  );
  const Styles = {
    header: {
      background: `linear-gradient(rgba(23, 26, 34, 0.5) 0%, rgb(23, 26, 34) 100%) center center no-repeat padding-box padding-box, url(${cover}) transparent`,
      opacity: 1,
      height: '100px',
      textAlign: 'left',
      width: '100%',
      position: 'absolute'
    }
  };
  useEffect(() => {
    setCover(competitionData?.game?.overViewPageBanner);
  }, [competitionData]);
  return (
    <Modal
      isOpen={open}
      
      className="modal"
      contentLabel="Example Modal"
      style={{ backgroundColor: '#171a22' }}>
      <div className="modal-close" style={{ zIndex: '1' }} onClick={() => onClose(false)}>
        <RiCloseLine />
      </div>
      <div className="modal-header" style={Styles.header}>
        <h2 className="margenedHeading">Create Match</h2>
      </div>
      <div className="modal-body" style={{ marginTop: '100px' }}>
        <CreateMatcehsFunctionality stageIndex={stageIndex} onClose={onClose} groupIndex={groupIndex}/>
      </div>
    </Modal>
  );
};

export default CreateMatchModal;
