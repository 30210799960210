import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, ToggleButton } from 'react-bootstrap';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import { TIMEZONES } from '../../../../../utils/timezones';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Datetime from 'react-datetime';
import _ from 'lodash';
import 'react-datetime/css/react-datetime.css';
import { CheckboxSlider, MultiSelectDropDown, MultipleSelect } from '../../../../_common';
import { toast } from 'react-toastify';
import { getCompetitionsData } from '../../../../../redux/action/CompetitonData';
import { useParams } from 'react-router-dom';
import { HiInformationCircle, HiOutlineGlobeAlt, HiX } from 'react-icons/hi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { allCountries } from '../../../../../utils/countriesWithCode';
import Tippy from '@tippyjs/react';
import './_style.scss';
const GeneralTab = ({
  setAllInformationData,
  allInformationData,
  setInformationCall,
  setUnSavedStages,
  selectedTab,
  userPermissions,
  setName
}) => {
  const [games, setGames] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  // const informationData = useSelector(
  //   (state) => state.COMPETITION.informationData?.multiStageLeague ?? {}
  // );
  const informationData = useSelector((state) => state.COMPETITION.Competition?.multiStageLeague);

  const inforamtionPreviewData = useSelector((state) => state.PREVIEW.Information ?? null);

  const { register, handleSubmit, errors } = useForm();
  const matches = useSelector(
    (state) => state.COMPETITION?.Matches?.data?.data[0][0].AllMatches.length > 0
  );
  const [multiSelectCountriesOptions, setMultiSelectCountriesOptions] = useState([
    {
      _id: '0',
      label: 'Everyone',
      flag: <HiOutlineGlobeAlt />,
      value: 'Everyone'
    },
    ...Object.entries(allCountries).map(([code, label], key) => ({
      _id: key,
      label,
      flag: code,
      value: code
    }))
  ]);
  const zerozone = TIMEZONES()[0].value;

  const [allGeneralData, setAllGeneralData] = useState({
    name: null,
    game: '',
    region: '',
    openFor: [],
    contact: '',
    timezone: informationData?.timezone,
    startedDate: '',

    division: '',
    competitionType: '',
    divisionName: '',
    participantType: '',
    leagueFinished: ''
  });
  const lolRegion = [
    'NA - North America',
    'EUW - Europe West',
    'EUN - Europe Nordic & East',
    'KR - Korea',
    'BR - Brazil',
    'JP - Japan',
    'RU - Russia',
    'OCE - Oceania',
    'TR - Turkey',
    'LAN - Latin America North',
    'LAS - Latin America South'
  ];
  const valorentregions = ['Europe', 'Americas', 'Asia'];
  useEffect(() => {
    const {
      name,
      game,
      region,
      openFor,
      contact,
      timezone,
      startedDate,
      division,
      competitionType,
      divisionName,
      participantType,
      leagueFinished
    } = allGeneralData;
    if (allGeneralData?.name !== null) {
      setAllInformationData((state) => ({
        ...state,
        name,
        game,
        region,
        openFor,
        contact,
        timezone,
        startedDate,
        division,
        competitionType,
        divisionName,
        participantType,
        leagueFinished
      }));
    }
    // console.log('allGeneralData=>',allGeneralData);
  }, [allGeneralData]);

  useEffect(() => {
    let generalTabKeys = [
      'name',
      'game',
      'region',
      'openFor',
      'contact',
      'timezone',
      'startedDate',
      'division',
      'competitionType',
      'divisionName',
      'participantType',
      'leagueFinished'
    ];
    let _informationData = { ...informationData };
    if (inforamtionPreviewData) {
      let anyChange = false;
      generalTabKeys.forEach((elem) => {
        if (elem === 'openFor') return;
        let val1 = _informationData[elem];
        let val2 = inforamtionPreviewData[elem];
        if (elem === 'game') {
          val1 = _informationData[elem]._id;
        }
        if (!_.isEqual(val1, val2)) {
          _informationData[elem] = inforamtionPreviewData[elem];
          anyChange = true;
        }
      });
      if (anyChange) {
        setUnSavedStages((state) =>
          state.includes(selectedTab) ? [...state] : [...state, selectedTab]
        );
      } else {
        setUnSavedStages((state) =>
          state.includes(selectedTab) ? state.filter((el) => el !== selectedTab) : [...state]
        );
      }
      // Object.keys(_informationData).forEach((el) => {
      //   if (Object.keys(inforamtionPreviewData).includes(el)){
      //     _informationData[el] = inforamtionPreviewData[el];
      //   }
      // });
    }
    // console.log({_informationData})
    setAllGeneralData({
      name: allInformationData?.name ?? _informationData?.name,
      game:
        allInformationData?.game && allInformationData?.game._id
          ? allInformationData?.game?._id
          : allInformationData?.game
          ? allInformationData?.game
          : '5f342371f52fd80836260de8',
      region: allInformationData?.region
        ? allInformationData?.region
        : allInformationData?.season?.game?._id === '5f342371f52fd80836260de8'
        ? 'EUW - Europe West'
        : allInformationData?.season?.game?._id === '61f8032c7ce32b75aaecdf87'
        ? 'Asia'
        : '',
      contact: allInformationData?.contact ?? _informationData?.contact,
      timezone: allInformationData?.timezone?.length > 2 ? allInformationData?.timezone : zerozone,
      startedDate: allInformationData?.startedDate
        ? allInformationData?.startedDate
        : `${moment().format('YYYY-MM-DDTHH:mm:ss')}`,
      division: allInformationData?.division ?? _informationData?.division,
      competitionType: allInformationData?.competitionType ?? _informationData?.competitionType,
      divisionName: allInformationData?.divisionName ?? _informationData?.competitionType,
      participantType: allInformationData?.participantType ??_informationData?.competitionType,
      leagueFinished: allInformationData?.leagueFinished ?? _informationData?.competitionType
    });
  }, [informationData]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(() => {
        return data.filter((game) => game.name !== 'No Game');
      });
    });
  }, []);
  useEffect(() => {
    let _informationData = { ...informationData };
    if (inforamtionPreviewData) {
      _informationData.openFor = inforamtionPreviewData.openFor ?? informationData.openFor;
    }
    if (_informationData?.openFor?.length > 0) {
      let data = multiSelectCountriesOptions.map((e) => {
        let label = _informationData?.openFor.find((j) => e.value == j.value);
        if (label) {
          return { ...e, selected: true };
        } else {
          return { ...e, selected: false };
        }
      });

      setMultiSelectCountriesOptions(data);
    } else {
      let data = multiSelectCountriesOptions.map((e) => {
        if (e._id == '0') {
          return { ...e, selected: true };
        } else {
          return { ...e, selected: false };
        }
      });
      setMultiSelectCountriesOptions(data);
    }
  }, [informationData]);
  useEffect(() => {
    let seletcedCountries = multiSelectCountriesOptions?.filter((e) => e.selected === true);
    setAllGeneralData((state) => ({ ...state, openFor: seletcedCountries }));
  }, [multiSelectCountriesOptions]);
  const hendleChange = () => {
    setUnSavedStages((state) => state.filter((e) => e != selectedTab));
    const x = allGeneralData?.openFor?.filter((option) => option.selected === true);

    const a = x.map((option) => {
      delete option['_id'];
      return option;
    });
    let dataToSend = { ...allInformationData, openFor: a };
    setInformationCall(dataToSend);
  };

  const handleGroupSelect = (selected, updatedOptions) => {
    setMultiSelectCountriesOptions(updatedOptions);
    setUnSavedStages((state) => [...state, selectedTab]);
    setAllGeneralData((state) => ({ ...state, openFor: [...updatedOptions] }));
  };
  const validateform = (e) => {
    if (e.target.value.length < 1) {
      e.target.style.border = '1px solid red';
      toast.error(`name shoud not be empty`);
    } else {
      e.target.style.border = '1px solid #3eabf0';
    }
  };
  const onDeleteCompetition = () => {
    Axios.put(process.env.REACT_APP_CORE_API + '/api/organizer/trash/delete', {
      Organizers: [],
      Folders: [],
      Competitions: [id]
    });
    let x = window.location.href.split('/')[2];
    window.location.replace('http://' + x);
  };
  const content =
    'The competition game cannot be changed after matches have been created or participants added.';

  return (
    <div>
      <h2>INFORMATION</h2>
      <Form>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                competition name
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                type="text"
                id="name"
                name="name"
                maxlength="50"
                value={allGeneralData?.name}
                onBlur={(e) => validateform(e)}
                onChange={(e) => {
                  const value = e.target.value;
                  setAllGeneralData((state) => ({ ...state, name: value }));
                  setName(value);
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}
                style={{
                  height: '44px',
                  border: '1px solid #22262e'
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            <div className="form-row">
              <label
                htmlFor="game"
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                Game
              </label>
              <Tippy content={matches ? content : null} placement={'top'}>
                <div>
                  <select
                    id="game"
                    name="game"
                    className={`form-input ${errors.game ? 'invalid' : ''}`}
                    defaultValue={allGeneralData.game}
                    disabled={matches}
                    onChange={(e) => {
                      const gameid = e.target.value;
                      setUnSavedStages((state) => [...state, selectedTab]);
                      setAllGeneralData((state) => ({ ...state, game: gameid }));
                    }}
                    style={matches ? { opacity: '0.5' } : {}}>
                    <option value="_DEFAULT_" disabled>
                      Choose game
                    </option>

                    {games?.map(({ _id, name }) => (
                      <option key={_id} value={_id} selected={_id === allGeneralData.game}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </Tippy>

              {errors.game && <div className="form-input-error">{errors.game.message}</div>}
            </div>
          </Col>
          <Col md={3}>
            <div className="form-row">
              <div style={{ display: 'flex' }}>
                <label
                  htmlFor="region"
                  style={{
                    textTransform: 'uppercase',
                    color: '#68717e',
                    font: 'normal normal bold 12px/15px Montserrat'
                  }}>
                  Region
                </label>
                <Tippy
                  content="The region determines what servers players and teams can join from."
                  placement={'top'}>
                  <div
                    style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                    <HiInformationCircle
                      style={{ color: '#68717e', fontSize: 'large', margin: '-2px 0px 0px 0px' }}
                    />
                  </div>
                </Tippy>
              </div>
              <select
                ref={register({
                  validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
                })}
                id="region"
                name="region"
                className={`form-input ${errors.game ? 'invalid' : ''}`}
                defaultValue={allGeneralData.region}
                onChange={(e) => {
                  const selectedRegion = e.target.value;
                  setAllGeneralData((state) => ({ ...state, region: selectedRegion }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}>
                {allGeneralData.game == '5f342371f52fd80836260de8' ? (
                  lolRegion?.map((name, index) => (
                    <option
                      key={index + 'l'}
                      value={name}
                      selected={name === allGeneralData.region}>
                      {name}
                    </option>
                  ))
                ) : allGeneralData.game === '61f8032c7ce32b75aaecdf87' ? (
                  valorentregions?.map((name, index) => (
                    <option
                      key={index + 'v'}
                      value={name}
                      selected={name === allGeneralData.region}>
                      {name}
                    </option>
                  ))
                ) : (
                  <option value="_DEFAULT_" disabled>
                    nothing to choose
                  </option>
                )}
              </select>
              {errors.game && <div className="form-input-error">{errors.game.message}</div>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Label
              style={{
                textTransform: 'uppercase',
                color: '#68717e',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              Open for
              <Tippy
                content="The countries will be displayed on the competition page, but participants won’t be barred from entering based on what is selected."
                placement={'top'}>
                <div style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                  <HiInformationCircle
                    style={{ color: '#68717e', fontSize: 'large', marginBottom: '-3px' }}
                  />
                </div>
              </Tippy>
            </Form.Label>

            <div className="d-flex align-items-center">
              <MultipleSelect
                options={multiSelectCountriesOptions}
                handleChange={handleGroupSelect}
                // placeholder="Choose Countries..."
                placeholder="Select one or several countries"
                showPlaceholder={true}
              />
            </div>
            <Form.Control.Feedback type="invalid">
              {errors.country && errors.country.message}
            </Form.Control.Feedback>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                CONTACT EMAIL
                <Tippy
                  content="This mail will be displayed on the competition page if filled out."
                  placement={'top'}>
                  <div
                    style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                    <HiInformationCircle
                      style={{ color: '#68717e', fontSize: 'large', marginBottom: '-3px' }}
                    />
                  </div>
                </Tippy>
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                type="text"
                id="Info"
                className="Info plachodercolor"
                name="email "
                placeholder="Example@email.com"
                value={allGeneralData.contact}
                maxLength={30} // defaultValue={name}
                // onBlur={handleSubmit(onSubmit)}
                onChange={(e) => {
                  const editedContent = e.target.value;
                  setAllGeneralData((state) => ({ ...state, contact: editedContent }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}
                style={{ height: '44px', border: '1px solid #22262e' }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label
              style={{
                textTransform: 'uppercase',
                color: '#68717e',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              Time zone
              <Tippy
                content="The time zone selected here will be the default time zone when creating and editing matches, and it will be the time zone used if the competition is scheduled."
                placement={'top'}>
                <div style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                  <HiInformationCircle
                    style={{ color: '#68717e', fontSize: 'large', marginBottom: '-3px' }}
                  />
                </div>
              </Tippy>
            </Form.Label>
            <div className="form-row">
              <select
                ref={register({
                  validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
                })}
                id="region"
                style={{ marginTop: '5px' }}
                name="region"
                className={`form-input ${errors.timezone ? 'invalid' : ''}`}
                defaultValue={allGeneralData.timezone}
                onChange={(e) => {
                  const selectedtimezone = e.target.value;
                  setAllGeneralData((state) => ({ ...state, timezone: selectedtimezone }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}>
                {TIMEZONES().map(({ name, value }) => (
                  <option value={value} selected={value === allGeneralData.timezone}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.timezone && <div className="form-input-error">{errors.timezone.message}</div>}
            </div>
          </Col>
        </Row>
        <Row style={{ width: '26%' }}>
          <Col style={{ display: 'flex', marginTop: '10px', flexWrap: 'wrap' }}>
            {multiSelectCountriesOptions?.map((country) => {
              return (
                <div style={{ marginTop: '5px' }}>
                  {country.selected === true ? (
                    <div
                      style={{
                        backgroundColor: '#22262e',
                        padding: '7px',
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'uppercase',
                        fontWeight: 'bold'
                      }}>
                      {country.label === 'Everyone' ? (
                        country.flag
                      ) : (
                        <img
                          src={`https://www.flagcdn.com/128x96/${country.flag.toLowerCase()}.png`}
                          width="20px"
                          style={{ marginRight: '5px', borderRadius: '50%' }}
                        />
                      )}
                      {country.label}
                      <div
                        style={{
                          marginLeft: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#68717E'
                        }}
                        onClick={() => {
                          const deletedcountry = multiSelectCountriesOptions.map((countries) => {
                            if (country.label === countries.label)
                              return { ...countries, selected: false };
                            else {
                              return { ...countries };
                            }
                          });
                          setMultiSelectCountriesOptions(deletedcountry);
                        }}>
                        <HiX />
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </Col>
        </Row>
        <h2>TIME AND DIVISION (CATEGORIZATION)</h2>
        <Row>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                Start Date
                <Tippy
                  content="The start date will be displayed on the competition page."
                  placement={'top'}>
                  <div
                    style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                    <HiInformationCircle
                      style={{ color: '#68717e', fontSize: 'large', marginBottom: '-3px' }}
                    />
                  </div>
                </Tippy>
              </Form.Label>
              <div
                className="test"
                style={{
                  position: 'absolute',
                  zIndex: '-1',
                  height: '-webkit-fill-available',
                  width: '-webkit-fill-available',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                  padding: '0px 10px 11px 0px'
                }}>
                <AiOutlineCalendar color="#68717e" />
              </div>
              <Datetime
                value={new Date(allGeneralData.startedDate)}
                onChange={(e) => {
                  let stardate = moment(e).format('YYYY-MM-DDTHH:mm');
                  setAllGeneralData((state) => ({ ...state, startedDate: stardate }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}
                dateFormat="DD-MM-YYYY"
                timeFormat=""
                className="edit-input-border"
              />
            </Form.Group>
          </Col>
          <Col md={3} style={{ paddingBottom: '15px' }}>
            <div className="form-row" style={{ marginBottom: '1px' }}>
              <div style={{ display: 'flex' }}>
                <label
                  htmlFor="division"
                  style={{
                    textTransform: 'uppercase',
                    color: '#68717e',
                    font: 'normal normal bold 12px/15px Montserrat',
                    marginTop: '4px',
                    marginBottom: '5px'
                  }}>
                  Division
                </label>
                <Tippy
                  content="The division is used to internally categorize the level of play for an organizer, and will be used for match filtering in the overview page on Leagues.gg."
                  placement={'top'}>
                  <div
                    style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                    <HiInformationCircle
                      style={{ color: '#68717e', fontSize: 'large', margin: '-2px 0px 0px 0px' }}
                    />
                  </div>
                </Tippy>
              </div>

              <select
                ref={register({
                  validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
                })}
                id="division"
                name="division"
                className={`form-input ${errors.tournamentDivisionName ? 'invalid' : ''}`}
                value={allGeneralData?.division}
                onChange={(e) => {
                  const selectedDisvision = e.target.value;
                  setAllGeneralData((state) => ({ ...state, division: selectedDisvision }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}>
                {[...Array(10).keys()].map((division, key) => (
                  <option key={key} value={division + 1}>
                    {`Division ${division + 1}`}
                  </option>
                ))}
              </select>
              {errors.tournamentDivisionName && (
                <div className="form-input-error">{errors.tournamentDivisionName.message}</div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="form-row">
              <div style={{ display: 'flex' }}>
                <label
                  htmlFor="competitionType"
                  style={{
                    textTransform: 'uppercase',
                    color: '#68717e',
                    font: 'normal normal bold 12px/15px Montserrat'
                  }}>
                  COMPETITION TYPE
                </label>
                <Tippy
                  content="Competition type determines which page the competition is listed on. Tournaments are usually short competitions less than a month long, where the participants are either signed up, qualified, or were invited. Leagues are usually between one to three months in length and the participants stay from one season to another, perhaps being relegated or promoted based on performance."
                  placement={'top'}>
                  <div
                    style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                    <HiInformationCircle
                      style={{ color: '#68717e', fontSize: 'large', margin: '-2px 0px 0px 0px' }}
                    />
                  </div>
                </Tippy>
              </div>
              <select
                ref={register({
                  validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
                })}
                id="competitionType"
                name="competitionType"
                className={`form-input ${errors.competitionType ? 'invalid' : ''}`}
                value={allGeneralData?.competitionType}
                onChange={(e) => {
                  const competitiontyp = e.target.value;
                  setAllGeneralData((state) => ({ ...state, competitionType: competitiontyp }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}>
                {['League', 'Tournament'].map((competitiont, key) => (
                  <option value={competitiont} key={key}>
                    {competitiont}
                  </option>
                ))}
              </select>
              {errors.competitionType && (
                <div className="form-input-error">{errors.competitionType.message}</div>
              )}
            </div>
          </Col>
          <Col md={3} style={{ marginTop: '12px' }}>
            <Form.Group>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                {/* Division Name */}
                Division custom name (optional)
                <Tippy
                  content="In the upper right corner on the thumbnail of a competition the division will be written. If the custom name is filled in, it will be displayed instead of the division."
                  placement={'top'}>
                  <div
                    style={{ display: 'inline', width: 'fit-content', margin: '0px 0px 0px 8px' }}>
                    <HiInformationCircle
                      style={{ color: '#68717e', fontSize: 'large', marginBottom: '-3px' }}
                    />
                  </div>
                </Tippy>
              </Form.Label>
              <Form.Control
                name="tournamentDivisionName"
                id="tournamentDivisionName"
                className="plachodercolor"
                ref={register()}
                value={allGeneralData?.divisionName}
                maxLength={20}
                placeholder={'Custom name'}
                onChange={(e) => {
                  const divname = e.target.value;
                  setAllGeneralData((state) => ({ ...state, divisionName: divname }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}
                style={{ height: '44px', border: '1px solid #22262e' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tournamentDivisionName && errors.tournamentDivisionName.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <h2>participants</h2>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                type of participants
              </Form.Label>
              <CheckboxSlider
                label={'Teams'}
                value={allGeneralData.participantType === 1 ? true : false}
                onChange={(e) => {
                  setUnSavedStages((state) => [...state, selectedTab]);
                  setAllGeneralData((state) => ({ ...state, participantType: 1 }));
                }}
              />
              <CheckboxSlider
                label={'Players'}
                value={allGeneralData.participantType === 2 ? true : false}
                onChange={(e) => {
                  setAllGeneralData((state) => ({ ...state, participantType: 2 }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <div className="form-row">
              <label
                htmlFor="FINISHED"
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                LEAGUE FINISHED?
              </label>
              <select
                ref={register({
                  validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
                })}
                id="FINISHED"
                name="FINISHED"
                className={`form-input ${errors.FINISH ? 'invalid' : ''}`}
                value={allGeneralData?.leagueFinished === true ? 'Yes' : 'No'}
                onChange={(e) => {
                  const isfinishedleague = e.target.value;
                  setAllGeneralData((state) => ({
                    ...state,
                    leagueFinished: isfinishedleague === 'No' ? false : true
                  }));
                  setUnSavedStages((state) => [...state, selectedTab]);
                }}>
                {['No', 'Yes'].map((FINISHED) => (
                  <option value={FINISHED}>{FINISHED}</option>
                ))}
              </select>
              {errors.FINISH && <div className="form-input-error">{errors.FINISH.message}</div>}
            </div>
          </Col>
        </Row>
      </Form>
      <button className="button primary" onClick={hendleChange}>
        Save Changes
      </button>
      {userPermissions?.createAndDeleteCompetitions && (
        <button className="button primary redButton" onClick={() => onDeleteCompetition()}>
          Delete Competition
        </button>
      )}
    </div>
  );
};

export default GeneralTab;
