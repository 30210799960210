import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const List = ({ input, selectLanguage }) => {
  const language = useSelector((state) => state.LANGUAGE.Selected);
  let allLanguages = useSelector((state) => state.LANGUAGE.AllLanguages);
  allLanguages = allLanguages.sort((a, b) =>
    a.language > b.language ? 1 : b.language > a.language ? -1 : 0
  );

  const SelectedValue = (language) => {
    selectLanguage(language);
  };

  const filteredData = allLanguages.filter((el) => {
    if (input === '') {
      return el;
    } else {
      return el.language.toLowerCase().includes(input);
    }
  });
  return (
    <div style={{ maxHeight: '390px',  }}>
      {filteredData.map((Language, index) =>
        Language.isavailable ? (
          <div
          key={index}
            style={
              language._id === Language._id
                ? { marginTop: '10px', background: '#1c2029' }
                : { marginTop: '10px' }
            }
            id={`lang-${Language._id}`}
            onClick={() => SelectedValue(Language)}
            className="itemshover"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '10px',
                overFlow: 'hidden',
                width: '100%',
                flex: '1 1 auto'
              }}
            >
              <div
                style={{ display: 'flex', flex: '1 1 auto', flexDirection: 'row', width: '250px' }}
              >
                <div>
                  <img
                    src={`https://www.flagcdn.com/128x96/${Language.flag.toLowerCase()}.png`}
                    alt="not found"
                    style={{ display: 'block', height: '1rem', width: 'auto' }}
                  />
                </div>
                <div style={{ marginLeft: '10px' }}>{Language.language?.toUpperCase()}</div>
                <div style={{ marginLeft: '10px' }}>
                  {'(' + Language.langCode?.toUpperCase() + ')'}
                </div>
              </div>
              <div>
                <div>
                  <div>{Language.endonym?.toUpperCase()}</div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      )}
    </div>
  );
};

export default List;
