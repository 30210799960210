import {
  ALL_TEAMS,
  APPEND_STAGES_DATA,
  MATCHES_FILTER,
  REMOVE_STAGE_WITH_ID,
  REPLACE_STAGE_AFTER_UPDATE,
  SET_COMETITIONDATA,
  SET_COMPETITION,
  SET_CURRENT_STAGE,
  SET_INFORMATION_DATA,
  SET_MATCHES,
  SET_STAGES_DATA,
  SET_UNSAVED_STAGES,
  UPDATE_COMPETITION,
  UPDATE_SCORESYSTEM,
  SEEDING_DATA,
  SET_ANYCHANGE_IN_STAGE,
  CLEAR_UNSAVED_STAGES,
  SET_STAGE_INDEX,
  UPDATE_STAGE_AFTER_CREATE,
  TOGGLE_MATCH_STATUS
} from '../action/types';
const initialState = {
  Matches: [],
  CompetitionData: [],
  Competition: {},
  StageTabs: [],
  CurrentStage: {},
  isAnyChange: 1,
  UnsavedStages: [],
  filterMatches: {},
  allTeams: [],
  informationData: {},
  isAnyChangeInStage: false,
  StageIndex: 0,
  SeedingTab: {
    loading: false,
    seedingStages: [],
    stages: [],
    groups: [],
    teams: [],
    totalSeeds: 0,
    seeds: [],
    saveLoading: false,
    saveMessage: '',
    updateSeeding: 0,
    updatedSavedChanges: 0
  }
};

export default function competitionDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMETITIONDATA:
      return {
        ...state,
        CompetitionData: action.payload
      };
    case SET_COMPETITION:
      return {
        ...state,
        Competition: action.payload
      };
    case SET_MATCHES:
      return {
        ...state,
        Matches: action.payload
      };

    case UPDATE_COMPETITION:
      return {
        ...state,
        Competition: {
          ...state.Competition,
          multiStageLeague: {
            ...state.Competition.multiStageLeague,
            name: action.payload.name,
            logo: action.payload.logo,
            cover: action.payload.cover
          }
        }
      };
    case SET_ANYCHANGE_IN_STAGE:
      return {
        ...state,
        isAnyChangeInStage: action.payload
      };
    case MATCHES_FILTER:
      return {
        ...state,
        filterMatches: action.payload
      };
    case ALL_TEAMS:
      return {
        ...state,
        allTeams: action.payload
      };
    case SET_INFORMATION_DATA:
      return {
        ...state,
        informationData: action.payload
      };
    case SET_STAGES_DATA:
      return {
        ...state,
        StageTabs: action.payload
      };
    case APPEND_STAGES_DATA:
      const [lastStage] = state.StageTabs.slice(-1);
      const newStage = {
        ...action.payload,
        _id: `${state?.StageTabs.length + 1}`,
        name: `Stage  ${state?.StageTabs.length + 1}`,
        scoreSystem: lastStage?.scoreSystem || [],
        rankingCriteria: lastStage?.rankingCriteria,
        multiStageLeague: lastStage?.multiStageLeague,
        isMatchesGenerated: false,
        ...(lastStage?.league && { league: lastStage.league }),
        ...(lastStage?.tournament && { tournament: lastStage.tournament })
      };
      let newStageObj = {
        count: 0,
        allStagesmatches: [],
        stageDetails: newStage
      };
      let newGroupObj = {
        count: 0,
        groupMatches: [],
        groupDetails: action.payload?.groups[0]
      };
      return {
        ...state,
        StageTabs: [...state.StageTabs, { _id: `${state?.StageTabs.length + 1}` }],
        isAnyChangeInStage: true,
        Competition: {
          ...state.Competition,
          multiStageLeague: {
            ...state.Competition.multiStageLeague,
            stages: [
              ...state.Competition.multiStageLeague.stages,
              { _id: `${state?.StageTabs.length + 1}` }
            ]
          }
        },
        UnsavedStages: [...state.UnsavedStages, newStage],
        Matches: {
          ...state.Matches,
          data: {
            ...state.Matches.data,
            data: [...(state?.Matches?.data?.data ?? []), [newStageObj, newGroupObj]]
          }
        }
      };

    case UPDATE_STAGE_AFTER_CREATE:
      // let _newStageTabs = state.StageTabs.push(action.payload)
      let newStageObj2 = {
        count: 0,
        allStagesmatches: [],
        stageDetails: action.payload
      };
      let newGroupObj2 = {
        count: 0,
        groupMatches: [],
        groupDetails: action?.payload?.groups[0]
      };
      return {
        ...state,
        StageTabs: [...state.StageTabs, action.payload],
        Competition: {
          ...state.Competition,
          multiStageLeague: {
            ...state.Competition.multiStageLeague,
            stages: [...state.Competition.multiStageLeague.stages, action.payload]
          }
        },
        Matches: {
          ...state.Matches,
          data: {
            ...state.Matches.data,
            data: [...(state?.Matches?.data?.data ?? []), [newStageObj2, newGroupObj2]]
          }
        }
      };
    case SET_CURRENT_STAGE:
      return {
        ...state,
        CurrentStage: action.payload
      };
    case REMOVE_STAGE_WITH_ID:
      let newTabs = state.StageTabs.filter((el) => el._id !== action.payload);
      return {
        ...state,
        StageTabs: newTabs
      };
    case CLEAR_UNSAVED_STAGES:
      return {
        ...state,
        UnsavedStages: [],
        StageTabs: state.StageTabs.filter((tab) => tab._id.length > 6)
      };
    case SET_STAGE_INDEX:
      return {
        ...state,
        StageIndex: action.payload
      };
    case SET_UNSAVED_STAGES:
      let find = state.UnsavedStages.findIndex((el) => el._id === action.payload._id);
      if (find < 0) {
        return {
          ...state,
          isAnyChange: state.isAnyChange + 1,
          UnsavedStages: [...state.UnsavedStages, action.payload]
        };
      } else {
        let _newObj = JSON.parse(JSON.stringify(action.payload));
        state.UnsavedStages[find] = _newObj;
        return {
          ...state,
          isAnyChange: state.isAnyChange + 1
        };
      }

    // case SET_UNSAVED_STAGES:
    //   let find = state.UnsavedStages.findIndex((el) => el._id === action.payload._id);
    //   if (find < 0) {
    //     return {
    //       ...state,
    //       UnsavedStages: [...state.UnsavedStages, action.payload]
    //     };
    //   } else {
    //     let _newObj = JSON.parse(JSON.stringify(action.payload));
    //     const newUnsavedStages = [...state.UnsavedStages]; // creating a new array
    //     newUnsavedStages[find] = _newObj; // updating the correct object
    //     return {
    //       ...state,
    //       UnsavedStages: newUnsavedStages // returning the new array
    //     };
    //   }
    // case REPLACE_STAGE_AFTER_UPDATE:
    //   let index = state.StageTabs.findIndex((el) => el._id === action.payload._id);
    //   state.StageTabs[index] = action.payload;
    //   state.UnsavedStages = state.UnsavedStages.filter((el) => el._id !== action.payload._id);
    //   return state;
    case REPLACE_STAGE_AFTER_UPDATE:
      let { stage, oldId } = action.payload;
      if (oldId) {
        const oldIndex = state.StageTabs.findIndex((el) => el._id === oldId);
        const updatedTabs = [...state.StageTabs];
        updatedTabs[oldIndex] = stage; // replace the updated stage object in the new array
        const newUnsavedStages = state.UnsavedStages.filter((el) => el._id !== oldId);
        return {
          ...state,
          StageTabs: updatedTabs, // update StageTabs with the new array
          UnsavedStages: newUnsavedStages
        };
      } else {
        const index = state.StageTabs.findIndex((el) => el._id === stage._id);
        const updatedTabs = [...state.StageTabs]; // create a new array using spread operator
        updatedTabs[index] = stage; // replace the updated stage object in the new array
        const newUnsavedStages = state.UnsavedStages.filter((el) => el._id !== stage._id);
        return {
          ...state,
          StageTabs: updatedTabs, // update StageTabs with the new array
          UnsavedStages: newUnsavedStages
        };
      }
    case TOGGLE_MATCH_STATUS:
      const matchesData = state.Matches?.data?.data || [];
      const updatedMatch = action.payload;
      const allMatches = matchesData[0]?.[0]?.AllMatches;

      if (!allMatches) {
        return state;
      }

      const updatedMatches = allMatches.map((match) => {
        if (match._id === updatedMatch._id) {
          return {
            ...match,
            status: updatedMatch.status
          };
        }
        return match;
      });
      return {
        ...state,
        Matches: {
          ...state.Matches,
          data: {
            ...state.Matches.data,
            data: matchesData.map((elem, ind, v) => {
              if (ind === 0) {
                return elem.map((elem2, ind2) => {
                  if (ind2 === 0) {
                    return {
                      ...elem2,
                      AllMatches: updatedMatches
                    };
                  }
                });
              }
              return elem;
            })
          }
        }
      };

    /***********************
     *  Data for Seeding   *
     ***********************/
    case SEEDING_DATA.LOAD_SUCCESS:
      let seedingStages = [];
      let stageIndex = action.stageIndex;
      state.SeedingTab.stages = action.payload;
      let stages = action.payload;
      stages.forEach((stage, index) => {
        let totalseeds = 0;
        let maxTeams = 0;
        stage.leaderBoardName = 'Initial Seeding';
        let stageTeams = [];
        if (index > 0) {
          let prevStage = { ...stages[index - 1] };
          stage.leaderBoardName = `Leaderboard (${prevStage.name})`;
          let tempTeams = [];
          let pervStageTeamsWrtGroups = [];
          let prevStageMaxTeams = 0;
          let prevStageTotalTeams = 0;
          let prevStageNumberOfGroup = prevStage?.groups?.length;

          stage.prevStageNumberOfGroup = prevStageNumberOfGroup;
          if (prevStage.stageType === 'league') {
            tempTeams = prevStage.league.teams;
          } else {
            tempTeams = prevStage.tournament.teams;
          }
          prevStage.groups.forEach((group) => {
            // if (group.teams.length > prevStageMaxTeams) prevStageMaxTeams = group.teams.length;
            if (group.noOfTeams > prevStageMaxTeams) prevStageMaxTeams = group.noOfTeams;
            prevStageTotalTeams += group.noOfTeams;
          });
          let prevStageLeagueTeams =
            typeof prevStage?.league?.teams !== 'undefined'
              ? [...prevStage.league.teams]
              : [...prevStage.tournament.teams];
          prevStage.groups = prevStage.groups.map((group, _i) => {
            let _group_teams_withScore = [];
            _group_teams_withScore = group.teams.map((team) => {
              let _team = prevStageLeagueTeams.find(
                (x) => x.team && team.team && x.team._id === team.team._id
              );
              if (_team) {
                team.score = _team.score;
                team.order = _team.order;
                team.isComplete = group.matchCompleted;
                // team.isComplete = group.matchCompleted ?? _i % 2 === 0;
              }
              return { ...team };
            });
            if (group.overviewManualSort) {
              group.teamsWithScores = _group_teams_withScore.sort((a, b) => a.order - b.order);
            } else {
              group.teamsWithScores = _group_teams_withScore.sort((a, b) => b.score - a.score);
            }
            return { ...group };
          });
          Array.from(Array(prevStageMaxTeams)).forEach((item, index) => {
            prevStage.groups.forEach((group, _i) => {
              // group.teams.sort((a, b) => b.score - a.score);
              if (index < group.teams.length) {
                // let _team = { ...stage.league.autoFillTeams ? group.teamsWithScores[index] : group.teams[index] };
                let _team = { ...group.teamsWithScores[index] };
                _team.isComplete = _team.isComplete ?? group.matchCompleted;
                // _team.isComplete = group.matchCompleted ?? _i % 2 === 0;
                if (_team.isComplete) {
                  _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                } else {
                  _team.seedLabel = `TBD (${group.name} - Seed ${index + 1})`;
                }

                pervStageTeamsWrtGroups.push(_team);
              } else {
                let _team = { isComplete: false };
                _team.seedLabel = `TBD (${group.name} - Seed ${index + 1})`;
                pervStageTeamsWrtGroups.push(_team);
              }
            });
          });
          // console.log('pervStageTeamsWrtGroups: ALL_', pervStageTeamsWrtGroups)
          let currentStageTeams = [];
          let currentStageMaxTeams = 0;
          stage.groups.forEach((group) => {
            if (group.noOfTeams > currentStageMaxTeams) currentStageMaxTeams = group.noOfTeams;
          });
          let prevStageTeamIndex = 0;
          Array.from(Array(currentStageMaxTeams)).forEach((item, index) => {
            stage.groups.forEach((group, _i) => {
              let _grpTeams = group.teams.filter((team) => team.team).map((team) => team);
              if (!stage?.league?.autoFillTeams && _grpTeams) {
                // if (!stage.league.autoFillTeams && group.teams.length > 0) {
                if (index < group.teams.length) {
                  if (group.teams[index]) {
                    if (group.teams[index].team) {
                      let _team = { ...group.teams[index] };
                      let _prevGrpTeam = pervStageTeamsWrtGroups.find(
                        (x) => x.team && _team.team && x.team._id === _team.team._id
                      );
                      let isComplete = false;
                      if (_prevGrpTeam) {
                        isComplete = _prevGrpTeam.isComplete;
                        _team.seedLabel = _prevGrpTeam.seedLabel;
                      } else {
                        let seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                        _team.seedLabel = seedLabel;
                      }
                      currentStageTeams.push({
                        group,
                        team: _team ?? 'TBD',
                        seedPosition: _team.seedPosition ?? index + 1,
                        isComplete
                      });
                    } else {
                      if (
                        pervStageTeamsWrtGroups[prevStageTeamIndex] &&
                        pervStageTeamsWrtGroups[prevStageTeamIndex].team
                      ) {
                        // group.teams[index] = pervStageTeamsWrtGroups[prevStageTeamIndex];
                        let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                        let isComplete = _team.isComplete;
                        // _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                        currentStageTeams.push({
                          group,
                          team: _team ?? 'TBD',
                          seedPosition: index + 1,
                          isComplete
                        });
                      } else {
                        let isComplete = pervStageTeamsWrtGroups[prevStageTeamIndex].isComplete;
                        let _Team = {
                          seedLabel: pervStageTeamsWrtGroups[prevStageTeamIndex].seedLabel,
                          seedPosition: index + 1
                        };
                        // _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                        currentStageTeams.push({
                          group,
                          team: _Team ?? 'TBD',
                          seedPosition: index + 1,
                          isComplete
                        });
                      }
                    }
                    // group.teams[index] = pervStageTeamsWrtGroups[prevStageTeamIndex];
                  } else {
                    let _team = { seedLabel: 'TBD' };
                    currentStageTeams.push({
                      group,
                      team: _team,
                      seedPosition: index + 1,
                      isTbd: true,
                      isComplete: false
                    });
                  }
                  prevStageTeamIndex++;
                } else if (index < group.noOfTeams) {
                  currentStageTeams.push({
                    group,
                    team: 'TBD',
                    seedPosition: index + 1,
                    isTbd: true,
                    isComplete: false
                  });
                }
              } else {
                if (index < group.noOfTeams) {
                  if (
                    pervStageTeamsWrtGroups[prevStageTeamIndex] &&
                    pervStageTeamsWrtGroups[prevStageTeamIndex].team
                  ) {
                    // group.teams[index] = pervStageTeamsWrtGroups[prevStageTeamIndex];
                    let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                    let isComplete = _team.isComplete;
                    // _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                    currentStageTeams.push({
                      group,
                      team: _team ?? 'TBD',
                      seedPosition: index + 1,
                      isComplete
                    });
                  } else {
                    let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                    if (!_team) {
                      _team = { seedLabel: `TBD` };
                    }
                    currentStageTeams.push({
                      group,
                      team: _team.seedLabel,
                      seedPosition: index + 1,
                      isTbd: true,
                      isComplete: false
                    });
                  }
                  prevStageTeamIndex++;
                }
              }
            });
          });

          if (
            stage?.league?.autoFillTeams === undefined ||
            stage?.tournament?.autoFillTeams === undefined ||
            stage.league.autoFillTeams === true ||
            stage?.tournament.autoFillTeams === true
          ) {
            if (stage?.league?.autoFillTeams === undefined) {
              if (stage?.league) {
                stage.league.autoFillTeams = true;
              }else{
                stage.tournament.autoFillTeams = true
              }
            }
            stageTeams = tempTeams.sort((a, b) => b.score - a.score);
          } else {
            stageTeams = tempTeams.sort((a, b) => a.order - b.order);
          }
          if(stageTeams.stageType === 'league'){
            stage.league.teams = stageTeams;
          }else{
            stage.tournament.teams = stageTeams
          }
          stage.prevStageTotalTeams = prevStageTotalTeams;
          stage.seedings = currentStageTeams;
        } else {
          if (stage.stageType === 'league') {
            state.SeedingTab.teams = stage.league.teams;
            stageTeams = stage.league.teams;
          } else {
            state.SeedingTab.teams = stage.tournament.teams;
            //stageTeams = action.payload[stageIndex].tournament.teams;
            stageTeams = stage.tournament.teams;
          }
          let _groupsTeamsAddedLength = 0;
          stage.groups.map((item, index) => {
            totalseeds = totalseeds + item.noOfTeams;
            if (item.noOfTeams > maxTeams) {
              maxTeams = item.noOfTeams;
            }
            _groupsTeamsAddedLength += item.teams.length;
          });
          stage.totalSeeds = totalseeds;
          stage.prevStageTotalTeams = totalseeds;

          // ****************************************************** //
          // groupsTeams --> array of objects for seeding;
          let groupsTeams = [];
          // based on maxTeams length create an array of objects containing all the data related to
          // groups, teams, seed positions if available
          Array(maxTeams)
            .fill(0)
            .map((_, i) => {
              stage.groups.forEach((group) => {
                let _item = {
                  group: group
                };
                if (i < group.noOfTeams) {
                  if (group.teams.length < group.noOfTeams) {
                    let add_pos = true;
                    group.teams.forEach((team, t_index) => {
                      if (team.seedPosition === i + 1) {
                        _item.team = team;
                        // console.log('_team: ', team)
                        // _item.team.seedLabel = team.team.name;
                        _item.seedPosition = team.seedPosition;
                        _item.isComplete = true;
                        add_pos = false;
                      }
                    });
                    if (add_pos) {
                      _item.seedPosition = i + 1;
                    }
                  } else {
                    if (
                      group.teams[i] !== undefined &&
                      _groupsTeamsAddedLength <= stageTeams.length
                    ) {
                      if (index === 0) _item.team = group.teams[i];
                      else if (index > 0) _item.team = stageTeams[i];
                      _item.seedPosition = group.teams[i].seedPosition;
                      // console.log('_item.team: ', _item.team)
                      _item.team.seedLabel = _item.team.team.name;
                      _item.isComplete = true;
                    } else {
                      _item.seedPosition = i + 1;
                    }
                  }
                  groupsTeams.push(_item);
                }
              });
            });

          // check what is greater the groupsTeams or teams in the stage
          if (stageTeams.length >= groupsTeams.length && index <= 0) {
            stageTeams.forEach((_team, i) => {
              _team.seedLabel = _team?.team?.name;
              if (i < groupsTeams.length) {
                if (groupsTeams[i].team === undefined) {
                  groupsTeams[i].team = _team;
                  groupsTeams[i].team.seedLabel = _team.team.name;
                  groupsTeams[i].isComplete = true;
                }
              } else {
                groupsTeams.push({ team: _team, isComplete: true });
              }
            });
          } else {
            groupsTeams = groupsTeams.map((_team, _index) => {
              if (_index < stageTeams.length) {
                if (_team.team === undefined) {
                  _team.team = stageTeams[_index];
                  _team.team.seedLabel = stageTeams[_index].team.name;
                }
              } else {
                //* in case of 1st stage, if there are less teams in the stage
                //* then show "Not enough teams"
                _team.team = 'TBD';
                // _team.isTbd = true;
                _team.noTeam = true;
              }
              _team.isComplete = true;
              return _team;
            });
          }
          stage.seedings = groupsTeams;
        }
        // ****************************************************** //
        stage.totalSeeds = totalseeds;
        stage.unSavedChanges = false;
        seedingStages.push(stage);
      });
      state.SeedingTab.seedingStages = seedingStages;
      return state;

    case SEEDING_DATA.TOGGLE_AUTO_FILL:
      let _stgIndex = action.stageIndex;
      let _stg = state.SeedingTab.seedingStages[_stgIndex];
      let _isAutoFill = action.isAutoFill;
      if (typeof _stg?.bracketType === 'undefined') {
        if (_stg?.league) {
          _stg.league.autoFillTeams = _isAutoFill;
        }
      } else {
        if (_stg?.tournament) {
          _stg.tournament.autoFillTeams = _isAutoFill;
        }
      }
      let totalseeds = 0;
      let maxTeams = 0;
      let stageTeams = [];
      let groupsTeams2 = [];

      if (_stgIndex > 0) {
        let prevStage2 = { ...state.SeedingTab.seedingStages[_stgIndex - 1] };
        let prevStageTeams = [...prevStage2.league.teams];
        let pervStageTeamsWrtGroups = [];
        let prevStageMaxTeams = 0;
        let prevStageNumberOfGroup = prevStage2?.groups?.length;

        _stg.prevStageNumberOfGroup = prevStageNumberOfGroup;

        prevStage2.groups.forEach((group) => {
          if (group.noOfTeams > prevStageMaxTeams) prevStageMaxTeams = group.noOfTeams;
        });
        let prevStageLeagueTeams = [...prevStage2.league.teams];
        prevStage2.groups = prevStage2.groups.map((group, _i) => {
          let _group_teams_withScore = [];
          if (group.teams.length > 0) {
            _group_teams_withScore = group.teams.map((team) => {
              let _team = prevStageLeagueTeams.find((x) => x?.team?._id === team?.team?._id);
              if (_team) {
                team.score = _team.score;
                team.order = _team.order;
                team.isComplete = group.matchCompleted;
                // team.isComplete = group.matchCompleted ?? _i % 2 !== 0;
              }
              return { ...team };
            });
          } else {
            _group_teams_withScore = Array(group.noOfTeams)
              .fill(0)
              .map((team, _i) => {
                return {
                  team: {
                    seedLabel: `TBD (${group.name} - ${_i + 1})`
                  },
                  score: 0,
                  order: 0,
                  isComplete: false,
                  isTbd: true
                };
              });
          }
          if (group.overviewManualSort) {
            group.teamsWithScores = _group_teams_withScore.sort((a, b) => a.order - b.order);
          } else {
            group.teamsWithScores = _group_teams_withScore.sort((a, b) => b.score - a.score);
          }
          return { ...group };
        });

        Array.from(Array(prevStageMaxTeams)).forEach((item, index) => {
          prevStage2.groups.forEach((group, _i) => {
            if (index < group.teamsWithScores.length) {
              let _team = { ...group.teamsWithScores[index] };
              _team.isComplete = group.matchCompleted;
              if (_team.isComplete) {
                _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
              } else {
                _team.seedLabel = `TBD (${group.name} - Seed ${index + 1})`;
              }
              pervStageTeamsWrtGroups.push(_team);
            }
          });
        });

        let currentStageTeams = [];
        let currentStageMaxTeams = 0;

        _stg.groups.forEach((group) => {
          if (group.noOfTeams > currentStageMaxTeams) currentStageMaxTeams = group.noOfTeams;
        });

        let prevStageTeamIndex = 0;
        Array.from(Array(currentStageMaxTeams)).forEach((item, index) => {
          _stg.groups.forEach((group, _i) => {
            if (!_isAutoFill && group.teams.length > 0) {
              if (index < group.teams.length) {
                if (group.teams[index]) {
                  let _team = { ...group.teams[index] };
                  let _prevGrpTeam = pervStageTeamsWrtGroups.find(
                    (x) => x.team?._id === _team?.team?._id
                  );
                  let isComplete = false;
                  if (_prevGrpTeam) {
                    isComplete = _prevGrpTeam.isComplete;
                    _team.seedLabel = _prevGrpTeam.seedLabel;
                  } else {
                    let seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                    _team.seedLabel = seedLabel;
                  }
                  currentStageTeams.push({
                    group,
                    team: _team ?? 'TBD',
                    seedPosition: _team.seedPosition ?? index + 1,
                    isComplete
                  });
                } else {
                  let _prevGrpTeam = pervStageTeamsWrtGroups[prevStageTeamIndex];
                  let _team = { seedLabel: 'TBD' };
                  if (_prevGrpTeam) {
                    _team = { ..._prevGrpTeam };
                  }
                  currentStageTeams.push({
                    group,
                    team: _team,
                    seedPosition: index + 1,
                    isTbd: true,
                    isComplete: false
                  });
                }
                prevStageTeamIndex++;
              }
            } else {
              if (index < group.noOfTeams) {
                if (
                  pervStageTeamsWrtGroups[prevStageTeamIndex] &&
                  pervStageTeamsWrtGroups[prevStageTeamIndex].team
                ) {
                  let _team = { ...pervStageTeamsWrtGroups[prevStageTeamIndex] };
                  let isComplete = _team.isComplete;
                  // group.teams[index] = _team;
                  currentStageTeams.push({
                    group,
                    team: _team,
                    seedPosition: index + 1,
                    isComplete
                  });
                } else {
                  let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                  if (!_team) {
                    _team = { seedLabel: `TBD` };
                  }
                  currentStageTeams.push({
                    group,
                    team: _team,
                    seedPosition: index + 1,
                    isTbd: true,
                    isComplete: false
                  });
                }
                prevStageTeamIndex++;
              }
            }
          });
        });
        if(_stg.stageType === 'league'){
          _stg.league.teams = currentStageTeams;
        }else{
          _stg.tournament.teams = currentStageTeams;
        }
        _stg.seedings = currentStageTeams;
        state.SeedingTab.seedingStages[_stgIndex] = _stg;
      } else {
        if (_stg.stageType === 'league') {
          state.SeedingTab.teams = _stg.league.teams;
          stageTeams = _stg.league.teams;
        } else {
          state.SeedingTab.teams = _stg.tournament.teams;
          stageTeams = _stg.tournament.teams;
        }
        _stg.groups.forEach((item, index) => {
          totalseeds = totalseeds + item.noOfTeams;
          if (item.noOfTeams > maxTeams) {
            maxTeams = item.noOfTeams;
          }
        });
        _stg.totalSeeds = totalseeds;

        // based on maxTeams length create an array of objects containing all the data related to
        // groups, teams, seed positions if availabled
        Array(maxTeams)
          .fill(0)
          .map((_, i) => {
            _stg.groups.forEach((group) => {
              let _item = {
                group: group
              };
              if (i < group.noOfTeams) {
                if (group.teams.length < group.noOfTeams) {
                  let add_pos = true;
                  group.teams.forEach((team, t_index) => {
                    if (team.seedPosition === i + 1) {
                      _item.team = team;
                      // console.log('_team: ', team)
                      _item.seedPosition = team.seedPosition;
                      _item.isComplete = true;
                      add_pos = false;
                    }
                  });
                  if (add_pos) {
                    _item.seedPosition = i + 1;
                  }
                } else {
                  if (group.teams[i] !== undefined) {
                    if (_stgIndex == 0) _item.team = group.teams[i];
                    else if (_stgIndex > 0) _item.team = stageTeams[i];
                    _item.seedPosition = group.teams[i].seedPosition;
                    // console.log('_item.team: ', _item)
                    if (_item?.team?.team?.name) {
                      _item.team.seedLabel = _item?.team?.team?.name;
                    } else {
                      _item.team.seedLabel = `TBD`;
                    }
                    _item.isComplete = true;
                  } else {
                    _item.seedPosition = i + 1;
                  }
                }
                groupsTeams2.push(_item);
              }
            });
          });

        // check what is greater the groupsTeams or teams in the stage
        if (stageTeams.length >= groupsTeams2.length && _stgIndex <= 0) {
          stageTeams.forEach((_team, i) => {
            if (i < groupsTeams2.length) {
              if (groupsTeams2[i].team === undefined) {
                groupsTeams2[i].team = _team;
                groupsTeams2[i].team.seedLabel = _team.team.name;
                groupsTeams2[i].isComplete = true;
              }
            } else {
              groupsTeams2.push({ team: _team, isComplete: true });
            }
          });
        } else {
          groupsTeams2 = groupsTeams2.map((_team, index) => {
            if (index < stageTeams.length) {
              if (_team.team === undefined) {
                _team.team = stageTeams[index];
                _team.team.seedLabel = stageTeams[index].team.name;
              }
            } else {
              //* in case of 1st stage, if there are less teams in the stage
              //* then show "Not enough teams"
              _team.team = 'TBD';
              _team.noTeam = true;
            }
            _team.isComplete = true;
            return _team;
          });
        }
        // if (_isAutoFill) {
        //   groupsTeams2 = groupsTeams2.sort((a, b) => a.team.seedPosition - b.team.seedPosition).map(item => item);
        // }
        _stg.seedings = groupsTeams2;
        state.SeedingTab.seedingStages[_stgIndex] = _stg;
      }
      state.SeedingTab.updateSeeding = state.SeedingTab.updateSeeding + 1;

      return state;

    case SEEDING_DATA.UPDATE_STAGE_SEEDING:
      let _stageIndex = action.stageIndex;
      let _stage = state.SeedingTab.seedingStages[_stageIndex];
      let _seedings = action.seedings;
      _stage.seedings = _seedings;
      state.SeedingTab.seedingStages[_stageIndex] = _stage;
      state.SeedingTab.updateSeeding = state.updateSeeding + 1;
      return state;

    case SEEDING_DATA.SET_SAVE_LOADING:
      state.SeedingTab.saveLoading = action.payload;
      if (action.message) {
        state.SeedingTab.saveMessage = action.message;
      }
      return state;

    case SEEDING_DATA.SET_SAVE_MESSAGE_RESET:
      state.SeedingTab.saveMessage = '';
      return state;

    case SEEDING_DATA.SAVED_UPDATE_GROUPS:
      let _stgId = action.payload.stageId;
      let _dataGroups = action.payload.data;
      let _stgIdx = state.SeedingTab.seedingStages.findIndex((item) => item._id === _stgId);
      if (_stgIdx >= 0) {
        let _selectedStg = { ...state.SeedingTab.seedingStages[_stgIdx] };
        let _selectedStageGroups = [..._selectedStg.groups];
        _selectedStageGroups = _selectedStageGroups.map((group, index) => {
          let _group = { ...group };
          let _dGroup = _dataGroups.find((item) => item._id === group._id);
          if (_dGroup) {
            _group.teams = _dGroup.teams;
          }
          return _group;
        });
        _selectedStg.groups = [..._selectedStageGroups];
        state.SeedingTab.seedingStages[_stgIdx] = { ..._selectedStg };
      }
      return state;

    case SEEDING_DATA.SET_SEEDINGS_IN_STAGE:
      state.SeedingTab.seedingStages[action.payload.stageIndex].unSavedChanges =
        action.payload.unSavedChanges;
      state.SeedingTab.seedingStages[action.payload.stageIndex].seedings = action.payload.seedings;
      // state.SeedingTab.updatedSavedChanges = state.SeedingTab.updatedSavedChanges + 1;
      let uSavedChanges = state.SeedingTab.updatedSavedChanges;
      state.SeedingTab.updatedSavedChanges = uSavedChanges + 1;
      return state;

    case SEEDING_DATA.SET_UNSAVED_CHANGES_IN_STAGE:
      state.SeedingTab.seedingStages[action.payload.stageIndex].unSavedChanges =
        action.payload.unSavedChanges;
      // state.SeedingTab.updatedSavedChanges = state.SeedingTab.updatedSavedChanges + 1;
      let uSavedChanges2 = state.SeedingTab.updatedSavedChanges;
      state.SeedingTab.updatedSavedChanges = uSavedChanges2 + 1;
      return state;

    case SEEDING_DATA.SET_UNSAVED_CHANGES_ALL_SAVED:
      state.SeedingTab.seedingStages = state.SeedingTab.seedingStages.map((item) => ({
        ...item,
        unSavedChanges: false
      }));
      // state.SeedingTab.updatedSavedChanges = state.SeedingTab.updatedSavedChanges + 1;
      let uSavedChanges3 = state.SeedingTab.updatedSavedChanges;
      state.SeedingTab.updatedSavedChanges = uSavedChanges3 + 1;
      return state;

    default:
      return state;
  }
}
