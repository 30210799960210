'use strict';
import React, { useState, useEffect, useRef } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { HiX } from 'react-icons/hi';

// added an onBlur function for save call on close
const ColorPicker = ({ mainColour, onUpdateColor, onBlur, show, id, onClose, scroll }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({ hex: mainColour.replace('#', '') });
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // debugger
      // let newTop =  `-${scroll}px`
      // debugger
      console.log(scroll);
      ref.current.style.marginTop = `-${scroll}px`;
    }
  }, [scroll, show]);
  useEffect(() => {
    setColor({ hex: mainColour.replace('#', '') });
  }, [mainColour]);

  const handleClick = () => {
    if (displayColorPicker && onBlur) onBlur();
    setDisplayColorPicker((state) => !state);
  };

  const handleClose = () => {
    if (onBlur) onBlur();
    onClose();
    setDisplayColorPicker((state) => false);
  };

  const handleChange = (colorss) => {
    setColor({ hex: colorss.hex.replace('#', '') });
    const clr = onUpdateColor(colorss.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        marginTop: '15px',
        width: '62px',
        height: '24px',
        /* UI Properties */
        textAlign: 'left',
        font: 'normal normal bold 10px/13px Montserrat',
        letterSpacing: '0px',
        color: '#ffffff',
        background: '#68717E 0% 0% no-repeat padding-box',
        textTransform: 'uppercase',
        opacity: 1
      },
      // color: {
      //   width: '40px',
      //   height: '30px',
      //   borderRadius: '2px',
      //   //   background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
      //   background: `#${color.hex}`,
      // },
      swatch: {
        padding: '5px',
        background: '#12141A',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer'
      },
      popover: {
        position: 'absolute',
        background: '#12141A !important',
        zIndex: '2'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    }
  });
  useEffect(() => {
    if (show === id) {
      setDisplayColorPicker(true);
    }
    // console.log({show,id})
  }, [show]);

  return (
    <div>
      {/* <div style={styles.swatch} onClick={handleClick}>
        <button style={styles.color} onClick={(e) => e.preventDefault()}>
          Custom
        </button>
      </div> */}
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <div
            ref={ref}
            style={{
              display: 'flex',
              flexDirection: 'row-reverse'
            }}>
            <HiX onClick={handleClose} />
            <SketchPicker color={color} onChange={handleChange} disableAlpha={true} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
