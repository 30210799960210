import React, { useState, useEffect, useContext } from 'react';
import MyCompetitionItem from './../../../_common/MyCompetitionItem/MyCompetitionItem';
import moment from 'moment-timezone';
import ShareOptions from '../../../_common/ShareOptions/ShareOptions';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreateOrganizer from './CreateOrganizer';
import { Loading } from '../../../_common';
import { HiFolder } from 'react-icons/hi';
import OrganiserCard from '../../../_common/OrganiserCard/OrganiserCard';
import useContextMenu from '../../../_common/ContextMenu/hooks/useContextMenu';
import ContextMenu from '../../../_common/ContextMenu/ContextMenu';
import Options from '../../../_common/ThreeDots/Options/Options';
import useCtrlDelete from '../../../_common/ContextMenu/hooks/useCtrlDelete';
import { checkDeleteOrgPermission } from '../../../../helpers/permissionsHelper';
import DeleteItems from '../../../_modals/OrganizerPopup/deleteitems/delete';


const OrganizerGridView = ({ allOrganizers, hideOption, showCreate = false ,user}) => {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [selectedBoolean, setSelectedBoolean] = useState();
  const timeZone = useSelector((state) => state.TIMEZONE);
  const loading = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const [showDelete,setShowDelete] = useState(false)

  const history = useHistory();
  // const { isVisible, x, y, showMenu, hideMenu } = useContextMenu();
  const handleRouting = (id) => {
    history.push(`my-competitions/${id}`, { view: 'GRID' });
  };
  // useEffect(() => {
  //   console.log('Selected Competiton', selectedCompitation);
  // }, [selectedCompitation]);
  const getDate = (date) => {
    if (!timeZone) {
      return date;
    } else {
      return moment(date).tz(timeZone?.timeZone);
    }
  };
  const getLastEdited = ({ lastEditedDate, lastEditor }) => {
    let _lastEdited = moment(lastEditedDate).format('DD/MM-YYYY');
    let editorName = lastEditor?.firstName + ' ' + lastEditor?.lastName;
    return ` Last Edited: ${_lastEdited} by ${editorName}`;
  };

  const myOrganiserHandler = (i) => {
    // if(selectedCompitation === i ) return setSelectedCompitation(null)
    setSelectedOrg(i);
    // setSelectedBoolean(!selectedBoolean);
  };
  const onHoverHandler=(id,type)=>{
    switch(type){
      case 'IN':
        return setHoveredId(id);
      case 'OUT':
        return setHoveredId(null);
        default:
          return setHoveredId(null);
    }
  }

useCtrlDelete(()=>{
  let _organizer = allOrganizers.find(org => org._id === selectedOrg)
  if(!_organizer) return
  let perm = checkDeleteOrgPermission(user,_organizer?.organizerRoles)
  if(perm){
    setShowDelete(true)
  } 
})



  return loading ? (
    <div className="text-center league-no-matches">
      <Loading width={50} height={50} />
    </div>
  ) : (
    <div className="row m-0 grid-tab-content">
      <div style={{ marginLeft: 'auto', marginRight: '-17px', marginTop: '-135px' }}>
        <CreateOrganizer showCreate={showCreate}/>
      </div>

      <div className="col-12 p-0">
        <div className="row smaller-gutter-row container" style={{ maxWidth: '3000px' }}>
          {allOrganizers?.map((organizer, i) => {
            let _disabled = (organizer?._id === selectedOrg) ? true : (organizer?._id === hoveredId) ? true : false
            return (
              <OrganiserCard
                onHoverHandler={onHoverHandler}
                _disabled={!_disabled}
                organiser={organizer}
                permissions={organizer?.organizerRoles ?? []}
                thumbnail={organizer?.thumbnail}
                logo={organizer?.logo}
                last_edited={getLastEdited(organizer)}
                ind={i}
                title={organizer?.name}
                onClickHandler={(e)=>myOrganiserHandler(organizer?._id)}
                onDoubleClickHandler={(e)=>handleRouting(organizer?._id)}
                type="ORGANIZER"
              />
              // <div key={i} id={organizer._id} onClick={() => myCompetitionItemHandler(i)}>
              //   {/* <div style={{border:selectedCompitation===i && '1px solid red'}} */}
              //   {/* > */}
              //   <MyCompetitionItem
              //     //  style={{border:selectedCompitation===i && '1px solid red'}}
              //     key={organizer._id}
              //     hideOption={hideOption}
              //     // link_to={`/my-competitions/${123}`}
              //     onRouting={() => handleRouting(organizer._id)}
              //     title={
              //       <div style={{ display: 'flex', flexDirection: 'row' }}>
              //         <span className="imgLogoContainer">
              //           <HiFolder
              //             size={15}
              //             style={{ color: selectedCompitation === i ? '#3eabf0' : '#3eabf0' }}
              //           />
              //         </span>
              //         <span
              //           style={{
              //             font:'normal normal bold 12px/15px Montserrat',
              //             maxWidth: '235px',
              //             textOverflow: 'ellipsis',
              //             marginLeft: '300px',
              //             height: '15px',
              //             width: '82px',
              //             padding: '0px',
              //             overflow: 'hidden',
              //             position: 'relative',
              //             display: 'inline-block',
              //             margin: '0 5px 0 5px',
              //             textAlign: 'left',
              //             textDecoration: 'none',
              //             textOverflow: 'ellipsis',
              //             whiteSpace: 'nowrap',
              //             color: selectedCompitation === i && '#3eabf0'
              //           }}>
              //           {organizer.name}
              //         </span>
              //         <div style={{ display: 'none' }}>
              //           <ShareOptions selected={selectedCompitation} ind={i} />
              //         </div>
              //       </div>
              //     }
              //     threeDots
              //     type={'ORGANIZER'}
              //     organizerData={organizer}
              //     organizerId={organizer._id}
              //     thumbNail={organizer.thumbnail ? organizer.thumbnail : organizer.logo}
              //     selected={selectedCompitation}
              //     ind={i}
              //      date={
              //       <div
              //         style={{
              //           color: selectedCompitation === i ? '#296f9e' : '#68717E',
              //           // font: 'normal normal normal 12px/45px Montserrat',
              //           height:'15px',
              //           width:'247px',
              //           fontSize:'11px',
              //           marginTop: '7px'
              //         }}>
              //         {organizer.lastEditedDate
              //           ? 'Last edited: ' +
              //             moment(getDate(organizer.lastEditedDate)).format('MM-DD-YYYY HH:mm:ss') +
              //             ' by ' +
              //             organizer?.lastEditor?.firstName +
              //             ' ' +
              //             organizer?.lastEditor?.lastName
              //           : undefined}
              //       </div>
              //     }
              //   />
              // </div>
              // </div>
            );
          })}
          {/* {isVisible && <ContextMenu x={x} y={y} onClose={hideMenu} isVisible={isVisible}>
            <Options type='ORG'/>
          </ContextMenu>} */}
          {showDelete && (
          <DeleteItems
            open={showDelete}
            setDelete={(e) => setShowDelete(!showDelete)}
            type={"ORGANIZER"}
            folder={allOrganizers.find(org=> org._id === selectedOrg)}
          />
        )}
        </div>
      </div>
    </div>
  );
};

export default OrganizerGridView;
