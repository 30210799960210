// import { Checkbox, FormGroup,FormControlLabel } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { HiInformationCircle, HiOutlineArrowNarrowRight, HiUserGroup } from 'react-icons/hi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { IoIosPeople } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import SelectCustom from './SelectCustom';
import Group from './Group';
import { SEEDING_DATA } from '../../../../../redux/action/types';
import { fetchSeedingDetailsAutoSaveOnChange, fetchSeedingDetailsWithSave, saveSeedingDetailsForAll } from '../../utils';
import { checkUnSavedSeedingChanges, resetSeedingUpdate, setSeedingsWithoutSave } from '../../../../../redux/action/seedingActions';
import { InformationLogo, SimpleTabs } from '../../../../_common';
import { setIsAnyChangeInTab } from '../../../../../redux/action/CompetitonData';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Loading from '@common/Loading/Loading';


const FIRST_STAGE_SUB_TEXT = 'Drag the participants in the left column to change their initial seeding for this competition. Dropdowns in the right column determine how participants are placed based on the initial seeding.'
const NEXT_STAGE_SUB_TEXT = 'Dropdowns in the right column determine how participants are placed in this stage based on the leaderboard from the previous stage, seen in the left column.'


const SeedingContainer = ({ id, currentStageIndex, currentStage, loadingState }) => {

  const dispatch = useDispatch();
  const [isAutoFill, setIsAutoFill] = useState(true);
  const [currentGroup, setCurrentGroup] = useState({ label: 'All Groups', value: 0 });
  const [selectedGroupsTeam, setSelectedGroupsTeam] = useState([]);
  const [dropDownValues, setDropdownValues] = useState([{}]);
  const [subTabs, setSubTabs] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [seeds, setSeeds] = useState([]);

  const [valuesToShow, setValuesToShow] = useState([]);

  const dragItem = useRef();
  const dragOverItem = useRef();


  // redux store data
  const seedingTabsData = useSelector((state) => state.COMPETITION.SeedingTab);
  const seedingStages = useSelector((state) => state.COMPETITION.SeedingTab.seedingStages);
  const totalSeeds = useSelector((state) => state.COMPETITION.SeedingTab.totalSeeds);
  const updateSeeding = useSelector((state) => state.COMPETITION.SeedingTab.updateSeeding);
  const saveLoading = useSelector((state) => state.COMPETITION.SeedingTab.saveLoading);
  const teams = useSelector((state) => state.COMPETITION.SeedingTab.teams);

  useEffect(() => {
    if (seedingStages) {
      if (seedingStages[currentStageIndex] !== undefined) {
        setSelectedGroupsTeam(seedingStages[currentStageIndex]?.seedings);
        setSubTab(seedingStages[currentStageIndex].groups, seedingStages[currentStageIndex]?.seedings);

        // let _seedings = seedingStages[currentStageIndex].seedings;
        let _groups = seedingStages[currentStageIndex].groups;
        let _groupSeeds = [];
        _groups.forEach(group => {
          let _seeds = Array(group.noOfTeams)
            .fill(0)
            .map((item, index) => ({
              group,
              seedPosition: index + 1
            }));
          _groupSeeds = [..._groupSeeds, ..._seeds]
        })
        _groupSeeds = _groupSeeds.sort((a, b) => a.seedPosition - b.seedPosition);
        populateValuesToShow(seedingStages[currentStageIndex]?.seedings, _groupSeeds);
        setDropdownValues(_groupSeeds);
        setActiveTab(_groups?.length > 1 ? 0 : 1)
      }
    }
  }, [seedingStages, currentStageIndex, seedingStages?.length]);


  const populateValuesToShow = (seedings, _dropDownValues) => {
    let array = seedings
      .map((x, i) => {
        let isTBD = x.team === "TBD" || x.isTbd;
        let _team = isTBD ? x.team : x.team && x.team.team ? x.team.team : "TBD";
        let _teamId = x.team && x.team.team ? x.team?.team?._id : null;
        let _teamName = isTBD ? _team : (_team && _team.name) ? _team.name : "TBD";
        // console.log(isTBD, x.isTbd, _team);
        let _teamLabel = isTBD
          ? _team.seedLabel
            ? _team.seedLabel
            : typeof _team === 'string' ? _team : _team?.name
          : x.team && x.team.seedLabel
            ? x.team.seedLabel
            : typeof _team === 'string' ? _team
              : _team?.name ? _team.name : "TBD";
        // _teamLabel = 'Team';
        let isGroup = x.group !== undefined;
        let _group = isGroup ? x.group : "";
        let _groupId = isGroup ? _group._id : "";
        let _groupName = isGroup ? _group.name : "";
        let _seedPosition = x?.seedPosition;
        let dropdownValueObject = _dropDownValues.find(
          (v) => _groupId === v?.group?._id && x?.seedPosition === v?.seedPosition
        );
        let dropdownValue = "NO SPOT";
        if (dropdownValueObject && i <= _dropDownValues.length) {
          dropdownValue = `${dropdownValueObject.group.name} - Seed ${dropdownValueObject.seedPosition}`;
        }
        let isNoSpot = false;
        if (dropdownValue === "NO SPOT" || _seedPosition === -1) {
          isNoSpot = true;
          dropdownValue = "NO SPOT";
        }
        let whiteLabel = ''
        let greyedLabel = '';
        let containsTBD_group = false;
        let onlyOneGroup = seedingStages[currentStageIndex].prevStageNumberOfGroup;
        if (_teamLabel.includes('TBD (')) {
          whiteLabel = 'TBD ';
          let part2 = _teamLabel.split('TBD (')[1];
          greyedLabel = `(${part2}`;
          if (onlyOneGroup === 1) {
            let part3 = part2.split(' - ')[1];
            greyedLabel = `(${part3}`;
          }
          containsTBD_group = true;
        }
        let noTeam = false;
        let prevStageTotalTeams = seedingStages[currentStageIndex].prevStageTotalTeams;
        //* in case of 1st stage, if there are less teams in the stage or
        //* if previous stage has less teams than the total seedings in the current stage
        //* then show "Not enough teams"
        if ((currentStageIndex === 0 && x?.noTeam) || i >= prevStageTotalTeams) {
          noTeam = true;
        }
        let _Id = isTBD ? (_team.seedLabel ? "TBD - " + i : _team.includes("TBD") ? _team + " - " + i : _team) : _team._id ? _team._id : "TBD - " + i;
        return {
          i: i,
          _id: _Id,
          itemIndex: isTBD ? _team : _teamId,
          noTeam,
          stage_id: seedingStages[currentStageIndex]._id,
          _team,
          _teamLabel,
          containsTBD_group,
          whiteLabel,
          greyedLabel,
          _groupName,
          dropdownValue,
          _seedPosition,
          isNoSpot,
          _teamId
        }
      });

    setValuesToShow(array);
  }




  const handleSelectedGroup = (e) => {
    let value = e.target.value;
    let label = e.target.name;
    setActiveTab(value);
    setCurrentGroup({ label, value })
  };

  const setSubTab = (groups, _seeds, reset) => {
    if (groups !== undefined) {
      let _seeding = reset ? [] : _seeds ? [..._seeds] : [];
      _seeding = _seeding?.length > 0 ? _seeding.filter((x) => x.stage_id === seedingStages[currentStageIndex]._id) : [];
      const _subtabs = groups.map((item, index) => ({
        key: index + 1,
        name: item.name,
        content: (
          <Group
            totalSeeds={totalSeeds}
            groupId={item._id}
            allGroup={activeTab === 0 ? true : false}
            groups={seedingStages[currentStageIndex].groups}
            seeds={_seeding}
            teams={seedingStages[currentStageIndex].stageType === "league" ? seedingStages[currentStageIndex].league.teams : seedingStages[currentStageIndex].tournament.teams}
            stageSeedings={selectedGroupsTeam}
            updateSeeding={updateSeeding}
            handleRemoveSeeding
          />
        ),
      }));
      setSubTabs(_subtabs);
      // debugger
    }
  };

  const dragStart = (e, position) => {
    // if (Number(currentStageIndex) === 0) {
    //   dragItem.current = position;
    // }
  };

  const dragEnter = (e, position) => {
    // if (Number(currentStageIndex) === 0) {
    //   dragOverItem.current = position;
    // }
  };

  const dropNew = (e) => {
    // if (Number(currentStageIndex) === 0) {
    //   let _teamsArray = [...selectedGroupsTeam];
    //   let dragItemContent = _teamsArray[dragItem.current];
    //   const dragItemIndex = dragItem.current;

    //   let dOverItem = _teamsArray[dragOverItem.current];
    //   const dragOverItemIndex = dragOverItem.current;

    //   let temp = { ...dOverItem };
    //   dOverItem.team = dragItemContent.team;
    //   dragItemContent.team = temp.team;
    //   _teamsArray[dragItemIndex] = dragItemContent;
    //   _teamsArray[dragOverItemIndex] = dOverItem;
    //   dispatch({
    //     type: SEEDING_DATA.UPDATE_STAGE_SEEDING,
    //     stageIndex: currentStageIndex,
    //     seedings: _teamsArray,
    //   });
    //   setSelectedGroupsTeam(_teamsArray);
    //   // setSeedingsWithoutSave(currentStageIndex, _teamsArray);
    //   // fetchSeedingDetailsAutoSaveOnChange(seedingStages[currentStageIndex]._id, seedingStages[currentStageIndex].league.autoFillTeams, _teamsArray, id);
    //   dispatch(resetSeedingUpdate());
    //   let isChanged = dragItemIndex !== dragOverItemIndex;
    //   dispatch(setIsAnyChangeInTab(isChanged));
    //   checkUnSavedSeedingChanges(currentStageIndex, isChanged);
    // }
  };

  const changeTeamHandler = (value, itemId) => {
    // console.log(value, itemId);
    // debugger;
    // console.log('value: ', value);
    // console.log('itemId: ', itemId);
    // // return;
    // debugger;
    const current = value;
    const teamId = itemId.split("-")[1];
    let groupName = '';
    let seed = 0;
    let setIsNoSpot = false;
    if (value.includes('NO SPOT')) {
      setIsNoSpot = true;
    } else {
      groupName = current.split("-")[0].trim();
      seed = parseInt(current.split("-")[1].trim().split(" ")[1]);
    }
    // console.log("selectedGroupsTeam", [...selectedGroupsTeam]);
    // getting the source index
    let sourceIndex = -1;
    if (teamId.startsWith("TBD") || teamId.includes("#+#")) {
      let _srcGrp = teamId.split("#+#")[1];
      let _srcSp = teamId.split("#+#")[2];
      sourceIndex = selectedGroupsTeam.findIndex((x) => x?.group?.name == _srcGrp && x?.seedPosition == _srcSp);
    } else {
      sourceIndex = selectedGroupsTeam.findIndex((x) => x?.team && !x?.isTbd && x?.team?.team?._id == teamId);
    }
    let sourceObject = { ...selectedGroupsTeam[sourceIndex] };

    let _seedings = [...selectedGroupsTeam];


    if (setIsNoSpot) {
      delete sourceObject.group;
      _seedings[sourceIndex] = sourceObject;

      dispatch({
        type: SEEDING_DATA.UPDATE_STAGE_SEEDING,
        stageIndex: currentStageIndex,
        seedings: _seedings,
      });
      setSelectedGroupsTeam(_seedings);
      populateValuesToShow(_seedings, dropDownValues)

      checkUnSavedSeedingChanges(currentStageIndex, true);
      dispatch(setIsAnyChangeInTab(true));
      dispatch(resetSeedingUpdate());

      return;
      // sourceObject.seedPosition = -1;
    }

    //  get destination index
    let destinationIndex = selectedGroupsTeam.findIndex((x) => x.group && x.group.name === groupName && x.seedPosition === seed);
    let destinationObject = { ...selectedGroupsTeam[destinationIndex] };

    if (destinationIndex < 0) {
      // if (teamId.startsWith("TBD") || teamId.includes("#+#")) {
      //   let _srcGrp = teamId.split("#+#")[1];
      //   let _srcSp = teamId.split("#+#")[2];
      //   destinationIndex = selectedGroupsTeam.findIndex((x) => x?.group?.name == _srcGrp && x?.seedPosition == _srcSp);
      // } else {
      //   destinationIndex = selectedGroupsTeam.findIndex((x) => x?.team && !x?.isTbd && x?.team?.team?._id == teamId);
      // }
      destinationObject = { ...selectedGroupsTeam[destinationIndex] }
    }
    // (destinationIndex === sourceIndex) ||
    if (!sourceObject.group) {
      let _group = seedingStages[currentStageIndex].groups.find(grp => grp.name === groupName);
      // delete destinationObject.group;
      if (_group) {
        sourceObject.group = _group;
      }
      if (!sourceObject.seedPosition)
        sourceObject.seedPosition = seed;
      // if (_group.name === groupName) {
      // }
      // _seedings[sourceIndex] = sourceObject;
      // _seedings[destinationIndex] = destinationObject;
    } else if (!destinationObject.group) {
      let _group = seedingStages[currentStageIndex].groups.find(grp => grp.name === groupName);
      // delete sourceObject.group;
      if (_group) {
        destinationObject.group = _group;
      }
      if (destinationIndex < 0) {
        destinationObject.seedPosition = seed;
        destinationObject.isComplete = true;
        // destinationObject.noTeam = true;
      }
      // if (_group.name === groupName) {
      destinationObject.seedPosition = seed;
      // }
      // _seedings[sourceIndex] = sourceObject;
      // _seedings[destinationIndex] = destinationObject;
    }
    // else {
    // swap the objects
    let tempGroup = sourceObject.group;
    let tempSP = sourceObject.seedPosition;

    // assign destination to source
    if (destinationObject.group) {
      sourceObject.group = destinationObject.group;
      sourceObject.seedPosition = destinationObject.seedPosition;
    }
    // assign source to destination
    if (destinationIndex > -1) {
      destinationObject.group = tempGroup;
      destinationObject.seedPosition = tempSP;
    }

    // updating seedings
    _seedings[sourceIndex] = sourceObject;
    if (destinationIndex > -1) {
      _seedings[destinationIndex] = destinationObject;
    }
    // }

    // console.log("selectedGroupsTeam _seedings", [..._seedings]);
    // dispatching the action
    dispatch({
      type: SEEDING_DATA.UPDATE_STAGE_SEEDING,
      stageIndex: currentStageIndex,
      seedings: _seedings,
    });
    setSelectedGroupsTeam(_seedings);
    populateValuesToShow(_seedings, dropDownValues)
    // setSeedingsWithoutSave(currentStageIndex, _seedings);
    // fetchSeedingDetailsAutoSaveOnChange(seedingStages[currentStageIndex]._id, seedingStages[currentStageIndex].league.autoFillTeams, _seedings, id);
    let isChanged = destinationIndex !== sourceIndex;
    checkUnSavedSeedingChanges(currentStageIndex, isChanged);
    dispatch(setIsAnyChangeInTab(isChanged));
    dispatch(resetSeedingUpdate());
  };
  const popoverText = () => {
    let text = '';
    if (currentStageIndex == 0) {
      text = `There are not enough teams in the competition to fill the spots in ${seedingStages[currentStageIndex].name}`
    } else {
      text = `There are not enough teams in ${seedingStages[currentStageIndex - 1].name} to fill the spots in ${seedingStages[currentStageIndex].name}.`
    }
    return text;
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    let _seedingClone = [...selectedGroupsTeam];
    let _newSeedingData = [...selectedGroupsTeam];

    if (startIndex < endIndex) {
      for (let i = startIndex; i <= endIndex; i++) {
        let object = { ..._seedingClone[i] }
        if (i === endIndex) {
          let newTeam = _seedingClone[startIndex].team;
          _newSeedingData[i] = { ...object, team: newTeam }
        } else {
          let newTeam = _seedingClone[i + 1].team;
          _newSeedingData[i] = { ...object, team: newTeam }
        }
      }
    } else if (startIndex > endIndex) {
      for (let i = endIndex; i <= startIndex; i++) {
        let object = { ..._seedingClone[i] }
        if (i === startIndex) {
          let newTeam = _seedingClone[endIndex].team;
          _newSeedingData[i] = { ...object, team: newTeam }
        } else {
          let newTeam = _seedingClone[i + 1].team;
          _newSeedingData[i] = { ...object, team: newTeam }
        }
      }
    };

    dispatch({
      type: SEEDING_DATA.UPDATE_STAGE_SEEDING,
      stageIndex: currentStageIndex,
      seedings: _newSeedingData,
    });
    setSelectedGroupsTeam(_newSeedingData);
    populateValuesToShow(_newSeedingData, dropDownValues);
    // setSeedingsWithoutSave(currentStageIndex, _seedings);
    // fetchSeedingDetailsAutoSaveOnChange(seedingStages[currentStageIndex]._id, seedingStages[currentStageIndex].league.autoFillTeams, _seedings, id);
    let isChanged = true;
    checkUnSavedSeedingChanges(currentStageIndex, isChanged);
    dispatch(setIsAnyChangeInTab(isChanged));
    dispatch(resetSeedingUpdate());
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      valuesToShow,
      result.source.index,
      result.destination.index
    );

    // setValuesToShow(
    //   items
    // );
  }

  const grid = 10;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const handleRemoveSeeding = (groupId, team, seedPosition) => {
    // let selectedGroupTeam = selectedGroupsTeam[index];
    const selectedGroupTeamIndex = selectedGroupsTeam.findIndex((item) => item?.team?.team?._id === team?.team?._id && item.seedPosition === seedPosition && item.group?._id === groupId);
    if (selectedGroupTeamIndex > -1) {
      const selectedGroupTeam = selectedGroupsTeam[selectedGroupTeamIndex];
      // selectedGroupTeam.seedPosition = -1;
      // selectedGroupTeam.noTeam = true;
      delete selectedGroupTeam.group;
      delete selectedGroupTeam.seedPosition;
      let _seedings = [...selectedGroupsTeam];
      _seedings[selectedGroupTeamIndex] = selectedGroupTeam;
      dispatch({
        type: SEEDING_DATA.UPDATE_STAGE_SEEDING,
        stageIndex: currentStageIndex,
        seedings: _seedings,
      });
      setSelectedGroupsTeam(_seedings);
      populateValuesToShow(_seedings, dropDownValues)
      // setSeedingsWithoutSave(currentStageIndex, _seedings);
      // fetchSeedingDetailsAutoSaveOnChange(seedingStages[currentStageIndex]._id, seedingStages[currentStageIndex].league.autoFillTeams, _seedings, id);
      let isChanged = true;
      checkUnSavedSeedingChanges(currentStageIndex, isChanged);
      dispatch(setIsAnyChangeInTab(isChanged));
      dispatch(resetSeedingUpdate());
    }
  }
  if(loadingState) return (
    <div className='mt-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loading/>
    </div>
  )
  return (
    <>
      <Row md={2} style={{ alignItems: 'flex-start' }}>
        <Col sm={5} className="">
          <h2> {seedingStages && seedingStages[currentStageIndex] ? seedingStages[currentStageIndex]?.leaderBoardName : 'SEEDING'}</h2>
          {/* <p className='sub-text'>Drag to change the order of the teams in the left column.</p> */}
          <p className='sub-text'>
            {currentStageIndex === 0 ? FIRST_STAGE_SUB_TEXT : NEXT_STAGE_SUB_TEXT}
          </p>
          {/* Drag the participants in the left column to change their initial seeding for this competition. Dropdowns in the right column determine how participants are placed based on the initial seeding. */}
          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox className='seedingAutoFill' checked={isAutoFill} />}
                onChange={() => {
                  dispatch({
                    type: SEEDING_DATA.TOGGLE_AUTO_FILL,
                    stageIndex: currentStageIndex,
                    isAutoFill: !isAutoFill,
                  });
                  dispatch(setIsAnyChangeInTab(true));
                  setIsAutoFill((state) => !state)
                  checkUnSavedSeedingChanges(currentStageIndex, true);
                }}
                label="AUTOFILL GROUPS"
              />
            </FormGroup>
          </div>
          <div className='seeding-left-col'>
            <Col style={{ display: 'flex', paddingLeft: 0 }}>
              <Col xs="5" style={{ paddingLeft: 0 }}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {
                          valuesToShow.map(({
                            i, itemIndex, noTeam,
                            _id, _teamName, _team,
                            containsTBD_group, whiteLabel,
                            greyedLabel, _teamLabel
                          }, index) => (
                            <Draggable key={_id + index} draggableId={_id + index} index={index}>
                              {(provided, snapshot) => (
                                // <>
                                <Card
                                  onDragStart={(e) => dragStart(e, i)}
                                  onDragEnter={(e) => dragEnter(e, i)}
                                  onDragEnd={dropNew}
                                  // draggable={!isAutoFill}
                                  item-index={itemIndex}
                                  body
                                  className={`compact-card seed-row ${isAutoFill ? "disabled" : ""} ${noTeam ? "no-teams-borderd" : ""}`}
                                  index={0}
                                  key={index}
                                  id={_id}
                                  name={_teamName}
                                  stage_id={seedingStages[currentStageIndex]._id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {noTeam ?
                                    <div className='card-no-teams-container'>
                                      <div className='no-team-name-card'>Not enough teams
                                        <InformationLogo
                                          content={popoverText()}
                                        />
                                      </div>
                                    </div>
                                    : (
                                      <div className="card-teams-container">
                                        <div className='team-name-card'>
                                          #{i + 1}
                                          {_team?.logo && !_teamLabel.includes("TBD") ? <img src={_team?.logo} className='team-icon' />
                                            :
                                            <HiUserGroup size={17}
                                              style={{ marginRight: '10px', marginLeft: '10px' }}
                                              color={"#68717e"}
                                            />}
                                          <p className='team-name'>
                                            {containsTBD_group ? <>{whiteLabel} <span className='greyed-text' style={{ color: '#68717E' }}>{greyedLabel}</span></> : _teamLabel}
                                          </p>
                                        </div>
                                        <FontAwesomeIcon height={"20px"} width="20px" icon={faGripHorizontal} className="wtext-secondary drag-handle flex-end" />
                                      </div>
                                    )}
                                </Card>
                                // </>
                              )}
                            </Draggable>
                          ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Col>
              <Col style={{ paddingLeft: 0, width: '30px', maxWidth: '30px' }}>
                {valuesToShow.map((item, index) => (
                  <Row className='seed-row' key={index}>
                    <HiOutlineArrowNarrowRight size={25} color={'#68717e'} />
                  </Row>
                ))}
              </Col>
              <Col xs="5" style={{ paddingLeft: 0 }}>
                {valuesToShow.map(({
                  i, _teamId, dropdownValue, _groupName, _seedPosition, isNoSpot
                }, index) => (
                  <Row key={_teamId + "ddd" + index} className='seed-row'>
                    <Col className="w250">
                      <SelectCustom
                        value={dropdownValue}
                        options={[...dropDownValues?.map((item, y) => `${item.group.name} - Seed ${item.seedPosition}`), 'NO SPOT']}
                        itemId={"select-" + (_teamId ?? `TBD #+#${_groupName}#+#${_seedPosition}`)}
                        onClick={changeTeamHandler}
                        disabled={isAutoFill}
                        singleGroup={seedingStages[currentStageIndex].groups?.length === 1}
                        noSpot={isNoSpot}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Col>
            {/* <Col style={{ display: 'flex' }}>
              {selectedGroupsTeam
                .map((x, i) => {
                  let isTBD = x.team === "TBD" || x.isTbd;
                  let _team = isTBD ? x.team : x.team && x.team.team ? x.team.team : "TBD";
                  let _teamId = x.team && x.team.team ? x.team?.team?._id : null;
                  let _teamName = isTBD ? _team : (_team && _team.name) ? _team.name : "TBD";
                  // console.log(isTBD, x.isTbd, _team);
                  let _teamLabel = isTBD
                    ? _team.seedLabel
                      ? _team.seedLabel
                      : typeof _team === 'string' ? _team : _team?.name
                    : x.team && x.team.seedLabel
                      ? x.team.seedLabel
                      : typeof _team === 'string' ? _team
                        : _team?.name ? _team.name : "TBD";
                  // _teamLabel = 'Team';
                  let isGroup = x.group !== undefined;
                  let _group = isGroup ? x.group : "";
                  let _groupId = isGroup ? _group._id : "";
                  let _groupName = isGroup ? _group.name : "";
                  let _seedPosition = x?.seedPosition;
                  let dropdownValueObject = dropDownValues.find(
                    (v) => _groupId === v.group._id && x.seedPosition === v.seedPosition
                  );
                  let dropdownValue = "NO SPOT";
                  if (dropdownValueObject && i <= dropDownValues.length - 2) {
                    dropdownValue = `${dropdownValueObject.group.name} - Seed ${dropdownValueObject.seedPosition}`;
                  }
                  let isNoSpot = false;
                  if (dropdownValue === "NO SPOT") {
                    isNoSpot = true;
                  }
                  let whiteLabel = ''
                  let greyedLabel = '';
                  let containsTBD_group = false;
                  let onlyOneGroup = seedingStages[currentStageIndex].prevStageNumberOfGroup;
                  if (_teamLabel.includes('TBD (')) {
                    whiteLabel = 'TBD ';
                    let part2 = _teamLabel.split('TBD (')[1];
                    greyedLabel = `(${part2}`;
                    if (onlyOneGroup === 1) {
                      let part3 = part2.split(' - ')[1];
                      greyedLabel = `(${part3}`;
                    }
                    containsTBD_group = true;
                  }
                  let noTeam = false;
                  let prevStageTotalTeams = seedingStages[currentStageIndex].prevStageTotalTeams;
                  if (i >= prevStageTotalTeams) {
                    noTeam = true;
                  }

                  return (
                    // <Row className='seed-row'>
                    <>
                      <Col>
                        <Card
                          onDragStart={(e) => dragStart(e, i)}
                          onDragEnter={(e) => dragEnter(e, i)}
                          onDragEnd={dropNew}
                          draggable={!isAutoFill}
                          item-index={isTBD ? _team : _teamId}
                          body
                          className={`compact-card ${isAutoFill ? "disabled" : ""} ${noTeam ? "no-teams-borderd" : ""}`}
                          index={0}
                          key={i}
                          id={isTBD ? (_team.seedLabel ? "TBD" : _team) : _team._id ? _team._id : "TBD"}
                          name={_teamName}
                          stage_id={currentStage._id}
                        >
                          {noTeam ?
                            <div className='card-no-teams-container'>
                              <div className='no-team-name-card'>Not enough teams
                                <InformationLogo
                                  content={popoverText()}
                                />
                              </div>
                            </div>
                            :
                            (<div className="card-teams-container">
                              <div className='team-name-card'>
                                #{i + 1}
                                {_team?.logo && !_teamLabel.includes("TBD") ? <img src={_team?.logo} className='team-icon' />
                                  :
                                  <HiUserGroup size={17}
                                    style={{ marginRight: '10px', marginLeft: '10px' }}
                                    color={"#68717e"}
                                  />}
                                <p className='team-name'>
                                  {containsTBD_group ? <>{whiteLabel} <span className='greyed-text' style={{ color: '#68717E' }}>{greyedLabel}</span></> : _teamLabel}
                                </p>
                              </div>
                              <FontAwesomeIcon size={20} icon={faGripHorizontal} className="wtext-secondary drag-handle flex-end" />
                            </div>
                            )}
                        </Card>
                      </Col>
                      <HiOutlineArrowNarrowRight size={25} color={'#68717e'} />
                      <Col className="w250">
                        <SelectCustom
                          value={dropdownValue}
                          options={[...dropDownValues?.map((item, y) => `${item.group.name} - Seed ${item.seedPosition}`), 'NO SPOT']}
                          itemId={"select-" + (_teamId ?? `TBD #+#${_groupName}#+#${_seedPosition}`)}
                          onClick={changeTeamHandler}
                          disabled={isAutoFill}
                          singleGroup={seedingStages[currentStageIndex].groups?.length === 1}
                          noSpot={isNoSpot}
                        />
                      </Col>
                    </>
                  )
                })}
            </Col> */}
          </div>
        </Col>
        <Col className="distribution-main-container">
          <h2>DISTRIBUTION {seedingStages[currentStageIndex] ? `(${seedingStages[currentStageIndex].name})` : ''}</h2>
          <p className='sub-text' style={{
            height: seedingStages[currentStageIndex] && seedingStages[currentStageIndex]?.groups?.length > 3 ? '35px' : '57px'
          }}>Drag and drop teams from the lest side to the desired seed inside each group.</p>
          <div className='groups-dd-container'>
            {seedingStages[currentStageIndex] && seedingStages[currentStageIndex].groups ?
              seedingStages[currentStageIndex].groups?.length > 3 ?
                (
                  <div style={{ width: '150px' }}>
                    <select
                      onChange={handleSelectedGroup}
                      id="birthdayMonth"
                      name="group"
                      className={'form-input black-bg'}
                      // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
                      defaultValue={currentGroup}>
                      <option value={0}>
                        All Groups
                      </option>
                      {seedingStages[currentStageIndex].groups.map((group, i) => (
                        <option key={group._id} value={i + 1}>{group.name}</option>
                      ))}
                    </select>
                  </div>
                )
                : seedingStages[currentStageIndex].groups.length > 1 ?
                  <SimpleTabs
                    styles={{ height: "42px" }}
                    pages={[
                      'All Groups',
                      ...(
                        seedingStages[currentStageIndex] ?
                          seedingStages[currentStageIndex].groups?.map((group, i) => (group.name))
                          : []
                      )
                    ]}
                    page={activeTab} setPage={(a) => {
                      setActiveTab(a)
                    }}
                  /> : <div style={{ height: '20px', width: '10px' }} />
              : null}

          </div>
          <div className='distributions'>
            <div className='dist-container'>
              {/* <Row> */}
              {/* Group 1 */}
              <div>
                {seedingStages[currentStageIndex] &&
                  seedingStages[currentStageIndex].groups &&
                  seedingStages[currentStageIndex].groups.length > 0 && (
                    <Group
                      key={seedingStages[currentStageIndex]?._id + "www"}
                      groupId={
                        activeTab === 0 ? 0 :
                          seedingStages[currentStageIndex].groups &&
                            seedingStages[currentStageIndex].groups.length >= 1 &&
                            seedingStages[currentStageIndex].groups[activeTab - 1]
                            ? seedingStages[currentStageIndex].groups[activeTab - 1]._id
                            : 0}
                      allGroup={(activeTab == 0 || activeTab === undefined) && seedingStages[currentStageIndex].groups.length !== 1 ? true : false}
                      groups={seedingStages[currentStageIndex].groups}
                      seeds={seeds.filter((x) => x.stage_id === currentStage._id)}
                      totalSeeds={totalSeeds}
                      teams={teams}
                      stageSeedings={selectedGroupsTeam}
                      updateSeeding={updateSeeding}
                      onlySingleGroup={
                        seedingStages[currentStageIndex] &&
                        seedingStages[currentStageIndex].groups &&
                        seedingStages[currentStageIndex].groups?.length === 1}
                      handleRemoveSeeding={handleRemoveSeeding}
                    />
                  )}
                {/* {subTabs !== undefined &&
                  subTabs.map(
                    ({ key, content }) =>
                      key === activeTab && (
                        <div key={key} className="subtabs-content">
                          {content}
                        </div>
                      )
                  )} */}
              </div>
              {/* </Row> */}
            </div>
          </div>
        </Col>
      </Row >
      <Row md={2} style={{ alignItems: 'flex-start' }}>
        <Col className="">
          <button className="save-seeding-primary-btn" onClick={() => {
            // fetchSeedingDetailsWithSave(seedingStages[currentStageIndex]._id, isAutoFill, id);
            saveSeedingDetailsForAll(id);
          }}>
            {"SAVE CHANGES"}
            {/* saveLoading ? <Loading /> : */}
          </button>
        </Col>
      </Row>
    </>
  )
};

export default SeedingContainer;