import React from 'react';
import { SimpleTabs } from '../../_common';
const GroupTabs = ({ groupsName, setGroupIndex, groupIndex }) => {
  return (
    <div className="subTab">
      {groupsName.length > 1 ? (
        <SimpleTabs pages={groupsName} page={groupIndex} setPage={setGroupIndex} />
      ) : null}
    </div>
  );
};
export default GroupTabs;
