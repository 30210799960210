import React, { useContext, useEffect, useState } from 'react';
import Dropdown from '../../../Layout/Header/Dropdown/Dropdown';
import List from './List';
import './styles.scss';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterMatches } from '../../../../redux/action/CompetitonData';

const GroupBy = () => {
  const group = [
     { label: 'GROUP BY', value: '' },
    { label: 'NONE', value: 'none' },
    {
      label: 'DATE',
      value: 'date'
    },
    {
      label: 'ROUND',
      value: 'round'
    },
    {
      label: 'WEEK',
      value: 'week'
    }
  ];
  const dispatch=useDispatch()
  const [groupBy, setGroupby] = useState();
  const { register, errors } = useForm();
  const filterQuery = useSelector((state) => state?.COMPETITION?.filterMatches);

  useEffect(()=>{
    console.log(groupBy)

    dispatch(
      getFilterMatches({
        ...filterQuery,
        groupBy:groupBy
      })
    );
  },[groupBy])
  return (
    <>
        <div className="form-row" style={{ width: '150px' }}>
          <select
            ref={register({
              validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
            })}
            id="region"
            name="region"
            className={`form-input ${errors.timezone ? 'invalid' : ''}`}
            value={groupBy}
            onChange={(e) => {
              setGroupby(e.target.value);
            }}>
            {group.map(({ label, value }) => (
              <option  value={value}>{label}</option>
            ))}
          </select>
          {errors.timezone && <div className="form-input-error">{errors.timezone.message}</div>}
        </div>
    </>
  );
};

export default GroupBy;
