import React, { useState } from 'react';
import Axios from 'axios';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { localeData } from 'moment';
import { useEffect } from 'react';

const SetPlayers = ({
  onClose,
  teamData = {},
  teamSelector,
  match,
  updateMatches = ()=>{},
  flag,
  isScrim,
  game = null,
  updateMatchRecords = ()=>{}
}) => {
  useEffect(() => {
    console.log('aa updateMatchRecords', updateMatchRecords);
  }, [updateMatchRecords]);
  useEffect(() => {
    console.log('aa game', game);
  }, [game]);
  useEffect(() => {
    console.log('aa isScrim', isScrim);
  }, [isScrim]);
  useEffect(() => {
    console.log('aa flag)', flag);
  }, [flag]);
  useEffect(() => {
    console.log('aa   upda', updateMatches);
  }, [updateMatches]);
  useEffect(() => {
    console.log('aa   matc', match);
  }, [match]);
  useEffect(() => {
    console.log('aa  teamS', teamSelector);
  }, [teamSelector]);
  useEffect(() => {
    console.log('aa  teamD', teamData);
  }, [teamData]);
  const [loading, setLoading] = useState(false);
  const { _id } = match;
  const data = flag ? match.csgoData : match.lolData;
  const [gameData, setGameData] = useState(game);
  const [oldTeamData, setOldTeamData] = useState(
    flag ? match.csgoData.players[teamSelector] : match.lolData.players[teamSelector]
  );
  const [selectedTeamData, setSelectedTeamData] = useState(teamData);
  const [roles, setRoles] = useState(
    game?.configuration?.playerTypes.map(({ name, optional }) => {
      const existingSetPlayer =
        data?.players &&
        (data?.players[teamSelector] || []).find(({ role = '' }) => role.includes(name));
      return {
        role: name,
        player: existingSetPlayer ? existingSetPlayer.player : false,
        optional: optional ? true : false
      };
    })
  );

  const getPlayersData = async () => {
    const playerIds = data?.players[teamSelector]?.map(({ player }) => player);
    const { data: respData } = await Axios.post(
      `${process.env.REACT_APP_CORE_API}/api/players/getMultiPlayer`,
      {
        playerIds
      }
    );
    const teamPlayers = data?.players[teamSelector];
    const result = respData.map((player) => {
      const _player = teamPlayers.find((_teamPlayer) => _teamPlayer.player === player._id);
      return { ..._player, role: `Player:${_player.role}`, player };
    });
    setSelectedTeamData((prevState) => ({ ...prevState, members: result }));
  };

  useEffect(() => {
    if (!selectedTeamData?.members?.length && data?.players[teamSelector]?.length) {
      getPlayersData();
    }
  }, []);

  const isAllPlayersSet = () => {
    const requiredRoles = roles?.filter(({ optional }) => !optional);

    return requiredRoles?.filter(({ player }) => player).length >= requiredRoles?.length;
  };

  const onUpdatePlayer = (roleName, newPlayerId) => {
    console.log('roleName, newPlayerId', roleName, newPlayerId, roles);
    setRoles((roles) =>
      roles?.map((role) => (role.role === roleName ? { ...role, player: newPlayerId } : role))
    );
  };

  const onSetPlayers = async () => {
    if (!isAllPlayersSet() && !flag) return false;
    setLoading(true);

    try {
      let data;
      if (flag) {
        data = await Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${_id}/setPlayers`, {
          roles,
          teamSelector
        });
      } else {
        data = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/matches/${_id}/setPlayers`, {
          roles,
          teamSelector
        });
      }
      if (isScrim) {
        updateMatches(_id, data.data.newMatch);
      } else {
        updateMatches((matches) => matches.map((m) => (m._id === _id ? data.data.newMatch : m)));
      }
      setLoading(false);
      toast.success('Successfully set players for ' + selectedTeamData.name);
      updateMatchRecords();
      onClose();
    } catch (e) {
      console.log(e)
      toast.error('Could not set players for this match');
      setLoading(false);
    }
  };

  const onSetDefaultPlayers = async () => {
    if (roles && roles?.length > 0) {
      roles.map(
        (singleRole) =>
          selectedTeamData?.members?.length > 0 &&
          selectedTeamData?.members?.filter(({ player = null, role }) => {
            if (role?.includes(singleRole.role) && player) {
              console.log('roleName, newPlayerId', singleRole.role, player);

              onUpdatePlayer(singleRole.role, player._id);
            }
          })
      );
    }
  };
  const getOldPlayerName = (role) => {
    return oldTeamData.find((el) => el.role === role)?.player?.name;
  };
  const checkSameTeam = (player) => {
    if (player) {
      const check = roles.find((role) => role.player === player.player);
      return Boolean(check);
    }
  };

  return (
    <div>
      {roles
        ?.filter(({ role }) => role.toLowerCase() !== 'sub')
        .map(({ icon, role, player, optional }, index) => (
          <Form.Group key={role} style={{margin:'10px 0 10px'}}>
            <Form.Label style={{font:'normal normal bold 12px/15px Montserrat'}}>
              {game?.shortName == 'CSGO' ? 'Player ' + (index + 1) : role}
            </Form.Label>
            <select
              id="region"
              name="region"
              className={`form-input`}
              value={(player?._id ? player._id : player) || '_DEFAULT_'}
              onChange={(e) => onUpdatePlayer(role, e.target.value)}>
              {!flag ? (
                oldTeamData?.length > 0 ? (
                  <>
                    <option value="_DEFAULT_" disabled={optional || oldTeamData?.length > 0}>
                      {oldTeamData?.length > 0
                        ? getOldPlayerName(role)
                        : optional
                        ? 'No substitute'
                        : 'No Player Selected'}
                    </option>
                    {checkSameTeam(oldTeamData[index]) && (
                      <option value="_DEFAULT_">
                        {optional ? 'No substitute' : 'No Player Selected'}
                      </option>
                    )}
                  </>
                ) : (
                  <option value="_DEFAULT_" disabled={optional || oldTeamData?.length > 0}>
                    {optional ? 'No substitute' : 'No Player Selected'}
                  </option>
                )
              ) : (
                <option value="_DEFAULT_">
                  {optional ? 'No substitute' : 'No Player Selected'}
                </option>
              )}

              {selectedTeamData?.members?.length > 0 &&
                selectedTeamData?.members
                  .filter(({ role = '' }) => role?.includes('Player'))
                  .map(
                    ({ player }) => player && <option value={player._id}>{player?.name}</option>
                  )}
            </select>
          </Form.Group>
        ))}
        <div style={{display:'flex',justifyContent:'flex-end'}}>
      <Button
        onClick={onSetPlayers}
        style={{marginRight:'5px',width:'25%'}}
        variant="success"
        className="button primary"
        disabled={(loading || !isAllPlayersSet()) && !flag}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>{' '}
      <Button
        onClick={onSetDefaultPlayers}
        style={{width:'45%'}}
        variant="success"
        className="button primary"
        disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Set Default Players'}
      </Button>
      </div>
    </div>
  );
};

export default SetPlayers;
