import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { CheckboxSlider } from '../../../../_common';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
const Publishing = ({ setAllInformationData, allInformationData, socketRef,setInformationCall ,  setUnSavedStages,
  selectedTab}) => {
  const [visible, setVisible] = useState({
    Public: false,
    Unlisted: false,
    Private: false,
    Scheduled: false
  });
  const date = moment(Date.now() + 7 * 24 * 3600 * 1000);
  const [visibility, setVisibility] = useState('');
  const [datetime, setdatetime] = useState();
  const [publishDate, setPublishDate] = useState(date.format('YYYY-MM-DD'));
  const [publishTime, setPublishTime] = useState('18:00');
  const { register, handleSubmit, errors } = useForm();
  // const organizerdata = useSelector((state) => state.COMPETITION.informationData.multiStageLeague);
  const organizerdata = useSelector((state) => state.COMPETITION.Competition.multiStageLeague);
  useEffect(() => {
    if (socketRef.current && organizerdata?._id) {
      socketRef.current.emit('changeVisibility', {
        roomId: organizerdata?._id,
        status: visibility==='Scheduled'? visibility+' '+(moment(publishDate).format('Do of MMMM YYYY')).toString() : visibility
      });
    }
  }, [visibility,publishDate]);
  useEffect(() => {
    if (organizerdata?.visibility === 'Scheduled') {
      setVisible({ Public: false, Unlisted: false, Private: false, Scheduled: true });
    }
    if (organizerdata?.visibility === 'Public') {
      setVisible({ Public: true, Unlisted: false, Private: false, Scheduled: false });
    }
    if (organizerdata?.visibility === 'Unlisted') {
      setVisible({ Public: false, Unlisted: true, Private: false, Scheduled: false });
    }
    if (organizerdata?.visibility === 'Private') {
      setVisible({ Public: false, Unlisted: false, Private: true, Scheduled: false });
    }
  }, [organizerdata]);
  useEffect(() => {
    if (visible.Public === true) {
      setVisibility('Public');
    }
    if (visible.Unlisted === true) {
      setVisibility('Unlisted');
    }
    if (visible.Private === true) {
      setVisibility('Private');
    }
    if (visible.Scheduled === true) {
      setVisibility('Scheduled');
    }
  }, [visible]);
  useEffect(() => {
    setdatetime(publishDate + publishTime);
  }, [publishDate, publishTime]);
  useEffect(() => {
    if (visibility) {
      if (
        moment(datetime).format('DD/MM/YYYY HH:mm:ss').valueOf() <=
          moment().format('DD/MM/YYYY HH:mm:ss').valueOf() ||
        !datetime
      ) {
        toast.error('Invalid Time');
        return;
      }
    }
    setAllInformationData((state) => ({ ...state, publishDate: datetime, visibility }));
  }, [datetime, visibility]);
  const hendleChange = () => {
    setUnSavedStages((state) => state.filter((e)=>e!=selectedTab));
    setInformationCall(allInformationData)
  };
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '50%' }}>
          <h2>visibility</h2>
          <Col>
            <Form.Group>
              <CheckboxSlider
                label={'Public'}
                value={visible?.Public}
                onChange={(e) =>{
                  setVisible({ Public: true, Unlisted: false, Private: false, Scheduled: false });
                  setUnSavedStages((state) => [...state, selectedTab]);
                }
                }
                explenamtion={
                  <strong style={{ color: '#68717e', fontWeight: '100' }}>
                    Players and teams can find your competition on Leagues.gg.
                  </strong>
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <CheckboxSlider
                label={'Unlisted'}
                value={visible?.Unlisted}
                onChange={(e) =>{
                  setVisible({ Public: false, Unlisted: true, Private: false, Scheduled: false })
                  setUnSavedStages((state) => [...state, selectedTab]);
                }
                }
                explenamtion={
                  <strong style={{ color: '#68717e', fontWeight: '100' }}>
                    Unlisted competitions are hidden from other users and can only be accessed using
                    a direct link.
                  </strong>
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <CheckboxSlider
                label={'Private'}
                value={visible?.Private}
                onChange={(e) =>{
                  setVisible({ Public: false, Unlisted: false, Private: true, Scheduled: false })
                  setUnSavedStages((state) => [...state, selectedTab]);
                }
                }
                explenamtion={
                  <strong style={{ color: '#68717e', fontWeight: '100' }}>
                    Only users with permissions for this competition can view it.{' '}
                  </strong>
                }
              />
            </Form.Group>
          </Col>{' '}
          <Col>
            <Form.Group>
              <CheckboxSlider
                label={'Scheduled'}
                value={visible?.Scheduled}
                onChange={(e) =>{
                  setVisible({ Public: false, Unlisted: false, Private: false, Scheduled: true })
                  setUnSavedStages((state) => [...state, selectedTab]);
                }
                }
                explenamtion={
                  <strong style={{ color: '#68717e', fontWeight: '100' }}>
                    Choose a time for the competition to go public. The competition will be private
                    until the scheduled time.{' '}
                  </strong>
                }
              />
            </Form.Group>
          </Col>
        </div>
        {visible?.Scheduled === true ? (
          <div style={{ width: '50%' }}>
            <h2>PUBLISH ON</h2>
            <Row>
              <Col style={{ width: '60%' }}>
                <Form.Group>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                    value={`${publishDate}`}
                    onChange={(e) => {
                      setPublishDate(e.target.value);
                      setUnSavedStages((state) => [...state, selectedTab]);
                    }}
                    style={{border:'1px solid #22262e'}}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate && errors.startDate.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col style={{ width: '40%' }}>
                <Form.Group>
                  <input
                    type="time"
                    id="startDate"
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                    // timeFormat="HH:mm:ss"
                    value={`${publishTime}`}
                    my-date-format="DD/MM/YYYY, hh:mm:ss"
                    onChange={(e) => {
                      setPublishTime(e.target.value);
                      setUnSavedStages((state) => [...state, selectedTab]);
                    }}
                    style={{border:'1px solid #22262e'}}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate && errors.startDate.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
      <button className="button primary" onClick={hendleChange}>
        Save Changes
      </button>
    </>
  );
};
export default Publishing;
