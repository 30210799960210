import React, { useContext, useState, useEffect } from 'react';
import { Loading } from '@common';
import Dropdown from './../Dropdown/Dropdown';
import { AppContext } from '../../../../context/AppContext';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import useTranslated from '../../../../helpers/translationHelper';
const GameSwitcherLink = ({ icon, name, onClick }) => {
  return (
    <div onClick={onClick} className="game-switcher-link text-12 bold pt-2 pb-2">
      <div className="icon" style={{ backgroundImage: `url(${icon})` }} />
      {name}
    </div>
  );
};

const GameSwitcher = ({ game, setGame }) => {
  const { user, selectedGame, setSelectedGame } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  let usertranslate = useTranslated('id_top_bar_1');
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      // console.log('games', data);
      setGames(() => {
        return data.filter((game) => game.name !== 'No Game');
      });
      // setGames(data);
      setLoading(false);
    });
  }, []);

  // const onSwitch = (game) => {
  //   setSelectedGame(game);
  //   window.location.reload();
  // };
  const onSwitch = (game) => {

    let session = localStorage.getItem("sessionToken");
    switch (game.name) {
      case "League of Legends":
        if (session) window.location.href = `${process.env.REACT_APP_LOL_URL}?lst=${session}&shortName=LOL`;
        else window.location.href = `${process.env.REACT_APP_LOL_URL}?shortName=LOL`;
        break;
      case "Valorant":
        if (session) window.location.href = `${process.env.REACT_APP_VALORANT_URL}?lst=${session}&shortName=VL`;
        else window.location.href = `${process.env.REACT_APP_VALORANT_URL}?shortName=VL`;
        break;
      case "CS:GO":
        // console.log(`${process.env.REACT_APP_CSGO_URL}?lst=${session}`);
        if (session) window.location.href = `${process.env.REACT_APP_CSGO_URL}?lst=${session}&shortName=CSGO`;
        else window.location.href = `${process.env.REACT_APP_CSGO_URL}?shortName=CSGO`;
        break;
      //  case "Organizer":
       
      //     if (session) window.location.href = `https://test-organizer.leagues.gg/?lst=${session}`;
      //     else window.location.href = `https://test-organizer.leagues.gg/`;
      //     break;
      default:
        setSelectedGame(game);
        window.location.reload();
        break;
    }
  };
  return (
    <Dropdown
      games={games && games.length}
      isGameSwitcher={true}
      image={selectedGame && '@img/hiChip.svg'}
      label={selectedGame && selectedGame.shortName}
      showCaret
      dynamic_width
      className="header-game-switcher"
      render={(open, setOpen) =>
        loading ? (
          <Loading width={20} height={20} className="loaderForGamesSelection" />
        ) : (
          <>
            {/* <Collapsible transitionTime={100} classParentString="collapsible-wrapper"> */}
            <div className="game-switcher-link text-12 bold pt-2 pb-2">
              <div className="icon" />
              <Link to={'/browse-games'} className={'primary-link'}>
                {usertranslate}
              </Link>
            </div>
            {games.map((game, i) => (
              <GameSwitcherLink
                key={i}
                icon={game.whiteIcon}
                name={game.name}
                onClick={() => {
                  // if (game.name === 'Valorant') {
                  //   window.open(process.env.REACT_APP_VALORANT_URL, '_blank');
                  // } else {
                    onSwitch(game);
                    setOpen(false);
                  // }
                }}
              />
            ))}
            {/* </Collapsible> */}
          </>
        )
      }
    />
  );
};

export default GameSwitcher;
