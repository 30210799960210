export const bracketOutcomes = [
  { bestOf: 1, outcomes: [{ score: '1-0', winnerPoints: 1, loserPoints: 0 }] },
  {
    bestOf: 2,
    outcomes: [
      { score: '2-0', winnerPoints: 2, loserPoints: 0 },
      { score: '1-1', winnerPoints: 1, loserPoints: 1 }
    ]
  },
  {
    bestOf: 3,
    outcomes: [
      { score: '2-0', winnerPoints: 2, loserPoints: 0 },
      { score: '2-1', winnerPoints: 2, loserPoints: 1 }
    ]
  },
  {
    bestOf: 4,
    outcomes: [
      { score: '3-0', winnerPoints: 3, loserPoints: 0 },
      { score: '3-1', winnerPoints: 3, loserPoints: 1 },
      { score: '2-2', winnerPoints: 2, loserPoints: 2 }
    ]
  },
  {
    bestOf: 5,
    outcomes: [
      { score: '3-0', winnerPoints: 3, loserPoints: 0 },
      { score: '3-1', winnerPoints: 3, loserPoints: 1 },
      { score: '3-2', winnerPoints: 3, loserPoints: 2 }
    ]
  },
  {
    bestOf: 6,
    outcomes: [
      { score: '4-0', winnerPoints: 4, loserPoints: 0 },
      { score: '4-1', winnerPoints: 4, loserPoints: 1 },
      { score: '4-2', winnerPoints: 4, loserPoints: 2 },
      { score: '3-3', winnerPoints: 3, loserPoints: 3 }
    ]
  },
  {
    bestOf: 7,
    outcomes: [
      { score: '4-0', winnerPoints: 4, loserPoints: 0 },
      { score: '4-1', winnerPoints: 4, loserPoints: 1 },
      { score: '4-2', winnerPoints: 4, loserPoints: 2 },
      { score: '4-3', winnerPoints: 4, loserPoints: 3 }
    ]
  },
  {
    bestOf: 8,
    outcomes: [
      { score: '5-0', winnerPoints: 5, loserPoints: 0 },
      { score: '5-1', winnerPoints: 5, loserPoints: 1 },
      { score: '5-2', winnerPoints: 5, loserPoints: 2 },
      { score: '5-3', winnerPoints: 5, loserPoints: 3 },
      { score: '4-4', winnerPoints: 4, loserPoints: 4 }
    ]
  },
  {
    bestOf: 9,
    outcomes: [
      { score: '5-0', winnerPoints: 5, loserPoints: 0 },
      { score: '5-1', winnerPoints: 5, loserPoints: 1 },
      { score: '5-2', winnerPoints: 5, loserPoints: 2 },
      { score: '5-3', winnerPoints: 5, loserPoints: 3 },
      { score: '5-4', winnerPoints: 5, loserPoints: 4 }
    ]
  }
];
export const roundRobinOutcomes = [
  { bestOf: 1, outcomes: [{ score: '1-0', winnerPoints: 1, loserPoints: 0 }] },
  {
    bestOf: 3,
    outcomes: [
      { score: '2-0', winnerPoints: 2, loserPoints: 0 },
      { score: '2-1', winnerPoints: 2, loserPoints: 1 }
    ]
  },
  {
    bestOf: 5,
    outcomes: [
      { score: '3-0', winnerPoints: 3, loserPoints: 0 },
      { score: '3-1', winnerPoints: 3, loserPoints: 1 },
      { score: '3-2', winnerPoints: 3, loserPoints: 2 }
    ]
  },
  {
    bestOf: 7,
    outcomes: [
      { score: '4-0', winnerPoints: 4, loserPoints: 0 },
      { score: '4-1', winnerPoints: 4, loserPoints: 1 },
      { score: '4-2', winnerPoints: 4, loserPoints: 2 },
      { score: '4-3', winnerPoints: 4, loserPoints: 3 }
    ]
  },
  {
    bestOf: 9,
    outcomes: [
      { score: '5-0', winnerPoints: 5, loserPoints: 0 },
      { score: '5-1', winnerPoints: 5, loserPoints: 1 },
      { score: '5-2', winnerPoints: 5, loserPoints: 2 },
      { score: '5-3', winnerPoints: 5, loserPoints: 3 },
      { score: '5-4', winnerPoints: 5, loserPoints: 4 }
    ]
  }
];
