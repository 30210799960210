import React, { useState, createContext, useEffect } from 'react';
import Axios from 'axios';
import './_delete.scss';
import Popup_modals from '../modals/Popup_modals';
import { useDispatch } from 'react-redux';
import { getTrash } from '../../../../redux/action/trashActions';
import { HiX } from 'react-icons/hi';
const backendServerURL = process.env.REACT_APP_CORE_API;

const Component = ({ onClose, item, type, namefolder, isdoubbleclick,handleClearSelection }) => {
  const dispatch = useDispatch();
  const deleteOrganizaion = (type, id) => {
    let payload = {
      Organizers: [],
      Folders: [],
      Competitions: []
    };
    // debugger;
    let url = backendServerURL + '/api/organizer/trash/delete/forever'
    if (type === 'FOLDER') {
      payload.Folders.push(id);
    } else if (type === 'ORGANIZER') {
      payload.Organizers.push(id);
    } else if (type === 'COMPETITION') {
      payload.Competitions.push(id);
    }else if(type === "MULTIPLE"){
      payload = item
    }
    Axios.delete(url, { data: payload })
      .then((res) => {
        onClose(false);
        dispatch(getTrash());
        handleClearSelection([])
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="newfolder-main">
      <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 style={{ marginTop: '20px' }}>
          {isdoubbleclick === true ? 'This is in your trash' : 'DELETE FOREVER ?'}
        </h2>
        <HiX onClick={() => {
            onClose(false);
          }}
          style={{marginTop: '18px',marginRight: '14px',fontSize: '25px',color:'grey'}}/>
      </div>
      <div className="modal-body">
        <div className="organizer-delete-text" style={{ color: ' #68717e' }}>
          {isdoubbleclick === true
            ? "To view this you'll need to restore it from your trash."
            : `${namefolder} will be deleted forever and it won’t be possible to restore it.`}
        </div>

        <div className="newfolder-buttons">
          <button
            className="button bordered medium ml-3 mr-3"
            onClick={() => {
              onClose(false);
            }}>
            Cancel
          </button>
          <button
            className="button primary medium"
            onClick={(e) => deleteOrganizaion(type, item?._id ?? item)}>
            Delete Forever
          </button>
        </div>
      </div>
    </div>
  );
};
const DeleteItemsForever = ({
  open,
  setDeleteforeveropen,
  item,
  type,
  folderName,
  isdoubbleclick,
  setSelectedItems
}) => {
  return (
    <Popup_modals
      open={open}
      folderName={folderName}
      onClose={setDeleteforeveropen}
      item={item}
      type={type}
      Component={Component}
      isdoubbleclick={isdoubbleclick}
      handleClearSelection={setSelectedItems}></Popup_modals>
  );
};
export default DeleteItemsForever;
