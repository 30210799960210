import React, { useRef, useState, useEffect } from 'react';

const SeedingSimpleTabs = ({ page, setPage, pages, savedStatus, unSaveCount }) => {
  const [newPages, setNewPages] = useState([]);
  const [dropDownPages, setDropDownPages] = useState([]);
  const [render1, setRender1] = useState(0);
  useEffect(() => {
    if (pages.length > 3) {
      const [a, b, ...rest] = pages;
      setNewPages([a, b]);
      setDropDownPages([...rest]);
    } else {
      setNewPages(pages);
    }
  }, [pages]);
  const containerRef = useRef(null);
  const elemRef = useRef(null);
  // const [isScrollable, setIsScrollable] = useState(false);
  // useEffect(() => {
  //   setIsScrollable(containerRef.current.scrollWidth > containerRef.current.clientWidth);
  // });

  useEffect(() => {
    setRender1((render) => render + 1);
  }, [unSaveCount]);

  return (
    <div className="tabs-container simple__tabs__container" style={{ height: '35px' }}>
      <div ref={containerRef} className="tabs-nav mt-4 sub-tabs" style={{ background: 'none' }}>
        <nav ref={elemRef}>
          {/* {newPages.map((item, index) => ( */}
          {pages.map((item, index) => (
            <button
              className={`${page === index ? 'active' : ''} ${savedStatus[index] ? 'unsaved' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                setPage(index);
              }}
              key={index}>
              {`${item}${savedStatus[index] ? ' *' : ''}`}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default SeedingSimpleTabs;
