import React from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from 'react-toastify';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import useTranslated from '../../../helpers/translationHelper';
import { useEffect, createRef } from 'react';
const GenerateCodes = ({ open, onClose, matchData }) => {
  const elRefs = React.useRef([]);
  useEffect(() => {
    let tournamentCodes = matchData?.lolData?.tournamentCodes
      ? matchData?.lolData?.tournamentCodes
      : [];
    if (elRefs.current.length !== tournamentCodes.length) {
      // add or remove refs
      elRefs.current = Array(tournamentCodes.length)
        .fill()
        .map((_, i) => elRefs.current[i] || createRef());
    }
  }, [matchData]);
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="modal"
      contentLabel="Example Modal"
      shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Tournament Codes</h2>
      </div>
      <div className="modal-body">
        <div className="tournaments-codes-row" style={{ display: 'block !important' }}>
          {matchData?.lolData &&
            !!matchData?.lolData?.tournamentCodes.length &&
            matchData?.lolData?.tournamentCodes.map((code, i) => (
              <Form.Group key={i}>
                <Form.Label className="d-block text-secondary">
                  <small>Map {i + 1}:</small>
                </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    ref={elRefs.current[i]}
                    readOnly
                    style={{ fontFamily: 'monospace', fontSize: '12px' }}
                    size="sm"
                    type="text"
                    value={code}
                  />
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Copy</Tooltip>}>
                    <Button
                      variant="link"
                      size="sm"
                      className="text-primary"
                      onClick={() => {
                        navigator.clipboard.writeText(elRefs?.current[i]?.current?.value);
                        toast.success('Copied to clipboard ✅');
                      }}>
                      <FontAwesomeIcon icon={faCopy} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default GenerateCodes;
