import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function Outcomes({ scoreSystem, currentFormat, stage, setStageSettings,isMatchesGenerated }) {

  const handleScoreChange = (e, score, index) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value < -100 || value > 100) {
      return;
    }
    setStageSettings((state) => {
      let newState = JSON.parse(JSON.stringify(state)); // FOR RENDERING CHANGES ON STATE UPDATE
      let systemInd;
      switch (name) {
        case 'winnerPoints':
          systemInd = newState.scoreSystem.findIndex((el, ind) => ind === currentFormat);
          newState.scoreSystem[systemInd].outcomes.map((el) => {
            if (el.score === score) {
              el.winnerPoints = Number(value);
            }
          });
          return newState;
        case 'loserPoints':
          systemInd = newState.scoreSystem.findIndex((el, ind) => ind === currentFormat);
          newState.scoreSystem[systemInd].outcomes.map((el) => {
            if (el.score === score) {
              el.loserPoints = Number(value);
            }
          });
          return newState;
        default:
          return state;
      }
    });
  };
  const styles = {
    outcomes: {
      width: '73px',
      height: '15px',
      /* UI Properties */
      textAlign: 'left',
      font: 'normal normal bold 12px/15px Montserrat',
      letterSpacing: '0px',
      color: '#FFFFFF',
      opacity: 1
    },
    score: {
      width: '70px',
      height: '44px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      /* UI Properties */
      background: '#12141A 0% 0% no-repeat padding-box',
      border: '1px solid #22262E',
      borderRadius: '2px',
      opacity: 1
    },
    pointsText: {
      width: '22px',
      height: '13px',
      /* UI Properties */
      textAlign: 'left',
      font: 'normal normal bold 10px/13px Montserrat',
      letterSpacing: '0px',
      color: '#FFFFFF',
      textTransform: 'uppercase',
      opacity: 0.6
    },
    points: {
      width: '100px',
      height: '31px',
      /* UI Properties */
      border: '1px solid #22262E',
      borderRadius: '2px',
      opacity: 1
    }
  };
  return (
    <div>
      <h1 style={styles.outcomes}>Outcomes</h1>
      <table>
        <thead>
          <tr className="col-3">
            <th>Score</th>
            <th>Winner Points</th>
            <th>Loser Points</th>
          </tr>
        </thead>
        <tbody>
          {scoreSystem.outcomes.map((_scoreSystem, ind) => (
            <tr key={`${_scoreSystem.score}-${scoreSystem.bestOf}`}>
              <td className="col-3">
                <div style={styles.score}>
                  <div style={styles.pointsText}>{_scoreSystem.score}</div>
                </div>
              </td>
              <td /* style={styles.points} */>
                <input
                  onChange={(e) => handleScoreChange(e, _scoreSystem.score, ind)}
                  type="number"
                  name="winnerPoints"
                  min={-100}
                  max={100}
                  disabled={isMatchesGenerated}
                  autoComplete="off"
                  className={`form-input ${
                    _scoreSystem.winnerPoints === '' ? 'red-bg' : 'black-bg'
                  }`}
                  // className={'form-input black-bg'}
                  defaultValue={_scoreSystem.winnerPoints}
                  // value={_scoreSystem.winnerPoints}
                  placeholder="Enter Winner Points"
                />
              </td>
              <td /* style={styles.points} */>
                <input
                  type="number"
                  name="loserPoints"
                  onChange={(e) => handleScoreChange(e, _scoreSystem.score, ind)}
                  autoComplete="off"
                  min={-100}
                  max={100}
                  disabled={isMatchesGenerated}
                  className={`form-input ${
                    _scoreSystem.loserPoints === '' ? 'red-bg' : 'black-bg'
                  }`}
                  // className={'form-input black-bg'}
                  // defaultValue={_scoreSystem.loserPoints}
                  value={_scoreSystem.loserPoints}
                  placeholder="Enter Loser Points"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Outcomes;
