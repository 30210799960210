import React, { useEffect, useRef } from 'react';
/* This Hook is same a useEffect but it is not called on initital render */
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export default useDidMountEffect;
