import React from 'react';
import EditMatchModal from './EditMatchModal/EditMatchModal';
const EditMatchPopup = (props) => {
  return (
    <>
      <div>
        <EditMatchModal
          open={props.open}
          groupIndex={props.groupIndex}
          editMatchData={props.editMatchData}
          stageIndex={props.stageIndex}
          onClose={props.onClose}
        />
      </div>
    </>
  );
};
export default EditMatchPopup;
