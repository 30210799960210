import Tippy from '@tippyjs/react';
import React, { useState, useEffect, useContext } from 'react';
import CreateCompetitionModal from '../../../_modals/CreateCompetitionModal/CreateCompetitionModal';
const CreateOrganizer = ({showCreate=false}) => {
  const [showOrgModal, setShowOrgModal] = useState(false);

  return (
    <div className="create-organizer">
      <Tippy disabled={showCreate} content="You don’t have access to create an organizer." placement='left'>
        <div>
      <button
        disabled={!showCreate}
        className="button wide-primary"
        style={{ height: '36px', background: `${!showCreate ? '#68717e' : '#3eabf0'}`}}
        onClick={() => setShowOrgModal(true)}>
        + CREATE ORGANIZER
      </button>
      </div>
      </Tippy>
      {showOrgModal ? (
        <CreateCompetitionModal open={showOrgModal} onClose={setShowOrgModal} />
      ) : null}
    </div>
  );
};

export default CreateOrganizer;
