import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@common';
import { Tabs } from 'antd';
import { HiPlus } from 'react-icons/hi';
import './_stage-tabs.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addNewStage, deleteStage, getCompetition, getCompetitionsData, updateStage } from '../../../redux/action/CompetitonData';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Axios from 'axios';
function StageTabs({
  page,
  setPage,
  stages,
  pages,
  id,
  disableSave = false,
  disableDelete = false
}) {
  const backend = process.env.REACT_APP_CORE_API;
  const containerRef = useRef(null);
  const elemRef = useRef(null);
  const dispatch = useDispatch();
  const [Unsaved, setUnsaved] = useState({});
  const [newPages, setNewPages] = useState(pages);
  const currentStage = useSelector((state) =>
    state.COMPETITION.CurrentStage ? state.COMPETITION.CurrentStage : {}
  );
  const UnsavedStages = useSelector((state) =>
    state.COMPETITION.UnsavedStages ? state.COMPETITION.UnsavedStages : []
  );
  const isAnyChange = useSelector((state) =>
    state.COMPETITION.isAnyChange ? state.COMPETITION.isAnyChange : []
  );
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    setIsScrollable(containerRef.current.scrollWidth > containerRef.current.clientWidth);
  }, []);

  const checkChanges = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  // useEffect(() => {
  //   console.log("Useefect called for unsaved changes")
  //   const updatedPages = pages.map((item, index) => {
  //     const find1 = UnsavedStages.find((el) => el._id === item._id);
  //     const find2 = stages.find((el) => el._id === item._id);
  //     const isSame = !find1 || !find2 || checkChanges(find1, find2);
  //     return {
  //       ...item,
  //       isSame,
  //     };
  //   });
  //   setNewPages(updatedPages);
  // }, [UnsavedStages]);
  // useEffect(() => {
  //   let find = UnsavedStages.find((el) => el._id === pages[page]._id);
  //   if (find) {
  //     let isSame = checkChanges(stages[page], find);
  //     if (!isSame) {
  //       setUnsaved({ _id: find._id, unsaved: true });
  //     } else {
  //     }
  //   }
  // }, [UnsavedStages,page]);
  const saveStageChanges = (_id) => {
    let _obj = UnsavedStages.find((el) => el._id === pages[page]._id);
    if (_obj) {
      if (typeof _obj?.bracketType !== 'undefined' && _obj.scoreSystem.length < 5) {
        _obj.scoreSystem = [
          ..._obj.scoreSystem,
          {
            bestOf: 7,
            outcomes: [
              {
                score: '4-0',
                winnerPoints: 4,
                loserPoints: 0
              },
              {
                score: '4-1',
                winnerPoints: 4,
                loserPoints: 1
              },
              {
                score: '4-2',
                winnerPoints: 4,
                loserPoints: 2
              },
              {
                score: '4-3',
                winnerPoints: 4,
                loserPoints: 3
              }
            ]
          },
          {
            bestOf: 9,
            outcomes: [
              {
                score: '5-0',
                winnerPoints: 5,
                loserPoints: 0
              },
              {
                score: '5-1',
                winnerPoints: 5,
                loserPoints: 1
              },
              {
                score: '5-2',
                winnerPoints: 5,
                loserPoints: 2
              },
              {
                score: '5-3',
                winnerPoints: 5,
                loserPoints: 3
              },
              {
                score: '5-4',
                winnerPoints: 5,
                loserPoints: 4
              }
            ]
          }
        ];
      }
      if (_obj._id.length < 6) {
        Axios.post(backend + `/api/organizer/competition/${_id}/stage/`, {
          multiStageLeague: _obj?.multiStageLeague?._id
        })
          .then((res) => {
            const { data } = res.data;
            const { msg } = res.data;
            // toast.success(msg);
            const {
              stageType,
              playThirdPlaceOrder,
              evenGroupBySize,
              groups,
              pairingProcedure,
              homeTeam,
              teamSize,
              name,
              format,
              encounters,
              groupSize,
              rankingCriteria,
              scoreSystem,
              multiStageLeague,
              league,
              tournament,
              isMatchesGenerated,
              bracketType
            } = _obj;
            const groupswithId = groups.map((group,ind) =>{
              if(ind === 0){
                return {
                  ...group,
                  _id: data.groups[0]._id
                }
              }else{
                return group
              }
            })
            const updatedObj = {
              ...data,
              ...(bracketType && {stageType : 'tournament'}),
              playThirdPlaceOrder,
              evenGroupBySize,
              groups: groupswithId,
              pairingProcedure,
              homeTeam,
              teamSize,
              name,
              format,
              encounters,
              groupSize,
              multiStageLeague,
              isMatchesGenerated,
              ...(league && {league}),
              ...(tournament && {tournament}),
              ...(bracketType && {bracketType}),
              rankingCriteria: rankingCriteria.map(({rankingOrder, participants,matches, criteria})=>({rankingOrder, participants,matches, criteria})),
              scoreSystem: scoreSystem.map(({bestOf,outcomes})=> ({bestOf,outcomes}))
            };

            dispatch(updateStage(_id, updatedObj,_obj._id));
            // dispatch({
            //   type: APPEND_STAGES_DATA,
            //   payload: data
            // });
            dispatch(getCompetition(_id));
            dispatch(getCompetitionsData(_id));
          })
          .catch((err) => {
            const { msg } = err.response.data;
            toast.error(msg);
            console.log(err);
          });
      } else {
        dispatch(updateStage(_id, _obj));
      }
    } else {
      toast.warning('Stage is already saved');
    }
  };
  const deleteThisStage = (id, stageId) => {
    if (stageId) {
      dispatch(deleteStage(id, stageId));
      setPage((state) => {
        if (state > 0) {
          return state - 1;
        } else {
          return 0;
        }
      });
    }
  };
  return (
    <div
      className="tabs-container simple__tabs__container button-in-stages"
      style={{ height: '35px' }}>
      <div
        ref={containerRef}
        className="tabs-nav mt-4 sub-tabs"
        style={{ background: 'none', overflow: 'visible' }}>
        <nav ref={elemRef}>
          {isAnyChange &&
            pages.map((item, index) => {
              let isSame = true;
              let find1 = UnsavedStages.find((el) => el._id === item._id);
              let find2 = stages.find((el) => el._id === item._id);
              if (find1 && find2) {
                isSame = _.isEqual(find1, find2);
              }
              return (
                <button
                  className={page === index ? 'active' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(index);
                  }}
                  key={index}>
                  <div className={!isSame ? 'unsaved-stage' : ''}>
                    {!isSame ? find1?.name : item?.name}
                  </div>
                </button>
              );
            })}
          <button
            className="add-stage-btn"
            style={{
              marginTop: '-7px',
              width: '130px',
              font: 'normal normal bold 12px/15px Montserrat'
            }}
            onClick={(e) => {
              e.preventDefault();
              dispatch(addNewStage(id));
            }}>
            <HiPlus size={12} style={{ marginRight: '4px', marginTop: '3px' }} />
            Add Stage
          </button>
          {/* <div className="ellipses-btn">{isScrollable && <button onClick={() => {}}>{"..."}</button>}</div> */}
        </nav>
        <div className="side-buttons" style={{ marginTop: '-4px' }}>
          <button
            className={disableSave ? 'button-primary-disabled' : 'button-primary'}
            style={{ font: 'normal normal bold 12px/15px Montserrat' }}
            disabled={disableSave}
            onClick={(e) => saveStageChanges(id)}>
            Save Changes
          </button>
          <button
            disabled={disableDelete}
            style={{ font: 'normal normal bold 12px/15px Montserrat' }}
            className={disableDelete ? 'button-delete-disabled' : 'button-delete'}
            onClick={(e) => {
              e.preventDefault();
              deleteThisStage(id, currentStage?._id);
            }}>
            Delete Stage
          </button>
        </div>
      </div>
    </div>
  );
}

export default StageTabs;
