
import { SET_DROP, SET_FOLDERS, SET_FOLDER_ONLY, SET_ORGANIZERS,SET_COMPETITIONS,SET_INHERITEDPERMISSIONS_ONLY } from '../action/types';
const initialState = {
  Organizers: [],
  Folders: [],
  Breadcrumbs:[],
  Competitions: [],
  InheritedPermissions: [],
  CurrentUserPermissions:[]
};

// Use the initialState as a default value
export default function foldersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FOLDERS:
      return {
        ...state,
        Folders: action.payload?.folder,
        matchCount: action.payload?.matchCount,
        Breadcrumbs: action.payload?.breadcrumbs,
        InheritedPermissions : action.payload?.inheritedPermissions,
        CurrentUserPermissions : action.payload?.currentUserPermissions 
      };
    case SET_DROP:
      return {
        ...state,
        Folders: action.payload
      }
    case SET_COMPETITIONS:
      return {
        ...state,
        Competitions : action.payload
      }  
    case SET_FOLDER_ONLY:
      return {
        ...state,
        Folders: action.payload
      };
    case SET_ORGANIZERS:
      return {
        ...state,
        Organizers: action.payload
      };
    case SET_INHERITEDPERMISSIONS_ONLY:
      return {
        ...state,
        InheritedPermissions: action.payload
      }
    default:
      return state;
  }
}
