import React from 'react';
import { SimplePageWithTabs } from '@common';

const Contact = () => {
  return (
    <SimplePageWithTabs title="Contact">
      <div className="row smaller-gutter-row">
        <div className="col-12"></div>
      </div>
    </SimplePageWithTabs>
  );
};

export default Contact;
