import React from 'react';
import moment from 'moment';

const UserKeyInfo = ({ profile }) => {
  const keyInfo = [
    {
      icon: `https://flagcdn.com/w20/${profile.nationality.code?.toLowerCase()}.png`,
      value: profile.nationality.name,
      label: 'Nationality'
    },
    { value: profile.gender, label: 'Gender' },
    { value: moment().diff(profile.birthday, 'years'), label: 'Age' },
    { value: '_DISCORD_', label: 'Discord' },
    { value: '_PHONE_', label: 'Phone' },
    { value: profile.email, label: 'Email' }
  ];

  return (
    <>
      <div className="user-profile-info">
        <div className="row">
          {keyInfo.map(({ icon, value, label }, i) => (
            <div className="col-3" key={i}>
              <div className="user-info-item">
                <p className="item-value">
                  {icon && <img src={icon} alt={value} />}
                  {value}
                </p>
                <p className="item-label">{label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="user-profile-socials">
        {profile.assocPlayers && (
          <div className="players-games-icons">
            {profile.assocPlayers.map(({ game }) => game && game.name)}
          </div>
        )}

        <div className="social-icons">Twitter/Facebook/IG</div>
      </div>
    </>
  );
};

export default UserKeyInfo;
