import moment from 'moment';
import { SET_TIMEZONE } from '../action/types';
const initialState =  {timeZone: `${moment.tz.guess()}`} ;
// Use the initialState as a default value
export default function timeZoneReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIMEZONE:
      return {
        ...state,
        timeZone: action.payload
      };
    default:
      return state;
  }
}
