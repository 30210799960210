import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDidMountEffect from '../../../../helpers/useDidMountEffect';
import { setStageIndexGlobally } from '../../../../redux/action/CompetitonData';
import { getStagesSeedingData } from '../../../../redux/action/seedingActions';
import SeedingTabs from '../../Competition/SeedingTabs';
import SeedingContainer from './Seeding/SeedingContainer';
import './Seeding/seedingStyles.scss';

const SeedingTab = ({ id, stagesCount, currentParentStageIndex }) => {
  const dispatch = useDispatch();
  const loadingState = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const stageIndexGlobally = useSelector((state) =>
    state.COMPETITION.StageIndex ? state.COMPETITION.StageIndex : 0
  );
  const [currentStageIndex, setCurrentStageIndex] = useState(0);

  // redux store data
  const allStages = useSelector((state) => state.COMPETITION.SeedingTab.stages);

  const [currentStage, setCurrentStage] = useState(allStages && allStages[0] ? allStages[0] : {});

  useEffect(() => {
    dispatch(getStagesSeedingData({ id }));
  }, [id]);

  useEffect(() => {
    // if (stagesCount > 1) {
    //   if (currentParentStageIndex === 0) {
    //     setCurrentStage(allStages[currentStageIndex]);
    //   } else {
    //     setCurrentStage(allStages[currentStageIndex - 1]);
    //   }
    // } else
    if (allStages.length > 0) {
      if (allStages[currentStageIndex]) {
        setCurrentStage(allStages[currentStageIndex]);
      } else {
        setCurrentStage(allStages[currentStageIndex - 1]);
      }
    }
  }, [allStages, stagesCount, currentParentStageIndex]);

  useDidMountEffect(() => {
    let _nextPage = currentStageIndex + 1;
    if (stageIndexGlobally !== _nextPage) {
      dispatch(setStageIndexGlobally(_nextPage));
    }
  }, [currentStageIndex]);

  useEffect(() => {
    if (stageIndexGlobally !== 0) {
      let _stageIndexGlobally = stageIndexGlobally - 1;
      setCurrentStageIndex(_stageIndexGlobally);
    }
  }, [stageIndexGlobally]);

  return (
    <>
      <SeedingTabs
        id={id}
        currentStage={currentStage}
        currentStageIndex={currentStageIndex}
        setCurrentStageIndex={setCurrentStageIndex}
      />
      <SeedingContainer loadingState={loadingState} id={id} currentStage={currentStage} currentStageIndex={currentStageIndex} />
    </>
  );
};
export default SeedingTab;
