import React, { useState, createContext, useEffect } from 'react';
import Axios from 'axios';
import './_delete.scss';
import Popup_modals from '../modals/Popup_modals';
import { useDispatch } from 'react-redux';
import { getTrash } from '../../../../redux/action/trashActions';
import { HiX } from 'react-icons/hi';
const backendServerURL = process.env.REACT_APP_CORE_API;

const Component = ({ onClose, deleteOrganizaionId, namefolder, isdoubbleclick, canRestor }) => {
  const dispatch = useDispatch();
  let restoreids = {
    Organizers: [],
    Folders: [],
    Competitions: []
  };
  const restoreOrganizaion = () => {
    if (deleteOrganizaionId.includes('folder')) {
      restoreids.Folders.push(deleteOrganizaionId.split(' ').pop());
    }
    if (deleteOrganizaionId.includes('organizer')) {
      restoreids.Organizers.push(deleteOrganizaionId.split(' ').pop());
    }
    if (deleteOrganizaionId.includes('competition')) {
      restoreids.Competitions.push(deleteOrganizaionId.split(' ').pop());
    }

    Axios.put(backendServerURL + '/api/organizer/trash/restore', restoreids)
      .then((res) => {
        onClose(false);
        dispatch(getTrash());
        // const ele = document.getElementById(deleteOrganizaionId.split(' ').pop());
        // ele.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="newfolder-main">
      <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 style={{ marginTop: '20px' }}>
          {' '}
          {isdoubbleclick === true ? 'This is in your trash' : 'DELETE FOREVER'}
        </h2>
        <HiX onClick={() => {
            onClose(false);
          }}
          // style={{marginTop: '18px',marginRight: '14px',fontSize: '24px',color:'grey'}}
          style={{ marginTop: '18px', marginRight: '22px', fontSize: '26.5px', color: '#68717e' }}
          />
      </div>

      <div className="organizer-delete-text" style={{ color: '#68717e' }}>
        {isdoubbleclick === true
          ? "To view this you'll need to restore it from your trash."
          : `${namefolder} will be deleted forever and it won’t be possible to restore it.`}
      </div>

      <div className="newfolder-buttons">
        <button
          className="button bordered ml-3 mr-3"
          onClick={() => {
            onClose(false);
          }}>
          Cancel
        </button>
        <button disabled={canRestor} className="button primary " onClick={restoreOrganizaion}>
          Restore
        </button>
      </div>
    </div>
  );
};
const RestoreItmes = ({
  open,
  setDeleteforeveropen,
  deleteOrganizaionId,
  folderName,
  isdoubbleclick,
  canRestor
}) => {
  // console.log('folderNames',folderNames);

  return (
    <Popup_modals
      open={open}
      canRestor={canRestor}
      folderName={folderName}
      onClose={setDeleteforeveropen}
      deleteOrganizaionId={deleteOrganizaionId}
      Component={Component}
      isdoubbleclick={isdoubbleclick}></Popup_modals>
  );
};
export default RestoreItmes;
