import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { RiCloseLine, RiArrowGoBackLine, RiArrowGoForwardLine } from 'react-icons/ri';
import { useEffect } from 'react';
import Loading from '../../_common/Loading/Loading';
import useTranslated from '../../../helpers/translationHelper';

const EditImageModal = ({
  name,
  width,
  height,
  buttonText,
  defaultImage = null,
  crop = true,
  rotate = true,
  borderRadius,
  open,
  onClose,
  afterImageUpload,
  logoCropped,
  afterImageUploadFullSize,
  setLogoEditModal,
}) => {
  const editor = useRef();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [imgCrop, setImgCrop] = useState(true);

  const lableDragAndDrop = useTranslated('id_pop-up_-_upload_image_4');
  const lableRestart = useTranslated('id_pop-up_-_upload_image_5');

  function isTransparent(e) {
    let topX = 0.5,
      topY = 0.5;
  
    let topXX = e.path[0].width - 0.5,
      topYY = topY;
  
    let bottomX = topX,
      bottomY = e.path[0].height - 0.5;
  
    let bottomXX = e.path[0].width - 0.5,
      bottomYY = e.path[0].height - 0.5;
  
    let canvas = document.createElement('canvas');
    canvas.setAttribute('width', e.width);
    canvas.setAttribute('height', e.height);
    canvas.setAttribute('id', e.id + '-canvas');
    canvas.setAttribute('style', 'display:none;');
    document.body.appendChild(canvas);
    let ctx = canvas.getContext('2d');
    ctx.drawImage(e, 0, 0, e.width, e.height);
  
    if (ctx.getImageData === undefined) {
      setImgCrop(true);
      return;
    }
  
    if (
      ctx.getImageData(topX, topY, 1, 1).data[3] == 0 &&
      ctx.getImageData(topXX, topYY, 1, 1).data[3] == 0
    ) {
      setImgCrop(false);
      return;
    }
  
    if (
      ctx.getImageData(bottomX, bottomY, 1, 1).data[3] == 0 &&
      ctx.getImageData(bottomXX, bottomYY, 1, 1).data[3] == 0
    ) {
      setImgCrop(false);
      return;
    }
  
    if (
      ctx.getImageData(bottomXX, bottomYY, 1, 1).data[3] == 0 &&
      ctx.getImageData(topX, topY, 1, 1).data[3] == 0
    ) {
      setImgCrop(false);
      return;
    }
  
    if (
      ctx.getImageData(
        topXX,
        topYY,
        1,
        1
      ).data[3] == 0 &&
      ctx.getImageData(bottomX, bottomY, 1, 1).data[3] == 0
    ) {
      setImgCrop(false);
      return;
    }
  
    if (
      ctx.getImageData(topX, topY, 1, 1).data[3] == 0 &&
      ctx.getImageData(bottomX, bottomY, 1, 1).data[3] == 0
    ) {
      setImgCrop(false);
      return;
    }
  
    if (
      ctx.getImageData(
        topXX,
        topYY,
        1,
        1
      ).data[3] == 0 &&
      ctx.getImageData(bottomXX, bottomYY, 1, 1).data[3] == 0
    ) {
      setImgCrop(false);
      return;
    }
  
    setImgCrop(true);
  }
  

  const handleDrop = (dropped) => {
    // Get image from drop
    const image = dropped[0];
    const imageObj = new Image();
    imageObj.src = window.URL.createObjectURL(image);

    //imageObj.addEventListener('load', isTransparent, true);

    imageObj.onload = () => {
      const imageSize = image && image.size;
      const imageWidth = imageObj.width;
      const imageHeight = imageObj.height;

      // Is image less than 8MB?
      if (imageSize > 1000000) return toast.error('File must be smaller than 1MB');

      // Is image the minimum dimensions?
      if (imageWidth < width || imageHeight < height)
        return toast.error(`File must be at least ${width}px x ${height}px in size`);

      setImage(dropped[0]);
    };
  };

  const clearImage = () => {
    setImage(false);
    setImgCrop(true);
    setUploading(false);
  };

  const handleScale = (e) => setScale(parseFloat(e.target.value));
  const rotateLeft = () => setRotation(rotation - 90);
  const rotateRight = () => setRotation(rotation + 90);

  useEffect(() => {
    clearImage();
    setImgCrop(true);
    setUploading(false);
    if (defaultImage) {
      setImage(defaultImage)
    }
  }, [open]);

  useEffect(() => {
    setRotation(0);
    setScale(1);
  }, [image]);

  const onClickSave = () => {
    setUploading(true);

    const canvas = !crop ? editor.current.getImage() : editor.current.getImageScaledToCanvas();

    canvas.toBlob((blob) => {
      const file = new File([blob], `${name}-${width}-${height}.png`, {
        type: 'image/png'
      });

      if (defaultImage) {
        logoCropped(file);
        return true;
      }

      const formData = new FormData();
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };
      formData.append('image', file);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config)
        .then(({ data }) => {
          afterImageUpload(data.file);
          if (logoCropped) {
            logoCropped(imgCrop);
          }
        })
        .catch((err) => {
          const msg = err?.response?.data
            ? err.response.data.msg
            : 'There was a problem uploading your file';
          toast.error(msg);
          setUploading(false);
        });
    });

    if (afterImageUploadFullSize) {
      const file = new File([image], `${name}.png`, {
        type: 'image/png'
      });

      const formData = new FormData();
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };
      formData.append('image', file);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config).then(
        ({ data }) => {
          afterImageUploadFullSize(data.file);
        }
      );
    }
  };

  const cropDone = () => {
    setUploading(true);

    const canvas = !crop ? editor.current.getImage() : editor.current.getImageScaledToCanvas();

    canvas.toBlob((blob) => {
      const file = new File([blob], `${name}-${width}-${height}.png`, {
        type: 'image/png'
      });

      logoCropped(file);
    })
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={{ content: { width: width + 50 } }}
      className="modal edit-image-modal"
      contentLabel="Example Modal">
      <div className="modal-close" onClick={()=>{setLogoEditModal(false)}}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>
          {/* Upload */} {name}
        </h2>
      </div>
      <div className="edit-image-body" style={defaultImage ? {display: 'flex', alignItems: 'center', justifyContent: 'center'} : {}}>
        {image ? (
          <div>
            <AvatarEditor
              ref={editor}
              width={width}
              height={height}
              image={image}
              scale={scale}
              rotate={rotation}
              borderRadius={borderRadius}
            />

            <div className="edit-image-controls">
              {rotate && (
                <button onClick={rotateLeft}>
                  <RiArrowGoBackLine />
                </button>
              )}
              <input
                name="scale"
                type="range"
                className="form-input"
                value={scale}
                onChange={handleScale}
                min="1"
                max="2"
                step="0.01"
                defaultValue="1"
              />
              {rotate && (
                <button onClick={rotateRight}>
                  <RiArrowGoForwardLine />
                </button>
              )}
            </div>

            <div className="save-buttons">
              <button className="button transparent" onClick={clearImage}>
                {lableRestart /* Restart */}
              </button>
              <button className="button primary" onClick={defaultImage ? cropDone : onClickSave} disabled={uploading}>
                {uploading ? <Loading /> : defaultImage ? buttonText : 'Upload'}
              </button>
            </div>
          </div>
        ) : (
          <Dropzone
            multiple={false}
            accept={['image/jpeg', 'image/jpg', 'image/png', 'image/gif']}
            onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div className="image-dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>{lableDragAndDrop /* Drag and drop or click to upload image */}</p>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </Modal>
  );
};

// accessibility
Modal.setAppElement('#root');

export default EditImageModal;
