import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@common';
import { Tabs } from 'antd';
import { HiPencil } from 'react-icons/hi';

const ShareTabs = ({ page, setPage, pages, totalmembers }) => {
  const containerRef = useRef(null);
  const elemRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    setIsScrollable(containerRef.current.scrollWidth > containerRef.current.clientWidth);
  },[]);

  return (
    <div className="tabs-container simple__tabs__container" style={{ height: '35px' }}>
      <div ref={containerRef} className="tabs-nav mt-4 sub-tabs" style={{ background: 'none' }}>
        <nav style={{ width: '100%' }} ref={elemRef}>
          {pages.map((item, index) => (
            <button
              className={page === index ? 'active' : ''}
              // style={
              //   item === 'Customize' ? { position: 'absolute', right: '0', marginRight: '0' } : {}
              // }
              onClick={() => setPage(index)}
              key={index}>
              <div style={{display:'flex',alignItems:'center',marginTop : item === 'Customize' ? '-1px' : null }}>
                {item === 'Customize' && (
                  <HiPencil style={{ width: '20px', height: '20px', marginRight: '3px' }} />
                )}
                <span>
                  {item}
                  {item === 'All' && totalmembers ? ` (${totalmembers})` : ''}
                </span>
              </div>
            </button>
          ))}
          {/* <div className="ellipses-btn">{isScrollable && <button onClick={() => {}}>{"..."}</button>}</div> */}
        </nav>
      </div>
    </div>
  );
};

export default ShareTabs;
