import React, { useEffect } from 'react';
import './_selectionBar.scss';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Checkbox } from '../../../../_common';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCompetitionsMatches } from '../../../../../redux/action/CompetitonData';
import { useDispatch } from 'react-redux';

const MatchesSelectionBar = ({ selectedMatch, setSelectedMatch, checkBeforeDeletion, bracketMathces}) => {
  const { id } = useParams();
  const [direction, setDirection] = useState('Later');
  const [timeStemps, setTimeStemps] = useState('Weeks');
  const [value, setValue] = useState();
  const [Team1ff, setTeam1ff] = useState(false);
  const [Team2ff, setTeam2ff] = useState(false);
  const [sideSelection, setSideSelection] = useState(true);
  const competitionData = useSelector((state) => state.COMPETITION.Competition.multiStageLeague);
  const [broadcasted, setBroadcasted] = useState(true);
  const [stream, setStream] = useState(competitionData?.streams[0]?._id);
  const [selectAction, setSelectedAction] = useState();
  const [bestOf, setBestOf] = useState(0);
  const [switchScore, setSwitchScore] = useState(false);
  const [earlyDateTime, setEarlyDateTime] = useState();
  const [latestDateTime, setLatestDateTime] = useState();
  const [team1Score, setTeam1Score] = useState(0);
  const [team2Score, setTeam2Score] = useState(0);
  const [lineups, setLineups] = useState(false);

  const dispatch = useDispatch();
  const Actions = [
    'Move match',
    'Set score',
    'Change match type',
    'Switch teams',
    'Set default players',
    'Set FF',
    'Broadcasting',
    'Side selection',
    'Custom rescheduling',
    'Duplicate',
    'Delete'
  ];
  const saveDeleteHangle = () => {
    if (selectAction === 'Delete') {
      // let isCompleted = checkBeforeDeletion()
      // if (isCompleted) {   // F14-Frederick Wants to delete competed Matches too
      //   toast.error('Completed match could not delete')
      //   return false
      // }
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/deleteMulti`,
          {
            ids: [...selectedMatch]
          }
        );
        toast.success(response.msg);
      } catch (err) {
        toast.error('Error While Deleting Matches');
      }
    }
    if (selectAction === 'Move match') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/moveMatch`,
          {
            direction,
            matches: selectedMatch,
            time: timeStemps,
            value
          }
        );
        toast.success('Move Matches Successfully');
      } catch (err) {
        toast.error('Error While Move Matches');
      }
    }
    if (selectAction === 'Set FF') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/sideSelectionMulti?t1FF=${Team1ff}&t2FF=${Team2ff}`,
          { ids: [...selectedMatch] }
        );
        toast.success(`Team 1 FF is ${Team1ff} and Team 2 FF ${Team2ff} update successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Side selection') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/sideSelectionMulti?sideSelection=${sideSelection}`,
          { ids: [...selectedMatch] }
        );
        toast.success(`selected matches Side Selection update successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Broadcasting') {
      const res = competitionData?.streams.find((id) => id._id === stream);
      console.log(res);
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/broadcastMulti?broadcasted=${broadcasted}`,
          {
            ids: [...selectedMatch],
            broadCastedChannel: {
              name: res.name,
              url: res.url,
              language: res.language
            }
          }
        );
        toast.success(`selected matches Broadcast update successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Duplicate') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/duplicateMatches`,
          {
            ids: [...selectedMatch]
          }
        );
        toast.success(`selected matches Duplicate successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Change match type') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/updateBestOf`,
          {
            ids: [...selectedMatch],
            bestOf
          }
        );
        toast.success(`selected matches  Type Changed successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Switch teams') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/switchMulti`,
          {
            ids: [...selectedMatch],
            switchScore
          }
        );
        toast.success(`selected matches Teams Switched successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Custom rescheduling') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/rescheduleMulti`,
          {
            ids: [...selectedMatch],
            reschedule: {
              earliestDate: earlyDateTime,
              latestDate: latestDateTime
            }
          }
        );
        toast.success(`selected matches Rescheduling update successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Set score') {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/organizer/Competition/${id}/matches/setSelectedMatchesScore`,
          {
            ids: [...selectedMatch],
            t1Score: team1Score,
            t2Score: team2Score
          }
        );
        toast.success(`selected matches Score updated successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    if (selectAction === 'Set default players') {
      try {
        const response = Axios.post(
          `${competitionData?.game.shortName==='LoL'?process.env.REACT_APP_LOL_API:process.env.REACT_APP_CSGO_API}/api/matches/setMultiPlayers`,
          {
            ids: [...selectedMatch],
            lineups
          }
        );
        toast.success(`selected matches pyaer set successfully`);
      } catch (err) {
        toast.error('Error While FF');
      }
    }
    setTimeout(() => {
      setSelectedMatch([]);
      let query = { status: '', limit: '100', page: '1' };
      dispatch(getCompetitionsMatches({ id: id, query }));
    }, 2000);
  };
  return (
    <>
      <div className="selectionBarContainer">
        <p style={{ marginLeft: '15px', width: 'fit-content' }}>
          {selectedMatch?.length === 1
            ? selectedMatch?.length + ' Match '
            : selectedMatch?.length + ' Matches '}
           Selected
        </p>
        <select
          id="actions"
          name="actions"
          defaultValue={selectAction}
          className={'selectActionsDropdown form-input'}
          onChange={(e) => {
            setSelectedAction(e.target.value);
          }}>
          <option value="_DEFAULT_" selected>
            Choose Action
          </option>
          {Actions?.map((action, key) => (
            <option id={action + key} value={action} selected={selectAction === action}>
              {action}
            </option>
          ))}
        </select>
        <div className="sepratorline">|</div>
        {selectAction === 'Move match' ? (
          <MoveMatch
            setDirection={setDirection}
            setTimeStemps={setTimeStemps}
            setValue={setValue}
            value={value}
            timeStemps={timeStemps}
            direction={direction}
          />
        ) : selectAction === 'Set score' ? (
          <SetScore
            team1Score={team1Score}
            team2Score={team2Score}
            setTeam1Score={setTeam1Score}
            setTeam2Score={setTeam2Score}
          />
        ) : selectAction === 'Change match type' ? (
          <ChangeMatchType bestOf={bestOf} setBestOf={setBestOf} bracketMathces={bracketMathces} />
        ) : selectAction === 'Set default players' ? (
          <SetDefaultplayer lineups={lineups} setLineups={setLineups} />
        ) : selectAction === 'Set FF' ? (
          <SetFF
            Team1ff={Team1ff}
            Team2ff={Team2ff}
            setTeam1ff={setTeam1ff}
            setTeam2ff={setTeam2ff}
          />
        ) : selectAction === 'Broadcasting' ? (
          <Broadcasting
            broadcasted={broadcasted}
            setBroadcasted={setBroadcasted}
            stream={stream}
            setStream={setStream}
          />
        ) : selectAction === 'Side selection' ? (
          <SideSelection sideSelection={sideSelection} setSideSelection={setSideSelection} />
        ) : selectAction === 'Custom rescheduling' ? (
          <CustomRescheduling
            setEarlyDateTime={setEarlyDateTime}
            setLatestDateTime={setLatestDateTime}
          />
        ) : selectAction === 'Switch teams' ? (
          <SwitchScores switchScore={switchScore} setSwitchScore={setSwitchScore} />
        ) : null}

        <button
          onClick={() => saveDeleteHangle()}
          className={`button primary applyButton ${
            selectAction === 'Delete' ? 'redButton' : null
          }`}>
          {selectAction === 'Delete' ? 'Delete' : 'Apply'}
        </button>
      </div>
    </>
  );
};
export default MatchesSelectionBar;
export const MoveMatch = ({
  setTimeStemps,
  setValue,
  setDirection,
  value,
  timeStemps,
  direction
}) => {
  const directions = ['Later', 'Earlier'];
  const times = ['Weeks', 'Days', 'Hours', 'Minutes'];
  useEffect(() => {}, []);
  return (
    <div className="moveMatchContainer">
      <h4>Direction</h4>
      <select
        id="direction"
        name="direction"
        defaultValue={direction}
        className={'selectActionsDropdown form-input'}
        onChange={(e) => {
          setDirection(e.target.value);
        }}>
        {directions?.map((action, key) => (
          <option id={action + key} value={action} selected={direction === action}>
            {action}
          </option>
        ))}
      </select>
      <h4>Time</h4>
      <select
        id="direction"
        name="direction"
        defaultValue={timeStemps}
        className={'selectActionsDropdown form-input'}
        onChange={(e) => {
          setTimeStemps(e.target.value);
        }}>
        {times?.map((action, key) => (
          <option id={action + key} value={action} selected={timeStemps === action}>
            {action}
          </option>
        ))}
      </select>
      <h4>Value</h4>
      <input
        className="inputNumber"
        type="number"
        value={value}
        min={0}
        max={
          timeStemps === 'Hours'
            ? 48
            : timeStemps === 'Days'
            ? 60
            : timeStemps === 'Weeks'
            ? 52
            : 120
        }
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
export const SetScore = ({ team1Score, setTeam1Score, team2Score, setTeam2Score }) => {
  const handleScoreClicks = ({ name, action }) => {
    if (name === 'left-side') {
      if (action === 'MINUS') {
        setTeam1Score((state) => state - 1);
      } else if (action === 'PLUS') {
        setTeam1Score((state) => state + 1);
      }
    } else if (name === 'right-side') {
      if (action === 'MINUS') {
        setTeam2Score((state) => state - 1);
      } else if (action === 'PLUS') {
        setTeam2Score((state) => state + 1);
      }
    }
  };
  return (
    <div className="moveMatchContainer">
      <h4>Team 1</h4>
      <div
        className="scores-manually-2"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          height: '45px',
          alignItems: 'center',
          margin: '0px 15px'
        }}>
        <button
          className="plusminusbutton"
          onClick={(e) => {
            handleScoreClicks({ name: 'left-side', action: 'MINUS' });
          }}>
          <AiOutlineMinus size={12} />
        </button>
        <input
          type="number"
          name="leftSide"
          style={{ border: `1px solid #57595f`, width: '100px' }}
          min={0}
          onChange={(e) => setTeam1Score(Math.floor(e.target.value))}
          value={team1Score}
        />
        <button
          className="plusminusbutton"
          onClick={(e) => {
            handleScoreClicks({ name: 'left-side', action: 'PLUS' });
          }}>
          <AiOutlinePlus size={12} />
        </button>
      </div>
      <h4>Team 2</h4>
      <div
        className="scores-manually-2"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          height: '45px',
          alignItems: 'center',
          margin: '0px 15px'
        }}>
        <button
          className="plusminusbutton"
          onClick={(e) => {
            handleScoreClicks({ name: 'right-side', action: 'MINUS' });
          }}>
          <AiOutlineMinus size={12} />
        </button>
        <input
          type="number"
          name="leftSide"
          style={{ border: `1px solid #57595f`, width: '100px' }}
          min={0}
          onChange={(e) => setTeam2Score(Math.floor(e.target.value))}
          value={team2Score}
        />
        <button
          className="plusminusbutton"
          onClick={(e) => {
            handleScoreClicks({ name: 'right-side', action: 'PLUS' });
          }}>
          <AiOutlinePlus size={12} />
        </button>
      </div>
    </div>
  );
};
export const ChangeMatchType = ({ bestOf, setBestOf, bracketMathces }) => {
  return (
    <div className="moveMatchContainer">
      <label
        htmlFor="best of"
        style={{
          textTransform: 'uppercase',
          color: 'white',
          font: 'normal normal bold 12px/15px Montserrat',
          marginTop: '2px'
        }}>
        BEST OF
      </label>
      <select
        id="best of"
        name="best of"
        className={`form-input inputNumber`}
        defaultValue={bestOf}
        onChange={(e) => {
          setBestOf(e.target.value);
        }}>
        {(bracketMathces ? [1, 3, 5, 7, 9] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
          .map(name => (
            <option key={name} value={name} selected={name == bestOf}>
              BEST OF {name}
            </option>
          ))}
      </select>
    </div>
  );
};
export const SwitchScores = ({ switchScore, setSwitchScore }) => {
  return (
    <div className="moveMatchContainer">
      <Checkbox
        type="checkbox"
        checked={switchScore}
        onChange={() => setSwitchScore(!switchScore)}
        className="check-type1"
      />
      <h4 style={{ textTransform: 'uppercase' }}>Switch Scores</h4>
    </div>
  );
};
export const SetDefaultplayer = ({ lineups, setLineups }) => {
  return (
    <div className="moveMatchContainer">
      <Checkbox
        type="checkbox"
        checked={lineups}
        onChange={() => setLineups(!lineups)}
        className="check-type1"
      />
      <h4 style={{ textTransform: 'uppercase' }}>overwrite lineups</h4>
    </div>
  );
};
export const SetFF = ({ Team1ff, setTeam1ff, Team2ff, setTeam2ff }) => {
  return (
    <div className="moveMatchContainer">
      <Checkbox
        type="checkbox"
        checked={Team1ff}
        onChange={() => setTeam1ff(!Team1ff)}
        className="check-type1"
      />
      <h4 style={{ marginRight: '15px' }}>Team 1</h4>

      <Checkbox
        type="checkbox"
        checked={Team2ff}
        onChange={() => setTeam2ff(!Team2ff)}
        className="check-type1"
      />
      <h4 style={{ marginRight: '15px' }}>Team 2</h4>
    </div>
  );
};
export const Broadcasting = ({ setStream, stream, setBroadcasted, broadcasted }) => {
  const competitionData = useSelector((state) => state.COMPETITION.Competition.multiStageLeague);
  return (
    <div className="moveMatchContainer">
      <Checkbox
        type="checkbox"
        checked={broadcasted}
        onChange={() => setBroadcasted(!broadcasted)}
        className="check-type1"
      />
      <h4 style={{ textTransform: 'uppercase' }}>broadcasted</h4>

      {broadcasted ? (
        <>
          <label
            htmlFor="channel"
            style={{
              textTransform: 'uppercase',
              color: 'white',
              font: '15px / 15px Montserrat',
              marginTop: '2px',
              marginLeft: '15px'
            }}>
            CHANNEL
          </label>
          <select
            id="channel"
            name="bchannel"
            className={`form-input inputNumber`}
            defaultValue={stream}
            onChange={(e) => {
              setStream(e.target.value);
            }}>
            {competitionData?.streams?.map((strm, index) => (
              <option key={strm._id} value={strm._id} selected={stream === strm._id}>
                {strm.name}
              </option>
            ))}
          </select>
        </>
      ) : null}
    </div>
  );
};
export const SideSelection = ({ sideSelection, setSideSelection }) => {
  return (
    <div className="moveMatchContainer">
      <Checkbox
        type="checkbox"
        checked={sideSelection}
        onChange={() => setSideSelection(!sideSelection)}
        className="check-type1"
      />
      <h4 style={{ textTransform: 'uppercase' }}>side selection</h4>
    </div>
  );
};
export const CustomRescheduling = ({ setEarlyDateTime, setLatestDateTime }) => {
  const leagesid = useSelector(
    (state) => state?.COMPETITION?.Competition?.multiStageLeague?.stages[0]?.league?._id
  );
  const { errors } = useForm();
  const [payload, setPayload] = useState({
    canPlayersVeto: true,
    earliestDate: null,
    latestDate: null,
    rescheduleType: null,
    setPlayersDeadline: 0,
    forfeitTeam: null,
    rulesGhostSlug: null
  });
  const [earlydate, setEarlydate] = useState(payload?.earliestDate);
  const [earlytime, setEarlytime] = useState(payload?.earliestDate);
  const [latesttime, setLatesttime] = useState(payload?.latestDate);
  const [latestdate, setlatestdate] = useState(payload?.latestDate);
  const [league, setLeague] = useState({});

  useEffect(() => {
    if (latestdate || latesttime) {
      setLatestDateTime(latestdate + 'T' + latesttime);
      setPayload((state) => ({
        ...state,
        latestDate: latestdate + 'T' + latesttime
      }));
    }
  }, [latestdate, latesttime]);
  useEffect(() => {
    if (
      (earlydate == null || earlytime === null || latestdate == null || latesttime == null) &&
      payload.earliestDate !== null
    ) {
      setEarlydate(
        payload?.earliestDate
          ? `${moment(payload.earliestDate).format('YYYY-MM-DD')}`.split('T')[0]
          : null
      );
      setEarlytime(payload?.earliestDate ? moment(payload.earliestDate).format('hh:mm:ss') : null);
      setlatestdate(payload?.latestDate ? `${payload?.latestDate}`.split('T')[0] : null);
      setLatesttime(payload?.latestDate ? moment(payload.latestDate).format('hh:mm:ss') : null);
    }
  }, [payload]);
  useEffect(() => {
    if (earlydate || earlytime) {
      setEarlyDateTime(earlydate + 'T' + earlytime);
      setPayload((state) => ({
        ...state,
        earliestDate: earlydate + 'T' + earlytime
      }));
    }
  }, [earlydate, earlytime]);
  useEffect(() => {
    leagesid &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${leagesid}?withMatches=true`).then(
        ({ data }) => {
          setLeague(data);
        }
      );
  }, [leagesid]);

  useEffect(() => {
    setPayload({
      canPlayersVeto: true,
      earliestDate:
        league?.reschedule && league?.reschedule.earliestDate
          ? moment(league?.reschedule.earliestDate)
          : league?.startDate
          ? moment(league?.startDate)
          : false,
      latestDate:
        league?.reschedule && league?.reschedule.latestDate
          ? moment(league?.reschedule.latestDate).toISOString()
          : league?.startDate
          ? moment(league?.startDate)
              .add(7, 'days')
              .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
              .toISOString()
          : false,
      rescheduleType:
        league?.reschedule && league?.reschedule.rescheduleType
          ? league?.reschedule.rescheduleType
          : 'NO_RESCHEDULE',
      setPlayersDeadline: league?.setPlayersDeadline,
      forfeitTeam: league?.forfeitTeam,
      rulesGhostSlug: league?.rulesGhostSlug
    });
  }, [league]);
  return (
    <div>
      <Row style={{ alignItems: 'flex-start' }}>
        <Col style={{ paddingLeft: '63px', paddingTop: '10px' }}>
          <Form.Group className="mb-3" style={{ display: 'flex', flexDirection: 'row' }}>
            <Row>
              <label
                className="form-label lable"
                style={{
                  margin: '5px 0px 0px 15px',
                  color: 'white',
                  font: '15px / 15px Montserrat'
                }}>
                Earliest Date
              </label>

              <Col style={{ width: '60%' }}>
                <Form.Group>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                    // value={Format="HH:mm:ss"
                    value={
                      payload?.earliestDate
                        ? `${moment(payload.earliestDate).format('YYYY-MM-DD')}`.split('T')[0]
                        : null
                    }
                    onChange={(e) => {
                      setEarlydate(e.target.value);
                      // setUnSavedStages((state) => [...state, selectedTab]);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate && errors.startDate.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col style={{ width: '40%' }}>
                <Form.Group>
                  <input
                    type="time"
                    id="startDate"
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                    // timeFormat="HH:mm:ss"
                    value={moment(payload.earliestDate).format('hh:mm:ss')}
                    my-date-format="DD/MM/YYYY, hh:mm:ss"
                    onChange={(e) => {
                      setEarlytime(e.target.value);
                      // setUnSavedStages((state) => [...state, selectedTab]);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate && errors.startDate.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col style={{ paddingTop: '10px' }}>
          <Form.Group className="mb-3" style={{ display: 'flex', flexDirection: 'row' }}>
            <Row>
              <label
                className="form-label lable"
                style={{
                  margin: '5px 0px 0px 15px',
                  color: 'white',
                  font: '15px / 15px Montserrat'
                }}>
                Latest Date
              </label>

              <Col style={{ width: '60%' }}>
                <Form.Group>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                    value={payload?.latestDate ? `${payload?.latestDate}`.split('T')[0] : null}
                    // defaultValue={`${payload.latestDate}`}
                    onChange={(e) => {
                      setlatestdate(e.target.value);
                      // setUnSavedStages((state) => [...state, selectedTab]);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate && errors.startDate.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col style={{ width: '40%' }}>
                <Form.Group>
                  <input
                    type="time"
                    id="startDate"
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                    // timeFormat="HH:mm:ss"
                    value={moment(payload.latestDate).format('hh:mm:ss')}
                    my-date-format="DD/MM/YYYY, hh:mm:ss"
                    onChange={(e) => {
                      setLatesttime(e.target.value);
                      // setUnSavedStages((state) => [...state, selectedTab]);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate && errors.startDate.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
          <span className="text-danger">
            {moment(payload.earliestDate).isAfter(moment(payload.latestDate))
              ? 'Latest Date should be after the Earliest Date'
              : null}
          </span>
        </Col>
      </Row>
    </div>
  );
};
