import React from "react";

const Statusbar = ({selectionCount, deleteSelected}) => {
    return (
        <div
        className="mb-5"
        style={{
          width: '100%',
          padding: '.7%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          background: '#22262E',
          justifyContent: 'space-between',
        }}>
        <div style={{marginLeft:"14px"}}>{`${selectionCount} ITEMS SELECTED`}</div>
        <button className='button primary' style={{marginRight:"3px",height:'40px' ,background:"#F11857",border:"1 px solid #F11857"}} onClick={deleteSelected}>DELETE</button>
        </div>
    )
}
export default Statusbar