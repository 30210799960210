import React, { useState } from 'react';
import { List } from '@common';
import SortableListItem from '../SortableListItem/SortableListItem';

const SortableList = ({ children, config, classes }) => {
  const [sort, setSort] = useState(false);
  const [desc, setDesc] = useState(false);
  let filtered = React.Children.toArray(children);
  let roles = ['TOP', 'JUNGLE', 'MID', 'BOT', 'SUPPORT', 'SUB'];
  if (filtered.length) {
    filtered = filtered.map((i) => React.cloneElement(i, { config: config }));

    if (sort) {
      filtered.sort((a, b) => {
        if (a.props[sort] > b.props[sort]) return 1;
        if (b.props[sort] > a.props[sort]) return -1;

        return 0;
      });
      if (sort === 'position') {
        let temp = [];
        roles.forEach((e) => {
          temp.push(...filtered.filter((el) => el.props[sort] === e));
        });
        filtered = temp;
      }
    }
    if (desc) filtered = filtered.reverse();

    return (
      <div className={`sortable-list ${classes}`}>
        <div className="sortable-list-header">
          <SortableListItem
            config={config}
            show_header={true}
            sort={sort}
            setSort={setSort}
            desc={desc}
            setDesc={setDesc}
          />
        </div>
        <List>{filtered}</List>
      </div>
    );
  } else {
    return (
      <div className={`sortable-list ${classes}`}>
        <List>Nothing to show here!</List>
      </div>
    );
  }
};

export default SortableList;
