import React, { useState, useContext, useEffect } from 'react';
import useTranslated from '../helpers/translationHelper';
export const TIMEZONES = () => {
  let t1 = useTranslated('id_dropdown_-_time_zones_0');
  let t2 = useTranslated('id_dropdown_-_time_zones_1');
  let t3 = useTranslated('id_dropdown_-_time_zones_2');
  let t4 = useTranslated('id_dropdown_-_time_zones_3');
  let t5 = useTranslated('id_dropdown_-_time_zones_4');
  let t6 = useTranslated('id_dropdown_-_time_zones_5');
  let t7 = useTranslated('id_dropdown_-_time_zones_6');
  let t8 = useTranslated('id_dropdown_-_time_zones_7');
  let t9 = useTranslated('id_dropdown_-_time_zones_8');
  let t10 = useTranslated('id_dropdown_-_time_zones_9');
  let t11 = useTranslated('id_dropdown_-_time_zones_10');
  let t12 = useTranslated('id_dropdown_-_time_zones_11');
  let t13 = useTranslated('id_dropdown_-_time_zones_12');
  let t14 = useTranslated('id_dropdown_-_time_zones_13');
  let t15 = useTranslated('id_dropdown_-_time_zones_14');
  let t16 = useTranslated('id_dropdown_-_time_zones_15');
  let t17 = useTranslated('id_dropdown_-_time_zones_16');
  let t18 = useTranslated('id_dropdown_-_time_zones_17');
  let t19 = useTranslated('id_dropdown_-_time_zones_18');
  let t20 = useTranslated('id_dropdown_-_time_zones_19');
  let t21 = useTranslated('id_dropdown_-_time_zones_20');
  let t22 = useTranslated('id_dropdown_-_time_zones_21');
  let t23 = useTranslated('id_dropdown_-_time_zones_22');
  let t24 = useTranslated('id_dropdown_-_time_zones_23');
  let t25 = useTranslated('id_dropdown_-_time_zones_24');
  let t26 = useTranslated('id_dropdown_-_time_zones_25');
  let t27 = useTranslated('id_dropdown_-_time_zones_26');
  let t28 = useTranslated('id_dropdown_-_time_zones_27');
  let t29 = useTranslated('id_dropdown_-_time_zones_28');
  let t30 = useTranslated('id_dropdown_-_time_zones_29');
  let t31 = useTranslated('id_dropdown_-_time_zones_30');
  let t33 = useTranslated('id_dropdown_-_time_zones_31');
  let t34 = useTranslated('id_dropdown_-_time_zones_32');
  let t35 = useTranslated('id_dropdown_-_time_zones_33');
  let t36 = useTranslated('id_dropdown_-_time_zones_34');
  let t37 = useTranslated('id_dropdown_-_time_zones_35');
  let t38 = useTranslated('id_dropdown_-_time_zones_36');
  let t39 = useTranslated('id_dropdown_-_time_zones_37');
  let t40 = useTranslated('id_dropdown_-_time_zones_38');
  let t41 = useTranslated('id_dropdown_-_time_zones_39');
  let t43 = useTranslated('id_dropdown_-_time_zones_40');
  let t44 = useTranslated('id_dropdown_-_time_zones_41');
  let t45 = useTranslated('id_dropdown_-_time_zones_42');
  let t46 = useTranslated('id_dropdown_-_time_zones_43');
  let t47 = useTranslated('id_dropdown_-_time_zones_44');
  let t48 = useTranslated('id_dropdown_-_time_zones_45');
  let t49 = useTranslated('id_dropdown_-_time_zones_46');
  let t50 = useTranslated('id_dropdown_-_time_zones_47');
  let t51 = useTranslated('id_dropdown_-_time_zones_48');
  let t52 = useTranslated('id_dropdown_-_time_zones_49');
  let t53 = useTranslated('id_dropdown_-_time_zones_50');
  let t54 = useTranslated('id_dropdown_-_time_zones_51');
  let t55 = useTranslated('id_dropdown_-_time_zones_52');
  let t56 = useTranslated('id_dropdown_-_time_zones_53');
  let t57 = useTranslated('id_dropdown_-_time_zones_54');
  let t58 = useTranslated('id_dropdown_-_time_zones_55');
  let t59 = useTranslated('id_dropdown_-_time_zones_56');
  let t32 = useTranslated('id_dropdown_-_time_zones_57');
  let t60 = useTranslated('id_dropdown_-_time_zones_58');
  let t61 = useTranslated('id_dropdown_-_time_zones_59');
  let t62 = useTranslated('id_dropdown_-_time_zones_60');
  let t63 = useTranslated('id_dropdown_-_time_zones_61');
  let t64 = useTranslated('id_dropdown_-_time_zones_62');
  let t65 = useTranslated('id_dropdown_-_time_zones_63');
  let t66 = useTranslated('id_dropdown_-_time_zones_64');
  let t67 = useTranslated('id_dropdown_-_time_zones_65');
  let t68 = useTranslated('id_dropdown_-_time_zones_66');
  let t69 = useTranslated('id_dropdown_-_time_zones_67');
  let t70 = useTranslated('id_dropdown_-_time_zones_68');
  let t71 = useTranslated('id_dropdown_-_time_zones_69');
  let t72 = useTranslated('id_dropdown_-_time_zones_70');
  let t73 = useTranslated('id_dropdown_-_time_zones_71');
  let t74 = useTranslated('id_dropdown_-_time_zones_72');
  let t75 = useTranslated('id_dropdown_-_time_zones_73');
  let t76 = useTranslated('id_dropdown_-_time_zones_74');
  let t77 = useTranslated('id_dropdown_-_time_zones_75');
  let t78 = useTranslated('id_dropdown_-_time_zones_76');
  let t79 = useTranslated('id_dropdown_-_time_zones_77');
  let t80 = useTranslated('id_dropdown_-_time_zones_78');
  let t81 = useTranslated('id_dropdown_-_time_zones_79');
  let t82 = useTranslated('id_dropdown_-_time_zones_80');
  let t83 = useTranslated('id_dropdown_-_time_zones_81');
  let t84 = useTranslated('id_dropdown_-_time_zones_82');
  let t85 = useTranslated('id_dropdown_-_time_zones_83');
  let t86 = useTranslated('id_dropdown_-_time_zones_84');
  let t87 = useTranslated('id_dropdown_-_time_zones_85');
  let t88 = useTranslated('id_dropdown_-_time_zones_86');
  let t89 = useTranslated('id_dropdown_-_time_zones_87');
  let t90 = useTranslated('id_dropdown_-_time_zones_88');
  let t91 = useTranslated('id_dropdown_-_time_zones_89');
  let t92 = useTranslated('id_dropdown_-_time_zones_90');
  let t93 = useTranslated('id_dropdown_-_time_zones_101');
  let t94 = useTranslated('id_dropdown_-_time_zones_91');
  let t95 = useTranslated('id_dropdown_-_time_zones_92');
  let t96 = useTranslated('id_dropdown_-_time_zones_93');
  let t97 = useTranslated('id_dropdown_-_time_zones_94');
  let t98 = useTranslated('id_dropdown_-_time_zones_95');
  let t99 = useTranslated('id_dropdown_-_time_zones_96');
  let t100 = useTranslated('id_dropdown_-_time_zones_97');
  let t101 = useTranslated('id_dropdown_-_time_zones_98');
  let t102 = useTranslated('id_dropdown_-_time_zones_99');
  let t103 = useTranslated('id_dropdown_-_time_zones_100');
  let t104 = useTranslated('id_dropdown_-_time_zones_102');
  let t105 = useTranslated('id_dropdown_-_time_zones_103');
  let t106 = useTranslated('id_dropdown_-_time_zones_104');
  let t107 = useTranslated('id_dropdown_-_time_zones_105');
  let t108 = useTranslated('id_dropdown_-_time_zones_106');
  let t109 = useTranslated('id_dropdown_-_time_zones_107');
  let t110 = useTranslated('id_dropdown_-_time_zones_108');
  let t111 = useTranslated('id_dropdown_-_time_zones_109');
  let t112 = useTranslated('id_dropdown_-_time_zones_110');
  let t113 = useTranslated('id_dropdown_-_time_zones_111');
  let t114 = useTranslated('id_dropdown_-_time_zones_112');
  let t115 = useTranslated('id_dropdown_-_time_zones_113');
  let t116 = useTranslated('id_dropdown_-_time_zones_114');
  let t117 = useTranslated('id_dropdown_-_time_zones_115');
  let t118 = useTranslated('id_dropdown_-_time_zones_116');
  let t119 = useTranslated('id_dropdown_-_time_zones_117');
  let t120 = useTranslated('id_dropdown_-_time_zones_118');
  let t121 = useTranslated('id_dropdown_-_time_zones_119');
  let t122 = useTranslated('id_dropdown_-_time_zones_120');
  let t123 = useTranslated('id_dropdown_-_time_zones_121');
  let t124 = useTranslated('id_dropdown_-_time_zones_122');
  let t125 = useTranslated('id_dropdown_-_time_zones_123');
  let t126 = useTranslated('id_dropdown_-_time_zones_124');
  let t127 = useTranslated('id_dropdown_-_time_zones_125');
  let t128 = useTranslated('id_dropdown_-_time_zones_126');
  let t42 = useTranslated('id_dropdown_-_time_zones_127');

  return [
    { name: t1, value: 'Etc/GMT+11' },
    { name: t2, value: 'America/Adak' },
    { name: t3, value: 'Pacific/Honolulu' },
    { name: t4, value: 'Pacific/Marquesas' },
    { name: t5, value: 'America/Anchorage' },
    { name: t6, value: 'Etc/GMT+9' },
    { name: t7, value: 'America/Tijuana' },
    { name: t8, value: 'Etc/GMT+8' },
    { name: t9, value: 'America/Los_Angeles' },
    { name: t10, value: 'US/Arizona' },
    { name: t11, value: 'America/Chihuahua' },
    { name: t12, value: 'America/Hermosillo' },
    { name: t13, value: 'America/Chicago' },
    { name: t14, value: 'America/Regina' },
    { name: t15, value: 'America/Araguaina' },
    { name: t16, value: 'Pacific/Easter' },
    { name: t17, value: 'America/Mexico_City' },
    { name: t18, value: 'America/Regina' },
    { name: t19, value: 'America/Bogota' },
    { name: t20, value: 'America/Cancun' },
    { name: t21, value: 'America/Panama' },
    { name: t22, value: 'America/Havana' },
    { name: t23, value: 'America/New_York' },
    { name: t24, value: 'America/Asuncion' },
    { name: t25, value: 'America/Glace_Bay' },
    { name: t26, value: 'America/Caracas' },
    { name: t27, value: 'America/Cuiaba' },
    { name: t28, value: 'America/Cuiaba' },
    { name: t29, value: 'America/Santiago' },
    { name: t30, value: 'America/St_Johns' },
    { name: t41, value: 'America/Araguaina' },
    { name: t43, value: 'America/Cayenne' },
    { name: t44, value: 'America/Buenos_Aires' },
    { name: t45, value: 'America/Nuuk' },
    { name: t46, value: 'America/Montevideo' },
    { name: t47, value: 'America/Punta_Arenas' },
    { name: t48, value: 'America/Bahia' },
    { name: t49, value: 'Etc/GMT+2' },
    { name: t50, value: 'Atlantic/Azores' },
    { name: t51, value: 'Atlantic/Cape_Verde' },
    { name: t52, value: '	Etc/UTC' },
    { name: t53, value: 'Etc/GMT' },
    { name: t54, value: 'Etc/Greenwich' },
    { name: t55, value: 'Africa/Sao_Tome' },
    { name: t56, value: 'Africa/Casablanca' },
    { name: t57, value: 'Europe/Amsterdam' },
    { name: t58, value: 'Europe/Belgrade' },
    { name: t59, value: 'Europe/Brussels' },
    { name: t60, value: 'Europe/Sarajevo' },
    { name: t61, value: 'Europe/Rome' },
    { name: t62, value: 'Asia/Amman' },
    { name: t63, value: 'Europe/Athens' },
    { name: t64, value: 'Asia/Beirut' },
    { name: t65, value: 'Africa/Cairo' },
    { name: t66, value: 'Asia/Nicosia' },
    { name: t67, value: 'Asia/Damascus' },
    { name: t68, value: 'Asia/Hebron' },
    { name: t69, value: 'Africa/Maputo' },
    { name: t70, value: 'Europe/Helsinki' },
    { name: t71, value: 'Asia/Jerusalem' },
    { name: t72, value: 'Europe/Kaliningrad' },
    { name: t73, value: 'Africa/Khartoum' },
    { name: t74, value: 'Africa/Tripoli' },
    { name: t75, value: 'Africa/Windhoek' },
    { name: t76, value: 'Asia/Baghdad' },
    { name: t77, value: 'Europe/Istanbul' },
    { name: t78, value: 'Asia/Riyadh' },
    { name: t79, value: 'Europe/Minsk' },
    { name: t80, value: 'Europe/Moscow' },
    { name: t81, value: 'Africa/Nairobi' },
    { name: t82, value: 'Asia/Tehran' },
    { name: t83, value: 'Asia/Dubai' },
    { name: t84, value: 'Europe/Astrakhan' },
    { name: t85, value: 'Asia/Baku' },
    { name: t86, value: 'Europe/Samara' },
    { name: t87, value: 'Indian/Mauritius' },
    { name: t88, value: 'Europe/Saratov' },
    { name: t89, value: 'Asia/Tbilisi' },
    { name: t90, value: 'Asia/Oblast' },
    { name: t91, value: 'Asia/Yerevan' },
    { name: t92, value: 'Asia/Kabul' },
    { name: t93, value: 'Asia/Tashkent' },
    { name: t94, value: 'Asia/Yekaterinburg' },
    { name: t95, value: 'Asia/Karachi' },
    { name: t96, value: 'Asia/Qyzylorda' },
    { name: t97, value: 'Asia/Kolkata' },
    { name: t98, value: 'Asia/Colombo' },
    { name: t99, value: 'Asia/Kathmandu' },
    { name: t100, value: 'Asia/Dhaka' },
    { name: t101, value: 'Asia/Omsk' },
    { name: t102, value: 'Asia/Yangon' },
    { name: t103, value: 'Asia/Bangkok' },
    { name: t104, value: 'Asia/Hovd' },
    { name: t105, value: 'Asia/Hovd' },
    { name: t106, value: 'Asia/Krasnoyarsk' },
    { name: t107, value: 'Asia/Novosibirsk' },
    { name: t108, value: 'Asia/Tomsk' },
    { name: t109, value: 'Asia/Shanghai' },
    { name: t110, value: 'Asia/Irkutsk' },
    { name: t111, value: 'Asia/Singapore' },
    { name: t112, value: 'Australia/Perth' },
    { name: t113, value: 'Asia/Taipei' },
    { name: t114, value: 'Asia/Ulaanbaatar' },
    { name: t115, value: 'Australia/Eucla' },
    { name: t116, value: 'Asia/Chita' },
    { name: t117, value: 'Asia/Tokyo' },
    { name: t118, value: 'Asia/Pyongyang' },
    { name: t119, value: 'Asia/Seoul' },
    { name: t120, value: 'Asia/Yakutsk' },
    { name: t121, value: 'Australia/Adelaide' },
    { name: t122, value: 'Australia/Darwin' },
    { name: t123, value: 'Australia/Brisbane' },
    { name: t124, value: 'Australia/Sydney' },
    { name: t125, value: 'Pacific/Guam' },
    { name: t126, value: 'Australia/Hobart' },
    { name: t127, value: 'Asia/Vladivostok' },
    { name: t128, value: 'Australia/Lord_Howe' },
    { name: t31, value: 'Pacific/Bougainville' },
    { name: t32, value: 'Asia/Magadan' },
    { name: t33, value: 'Pacific/Norfolk' },
    { name: t34, value: 'Asia/Sakhalin' },
    { name: t35, value: 'Pacific/Noumea' },
    { name: t36, value: 'Pacific/Auckland' },
    { name: t37, value: 'Etc/GMT-12' },
    { name: t38, value: 'Pacific/Fiji' },
    { name: t39, value: 'Pacific/Chatham' },
    { name: t40, value: 'Etc/GMT-13' },
    { name: t42, value: 'Pacific/Tongatapu' }
  ];
};
