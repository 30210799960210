import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../context/AppContext';
const useRoles = () => {
  const { user } = useContext(AppContext);
  const [perms, setPerms] = useState(null);
  const competitonData = useSelector((state) =>
    state.COMPETITION.Competition ? state.COMPETITION.Competition : {}
  );
  useEffect(() => {
    let InheritedPermissions = competitonData?.inheritedPermissions ?? [];
    let CompetitionPermissions = competitonData?.multiStageLeague?.organizerRoles ?? [];
    let allPermissions = [...InheritedPermissions, ...CompetitionPermissions];
    if (user) {
      let orgAdmin = user?.state?.permissions?.user?.OrganizerAdmin ?? false;
      let orgAccess = user?.state?.permissions?.user?.OrganizerAccess ?? false;
      let sharedOrganizer = user?.state?.sharedOrganizer > 0 ? true : false;
      if (user?.state?.admin || orgAdmin) {
        setPerms({
          administrateCompetitions: true,
          changePermission: true,
          createAndDeleteCompetitions: true,
          editFormat: true,
          editMatches: true,
          manageFolderStructure: true,
          manageParticipants: true,
          setMatchScore: true
        });
      } else if (!orgAdmin && (orgAccess || sharedOrganizer)) {
        let userPermissions = allPermissions.find(
          (permission) => permission.user._id === user.state._id
        );
        if (userPermissions) {
          const { permission } = userPermissions.userPermission;
          if (permission) {
            setPerms(permission);
          }
        }
      }
    }
  }, [competitonData]);
  return perms;
};

export default useRoles;
