// This component will use css classes and other components from OrganizerCard => component in _commons
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Item, Icon } from '@common';
import default_tournament_image from '@img/default-tournament-image.png';
import default_logo from '@img/sample-logo.png';
import def_thumbnail from '@img/org-thumb.jpeg';

import moment from 'moment';
import { HiFolder } from 'react-icons/hi';
import { AppContext } from '../../../context/AppContext';
import { useSelector } from 'react-redux';
import './competition-card.scss';
import OrganiserItem from '../OrganiserCard/OrganiserItem';
import ShareOptions from '../ShareOptions/ShareOptions';
import ThreeDots from '../ThreeDots/ThreeDots';
import {
  getFolderOrCompetitonPermissions,
  getOrganiserPermissions
} from '../../../helpers/permissionsHelper';
import useContextMenu from '../ContextMenu/hooks/useContextMenu';
import ContextMenu from '../ContextMenu/ContextMenu';
import Options from '../ThreeDots/Options/Options';
const TournamentItemImageInner = ({ text, color, IconName, startTime }) => {
  return (
    <React.Fragment>
      <div className="image-cover"></div>
      {moment() < moment(startTime) ? (
        <span className={`tournament-item-image-inner ${color ?? ''}`}>
          <Icon name={IconName ?? 'clock'} />
          {text ?? ''}
        </span>
      ) : null}
    </React.Fragment>
  );
};

const CompetitionCard = (props) => {
  const {
    //New Props
    onHoverHandler = () => {},
    onClickHandler = () => {},
    onDoubleClickHandler = () => {},
    handleClearSelection = () => {},
    onContextMenu = () => {},
    image,
    title,
    _disabled = true,
    last_edited,
    thumbnail,
    ind = null,
    permissions = [],
    organiser,
    competition,
    type,
    logo,
    parent_organiser,
    compRef,
    showTrash
    //End
  } = { ...props };
  const { user } = useContext(AppContext);
  const { userPermissions, showDots } = useMemo(
    () => getFolderOrCompetitonPermissions(user, permissions),
    [permissions]
  );
  const { isVisible, x, y, showMenu, hideMenu } = useContextMenu();
  // useEffect(() => {
  // console.log({ userPermissions, showDots });
  // }, [userPermissions, showDots]);
  const handleContextMenu = (event) => {
    event.preventDefault();
    handleClearSelection(competition?._id, 'competitions');
    // onClickHandler(competition?._id,"competitions");
    showMenu(event);
  };
  return (
    <div
      className="organaizer-card-main-div competitionCard-mainDiv multiselectItem-cls" //!multiselectItem-cls is for using multiselect DONt Remove
      key={competition?._id ?? ind}
      id={competition?._id ?? ind}
      ref={compRef}
      onMouseEnter={() => onHoverHandler(competition?._id, 'IN')}
      onMouseLeave={() => onHoverHandler(competition?._id, 'OUT')}
      onContextMenu={handleContextMenu}
      onClick={(e) => {
        onClickHandler(e, competition?._id, 'competitions');
      }}
      onDoubleClick={onDoubleClickHandler}>
      <OrganiserItem
        thumbnail={thumbnail}
        isShowTrashfolders={showTrash}
        lastEdited={competition?.lastEditedDate}
        logo={logo}
        disabled={_disabled}
      />
      <div className="details-card-OC">
        <div className="logo-name-dots">
          <div className="logo-and-name">
            {logo && (
              <div className="logo-OC">
                <img src={logo ?? default_logo} />
                {/* <HiFolder color="#3eabf0" /> */}
              </div>
            )}
            <div className="item-title-OC">
              <h3 style={{ color: _disabled ? '#ffffff' : '#3eabf0' }}>{title ?? 'SteelSeries'}</h3>
            </div>
          </div>
          <div className="three-dots-OC">
            {showDots && (
              <ThreeDots
                userPermissions={userPermissions}
                user={user}
                type={type}
                disabled={_disabled}
                // organiser={organiser}
                competition={competition}
                onFirstClick={(e) => onClickHandler(e, competition?._id, 'competitions')}
              />
            )}
          </div>
        </div>
        {/* This Div is Additional for Component Card */}
        <div className="parent-of-component">
          <div className="logo-CC">
            {/* <img src={logo ?? default_logo}/> */}
            <HiFolder color={_disabled ? '#8FA3B1' : "#3eabf0"}/* "#3eabf0" */ size={16} />
          </div>
          <div className="item-title-CC">
            <h3 style={{ color: _disabled ? '#8FA3B1' : '#3eabf0' }}>
              {parent_organiser ?? 'SteelSeries'}
            </h3>
          </div>
        </div>
        {/* This Div is Additional for Component Card */}
        <div
          className="last-edited-OC"
          style={_disabled ? { color: '#68717e' } : { color: '#296f9e' }}>
          {last_edited ?? 'Last Edited: 29/11-2019 by Fredrik Hockauf'}
        </div>
      </div>
      {showDots && isVisible && !_disabled && (
        <ContextMenu x={x} y={y} onClose={hideMenu} isVisible={isVisible}>
          <Options type={type} competition={competition} userPermissions={userPermissions} />
        </ContextMenu>
      )}
    </div>
  );
};

export default CompetitionCard;
