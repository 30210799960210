import React, { Component } from 'react';
import { default as ReactSelect } from 'react-select';
import './multiselect-dropdown.scss';
import { components } from 'react-select';
import { useState } from 'react';
import { useEffect } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { bracketOutcomes } from '../../Pages/MyCompetitions/ConpetitionTabs/Stages/outcomesData';
function MultipleSelect({
  placeholder = 'Choose Stage(s)',
  options = [],
  handleChange,
  disabled = false,
  showPlaceholder = false
}) {
  const [Selected, setSelected] = useState([]);
  const [open,setOpen] = useState(false)
  
  useEffect(() => {
    if (options.length > 0) {
      setDefaultOptions(options);
    }
  }, [options]);

  const setDefaultOptions = (options) => {
    let _selected = options.map((option) => {
      if (option?.selected) {
        return {
          ...option,
          label: option.label,
          value: option.value
        };
      }
    });
    setSelected(_selected);
    return;
  };
  const styles = {
    main: '#171a22'
  };
  const placeholder01 = showPlaceholder
    ? {
        Option,
        // DropdownIndicator,
        MultiValueRemove: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        ValueContainer: CustomValueContainer,
        closeMenuOnScroll: () => null
      }
    : {
        Option,
        // DropdownIndicator,
        MultiValueRemove: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null
        // MultiValueRemove: () => null
      };
    
  return (
    <ReactSelect
      menuIsOpen={open}
      onMenuOpen={()=> setOpen(true)}
      onMenuClose={()=> setOpen(false)}
      options={options}
      //   defaultValue={
      //     format === 2
      //       ? [bestOf2[0], bestOf2[1], bestOf2[3]]
      //       : [bestOf[0], bestOf[1], bestOf[2], bestOf[4]]
      //   }
      isDisabled={disabled}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      // components={{
      //   Option,
      //   // DropdownIndicator,
      //   MultiValueRemove: () => null,
      //   IndicatorSeparator: () => null,
      //   ClearIndicator: () => null
      //   // MultiValueRemove: () => null
      // }}
      components={placeholder01}
      // menuIsOpen={true}
      captureMenuScroll={false}
      isSearchable={false}
      styles={{
        // indicatorsContainer: (style) => ({
        //   ...style,
        //   display: 'revert',
        // }),
        // DropdownIndicator:(style) => ({
        //     ...style,
        //     display: 'revert',
        //   }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          height: '15px',
          /* UI Properties */
          textAlign: 'left',
          font: 'normal normal normal 12px/15px Montserrat',
          letterSpacing: '0px',
          // color: '#FFFFFF',
          color: '#818386'
          // opacity: '0.5'
        }),
        container: (baseStyles, state) => ({
          ...baseStyles,
          width: '100%',
          borderRadius: '2px',
          //   height: '40px',
          //   opacity: 1,
          //   background: 'transparent',
          // border: '1px solid #22262E',
          marginTop: '5px'
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: 'auto',
          height: '44px',
          opacity: 1,
          border: state.isFocused ? '1px solid #3eabf0' : '1px solid #22262E',
          background: 'transparent',
          //   border: '1px solid #22262E',
          borderRadius: '2px',
          //   color: 'white',
          //   backgroundColor: 'blue',
          //   height: '44px',
          // border: state.isFocused ? '1px solid #3eabf0' : '1px solid #22262E',
          //   borderRadius: '2px',
          //   opacity: 1,
          ':hover': {
            border: '1px solid var(--primary)'
          }
        }),
        menu: (base) => ({
          ...base,
          // padding: '8px 0px 0px 5px',
          // margin: 0,
          // maxHeight: '200px',
          // border: '1px solid',
          // borderRadius: '2px',
          // border: '1px solid #22262e',
          // overflow: 'auto',
          marginTop: '0px',
          background: 'transparent'
        }),
        multiValue: (styles, { data }) => ({
          background: 'transparent',
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          color: '#68717E',
          '&:not(:first-child)::before': {
            content: '","'
          }
        }),
        multiValueLabel: (styles, { data }) =>
          Selected.filter((el) => el).length > 1
            ? {
                ...styles,
                // color: '#7e8fa8',
                color: '#68717E',
                //   backgroundColor: 'transparent',
                border: 0,
                padding: 0,
                margin: 0,
                textTransform: 'capitalize'
                // '&:after': {
                //   content: '","'
                // }
              }
            : {
                ...styles,
                // color: '#7e8fa8',
                color: '#68717E',
                //   backgroundColor: 'transparent',
                border: 0,
                padding: 0,
                margin: 0,
                textTransform: 'capitalize'
              },

        // options: (baseStyles, state) => ({
        // }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
          marginTop: '0px',
          // background: styles.main,
          background: '#03060D 0% 0% no-repeat padding-box',
          border: '1px solid #22262E',
          maxHeight: '200px'
        }),
        option: (baseStyles, state) => ({
          //   ...baseStyles,
          background: 'transparent',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignContent: 'flex-start',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '10px 0 10px 15px'
        })
      }}
      placeholder={placeholder}
      onChange={(arr) => {
        let _selectedIds = arr.map((el) => el._id);
        let _options = options.map((option) => {
          if (_selectedIds.includes(option._id)) {
            return {
              ...option,
              selected: true
            };
          } else {
            return { ...option, selected: false };
          }
        });
        handleChange(_selectedIds, _options);
      }}
      allowSelectAll={true}
      value={Selected}
    />
  );
}
const Option = (props) => {
  const label = {
    height: '15px',
    /* UI Properties */
    textAlign: 'left',
    font: 'normal normal normal 12px/15px Montserrat',
    letterSpacing: '0px',
    color: '#ffffff',
    opacity: 1,
    paddingLeft: '15px'
  };
  return (
    <div style={{ width: '100%' }}>
      <components.Option {...props}>
        <input
          id="multiselect-CheckBox"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        {props?.data?.flag ? (
          props?.data?.label === 'Everyone' ? (
            <div
              style={{
                marginLeft: '5px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {props?.data?.flag}
            </div>
          ) : (
            <img
              src={`https://www.flagcdn.com/128x96/${props?.data?.flag.toLowerCase()}.png`}
              width="30px"
              style={{ marginLeft: '5px' }}
            />
          )
        ) : null}
        <label style={label} className="multislect-label">
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};
// const DropdownIndicator = props => {
//     const { selectProps } = props;
//     let arrow;
//     if (selectProps.menuIsOpen) {
//      arrow = <FaCaretUp size="12px" />;
//     } else {
//      arrow = <FaCaretDown size="12px" />;
//     }
//     return <components.DropdownIndicator {...props}>{arrow}</components.DropdownIndicator>;
//    }
const CustomValueContainer = ({ children,...props }) => {
  const { Placeholder, ValueContainer } = components;

  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {/* {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )} */}
    </ValueContainer>
  );
};
export default MultipleSelect;
