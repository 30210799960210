import Axios from 'axios';
import { toast } from 'react-toastify';
import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import StageContent from './StageContent';
import { BiRefresh } from 'react-icons/bi';
import SwitchButton from '../../../../_common/switch/SwitchButton';
import { useSelector } from 'react-redux';
import useDidMountEffect from '../../../../../helpers/useDidMountEffect';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  setStageIndexGlobally,
  setIsAnyChangeInTab
} from '../../../../../redux/action/CompetitonData';
const StageTab = ({ stages, competitionId, getLeaderboard, defaultLogo, shortName, loadingState }) => {
  const [manually, setManually] = useState(false);
  const [activeGroup, setActiveGroup] = useState('');
  const [unsave, setUnsaved] = useState();
  const [activeStage, setActiveStage] = useState(null);
  const [unsavedStages, setUnsavedStages] = useState([]);
  /* Below code is added for keeping stageIndex across different tabs */
  const dispatch = useDispatch();
  const stageIndexGlobally = useSelector((state) =>
    state.COMPETITION?.StageIndex ? state.COMPETITION.StageIndex : 0
  );
  useEffect(() => {
    if (stages.length === 0) return;
    if (stageIndexGlobally !== 0) {
      let _stageIndexGlobally = stageIndexGlobally - 1;
      let _stage = stages[_stageIndexGlobally];
      const _activeStage = unsavedStages.find((stage) => stage._id === _stage._id);
      if (_activeStage) {
        setActiveStage({ ..._activeStage });
      } else {
        if (_stage) {
          setActiveStage(JSON.parse(JSON.stringify(_stage)));
        } else {
          _stage = stages[_stageIndexGlobally - 1];
          setActiveStage(JSON.parse(JSON.stringify(_stage)));
        }
      }
    }
  }, [stages]);
  useDidMountEffect(() => {
    let _stageIndex = stages.findIndex((stage) => stage._id === activeStage._id);
    let _activeStage = _stageIndex + 1;
    if (stageIndexGlobally !== _activeStage) {
      dispatch(setStageIndexGlobally(_activeStage));
    }
  }, [activeStage]);
  useEffect(() => {
    if (unsave === true) {
      // saveStageChanges()
      dispatch(setIsAnyChangeInTab(true));
    } else {
      dispatch(setIsAnyChangeInTab(false));
    }
  }, [unsave]);
  useEffect(() => {
    if (manually === true) {
      // saveStageChanges()
      setUnsaved(true);
    } else {
      setUnsaved(false);
    }
  }, [manually]);
  /* above code is added for keeping stageIndex across different tabs */
  const sortDescendingScore = (teams) => {
    return teams.sort((a, b) => b.score - a.score);
  };

  const sortTeams = (teams = [], isManualSort = false) => {
    teams = teams.filter((team) => team && !!team.team);
    return isManualSort ? teams.sort((a, b) => a.order - b.order) : sortDescendingScore(teams);
  };
  const addRemoveUnsavedStage = (obj) => {
    let _index = obj.index;
    switch (obj.type) {
      case 'ADD':
        let _unsavedStages = [...unsavedStages];
        _unsavedStages = _unsavedStages.filter((el) => el?._id !== _index?._id);
        return setUnsavedStages([..._unsavedStages, _index]);
      case 'REMOVE':
        return setUnsavedStages((prevState) => prevState.filter((el) => el?._id !== _index?._id));
    }
  };

  const setStage = (stage) => {
    let teams;
    let groupIndex = -1;
    if (activeGroup === '') {
      groupIndex = 0;
      teams = stage.groups[groupIndex].teams;
    } else {
      stage.groups.filter((group, i) => {
        if (group.name === activeGroup) {
          groupIndex = i;
        }
      });
      teams = stage.groups[groupIndex].teams;
    }
    let sortedTeams = sortTeams(teams, stage.groups[groupIndex].overviewManualSort);
    stage.groups[groupIndex].teams = sortedTeams;
    const _activeStage = unsavedStages.find((_stage) => _stage._id === stage._id);
    if (_activeStage) {
      setActiveStage(_activeStage);
    } else {
      setActiveStage(JSON.parse(JSON.stringify(stage)));
    }
    // setActiveStage(stage);
  };

  const getGroups = () => {
    return stages.map((stage, index) => {
      if (stage.name != activeStage?.name) return;

      return stage.groups.map((group, innerIndex) => {
        if (stage.name == activeStage?.name && activeGroup == '' && innerIndex == 0) {
          setActiveGroup(group.name);
        }

        return (
          <button
            className={activeStage?.name == stage.name && activeGroup == group.name ? 'active' : ''}
            key={group?._id ?? innerIndex}
            onClick={() => {
              // setActiveStage(stage);
              const _activeStage = unsavedStages.find((stage) => stage._id === stage._id);
              if (_activeStage) {
                setActiveStage(_activeStage);
              } else {
                setActiveStage(JSON.parse(JSON.stringify(stage)));
              }
              setActiveGroup(group.name);
            }}>
            <div>{group.name}</div>
          </button>
        );
      });
    });
  };

  const saveChange = () => {
    let teams = [];
    let group = activeStage.groups.filter((group) => group.name === activeGroup);
    if (!manually) {
      teams = group[0].teams.map((team) => ({
        ...team,
        overviewManualSort: false,
        team: team.team,
        order: null
      }));
    } else {
      teams = group[0].teams.map((team, i) => {
        team.order = i;
        team.team = team.team;
        team.overviewManualSort = true;
        return team;
      });
    }
    group[0].teams = teams;
    Axios.put(
      `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competitionId}/stage/${activeStage._id}/group/leaderboard`,
      { group: group[0] }
    )
      .then((response) => {
        setUnsaved(false);
        addRemoveUnsavedStage({ type: 'REMOVE', index: activeStage });
        toast.success(response.data.msg);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.msg);
          return;
        }
        toast.error(error.message);
      });
  };

  const reCalculateScoreboard = (data, stage) => {
    if (stage) {
      Axios.post(
        `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competitionId}/recalculateStageScoreboard`,
        data
      )
        .then((response) => {
          toast.success(response.data.msg);
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.msg);
            return;
          }
          toast.error(error.message);
        });
    } else {
      // localhost:8091/api/organizer/competition/6399bf26d6b18e1c8d559f13/recalculateGroupScoreboard
      Axios.post(
        `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competitionId}/recalculateGroupScoreboard`,
        data
      )
        .then((response) => {
          toast.success(response.data.msg);
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.msg);
            return;
          }
          toast.error(error.message);
        });
    }
    // Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${competitionId}/recalculateStageScoreboard`, data).then(response => {
    //     toast.success(response.data.msg)
    // }).catch(error => {
    //     if (error.response) {
    //         toast.error(error.response.data.msg)
    //         return
    //     }
    //     toast.error(error.message)
    // })
  };

  const orderChange = (result) => {
    if (!result.destination) {
      toast.info("can't drage element out side the container");
      return;
    }
    if (!manually) {
      toast.info('Please enable manually choose places');
      return;
    }
    if (result.source.index == result.destination.index) {
      toast.info('Invalid replacement');
      return;
    }

    let currentStage = activeStage;
    let groupIndex = -1;
    let group = currentStage.groups.filter((group, i) => {
      if (group.name === activeGroup) {
        groupIndex = i;
        return group;
      }
    });
    let sourceTeam = group[0].teams[result.source.index];
    let desctinationTeam = group[0].teams[result.destination.index];
    group[0].teams[result.destination.index] = sourceTeam;
    group[0].teams[result.source.index] = desctinationTeam;
    // console.log('update group after change placements ', group[0]);
    currentStage.groups[groupIndex] = group[0];
    setActiveStage({ ...currentStage });
  };
  const allUnsavedStages = useMemo(() => {
    let differentStages = [];
    const _stages = stages;
    let _unsaved = unsavedStages;
    _unsaved.forEach((stage) => {
      let mainStage = _stages.find((el) => el._id === stage._id);
      if (_.isEqual(stage, mainStage)) {
        return;
      } else {
        return differentStages.push(stage._id);
      }
    });
    return differentStages;
  }, [stages, unsavedStages]);
  return (
    <div className="tabs-container-1">
      <div className="teams-tab-container">
        <div className="tabs-nav" style={{ borderBottom: 0, marginTop: 0 }}>
          <nav style={{ maxWidth: 868, overflow: 'scroll' }}>
            {stages.map((stage, index) => {
              if (index == 0 && activeStage == null) {
                setStage(stage);
              }
              return (
                <button
                  className={activeStage?.name == stage.name ? 'active' : ''}
                  key={stage?.id}
                  onClick={() => {
                    setStage(stage);
                    setActiveGroup('');
                  }}>
                  <div className={allUnsavedStages.includes(stage?._id) ? 'unsaved-stage' : ''}>
                    {stage?.name}
                  </div>
                  {/* <div>{stage.name}</div> */}
                </button>
              );
            })}
            <div
              style={{
                width: 30,
                borderLeftWidth: 1,
                borderLeftStyle: 'solid',
                borderLeftColor: '#22262e'
              }}>
              <span></span>
            </div>
            {getGroups()}
          </nav>
          <div className="side-buttons">
            <SwitchButton
              label={'manually choose places'}
              onChange={() => setManually(!manually)}
            />
            <button
              className="button-primary"
              onClick={() => {
                saveChange();
                getLeaderboard();
              }}>
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <div className={`tab-content`}>
        {activeStage && (
          <StageContent
            shortName={shortName}
            onDragEnd={orderChange}
            manually={manually}
            stage={activeStage}
            loadingState={loadingState}
            stageIndex ={stages.findIndex(stage=> stage?._id === activeStage?._id)}
            selectedGroup={activeGroup}
            onChange={(updated) => {
              let currentStage = activeStage;
              let groupIndex = -1;
              let group = currentStage.groups.filter((group, i) => {
                if (group.name === activeGroup) {
                  groupIndex = i;
                  return group;
                }
              });

              let team = group[0].teams[updated.index];
              if (updated?.score) {
                team.score = parseInt(updated.score);
              } else if (updated?.mapsWon) {
                team.mapsWon = parseInt(updated.mapsWon);
              } else if (updated?.mapsLost) {
                team.mapsLost = parseInt(updated.mapsLost);
              }
              group[0].teams[updated.index] = team;
              currentStage.groups[groupIndex] = group[0];
              // console.log('reading => ', currentStage);
              setActiveStage(currentStage);
              addRemoveUnsavedStage({ type: 'ADD', index: currentStage });
              setUnsaved(true);
              defaultLogo = { defaultLogo };
            }}
          />
        )}
      </div>
      <Row style={{ marginTop: 15, paddingLeft: 10 }}>
        <Col md={12}>
          <button
            className="button"
            style={{ backgroundColor: '#68717E', marginRight: 15 }}
            onClick={() => {
              let group = activeStage.groups.filter((group) => group.name === activeGroup);
              reCalculateScoreboard(
                {
                  groupId: group[0]._id,
                  stageId: group[0].stage,
                  stageType: activeStage.stageType,
                  leagueId: activeStage?.league?._id
                },
                false
              );
            }}>
            <BiRefresh color={'#FFFFFF'} size={20} style={{ verticalAlign: 'bottom' }} />
            RECALCULATE GROUP
          </button>

          <button
            className="button"
            style={{ backgroundColor: '#68717E' }}
            onClick={() => {
              let group = activeStage.groups.filter((group) => group.name === activeGroup);
              reCalculateScoreboard(
                {
                  stageId: group[0].stage,
                  leagueId: activeStage?.league?._id,
                  stageType: activeStage.stageType
                },
                true
              );
            }}>
            <BiRefresh color={'#FFFFFF'} size={20} style={{ verticalAlign: 'bottom' }} />
            RECALCULATE STAGE
          </button>
        </Col>
      </Row>
    </div>
  );
};
export default StageTab;
