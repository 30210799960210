import { SET_INFORMATION_PREVIEW_DATA, SET_PRIZES_PREVIEW_DATA } from "../action/types";

const initialState = {
    Prizes: null,
    Information: null
}
const previewTabsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRIZES_PREVIEW_DATA:
            return{
                ...state,
                Prizes: action.payload
            }
        case SET_INFORMATION_PREVIEW_DATA:
            return{
                ...state,
                Information:action.payload
            }
    
        default:
          return state;
      }
}

export default previewTabsReducer