import React, { useEffect, useState } from 'react';
import { Card, Nav } from 'react-bootstrap';
import SetPlayers from './SetPlayers';
import { computeSegEndResizable } from '@fullcalendar/react';
import './_setplayers.scss';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';

const MatchSetPlayersModal = ({ show, onHide, match, updateMatches, league, flag, isScrim, game = null, updateMatchRecords }) => {
  const [activeTab, setActiveTab] = useState(0);

  if (!match) return null;

  const { t1 = {}, t2 = {},sp1 = {},sp2 = {},isSeedingMatch } = match;
  // console.log("t1: ", t1)
  // console.log("t2: ", t2)


  const tabs = [
    {
      name: isSeedingMatch ? `Seed ${sp1?.seedPosition}` : t1 ? `${t1?.name}` : '[DELETED]',
      content: t1 ? (
        <SetPlayers
          onClose={onHide}
          match={match}
          updateMatches={updateMatches}
          league={league}
          teamData={t1}
          teamSelector="t1"
          isScrim={isScrim}
          flag={flag}
          game={game}
          updateMatchRecords={updateMatchRecords}
        />
      ) : (
        'Team not found.'
      )
    },
    {
      name: isSeedingMatch ? `Seed ${sp2?.seedPosition}` : t2 ? `${t2?.name}` : '[DELETED]',
      content: t2 ? (
        <SetPlayers
          onClose={onHide}
          match={match}
          updateMatches={updateMatches}
          league={league}
          teamData={t2}
          teamSelector="t2"
          isScrim={isScrim}
          flag={flag}
          game={game}
          updateMatchRecords={updateMatchRecords}
        />
      ) : (
        "Team not found."
      ),
    },
  ];

  return (
    <Modal
      isOpen={show}
      onRequestClose={onHide}
      className="modal editCategory"
      // shouldCloseOnEsc={false}
    >
      <div
        className="modal-close"
        onClick={() => {
          onHide();
        }}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Manually Set Players</h2>
      </div>
      <div className="modal-body">
        <div>
          <div style={{ borderRadius: 0, border: 0, margin: 0 }}>
              <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(parseInt(t))}>
                {tabs.map(({ name }, i) => (
                  <Nav.Item key={i}>
                    <Nav.Link eventKey={i} style={{color:activeTab===i?"#3eabf0":''}}>{name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            <div className='test'>
              {tabs.map(({ content }, i) => i === activeTab && <div key={i}>{content}</div>)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MatchSetPlayersModal;
