import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@common';
import { BiChip } from 'react-icons/bi';

const DropdownHeader = ({ title, dots_menu }) => {
  if (typeof dots_menu == 'undefined') {
    return (
      <header>
        <h3>{title}</h3>
      </header>
    );
  }

  return (
    <header>
      <h3>{title}</h3>
      <label htmlFor="dropdown-dotted-button-menu-box" className="dropdown-dotted-button">
        <span></span>
        <span></span>
        <span></span>

        <input type="checkbox" id="dropdown-dotted-button-menu-box" />
        <div className="dropdown-dotted-button-menu">
          {dots_menu.map((i) => (
            <div key={i} onClick={i[1]}>
              {i[0]}
            </div>
          ))}
        </div>
      </label>
    </header>
  );
};

const Dropdown = ({
  games,
  children,
  label,
  image,
  title,
  badge,
  showCaret,
  dots_menu,
  dynamic_width,
  className,
  footer,
  render,
  isGameSwitcher = false,
  istrimezone,
  showDropdown,
  isAnimation = false
}) => {
  const dropdown = useRef();
  const [showDropdowns, setshowDropdowns] = useState(false);
  useEffect(() => {
    document.addEventListener('mousedown', checkOutsideClick);
  }, []);

  const checkOutsideClick = (e) => {
    // if click outside div
    if (!istrimezone) {
      if (dropdown.current && !dropdown.current.contains(e.target)) {
        setshowDropdowns(false);
    }   
  }
 else{
  if (dropdown.current && dropdown.current.contains(e.target)) {
    setTimeout(()=>{
      setshowDropdowns(false);

    },[3000])
}   
 }
  };
  useEffect(() => {
    setshowDropdowns(showDropdown);
  }, [showDropdown]);
  let classes = 'dropdown-wrapper';
  if (showDropdowns) classes += ' show';
  if (typeof dynamic_width !== 'undefined') classes += ' dynamic-width';
  if (typeof className !== 'undefined') classes += ' ' + className;
  if (isGameSwitcher) classes += ' gc-box';

  return (
    <div ref={dropdown} className={classes + ' toggle-game'} style={{ marginRight: '0px' }}>
      <span
        style={{ paddingLeft: '0px' }}
        className={`${showDropdowns ? 'dropdown-toggle dropdown-toggle1' : 'dropdown-toggle'}`}
        onClick={() => {
          setshowDropdowns(!showDropdowns);
          // setshowDropdowns(true)
        }}>
        <div>
          {image && (
            <div className="image" style={{ marginLeft: '20px' }}>
              <BiChip size={17} />{' '}
            </div>
          )}
          <span className="label">{label}</span>
          {!!badge && <span className="badge">{badge}</span>}
        </div>
        {showCaret && (
          <span className="caret">
            <Icon name="triangle-down" />
          </span>
        )}
      </span>
      {/* <span className="gc-bottom-content"></span> */}
      <span className="gc-top-content"></span>

      {/* {isGameSwitcher && <div className="empty z_index"></div>} */}
      <div
        className={`dropdown-content ${isAnimation && showDropdowns ? 'dropdown_menu-2' : ''} ${
          isGameSwitcher ? 'edge' : 'translation-dropdown'
        } `}
        style={
          isGameSwitcher
            ? {
                backgroundColor: '#191b24',
                height: showDropdowns && games ? `${games * 34 + 34 + 20}px` : ''
              }
            : {}
        }>
        {title ? <DropdownHeader title={title} dots_menu={dots_menu} /> : null}

        <main className={`scroll-gradient`}>{render(showDropdowns, setshowDropdowns)}</main>
        {/* <span className="right-corner-dropdown"></span> */}

        {footer ? <footer>{footer}</footer> : null}
        {/* <div class="bottom-image-cover forGameSelector"></div> */}
      </div>

      <span className="gc-top"></span>
      <span className="gc-bottom"></span>
      <span className="gc-left"></span>
      <span className="gc-right"></span>
      <span className="gc-right-corner"></span>
      <span className="gc-left-corner"></span>
    </div>
  );
};

export default Dropdown;
