import React from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import CreateCompetitionModalFunctionality from './CreateCompetitionModalFunctionality';
const CreateCompetitionModal = ({ open, onClose, organizerdata, isEditorganizer }) => {
  return (
    <Modal
      isOpen={open}
      className="modal"
      contentLabel="Example Modal"
      style={{ backgroundColor: '#171a22' }}>
      <div className="modal-close" onClick={() => onClose(false)}>
        <RiCloseLine color='#68717e'   style={{marginRight:'35px' ,marginTop:'4px'}} />
      </div>
      <div className="modal-header" style={{ textAlign: 'left' }}>
        <h2>{isEditorganizer ? 'Edit organizer' : 'Create organizer'}</h2>
        <h3 style={{marginTop:'-14px'}}>Add your organizer logo and information.</h3>
      </div>
      <div className="modal-body">
        <CreateCompetitionModalFunctionality
          onClose={onClose}
          organizerdata={organizerdata}
          isEditorganizer={isEditorganizer}
        />
      </div>
    </Modal>
  );
};

export default CreateCompetitionModal;
