// import data from '../../data'

// import {data} from '../../data'
import { SET_TRANSLATION } from '../action/types';
const initialState = {
  Translation: []
};

// Use the initialState as a default value
export default function translationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSLATION:
      return {
        ...state,
        Translation: action.payload
      };

    default:
      return state;
  }
}
