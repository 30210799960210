import React, { useEffect, useRef, useContext, useMemo } from 'react';
import { Folder } from '@common';
import AddNewFolder from '../Folder/AddNewFolder';
import GridTornaments from './GridTornaments';
import { useDispatch, useSelector } from 'react-redux';
import { getFolders, getOrganizer } from '../../../redux/action/competitionActions';
import { setSelctedFolders } from '../../../redux/action/dragNDropActions';
import { useState } from 'react';
import ItemsDragLayer from './ItemsDragLayer';
import Card from './Card';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../Loading/Loading';
import Editbar from '../../Pages/MyOrganizations/Organizer/Editbar';
import { AppContext } from '../../../context/AppContext';
import {
  canCreateFolder,
  checkDeletePermForMultipleItems,
  checkDeletePermission
} from '../../../helpers/permissionsHelper';
import DragNDropWrapper from '../DragNDropWrapper/DragNDropWrapper';
import CompetitionCard from '../CompetitionCard/CompetitionCard';
import useMultiSelect from '../../../multiselectionHelpers/hooks/useMultiselection';
import { CustomDragLayerGrid } from '../DragNDropWrapper/CustomDragLayerGrid';
import { useDragLayer } from 'react-dnd';
import useHandleClickOutside from '../ContextMenu/hooks/useHandleClickOutside';
import DeleteItems from '../../_modals/OrganizerPopup/deleteitems/delete';
import moment from 'moment';
import useClickOutsideNav from '../ContextMenu/hooks/useClickOutsideNav';
const styles = {
  main: {
    width: '50%',
    margin: '0 auto',
    textAlign: 'center'
  },
  content: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'left',
    alignItems: 'stretch',
    alignContent: 'stretch'
  }
};
function GridView({ parentFolder, handleFolderSelection, clearItemSelection, handleKeyDown }) {
  const selectedFolder = useSelector((state) => state.DRAGNDROP.SelectedFolders ?? []);
  const { user } = useContext(AppContext);
  const folderRefs = useRef([]);
  const [hoveredId, setHoveredId] = useState(null);
  const competitionsRef = useRef(null);
  const selected = useRef(null);
  const [unSelected, setUnSelected] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);
  const dispatch = useDispatch();
  const [addnewfolder, setAddnewfolder] = useState();
  let [selectedFolders, setSelectedFolders] = useState(0);
  const [canCreate, setCanCreate] = useState(false);
  const { id, folderId } = useParams();
  //*New Work
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const inheritedPermissions = useSelector((state) =>
    state.FOLDERS?.InheritedPermissions ? state.FOLDERS.InheritedPermissions : []
  );
  const handleDelete = (type, selected) => {
    const { folders, competitions } = selected;

    const folderObjects = parentFolder?.folders
      ? parentFolder?.folders.filter((elem) => folders.includes(elem?._id))
      : [];
    const compObjs = parentFolder?.competitions
      ? parentFolder?.competitions.filter((elem) => competitions.includes(elem?._id))
      : [];
    const allowed = checkDeletePermForMultipleItems(user, inheritedPermissions, [
      ...folderObjects,
      ...compObjs
    ]);
    if (type && allowed) {
      setDeleteType(type);
      setOpenDelete(!openDelete);
      // }
    }
  };

  const {
    selectedItems,
    handleSelect,
    handleSelectAll,
    handleClearSelection,
    handlePreventSelection
  } = useMultiSelect(parentFolder, handleDelete);
  const onHoverHandler = (id, type) => {
    switch (type) {
      case 'IN':
        return setHoveredId(id);
      case 'OUT':
        return setHoveredId(null);
      default:
        return setHoveredId(null);
    }
  };
  const history = useHistory();
  const showDelete = useMemo(() => {
    const { folders, competitions } = selectedItems;
    if (parentFolder) {
      const folderObjects = parentFolder?.folders
        ? parentFolder?.folders.filter((elem) => folders.includes(elem?._id))
        : [];
      const compObjs = parentFolder?.competitions
        ? parentFolder?.competitions.filter((elem) => competitions.includes(elem?._id))
        : [];
      return checkDeletePermForMultipleItems(user, inheritedPermissions, [
        ...folderObjects,
        ...compObjs
      ]);
    }
  }, [selectedItems]);
  const getObjectFromId = (typ, id) => {
    if (typ === 'folders') {
      // debugger
      return parentFolder?.folders.find((folder) => folder._id === id);
    }
    if (typ === 'competitions') {
      return parentFolder?.competitions.find((competition) => competition._id === id);
    }
  };
  const getLastEdited = ({ lastEditedDate, lastEditor }) => {
    let _lastEdited = moment(lastEditedDate).format('DD/MM-YYYY');
    let editorName = lastEditor?.firstName + ' ' + lastEditor?.lastName;
    return ` Last Edited: ${_lastEdited} by ${editorName}`;
  };
  const onClickHandler = (e, id, type) => {
    // console.log({ id, type, selectedItems });
    handleSelect(e, id, type);
  };
  //*end
  // useEffect(() => {
  //   console.log({ parentFolder, selectedItems });
  // }, [parentFolder, selectedItems]);
  const updateData = () => {
    if (folderId) {
      dispatch(getFolders(folderId));
    }
    if (id) {
      dispatch(getOrganizer(id));
    }
  };
  useEffect(() => {
    if (typeof parentFolder === 'object') {
      let parentPerms = parentFolder?.organizerRoles ?? [];
      let can = canCreateFolder(user, [...parentPerms, ...inheritedPermissions]);
      setCanCreate(can);
    }
  }, [parentFolder, inheritedPermissions]);
  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)

    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log('unmounting...');
    clearItemSelection();
  }, []);

  const handleRouting = (id, type) => {
    if (type === 'FOLDER') {
      return history.push(`/folders/${id}`);
    }
    if (type === 'COMPETITION') {
      let url = `/competition/${id}`;
      window.open(url, '_blank').focus();
    }
  };

  useEffect(() => {
    updateData();
  }, [addnewfolder]);

  const loading = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging()
  }));

  //Will work in it later
  // useHandleClickOutside(competitionsRef,()=> {debugger})
  useClickOutsideNav((e) => {
    handleClearSelection();
  });
  return (
    <>
      {[...selectedItems?.competitions, ...selectedItems?.folders].length > 1 && showDelete ? (
        <Editbar
          showDelete={showDelete}
          unSelected={unSelected}
          selectedItems={selectedItems}
          handleClearSelection={handleClearSelection}
        />
      ) : <h3 style={{ height: '50px', marginTop: '-17px', display: 'flex', alignItems: 'center', marginBottom: 0 }}>FOLDERS</h3>} {/*adding style relatively to editbar*/}
      <div style={{ float: 'right', position: 'absolute', right: '0px', top: '-88px' }}>
        {canCreate && (
          <AddNewFolder
            isLastView={true}
            setAddnewfolder={setAddnewfolder}
            parentId={parentFolder?.children ? parentFolder._id : null}
            OrganizaionId={parentFolder.organizer ? parentFolder.organizer._id : parentFolder._id}
          />
        )}
      </div>
      {selectedFolder.length <= 1 && (
        <div className="col-12 " style={{ height: '50px' }}>
          {/* <span className="text-22 uppercase bold"> */}
          <CustomDragLayerGrid />
          {/* <span
            className="uppercase"
            style={{
              width: '79px',
              height: '19px',
              marginLeft: '-30px',
              font: 'normal normal bold 16px/19px Montserrat'
            }}> */}
          
          {/* <span className="color-primary outline">{year}</span> */}
          {/* </span> */}
        </div>
      )}
      {parentFolder && (
        <div
          ref={selected}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          className="remove-tab wrapper-ms-unique-cls"
          style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
          {parentFolder?.folders.map((folder, index) => {
            let _disabled = selectedItems?.folders.includes(folder._id)
              ? true
              : hoveredId === folder._id
              ? true
              : false;
            // debugger
            return (
              <DragNDropWrapper
                key={folder?._id}
                parent={parentFolder?._id}
                view="GRID"
                blurIt={_disabled && isDragging ? true : false}
                _item={{ element: folder, type: 'FOLDER', index: index }}
                _disabled={!_disabled}
                canCreate={canCreate}
                handleClearSelection={handleClearSelection}
                selectedItems={selectedItems}>
                <Folder
                  onHoverHandler={onHoverHandler}
                  forwardedRef={folderRefs}
                  folder={folder}
                  name={folder?.name}
                  user={user}
                  permissions={[...inheritedPermissions, ...folder.organizerRoles]}
                  disabled={!_disabled}
                  type={'FOLDER'}
                  folderId={folder._id}
                  onClickHandler={onClickHandler}
                  handleClearSelection={handleClearSelection}
                  handlePreventSelection={handlePreventSelection}
                  handleRouting={handleRouting}
                />
              </DragNDropWrapper>
            );
          })}
          {canCreate && (
            <AddNewFolder
              setAddnewfolder={setAddnewfolder}
              parentId={parentFolder?.children ? parentFolder._id : null}
              OrganizaionId={parentFolder.organizer ? parentFolder.organizer._id : parentFolder._id}
            />
          )}
        </div>
      )}
      {/* <div style={{border: '1px solid  #1d1f26'}} /> */}
      {/* <h3>COMPETITIONS</h3> */}
      {parentFolder?.competitions?.length > 0 ?(
        parentFolder?.competitions?.length == '1' ? (<h3>1 COMPETITION</h3>) : (<h3>{` ${parentFolder?.competitions?.length} COMPETITIONS`}</h3>)
      ): (<h3>NO COMPETITIONS</h3>)}
      <div style={{ display: 'flex', flexFlow: 'row wrap' }} className="wrapper-ms-unique-cls">
        {parentFolder?.competitions &&
          parentFolder?.competitions.map((competition, index) => {
            let _disabled = selectedItems?.competitions.includes(competition._id)
              ? true
              : hoveredId === competition._id
              ? true
              : false;
            return (
              <DragNDropWrapper
                key={competition?._id}
                parent={parentFolder?._id}
                blurIt={_disabled && isDragging ? true : false}
                _item={{ element: competition, type: 'COMPETITION', index: index }}
                _disabled={!_disabled}
                canCreate={canCreate}
                handleClearSelection={handleClearSelection}
                selectedItems={selectedItems}>
                <CompetitionCard
                  onHoverHandler={onHoverHandler}
                  last_edited={getLastEdited(competition)}
                  compRef={competitionsRef}
                  type={'COMPETITION'}
                  _disabled={!_disabled}
                  competition={competition}
                  handleClearSelection={handleClearSelection}
                  onClickHandler={onClickHandler}
                  onDoubleClickHandler={(e) => handleRouting(competition?._id, 'COMPETITION')}
                  permissions={[...inheritedPermissions, ...competition.organizerRoles]}
                  thumbnail={competition?.thumbnail}
                  logo={competition?.logo}
                  title={competition?.name}
                  parent_organiser={competition?.organizer?.name}
                />
              </DragNDropWrapper>
            );
          })}
      </div>
      {openDelete && (
        <DeleteItems
          open={openDelete}
          setDelete={() => setOpenDelete(!openDelete)}
          folder={
            deleteType === 'FOLDER'
              ? getObjectFromId('folders', selectedItems?.folders[0])
              : deleteType === 'COMPETITION'
              ? getObjectFromId('competitions', selectedItems?.competitions[0])
              : false
          }
          multipleDelete={deleteType === 'MULTIPLE' ? selectedItems : false}
          type={deleteType}
          handleClearSelection={handleClearSelection}
        />
      )}
    </>
  );
}

export default GridView;
