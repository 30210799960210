import { SET_TRASH } from '../action/types';
const initialState = {
  Trash: {}
};

// Use the initialState as a default value
export default function trashReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRASH:
      return {
        ...state,
        Trash: action.payload
      };
    default:
      return state;
  }
}
