import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Axios from 'axios';
import './edit-veto-modal.scss';
import { RiCloseLine } from 'react-icons/ri';
import { Form, Row, Col, Accordion, Button, Spinner, Card } from 'react-bootstrap';
import { useRef } from 'react';
import { toast } from 'react-toastify';
// const EditVetoModal = () => {
//   return (
//         <Modal isOpen={open} shouldCloseOnEsc={false} className="modal setScore-modal"> hello</Modal>
//   )
// }

// export default EditVetoModal

export default function EditVetoModal({ open, onClose, match }) {
  const [vetoSteps, setVetoSteps] = useState(null);
  const refer = useRef(null);
  const options = [ 'csgo', 'valorent',' lol', 'cod', 'fifa'];
  //   useEffect(() => {
  //     var acc = document.getElementsByClassName('accordion');
  //     var i;

  //     for (i = 0; i < acc.length; i++) {
  //       acc[i].addEventListener('click', function () {
  //         this.classList.toggle('active');
  //         var panel-1 = this.nextElementSibling;
  //         if (panel-1.style.maxHeight) {
  //           panel-1.style.maxHeight = null;
  //         } else {
  //           panel-1.style.maxHeight = panel-1.scrollHeight + 'px';
  //         }
  //       });
  //     }
  //   });
  //   useEffect(() => {
  //     console.log('Match data', match);

  //     if (match) {
  //       Axios.get(`https://api-csgo.leagues.gg/api/matches/${match._id}/veto`)
  //         .then(({ data }) => {
  //           // const messageError = data.steps.map((data) => ({ id: data.no, error: false }));
  //           // setSubmitError(messageError);
  //           // setSubmit(!data.steps.every((el) => el.map !== null));
  //           setVetoSteps(data.steps);
  //           console.log('veto steps => ', data.steps);
  //           // setLoading(false);
  //         })
  //         .catch((err) => {
  //           toast.error('There was a problem in getting veto steps');
  //         });
  //     }
  //   }, [match]);

  const handleClick = (e) => {
    let elem = e.target;
    let nextSibling = elem.nextElementSibling;
    if (nextSibling.style.maxHeight) {
      nextSibling.style.maxHeight = null;
    } else {
      nextSibling.style.maxHeight = nextSibling.scrollHeight + 'px';
    }
  };
  return (
    <Modal isOpen={open} shouldCloseOnEsc={false} className="modal setScore-modal">
      <div
        className="modal-close"
        onClick={() => {
          onClose(false);
        }}>
        <RiCloseLine color={'white'} />
      </div>
      <div className="modal-header header-horizontal-line">
        <h2 className="bold uppercase">Edit Veto</h2>
        <hr />
      </div>
      <div className="modal-body" style={{ padding: '50px' }}>
        {Array(2)
          .fill(1)
          .map((el, i) => {
            return (
              <div key={`PARENT-${i}`}>
                <button class="accordion" onClick={handleClick}>
                  {`section ${i + 1}`}
                </button>
                <div class="panel-1">
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p> */}
                  {Array(5)
                    .fill(1)
                    .map((el, i) => {
                      return (
                        <div key={`child-${i}`}>
                          <button className="accordion" onClick={handleClick}>
                            l
                          </button>
                          <div class="panel-1" style={{margin:'7px 10px'}}>
                            {/* <p key={`childer-${i}`}>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat.
                            </p> */}
                            <select  className='form-input'>
                                <option >please chose one option</option>
                                   {options.map((opt,ind)=> {
                                    return <option key={ind}>{opt}</option>
                                   })}
                            </select>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
      <div className="mt-4 modal-footer footer-horizontal-line">
        {/* <div className="col-6">
            <Row className="footer-buttons">
              <button
                type="submit"
                className="save-changes-btn"
                // onClick={() => {
                //   // debugger
                //   handleSubmit(matchData._id, payload);
                // }}
                >
                <span>SAVE CHANGES</span>
              </button>
              <button type="submit" className="cancel-changes-btn" 
            //   onClick={() => onClose(false)}
              >
                <span>CANCEL</span>
              </button>
            </Row>
        </div> */}
        <div >
          <Button type="button" className="mr-2" size="sm" variant="success">
            Reset Veto
          </Button>
          <Button className="mr-2" type="button" size="sm" variant="secondary">
            Cancel
          </Button>
          <Button type="button" size="sm" variant="success">
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
