import React, { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Row, Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { HiChevronDown, HiPlus, HiX } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  clearUnsavedStages,
  deleteStage,
  setIsAnyChangeInTab,
  setUnSavedStages,
  updateStage
} from '../../../../../redux/action/CompetitonData';
import {
  InformationLogo,
  MultipleSelect,
  MultiSelectDropDown,
  SelectWithDescription,
  SimpleTabs
} from '../../../../_common';
import ColorPicker from './ColorPicker';
import Outcomes from './Outcomes';
import RankingCriteria from './RankingCriteria';
import './stage.scss';
import { bracketOutcomes } from './outcomesData';
import SwitchButton from '../../../../_common/switch/SwitchButton';
import Brackets from './Brackets/Brackets';
import { useSelector } from 'react-redux';
import { bottom } from '@popperjs/core';
import _ from 'lodash';
const FORMATS = [
  { label: 'ROUND ROBIN', value: 1 },
  { label: 'BRACKET', value: 2 }
];
const BracketType = [
  { label: 'Single elimination', value: 1 },
  { label: 'Double elimination', value: 2 },
  { label: 'King of the hill', value: 3 },
  { label: 'Staircase', value: 4 },
  { label: 'Custom', value: 5 }
];

function Stage({
  currentStage,
  setPage,
  stageIndex,
  disableSave,
  setDisableSave,
  setDisableDelete,
  setUnsavedId,
  setIsChanged
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [disableSave, setDisableSave] = useState(false);
  const [isMatchesGenerated, setIsMatchesGenerated] = useState(false); //state for disabling fields
  const [StageSettings, setStageSettings] = useState(currentStage);
  const [format1, setFormat1] = useState([
    { value: 1, label: 'Best of 1' },
    { value: 2, label: 'Best of 2' },
    { value: 3, label: 'Best of 3' },
    { value: 4, label: 'Best of 4' },
    { value: 5, label: 'Best of 5' },
    { value: 6, label: 'Best of 6' },
    { value: 7, label: 'Best of 7' },
    { value: 8, label: 'Best of 8' },
    { value: 9, label: 'Best of 9' }
  ]);
  const [format2, setFormat2] = useState([
    { value: 1, label: 'Best of 1' },
    { value: 3, label: 'Best of 3' },
    { value: 5, label: 'Best of 5' },
    { value: 7, label: 'Best of 7' },
    { value: 9, label: 'Best of 9' }
  ]);
  const { register, handleSubmit, setValue, errors, setError, reset } = useForm();
  const [groupColors, setGroupColors] = useState([
    { name: 'Group A', noOfTeams: 0, color: '#19db60', colorName: 'Green', stage: '' },
    { name: 'Group B', noOfTeams: 0, color: '#d5901f', colorName: 'Yellow', stage: '' },
    { name: 'Group C', noOfTeams: 0, color: '#3eabf0', colorName: 'Blue', stage: '' },
    { name: 'Group D', noOfTeams: 0, color: '#f11857', colorName: 'Red', stage: '' },
    { name: 'Group E', noOfTeams: 0, color: '#88d1ff', colorName: 'Cyan', stage: '' },
    { name: 'Group F', noOfTeams: 0, color: '#d14419', colorName: 'Orange', stage: '' },
    { name: 'Group G', noOfTeams: 0, color: '#19d17c', colorName: 'Malachite', stage: '' },
    { name: 'Group H', noOfTeams: 0, color: '#e23ef0', colorName: 'Magenta', stage: '' },
    { name: 'Group I', noOfTeams: 0, color: '#a6f118', colorName: 'Kiwi', stage: '' },
    { name: 'Group J', noOfTeams: 0, color: '#7e8fa8', colorName: 'Gray', stage: '' }
  ]);
  const [currentFormat, setCurrentFormat] = useState(0);
  const [show, setShow] = useState('0');
  const [custom, setCustom] = useState('0');
  const [scroll, setScroll] = useState(0);
  const [manually, setManually] = useState(false);
  const [inputVal,setInputVal] = useState(StageSettings.teamSize);
  const[isOn,setIsOn] = useState(false)
  const matches = useSelector((state) => state.COMPETITION?.Matches?.data?.data ?? []);
  const UnsavedStages = useSelector((state) =>
    state.COMPETITION.UnsavedStages ? state.COMPETITION.UnsavedStages : []
  );
  // useEffect(()=>{
  useEffect(() => {
    // Disabling the input fields if matches are already generated
    if (matches.length > 0) {
      let _stageIndex = stageIndex + 1;
      let currentStagematches = matches[_stageIndex];
      let matchesExist = checkIfMatchesExists(currentStagematches); //it will return a boolean
      setDisableDelete(matchesExist);
      setIsMatchesGenerated(matchesExist);
    }
  }, [matches, stageIndex]);
  useEffect(() => {
    let available = StageSettings.scoreSystem.map((el) => el.bestOf);
    if (StageSettings.format === 1) {
      let _format1 = format1.map((el, ind) => {
        if (available.includes(el.value)) {
          return {
            _id: el.value,
            ...el,
            selected: true
          };
        } else {
          return {
            _id: el.value,
            ...el,
            selected: false
          };
        }
      });
      setFormat1(_format1);
    } else {
      let _format2 = format2.map((el) => {
        if (available.includes(el.value)) {
          return {
            _id: el.value,
            ...el,
            selected: true
          };
        } else {
          return {
            _id: el.value,
            ...el,
            selected: false
          };
        }
      });
      setFormat2(_format2);
    }
  }, [StageSettings.format]);
  useEffect(() => {
    // console.log('StageSettings', StageSettings);
    let EmtyGroup = StageSettings.groups.find((el) => el.name === '' || el.noOfTeams < 2);

    if (StageSettings.name === '' || EmtyGroup) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }

    const unsavedStage = UnsavedStages.find((el) => el._id === StageSettings._id);
    const _current = StageSettings;
    if (!_.isEqual(_current, unsavedStage)) {
      dispatch(setUnSavedStages(StageSettings));
    }
  }, [StageSettings]);
  const checkIfMatchesExists = (groups) => {
    // it will return a boolean
    if (groups?.length === 0 || !groups) return false;
    let count = false;
    groups.forEach((group) => {
      if (group.count > 0) return (count = true);
    });
    return count;
  };
  const onUpdateColour = (type, value, id) => {
    // if (type === 'groupTeams' && Number(value) < 2) return;
    setStageSettings((state) => {
      switch (type) {
        case 'groupName':
          const updatedGroupsName = state.groups.map((group) => {
            if (group._id === id) {
              return {
                ...group,
                name: value
              };
            }
            return group;
          });
          return {
            ...state,
            groups: updatedGroupsName
          };
        case 'groupColor':
          const updatedGroupsColor = state.groups.map((group) => {
            if (group._id === id) {
              return {
                ...group,
                color: value
              };
            }
            return group;
          });
          return {
            ...state,
            groups: updatedGroupsColor
          };
        case 'groupTeams':
          const updatedGroupsNoOfTeams = state.groups.map((group) => {
            if (group._id === id) {
              return {
                ...group,
                noOfTeams: Number(value)
              };
            }
            return group;
          });
          return {
            ...state,
            groups: updatedGroupsNoOfTeams
          };
        default:
          return state;
      }
    });
  };
  const handleAddGroup = (groups) => {
    let index = groups.length;
    if (index < 10) {
      groupColors[index]._id = Math.random() % 1000;
      groupColors[index].noOfTeams = StageSettings.groupSize;
      groupColors[index].stage = StageSettings._id;

      let { colorName, ...rest } = groupColors[index];
      setStageSettings((state) => ({ ...state, groups: [...state.groups, rest] }));
      toast.info('New Group Added.');
    } else {
      toast.error('Maximum no of Groups Reached');
    }
  };

  const getColorName = (color) => {
    let group = groupColors.find((group) => group.color === color);
    if (group) {
      return group.colorName;
    } else {
      return color;
    }
  };
  const deleteThisStage = (id, stageId) => {
    if (stageId) {
      // debugger
      dispatch(deleteStage(id, stageId));
      setPage((state) => {
        if (state > 0) {
          return state - 1;
        } else {
          return 0;
        }
      });
    }
  };
  const getNewSelection = (list1, list2, isUnion = false) =>
    list1.filter(
      (
        (set) => (a) =>
          isUnion === set.has(a.bestOf)
      )(new Set(list2.map((b) => b.bestOf)))
    );
  const handleBestOfChange = (selected, options) => {
    if (selected.length === 0) {
      toast.error('Stage must have at least one outcome selected');
      return;
    }
    if (options.length > 6) {
      setFormat1(options);
    } else {
      setFormat2(options);
    }
    let _newBestOf = bracketOutcomes.filter((el) => selected.includes(el.bestOf));
    let currentScoreSystem = StageSettings.scoreSystem;
    if (_newBestOf.length > currentScoreSystem.length) {
      let diff = getNewSelection(_newBestOf, currentScoreSystem);
      setStageSettings((state) => ({ ...state, scoreSystem: [...state.scoreSystem, ...diff] }));
    } else {
      let diff = getNewSelection(currentScoreSystem, _newBestOf);
      setStageSettings((state) => ({
        ...state,
        scoreSystem: state.scoreSystem.filter((el) => el.bestOf !== diff[0].bestOf)
      }));
    }
  };
  // useEffect(()=>{
  //   console.log('teamSize=>',StageSettings.teamSize);
  //   console.log('groupSize=>',StageSettings.groupSize);
  //   console.log('inputVal=>',inputVal);
  // },[StageSettings,inputVal])

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'groupSize' && (Number(value) < 2 || Number(value) >1000)) return;
    if (name === 'encounters' && Number(value) < 1) return;

    dispatch(setIsAnyChangeInTab(true));
    // let _StageSettings = {...StageSettings}
    setStageSettings((state) => {
      switch (name) {
        case 'name':
          return {
            ...state,
            name: value
          };
        case 'encounters':
          return {
            ...state,
            encounters: value
          };
        case 'evenGroupBySize':
          if (state.evenGroupBySize === false) {
            state.groups.forEach((element) => {
              element.noOfTeams = state.groupSize;
            });
          }
          return {
            ...state,
            evenGroupBySize: !state.evenGroupBySize
          };
        case 'format':
          let bracket = [1, 3, 5];
          if (value === '1') {
            bracket = [1, 2, 3, 5];
          }
          let scoreS = bracketOutcomes
            .map((el) => {
              if (bracket.includes(el.bestOf)) {
                return { ...el };
              }
            })
            .filter((el) => el);
          if (value === '2') {
            return {
              ...state,
              format: Number(value),
              bracketType: 1,
              scoreSystem: scoreS
            };
          } else {
            return {
              ...state,
              format: Number(value),
              scoreSystem: scoreS
            };
          }
        case 'homeTeam':
          return {
            ...state,
            homeTeam: value
          };
        case 'pairingProcedure':
          return {
            ...state,
            pairingProcedure: value
          };
        case 'bracketType':
          return {
            ...state,
            bracketType: value
          };
        case 'groupSize':
          if (Number(value) < 2) return state;
          let _val = Number(value);
          let _groups = StageSettings.groups.map((el) => ({ ...el, noOfTeams: _val }));
          return {
            ...state,
            groupSize: _val,
            groups: _groups
          };
        case 'teamSize':
          let _valTeams = Number(value);
          return {
            ...state,
            teamSize: _valTeams
          };
        default:
          return state;
      }
    });
  };
  const totalTeamsInStage = useMemo(() => {
    let totalTeams = StageSettings.groups.reduce((acc, curr) => acc + curr.noOfTeams, 0);
    // console.log({totalTeams})
    return totalTeams;
  }, [StageSettings.groups, StageSettings.evenGroupBySize]);
  const onSubmit = (id, obj) => dispatch(updateStage(id, obj));
  return (
    <div className="stages-component-styles">
      <form
        onSubmit={handleSubmit(onSubmit)}
        // id="acc-settings-form"
        className="account-settings-form stage-tab-custom">
        <Row style={{ alignItems: 'flex-start' }}>
          <Col sm={4} className="left-row-stages">
            <div className="form-row">
              <h1>Stage Settings</h1>
              <div className="col-8 stage-fields-pos">
                <InputComponent
                  defaultValue={StageSettings?.name}
                  labelText="Stage Name"
                  register={register}
                  errors={errors}
                  name={'name'}
                  type={'text'}
                  classNames={'form-input black-bg'}
                  // className={
                  //   name === '' ? 'error-form-input red-bg' : `form-input black-bg`
                  // }
                  handleChange={handleChange}
                />
                <label className="text-12 faded bold uppercase" htmlFor="stageName">
                  Format
                </label>

                <select
                  onChange={(e) => handleChange(e)}
                  id="birthdayMonth"
                  name="format"
                  className={'form-input black-bg'}
                  disabled={isMatchesGenerated}
                  // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
                  defaultValue={StageSettings?.format}>
                  <option value="" disabled>
                    Select Format
                  </option>
                  {FORMATS.map((format, i) => (
                    <option key={i} value={format.value}>
                      {format.label}
                    </option>
                  ))}
                </select>
                {/* <InputComponent
                  defaultValue={StageSettings?.encounters}
                  labelText="Encounters"
                  register={register}
                  errors={errors}
                  name={'encounters'}
                  type={'number'}
                  classNames={'form-input black-bg'}
                  handleChange={handleChange}
                  disabled={isMatchesGenerated}
                  minimum={1}
                /> */}
              </div>
            </div>
            <div className="form-row">
              <h1>Format Settings</h1>
              {StageSettings.format === 1 && (
                <div className="col-8 stage-fields-pos" style={{ marginBottom: '-8px' }}>
                  <InputComponent
                    defaultValue={StageSettings?.encounters}
                    labelText="Encounters"
                    register={register}
                    errors={errors}
                    name={'encounters'}
                    type={'number'}
                    classNames={'form-input black-bg'}
                    handleChange={handleChange}
                    disabled={isMatchesGenerated}
                    minimum={1}
                  />
                  {/* <label className="text-12 faded bold uppercase" htmlFor="stageName">
                    Format
                  </label>

                  <select
                    onChange={(e) => handleChange(e)}
                    id="birthdayMonth"
                    name="format"
                    className={'form-input black-bg'}
                    disabled={isMatchesGenerated}
                    // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
                    defaultValue={StageSettings?.format}>
                    <option value="" disabled>
                      Select Format
                    </option>
                    {FORMATS.map((format, i) => (
                      <option key={i} value={format.value}>
                        {format.label}
                      </option>
                    ))}
                  </select> */}
                  {/* </div> */}
                </div>
              )}
              {StageSettings.format === 1 && (
                <div className="col-8 stage-fields-pos">
                  {/* <div className="form-row"> */}
                  <label className="text-12 faded bold uppercase" htmlFor="lastName">
                    Pairing Procedure
                  </label>
                  <select
                    name="pairingProcedure"
                    onChange={(e) => handleChange(e)}
                    className={'form-input black-bg'}
                    disabled={isMatchesGenerated}
                    // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
                    defaultValue={StageSettings?.pairingProcedure}>
                    <option value="" disabled>
                      Select Pairing Procedure
                    </option>
                    {['Circle', 'Berger', 'Schurig'].map((procedure, i) => (
                      <option key={i} value={procedure}>
                        {procedure}
                      </option>
                    ))}
                  </select>
                  {/* {errors.lastName && (
                    <div className="form-input-error">{errors.lastName.message}</div>
                  )} */}
                  {/* </div> */}
                </div>
              )}
              {StageSettings.format === 1 && (
                <div className="col-8 stage-fields-pos">
                  {/* <div className="form-row"> */}
                  <label className="text-12 faded bold uppercase" htmlFor="lastName">
                    Home Team
                  </label>
                  <SelectWithDescription
                    name="homeTeam"
                    disabled={isMatchesGenerated}
                    defaultSelection={
                      StageSettings?.homeTeam ? StageSettings.homeTeam : 'Alternate'
                    }
                    handleChange={handleChange}
                  />
                  {/* <select
                    onChange={(e) => handleChange(e)}
                    disabled={isMatchesGenerated}
                  name="homeTeam"
                    className={'form-input black-bg'}
                    // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
                    defaultValue={StageSettings?.homeTeam}>
                    <option value="" disabled>
                      Select Format
                    </option>
                    {['Alternate', 'Favor better seed', 'Algorithmic', 'Random'].map((team, i) => (
                      <option key={i} value={team}>
                        {team}
                      </option>
                    ))}
                  </select> */}
                  {/* {errors.lastName && (
                    <div className="form-input-error">{errors.lastName.message}</div>
                  )} */}
                  {/* </div> */}
                </div>
              )}
              {/* {StageSettings.format === 2 && (
                <div className="col-8 stage-fields-pos">
                  <label className="text-12 faded bold uppercase" htmlFor="lastName">
                    WINNER(S)
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    name="winners"
                    className={'form-input black-bg'}
                    defaultValue={StageSettings?.homeTeam}>
                    <option value="" disabled>
                      Select Format
                    </option>
                    {['One Winner', 'Top 2 Teams', 'Top 3 Teams', 'Top 4 Teams','Top 5 Teams'].map((team, i) => (
                      <option key={i} value={team}>
                        {team}    
                      </option>
                    ))}
                  </select>
                </div>
              )} */}
              {StageSettings.format === 2 && (
                <div className="col-8 stage-fields-pos">
                  {/* <div className="form-row"> */}
                  <label className="text-12 faded bold uppercase" htmlFor="lastName">
                    BRACKETS
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    name="bracketType"
                    className={'form-input black-bg'}
                    disabled={isMatchesGenerated}
                    // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
                    defaultValue={StageSettings?.bracketType}>
                    <option value="" disabled>
                      Select Bracket Type
                    </option>
                    {BracketType.map((el, i) => (
                      <option key={i} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </select>
                  {/* {errors.lastName && (
                    <div className="form-input-error">{errors.lastName.message}</div>
                  )} */}
                  {/* </div> */}
                </div>
              )}
              {StageSettings.format === 2 &&
                (StageSettings.bracketType === 1 ||
                  StageSettings.bracketType.name === 'Single Elimination') && (
                  <div className="col-8 stage-fields-pos">
                    {/* <div className="form-row"> */}
                    <label className="text-12 faded bold uppercase" htmlFor="lastName">
                      TEAMS
                    </label>
                    <input
                      onChange={(e) => {
                        // handleChange(e);
                        if (e.target.value > 1 && e.target.value <= 1000) {
                          handleChange(e);
                          setInputVal(e.target.value);
                        }
                        else{
                          setInputVal(e.target.value);
                        }
                      }}
                      style={(StageSettings.teamSize > StageSettings.groupSize) ? {border:'1px solid red'}: {}}
                      ref={register({
                        required: 'Required',
                        maxLength: { value: 1000, message: 'Max or min value is wrong' }
                      })}
                      // disabled={disabled}
                      type="number"
                      min={2}
                      max={1000}
                      name="teamSize"
                      disabled={isMatchesGenerated}
                      // value={StageSettings.teamSize < '2' ? '2' : StageSettings.teamSize > '1000' ? '1000' : StageSettings.teamSize }
                      // defaultValue={StageSettings?.teamSize}
                      autoComplete="off"
                      className={` ${
                        // (StageSettings.teamSize=== StageSettings.groupSize)? 'form-input black-bg':(StageSettings?.teams === '' || StageSettings?.teamSize > StageSettings?.groupSize)? 'form-input input-red-border-1' : (inputVal === '' || inputVal < '2')? 'form-input input-red-border-2':'form-input black-bg'
                        (inputVal === '' || inputVal < 2)? 'form-input input-red-border-2':(StageSettings?.teamSize > StageSettings?.groupSize)? 'form-input input-red-border-1' :'form-input black-bg'
                        // (StageSettings?.teams === '' || StageSettings.teamSize > StageSettings.groupSize || inputVal === '' || inputVal < '2')? 'form-input red-bg hover-red' : 'form-input black-bg'
                      }`}
                      // className={classNames}
                      value={inputVal}
                      defaultValue={StageSettings?.teamSize && StageSettings?.teamSize }
                      placeholder={`Enter number of teams in the bracket`}
                    />
                    {(inputVal === '') ?( <div style={{color:'#f11857',marginTop:'5px',fontSize:'12px'}}>Number of teams cannot be empty</div>):(StageSettings?.teamSize > StageSettings?.groupSize)? (<div style={{color:'#d5901f',marginTop:'5px',fontSize:'12px'}}>The number of teams in the bracket exceed the number of teams in this stage.</div>):''}
                    {/* {(StageSettings?.teamSize > StageSettings?.groupSize) ?( <div style={{color:'#d5901f',marginTop:'5px',fontSize:'12px'}}>The number of teams in the bracket exceed the number of teams in this stage.</div>):(inputVal === '')? (<div style={{color:'#f11857',marginTop:'5px',fontSize:'12px'}}>Number of teams cannot be empty</div>):''} */}
                    {/* {inputVal === '' && <div style={{color:'#f11857',marginTop:'5px',fontSize:'12px'}}>Number of teams cannot be empty</div>} */}
                    {errors.name && <div className="form-input-error">{errors.name.message}</div>}
                  </div>
                )}

              {StageSettings.format === 2 &&
                (StageSettings.bracketType === 1 ||
                  StageSettings.bracketType.name === 'Single Elimination') && (
                  <>
                    <SwitchButton
                      label={'3rd place decider'}
                      disabled={isMatchesGenerated}
                      onChange={() => {
                        setManually(!manually)
                        setIsOn(!isOn)
                      }}
                      isOn={isOn}
                    />
                    <div style={{color:'#68717e',fontSize:'13px',marginLeft:'55px'}}>includes a decider match for 3rd and 4th place.</div>
                  </>
                )}
            </div>
            {StageSettings.format === 1 && (
              <div className="form-row">
                <h1>Score System</h1>
                <div className="col-10 stage-fields-pos">
                  <label className="text-12 faded bold uppercase" htmlFor="stageName">
                    Match Types
                  </label>
                  {/*  this is the demo of translation helper function */}
                  <MultipleSelect
                    // disabled={isMatchesGenerated}
                    options={StageSettings.format === 1 ? format1 : format2}
                    handleChange={(selectedIds, options) =>
                      handleBestOfChange(selectedIds, options)
                    }
                    placeholder="Choose Match Types..."
                  />
                </div>
                <div className="col-10 pl-0">
                  <SimpleTabs
                    pages={StageSettings.scoreSystem.map((el) => `Best of ${el.bestOf}`)}
                    page={currentFormat}
                    setPage={setCurrentFormat}
                  />
                  {StageSettings.scoreSystem.length > 0 && (
                    <Outcomes
                      isMatchesGenerated={isMatchesGenerated}
                      scoreSystem={
                        StageSettings.scoreSystem.length > 0 &&
                        StageSettings.scoreSystem[currentFormat]
                      }
                      currentFormat={currentFormat}
                      stage={StageSettings}
                      setStageSettings={setStageSettings}
                    />
                  )}
                </div>
              </div>
            )}

            {/* <Button className='btn-preview-brackets' >Preview Brackets</Button> */}
          </Col>
          <Col sm={8}>
            <h1 style={{ marginBottom: '3px', marginTop: '34px' }}>Group info</h1>
            {/* <div className="col-6"> */}
            <div className="form-row">
              <div className="row sm-gutters" style={{ alignItems: 'flex-end' }}>
                <div className="col-3">
                  <InputComponent
                    defaultValue={StageSettings.groupSize}
                    labelText="Group Team Size"
                    content={'The number of teams in each group.'}
                    name={'groupSize'}
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    type={'number'}
                    classNames={`form-input ${
                      StageSettings.groupSize === 0 ? 'red-bg' : 'black-bg'
                    } `}
                    disabled={!StageSettings.evenGroupBySize || isMatchesGenerated}
                    minimum={2}
                  />
                </div>
                <div className="col-6">
                  <Row className="pl-4">
                    <InputComponent
                      defaultValue={totalTeamsInStage}
                      labelText="Total Teams In stage"
                      register={register}
                      name={'totalTeams'}
                      // handleChange={handleChange}
                      errors={errors}
                      type={'number'}
                      classNames={'form-input grey-bg'}
                      disabled={true}
                    />
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          name="evenGroupBySize"
                          onChange={(e) => handleChange(e)}
                          id="flexCheckChecked"
                          disabled={isMatchesGenerated}
                          checked={StageSettings?.evenGroupBySize}
                        />
                        <label
                          className="form-check-label"
                          style={{ alignItems: 'center' }}
                          htmlFor="flexCheckChecked">
                          Even Group Sizes{' '}
                          <InformationLogo content="Ensure all the groups have the same number of teams." />
                        </label>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h1>Group</h1>
              <button
                disabled={isMatchesGenerated}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                className="add-group-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleAddGroup(StageSettings?.groups);
                }}>
                <HiPlus size={12} style={{ marginRight: '4px' }} />
                <span>Add Group</span>
              </button>
            </div>
            <table className="fixed-header">
              {/* <div> */}
              <thead /* style={{display:'block'}} */>
                <tr>
                  <th>Number</th>
                  <th>Group Name</th>
                  <th>Number of Teams</th>
                  <th>Group Color</th>
                  <th>Preview</th>
                  <th></th>
                </tr>
              </thead>
              {/* </div> */}
              {/* <div
                className="group-scrollbar"
                onScroll={(e) => {
                  setScroll(e.target.scrollTop);
                }}> */}
              <tbody
                onScroll={(e) => {
                  setScroll(e.target.scrollTop);
                }} /* style={{display: 'block'}} */
              >
                {StageSettings.groups?.map(({ _id, name, noOfTeams, color }, index) => {
                  let num = index + 1;
                  return (
                    <tr key={_id}>
                      <td>{num}</td>
                      <td className="test">
                        <input
                          onChange={(e) => onUpdateColour('groupName', e.target.value, _id)}
                          type="groups"
                          autoComplete="off"
                          // className={`form-input ${errors.firstName ? 'invalid' : ''}`}
                          className={
                            name === '' ? 'error-form-input red-bg' : `form-input black-bg`
                          }
                          // defaultValue={user.state.firstName}
                          value={name}
                          placeholder="Group Name"
                        />
                      </td>
                      <td>
                        <input
                          onChange={(e) => onUpdateColour('groupTeams', e.target.value, _id)}
                          type="number"
                          id="firstName"
                          name="firstName"
                          autoComplete="off"
                          disabled={StageSettings.evenGroupBySize || isMatchesGenerated}
                          min={0}
                          className={`form-input ${noOfTeams === 0 ? 'red-bg' : 'black-bg'}`}
                          // className={'form-input grey-bg'}
                          value={noOfTeams}
                          // defaultValue={user.state.firstName}
                          placeholder="Number of Teams"
                        />
                      </td>
                      <td>
                        <div
                          className="grp-color-dropDown"
                          onClick={(e) => {
                            setShow(_id);
                          }}>
                          <div
                            className="clrPic color-Green"
                            style={{ background: `${color} 0% 0% no-repeat padding-box` }}
                          />
                          <p>{getColorName(color)}</p>
                          <span>
                            <HiChevronDown size={25} />
                          </span>
                        </div>
                        <ColorPicker
                          show={custom}
                          id={_id}
                          scroll={scroll}
                          onClose={() => {
                            setShow('0');
                            setCustom('0');
                          }}
                          mainColour={color}
                          onUpdateColor={(clr) => onUpdateColour('groupColor', clr, _id)}
                          // onBlur={() => onSaveStage(i)}
                        />
                        <Colors
                          scroll={scroll}
                          show={show}
                          id={_id}
                          setCustom={setCustom}
                          onUpdateColour={onUpdateColour}
                          onClickOutside={() => {
                            setShow('0');
                          }}
                        />
                      </td>
                      <td>
                        <div className="preview-groups" style={{ background: `${color}22` }}>
                          <span className="span-preview-name" style={{ color: `${color}` }}>
                            {name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <HiX
                          size={20}
                          color="#f11857"
                          onClick={(e) => {
                            if (isMatchesGenerated) return;
                            if (StageSettings?.groups.length > 1) {
                              setStageSettings((state) => ({
                                ...state,
                                groups: state.groups.filter((el) => el._id !== _id)
                              }));
                            } else {
                              toast.error('Stage must have Atleast one Group');
                            }
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* </div> */}
            </table>
            {/* <button
              disabled={isMatchesGenerated} style={{display:'flex',alignItems:'center',justifyContent:'center'}}
              className="add-group-btn" 
              onClick={(e) => {
                e.preventDefault();
                handleAddGroup(StageSettings?.groups);
              }}>
              <HiPlus size={12} /> Add Group
            </button> */}
            {StageSettings.format === 1 && (
              <RankingCriteria
                stage={StageSettings}
                // isMatchesGenerated={isMatchesGenerated}
                setStageSettings={setStageSettings}
              />
            )}
          </Col>
        </Row>
      </form>
      {StageSettings.format === 2 && (
        <div>
          <h1>STAGE BRACKET </h1>
          <div className="d-flex mb-2">
            <div className="tag-team-round-matches">
              <div className="num">
                {inputVal === '' ? 0 : inputVal > 1 ?  StageSettings.teamSize : (inputVal < 2 && inputVal >= 0 ) ? inputVal : (typeof inputVal === '') ? '0' : '0'} <span>Teams</span>
              </div>
            </div>
            <div className="tag-team-round-matches">
              <div className="num">
                {manually === true ? StageSettings.teamSize : (inputVal === '0'|| inputVal === '')? '0' : StageSettings.teamSize - 1}{' '}
                <span>Matches</span>
              </div>
            </div>
          </div>
          <Brackets id={id} stage={StageSettings} setStageSettings={setStageSettings} />
        </div>
      )}
      <div className="side-buttons">
        {' '}
        {/* <button className="preview-brackets-btn">preview brackets</button> */}
        <button
          className={disableSave ? 'button-disabled' : 'button-primary'}
          style={{ font: 'normal normal bold 12px/15px Montserrat' }}
          disabled={disableSave}
          onClick={(e) => {
            e.preventDefault();
            onSubmit(id, StageSettings);
          }}>
          SAVE CHANGES
        </button>
        <button
          className={isMatchesGenerated ? 'button-delete-disabled' : 'button-delete'}
          disabled={isMatchesGenerated}
          style={{ font: 'normal normal bold 12px/15px Montserrat' }}
          onClick={(e) => {
            e.preventDefault();
            deleteThisStage(id, StageSettings?._id);
          }}>
          DELETE STAGE
        </button>
      </div>
    </div>
  );
}
export default Stage;

const Colors = ({
  show,
  onClickOutside,
  id,
  groupColors = ['#fff'],
  setCustom,
  scroll,
  onUpdateColour
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      // debugger
      // let newTop =  `-${scroll}px`
      // debugger
      ref.current.style.marginTop = `-${scroll}px`;
    }
  }, [scroll, show]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (show !== id) {
    return null;
  }
  return (
    <div key={id} ref={ref} id="colorBox-div" className="colors-dropDown">
      <div className="colors-div">
        <div
          className="clrPic color-Green"
          onClick={() => onUpdateColour('groupColor', '#19db60', id)}
        />
        <div
          className="clrPic color-Yellow"
          onClick={() => onUpdateColour('groupColor', '#d5901f', id)}
        />
        <div
          className="clrPic color-Blue"
          onClick={() => onUpdateColour('groupColor', '#3eabf0', id)}
        />
        <div
          className="clrPic color-Red"
          onClick={() => onUpdateColour('groupColor', '#f11857', id)}
        />
        <div
          className="clrPic color-Cyan"
          onClick={() => onUpdateColour('groupColor', '#88d1ff', id)}
        />
        <div
          className="clrPic color-Orange"
          onClick={() => onUpdateColour('groupColor', '#d14419', id)}
        />
        <div
          className="clrPic color-Malachite"
          onClick={() => onUpdateColour('groupColor', '#19d17c', id)}
        />
        <div
          className="clrPic color-Magenta"
          onClick={() => onUpdateColour('groupColor', '#e23ef0', id)}
        />
        <div
          className="clrPic color-Kiwi"
          onClick={() => onUpdateColour('groupColor', '#a6f118', id)}
        />
        <div
          className="clrPic color-Gray"
          onClick={() => onUpdateColour('groupColor', '#7e8fa8', id)}
        />
      </div>
      <button
        className="clr-custom-button"
        onClick={(e) => {
          e.preventDefault();
          onClickOutside();
          setCustom(id);
        }}>
        Custom
      </button>
    </div>
  );
};
const InputComponent = ({
  labelText,
  content = false,
  handleChange,
  register,
  errors,
  defaultValue,
  message = 'error',
  maxlength = 5,
  type,
  classNames,
  disabled = false,
  name,
  minimum = 0
}) => {
  return (
    <div className="pb-2">
      <label
        className="text-12 label-left faded bold uppercase"
        style={content ? { display: 'inline' } : {}}
        htmlFor="stageName">
        {labelText}
        {content && <InformationLogo content={content} />}
      </label>
      <input
        onChange={(e) => {
          handleChange(e);
        }}
        ref={register({
          required: 'Required',
          maxLength: { value: maxlength, message: message }
        })}
        disabled={disabled}
        type={type}
        min={minimum}
        name={name}
        autoComplete="off"
        className={` ${defaultValue === '' ? 'error-form-input red-bg' : classNames}`}
        // className={classNames}
        value={defaultValue}
        placeholder={`Enter ${labelText}`}
      />
      {errors.name && <div className="form-input-error">{errors.name.message}</div>}
    </div>
  );
};
