import React, { useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Options from './Options/Options';
import './tippybg.scss'
function ThreeDots({
  disabled = false,
  style = {},
  type,
  userPermissions,
  user,
  organiser,
  folder,
  competition,
  onFirstClick,
  isFolder
}) {
  const [tippyVisibility, setTippyVisibility] = useState(false);

  const handleTooltipClick = (e, disabled) => {
    e.stopPropagation();
    if (disabled) return onFirstClick(e);
    setTippyVisibility((prevState) => !prevState);
  };
  return (
    <div className='tippy-bg'>
      <Tippy
        content={
          <Options
            userPermissions={userPermissions}
            user={user}
            type={type}
            organiser={organiser}
            folder={folder}
            competition={competition}
            handleTippy={setTippyVisibility}
          />
        }
        interactive={true}
        placement="bottom-start"
        animation="shift-away"
        // hideOnClick="toggle"
        visible={tippyVisibility}
        onClickOutside={() => setTippyVisibility(false)}
        // trigger="click"
      >
        <div
          //  style={style}
          onDoubleClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          //   disabled={!disabled}
          // onClick={e=> e}
          className="hi-dots "
          // style={disabled ? { background: '#12161e' } : { background: '#091625' }}
          style={
            disabled ? { background: isFolder ? '#091625' : '#12161e' } : { background: '#091625' }
          }
          //   onClick={(e) => {
          // showContextMenu(e, item, permissions, type, isTrash);
          //   }}
        >
          <HiDotsHorizontal
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={(e) => handleTooltipClick(e, disabled)}
            // color={disabled ? '#68717e' : '#3eabf0'}
            color={disabled ? (isFolder ? '#3eabf0' : '#68717e') : '#3eabf0'}
            size={25}
          />
        </div>
      </Tippy>
    </div>
  );
}

export default ThreeDots;
