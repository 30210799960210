import React, { useEffect, useState, useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Checkbox } from '../../../../_common';
import { useForm } from 'react-hook-form';
import { TIMEZONES } from '../../../../../utils/timezones';
import './a.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Axios from 'axios';
import { getCompetitionsMatches } from '../../../../../redux/action/CompetitonData';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { HiInformationCircle } from 'react-icons/hi';
import Tippy from '@tippyjs/react';
import Datetime from 'react-datetime';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';

const CreateMatcehsFunctionality = ({ stageIndex, onClose, groupIndex }) => {
  const competitionData = useSelector((state) => state.COMPETITION.Competition.multiStageLeague);
  const { register, handleSubmit, errors } = useForm();
  const [publishDate, setPublishDate] = useState(`${moment().format('YYYY-MM-DD')}`);
  const [publishTime, setPublishTime] = useState(moment().format('hh:mm'));
  const [timeZone, setSelectedtimeZone] = useState(
    competitionData?.timezone ? competitionData?.timezone : `${TIMEZONES()[0].value}`
  );
  const [bestOf, setBestOf] = useState('0');
  const [team1, setTeam1] = useState([]);
  const [team2, setTeam2] = useState([]);
  const [leagueData, setleagueData] = useState([]);
  const [datetime, setdatetime] = useState();
  const [week, setWeek] = useState(1);
  const [round, setRound] = useState(1);
  const [tieBreaker, setTieBreaker] = useState(false);
  const [broadcasted, setbroadcasted] = useState(false);
  const [sideSelection, setSideSelection] = useState(false);
  const [league, setLeague] = useState();
  const [multiStageLeague, setmultiStageLeague] = useState();
  const [stage, setStage] = useState();
  const [scoreSystem, setscoreSystem] = useState();
  const [alldeatils, setAllDetails] = useState();
  const [isSeedindMatch, setIsseedingMatch] = useState(true);
  const [stagae1, setStage1] = useState();
  const [stagae2, setStage2] = useState();
  const [useCustommatchSheduling, setUseCustommatchSheduling] = useState(false);
  const [earliestDate, setearliestDate] = useState();
  const [latestDate, setlatestDate] = useState();
  const dispatch = useDispatch();
  const [stream, setStream] = useState(competitionData?.streams[0]?._id);
  const matches = useSelector((state) => state.COMPETITION?.Matches?.data?.data);
  useEffect(() => {
    if (publishDate && publishTime) {
      const ifDateChanged = moment(`${publishDate + publishTime}`, 'DD/MM/YYYY HH:mm:ss').tz(
        timeZone ? timeZone : 'Etc/UTC',
        true
      );
      setdatetime(`${publishDate + 'T' + publishTime}`);
    }
  }, [publishDate, publishTime, timeZone]);
  useEffect(() => {
    if (team1.length > 1) {
      const a = team1?.split(' ');
      setStage1(leagueData.find((aa) => aa._id == a[0] && aa.group == a[1]));
    }
  }, [team1]);
  useEffect(() => {
    if (team2.length > 1) {
      const a = team2?.split(' ');
      setStage2(leagueData.find((aa) => aa._id == a[0] && aa.group == a[1]));
    }
  }, [team2]);
  useEffect(() => {
    bestOf && alldeatils && setscoreSystem(alldeatils.scoreSystem[bestOf]?._id);
  }, [bestOf, alldeatils]);
  useEffect(() => {
    console.log('groupIndwx,groupIndex', groupIndex);
  }, [groupIndex]);
  useEffect(() => {
    if (competitionData && stageIndex) {
      let totalTeams = 0;
      // let groupsize = competitionData.stages[stageIndex - 1].groups.length;
      let data = [];
      if (groupIndex === 0) {
        competitionData.stages[stageIndex - 1].groups.map((group) => {
          if (totalTeams < group.noOfTeams) {
            totalTeams = group.noOfTeams;
          }
        });

        for (let i = 0; i < totalTeams; i++) {
          competitionData.stages[stageIndex - 1].groups.map((item) => {
            if (i < item.noOfTeams) {
              data.push({
                group: item._id,
                seedPosition: i + 1,
                _id: item.teams[i]?.team ? item.teams[i].team._id : i + 1,
                teams: item.teams[i]?.team
                  ? item.teams[i].team.name + '[' + item.name + ']'
                  : 'Seed ' + (i + 1) + '[' + item.name + ']'
              });
            }
          });
        }
      } else {
        let specific_group = competitionData.stages[stageIndex - 1].groups[groupIndex - 1];
        for (let i = 0; i < specific_group.noOfTeams; i++) {
          data.push({
            group: specific_group._id,
            seedPosition: i + 1,
            _id: specific_group.teams[i]?.team ? specific_group.teams[i].team._id : i + 1,
            teams: specific_group.teams[i]?.team
              ? specific_group.teams[i].team.name + '[' + specific_group.name + ']'
              : 'Seed ' + (i + 1) + '[' + specific_group.name + ']'
          });
        }
      }
      setTeam1(`${data[0]._id + ' ' + data[0].group}`);
      setTeam2(`${data[1]._id + ' ' + data[1].group}`);

      setleagueData(data);
      Axios.get(
        `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competitionData._id}`
      ).then(({ data }) => {
        setAllDetails(data?.multiStageLeague?.stages[0]);
        const teamdetails = data?.multiStageLeague?.stages[0].league;
        // setleagueData(teamdetails);
        // Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${teamdetails?._id}`).then(
        //   ({ data }) => {
        //     setleagueData(data);
        //   }
        // );
        setLeague(teamdetails?._id);
        setmultiStageLeague(competitionData?._id);
        setStage(data?.multiStageLeague?.stages[stageIndex - 1]._id);
      });
    }
  }, [competitionData, stageIndex]);
  useEffect(() => {
    console.log('currentDate', publishTime);
  }, [publishTime]);
  const submitForm = () => {
    const broadCastedChannel = competitionData?.streams?.find((strm, index) => strm._id == stream);

    const data = {
      match: {
        datetime,
        scheduleDay: datetime,
        timeZone,
        bestOf,
        t1:
          (team1.length > 1 && team1?.split(' ')[0]).length > 3
            ? team1.length > 1 && team1?.split(' ')[0]
            : null,
        t2:
          (team1.length > 1 && team2?.split(' ')[0]).length > 3
            ? team2.length > 1 && team2?.split(' ')[0]
            : null,
        sp1: { seedPosition: stagae1?.seedPosition, group: stagae1?.group },
        sp2: { seedPosition: stagae2?.seedPosition, group: stagae2?.group },
        week,
        round,
        tieBreaker,
        broadcasted,
        sideSelection,
        league,
        multiStageLeague,
        groups:
          stagae1?.group === stagae2?.group ? [stagae2?.group] : [stagae1?.group, stagae2?.group],
        stage,
        scoreSystem,
        isSeedindMatch,
        isTieBreaker: tieBreaker,
        broadCastedChannel,
        reschedule: { earliestDate, latestDate }
      }
    };

    Axios.post(
      `${process.env.REACT_APP_CORE_API}/api/organizer/competition/${competitionData._id}/matches/generateMatch`,
      data
    )
      .then(({ data }) => {
        let query = { status: '', limit: '100', page: '1' };
        dispatch(getCompetitionsMatches({ id: competitionData._id, query }));
        toast.success('Created Successfully');
        onClose(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const swapTeams = () => {
    let temp = team1;
    setTeam1(team2);
    setTeam2(temp);
  };

  // useEffect(() => {
  //   if (broadcasted) setStream(competitionData.streams && competitionData.streams[0] && competitionData.streams[0]._id)
  //   else setStream()
  // }, [broadcasted])

  return (
    <div>
      <h2 style={{ font: 'normal normal bold 16px/19px Montserrat' }}>MATCH INFO</h2>
      <Row>
        <Col>
          <div className="form-row" style={{ marginTop: '5px' }}>
            <label
              htmlFor="region"
              style={{
                textTransform: 'uppercase',
                // color: '#68717e',
                color: 'white',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              Team 1
            </label>
            <select
              style={{ border: '1px solid #22262E', bordeRadius: '2px' }}
              id="region"
              name="region"
              className={`form-input`}
              value={team1}
              onChange={(e) => {
                setTeam1(e.target.value);
              }}>
              {leagueData
                .filter((filterteam) => {
                  if (team2.length > 1) {
                    if (team2.split(' ')[0].length < 3) {
                      if (
                        filterteam?.seedPosition != team2.split(' ')[0] ||
                        filterteam?.group != team2.split(' ')[1]
                      ) {
                        return filterteam;
                      }
                    } else {
                      return filterteam?._id != team2.split(' ')[0];
                    }
                  } else {
                    return filterteam;
                  }
                })
                ?.map((name) => {
                  return (
                    <option key={name?.group} value={name._id + ' ' + name.group}>
                      {name?.teams}
                    </option>
                  );
                })}
            </select>
          </div>
        </Col>
        <div
          style={{ marginTop: '30px', marginLeft: '30px' }}
          onClick={() => {
            swapTeams();
          }}>
          <Tippy content="Switch teams" placement="bottom">
            <div>
              <HiOutlineSwitchHorizontal style={{ color: '#68717e' }} />
            </div>
          </Tippy>
        </div>
        <Col>
          <div className="form-row" style={{ marginTop: '5px' }}>
            <label
              htmlFor="region"
              style={{
                textTransform: 'uppercase',
                color: 'white',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              Team 2
            </label>
            <select
              style={{ border: '1px solid #22262E', bordeRadius: '2px' }}
              id="region"
              name="region"
              className={`form-input`}
              value={team2}
              onChange={(e) => {
                setTeam2(e.target.value);
              }}>
              {leagueData
                .filter((filterteam) => {
                  if (team1.length > 1) {
                    if (team1.split(' ')[0].length < 3) {
                      if (
                        filterteam?.seedPosition != team1.split(' ')[0] ||
                        filterteam?.group != team1.split(' ')[1]
                      ) {
                        return filterteam;
                      }
                    } else {
                      return filterteam?._id != team1.split(' ')[0];
                    }
                  } else {
                    return filterteam;
                  }
                })
                ?.map((name, ind) => {
                  return (
                    <option key={ind + name?.group} value={name._id + ' ' + name.group}>
                      {name?.teams}
                    </option>
                  );
                })}
            </select>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '5px' }}>
        <Col>
          <label
            htmlFor="region"
            style={{
              textTransform: 'uppercase',
              color: 'white',
              font: 'normal normal bold 12px/15px Montserrat',
              width: '77px',
              height: '5px'
            }}>
            START DATE
          </label>
          <Form.Group>
            <input
              style={{ color: '#68717e', border: '1px solid #22262E', bordeRadius: '2px',height:'44px' }}
              type="date"
              id="datetime"
              name="datetime"
              dateFormat="DD/MM/YYYY"
              value={`${publishDate}`}
              onChange={(e) => {
                setPublishDate(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.datetime && errors.datetime.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <label
            htmlFor="region"
            style={{
              textTransform: 'uppercase',
              // color: '#68717e',
              color: 'white',
              font: 'normal normal bold 12px/15px Montserrat'
            }}>
            TIME
          </label>
          <Form.Group>
            <input
              style={{ color: '#68717e', border: '1px solid #22262E', bordeRadius: '2px',height:'44px' }}
              type="time"
              id="datetime"
              name="datetime"
              dateFormat="DD/MM/YYYY"
              // timeFormat="HH:mm:ss"
              value={`${publishTime}`}
              my-date-format="DD/MM/YYYY, hh:mm:ss"
              onChange={(e) => {
                setPublishTime(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.datetime && errors.datetime.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <div className="form-row">
            <label
              htmlFor="region"
              style={{
                textTransform: 'uppercase',
                // color: '#68717e',
                color: 'white',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              Time zone
            </label>
            <select
              style={{ color: '#68717e', border: '1px solid #22262E', bordeRadius: '2px' }}
              ref={register({
                validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
              })}
              id="region"
              name="region"
              className={`form-input ${errors.timeZone ? 'invalid' : ''}`}
              value={timeZone}
              onChange={(e) => {
                setSelectedtimeZone(e.target.value);
              }}>
              <option value="">Select timeZone</option>
              {TIMEZONES().map(({ name, value }) => (
                <option value={value}>{name}</option>
              ))}
            </select>
            {errors.timeZone && <div className="form-input-error">{errors.timeZone.message}</div>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label
              style={{
                textTransform: 'uppercase',
                color: '#68717e',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              WEEK
            </Form.Label>
            <Form.Control
              ref={register({ required: true })}
              type="number"
              id="name"
              name="name"
              min={0}
              max={1000}
              value={week}
              // onBlur={handleSubmit(onSubmit)}
              onChange={(e) => {
                setWeek(e.target.value < 1 ? '1' : e.target.value > 1000 ? '1000' : e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name && errors.name.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label
              style={{
                textTransform: 'uppercase',
                color: '#68717e',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              Round
            </Form.Label>
            <Form.Control
              ref={register({ required: true })}
              type="number"
              id="name"
              name="name"
              value={round}
              // onBlur={handleSubmit(onSubmit)}
              onChange={(e) => {
                setRound(
                  e.target.value < 1 ? '1' : e.target.value > 1000 ? '1000' : e.target.value
                );
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name && errors.name.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2
            style={{
              font: 'normal normal bold 16px/19px Montserrat',
              widows: '82px',
              height: '19px',
              marginTop: '5px'
            }}>
            SETTINGS
          </h2>
          <strong style={{ color: '#68717e', font: 'normal normal normal 12px/15px Montserrat' }}>
            Please note that fields marked with ‘overwrite’ will overwrite the general match
            settings if edited.
          </strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-row" style={{ marginTop: '12px' }}>
            <label
              htmlFor="region"
              style={{
                textTransform: 'uppercase',
                // color: '#68717e',
                color: 'white',
                font: 'normal normal bold 12px/15px Montserrat',
                marginTop: '2px'
              }}>
              BEST OF
            </label>
            <select
              // ref={register({
              //   validate: (val) => (val && val !== '_DEFAULT_' ? true : 'Required')
              // })}
              id="region"
              name="region"
              className={`form-input`}
              defaultValue={bestOf}
              onChange={(e) => {
                setBestOf(e.target.value);
              }}>
              {/* {alldeatils?.scoreSystem?.map((name, index) => (
                <option key={index} value={index + 1} selected={index == bestOf}>
                  BEST OF {index + 1}
                </option>
              ))} */}
              {competitionData.stages[stageIndex - 1].scoreSystem.map((name, index) => (
                <option key={index} value={index + 1} selected={index == bestOf}>
                  BEST OF {name.bestOf}
                </option>
              ))}
            </select>
            {/* {errors.game && <div className="form-input-error">{errors.game.message}</div>} */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', marginTop: '9px' }}>
            <Checkbox
              onChange={() => {
                setTieBreaker(!tieBreaker);
              }}
              type="checkbox"
              checked={tieBreaker}
              className="check-type1"
            />
            <strong
              style={{
                color: '#68717e',
                fontWeight: '200',
                font: 'normal normal bold 12px/15px Montserrat',
                marginLeft: '2px',
                marginRight: '.5em'
              }}>
              TIEBREAKER
            </strong>
            <Tippy content="Tiebreaker matches do not contribute points to the overall score of a team, but will be used to decide ties between two or more teams." placement="right">
                <div>
                  <HiInformationCircle color='#68717e'/>
                </div>
              </Tippy>
          </div>
        </Col>
      </Row>
      <Row>
        <div
          style={{
            font: 'normal normal bold 12px/15px Montserrat',
            width: '56px',
            margin: '10px 30px'
          }}>
          ADDONS
        </div>
      </Row>
      <Row>
        <Col>
          <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            <Checkbox
              // key={folder._id}
              type="checkbox"
              style={{ Width: '30px', Height: '30px' }}
              checked={broadcasted}
              onChange={() => setbroadcasted(!broadcasted)}
              className="check-type1"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <strong
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat',
                  width: '98px',
                  height: '15px',
                  marginRight: '22px'
                }}>
                {' '}
                broadcastedED
              </strong>
              <Tippy content="Broadcasted matches will be displayed with a tag." placement="right">
                <div>
                  <HiInformationCircle />
                </div>
              </Tippy>
            </div>
          </div>
        </Col>
        <Col>
          {competitionData?.game?.shortName === 'LoL' && <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            <Checkbox
              type="checkbox"
              style={{ Width: '30px', Height: '30px' }}
              checked={sideSelection}
              onChange={() => setSideSelection(!sideSelection)}
              className="check-type1"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <strong
                style={{
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat',
                  width: '104px',
                  height: '15px',
                  marginRight: '5px'
                }}>
                SIDE SELECTION
              </strong>
              <Tippy
                content="Matches with side selection will have team 1 choose their preferred side to start on."
                placement="right">
                <div>
                  <HiInformationCircle />
                </div>
              </Tippy>
            </div>
          </div>}
        </Col>
      </Row>
      <Row>
        {broadcasted ? (
          <Col>
            <div className="form-row" style={{ marginTop: '12px' }}>
              <label
                htmlFor="streamname"
                style={{
                  textTransform: 'uppercase',
                  // color: '#68717e',
                  color: 'white',
                  font: 'normal normal bold 12px/15px Montserrat',
                  marginTop: '2px',display:'flex',justifyContent:'flex-start',alignItems:'center'
                }}>
                broadcast channel
                <div
                  className="tag-team-round-matches w-100"
                  style={{ backgroundColor: 'rgb(213, 144, 31,0.1)',marginBottom:'4px' }}>
                  <div style={{font:'normal normal bold 10px/13px Montserrat',color:'#d5901f',paddingTop:'2px'}}>
                    overwrite
                  </div>
                </div>
              </label>
              <select
                id="streamname"
                name="streamname"
                className={`form-input`}
                defaultValue={stream}
                onChange={(e) => setStream(e.target.value)}>
                {competitionData?.streams?.map((strm, index) => (
                  <option key={strm._id} value={strm._id} selected={stream === strm._id}>
                    {strm.name}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              padding: '15px 0px'
            }}>
            <Checkbox
              // key={folder._id}
              type="checkbox"
              style={{ width: '30px', height: '30px' }}
              checked={useCustommatchSheduling}
              onChange={() => setUseCustommatchSheduling(!useCustommatchSheduling)}
              className="check-type1"
            />
            <strong
              style={{
                color: '#68717e',
                font: 'normal normal bold 12px/15px Montserrat',
                height: '15px'
              }}>
              {' '}
              USE CUSTOM MATCH RESCHEDULING
            </strong>
          </div>
        </Col>
      </Row>
      {useCustommatchSheduling ? (
        <Row style={{ alignItems: 'flex-start' }}>
          <Col style={{ paddingLeft: '63px', paddingTop: '10px' }}>
            <Form.Group className="mb-3">
              <label className="form-label lable">Earliest Date</label>
              <Datetime
                value={moment(earliestDate).format('DD-MM-YYYY HH:mm:ss')}
                onChange={(e) => setearliestDate(e)}
                dateFormat="DD-MM-YYYY"
                timeFormat="HH:mm:ss"
                name="earliestDate"
                // isValidDate={(currentDate) => currentDate.isAfter(payload.earliestDate)}
              />
            </Form.Group>
          </Col>
          <Col style={{ paddingTop: '10px' }}>
            <Form.Group className="mb-3">
              <label className="form-label lable">Latest Date</label>
              <Datetime
                value={moment(latestDate).format('DD-MM-YYYY HH:mm:ss')}
                onChange={(e) => setlatestDate(e)}
                dateFormat="DD-MM-YYYY"
                timeFormat="HH:mm:ss"
                name="latestDate"
              />
            </Form.Group>
            <span className="text-danger">
              {moment(earliestDate).isAfter(moment(latestDate))
                ? 'Latest Date should be after the Earliest Date'
                : null}
            </span>
          </Col>
        </Row>
      ) : null}
      <hr style={{ border: '1px solid  #22262e' }}></hr>
      <div className="d-flex justify-content-end mt-2">
        <button onClick={submitForm} className="create-button button primary w-25">
          Create Match
        </button>
      </div>
    </div>
  );
};

export default CreateMatcehsFunctionality;
