import React, { useState, useEffect, useRef } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import Modal from 'react-modal';
import { Col, Form, Row } from 'react-bootstrap';
import './_filterMatchesPopup.scss';
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import { filterMatches } from '../../Pages/MyCompetitions/ConpetitionTabs/MatchesTab/matchUtlis';
import { Checkbox, CheckboxSlider } from '../../_common';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCompetitionsMatches } from '../../../redux/action/CompetitonData';
import { HiX } from 'react-icons/hi';
import { groupByRoundfubt } from '../../Pages/MyCompetitions/ConpetitionTabs/MatchesTab/GroupBy';
const FilterMatchesPopup = ({
  open,
  onClose,
  competitionData,
  showMatches,
  setShowMatches,
  selectedMatch,
  setSelectedMatch,
  matches,
  groupIndex,
  stageIndex,
  setSelectAllMatches,
  groupBye
}) => {
  const [checkCompleteOrNot, setCheckCompleteOrNot] = useState({
    complete: false,
    upcomimg: false
  });
  const ref = useRef();
  const [fromDateTime, setFromDateTime] = useState(null);
  const [toDateTime, setToDateTime] = useState(null);
  const [fromRound, setFromRound] = useState(null);
  const [fromWeek, setFromWeek] = useState(null);
  const [toRound, setToRound] = useState(null);
  const [toWeek, setToWeek] = useState(null);
  const [lineup, setLineup] = useState();
  const [rescheduleLocked, setRescheduleLocked] = useState({ Both: true, yes: false, no: false });
  const [broadcasted, setBroadcasted] = useState({ Both: true, yes: false, no: false });
  const [sideSelection, setSideSelection] = useState({ Both: true, yes: false, no: false });
  const [allMatchesArray, setAllMatchesArray] = useState([]);
  const Lineup = [
    'No filtering',
    'Roster not set for either team',
    'Roster set for one team',
    'Roster set for both teams'
  ];
  const dispatch = useDispatch();
  const { id } = useParams();
  const { errors } = useForm();
  // console.log(groupBye)
  useEffect(() => {
    if (fromWeek > 1000) {
      setFromWeek(1000);
    } else if (fromWeek < 0) {
      setFromWeek(0);
    }
  }, [fromWeek]);
  useEffect(() => {
    if (fromRound > 1000) {
      setFromRound(1000);
    } else if (fromRound < 0) {
      setFromRound(0);
    }
  }, [fromRound]);
  useEffect(() => {
    if (toRound > 1000) {
      setToRound(1000);
    } else if (toRound < 0) {
      setToRound(0);
    }
  }, [toRound]);
  useEffect(() => {
    if (toWeek > 1000) {
      setToWeek(1000);
    } else if (toWeek < 0) {
      setToWeek(0);
    }
  }, [toWeek]);

  const handleSubmit = async () => {
    // console.log(showMatches)
    let query = {
      fromDateTime,
      toDateTime,
      fromRound,
      fromWeek,
      toRound,
      toWeek,
      lineup,
      rescheduleLocked,
      broadcasted,
      sideSelection,
      checkCompleteOrNot
    };
    let matches = [];
    if (groupBye && groupBye !== '' && groupBye !== 'none') {
      // matches = filterMatches(allMatchesArray, query);
      let selectedMatches = allMatchesArray.filter((e) => selectedMatch.find((r) => r === e._id));
      let selectedGroupMatchesArray = groupByRoundfubt(selectedMatches, groupBye);
      let groupMatchesArray = groupByRoundfubt(allMatchesArray, groupBye);
      Object.keys(groupMatchesArray).map((key) => {
        let selected =
          selectedGroupMatchesArray[key] && selectedGroupMatchesArray[key].length
            ? selectedGroupMatchesArray[key]
            : [];
        matches = {
          ...matches,
          [key]: [...filterMatches(groupMatchesArray[key], query), ...selected]
        };
      });
      // matches=Object.keys(matches).map((key) => );
    } else {
      matches = filterMatches(allMatchesArray, query);
    }
    if (matches.length !== allMatchesArray.length && (!groupBye || groupBye === 'none')) {
      let selectedMatches = showMatches.filter((e) => selectedMatch.find((r) => r === e._id));
      setSelectAllMatches(true);
      let ids = matches.map((match) => match._id);
      setSelectedMatch([...ids, ...selectedMatch]);
      setShowMatches([...matches, ...selectedMatches]);
    } else if (
      matches.length !== allMatchesArray.length &&
      groupBye &&
      groupBye !== '' &&
      groupBye !== 'none'
    ) {
      setSelectAllMatches(true);
      setShowMatches(matches);
    } else {
      setShowMatches(showMatches);
    }
    onClose(false);
  };
  const handleCancel = async () => {
    setShowMatches(allMatchesArray);
    onClose(false);
  };
  useEffect(() => {
    if (matches) {
      if (stageIndex === 0) {
        // console.log(matches[stageIndex][0]?.AllMatches)
        setAllMatchesArray(matches[stageIndex][0]?.AllMatches);
        // setShowMatches(matches[stageIndex][0]?.AllMatches);
      } else if (groupIndex === 0) {
        setAllMatchesArray(matches[stageIndex][0]?.allStagesmatches);
        //  console.log(matches[stageIndex][0]?.allStagesmatches)
        // setShowMatches(matches[stageIndex][0]?.allStagesmatches);
      } else {
        setAllMatchesArray(matches[stageIndex][groupIndex]?.groupMatches);
        // console.log(matches[stageIndex][groupIndex]?.groupMatches)
        // setShowMatches(matches[stageIndex][groupIndex]?.groupMatches);
      }
    }
  }, [matches, groupIndex, stageIndex]);
  return (
    <Modal
      style={{ content: { width: 520 } }}
      isOpen={open}
      onRequestClose={onClose}
      className="modal editCategories"
      shouldCloseOnEsc={false}>
      <div
        className="modal-close" style={{margin:'6px 26px 0 0'}}
        onClick={() => {
          onClose(false);
        }}>
        <RiCloseLine />
      </div>
      <h2 className="modal-header uppercase bold" style={{ textAlign: 'left', font:'normal normal bold 18px/22px Montserrat',padding:'0 35px 0 33px' }}>
        filter matches by
      </h2>
      <div className="modal-body">
        <div className="uppercase bold new">time interval</div>
        <div>
          <Row>
            <Col style={{ width: '50%' }}>
              <Form.Group className="mb-3">
                <Form.Label
                  style={{
                    textTransform: 'uppercase',
                    color: '#68717e',
                    font: 'normal normal bold 12px/15px Montserrat'
                  }}>
                  From
                </Form.Label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse'
                  }}>
                  {fromDateTime?.length > 10 ? (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: '1',
                        // background: 'black',
                        background: '#171a22',
                        top: '45%',
                        right: '15px',
                        color: '#68717e'
                      }}>
                      <HiX onClick={() => setFromDateTime(null)} />
                    </div>
                  ) : null}
                  <input
                    type="datetime-local"
                    placeholder="Select earliest date"
                    id="border-color"
                    style={{
                      colorScheme:'dark',opacity:'0.5',color:'#fff',border:'1px solid rgb(44,48,56)'
                    }}
                    // onFocus={(e) => (e.target.type = 'date')}
                    // onBlur={(e) => (e.target.type = 'text')}
                    className={`fromtime filterinputfontsize ${
                      fromDateTime===null ?
                       'iputdatetime1' : null
                    }`}
                    // className="fromtime filterinputfontsize"
                    value={fromDateTime}
                    onChange={(e) => setFromDateTime(e.target.value)}
                  />
                  {/* <input
                    type="datetime-local"
                    placeholder="ali"
                    className="fromtime filterinputfontsize"
                    value={fromDateTime}
                    onChange={(e) => setFromDateTime(e.target.value)}
                  /> */}
                </div>
              </Form.Group>
            </Col>
            <Col style={{ width: '50%' }}>
              <Form.Group className="mb-3">
                <Form.Label
                  style={{
                    textTransform: 'uppercase',
                    color: '#68717e',
                    font: 'normal normal bold 12px/15px Montserrat'
                  }}>
                  To
                </Form.Label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse'
                  }}>
                  {toDateTime?.length > 10 ? (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: '1',
                        // background: 'black',
                        background: '#171a22',
                        top: '45%',
                        right: '15px',
                        color: '#68717e'
                      }}>
                      <HiX onClick={() => setToDateTime(null)} />
                    </div>
                  ) : null}
                  <input
                    type="datetime-local"
                    placeholder="Select latest date"
                    style={{
                      colorScheme:'dark',opacity:'0.5',color:'#fff',border:'1px solid rgb(44,48,56)'
                    }}
                    id="border-color"
                    className={`fromtime  filterinputfontsize ${
                      toDateTime===null ?
                       'iputdatetime' : null
                    }`}
                    value={toDateTime}
                    onChange={(e) => {
                      setToDateTime(e.target.value);
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="uppercase bold new">Week / round interval</div>
        <div>
          <Row>
            <Col style={{ width: '50%' }}>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                From Week
              </Form.Label>
              {fromWeek > null ? (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    // background: 'black',
                    background: '#171a22',
                    // top: '55%',
                    top: '49%',
                    right: '14px',
                    color: '#68717e'
                  }}>
                  <HiX onClick={() => setFromWeek('')} />
                </div>
              ) : null}
              <input
                id="number"
                className="filterinputfontsize week1"
                type="number"
                value={fromWeek}
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                onChange={(e) => setFromWeek(e.target.value)}
                max="1000"
                placeholder="Select minimum week"
              />
            </Col>
            <Col style={{ width: '50%' }}>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                To Week
              </Form.Label>
              {toWeek > null ? (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    // background: 'black',
                    background: '#171a22',
                    // top: '55%',
                    top: '49%',
                    right: '14px',
                    color: '#68717e'
                  }}>
                  <HiX onClick={() => setToWeek('')} />
                </div>
              ) : null}
              <input
                className="filterinputfontsize week1"
                id="number"
                type="number"
                value={toWeek}
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                onChange={(e) => setToWeek(e.target.value)}
                max="1000"
                placeholder="Select maximum week"
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '50%' }}>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                From Round
              </Form.Label>
              {fromRound > null ? (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    // background: 'black',
                    background: '#171a22',
                    top: '55%',
                    // top: '49%',
                    right: '14px',
                    color: '#68717e'
                  }}>
                  <HiX onClick={() => setFromRound('')} />
                </div>
              ) : null}
              <input
                className="filterinputfontsize"
                id="number"
                type="number"
                value={fromRound}
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                onChange={(e) => setFromRound(e.target.value)}
                max="1000"
                placeholder="Select minimum round"
              />
            </Col>
            <Col style={{ width: '50%' }}>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                To Round
              </Form.Label>
              {toRound > null ? (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    // background: 'black',
                    background: '#171a22',
                    top: '55%',
                    right: '14px',
                    color: '#68717e'
                  }}>
                  <HiX onClick={() => setToRound('')} />
                </div>
              ) : null}
              <input
                className="filterinputfontsize"
                id="number"
                type="number"
                value={toRound}
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                onChange={(e) => setToRound(e.target.value)}
                max="1000"
                placeholder="Select maximum round"
              />
            </Col>
          </Row>
        </div>
        {competitionData?.participantType === 2 &&
        competitionData?.stages[0]?.league?.forfeitTeam !== 'Do nothing' ? (
          <>
            <div className="uppercase bold" style={{ margin: '10px 0px' }}>
              Lineup set
            </div>
            <div>
              <Row>
                <Col>
                  <div className="form-row">
                    <select
                      id="region"
                      name="region"
                      className={`form-input ${errors.game ? 'invalid' : ''}`}
                      defaultValue={Lineup[0]}
                      onChange={(e) => {
                        const selectedRegion = e.target.value;
                        setLineup(selectedRegion);
                      }}>
                      {Lineup?.map((name, index) => (
                        <option key={index + 'Lineup'} value={name} selected={name === lineup}>
                          {name}
                        </option>
                      ))}
                    </select>
                    {errors.game && <div className="form-input-error">{errors.game.message}</div>}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : null}
        <div className="uppercase bold new" style={{ margin: '10px 0px 14px 0px' }}>
          MATCH STATUS
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            // key={folder._id}
            onChange={() => {
              setCheckCompleteOrNot({
                complete: checkCompleteOrNot?.complete,
                upcomimg: !checkCompleteOrNot?.upcomimg
              });
            }}
            type="checkbox"
            checked={checkCompleteOrNot?.upcomimg}
            className="check-type1"
          />
          <span
            style={{
              marginRight: '7px',
              marginLeft: '-10px',
              font: 'normal normal bold 12px/15px Montserrat',
              color: '#818386'
            }}>
            UPCOMING
          </span>
          <Checkbox
            type="checkbox"
            onChange={() => {
              setCheckCompleteOrNot({
                complete: !checkCompleteOrNot?.complete,
                upcomimg: checkCompleteOrNot?.upcomimg
              });
            }}
            checked={checkCompleteOrNot?.complete}
            className="check-type1"
          />
          <span
            style={{
              marginRight: '7px',
              marginLeft: '-10px',
              font: 'normal normal bold 12px/15px Montserrat',
              color: '#818386'
            }}>
            COMPLETE
          </span>
        </div>
        <div className="uppercase bold" style={{ margin: '10px 0px' }}>
          Filter by criteria
        </div>
        {/* <Form.Label
          style={{
            textTransform: 'uppercase',
            color: '#68717e',
            font: 'normal normal bold 12px/15px Montserrat'
          }}>
          Reschedule Locked
        </Form.Label>
        <Row>
          <Col>
            <Form.Group>
              <CheckboxSlider
                label={'Yes'}
                value={rescheduleLocked.yes}
                onChange={(e) => {
                  setRescheduleLocked({ Both: false, yes: true, no: false });
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <CheckboxSlider
                label={'No'}
                value={rescheduleLocked.no}
                onChange={(e) => {
                  setRescheduleLocked({ Both: false, yes: false, no: true });
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <CheckboxSlider
                label={'Both'}
                value={rescheduleLocked.Both}
                onChange={(e) => {
                  setRescheduleLocked({ Both: true, yes: false, no: false });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Label
          style={{
            textTransform: 'uppercase',
            color: '#68717e',
            font: 'normal normal bold 12px/15px Montserrat'
          }}>
          broadcasted
        </Form.Label>
        <Row>
          <Col>
            <Form.Group>
              <CheckboxSlider
                unique="broadcastedyes"
                label={'Yes'}
                value={broadcasted.yes}
                onChange={(e) => {
                  setBroadcasted({ Both: false, yes: true, no: false });
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <CheckboxSlider
                unique="broadcastedNo"
                label={'No'}
                value={broadcasted.no}
                onChange={(e) => {
                  setBroadcasted({ Both: false, yes: false, no: true });
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <CheckboxSlider
                unique="broadcastedBoth"
                label={'Both'}
                value={broadcasted.Both}
                onChange={(e) => {
                  setBroadcasted({ Both: true, yes: false, no: false });
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        {competitionData?.game?._id === '5f342371f52fd80836260de8' ? (
          <>
            <Form.Label
              style={{
                textTransform: 'uppercase',
                color: '#68717e',
                font: 'normal normal bold 12px/15px Montserrat'
              }}>
              side selection
            </Form.Label>{' '}
            <Row>
              <Col>
                <Form.Group>
                  <CheckboxSlider
                    unique="sideSelectionyes"
                    label={'Yes'}
                    value={sideSelection.yes}
                    onChange={(e) => {
                      setSideSelection({ Both: false, yes: true, no: false });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <CheckboxSlider
                    unique="sideSelectionno"
                    label={'No'}
                    value={sideSelection.no}
                    onChange={(e) => {
                      setSideSelection({ Both: false, yes: false, no: true });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <CheckboxSlider
                    unique="sideSelectionboth"
                    label={'Both'}
                    value={sideSelection.Both}
                    onChange={(e) => {
                      setSideSelection({ Both: true, yes: false, no: false });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : null} */}
        <div style={{ border: '1px solid #22262E', padding: '13px 15px' }}>
          <Form.Label
            style={{
              textTransform: 'uppercase',
              color: '#68717e',
              font: 'normal normal bold 12px/15px Montserrat'
            }}>
            Reschedule Locked
          </Form.Label>
          <Row>
            <Col>
              <Form.Group>
                <CheckboxSlider
                  label={'Yes'}
                  value={rescheduleLocked.yes}
                  onChange={(e) => {
                    setRescheduleLocked({ Both: false, yes: true, no: false });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <CheckboxSlider
                  label={'No'}
                  value={rescheduleLocked.no}
                  onChange={(e) => {
                    setRescheduleLocked({ Both: false, yes: false, no: true });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <CheckboxSlider
                  label={'Both'}
                  value={rescheduleLocked.Both}
                  onChange={(e) => {
                    setRescheduleLocked({ Both: true, yes: false, no: false });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Label
            style={{
              textTransform: 'uppercase',
              color: '#68717e',
              font: 'normal normal bold 12px/15px Montserrat'
            }}>
            broadcasted
          </Form.Label>
          <Row>
            <Col>
              <Form.Group>
                <CheckboxSlider
                  unique="broadcastedyes"
                  label={'Yes'}
                  value={broadcasted.yes}
                  onChange={(e) => {
                    setBroadcasted({ Both: false, yes: true, no: false });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <CheckboxSlider
                  unique="broadcastedNo"
                  label={'No'}
                  value={broadcasted.no}
                  onChange={(e) => {
                    setBroadcasted({ Both: false, yes: false, no: true });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <CheckboxSlider
                  unique="broadcastedBoth"
                  label={'Both'}
                  value={broadcasted.Both}
                  onChange={(e) => {
                    setBroadcasted({ Both: true, yes: false, no: false });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          {competitionData?.game?._id === '5f342371f52fd80836260de8' ? (
            <>
              <Form.Label
                style={{
                  textTransform: 'uppercase',
                  color: '#68717e',
                  font: 'normal normal bold 12px/15px Montserrat'
                }}>
                side selection
              </Form.Label>{' '}
              <Row>
                <Col>
                  <Form.Group>
                    <CheckboxSlider
                      unique="sideSelectionyes"
                      label={'Yes'}
                      value={sideSelection.yes}
                      onChange={(e) => {
                        setSideSelection({ Both: false, yes: true, no: false });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <CheckboxSlider
                      unique="sideSelectionno"
                      label={'No'}
                      value={sideSelection.no}
                      onChange={(e) => {
                        setSideSelection({ Both: false, yes: false, no: true });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <CheckboxSlider
                      unique="sideSelectionboth"
                      label={'Both'}
                      value={sideSelection.Both}
                      onChange={(e) => {
                        setSideSelection({ Both: true, yes: false, no: false });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
        </div>
        <hr />
        <div
          className="mt-4 modal-footer"
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
          {/* <button className="shareButton perviewButton" onClick={handleCancel}></button> */}
          <button className="shareButton " onClick={handleCancel} style={{font:'normal normal bold 10px/13px Montserrat',width: '25%'}}>
            CANCEL
          </button>
          <div style={{ color: ' #22262e', marginLeft: '6px' }}>|</div>
          <button className="button primary thicc" style={{ width: '25%',font:'normal normal bold 10px/13px Montserrat' }} onClick={handleSubmit}>
            ADD FILTERS
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterMatchesPopup;
