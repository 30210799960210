import { SET_CONTEXT_OBJ } from "../action/types";

const initialState = {
  ContextObj: {},
  ID: null
};

// Use the initialState as a default value
export default function contextMenuReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONTEXT_OBJ:
      return {
        ...state,
        ContextObj: action.payload
      };
    default:
      return state;
  }
}
