import React from 'react';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
const SettingRules = ({ payload, setPayload,handleSubmitSettings,userPermissions }) => {
  const onSubmit =  () => {
    handleSubmitSettings()
  }
  return (
    <Col>
      <Col md={3}>
        <Form.Group>
          <label className="form-label lable">Rules Ghost Slug</label>
          <Form.Control
            type="text"
            id="rulesGhostSlug"
            name="rulesGhostSlug"
            placeholder="Example: leagues-gg-rules-2"
            value={payload.rulesGhostSlug}
            onChange={(e) => {
              const value = e.target.value;
              setPayload((state) => ({ ...state, rulesGhostSlug: value }));
            }}
          />
        </Form.Group>
      </Col>
      <Row style={{ padding: '45px' }}>
        <button className="button primary" onClick={() => onSubmit()}>
          Save Changes
        </button>
        {userPermissions.createAndDeleteCompetitions && <button className="button primary redButton">Delete Competition</button>}
      </Row>
    </Col>
  );
};
export default SettingRules;
