import React from 'react';
import Tippy from '@tippyjs/react';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { Row, Col } from 'react-bootstrap';
import { MdDragHandle } from 'react-icons/md';
import { TiArrowUnsorted } from 'react-icons/ti';
import { AiOutlineTrophy } from 'react-icons/ai';
import { HiInformationCircle } from 'react-icons/hi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './StageContext.scss';
import { Loading } from '../../../../_common';

const place = { 1: '1st', 2: '2nd', 3: '3rd' };

const StageContent = ({ stage, manually, onDragEnd, onChange, selectedGroup, shortName, stageIndex, loadingState }) => {
  let manualCriteria = stage.rankingCriteria.filter(ranking => ranking.criteria === 'Manual')
  const getTeams = () => {
    let group = stage.groups.filter((group) => group.name === selectedGroup);
    let competition = stage?.league ?? stage?.tournament
    if (stage && competition && competition?.teams.length !== 0 && group[0].teams.length !== 0) {
      //&& group[0].matchCompleted == true
      return group[0].teams.map((team, index) => {
        index = index + 1;
        return (
          <Draggable key={team._id} draggableId={team._id} index={index - 1}>
            {(provided) => (
              <tr ref={provided.innerRef} {...provided.draggableProps}>
                <td {...provided.dragHandleProps}>
                  {manually && <MdDragHandle color={'#68717E'} size={15} />}
                  <AiOutlineTrophy
                    // color={'#D5901F'}
                    size={20}
                    // style={{ verticalAlign: 'bottom' }}
                    style={
                      index === 0
                        ? { verticalAlign: 'bottom', color: '#d5901f' }
                        : index === 1
                        ? { verticalAlign: 'bottom', color: '#999999' }
                        : index === 2
                        ? { verticalAlign: 'bottom', color: '#a9631b' }
                        : { verticalAlign: 'bottom', color: '#68717e' }
                    }
                  />
                  {index > 3 ? `${index}th` : place[index]}
                </td>
                <td>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Image
                      src={( stageIndex === 0 || group[0].matchCompleted) ? team?.team?.logo :"https://picsum.photos/seed/picsum/30/30"}
                      style={{ borderRadius: 30, marginRight: 15, width: 35 }}
                    />{' '}
                    <p>{( stageIndex === 0 || group[0].matchCompleted) ? team.team.name : 'TBD'}</p>
                  </span>
                </td>
                <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={team?.results?.Points ? team?.results?.Points : team.score}
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, score: e.target.value })}
                  />
                </td>
                {shortName === 'CSGO' || shortName === 'VL' && <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={team?.results?.rd ? team.results.rd : 0}
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, mapsWon: e.target.value })}
                  />
                </td>}
                <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={
                      team?.results?.wins
                        ? team?.results?.wins + team?.results?.losses + team?.results?.draws
                        : team?.results?.losses ? team?.results?.losses + team?.results?.draws : team?.results?.draws ? team?.results?.draws : 0
                    }
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, mapsWon: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={team?.results?.wins ? team.results.wins : 0}
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, mapsWon: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={team?.results?.draws ? team.results.draws : 0}
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, mapsWon: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={team?.results?.losses ? team.results.losses : 0}
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, mapsWon: e.target.value })}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={team?.results?.forfeits ? team.results.forfeits : 0}
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, mapsLost: e.target.value })}
                  />
                </td>
                {manualCriteria.length > 0 && <td>
                  <Form.Control
                    type="number"
                    disabled={!manually}
                    defaultValue={team?.results?.forfeits ? team.results.forfeits : 0}
                    column={'sm'}
                    className={'form-input black-bg'}
                    onChange={(e) => onChange({ index: index - 1, mapsLost: e.target.value })}
                  />
                </td>}
              </tr>
            )}
          </Draggable>
        );
      });
    } else {
      let rows = [];
      for (let i = 0; i < group[0].noOfTeams; i++) {
        rows.push(
          <tr>
            <td className='td-width'  style={{width:'13%'}}>
              {manually && <MdDragHandle color={'#68717E'} size={15} />}
              <AiOutlineTrophy
                // color={'#D5901F'}
                size={20}
                style={
                  i === 0
                    ? { verticalAlign: 'bottom', color: '#d5901f' }
                    : i === 1
                    ? { verticalAlign: 'bottom', color: '#999999' }
                    : i === 2
                    ? { verticalAlign: 'bottom', color: '#a9631b' }
                    : { verticalAlign: 'bottom', color: '#68717e' }
                }
              />{' '}
              {i + 1 > 3 ? `${i + 1}th` : place[i + 1]}
            </td>
            <td className='td-width-2 '>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Image
                  src="https://picsum.photos/seed/picsum/30/30"
                  style={{ borderRadius: 30, marginRight: 15 }}
                />{' '}
                <p>{'TBD'}</p>
              </span>
            </td>
            <td >
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), score: e.target.value})}*/
              />
            </td>
            {shortName === 'CSGO' || shortName === 'VL' && <td >
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), score: e.target.value})}*/
              />
            </td>}
            <td >
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), score: e.target.value})}*/
              />
            </td>
            <td>
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), score: e.target.value})}*/
              />
            </td>
            <td>
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), score: e.target.value})}*/
              />
            </td>
            <td>
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), mapsWon: e.target.value})}*/
              />
            </td>
            <td>
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), mapsLost: e.target.value})}*/
              />
            </td>
            {manualCriteria.length > 0 && <td>
              <Form.Control
                type="number"
                defaultValue={0}
                disabled={!manually}
                column={'sm'}
                className={
                  'form-input black-bg'
                } /*onChange={(e) => onChange({index: (i), mapsLost: e.target.value})}*/
              />
            </td>}
          </tr>
        );
      }
      return rows;
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <h2>LEADERBOARD</h2>
        </Col>
        <Col md={12} className='table-responsive'>
          {loadingState && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loading/></div>}
          {(!loadingState) && <Table variant="dark" className='leaderboard'>
            <thead>
              <tr key={Math.random()}>
                <th style={{paddingLeft:'0px'}}>
                  <div style={{display:'flex'}}>
                    PLACE{' '}
                    <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} />
                  </div>
                </th>
                <th style={{paddingLeft:'0px'}}>
                  <div style={{display:'flex'}}>
                    TEAM NAME{' '}
                    <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} />
                  </div>
                </th>
                <th>
                  <div style={{display:'flex'}}>
                      SCORE{' '}
                      <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} /> 
                      </div>   
                </th>
                {shortName === 'CSGO' || shortName === 'VL' && <th style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{display:'flex'}}>
                    RD{' '}
                    <Tippy content="RD is an acronym for ‘round difference’ and is the accumulated round score difference a participant has had with its opponents." placement="right">
                      <div>
                        <HiInformationCircle style={{ width: '15px', marginLeft: '.2em', cursor: 'pointer' }}/>
                      </div>
                    </Tippy> 
                    </div>   
                </th>}
                <th>
                  <div style={{display:'flex'}}>
                    MATCHES{' '}
                    <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} />  
                    </div>  
                </th>
                <th>
                  <div style={{display:'flex'}}>
                    MATCHES WON{' '}
                    <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} />  
                    </div>
                </th>
                <th>
                  <div style={{display:'flex'}}>
                    MATCHES TIED{' '}
                    <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} />   
                    </div> 
                </th>
                <th>
                  <div style={{display:'flex'}}>
                    MATCHES LOST{' '}
                    <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} />  
                    </div>    
                </th>
                <th>
                  <div style={{display:'flex'}}>
                    FORFEITS{' '}
                    <TiArrowUnsorted style={{ width: '15px', marginTop: '2px', cursor: 'pointer' }} />  
                    </div>    
                </th>
                {manualCriteria.length > 0 && <th style={{ display: 'flex', alignItems: 'center' }}>
                    RANKING POINTS{' '}
                    <Tippy content="Ranking points are manually distributed by a tournament organizer to rank teams in a leaderboard, usually by resolving ties." placement="right">
                      <div>
                        <HiInformationCircle style={{ width: '25px',height:'25px', marginLeft: '.2em', cursor: 'pointer',marginTop:'4px',paddingLeft:'5px' }}/>
                      </div>
                    </Tippy>      
                </th>}
              </tr>
            </thead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {getTeams()}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>}
        </Col>
      </Row>
    </>
  );
};
export default StageContent;
