import React from 'react';
import checkmark from '../../../img/check.png';
import checkmarkCSGO from '@img/check-mark-csgo.svg';
import checkmark1 from '../../../img/check.png';
import Tippy from '@tippyjs/react';
// import "./_checkbox.scss"
const Checkbox = ({
  label,
  checked,
  onChange,
  className,
  customClass,
  checkBoxClass,
  icon,
  style,
  tooltipText,
  game,
  isAligned,
  style1,matchesbg
}) => {
  return (
    <div className={`checkbox-wrapper noselect row m-0 p-0 align-items-center ${className}`} style={style ? style : style1}>
      <div
        onClick={onChange}
        className={`${
          checkBoxClass
            ? checkBoxClass
            : game === 'CS:GO'
            ? 'checkbox-container checkbox-csgo'
            : 'checkbox-container'
        }`}
        // style={checked ? { background: '#3eabf0' } : null}
        // style={checked ? { background: '#3eabf0',border:'1px solid #474d55',height:'16px',width:'16px' } : { border:'1px solid #474d55',height:'16px',width:'16px'}}
        style={checked ? { background: '#3eabf0',border:'1px solid #474d55',height:'16px',width:'16px' } : matchesbg ? {backgroundColor:'#03060d', border:'1px solid #474d55',height:'16px',width:'16px'} : {border:'1px solid #474d55',height:'16px',width:'16px'}}
      >
        {checked ? (
          <img
            className="check-mark"
            src={game === 'CS:GO' ? checkmarkCSGO : checkBoxClass ? checkmark1 : checkmark}
          />
        ) : null}
      </div>
      {/* <p className={`check-label ${customClass}`} style={{fontWeight:'700',textTransform:"uppercase"}}>{label}</p> */}
      <p className={`check-label ${customClass}`} style={{font :'normal normal normal 12px/15px Montserrat'}}>{label}</p>

     {icon && (
        <Tippy content={tooltipText} placement="right" animation="shift-toward">
          <div className="icon">{icon}</div>
        </Tippy>
      )}
    </div>
  );
};

export default Checkbox;
