import React, { useState, createContext, useEffect } from 'react';
import Axios from 'axios';
// import './_unSavedChanges.scss';
import { useDispatch, useSelector } from 'react-redux';
import Popup_modals from '../../../_modals/OrganizerPopup/modals/Popup_modals';
import { RiCloseLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { getFolders, getOrganizer } from '../../../../redux/action/competitionActions';
// import { setIsAnyChangeInTab, setUnSavedStages } from '../../../redux/action/CompetitonData';
const backendServerURL = process.env.REACT_APP_CORE_API;

const Component = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedTimezone = useSelector((state) => state.TIMEZONE.timeZone)
  const competitionDetails = useSelector((state) => state.FOLDERS.Folders);
  const [ids, setIds] = useState([{ organizer: null, folder: null }]);
  const [competitionName, setCompetitonNAme] = useState();

  useEffect(() => {
    console.log('ids', ids);
  }, [ids]);
  useEffect(() => {
    console.log('competitionDetails', competitionDetails.organizer);
    if (competitionDetails) {
      if (!competitionDetails?.organizer) {
        setIds((state) => [{ ...state, organizer: competitionDetails._id }]);
      } else
        setIds([{ organizer: competitionDetails.organizer._id, folder: competitionDetails._id }]);
    }
  }, [competitionDetails]);
  function generateRandom(maxLimit = 100){
    let rand = Math.random() * maxLimit;
    console.log(rand); // say 99.81321410836433
  
    rand = Math.floor(rand); // 99
  
    return rand;
  }
  const date= new Date();
  const cur= date.toJSON();
  let curDate = (cur.slice(0,11) + '18:00');
  const handleCreateCompetition = () => {
    const logos = ['https://www.freepnglogos.com/uploads/gta-5-logo-png/grand-theft-auto-v-1.png','https://www.freepnglogos.com/uploads/starbucks-logo-png-transparent-0.png','https://www.freepnglogos.com/uploads/telegram-logo-png-0.png','https://www.freepnglogos.com/uploads/pacman-png/pacman-icon-classic-games-icons-softiconsm-32.png','https://www.freepnglogos.com/uploads/jurassic-park-png-logo/jurassic-park-the-game-png-logo-9.png','https://cdn.pixabay.com/photo/2021/10/11/17/26/e-sports-6701392_1280.png','https://cdn.pixabay.com/photo/2019/11/28/15/28/baseball-4659392_1280.png','https://cdn.pixabay.com/photo/2021/02/10/00/10/house-stark-6000432_1280.png','https://cdn.pixabay.com/photo/2020/08/04/18/58/controller-5463628_1280.png']
    const thumbnails  =['https://rare-gallery.com/thumbs/560070-minecraft.jpg','https://rare-gallery.com/uploads/posts/915024-Xbox-Game-Studios-Minecraft-Minecraft-Dungeons-video-games.jpg','https://rare-gallery.com/thumbs/511659-no-mans-sky.jpg','https://rare-gallery.com/thumbs/511659-no-mans-sky.jpg','https://rare-gallery.com/thumbs/516930-star-wars-battlefront.jpg','https://rare-gallery.com/thumbs/569745-red-dead-redemption.jpg','https://rare-gallery.com/thumbs/527995-Halo-3-ODST.jpg','https://rare-gallery.com/thumbs/561183-arcade-video.jpg']
    const body = {
      name: competitionName,
      organizer: ids[0].organizer,
      folder: ids[0].folder,
      logo: logos[generateRandom(logos.length - 1)],
      cover: thumbnails[generateRandom(thumbnails.length - 1)],
      thumbnail: thumbnails[generateRandom(thumbnails.length - 1)],
      season: process.env.REACT_APP_COMPETITION_SEASON_ID,
      timezone : selectedTimezone,
      startedDate : curDate
    };
  
    Axios.post(backendServerURL + '/api/organizer/competition', body)
      .then(() => {
        toast.success('Created Successfully');
        if (body?.folder) {
          dispatch(getFolders(ids[0].folder));

          onClose(false);
        } else {
          dispatch(getOrganizer(ids[0].organizer));
          onClose(false);

        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  return (
    <div className="newfolder-main">
      <div className="modal-close" onClick={() => onClose(false)}>
        {/* <RiCloseLine color={'white'} /> */}
      </div>
      <div className="modal-header">
        <h2 className="bold uppercase">CREATE NEW COMPETITION</h2>
      </div>
      <div style={{ padding: '5% 6%' }}>
        <h3>COMPETITION NAME</h3>
        <input
          type="text"
          className='form-input'
          onChange={(e) => {
            setCompetitonNAme(e.target.value);
          }}
        />
        <button
          className="primary button"
          style={{ marginTop: '20px' }}
          onClick={() => {
            handleCreateCompetition();
          }}>
          Create Competition
        </button>
      </div>
    </div>
  );
};
const NewCompetionModal = ({ open, onClose }) => {
  console.log('open', open);
  return <Popup_modals open={open} onClose={onClose} Component={Component} />;
};
export default NewCompetionModal;
