import React, { useState, useEffect, useContext, useMemo } from 'react';
// import 'antd/dist/antd.css';
import { HiFolder, HiDotsHorizontal } from 'react-icons/hi';
import './list-view.scss';
import { Button, Space, Table } from 'antd';
import { TiArrowUnsorted } from 'react-icons/ti';
import ListViewItem from '../ListViewItem/ListViewItem';
// import ItemsDragLayer from './ItemsDragLayer';
import AddNewFolder from '../Folder/AddNewFolder';
import { useDragLayer } from 'react-dnd';
import ItemRow from '../ListViewItem/ItemRow';
import Loading from '../Loading/Loading';
import { useSelector } from 'react-redux';
// import ItemsTemplate from './ItemsTemplate';
import Editbar from '../../Pages/MyOrganizations/Organizer/Editbar';
import { AppContext } from '../../../context/AppContext';
import {
  canCreateFolder,
  checkDeletePermForMultipleItems,
  checkDeletePermission
} from '../../../helpers/permissionsHelper';
import { ListViewRow } from '../ListViewItem/ListViewRow';
import useMultiSelect from '../../../multiselectionHelpers/hooks/useMultiselection';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import DragNDropWrapper from '../DragNDropWrapper/DragNDropWrapper';
import { CustomDragLayerGrid } from '../DragNDropWrapper/CustomDragLayerGrid';
import DeleteItems from '../../_modals/OrganizerPopup/deleteitems/delete';
import { getFolders, getOrganizer } from '../../../redux/action/competitionActions';
import { useDispatch } from 'react-redux';
import useClickOutsideNav from '../ContextMenu/hooks/useClickOutsideNav';
const ListView = ({
  parentFolder,
  alldata,
  clearItemSelection,
  getTrans,
  handleFolderSelection,
  onSort,
  handleKeyDown = () => {}
}) => {
  const { user } = useContext(AppContext);
  //New Work
  const { id, folderId } = useParams();

  const [mainFolder, setMainFolder] = useState(parentFolder);
  const [hoveredId, setHoveredId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const [canCreate, setCanCreate] = useState(false);
  const loading = useSelector((state) => (state.LOADING.Loading ? state.LOADING.Loading : false));
  const [addnewfolder, setAddnewfolder] = useState();
  const selectedFolders = useSelector((state) => state.DRAGNDROP.SelectedFolders ?? []);
  const inheritedPermissions = useSelector((state) =>
    state.FOLDERS?.InheritedPermissions ? state.FOLDERS.InheritedPermissions : []
  );
  const competitionMatches = useSelector((state) => state.FOLDERS?.matchCount)
  const handleDelete = (type, selected) => {
    const { folders, competitions } = selected;

    const folderObjects = parentFolder?.folders
      ? parentFolder?.folders.filter((elem) => folders.includes(elem?._id))
      : [];
    const compObjs = parentFolder?.competitions
      ? parentFolder?.competitions.filter((elem) => competitions.includes(elem?._id))
      : [];
    const allowed = checkDeletePermForMultipleItems(user, inheritedPermissions, [
      ...folderObjects,
      ...compObjs
    ]);
    if (type && allowed) {
      setDeleteType(type);
      setOpenDelete(!openDelete);
    }
  };
  const onHoverHandler = (id, type) => {
    switch (type) {
      case 'IN':
        return setHoveredId(id);
      case 'OUT':
        return setHoveredId(null);
      default:
        return setHoveredId(null);
    }
  };
  let competitionStarted = false;
  const {
    selectedItems,
    handleSelect,
    handleSelectAll,
    handleClearSelection,
    handlePreventSelection
  } = useMultiSelect(mainFolder, handleDelete);
  const history = useHistory();
  const dispatch = useDispatch();
  const showDelete = useMemo(() => {
    const { folders, competitions } = selectedItems;
    if (parentFolder) {
      const folderObjects = parentFolder?.folders
        ? parentFolder?.folders.filter((elem) => folders.includes(elem?._id))
        : [];
      const compObjs = parentFolder?.competitions
        ? parentFolder?.competitions.filter((elem) => competitions.includes(elem?._id))
        : [];
      return checkDeletePermForMultipleItems(user, inheritedPermissions, [
        ...folderObjects,
        ...compObjs
      ]);
    }
  }, [selectedItems]);
  const getLastEdited = ({ lastEditedDate, lastEditor }) => {
    let _lastEdited = moment(lastEditedDate).format('DD/MM-YYYY');
    let editorName = 'User';
    if (user?.state?._id === lastEditor?._id) {
      editorName = 'Me';
    } else {
      editorName = lastEditor?.firstName + ' ' + lastEditor?.lastName;
    }
    return `${_lastEdited} - ${editorName}`;
  };
  const getObjectFromId = (typ, id) => {
    if (typ === 'folders') {
      return parentFolder?.folders.find((folder) => folder._id === id);
    }
    if (typ === 'competitions') {
      return parentFolder?.competitions.find((competition) => competition._id === id);
    }
  };
  const handleRouting = (id, type) => {
    if (type === 'FOLDER') {
      return history.push(`/folders/${id}`);
    }
    if (type === 'COMPETITION') {
      let url = `/competition/${id}`;
      window.open(url, '_blank').focus();
    }
  };
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging()
  }));

  //End New Work
  const [defaultSort, setDefaultSort] = useState({
    NAME: true,
    TOURNAMENT_DATE: true,
    MATCHES: true,
    ORG: true,
    FOLDER: true,
    ORGANIZER: true,
    LAST_MODIFIED: true
  });

  var isLastView = true;
  const updateData = () => {
    if (folderId) {
      dispatch(getFolders(folderId));
    }
    if (id) {
      dispatch(getOrganizer(id));
    }
  };
  useEffect(() => {
    updateData();
  }, [addnewfolder]);

  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)
    clearItemSelection();
    //onSort(parentFolder, 'NAME', defaultSort.NAME); // i commented this line bcz its creating issue with selection
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log('unmounting...');
  }, []);
  useEffect(() => {
    if (typeof parentFolder === 'object') {
      let parentPerms = parentFolder?.organizerRoles ?? [];
      let can = canCreateFolder(user, [...parentPerms, ...inheritedPermissions]);
      setCanCreate(can);
    }
  }, [parentFolder, inheritedPermissions]);
  useClickOutsideNav((e) => {
    handleClearSelection();
  });

  const searchMatchCount = (competitonId) => {
    let match = competitionMatches.filter(item => item._id === competitonId)
    return match.length > 0 ? match[0].count : 0
  }

  return (
    <div>
      {loading ? (
        <div className="text-center league-no-matches">
          <Loading width={50} height={50} />
        </div>
      ) : (
        <>
          <div>
            {[...selectedItems?.folders, ...selectedItems?.competitions].length > 1 ? (
              <Editbar
                showDelete={showDelete}
                selectedItems={selectedItems}
                handleClearSelection={handleClearSelection}
              />
            ) : null}
          </div>
          <div style={{ float: 'right', position: 'absolute', right: '0px', top: '-88px' }}>
            {canCreate && (
              <AddNewFolder
                isLastView={isLastView}
                setAddnewfolder={setAddnewfolder}
                parentId={parentFolder?.children ? parentFolder._id : null}
                OrganizaionId={
                  parentFolder.organizer ? parentFolder.organizer._id : parentFolder._id
                }
              />
            )}
          </div>
          <table
            onKeyDown={handleKeyDown}
            tabIndex={0}
            className="table wrapper-ms-unique-cls"
            border="0"
            cellSpacing="0"
            cellPadding="0"
            style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr> { /* need to get ride of this style={{ borderBottom: '1px solid #1d1f26' }} */}
                {/* <th scope="col">
              <input className="checkbox-row" type="checkbox"></input>
            </th> */}
                <th scope="col" style={{ width: '25%' }}>
                  <div
                    className="for-Arrows"
                    style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                    <div>NAME</div>
                    <div>
                      <TiArrowUnsorted
                        onClick={(e) => {
                          onSort(parentFolder, 'NAME', defaultSort.NAME);
                          setDefaultSort((state) => ({ ...state, NAME: !defaultSort.NAME }));
                        }}
                        style={{
                          width: '20px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          paddingTop: '1px'
                          // alignItems: 'center'
                          // justifyContent: 'center'
                        }}
                      />
                    </div>
                  </div>
                </th>

                <th scope="col" style={{ width: '13%' }}>
                  <div
                    className="for-Arrows"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div>TOURNAMENT DATE</div>
                    <div>
                      <TiArrowUnsorted
                        // onClick={(e) => {
                        //   onSort('TOURNAMENT_DATE');
                        // }}
                        style={{
                          width: '20px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          paddingTop: '1px'
                          // alignItems: 'center'
                          // justifyContent: 'center'
                        }}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col" style={{ width: '15%' }}></th>
                <th scope="col" style={{ textAlign: 'center', width: '12%' }}>
                  <div
                    className="for-Arrows"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div>MATCHES</div>
                    <div>
                      <TiArrowUnsorted
                        // onClick={(e) => {
                        //   onSort('MATCHES');
                        // }}
                        style={{
                          width: '20px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          paddingTop: '1px'
                          // alignItems: 'center'
                          // justifyContent: 'center'
                        }}
                      />
                    </div>
                  </div>
                </th>
                <th cope="col" style={{ width: '15%' }}></th>
                {/* <th scope="col" style={{width:'15%'}}>

                </th> */}
                {/* <th scope="col">
                  <div className="for-Arrows" style={{ display: 'flex', alignItems: 'center' }}>
                    <div>ORG.</div>
                    <div>
                      <TiArrowUnsorted
                        // onClick={(e) => {
                        //   onSort('ORG');
                        // }}
                        style={{
                          width: '20px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          paddingTop: '1px'
                          // alignItems: 'center'
                          // justifyContent: 'center'
                        }}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="for-Arrows" style={{ display: 'flex', alignItems: 'center' }}>
                    <div>FOLDER</div>
                    <div>
                      <TiArrowUnsorted
                        // onClick={(e) => {
                        //   onSort('FOLDER');
                        // }}
                        style={{
                          width: '20px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          paddingTop: '1px'
                          // alignItems: 'center'
                          // justifyContent: 'center'
                        }}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="for-Arrows" style={{ display: 'flex', alignItems: 'center' }}>
                    <div>ORGANIZER</div>
                    <div>
                      <TiArrowUnsorted
                        // onClick={(e) => {
                        //   onSort('ORGANIZER');
                        // }}
                        style={{
                          width: '20px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          paddingTop: '1px'
                          // alignItems: 'center'
                          // justifyContent: 'center'
                        }}
                      />
                    </div>
                  </div>
                </th> */}
                <th scope="col" style={{ width: '15%' }}>
                  <div className="for-Arrows" style={{ display: 'flex', alignItems: 'center' }}>
                    <div>LAST MODIFIED</div>
                    <div>
                      <TiArrowUnsorted
                        onClick={(e) => {
                          onSort(parentFolder, 'LAST_MODIFIED', defaultSort.LAST_MODIFIED);
                          setDefaultSort((state) => ({
                            ...state,
                            LAST_MODIFIED: !defaultSort.LAST_MODIFIED
                          }));
                        }}
                        style={{
                          width: '20px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          paddingTop: '1px'
                          // alignItems: 'center'
                          // justifyContent: 'center'
                        }}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col" style={{ width: '5%' }}></th>
              </tr>
            </thead>
            <tbody>
              {/* <ItemsDragLayer /> */}
              {alldata?.map((item, ind, v) => {
                let _disabled = true;
                if (item?.isFolder) {
                  _disabled = selectedItems?.folders.includes(item._id) || item?._id === hoveredId
                } else {
                  _disabled = selectedItems?.competitions.includes(item._id) || item?._id === hoveredId
                }
                let addSeparatorLine = false;
                let type = item?.isFolder ? 'FOLDER' : 'COMPETITION';
                if (type === 'COMPETITION' && !competitionStarted) {
                  // debugger
                  addSeparatorLine = true;
                  competitionStarted = true;
                }
                let selectionType = item?.isFolder ? 'folders' : 'competitions';
                return (
                  // <tr>
                  <DragNDropWrapper
                    addSeparatorLine={addSeparatorLine}
                    parent={parentFolder?._id}
                    view="LIST"
                    blurIt={_disabled && isDragging ? true : false}
                    _item={{ element: item, type: type, index: ind }}
                    _disabled={!_disabled}
                    canCreate={canCreate}
                    handleClearSelection={handleClearSelection}
                    selectedItems={selectedItems}>
                    <ListViewRow
                      matchCount={searchMatchCount(item._id)}
                      onHoverHandler={onHoverHandler}
                      _disabled={!_disabled}
                      item={item}
                      // organiser={organizer}
                      permissions={[...item?.organizerRoles, ...inheritedPermissions]}
                      // thumbnail={organizer?.thumbnail}
                      logo={item?.logo}
                      last_edited={getLastEdited(item)}
                      ind={ind}
                      title={item?.name}
                      onClickHandler={handleSelect}
                      onDoubleClickHandler={(e) => handleRouting(item?._id, type)}
                      type={item?.isFolder ? 'FOLDER' : 'COMPETITION'}
                      selectionType={selectionType}
                    />
                  </DragNDropWrapper>
                  // </tr>
                  // <ListViewItem
                  //   allItems={v}
                  //   folder={item}
                  //   key={ind}
                  //   index={ind}
                  //   parentId={parentFolder.id}
                  //   // addItemsToCart={props.addItemsToCart}
                  //   clearItemSelection={clearItemSelection}
                  //   // selectedFields={selectedFields}
                  //   handleSelection={handleFolderSelection}
                  //   destination={folder._id}
                  //   isDraggingAll={isDragging}
                  // />
                );
              })}
            </tbody>
          </table>
          <CustomDragLayerGrid />
          {openDelete && (
            <DeleteItems
              open={openDelete}
              setDelete={() => setOpenDelete(!openDelete)}
              folder={
                deleteType === 'FOLDER'
                  ? getObjectFromId('folders', selectedItems?.folders[0])
                  : deleteType === 'COMPETITION'
                  ? getObjectFromId('competitions', selectedItems?.competitions[0])
                  : false
              }
              multipleDelete={deleteType === 'MULTIPLE' ? selectedItems : false}
              type={deleteType}
              handleClearSelection={handleClearSelection}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ListView;
