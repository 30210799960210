function listenForOutsideClicks(listening, setListening, menuRef, setShowOptions) {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          const cur = menuRef?.current;
          const node = evt?.target;
          if (cur?.contains(node)) return;
          // setShowOptions(false);
          const contextMenu = document.getElementById('context-menu')

          if (evt.target.offsetParent != contextMenu) { 
            document.body.style.overflow = "scroll"
            contextMenu.classList.remove("visible"); }
        });
      });
    };
  }
  export default listenForOutsideClicks;
  