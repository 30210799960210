import React, { useState, createContext, useEffect } from 'react';
import Axios from 'axios';
import './_newfolder.scss';
import Popup_modals from '../modals/Popup_modals';
import { HiX } from 'react-icons/hi';
const backendServerURL = process.env.REACT_APP_CORE_API;

const Component = ({ onClose, parentId, OrganizaionId, setAddnewfolder }) => {
  const [name, setname] = useState();
  const [id, setId] = useState();

  const folderName = (e) => {
    e.preventDefault();
    Axios.post(backendServerURL + '/api/organizer/folder', {
      organizer: OrganizaionId ? OrganizaionId : 'null',
      name: name ? name : 'unnamed folder',
      parent: parentId ? parentId : null
    })
      .then((res) => {
        setAddnewfolder(res.data);
        onClose(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="newfolder-main modal_x">
      <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 style={{ font: 'normal normal bold 18px/22px Montserrat' }}>NEW FOLDER</h2>
        <HiX
          onClick={() => onClose(false)}
          style={{ marginTop: '18px', marginRight: '24px', fontSize: '26.5px', color: '#68717e' }}
        />
      </div>
      <form onSubmit={folderName}>
        <div className="newfolder-input">
          <input
          autoFocus
          defaultValue="Unnamed folder"
            maxLength={30}
            className="form-input "
            type="text"
            name="name"
            color="red"
            placeholder="Write the name of the folder"
            onChange={(e) => {
              setname(e.target.value.toLowerCase());
            }}
          />
        </div>

      <div className="newfolder-buttons">
        <button className="button bordered  ml-3 mr-3" type='button' onClick={() => onClose(false)}>
          Cancel
        </button>
        <button className="button primary " type='submit'>
          Create
        </button>
      </div>

        </form>
    </div>
  );
};
const Newfolder = ({ open, onClose, OrganizaionId, parentId, setAddnewfolder }) => {
  return (
    <Popup_modals
      open={open}
      onClose={onClose}
      OrganizaionId={OrganizaionId}
      parentId={parentId}
      setAddnewfolder={setAddnewfolder}
      Component={Component}></Popup_modals>
  );
};
export default Newfolder;
