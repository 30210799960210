import React, { useState, useRef } from 'react';
import './_share-options.scss';
import DeleteItems from '../../_modals/OrganizerPopup/deleteitems/delete';
import SharePopup from '../../_modals/SharePopup/SharePopup';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useEffect } from 'react';
import Axios from 'axios';
import Renamefolder from '../../_modals/OrganizerPopup/renameFolder/renameFolder';
import listenForOutsideClicks from './isclickoutide';
import DeleteItemsForever from '../../_modals/OrganizerPopup/deleteitems/deleteforever';
import CreateCompetitionModal from '../../_modals/CreateCompetitionModal/CreateCompetitionModal';
import { useDispatch, useSelector } from 'react-redux';
import { setContextObj } from '../../../redux/action/contextMenuAction';
function ShareOptions({
  isTrash,
  folderId,
  organizerId,
  folderName,
  setNewname,
  getTrans,
  organizerdata,
  competition,
  alldata,
  showRename,
  showShare,
  style,
  showDelete,
  permissions,
  hideOption,
  selected,
  ind,
  item,
  type,
  dotsColor
}) {
  const [open, setOpen] = useState(false);
  const contextMenu = useRef(document.getElementById('context-menu'));
  const [showOptions, setShowOptions] = useState(false);
  const [deleteOrganizaionId, setDeleteOrganizaionId] = useState();
  const [renameOrganizaionId, setRenameOrganizaionId] = useState();
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [deleteforeveropen, setDeleteforeveropen] = useState(false);
  const [deleteOrganizaionIdforever, setDeleteOrganizaionIdforever] = useState();
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [sharePopupid, setSharePopupid] = useState();

  const dispatch = useDispatch();
  let restoreids = {
    Organizers: [],
    Folders: [],
    Competitions: []
  };

  let backend = process.env.REACT_APP_CORE_API;
  const showContextMenu = (e, item, perm, type, isTrash = false) => {
    e.preventDefault();
    if (perm?.showOptions) {
      document.body.style.overflow = 'hidden';
    }
    // if (item?.organizerRoles && item?.organizerRoles.length) {
    //   if (item.organizerRoles[0].userPermission.name !== 'Owner' && type === 'ORGANIZER') {
    //     perm.delete = false
    //   }
    // }

    let obj = {
      item: item,
      permissions: perm,
      type: type,
      isTrash: isTrash
    };
    dispatch(setContextObj(obj));
    if (perm?.showOptions) {
      let { x, y } = menuRef.current.getBoundingClientRect();
      contextMenu.current.style.top = `${y + 28}px`;
      contextMenu.current.style.left = `${x}px`;
      contextMenu.current.classList.add('visible');
    }
  };
  const currentUserPermissions = useSelector((state) =>
    state.FOLDERS.CurrentUserPermissions ? state.FOLDERS.CurrentUserPermissions : []
  );
  const [deletePopup, setDeletePopup] = useState(false);
  const [renamePopup, setRenamePopup] = useState(false);

  const restoreData = (id) => {
    if (id.includes('folder')) {
      restoreids.Folders.push(id.split(' ').pop());
    }
    if (id.includes('organizer')) {
      restoreids.Organizers.push(id.split(' ').pop());
    }
    if (id.includes('competition')) {
      restoreids.Competitions.push(id.split(' ').pop());
    }

    Axios.put(backend + '/api/organizer/trash/restore', restoreids)
      .then((res) => {
        getTrans();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setShowOptions));
  const permanentyDelete = (id) => {
    setDeleteforeveropen(!deleteforeveropen);
  };
  return (
    <div ref={menuRef} className="Dots-containe" style={{marginLeft:'-6px'}}>
      {
        <div
          //  style={style}
          className="hi-dots "
          style={style}
          onClick={(e) => {
            showContextMenu(e, item, permissions, type, isTrash);
          }}>
          {/* <HiDotsHorizontal size={25}  style={{color:selected && selected === ind ? 'red' :'#3eabf0'}}/> */}
          <HiDotsHorizontal color={dotsColor} size={25} />
        </div>
      }
      {/* <div>
        {isTrash === true ? (
          <>
            {showOptions ? (
              <div className="spreadOptions">
                <div
                  className="list-item"
                  id={
                    folderId
                      ? 'folder ' + folderId
                      : organizerId
                      ? 'organizer ' + organizerId
                      : 'competition ' + competition
                  }
                  onClick={(e) => restoreData(e.target.id)}>
                  Restore
                </div>
                <div
                  className="list-item"
                  id={
                    folderId
                      ? 'folder ' + folderId
                      : organizerId
                      ? 'organizer ' + organizerId
                      : 'competition ' + competition
                  }
                  onClick={(e) => {
                    setDeleteOrganizaionIdforever(e.target.id);
                    setDeleteforeveropen(true);
                  }}>
                  Delete Forever
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {showOptions ? (
              <div className="spreadOptions">
                {folderId
                  ? showRename && (
                      <div
                        className="list-item"
                        id={folderId ? 'folder ' + folderId : null}
                        onClick={(e) => {
                          setRenamePopup(true);
                          setRenameOrganizaionId(e.target.id);
                        }}>
                        RENAME
                      </div>
                    )
                  : showRename && (
                      <div
                        className="list-item"
                        id={organizerId ? 'organizer ' + organizerId : null}
                        onClick={() => setShowOrgModal(true)}>
                        EDIT
                      </div>
                    )}

                <>
                  {showShare && (
                    <div
                      className="list-item"
                      id={
                        folderId
                          ? 'folder ' + folderId
                          : organizerId
                          ? 'organizer ' + organizerId
                          : 'competition ' + competition
                      }
                      onClick={(e) => {
                        setOpen(!open);
                        setSharePopupid(e.target.id);
                      }}>
                      SHARE
                    </div>
                  )}
                </>

                {showDelete && (
                  <div
                    className="list-item"
                    id={
                      folderId
                        ? 'folder ' + folderId
                        : organizerId
                        ? 'organizer ' + organizerId
                        : 'competition ' + competition
                    }
                    onClick={(e) => {
                      setDeletePopup(true);
                      setDeleteOrganizaionId(e.target.id);
                    }}>
                    DELETE
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div> */}
      {/* <SharePopup
        open={open}
        folderName={folderName}
        owners={sharePopupid}
        onClose={() => setOpen(!open)}
      />
      <DeleteItems
        open={deletePopup}
        setDelete={() => setDeletePopup(!deletePopup)}
        deleteOrganizaionId={deleteOrganizaionId}
        folderName={folderName}
      />
      <Renamefolder
        open={renamePopup}
        setRename={() => setRenamePopup(!renamePopup)}
        deleteOrganizaionId={renameOrganizaionId}
        folderName={folderName}
        setNewname={setNewname}
      />
      <DeleteItemsForever
        open={deleteforeveropen}
        setDeleteforeveropen={() => setDeleteforeveropen(!deleteforeveropen)}
        deleteOrganizaionId={deleteOrganizaionIdforever}
        isforever={true}
        folderName={folderName}
      />
      <CreateCompetitionModal
        open={showOrgModal}
        onClose={setShowOrgModal}
        organizerdata={organizerdata}
        isEditorganizer={true}
      /> */}
    </div>
  );
}

export default ShareOptions;
