import Axios from 'axios';
import { getFolders } from './competitionActions';
import {
  REARRANGE_ENTITIES,
  SET_DRAGGING_TASK,
  SET_ENTITIES,
  SET_SELECTED_TASKS,
  SET_FOLDERS,
  SET_DROP,
  SET_SELECTED_FOLDERS
} from './types';
let backend = process.env.REACT_APP_CORE_API;
export const setSelctedFolders = (arr)=> dispatch=>(
  dispatch({
  type : SET_SELECTED_FOLDERS,
  payload : arr
}))
// export const setSelectedTaskIds = (ids) => (dispatch) => {
//   dispatch({
//     type: SET_SELECTED_TASKS,
//     payload: ids
//   });
// };
// export const setDraggingTaskId = (id) => (dispatch) => {
//   dispatch({
//     type: SET_DRAGGING_TASK,
//     payload: id
//   });
// };
// export const setEntities = (obj) => (dispatch) => {
//   dispatch({
//     type: SET_ENTITIES,
//     payload: obj
//   });
// };
// export const rearrangeEntities = () => (dispatch) =>
//   dispatch({
//     type: REARRANGE_ENTITIES
//   });

export const moveToFolder = ({competitions,destinationFolder,folders}) => (dispatch) => {
  // debugger;
  let payload = {
    competitions,
    folders,
    destinationFolder
  };
  Axios.put(backend + '/api/organizer/folder/dragdrop', payload).then((res) => {
    // console.log({res})
    let folders = res.data;
    folders.folders = res.data.children ? res.data.children : res.data.folders;

    dispatch({
      type: SET_DROP,
      payload: folders
    });
    // const {_id} = res.data

    // dispatch(getFolders(_id))
  });
};
