import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InformationLogo, MultipleSelect } from '@common';

import './weekly-schedule-page.scss';
import {
  addTimeinDate,
  arrangeWeekdays,
  getConvertedDate,
  getDateAndTime,
  getDateWithAddedWeeks,
  getTimeInUTC,
  getWeekDay,
  getWeekDayFromArr,
  subtractTimeFromDate
} from '../../genHelpers';
function WeeklySchedulePage({
  competition,
  payload,
  setPayload,
  tag,
  stage,
  maxGroup,
  setStartDate,
  startDate
}) {
  // const [startDate, setStartDate] = useState('');
  const [matchSchedule, setMatchSchedule] = useState([]);
  const [weekdays, setWeekdays] = useState([]);
  const [timeType, setTimeType] = useState('m');
  const [numbers, setNumbers] = useState(60);
  const [errors, setErrors] = useState(null);
  useEffect(() => {
    // console.log({ timeType, numbers });
    let _err = null;
    switch (timeType) {
      case 'm':
        if (numbers < 1 || numbers > 120) {
          _err = 'The highest number of minutes are 1-120 minutes. Please choose a lower number.';
        } else {
          _err = null;
        }
        break;
      case 'h':
        if (numbers < 1 || numbers > 48) {
          _err = 'The highest number of hours are 1-48 hours. Please choose a lower number.';
        } else {
          _err = null;
        }
        break;
      case 'd':
        if (numbers < 1 || numbers > 6) {
          _err = 'The highest number of days are 1-6 days. Please choose a lower number.';
        } else {
          _err = null;
        }
        break;

      default:
        _err = null;
    }
    setErrors(_err);
  }, [timeType, numbers]);
  useEffect(() => {
    if (payload.weekSchedule.length === 0 || payload.startDate !== startDate) {
      handleReset({ force: true });
    } else {
      handleReset();
    }
  }, [payload]);
  const handleReset = (force = false) => {
    if (errors) {
      setErrors(null);
      setNumbers(60);
      setTimeType('m');
    }
    if (payload.weekSchedule.length > 0 && !force) {
      setMatchSchedule(payload.weekSchedule);
      let _weekdays = arrangeWeekdays(payload.startDate);
      setWeekdays(_weekdays);
    } else {
      if (payload.startDate !== startDate || force) {
        let matches = [];
        let week = 0;
        let day = 0;
        let totalMatches = typeof stage?.bracketType === 'undefined' ?  tag.matches : tag.rounds;
        let matchPerWeek = payload.matchesPerWeek;
        let _startDate = payload.startDate;
        let _timezone = payload.timezone;
        let _weekdays = arrangeWeekdays(_startDate);
        setWeekdays(_weekdays);
        // console.log({ _weekdays });
        for (let i = 0; i < totalMatches; i++) {
          let matchNo = i + 1;
          let _time = '18:00';
          let [_defaultDate, _defaultTime] = getDateAndTime(_startDate);
          if (i !== 0) {
            if (i % matchPerWeek === 0) {
              week = week + 1;
              day = 0;
            }
          }
          let _date = getDateWithAddedWeeks(_startDate, week);
          let matchObj = {
            _id: Math.floor(100000 + Math.random() * 900000),
            matchName: `Match ${matchNo}`,
            startDate: _startDate,
            date: _date,
            weekDay: day,
            week: week,
            time: _time,
            day: _weekdays[day]
          };
          matches.push(matchObj);
        }
        setStartDate(payload.startDate);
        setMatchSchedule(matches);
        setPayload((state) => ({
          ...state,
          weekSchedule: matches
        }));
      }
    }
  };
  const handleChange = ({ name, value }) => {
    switch (name) {
      case 'time-type':
        return setTimeType(value);

      case 'numbers':
        return setNumbers(parseInt(value));
    }
  };
  const addTimeFunction = (matches) => {
    if (errors) return;
    const newMatches = matches.map((match, i) => {
      if (i === 0) return match;
      let _num = i;
      let _numbers = numbers * _num;
      let [date, time] = addTimeinDate(`${match.date}T${match.time}`, _numbers, timeType);
      let _weekday = getWeekDayFromArr(date, time, weekdays);

      // debugger
      return {
        ...match,
        date: date,
        time: time,
        weekDay: _weekday,
        day: weekdays[_weekday]
      };
    });
    setMatchSchedule(newMatches);
    setPayload((state) => ({
      ...state,
      weekSchedule: newMatches
    }));
    // debugger;
  };
  const handleTimeChange = (e, id, time) => {
    let value = e.target.value;
    let matches = matchSchedule.map((match) => {
      if (match._id === id) {
        return {
          ...match,
          time: value
        };
      } else {
        return match;
      }
    });
    setPayload((state) => ({ ...state, weekSchedule: matches }));
    setMatchSchedule(matches);
  };
  const handleDayChange = (e, id, weekDay) => {
    let _value = parseInt(e.target.value);
    let date;
    let time;
    let matches = matchSchedule.map((match) => {
      if (match._id === id) {
        let value = match.weekDay - _value;
        if (value > weekDay) {
          [date, time] = addTimeinDate(`${match.date}T${match.time}`, value, 'd');
        } else {
          [date, time] = subtractTimeFromDate(`${match.date}T${match.time}`, value, 'd');
        }
        let _weekday = getWeekDayFromArr(date, time, weekdays);
        return {
          ...match,
          date: date,
          time: time,
          weekDay: _weekday,
          day: weekdays[_weekday]
        };
      } else {
        return match;
      }
    });
    // debugger;
    setPayload((state) => ({ ...state, weekSchedule: matches }));
    setMatchSchedule(matches);
  };
  
  return (
    <div className="weeklyschedulepage">
      <Row className="row-heading-two">
        <h2>Weekly Schedule</h2>
        <div className="tag-team-round-matches w-100" style={{backgroundColor:'rgb(65, 65, 65,0.1)'}}>
          <div className="num">
            {tag?.teams ?? 0} <span style={{color:'#68717e'}}>Teams</span>
          </div>
          <div className="num">
            {tag?.rounds ?? 0} <span style={{color:'#68717e'}}>Rounds</span>
          </div>
          <div className="num">
            {tag?.matches ?? 0} <span style={{color:'#68717e'}}>Matches</span>
          </div>
        </div>
      </Row>
      <Row className="stage-row">
        <h4>
          Add time between matches (optional){' '}
          <InformationLogo content="Add an amount of time between matches to ensure they aren’t played at the same time." />
        </h4>
        {/* <h5>Choose the groups you want to generate matches for.</h5> */}
        <Row className="time-interval-row">
          <input
            className={errors ? 'join-input-error' : 'join-input'}
            onChange={(e) => handleChange(e.target)}
            value={numbers}
            min={1}
            max={timeType === 'm' ? 120 : timeType === 'h' ? 48 : 6}
            type="number"
            name="numbers"
            id=""
          />
          <select
            name="time-type"
            className={errors ? 'join-select-error form-input' : 'join-select form-input'}
            value={timeType}
            onChange={(e) => handleChange(e.target)}
            // className={`form-input ${errors.birthdayMonth ? 'invalid' : ''}`}
            // defaultValue={StageSettings?.homeTeam}
          >
            {[
              { label: 'minutes', value: 'm' },
              { label: 'hours', value: 'h' },
              { label: 'days', value: 'd' }
            ].map(({ label, value }, i) => (
              <option key={i} value={value}>
                {label}
              </option>
            ))}
          </select>
          <button className="btn-btn-addTimeBtn" onClick={(e) => addTimeFunction(matchSchedule)}>
            <span>Add Time</span>
          </button>
          <button onClick={() => handleReset({ force: true })} className="btn-btn-resetBtn">
            <span>Reset</span>
          </button>
        </Row>
        {errors && <p className="time-error-3">{errors}</p>}
      </Row>
      <Row className="match-scheduling-wrapper-row">
        {matchSchedule.map(({ matchName, date, weekDay, time, _id }) => (
          <Row key={_id} className="match-schedule-row E246">
            <Col className="column-70-w">
              <label className="text-12 faded bold uppercase" htmlFor="lastName">
                {stage.stageType != 'tournament' ? matchName : 'Round ' + matchName.split(' ')[1]}
              </label>
              <select
                name="matches"
                className={'form-input'}
                onChange={(e) => handleDayChange(e, _id, weekDay)}
                value={weekDay}>
                {weekdays.map((day, i) => (
                  <option key={i} value={i}>
                    {day}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="column-30-w">
              <label className="text-12 faded bold uppercase" htmlFor="lastName">
                Time
              </label>
              <input
                type="time"
                name="time"
                onChange={(e) => handleTimeChange(e, _id, time)}
                value={time}
              />
            </Col>
          </Row>
        ))}
      </Row>
    </div>
  );
}

export default WeeklySchedulePage;
